
# Update bound select value when the multiple choice input is changed
$(document).on "input", ".SUBMISSION .multiple-input", (event) ->
  input = event.currentTarget
  return unless input? && input.dataset.boundMultiple?

  select = document.querySelector(input.dataset.boundMultiple)
  App.Helpers.Elements.Multiples.sync_bound_select(select)

# Set value when the multiple choice option is clicked
$(document).on "click", ".SUBMISSION .multiple:not(.empty_other)", (event) ->
  event.preventDefault()

  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)
  field.helpers.toggle_multiple(event.currentTarget)

$(document).on "keydown", ".SUBMISSION .multiple.empty_other", (event) ->
  return unless event.keyCode == 8

  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)
  field.helpers.update_other_multiple(event.currentTarget)

# Handle 'other' multiple text
$(document).on "input", ".SUBMISSION .multiple.other", (event) ->
  event.preventDefault()

  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)
  field.helpers.update_other_multiple(event.currentTarget)

# Display focused link outlines for multiples only if tabbed to, for accessibility.
$(document).on "keyup", ".multiple", (event) ->
  return unless event.which == 9

  field_input = event.currentTarget
  container = App.Helpers.Elements.closest(field_input, ".multiple_container")
  container.classList.add("tabbed")
