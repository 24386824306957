
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Strings ?= {}

window.App.Helpers.Strings.classify = (value) ->
  output = ""

  for section in value.split("_")
    output += section.charAt(0).toUpperCase() + section.slice(1)

  output

window.App.Helpers.Strings.pluralize = (value) ->
  return value if value[value.length - 1] == "s"
  value + "s"

window.App.Helpers.Strings.left_pad = (value, length, character = " ") ->
  value = character + value while value.length < length
  value

window.App.Helpers.Strings.right_pad = (value, length, character = " ") ->
  value += character while value.length < length
  value

window.App.Helpers.Strings.underscore = (value) ->
  split_capitals = value.split(/(?=[A-Z])/)

  value = split_capitals.join("_").toLowerCase() # Replace camelCase with underscores
  value = value.toLowerCase().replace(/\s/g, '_') # Replace spaces with underscores
  value = value.replace(/[^0-9A-Za-z_\-]/g, '') # Remove all non-alphanumeric characters
  value = value.replace(/_+/g, '_').replace(/^_|_$/g, '') # Remove repeated underscores
  value

window.App.Helpers.Strings.variable_name = App.Helpers.Strings.underscore

window.App.Helpers.Strings.titleize = (value) ->
  if value.indexOf(" ") > -1
    new_values = []

    for word in value.split(" ")
      new_values.push word.charAt(0).toUpperCase() + word.substr(1)

    new_values.join(" ")
  else
    value.charAt(0).toUpperCase() + value.substr(1)

window.App.Helpers.Strings.hash = (value) ->
  hash = 0

  return hash if value.length == 0

  for _, index in value
    char = value.charCodeAt(index)
    hash = ((hash << 5) - hash) + char
    hash |= 0

  hash

window.App.Helpers.Strings.matches_search = (search, value) ->
  searches = search.toLowerCase().split(" ")
  value = value.toLowerCase()

  for search_value in searches
    return false if value.indexOf(search_value) == -1

  true

window.App.Helpers.Strings.shade_color = (color, percent) ->
  f = parseInt(color.slice(1), 16)

  if percent < 0
    t = 0
    p = percent * -1
  else
    t = 255
    p = percent

  R = f >> 16
  G = f >> 8&0x00FF
  B = f&0x0000FF

  "#" + (0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1)
