
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}

class window.App.Models.Submission.Field.Pages
  constructor: (@submission) ->
    @callbacks = new CallbackManager()

  on: (event, callback) ->
    @callbacks.callback event, callback

  tab_elements: ->
    @submission._element.querySelectorAll(".FORM_PAGE_HEADER .FORM_PAGE_TAB")

  tab_names: ->
    tab_names = []

    for element in @tab_elements()
      tab_names.push(element.dataset.field)

    tab_names

  active_index: ->
    tab_header = @submission._element.querySelector(".FORM_PAGE_HEADER")

    return 0 unless tab_header?

    active_tab = tab_header.querySelector(".FORM_PAGE_TAB.ACTIVE_TAB")
    Array.from(tab_header.children).indexOf(active_tab)

  _field_to_page_index: (field, tab_header = undefined, tabs = undefined) ->
    tab_header ?= @submission._element.querySelector(".FORM_PAGE_HEADER")
    tabs ?= @tab_elements()

    if Number.isInteger(field)
      # If the number of tabs is 10, and the field is -100, using tabs.length + field once
      # will not solve the problem. The recursion below continues calculating until a valid
      # index is found.
      if field < 0
        return @_field_to_page_index(tabs.length + field, tab_header, tabs)
      else if field >= tabs.length
        return @_field_to_page_index(field - tabs.length, tab_header, tabs)

      index = field
    else if field instanceof App.Models.Submission.Field.Object
      index = @tab_names().indexOf(field.column_name)
    else
      if typeof field == "string"
        tab_element = tab_header.querySelector(".FORM_PAGE_TAB[data-field='#{field}']")
        index = Array.from(tab_header.children).indexOf(tab_element)
        index = undefined if index == -1

      if !index?
        field_model = @submission.fields.find(field)
        return unless field_model?
        index = @tab_names().indexOf(field_model.column_name)

    index

  page_name: (index) ->
    element = @page_tab_element(index)
    return unless element?

    element.dataset.field

  page_tab_element: (index) ->
    index = @_field_to_page_index(index)
    return unless index?

    @tab_elements()[index]

  page_element: (index) ->
    page_name = @page_name(index)
    pages = @submission._element.querySelectorAll(".FORM_PAGE")

    for page in pages
      return page if page.dataset.field == page_name

    null

  page_badge: (index, value) ->
    tab_element = @page_tab_element(index)
    badge_element = tab_element.querySelector(".tab_badge")

    if value == false
      if badge_element?
        badge_element.parentNode.removeChild(badge_element)

      return

    if !badge_element?
      badge_element = document.createElement("SPAN")
      badge_element.className = "tab_badge"
      tab_element.appendChild(badge_element)

    badge_element.textContent = value
    badge_element

  page_fields: (index) ->
    output = []
    element = @page_element(index)
    return output unless element?

    field_elements = element.querySelectorAll(".FIELD_CONTAINER")

    for el in field_elements
      output.push(@submission.fields.find_by_element(el))

    output

  open: (field) ->
    index = @_field_to_page_index(field)
    return unless index?

    page_name = @page_name(index)

    tabs = @tab_elements()
    pages = @submission._element.querySelectorAll(".FORM_PAGE")

    for tab in tabs
      tab.classList.toggle("ACTIVE_TAB", tab.dataset.field == page_name)

    for page in pages
      page.classList.toggle("ACTIVE_PAGE", page.dataset.field == page_name)

    @callbacks.trigger("change", page_name)

    @submission.sandboxes.publish "page.open", { value: index }

    @submission.refresh()

    @

  is_open: (field) ->
    index = @_field_to_page_index(field)

    return false unless index?

    @active_index() == index

  show_tabs: ->
    tab_header = @submission._element.querySelector(".FORM_PAGE_HEADER")
    return unless tab_header?
    tab_header.classList.remove("hidden")

  hide_tabs: ->
    tab_header = @submission._element.querySelector(".FORM_PAGE_HEADER")
    return unless tab_header?
    tab_header.classList.add("hidden")

  hide_tab: (index) ->
    element = @page_tab_element(index)
    return unless element?
    element.classList.add("hidden")

  show_tab: (index) ->
    element = @page_tab_element(index)
    return unless element?
    element.classList.remove("hidden")

  count: -> @tab_elements().length
  any: -> @count() > 0

  previous: ->
    index = @active_index() - 1
    @callbacks.trigger("previous", @page_name(index))
    @open(index)

  next: ->
    index = @active_index() + 1
    @callbacks.trigger("next", @page_name(index))
    @open(index)

  first: -> @open(0)
  second: -> @open(1)
  third: -> @open(2)
  last: -> @open(-1)

  is_first: -> @active_index() == @_field_to_page_index(0)
  is_second: -> @active_index() == @_field_to_page_index(1)
  is_third: -> @active_index() == @_field_to_page_index(2)
  is_last: -> @active_index() == @_field_to_page_index(-1)

  serialize: ->
    output = {}
    output.count = @count()
    output.active = @active_index()
    output.tab_names = @tab_names()
    output
