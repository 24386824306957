
window.Workflows ?= {}
window.Workflows.Helpers ?= {}

window.Workflows.Helpers.submissions_from_variables = (step, submission_variable, form_variable, type_variable, options) ->
  form_variable ?= "#{submission_variable}_form"
  type_variable ?= "#{submission_variable}_type"

  new Promise (resolve, reject) ->
    variables = {
      form: form_variable,
      lookup: submission_variable,
      type: type_variable
    }

    values = {
      form: Form.new(step.data.options[form_variable]),
      lookup: step.data.options[submission_variable],
      type: step.data.options[type_variable]
    }

    if values.type == "formula"
      lookup_promise = new Promise (resolve, reject) ->
        step.evaluate_formula(variables.lookup)
        .then resolve
        .catch reject
    else
      lookup_promise = Promise.resolve(values.lookup)

    lookup_promise.then (lookup_value) ->
      values.lookup = lookup_value

      Workflows.Helpers.submissions_from_variable_values(step, variables, values, options)
      .then (result) -> resolve(result.submissions)
      .catch reject

window.Workflows.Helpers.submissions_from_variable_values = (step, variables, values, options = {}) ->
  new Promise (resolve, reject) ->
    if !values.form?
      return reject({ form: null, submissions: [] })

    if typeof values.form == "string" || values.form instanceof String
      if values.form.length == 0
        return reject({ form: null, submissions: [] })

      values.form = App.Models.Form.new(values.form)

    switch values.type
      when "formula"
        identifiers = values.lookup
        identifiers = [identifiers] unless Array.isArray(identifiers)

        submissions = []

        for id in identifiers
          submission = values.form.submissions.find_locally(id) || values.form.submissions.find_locally_by_instance_id(id)
          submissions.push(submission) if submission?

        return resolve({ form: values.form, submissions: submissions })
      when "select"
        if !values.lookup?
          return reject({ form: values.form, submissions: [] })

        if values.lookup instanceof App.Models.Submission.Object
          return resolve({ form: values.form, submission: [values.lookup] })

        if values.lookup instanceof App.Schema.Submission
          return values.lookup.submission().then (submission) ->
            resolve({ form: values.form, submission: [submission] })

        if values.lookup instanceof App.Schema.SubmissionCollection
          return values.lookup.submissions().then (submissions) ->
            resolve({ form: values.form, submission: submissions })

        # Submission select dropdowns can either be direct variable references, "submission",
        # or submission field references, "submission:field". This could be cleaned up to expose
        # a more standardized child property lookup that dynamically changes how it finds attributes
        # depending on the base variable type.
        if values.lookup.indexOf(":") == -1
          Workflows.Helpers.submissions_from_variable(step, variables, values)
          .then resolve
          .catch reject
        else
          Workflows.Helpers.submissions_from_field_data(step, variables, values)
          .then resolve
          .catch reject

window.Workflows.Helpers.submissions_from_variable = (step, variables, values) ->
  new Promise (resolve, reject) ->
    step.get_variable(values.lookup).then (submissions) ->
      submissions = [submissions] if submissions? && !Array.isArray(submissions)

      if !submissions? || submissions.length == 0
        step.error("Submission not found")
        return reject({ form: values.form, value: [] })

      resolve({ form: submissions[0].form(), submissions: submissions })

# Submission context select fields save values in the format submission_reference:field_variable_name
# The submission_reference can be a variable, a submission permalink, or the context of the current workflow step.
# This must be evaluated to the value of the submission's connection `field_variable_name`.
window.Workflows.Helpers.submissions_from_field_data = (step, variables, values) ->
  new Promise (resolve, reject) ->
    Workflows.Helpers.field_values_from_variable_values(step, variables, values).then (result) ->
      scope_data = step.decode_field_data(values.lookup)
      field_name = scope_data.field

      if result.field.field_type() != "connection"
        step.error("Field `#{field_name}` is not a connection")
        return reject({ form: values.form, submissions: [] })

      result.field.load().then (field) ->
        field.connections().then (submissions) ->
          resolve({ form: result.field.connected_form(), submissions: submissions })
      .catch reject

window.Workflows.Helpers.submission_permalinks_from_variable_values = (step, variables, values) ->
  new Promise (resolve, reject) ->
    Workflows.Helpers.submissions_from_variable_values(step, variables, values).then (submissions) ->
      permalinks = []

      for submission in submissions
        permalinks.push(submission.permalink()) if submission.persisted()

      resolve({ form: submissions[0].form(), permalinks: permalinks })

window.Workflows.Helpers.context_from_variables = (step, lookup_variable, form_variable, type_variable) ->
  form_variable ?= "#{lookup_variable}_form"
  type_variable ?= "#{lookup_variable}_type"

  new Promise (resolve, reject) ->
    variables = {
      form: form_variable,
      lookup: lookup_variable,
      type: type_variable
    }

    values = {
      form: Form.new(step.data.options[form_variable]),
      lookup: step.data.options[lookup_variable],
      type: step.data.options[type_variable]
    }

    if values.type == "formula"
      lookup_promise = new Promise (resolve, reject) ->
        step.evaluate_formula(variables.lookup)
        .then resolve
        .catch reject
    else
      lookup_promise = Promise.resolve(values.lookup)

    lookup_promise.then (lookup_value) ->
      values.lookup = lookup_value

      Workflows.Helpers.context_from_variable_values(step, variables, values)
      .then resolve
      .catch reject

window.Workflows.Helpers.context_from_variable_values = (step, variables, values) ->
  new Promise (resolve, reject) ->
    values.form.load().then (form) ->
      context = {}
      context.form = form

      switch values.type
        when "formula"
          if Array.isArray(values.lookup)
            if values.lookup[0]? && values.lookup[0] instanceof App.Models.Submission.Object
              context.submissions = values.lookup
              return resolve(context)

          if values.lookup instanceof App.Models.Submission.Object
            context.submissions = [values.lookup]
            return resolve(context)

          resolve(context)
        when "select"
          scope_data = step.decode_field_data(values.lookup)

          if scope_data.field? && scope_data.field.length > 0
            field_schema = form.fields.find_by_column_name(scope_data.field)
            context.field = field_schema if field_schema?

          step.get_variable(scope_data.submission).done (submissions) ->
            submissions = [submissions] if submissions? && !Array.isArray(submissions)

            if submissions? && submissions.length > 0
              context.submissions = submissions

            resolve(context)
          .fail ->
            step.error("Submission retrieval failed")
            resolve(context)

window.Workflows.Helpers.field_values_from_variables = (step, field_variable, form_variable, type_variable) ->
  form_variable ?= "#{field_variable}_form"
  type_variable ?= "#{field_variable}_type"

  new Promise (resolve, reject) ->
    variables = {
      form: form_variable,
      lookup: field_variable,
      type: type_variable
    }

    values = {
      form: Form.new(step.data.options[form_variable]),
      lookup: step.data.options[field_variable],
      type: step.data.options[type_variable]
    }

    if values.type == "formula"
      lookup_promise = new Promise (resolve, reject) ->
        step.evaluate_formula(variables.lookup)
        .then resolve
        .catch reject
    else
      lookup_promise = Promise.resolve(values.lookup)

    lookup_promise.then (lookup_value) ->
      values.lookup = lookup_value

      Workflows.Helpers.field_values_from_variable_values(step, variables, values).then (result) ->
        Workflows.Helpers.format_field_values(result.field, result.value)
        .then resolve
        .catch reject
      .catch reject

# Submission field value selectors can either be formulas, or submission:field references.
# This parses and returns the value generated by formats based on the type value.
window.Workflows.Helpers.field_values_from_variable_values = (step, variables, values) ->
  new Promise (resolve, reject) ->
    values.form.load().then (form) ->
      switch values.type
        when "formula"
          step.evaluate_formula(variables.lookup).done (formula_values) ->
            formula_values ?= []
            formula_values = [formula_values] unless Array.isArray(formula_values)

            resolve({ form: form, value: formula_values })
          .fail ->
            step.error("Formula evaluation failed: `#{values.lookup}`")
            reject({ form: form, value: [] })
        when "select"
          Workflows.Helpers.context_from_variable_values(step, variables, values).then (context) ->
            if !context.field?
              step.error("Field isn't defined")
              return reject({ form: form, value: [] })

            if !context.submissions?
              step.error("Submission not found")
              return reject({ form: form, value: [] })

            field_name = context.field.column_name()

            for submission in context.submissions
              field = submission.fields.find_by_column_name(field_name)

              if !field?
                step.error("Submission Field `#{field_name}` not found")
                return reject({ form: form, field: context.field, value: [] })

              field_value = field.value()
              field_value = [field_value] unless Array.isArray(field_value)

              output.concat(field_value)

            resolve({ form: form, field: context.field, value: output })

window.Workflows.Helpers.format_field_values = (field, values) ->
  new Promise (resolve, reject) ->
    switch field.field_type()
      when "connection"
        form = field.connected_form()
        form.submissions.query_by_permalinks(values).submissions().then (submissions) ->
          resolve(submissions)
      else
        resolve(values)
