
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Sidebar ?= {}

window.App.Interface.Sidebar.overview = ->
  App.Interface.Sidebar._overview_model

class window.App.Interface.Sidebar.OverviewSideBar extends App.Interface.Sidebar.SideBar
  constructor: (@element) ->
    super(@element)

    @title = @element.dataset.title

    $(@element).on "click", ".collapse_form_sidebar", (event) =>
      event.preventDefault()
      event.stopPropagation()

      element = event.currentTarget

      @toggle_form_collapse(
        element, element.dataset.form, element.dataset.status == "true"
      )

    $(@element).on "click", ".sidebar-option[data-object='form_folder']", (event) =>
      event.preventDefault()
      event.stopPropagation()

      folder = event.currentTarget

      if folder.dataset.isApplication != "true"
        element = folder.querySelector(".collapse_form_folder_sidebar")

        @toggle_form_folder_collapse(
          element, element.dataset.folder, element.dataset.status == "true"
        )
      else
        @refresh(folder: folder.dataset.permalink)

    @generate_event_handlers()

  is_overview: -> true

  load: (options) ->
    return Promise.resolve() if @loaded()
    @refresh(options)

  loaded: ->
    return false unless @element?
    @element.classList.contains("loaded")

  show: (options) ->
    return unless @element?

    if @loaded()
      @generate_overview_return(options) if options? && options.return?
      return super(options)

    @load(options).then =>
      @generate_overview_return(options)
      super(options)

  return_to_context: ->
    if @_context_return_sidebar?
      @_context_return_sidebar.show()
    else
      @refresh()

  generate_overview_return: (options) ->
    return_button = @element.querySelector(".toggle_context_sidebar_return")
    return_button.remove() if return_button?

    return @_context_return_sidebar = null unless options? && options.return?

    @_context_return_sidebar = options.return.model

    return_button = document.createElement("A")
    return_button.className = "sidebar-option sidebar-option-large toggle_context_sidebar_return"

    return_icon = document.createElement("I")
    return_icon.className = "icon icon-right-open"

    return_text = document.createTextNode(options.return.title)

    return_button.appendChild(return_icon)
    return_button.appendChild(return_text)

    @element.insertBefore(return_button, @element.firstChild)

    null

  toggle_form_collapse: (element, form, status) ->
    new Promise (resolve, reject) =>
      new_status = !status
      add_loader element

      $.ajax
        url: Routes.toggle_sidebar_form_path(form, (new_status).toString())
        type: "PATCH"
        data: { authenticity_token: current_user.authenticity_token() }
      .done (response) =>
        remove_loader element
        element.dataset.status = response.visible

        icon = element.querySelector(".icon")
        icon.classList.toggle("icon-up-open", response.visible)
        icon.classList.toggle("icon-down-open", !response.visible)

        @refresh()

        resolve(@)
      .fail (xhr) =>
        App.Errors.response_error(xhr)

        reject(@)

  toggle_form_folder_collapse: (element, folder, status) ->
    new Promise (resolve, reject) =>
      new_status = !status

      element.dataset.status = new_status

      icon = element.querySelector(".icon")
      icon.classList.toggle("icon-up-open", new_status)
      icon.classList.toggle("icon-down-open", !new_status)

      wrapper = App.Helpers.Elements.closest(element, ".sidebar-option-folder-wrapper")

      children = wrapper.querySelector(".sidebar-option-folder-inner")
      children.classList.toggle("hidden", !new_status)

      resolve(@)

  refresh: (options = {}) ->
    if @_current_folder != options.folder
      if @has_cached_response(options.folder)
        @_current_folder = options.folder
        @load_response(@cached_response(options.folder))

        return Promise.resolve()

    @_refresh_request ?= new Promise (resolve, reject) =>
      data = {}
      data.authenticity_token = current_user.authenticity_token()

      # TODO: Support passing models and schemas directly
      if options.selected? && App.Helpers.Objects.isPlainObject(options.selected)
        data.selection_type = options.selected.type
        data.selection_permalink = options.selected.permalink

      if options.folder?
        data.folder = options.folder
        @_current_folder = options.folder
      else
        @_current_folder = undefined

      $.ajax
        url: Routes.sidebar_overview_path()
        type: "POST"
        data: data
      .done (response) =>
        @load_response(response)
        @cache_response(response, options.folder)

        resolve()
      .fail (xhr) =>
        @_refresh_request = undefined

        return unless current_user.signed_in()

        App.Errors.response_error(xhr)

        reject(xhr.responseJSON)

    @_refresh_request

  has_cached_response: (folder) ->
    @_cached_responses ?= {}
    @_cached_responses[folder]?

  cached_response: (folder) ->
    @_cached_responses ?= {}
    @_cached_responses[folder]

  cache_response: (response, folder) ->
    @_cached_responses ?= {}
    @_cached_responses[folder] = response

  load_response: (response) ->
    @_refresh_request = undefined

    element = App.Helpers.Elements.from_html(response)

    if element?
      @title = element.dataset.title
      @element.dataset.title = @title

      children = element.children

      @element.innerHTML = ""
      @element.insertBefore(children[0], null) for child in children
      @element.classList.add("loaded")

      @generate_event_handlers()

    element

  orderable: ->
    return false unless @element?
    @element.querySelector(".sidebar-options-outer[data-sortable=true]")?

  generate_event_handlers: ->
    return unless @element?

    @generate_sorting_handlers()

  generate_sorting_handlers: ->
    return unless @element?
    return if App.is_mobile()
    return unless @orderable()

    top_list = @element.querySelector(".sidebar-options-outer[data-sortable=true]")
    inner_lists = @element.querySelectorAll(".sidebar-option-container[data-sortable=true]")
    folder_lists = @element.querySelectorAll(".sidebar-option-folder-inner[data-sortable=true]")

    if top_list?
      new Sortable(top_list, {
        draggable: ".sidebar-option-top",
        onStart: ((top_list) -> -> top_list.classList.add("sorting"))(top_list),
        onEnd: ((top_list) -> -> top_list.classList.remove("sorting"))(top_list),
        onSort: => @save_ordering()
      })

    for list in inner_lists
      new Sortable(list, {
        draggable: ".sidebar-option",
        onStart: ((list) -> -> list.classList.add("sorting"))(list),
        onEnd: ((list) -> -> list.classList.remove("sorting"))(list),
        onSort: => @save_ordering()
      })

    for list in folder_lists
      new Sortable(list, {
        draggable: ".sidebar-option-wrapper",
        onStart: ((list) -> -> list.classList.add("sorting"))(list),
        onEnd: ((list) -> -> list.classList.remove("sorting"))(list),
        onSort: => @save_ordering()
      })

    undefined

  serialize_ordering: ->
    return unless @orderable()

    top_list = @element.querySelector(".sidebar-options-outer[data-sortable=true]")
    top_level_object_types = ["form", "form_folder", "custom_dashboard_view"]

    ordering = {}

    for option in top_list.querySelectorAll("[data-object]")
      object_type = option.dataset.object
      permalink = option.dataset.permalink

      continue if object_type == "_" || !permalink?

      ordering[object_type] ?= []

      if top_level_object_types.indexOf(object_type) != -1
        wrapper = App.Helpers.Elements.closest(
          option, ".sidebar-option-wrapper, .sidebar-option-folder-wrapper"
        )

        continue unless wrapper?

        index = App.Helpers.Elements.index(wrapper)
      else
        index = App.Helpers.Elements.index(option)

      ordering[object_type].push({ permalink: permalink, position: index })

    ordering

  save_ordering: ->
    return unless @element?
    return unless @orderable()

    $.ajax
      url: Routes.update_resource_ordering_path(format: "json")
      type: "POST"
      data: {
        ordering: @serialize_ordering()
        authenticity_token: current_user.authenticity_token()
      }

  reset_ordering: ->
    $.ajax
      url: Routes.reset_resource_ordering_path(format: "json")
      type: "DELETE"
      data: {
        authenticity_token: current_user.authenticity_token()
      }
