
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}

class window.App.Frame.Sandbox.Manager
  constructor: (@object, @namespace, @spawn_method) ->
    @_sandboxes = []
    @_subscriptions = []
    @callbacks = App.Helpers.Callbacks.new(@)

  on: (event, callback) -> @callbacks.on event, callback

  all: -> @_sandboxes
  started: -> @_sandboxes.length > 0

  new: ->
    new Error("No `new` method provided for sandboxed object.")
    @add(@spawn_method.apply(@object, arguments))

  add: (sandbox) ->
    return if @_sandboxes.indexOf(sandbox) != -1

    first_sandbox = @_sandboxes.length == 0

    @_sandboxes.push(sandbox)
    @attach_subscriptions(sandbox)

    @callbacks.trigger("start", sandbox) if first_sandbox
    @callbacks.trigger("add", sandbox)

    sandbox

  _subscribe: (event, callback) ->
    @_subscriptions.push({ event: event, callback: callback })

    subscribed_events = []

    for sandbox in @_sandboxes
      subscribed_events.push(sandbox.channel.subscribe(event, callback))

    subscribed_events

  subscribe: (event, callback) ->
    event = "#{@namespace}.#{event}"

    @_subscribe(event, callback)

  _publish: (event, data, source) ->
    sent_events = []

    for sandbox in @_sandboxes
      unless sandbox == source
        sent_events.push(sandbox.channel.publish(event, data))

    new App.Channels.FrameEvent.SentCollection(sent_events)

  publish: (event, data, source) ->
    event = "#{@namespace}.#{event}"

    @_publish(event, data, source)

  attach_subscriptions: (sandbox) ->
    for subscription in @_subscriptions
      sandbox.channel.subscribe(subscription.event, subscription.callback)

    sandbox
