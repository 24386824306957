
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseComment ?= {}
window.App.Elements.ResponseComment.Helpers ?= {}

# Naming this Routes conflicts with the top-level JS-Routes plugin.
class window.App.Elements.ResponseComment.Helpers.Routing
  constructor: (@comment) ->

  comments: ->
    Routes.submission_comments_path(
      @comment.viewer.form().permalink(),
      @comment.viewer.permalink()
    )

  new: (parent_permalink = undefined) ->
    if parent_permalink? && parent_permalink.length == 0
      parent_permalink = undefined

    Routes.new_submission_comment_path(
      @comment.viewer.form().permalink(),
      @comment.viewer.permalink(),
      parent_permalink: parent_permalink
    )

  create: (parent_permalink = undefined) ->
    if parent_permalink? && parent_permalink.length == 0
      parent_permalink = undefined

    Routes.create_submission_comment_path(
      @comment.viewer.form().permalink()
      @comment.viewer.permalink(),
      parent_permalink: parent_permalink
    )

  destroy: (comment_permalink) ->
    Routes.delete_submission_comment_path(
      @comment.viewer.form().permalink(),
      @comment.viewer.permalink(),
      comment_permalink
    )

  resolve: (comment_permalink) ->
    Routes.resolve_submission_comment_path(
      @comment.viewer.form().permalink(),
      @comment.viewer.permalink(),
      comment_permalink
    )

  unresolve: (comment_permalink) ->
    Routes.unresolve_submission_comment_path(
      @comment.viewer.form().permalink(),
      @comment.viewer.permalink(),
      comment_permalink
    )