window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseMailMerge ?= {}

class window.App.Elements.ResponseMailMerge.MailMerge
  constructor: (@element, @model_instance_id) ->
    @form_permalink = @element.dataset.formPermalink
    @mail_merge_permalink = @element.dataset.permalink

    @subject_field = @element.querySelector("input[name='mail_merge[subject]']")
    @recipient_to_field = @element.querySelector("input[name='mail_merge[recipient_to]']")
    @recipient_cc_field = @element.querySelector("input[name='mail_merge[recipient_cc]']")
    @recipient_bcc_field = @element.querySelector("input[name='mail_merge[recipient_bcc]']")
    @body_field = @element.querySelector("textarea[name='mail_merge[body]']")

    @save_button = @element.querySelector("button[type='submit'][value='save']")
    @save_send_button = @element.querySelector("button[type='submit'][value='save_send']")

    App.Helpers.Assets.get_entrypoints("codemirror.css").then (entrypoints) ->
      App.Helpers.Assets.require(entrypoints...)

    App.Helpers.Assets.get_entrypoints("codemirror.js").then (entrypoints) ->
      App.Helpers.Assets.require(entrypoints...)
    .then =>
      new FormulaWidget(@subject_field)
      new FormulaWidget(@recipient_to_field)
      new FormulaWidget(@recipient_cc_field)
      new FormulaWidget(@recipient_bcc_field)
      new FormulaWidget(@body_field)

    @save_button.addEventListener "click", (event) =>
      event.preventDefault()

      App.Helpers.Forms.clear_errors(@element)
      App.Helpers.Loaders.start(@save_button)

      data = App.Helpers.Forms.data(@element).mail_merge

      @model().save(data)
      .then =>
        App.Helpers.Loaders.finish(@save_send_button)
        @modal().hide()
      .catch (response) =>
        App.Helpers.Loaders.finish(@save_button)
        App.Helpers.Forms.apply_errors(@element, response.errors)

    @save_send_button.addEventListener "click", (event) =>
      event.preventDefault()

      App.Helpers.Forms.clear_errors(@element)
      App.Helpers.Loaders.start(@save_send_button)

      data = App.Helpers.Forms.data(@element).mail_merge

      @model().save_and_send(data)
      .then =>
        App.Helpers.Loaders.finish(@save_send_button)
        @modal().hide()
      .catch (response) =>
        App.Helpers.Loaders.finish(@save_send_button)
        App.Helpers.Forms.apply_errors(@element, response.errors)

    @element.addEventListener "submit", (event) =>
      event.preventDefault()

  form: -> App.Models.Form.new(@form_permalink)
  model: -> @form().mail_merges.find_by_instance_id(@model_instance_id)

  modal: -> App.Interface.Modal.find_by_id(@element.id)

  permalink: -> @mail_merge_permalink
  persisted: -> @mail_merge_permalink?
