
window.App ?= {}
window.App.Query ?= {}
window.App.Query.Condition ?= {}

window.App.Query.Condition.from_parameters = (parent, form, parameters, options = {}) ->
  if !form.schema_loaded()
    throw new Error("method requires schema() to be called on the form")

  field = App.Query.Condition.schema_from_parameter(parameters.field, form)
  field_class = App.Query.Condition.class_from_schema(field)

  model = new field_class(field, parameters.operator, parameters.value, options)
  model._set_parent(parent)
  model.instance_id = parameters.instance if parameters.instance?
  model

window.App.Query.Condition.schema_from_parameter = (parameter, form) ->
  parameter_is_model = parameter instanceof App.Schema.Form.Field
  parameter_is_model ||= parameter instanceof App.Schema.Form.Header

  return parameter if parameter_is_model

  App.Query.Condition.schema_from_name(parameter, form)

window.App.Query.Condition.schema_from_name = (name, form) ->
  if App.Schema.Form.Header.columns.indexOf(name) != -1
    return form.headers.find_by_column_name(name)

  field = form.fields.find_by_column_name(name)

  if !field? && form.deleted_fields?
    field = form.deleted_fields.find_by_column_name(name)

  field

window.App.Query.Condition.class_from_schema = (field) ->
  field_type_class = App.Helpers.Strings.classify(field.field_type())

  if field instanceof App.Schema.Form.Header
    condition_class = "HeaderCondition"
  else if field instanceof App.Schema.Form.Field
    condition_class = "FieldCondition"
  else
    return

  App.Query[condition_class][field_type_class]

window.App.Query.Condition.class_from_types = (condition_type, field_type) ->
  condition_type_class = App.Helpers.Strings.classify("#{condition_type}_condition")
  field_type_class = App.Helpers.Strings.classify(field_type)

  App.Query[condition_type_class][field_type_class]

window.App.Query.Condition.operator_name = (field_type, operator) ->
  if field_type? && i18n.exists("response_table.querying.operator_names.#{field_type}.#{operator}")
    return i18n.t("response_table.querying.operator_names.#{field_type}.#{operator}")
  else
    return i18n.t("response_table.querying.operator_names.default.#{operator}")

window.App.Query.Condition.aggregator_name = (field_type, operator) ->
  if field_type? && i18n.exists("response_table.aggregating.operator_names.#{field_type}.#{operator}")
    return i18n.t("response_table.aggregating.operator_names.#{field_type}.#{operator}")
  else
    return i18n.t("response_table.aggregating.operator_names.default.#{operator}")

window.App.Query.Condition.grouping_name = (field_type, operator) ->
  if field_type? && i18n.exists("response_table.grouping.operator_names.#{field_type}.#{operator}")
    return i18n.t("response_table.grouping.operator_names.#{field_type}.#{operator}")
  else
    return i18n.t("response_table.grouping.operator_names.default.#{operator}")

window.App.Query.Condition.serialize_value = (object, options) ->
  return object.toISOString() if object instanceof Date
  return object.username() if object instanceof App.Models.User.Object

  if options? && options.urlsafe == true && Array.isArray(object)
    return Object.assign({}, object)

  object

class window.App.Query.Condition.Base
  constructor: (@field, operator, @_value, @_options = {}) ->
    @instance_id = App.Helpers.Generators.instance_id()

    @operator(operator || @constructor.operators[0])

  form: ->
    return @parent.form() unless @field?
    @field.form()

  _set_parent: (parent) ->
    @parent = parent

  # Different field types have different Condition classes, so it's impossible to
  # keep the same Condition instance. This method returns the new instance and replaces
  # it in-place in the parent's array of children. The instance_id is also kept.
  # Proper usage: condition = condition._set_field(field)
  _set_field: (field) ->
    return unless field?
    field = App.Query.Condition.schema_from_parameter(field, @form())
    return if @field == field

    new_parameters = @serialize()
    new_parameters.field = field

    field_query = App.Query.Condition.class_from_schema(field)

    if field_query.operators.indexOf(new_parameters.operator) == -1
      new_parameters.operator = field_query.operators[0]

    @parent._replace_child(@, new_parameters)

  value: (value) ->
    if value?
      @_value = value
      @_presenter = null

    @_value

  operator: (value) ->
    if value?
      if @constructor.operators.indexOf(value) == -1
        throw new Error("Invalid Form Query Operator: #{value}")

      @_operator = value

    @_operator

  options: (options) ->
    @_options = options if options?
    @_options

  option: (key, value) ->
    return @_options[key] = value if key? && value?
    return @_options[key] if key?

    @_options

  destroy: (destroy_empty_parent = false) ->
    return unless @parent?

    # This can be used to check for any orphaned references if they are used elsewhere.
    @_destroyed = true
    @parent._remove_child(@, destroy_empty_parent)

  load: (parameters) ->
    field = App.Query.Condition.schema_from_parameter(parameters.field, form)

    @_set_field(field)
    @operator(parameters.operator)
    @value(parameters.value)

    @

  present: ->
    return Promise.resolve() unless @field?
    return Promise.resolve() unless @field instanceof App.Schema.Form.Field

    @_presenter ?= new Promise (resolve, reject) =>
      promises = []

      condition_value = @value()
      condition_value = [condition_value] unless Array.isArray(condition_value)

      for value in condition_value
        promises.push @field.present(value)

      Promise.all(promises).then (responses) ->
        output = []

        for value in responses
          output.push(value) if value? && value.length > 0

        resolve(output.join(", "))

  serialize: (options) ->
    output = {
      type: "condition",
      field: @field.column_name(),
      operator: @operator(),
      value: App.Query.Condition.serialize_value(@value(), options)
    }

    if options? && options.instance == true
      output.instance = @instance_id

    output
