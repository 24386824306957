
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentTable ?= {}
window.App.Elements.AttachmentTable.Helpers ?= {}

class window.App.Elements.AttachmentTable.Helpers.Translations
  constructor: (@table) ->

  empty: ->
    empty_table_translation = @table.element.dataset.emptyMessage

    return empty_table_translation if empty_table_translation?

    if @table.has_permission("create")
      return i18n.t("file_table.no_files_available_create_one")

    i18n.t("file_table.no_files_available")

  page_count: (start, finish, total) ->
    if total == 0
      i18n.t("response_table.page_count", {
        start: 0, finish: 0, total: 0
      })
    else
      i18n.t("response_table.page_count", {
        start: start + 1, finish: finish, total: total
      })
