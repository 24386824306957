
window.App ?= {}
window.App.Tutorial ?= {}
window.App.Tutorial.Lessons ?= {}

(->
  lesson = new App.Tutorial.Lesson("form_editor", "Building Forms")

  lesson.step {
    text: ->
      output = []
      output.push("Forms are the building blocks of your application - build them, change them, and connect them together.")

      if App.is_mobile()
        output.push("To get started, open the <i class='icon-menu mr-1 ml-1'></i> menu and tap a field type to add it to your form.")
      else
        output.push("To get started, <b>click or drag a field type</b> from the sidebar to add it to your form.")

      output.join(" ")
  }

  lesson.step {
    text: ->
      output = []
      output.push("You've created your first field!")

      if App.is_mobile()
        output.push("<b>Tap it</b> to start customizing it.")
      else
        output.push("<b>Click it</b> to start customizing it.")

      output.join(" ")
    trigger: (resolve) -> editor.on("field.create", resolve)
  }

  lesson.step {
    text: ""
    trigger: (resolve) -> editor.on("field.show", resolve)
  }

  null
)()
