
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.RowDrawing = {
  clear: ->
    for cell in @table_body_element.querySelectorAll("td:not(.row_select_td)")
      cell.innerHTML = ""

    if @table_aggregate_element?
      @table_aggregate_element.innerHTML = ""

    undefined

  load_empty_table_notice: ->
    row = document.createElement("TR")
    row.className = "empty_table_row"

    cell = document.createElement("TD")
    cell.setAttribute("colspan", @columns().length + 1)
    cell.innerHTML = "<div class='td-content'>#{@translations.empty()}</div>"

    row.appendChild(cell)
    @table_body_element.appendChild(row)

  load_data: (response) ->
    return if response.draw? && @draw_count != response.draw

    @table_body_element.innerHTML = ""
    @table_aggregate_element.innerHTML = "" if @table_aggregate_element?

    columns = @visible_columns()
    column_td_content = {}

    @subscription_update(response.subscription)

    return @load_empty_table_notice() if !response.data? || response.data.length == 0

    @load_column_data(response, columns, column_td_content)
    @load_response_data(response, columns, column_td_content)
    @load_aggregation_data(response, columns)

    App.Helpers.Elements.linkify(@table_body_element, form: @form().permalink())

    return @table_body_element

  load_column_data: (response, columns, column_td_content) ->
    for column in columns
      column_is_wrapped = false

      for column_data in response.data
        if column_data[column.field_id]? && column_data[column.field_id].length > 0
          column_content = column_data[column.field_id]

          column_is_wrapped = column_content.startsWith("<div class='td-content")
          column_is_wrapped ||= column_content.startsWith('<div class="td-content')

          break

      if column_is_wrapped
        column_td_content[column.field_id] = true
      else
        column_td_content[column.field_id] = false

    @table_body_element

  load_response_data: (response, columns, column_td_content) ->
    for row_data, index in response.data
      formattings = null
      formattings = response.formattings[row_data.permalink] if response.formattings?

      row = document.createElement("TR")
      row.setAttribute("data-permalink", row_data.permalink)

      for column in columns
        column_name = column.field_id
        column_permalink = column.permalink()

        cell = document.createElement("TD")
        cell.className = "row_select_td" if column_name == "checkbox"

        if column_td_content[column_name]
          cell.innerHTML = row_data[column_name]
        else
          content_class = "td-content"
          content_class += " thin" if column_name == "view" || column_name == "edit"

          cell.innerHTML = "<div class='#{content_class}'>#{row_data[column_name] || ""}</div>"

        row.appendChild(cell)

        if formattings? && formattings[column_permalink]?
          for format in formattings[column_permalink]
            @apply_format(format, row, cell, index)

      # Fake column for spacing
      if @fake_cell_element?
        row.appendChild(document.createElement("TD"))

      if formattings? && formattings.row?
        for format in formattings.row
          @apply_format(format, row, null, index)

      @table_body_element.appendChild(row)

    @table_body_element

  generate_aggregation_container: ->
    return @table_aggregate_element if @table_aggregate_element?

    footer = document.createElement("TFOOT")
    footer.classList.add("response_table_aggregations")

    @table_element.appendChild(footer)

    @table_aggregate_element = footer
    @table_aggregate_element

  load_aggregation_data: (response, columns) ->
    return unless response.aggregations?

    container = @generate_aggregation_container()

    inline_operators = []
    inline_aggregations_by_operator = {}

    standalone_aggregations = []

    for row_data in response.aggregations
      if @column(row_data.field.permalink)?
        inline_aggregations_by_operator[row_data.operator] ?= []
        inline_aggregations_by_operator[row_data.operator].push(row_data)

        inline_operators.push(row_data.operator) if inline_operators.indexOf(row_data.operator) == -1
      else
        standalone_aggregations.push(row_data)

    inline_operators = inline_operators.sort()

    for operator in inline_operators
      operator_rows = inline_aggregations_by_operator[operator]

      row = document.createElement("TR")
      row.dataset.operator = operator
      row.className = "aggregation_row"

      operator_rows = operator_rows.sort(
        (a, b) => @column(a.field.permalink).index() - @column(b.field.permalink).index()
      )

      first_aggregate = operator_rows[0]
      first_aggregate_model = @column(first_aggregate.field.permalink)

      name = i18n.t("response_table.aggregating.operator_names.default.#{operator}")

      cell = document.createElement("TD")
      cell.colSpan = first_aggregate_model.index()
      cell.innerHTML = "<div class='td-content'>#{name}</div>"

      row.appendChild(cell)

      for row_data, index in operator_rows
        column = @column(row_data.field.permalink)
        last_operator_aggregate = (index == operator_rows.length - 1)

        cell = document.createElement("TD")
        cell.dataset.permalink = row_data.permalink
        cell.dataset.field = row_data.field.permalink
        cell.dataset.operator = operator
        cell.className = "aggregation_cell"

        if !last_operator_aggregate
          next_aggregate = operator_rows[index + 1]
          next_aggregate_column = @column(next_aggregate.field.permalink)

          distance_to_next = next_aggregate_column.index() - column.index()
          cell.colSpan = distance_to_next
        else
          cell.colSpan = @columns().length - column.index()

        # Round decimal strings like "3.00"
        if row_data.result? && (row_data.result % 1) == 0
          row_data.result = parseInt(row_data.result).toString()

        cell_content = document.createElement("DIV")
        cell_content.className = "td-content"
        cell_content.textContent = row_data.result

        cell.appendChild(cell_content)
        row.appendChild(cell)

      # Fake column for spacing
      if @fake_cell_element?
        row.appendChild(document.createElement("TD"))

      container.appendChild(row)

    container

  increment_draw_count: ->
    @draw_count ?= 0
    @draw_count += 1
}