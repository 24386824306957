
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseKanban ?= {}
window.App.Elements.ResponseKanban.Helpers ?= {}

# Naming this Routes conflicts with the top-level JS-Routes plugin.
class window.App.Elements.ResponseKanban.Helpers.Routing
  constructor: (@kanban) ->

  data: ->
    Routes.kanban_data_path(
      @kanban.form().permalink(),
      @kanban.permalink(),
      search: @kanban.params.search()
    )

  save_order: ->
    Routes.save_kanban_ordering_path(
      @kanban.form().permalink(),
      @kanban.permalink()
    )
