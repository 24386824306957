
window.App ?= {}
window.App.Schema ?= {}

class window.App.Schema.Group
  @_models: {}

  @new: (data) ->
    @_models[data.permalink] ?= new App.Schema.Group(data)
    @_models[data.permalink]

  @schema: ->
    @_schema_promise ?= new Promise (resolve, reject) =>
      $.ajax
        url: "/api/rest/v1/groups/"
      .done (response) ->
        output = []

        for group_data in response.groups
          output.push App.Schema.Group.new(group_data)

        resolve(output)
      .fail (response) =>
        @_schema_promise = undefined

        reject(response.responseJSON)

  @load: -> @schema()
  @loaded: -> @_schema_promise?

  @find_by_permalink: (permalink) ->
    if App.Schema.Group._models[permalink]?
      return Promise.resolve(App.Schema.Group._models[permalink])

    new Promise (resolve, reject) ->
      $.ajax
        url: "/api/rest/v1/groups/#{permalink}"
      .done (response) ->
        group = App.Schema.Group.new(response)
        group._schema_promise = Promise.resolve(response)
        resolve group
      .fail (response) ->
        reject response.responseJSON

  @all: ->
    if !@_schema_promise?
      throw new Error("all requires App.Schema.Group.schema() to be called first")

    Object.values(@_models)

  @all_except_public: ->
    output = []

    for permalink, group of @_models
      continue if group.type() == "public"
      output.push(group)

    output

  constructor: (@_data = {}) ->

  name: -> @_data.name
  permalink: -> @_data.permalink

  type: -> @_data.type

  schema: ->
    @_schema_promise ?= new Promise (resolve, reject) =>
      $.ajax
        url: @_data.urls.self
      .done (response) =>
        @_data = response
        resolve(@)
      .fail (response) ->
        reject response.responseJSON

  load: -> @schema()
  loaded: -> @_schema_promise?

  users: ->
    new Promise (resolve, reject) =>
      @schema().then =>
        output = []

        for user in @_data.users
          output.push App.Schema.User.load(user)

        resolve(output)
