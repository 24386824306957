
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Modal ?= {}

window.App.Interface.Modal.sizes = ["x-small", "small", "medium", "large", "x-large"]
window.App.Interface.Modal.action_types = ["primary", "secondary", "tertiary"]

window.App.Interface.Modal.animation_time = 200

window.App.Interface.Modal.container = ->
  App.Interface.Modal._container ?= document.getElementById("modals-container")
  App.Interface.Modal._container

window.App.Interface.Modal.new = (title, body, actions, options) ->
  title ?= ""
  body ?= ""

  if !options? && App.Helpers.Objects.isPlainObject(actions)
    options = actions
    actions = undefined

  options ?= {}
  options.id ?= App.Helpers.Generators.uuid()
  options.size ?= App.Interface.Modal.parse_size(options.size)

  options.escape ?= options.safe
  options.escape ?= false

  container = document.createElement("DIV")
  container.className = "modal-container"
  container.style.zIndex = App.Helpers.Generators.unix_time()
  container.id = options.id

  container_inner = document.createElement("DIV")
  container_inner.className = "modal #{options.size}"

  container.appendChild(container_inner)

  modal = new App.Interface.Modal.Object(container)

  title_element = document.createElement("DIV")
  title_element.className = "title"

  body_element = document.createElement("DIV")
  body_element.className = "body"

  if options.escape
    title_element.textContent = title
    body_element.textContent = body
  else
    if App.Helpers.Elements.is_jquery(title)
      $(title_element).append(title)
    else
      title_element.innerHTML = title

    if App.Helpers.Elements.is_jquery(body)
      $(body_element).append(body)
    else
      body_element.innerHTML = body

  actions = actions[0] if App.Helpers.Elements.is_jquery(actions)

  if actions? && (actions.nodeType? || typeof actions == "string")
    if options.escape
      action_element = App.Interface.Modal.actions_to_element(undefined, modal)
    else
      action_element = document.createElement("DIV")
      action_element.className = "actions"
      action_element.innerHTML = actions
  else
    action_element = App.Interface.Modal.actions_to_element(actions, modal)

  body_element.appendChild(action_element)

  exit_element = document.createElement("DIV")
  exit_element.className = "modal-exit"
  exit_element.title = i18n.t("close_window")
  exit_element.innerHTML = "&times;"

  container_inner.appendChild(exit_element)
  container_inner.appendChild(title_element)
  container_inner.appendChild(body_element)

  modal.show() unless options.visible == false
  modal.force() if options.visible == true

  App.Interface.Modal.container().appendChild(container)

  modal

window.App.Interface.Modal.escape_new = (title, body, actions, options) ->
  if !options? && App.Helpers.Objects.isPlainObject(actions)
    options = actions
    actions = undefined

  options.escape = true
  App.Interface.Modal.new(title, body, actions, options)

window.App.Interface.Modal.parse_size = (value) ->
  return "medium" unless value?
  return "medium" if App.Interface.Modal.sizes.indexOf(value) == -1
  value

window.App.Interface.Modal.parse_actions = (actions = []) ->
  output = []

  for action in actions
    continue if action.if == false
    output.push App.Interface.Modal.parse_action(action)

  output

window.App.Interface.Modal.parse_action = (options = {}) ->
  output = {}

  output.text = options.text
  output.title = options.title
  output.callback = options.callback

  if options.close == true || options.close == false
    output.close = options.close

  if options.if?
    output.if = options.if

  if ["right", "left"].indexOf(options.align) == -1
    output.align = "right"
  else
    output.align = options.align

  if App.Interface.Modal.action_types.indexOf(options.type) == -1
    output.type = "secondary"
  else
    output.type = options.type

  output

window.App.Interface.Modal.actions_to_element = (actions, modal) ->
  container = document.createElement("DIV")
  container.className = "actions"

  action_elements = []

  if actions?
    for options in actions
      element = App.Interface.Modal.action_to_element(options, modal)
      continue unless element?
      action_elements.push(element)

  # Actions can be skipped at runtime with the "if" option.
  if action_elements.length > 0
    for element in action_elements
      container.appendChild(element)
  else
    element = document.createElement("A")
    element.className = "option primary right modal-exit"
    element.href = "#"
    element.textContent = "OK"

    container.appendChild(element)

  container

window.App.Interface.Modal.action_to_element = (options, modal) ->
  options = App.Interface.Modal.parse_action(options)
  return if options.if == false

  if typeof options.if == "function"
    return unless options.if(modal)

  element = document.createElement("A")
  element.href = "#"
  element.textContent = options.text
  element.title = options.title if options.title?
  element.className = "option #{options.type} #{options.align}"
  element.classList.add("modal-exit") if options.close != false

  if options.callback?
    element.addEventListener "click", ((callback) -> (event) ->
      event.preventDefault()
      callback(event, modal)
    )(options.callback)

  element

window.App.Interface.Modal.find_by_id = (id) ->
  for _, modal of App.Interface.Modal._models
    return modal if modal.element_id == id

  undefined

window.App.Interface.Modal.from_url = (url) ->
  new App.Interface.Modal.Object(undefined, url)

window.App.Interface.Modal.from_html = (html, options = {}) ->
  element = App.Helpers.Elements.from_html(html)

  App.Interface.Modal.container().appendChild(element)

  modal = new App.Interface.Modal.Object(element)
  modal.show() unless options.visible == false
  modal

window.App.Interface.Modal.all = ->
  Object.values(App.Interface.Modal._models)

window.App.Interface.Modal.last_modal = ->
  App.Interface.Modal._last

window.App.Interface.Modal.visible_modal = ->
  current = undefined
  current_index = 0

  for modal in App.Interface.Modal.visible_modals()
    if modal.container?
      z_index = parseInt(modal.container.style.zIndex)
      current = modal if z_index > current_index

  current

window.App.Interface.Modal.visible_modals = (except = []) ->
  except = [except] unless Array.isArray(except)
  except_provided = except.length > 0

  output = []

  if except_provided
    for modal in App.Interface.Modal.all()
      output.push(modal) if modal.visible() && except.indexOf(modal) == -1
  else
    for modal in App.Interface.Modal.all()
      output.push(modal) if modal.visible()

  output

window.App.Interface.Modal.hide_all = (except = []) ->
  except = [except] unless Array.isArray(except)

  modal.hide() for modal in App.Interface.Modal.visible_modals(except)

window.Modal ?= {}
window.Modal.new = App.Interface.Modal.new
window.Modal.from_url = App.Interface.Modal.from_url
