
$(document).on "click", ".read_announcement", (event) ->
  element = event.currentTarget
  permalink = element.dataset.permalink

  $.ajax
    url: Routes.read_announcement_path(permalink, format: "json")
    type: "PATCH"
    data: { authenticity_token: current_user.authenticity_token() }

$(document).on "click", ".hide_user_notice", (event) ->
  element = event.currentTarget
  notice = element.dataset.notice

  $.ajax
    url: Routes.hide_notice_path(notice, format: "json")
    type: "POST"
    data: { authenticity_token: current_user.authenticity_token() }

$(document).on "click", ".navbar.context .show_search_input", (event) ->
  event.preventDefault()

  element = event.currentTarget
  option = App.Helpers.Elements.closest(element, ".option")
  container = option.parentNode
  button = container.querySelector(".search_button")

  search = container.querySelector(".search_input")
  input = search.querySelector("input")

  menu = App.Helpers.Elements.closest(container, ".navbar.context")
  menu.classList.remove("mobile_search_visible")

  search.classList.toggle("hidden")
  button.classList.toggle("hidden")

  input.value = ""
  input.dispatchEvent(App.Helpers.Events.new("input", { bubbles: true }))

  input.focus() unless search.classList.contains("hidden")

$(document).on "click", ".new_resource_button", (event) ->
  event.preventDefault()

  button = event.currentTarget
  navbar = App.Helpers.Elements.closest(button, ".navbar")
  navbar_container = App.Helpers.Elements.closest(button, "#global_page_navbar")
  navbar_height = navbar_container.offsetHeight

  menu = navbar.querySelector(".menu_dropdown.new_resource_menu")
  menu.style.top = "#{navbar_height}px"
  menu.classList.toggle("hidden")

$(document).on "click", ".new_attachment_button", (event) ->
  event.preventDefault()

  button = event.currentTarget
  navbar = App.Helpers.Elements.closest(button, ".navbar")
  navbar_container = App.Helpers.Elements.closest(button, "#global_page_navbar")
  navbar_height = navbar_container.offsetHeight

  menu = navbar.querySelector(".menu_dropdown.new_attachment_menu")
  menu.style.top = "#{navbar_height}px"
  menu.classList.toggle("hidden")

$(document).on "click", ".menu_dropdown a, .menu_dropdown label", (event) ->
  button = event.currentTarget
  menu = App.Helpers.Elements.closest(button, ".menu_dropdown")
  menu.classList.add("hidden")

$(document).on "click", ".menu_dropdown_background", (event) ->
  menu_dropdowns = document.querySelectorAll(".menu_dropdown")

  for element in menu_dropdowns
    element.classList.add("hidden")

  undefined

$(document).on "click", ".navbar.head .option:not(.settings_option)", (event) ->
  selected_option = event.target
  navbar = App.Helpers.Elements.closest(selected_option, ".navbar.head")
  options = navbar.querySelectorAll(".option")

  for option in options
    option.classList.remove("selected")

  return if selected_option.classList.contains("root_option")

  selected_option.classList.add("selected")

$(document).on "mousedown", ".option.custom_option", (event) ->
  event.preventDefault()

  button = event.currentTarget
  instance_id = button.dataset.instance

  menu_element = App.Helpers.Elements.closest(button, ".navbar.context")

  # Menu options may be cloned into the sidebar on mobile screens
  if menu_element?
    menu = App.Interface.Menu.find(menu_element)
  else
    menu = App.menu()

  return unless menu?

  option = menu.options(instance_id)
  return unless option?

  option.trigger_preloads()

# This shouldn't be scoped by .navbar.context because context menu options may appear
# in the sidebar on mobile devices.
$(document).on "click", ".option.custom_option.text_option, .option.custom_option.submit_option", (event) ->
  button = event.currentTarget

  link = button.querySelector("a")

  if link?
    href = link.getAttribute("href")
    return true if href? && href != "#"

  event.preventDefault()

  instance_id = button.dataset.instance

  menu_element = App.Helpers.Elements.closest(button, ".navbar.context")

  # Menu options may be cloned into the sidebar on mobile screens
  if menu_element?
    menu = App.Interface.Menu.find(menu_element)
  else
    menu = App.menu()

  return unless menu?

  option = menu.options(instance_id)
  return unless option?

  option.trigger_callbacks()

# This shouldn't be scoped by .navbar.context because context menu options may appear
# in the sidebar on mobile devices.
$(document).on "input", ".option.custom_option.search_input", (event) ->
  event.preventDefault()

  button = event.currentTarget
  instance_id = button.dataset.instance

  menu_element = App.Helpers.Elements.closest(button, ".navbar.context")

  # Menu options may be cloned into the sidebar on mobile screens
  if menu_element?
    menu = App.Interface.Menu.find(menu_element)
  else
    menu = App.menu()

  return unless menu?

  option = menu.options(instance_id)
  return unless option?

  option.trigger_callbacks(option.input.value)

# This shouldn't be scoped by .navbar.context because context menu options may appear
# in the sidebar on mobile devices.
$(document).on "click", ".option.custom_option.filter_option", (event) ->
  event.preventDefault()

  button = event.currentTarget
  instance_id = button.dataset.instance

  menu_element = App.Helpers.Elements.closest(button, ".navbar.context")

  # Menu options may be cloned into the sidebar on mobile screens
  if menu_element?
    menu = App.Interface.Menu.find(menu_element)
  else
    menu = App.menu()

  return unless menu?

  option = menu.options(instance_id)
  return unless option?

  option.query_editor().show(build: true)
