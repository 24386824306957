
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}
window.App.Interface.Menu.MenuBar ?= {}
window.App.Interface.Menu.MenuBar.Helpers ?= {}
window.App.Interface.Menu.MenuBar.Helpers.Sandboxes ?= {}

window.App.Interface.Menu.MenuBar.Helpers.Sandboxes.register = (menu) ->
  menu.sandboxes.subscribe "option.create", (event) ->
    data = App.Interface.Menu.parse_option_data(event.data)
    menu.option data.text, data

  menu.sandboxes.subscribe "filter.create", (event) ->
    menu.filter_option App.Interface.Menu.parse_filter_data(event.data)

  menu.sandboxes.subscribe "search.create", (event) ->
    menu.search_option App.Interface.Menu.parse_search_data(event.data)

  menu.sandboxes.subscribe "submit.create", (event) ->
    data = App.Interface.Menu.parse_submit_data(event.data)
    menu.submit_option data.text, data

  menu.sandboxes.subscribe "dropdown.create", (event) ->
    data = App.Interface.Menu.SubMenu.parse_option_data(event.data)
    menu.dropdown_option data.text, data

  menu.sandboxes.subscribe "fullscreen.create", (event) ->
    menu.fullscreen_option event.data.visible

  menu.sandboxes.subscribe "alert", (event) ->
    menu.alert event.data.value, { id: event.data.id, safe: true }

  menu.sandboxes.subscribe "notice", (event) ->
    menu.alert event.data.value, { id: event.data.id, safe: true }

  for option in menu.options()
    option._generate_sandbox()

  undefined
