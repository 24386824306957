
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}
window.App.Elements.ResponseTable.Helpers ?= {}

class window.App.Elements.ResponseTable.Helpers.Translations
  constructor: (@table) ->

  response: ->
    @table.element.dataset.response || i18n.t("response_table.response")

  new_response: ->
    @table.element.dataset.newResponse

  responses: ->
    @table.element.dataset.responses || i18n.t("response_table.responses")

  empty: ->
    empty_table_translation = @table.element.dataset.emptyMessage

    return empty_table_translation if empty_table_translation?

    if @table.has_permission("create")
      form_permalink = @table.form().permalink()

      create_path = Routes.new_submission_path(form_permalink)
      create_text = i18n.t("response_table.no_responses_create_link_text")
      create_link = "<a href='#{create_path}' data-form-permalink='#{form_permalink}' class='new_submission_link'>#{create_text}</a>"

      import_path = Routes.submission_imports_path(form_permalink)
      import_text = i18n.t("response_table.no_responses_import_link_text")
      import_link = "<a href='#{import_path}'>#{import_text}</a>"

      return i18n.t("response_table.no_responses_create",
        create_link: create_link,
        import_link: import_link,
        interpolation: { escapeValue: false }
      )
    else
      return i18n.t("response_table.no_responses")

  subscribe: ->
    report_permalink = @table.params.report()

    if report_permalink?
      return i18n.t("response_subscriptions.reports.subscribe", {
        report: @table.table_name
      })
    else
      return i18n.t("response_subscriptions.forms.subscribe", {
        form: @table.table_name
      })

  unsubscribe: ->
    report_permalink = @table.params.report()

    if report_permalink?
      return i18n.t("response_subscriptions.reports.unsubscribe", {
        report: @table.table_name
      })
    else
      return i18n.t("response_subscriptions.forms.unsubscribe", {
        form: @table.table_name
      })

  page_count: (start, finish, total) ->
    if total == 0
      i18n.t("response_table.page_count", {
        start: 0, finish: 0, total: 0
      })
    else
      i18n.t("response_table.page_count", {
        start: start + 1, finish: finish, total: total
      })
