import 'modules/resource_list'


class window.Resource
  constructor: (@element, @list) ->
    @element = $(@element)

    @callbacks = new CallbackManager(@)
    @on = @callbacks.on

    @apply_item_events()

  new_url: -> undefined
  create_url: -> undefined
  edit_url: -> undefined
  update_url: -> undefined
  destroy_url: -> undefined

  new_method: -> "GET"
  create_method: -> "POST"
  edit_method: -> "GET"
  update_method: -> "PATCH"
  destroy_method: -> "DELETE"

  html: -> undefined
  show_modal: -> @modal.show() if @modal?
  hide_modal: -> @modal.hide() if @modal?

  edit_selector: -> ".edit-resource"
  destroy_selector: -> ".delete-resource"
  submit_form_selector: -> "input[type=submit]"

  edit_element: ->
    element = @item_element()
    return unless element?

    selector = @edit_selector()
    element.find(selector).addBack(selector)

  item_destroy_element: ->
    element = @item_element()
    return unless element?

    selector = @destroy_selector()
    element.find(selector).addBack(selector)

  modal_destroy_element: ->
    element = @modal_element()
    return unless element?

    selector = @destroy_selector()
    element.find(selector).addBack(selector)

  item_element: ->
    @element

  modal_element: ->
    return undefined unless @modal?
    $(@modal.container)

  form_element: ->
    @_form_element

  submit_element: ->
    modal_element = @modal_element()
    return unless modal_element?

    modal_element.find(@submit_form_selector())

  persisted: -> (@_persisted == true)

  new: (event) ->
    return $.Deferred().resolve(@modal.show()) if @modal?
    return $.Deferred().reject("No new_url provided") unless @new_url()?

    event.preventDefault() if event?

    return if loading event
    add_loader event

    deferred = $.Deferred()

    $.ajax
      url: @new_url()
      method: @new_method()
    .done (response) =>
      remove_loader event

      @_persisted = false

      @modal = App.Interface.Modal.from_html(response)
      @modal.show()

      @_form_element = $(@modal.container.querySelector("form"))

      @apply_form_events()

      deferred.resolve(@modal)

      @callbacks.trigger("new", @)
    .fail (xhr) ->
      remove_loader event
      App.Errors.response_error(xhr)
      deferred.reject()

    deferred

  create: (event) ->
    return $.Deferred().reject("No create_url provided") unless @create_url()?

    event.preventDefault() if event?

    button = @submit_element()
    return if loading button

    add_loader button

    deferred = $.Deferred()

    data = new FormData(@form_element()[0])

    $.ajax
      url: @create_url()
      method: @create_method()
      processData: false,
      contentType: false,
      data: data
    .done (response) =>
      remove_loader button

      @_persisted = true

      @modal = @modal.destroy()
      @_form_element = undefined

      @refresh_item(response)

      deferred.resolve()

      @callbacks.trigger("create", @)
    .fail (response) =>
      remove_loader button
      apply_errors_to_form(@form_element(), response.responseJSON.errors)
      deferred.reject()

    deferred

  edit: (event) ->
    return $.Deferred().resolve(@modal.show()) if @modal?
    return $.Deferred().reject("No edit_url provided") unless @edit_url()?

    event.preventDefault() if event?

    return if loading event
    add_loader event

    deferred = $.Deferred()

    $.ajax
      url: @edit_url()
      method: @edit_method()
    .done (response) =>
      remove_loader event

      @_persisted = true

      @modal = App.Interface.Modal.from_html(response)
      @modal.show()

      @_form_element = $(@modal.container.querySelector("form"))

      @apply_form_events()

      deferred.resolve(@modal)

      @callbacks.trigger("edit", @)
    .fail (xhr) ->
      remove_loader event
      App.Errors.response_error(xhr)
      deferred.reject()

    deferred

  update: (event) ->
    return $.Deferred().reject("No update_url provided") unless @update_url()?

    event.preventDefault() if event?

    button = @submit_element()
    return if loading button

    add_loader button

    deferred = $.Deferred()

    data = new FormData(@form_element()[0])

    $.ajax
      url: @update_url()
      method: @update_method()
      processData: false,
      contentType: false,
      data: data
    .done (response) =>
      remove_loader button

      @_persisted = true
      @modal = @modal.destroy()

      @refresh_item(response)

      deferred.resolve()

      @callbacks.trigger("update", @)
    .fail (response) =>
      remove_loader button
      apply_errors_to_form(@form_element(), response.responseJSON.errors)
      deferred.reject()

    deferred

  destroy: (event) ->
    return $.Deferred().reject("No destroy_url provided") unless @destroy_url()?

    event.preventDefault() if event?

    return if loading event
    add_loader event.target

    deferred = $.Deferred()

    data = @destroy_data()
    data._destroy = true
    data.authenticity_token = current_user.authenticity_token()



    $.ajax
      url: @destroy_url()
      method: @destroy_method()
      data: data
    .done (response) =>
      remove_loader event.target

      @_persisted = false
      @modal = @modal.destroy()

      @item_element().remove()
      @list._remove(@)

      deferred.resolve()

      @callbacks.trigger("destroy", @)
    .fail (xhr) ->
      remove_loader event.target
      App.Errors.response_error(xhr)
      deferred.reject()

    deferred

  form_data: ->
    element = @form_element()
    return {} unless element
    App.Helpers.Forms.data(element)

  create_data: -> @form_data()
  update_data: -> @form_data()
  destroy_data: -> @form_data()

  refresh_item: (html) ->
    old_element = @item_element()
    @element = $(html)

    old_element.replaceWith @element

    if @element.parent().length == 0
      if @list.new_button_item?
        @element.insertBefore(@list.new_button_item)
      else
        @element.appendTo(@list.list)

    @apply_item_events()

  apply_form_events: ->
    element = @form_element()

    return unless element?

    if @persisted()
      element.on "submit", (e) => @update(e)
      @modal_destroy_element().on "click", (e) => @destroy(e)
    else
      element.on "submit", (e) => @create(e)

  apply_item_events: ->
    element = @item_element()

    return unless element?

    @edit_element().on "click", (e) =>
      e.preventDefault()
      @edit(e)

    @item_destroy_element().on "click", (e) =>
      e.preventDefault()
      @destroy(e)