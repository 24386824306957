
window.App ?= {}
window.App.Events ?= {}
window.App.Events.Submissions ?= {}
window.App.Events.Submissions.Pages ?= {}

window.App.Events.Submissions.Pages.calculate_page_buttons = (submission) ->
  return unless submission.display() == "page"

  if submission.persisted() || submission.pages.count() <= 1
    submission.pages.show_tabs()

    App.Events.Submissions.Pages.toggle_next(submission, false)
    App.Events.Submissions.Pages.toggle_previous(submission, false)
    App.Events.Submissions.Pages.toggle_submit(submission, true)

    return submission

  submission.pages.hide_tabs()

  App.Events.Submissions.Pages.toggle_submit(submission, submission.pages.is_last())
  App.Events.Submissions.Pages.toggle_next(submission, !submission.pages.is_last())
  App.Events.Submissions.Pages.toggle_previous(submission, !submission.pages.is_first())

  submission

window.App.Events.Submissions.Pages.toggle_submit = (submission, status) ->
  button = submission._element.querySelector(".SUBMIT_BUTTON")
  return unless button?

  App.Helpers.Elements.toggle(button, status)

window.App.Events.Submissions.Pages.toggle_next = (submission, status) ->
  button = submission._element.querySelector(".NEXT_FORM_PAGE")
  return unless button?

  App.Helpers.Elements.toggle(button, status)

window.App.Events.Submissions.Pages.toggle_previous = (submission, status) ->
  button = submission._element.querySelector(".PREVIOUS_FORM_PAGE")
  return unless button?

  App.Helpers.Elements.toggle(button, status)

Hotkey.bind ["CTRL", "ALT", "LEFT"],
  if: ->
    return false unless App.current_user.config("modal_toggle_hotkeys")
    submission = App.Models.Submission.visible()
    submission? && submission.pages.any()
  do: ->
    submission = App.Models.Submission.visible()
    submission.pages.previous()

Hotkey.bind ["CTRL", "ALT", "RIGHT"],
  if: ->
    return false unless App.current_user.config("modal_toggle_hotkeys")
    submission = App.Models.Submission.visible()
    submission? && submission.pages.any()
  do: ->
    submission = App.Models.Submission.visible()
    submission.pages.next()

Hotkey.bind ["CTRL", "ALT", /[1-9]/],
  if: ->
    return false unless App.current_user.config("modal_toggle_hotkeys")
    submission = App.Models.Submission.visible()
    submission? && submission.pages.any()
  do: (event) ->
    submission = App.Models.Submission.visible()
    number = event.keyCode - 49

    if submission.pages.count() <= number
      submission.pages.last()
    else
      submission.pages.open(number)

Hotkey.bind ["CTRL", "ALT", "0"],
  if: ->
    return false unless App.current_user.config("modal_toggle_hotkeys")
    submission = App.Models.Submission.visible()
    submission? && submission.pages.any()
  do: ->
    submission = App.Models.Submission.visible()

    if submission.pages.count() <= 9
      submission.pages.last()
    else
      submission.pages.open(9)

$(document).on "click", ".SUBMISSION .FORM_PAGE_TAB", (event) ->
  event.preventDefault()

  tab = event.currentTarget
  field = tab.dataset.field
  element = App.Helpers.Elements.closest(tab, ".SUBMISSION")

  submission = App.Models.Submission.find_locally_by_element(element)
  submission.pages.open(field)

$(document).on "click", ".SUBMISSION .NEXT_FORM_PAGE", (event) ->
  event.preventDefault()

  element = App.Helpers.Elements.closest(event.currentTarget, ".SUBMISSION")

  submission = App.Models.Submission.find_locally_by_element(element)
  submission.pages.next()

$(document).on "click", ".SUBMISSION .PREVIOUS_FORM_PAGE", (event) ->
  event.preventDefault()

  element = App.Helpers.Elements.closest(event.currentTarget, ".SUBMISSION")

  submission = App.Models.Submission.find_locally_by_element(element)
  submission.pages.previous()
