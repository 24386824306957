
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}
window.App.Elements.ResponseTable.Helpers ?= {}

class window.App.Elements.ResponseTable.Helpers.MassUpdate
  constructor: (@table) ->
    @modal = App.Interface.Modal.from_url(
      Routes.bulk_edit_submissions_path(@form().permalink(), display: "modal")
    )

    @modal.on "load", =>
      @modal.id("mass_update_#{App.Helpers.Generators.uuid()}")

      container = @modal.container
      container.classList.add("MASS_UPDATE_SUBMISSION")

      form = App.Models.Form.new(container.dataset.formPermalink)
      @submission = form.submissions.initialize_element(container, modal: @modal)

      # This returns false purposefully to catch and handle any programmatic submission.
      # Only the MassUpdate helper should be able to submit requests.
      @submission.validate =>
        @update_from_element()
        return false

      $(@modal.element).on "submit", "form", (event) ->
        event.preventDefault()

  form: -> @table.form()

  update_from_element: ->
    data = {}
    data.submission = {}

    element = @modal.element.querySelector("form")

    for field in @submission.fields.all()
      continue if field.is_empty()

      data.submission[field.variable_name()] = field.value()

    # Add any options passed programmatically to the `save` method.
    last_options = @submission._last_save_options
    if last_options? && last_options.request? && last_options.request.data?
      for key, value of last_options.request.data
        data[key] = value

    @update(data).then => @modal.unload()

  update: (data = {}) ->
    new Promise (resolve, reject) =>
      @table.selections.load_promised_selections().then (permalinks) =>
        data.authenticity_token = current_user.authenticity_token()
        data.selections = permalinks.join(",")

        $.ajax
          url: Routes.bulk_update_submissions_path(@form().permalink())
          type: "POST"
          data: data
        .done =>
          @table.selections.deselect_all_existing()
          @table.selections.draw()

          @table.refresh()

          resolve()
        .fail reject

  show: (event) ->
    new Promise (resolve, reject) =>
      if App.Helpers.Objects.isEvent(event)
        button = event.currentTarget
        return if loading button
        add_loader button

      @modal.show().then ->
        remove_loader button
        resolve()
      .catch reject
