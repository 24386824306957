
window.App ?= {}
window.App.Schema ?= {}
window.App.Schema.Form ?= {}

class window.App.Schema.Form.Kanban extends App.Schema.Base
  include @, App.Schema.SourceBase

  constructor: (@form_permalink, @data) ->
    @callbacks = new CallbackManager(@)

    if App.is_child
      @_subscribe "update", (response) => @data = response

    undefined

  channel_namespace: -> "kanban.#{@data.permalink}"

  on: (event, callback) -> @callbacks.on(event, callback)

  name: -> @data.name
  permalink: -> @data.permalink
  form: -> App.Models.Form.new(@form_permalink)

  sandboxes: ->
    @_sandboxes ?= new App.Frame.Sandbox.Manager(@, "kanban.#{@permalink()}")
    @_sandboxes

  sandboxes_exist: ->
    @_sandboxes?

  _request_schema: (options) ->
    @_route_get({
      api: { url: @data.urls.self },
      channel: {
        event: "schema.load.kanban",
        data: {
          form: @form_permalink,
          kanban: @permalink()
        }
      },
      options: options
    })

  schema: ->
    source = @_requested_schema(options)
    source.promise ?= new Promise (resolve, reject) =>
      @_request_schema(options).then (response) =>
        @load_data(response, options)
        resolve(@)
      .catch (response) ->
        source.promise = undefined
        reject(response)

    source.promise

  update: (parameters, options) ->
    @_route_update({
      api: {
        url: @data.urls.self,
        data: {
          kanban: App.Schema.Form.Kanban.parameters_to_data(parameters)
        }
      },
      channel: {
        event: "kanban.update",
        data: {
          form: @form_permalink,
          kanban: @permalink(),
          parameters: parameters
        }
      }
      options: options
    }).then =>
      return if App.is_child

      @callbacks.trigger("update", @)
      @form().kanbans.callbacks.trigger("update", @)

      App.Schema.change(@)

      @

  destroy: (options) ->
    @_route_destroy({
      api: {
        url: @data.urls.self
      },
      channel: {
        event: "kanban.destroy",
        data: {
          form: @form_permalink,
          kanban: @permalink(),
        }
      }
      options: options
    }).then =>
      return if App.is_child

      @form().kanbans.reload().then =>
        @callbacks.trigger("destroy", @)
        @form().kanbans.callbacks.trigger("destroy", @)

        App.Schema.change(@)

        App.Models.Form.redirect_on_child_destruction(
          @form(), Routes.kanban_path(@form().permalink(), @permalink())
        )

        @

  @parameters_to_data: (parameters) ->
    output = {}

    if parameters.name?
      output.name = parameters.name

    if parameters.category_field?
      if parameters.category_field instanceof App.Schema.Form.Field
        output.category_field = parameters.category_field.permalink()
      else
        output.category_field = parameters.category_field

    if parameters.name_field?
      if parameters.name_field instanceof App.Schema.Form.Field
        output.name_field = parameters.name_field.permalink()
      else
        output.name_field = parameters.name_field

    if parameters.report?
      if parameters.report instanceof App.Schema.Form.Report
        output.report = parameters.report.permalink()
      else if parameters.report instanceof App.Query.Group
        output.report = parameters.report.serialize()
      else
        output.report = parameters.report

    output
