
$(document).on "input change", ".SUBMISSION input, .SUBMISSION textarea, .SUBMISSION select", (event) ->
  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_column = field_element.dataset.columnName

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)
  return unless submission?

  field = submission.fields.find_by_variable_name(field_column)
  return unless field?

  if field.input_timeout?
    clearTimeout(field.input_timeout)

  field.input_timeout = setTimeout((->
    field.callbacks.trigger("change", field)
  ), 250)

  undefined
