
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}
window.App.Elements.ResponseTable.Helpers ?= {}

class window.App.Elements.ResponseTable.Helpers.Exporting
  constructor: (@table) ->
    
  query: (new_query) ->
    return @table.query if !@_query? && !new_query?
    return @_query if !new_query?

    if !(new_query instanceof App.Query.Group)
      new_query = App.Query.from_top_level_parameters(@table.form(), new_query)

    @_query = new_query

    @save_request_parameters()

    @_query

  reset_query: ->
    @_query = undefined

    @save_request_parameters()

    @table.query

  modal: ->
    return @_modal if @_modal?

    @_modal = App.Interface.Modal.from_url(@table.routes.export())

    # Adds any changed export_names to the export link hrefs in the GET format
    # ?field_id=NewFieldName. This allows clicking export directly, rather than
    # saving, reloading, then clicking export.
    @_modal.on "load", (modal) =>
      for link in modal.element.querySelectorAll(".export_link")
        link.dataset.baseUrl = link.href

      modal.element.addEventListener "keyup", => @save_request_parameters()

    @_modal

  loaded: ->
    return false unless @_modal?
    @_modal.loaded()

  save_request_parameters: ->
    return true unless @_modal? && @_modal.element?

    links = @_modal.element.querySelectorAll(".export_link")
    inputs = @_modal.element.querySelectorAll(".export_input")

    overrides = {}

    for input in inputs
      if input.value? && input.value.length > 0
        overrides[input.dataset.id] = input.value

    for link in links
      export_url = App.Helpers.URIs.parse(link.dataset.baseUrl)
      export_url.set_param("field_overrides[#{id}]", value) for id, value of overrides
      export_url.set_param("where", @query().serialize(urlsafe: true))
      export_url.set_param("order", @table.ordering())
      export_url.set_param("columns", @table.column_parameters())

      link.href = export_url.value()

    true
