import 'app/elements/response_table/modules/column_management'
import 'app/elements/response_table/modules/column_ordering'
import 'app/elements/response_table/modules/column_resizing'
import 'app/elements/response_table/modules/configuration'
import 'app/elements/response_table/modules/elements'
import 'app/elements/response_table/modules/row_drawing'
import 'app/elements/response_table/modules/row_management'
import 'app/elements/response_table/modules/row_toggling'
import 'app/elements/response_table/modules/sticky_headers'
import 'app/elements/response_table/modules/subscriptions'


window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

class window.App.Elements.ResponseTable.Table
  include @, App.Elements.ResponseTable.ColumnManagement
  include @, App.Elements.ResponseTable.ColumnOrdering
  include @, App.Elements.ResponseTable.ColumnResizing
  include @, App.Elements.ResponseTable.Configuration
  include @, App.Elements.ResponseTable.Elements
  include @, App.Elements.ResponseTable.RowDrawing
  include @, App.Elements.ResponseTable.RowManagement
  include @, App.Elements.ResponseTable.RowToggling
  include @, App.Elements.ResponseTable.StickyHeaders
  include @, App.Elements.ResponseTable.Subscriptions

  constructor: (element) ->
    if App.Helpers.Elements.is_jquery(element)
      @element = element[0]
      @$_element = element
    else
      @element = element
      @$_element = $(element)

    @version = 2

    @load_elements()
    @load_configuration()

    @form_permalink = @element.dataset.permalink
    @instance_id = App.Helpers.Generators.uuid()

    @_columns_hash = {}
    @_columns_array = []

    for element in @table_head_element.querySelectorAll(".colhead:not(.fake-cell)")
      @initialize_column(element)

    @params = new App.Elements.ResponseTable.Helpers.Params(@)
    @routes = new App.Elements.ResponseTable.Helpers.Routing(@)
    @modals = new App.Elements.ResponseTable.Helpers.Modals(@)
    @translations = new App.Elements.ResponseTable.Helpers.Translations(@)
    @export = new App.Elements.ResponseTable.Helpers.Exporting(@)
    @query = new App.Query.Group(@form().permalink())
    @conditions = new App.Elements.ResponseTable.Helpers.Conditions(@)

    @generate_colgroup()
    @generate_elements()

    @initialize_resizing()
    @initialize_default_ordering()
    # @initialize_sticky_headers() if @params.fullpage()

    @callbacks = new CallbackManager(@)

    @selections = new App.Elements.ResponseTable.Helpers.Selections(@, {
      visible: !@params.field_exists()
    })

    App.Elements.ResponseTable.Column.generate_context_menus(@)

    @on "refresh", (response) =>
      @count_badge(response.recordsTotal)
      @page_badge(@params.page(), @params.length(), response.recordsTotal)

      if @has_form_permission("read")
        App.Elements.ResponseTable.Helpers.Conditions.draw_conditions(@)

      App.Elements.ResponseTable.Helpers.update_resource_counters(@, response.recordsTotal)

    @refresh(true)

    PubSub.subscribe("submission.updated.#{@form().permalink()}", => @refresh())
    PubSub.subscribe("submission.created.#{@form().permalink()}", => @refresh())
    PubSub.subscribe("submission.destroyed.#{@form().permalink()}", => @refresh())
    PubSub.subscribe("submission.restored.#{@form().permalink()}", => @refresh())
    PubSub.subscribe("submission.mass_updated", => @refresh())

    PubSub.subscribe("submission_data.change", => @refresh())

    for column in @columns()
      column.initialize_sorting()

    if @params.fullpage()
      App.context(@element).menu().filter_option(i18n.t("filter"), @query_editor())

      if @has_permission("manage")
        App.context(@element).menu().option(i18n.t("workflows_editor"), {
          id: "open_workflow_editor",
          icon: "shuffle",
          href: Routes.form_workflows_path(@form_permalink)
        })

  form: -> App.Models.Form.new(@form_permalink)

  on: (event, callback) -> @callbacks.on(event, callback)

  total_count: ->
    @_total_count

  visible_count: ->
    @table_body_element.querySelectorAll("tr").length

  count_badge: (value) ->
    @_total_count = value

    table_name = @element.querySelector(".table_name")

    return unless table_name?

    badge = table_name.querySelector(".table_count_inner")

    if badge?
      badge.textContent = value
    else
      badge_outer = document.createElement("DIV")
      badge_outer.className = "table_count"
      badge_outer.innerHTML = "<span class='table_parens_open'>(</span><span class='table_count_inner'></span><span class='table_parens_close'>)</span>"

      badge = badge_outer.querySelector(".table_count_inner")
      badge.textContent = value

      table_name.appendChild(badge_outer)

    badge

  page_badge: (page, page_length, total_records) ->
    page_start = page * page_length
    page_end = (page + 1) * page_length
    page_end = total_records if page_end > total_records

    @page_count_element.innerHTML = @translations.page_count(page_start, page_end, total_records)

    if @previous_page_buttons?
      for button in @previous_page_buttons
        button.classList.toggle("disabled", page_start == 0)

    if @next_page_buttons?
      for button in @next_page_buttons
        button.classList.toggle("disabled", page_end == total_records)

    undefined

  has_permission: (perm) ->
    perm = "edit_form" if perm == "manage"
    @element.getAttribute("data-" + perm.replace("_", "-")) == "true"

  has_form_permission: (perm) ->
    perm = "edit_form" if perm == "manage"
    @element.getAttribute("data-form-permission-" + perm.replace("_", "-")) == "true"

  closed: ->
    @element.dataset.closed == "true"

  frozen: ->
    @_frozen == true

  freeze: ->
    @_frozen = true

  refresh: (initial_load = false) ->
    return Promise.reject() if @frozen()

    if typeof initial_load == "object"
      options = initial_load
      initial_load = options.initial || false
    else
      options = {}

    if options.rate_limit && @_last_refresh?
      current_time = App.Helpers.Times.unix()

      if options.rate_limit == true
        timeout = 10
      else
        timeout = options.rate_limit

      timeout_released = @_last_refresh + timeout

      return Promise.resolve() if current_time < timeout_released

      @_last_refresh = current_time
    else
      @_last_refresh = App.Helpers.Times.unix()

    @increment_draw_count()

    $.ajax
      url: @routes.data(),
      data: JSON.stringify(@params.serialize()),
      contentType: "application/json; charset=utf-8",
      type: "POST"
    .done (response) =>
      @load_data(response)

      if initial_load
        App.Helpers.Elements.PageRows.reflow(
          App.Helpers.Elements.closest(@element, ".main_view_page")
        )

        PubSub.publish("response_table.ready", { table: @ })

      @callbacks.trigger("refresh", response)

  custom_sorting: ->
    @form().custom_sorting

  query_editor: ->
    return @_query_editor if @_query_editor?

    @_query_editor ?= App.Elements.ResponseQuery.from_table(@)

    @_query_editor

  query_editor_loaded: ->
    @_query_editor?

  menu: (options) ->
    return unless options?
    @_custom_menu_options ?= []
    @_custom_menu_options.push(options)

  apply_format: (format, row, cell, index) ->
    switch format.element
      when "column"
        null
      when "row"
        cells = row.querySelectorAll("td:not(.row_select_td)")
        cell_format = Object.assign({}, format)
        cell_format.element = "cell"

        for cell, index in cells
          @apply_format(cell_format, row, cell, index)

      when "cell"
        cell.style.backgroundColor = format.colors.background
        cell.style.borderColor = format.colors.border
      when "border"
        cell.style.border = "1px solid #{format.colors.border}"
        cell.style.borderTop = "none" unless index == 0
      when "text"
        cell.style.color = format.colors.foreground

    undefined

# Backwards compatibility
window.ResponseTable = App.Elements.ResponseTable.Table
