
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentPost ?= {}
window.App.Elements.AttachmentPost.Helpers ?= {}

class window.App.Elements.AttachmentPost.Helpers.Routing
  constructor: (@post) ->

  read: ->
    if @post.fullscreen()
      Routes.fullpage_post_path(@post.permalink())
    else
      Routes.post_path(@post.permalink())

  reader: ->
    Routes.post_content_path(@post.permalink())

  edit: ->
    if @post.permalink()?
      if @post.fullscreen()
        Routes.fullpage_edit_post_path(@post.permalink())
      else
        Routes.edit_post_path(@post.permalink())
    else
      Routes.new_post_path()

  editor: ->
    if @post.permalink()?
      Routes.post_editor_path(@post.permalink())

  files: ->
    if @post.folder_permalink()?
      Routes.attachment_folder_path(@post.folder_permalink())
    else
      Routes.facility_files_path()

  history: ->
    Routes.post_versions_path(@post.permalink())

  save: ->
    if @post.permalink()?
      Routes.update_post_path(@post.permalink())
    else
      Routes.create_post_path()

  destroy: ->
    Routes.delete_post_path(@post.permalink())

  restore: ->
    Routes.restore_post_path(@post.permalink())
