
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}
window.App.Elements.ResponseTable.Helpers ?= {}

class window.App.Elements.ResponseTable.Helpers.Modals
  constructor: (@table) ->
    @_models = {}

  settings: ->
    if !@_models.settings?
      @_models.settings = App.Interface.Modal.from_url(@table.routes.settings())
    
      @_models.settings.on "load", (modal) ->
        column_list = modal.element.querySelector(".field_column_options")
        column_positions = []

        return unless column_list?

        Sortable.create(column_list, {
          sort: true,
          draggable: ".field_column_option",
          ghostClass: "sortable-placeholder",
          chosenClass: "sortable-chosen"
        });

      @_models.settings.on "submit", (event) =>
        event.preventDefault()

        data = App.Helpers.Forms.data(@_models.settings.element)
        data = @_format_table_settings_data(data)

        @table.update_columns(data)

    @_models.settings

  _format_table_settings_data: (data) ->
    output = []

    for key, index in data.raw.column_positions
      output.push({
        id: data.raw.columns[key],
        permalink: data.raw.columns[key],
        visible: data.raw.column_visibilities[key],
        position: index
      })

    output
