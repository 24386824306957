
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseKanban ?= {}

class window.App.Elements.ResponseKanban.ColumnManager
  constructor: (@kanban) ->
    @_categories = {}

    for element in @kanban.kanban_element.querySelectorAll(".kanban_category")
      @initialize_element(element)

  all: -> Object.values(@_categories)
  any: -> @all().length > 0

  find: (category) -> @_categories[category]

  initialize_element: (element) ->
    return @_categories[category_name] if @_categories[category_name]?

    category_name = element.dataset.category

    @_categories[category_name] = new App.Elements.ResponseKanban.Column(@kanban, element)

  empty: ->
    for column in @all()
      column.empty()

    undefined

  save_order: (changed_category_values) ->
    new Promise (resolve, reject) =>
      active_categories = []
      categories = []

      # Optionally update only specific columns
      if changed_category_values?
        for column in @all()
          if changed_category_values.indexOf(column.value()) != -1
            categories.push({
              value: column.value()
              items: column.permalinks()
            })

          active_categories.push(column.value())
      else
        for column in @all()
          categories.push({
            value: column.value(),
            items: column.permalinks()
          })

          active_categories.push(column.value())

      $.ajax
        url: @kanban.routes.save_order()
        type: "PATCH"
        data: {
          authenticity_token: current_user.authenticity_token()
          kanban: {
            active: active_categories,
            categories: categories
          }
        }
      .done (response) -> resolve()
      .fail (response) -> reject(response)
