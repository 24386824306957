
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseQuery ?= {}

window.App.Elements.ResponseQuery.from_table = (table) ->
  editor_query = table.query.clone()

  if table.has_form_permission("read") && table.params.report()?
    table.form().schema(deleted_fields: true).then (form) ->
      form.reports.schema().then (reports) ->
        report = reports.find_by_permalink(table.params.report())
        report.schema().then (report) ->
          editor_query.prepend(report.query())
          query.report = report
          query.update_editor(false)
          query.modal.actions App.Elements.ResponseQuery.Query.generate_modal_actions(query)
          query.callbacks.trigger("recount")

  existing_query = table.params.initial_query_editor()
  existing_query = App.Elements.ResponseQuery._models[existing_query] if existing_query?

  if existing_query?
    query = existing_query
    query.query = table.query
    query.editor_query = editor_query
    query.update_editor()
  else
    query = new App.Elements.ResponseQuery.Query(table.form(), table.query, editor_query)
  
  query.on "change", => table.refresh()

  query.on "report.create", =>
    App.redirect_to Routes.report_path(
      table.form().permalink(),
      query.report.permalink(),
      query: query.instance_id
    )

    query.query.clear()
    query.update_editor()
    table.refresh()

  query
