
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Helpers ?= {}
window.App.Models.Submission.Helpers.Generators ?= {}

window.App.Models.Submission.Helpers.Generators.assign_link_attributes = (submission, button) ->
  return if submission._assigned_link_attributes
  submission._assigned_link_attributes = true

  connection_type = button.getAttribute("data-connection-type")

  if connection_type?
    connection_form_permalink = button.getAttribute("data-connection-form")
    connection_field_permalink = button.getAttribute("data-connection-field")
    connection_submission_permalink = button.getAttribute("data-connection-submission")

    if connection_type == "incoming"
      connection_field = submission.fields.find_by_permalink(connection_field_permalink)
      connection_field.value(connection_submission_permalink)
    else
      submission.after_create ->
        connection_form = Form.new(connection_form_permalink)
        connection_form.submissions.load(connection_submission_permalink).then (connection_submission) ->
          connection_field = connection_submission.fields.find_by_permalink(connection_field_permalink)
          connection_field.helpers.append(submission)

          connection_submission.save()
          App.Models.Submission.Helpers.Generators.refresh_button_table(submission, button)

  field_values = App.Models.Submission.Field.decode_preset_data(button.dataset)

  for name, value of field_values
    field = submission.fields.find_by_field_id(name)
    field ?= submission.fields.find_by_column_name(name)

    field.value(value) if field?

  App.Models.Submission.Helpers.Generators.refresh_button_table(submission, button)

  submission

window.App.Models.Submission.Helpers.Generators.refresh_button_table = (submission, button) ->
  if button.classList.contains("table_button")
    table_element = App.Helpers.Elements.closest(button, ".table_container")
    table = App.tables.find_by_element(table_element)
    table.callbacks.trigger("submission.new", submission) if table?

  undefined
