
window.App ?= {}
window.App.Schema ?= {}

window.App.Schema.SourceCollectionBase = {
  # Subclasses will need these methods defined
  # model_class: -> @
  # model_instance: (data) -> new @(data)

  models: (options) ->
    source = @_requested_schema(options)
    source.collection.models()

  new: (data, options) ->
    source = @_requested_schema(options)
    source.collection.new(data)

  push: ->
    source = @_requested_schema()
    source.collection.push(value) for value in arguments
    source.collection.models().length

  _value_to_model: (data, options) ->
    return unless data?
    return data if data instanceof @model_class()

    @value_to_model(data)

  value_to_model: (data, options) ->
    source = @_requested_schema(options)
    source.collection.value_to_model(data)

  find: (data, options) ->
    source = @_requested_schema(options)
    source.collection._value_to_model(data)

  all: (filter, options) ->
    if filter? && typeof filter != "function"
      options = filter
      filter = undefined

    source = @_requested_schema(options)
    source.collection.all(filter)

  any: (filter) ->
    if filter? && typeof filter != "function"
      options = filter
      filter = undefined

    source = @_requested_schema(options)
    source.collection.any(filter)

  first: (options) ->
    @require_schema(options) if @require_schema?
    @all(options)[0]

  second: (options) ->
    @require_schema(options) if @require_schema?
    @all(options)[1]

  third: (options) ->
    @require_schema(options) if @require_schema?
    @all(options)[2]

  last: (options) ->
    @require_schema(options) if @require_schema?

    values = @all(options)
    values[values.length - 1]

  count: (options) ->
    new Promise (resolve) =>
      @schema(options).then =>
        source = @_requested_schema(options)
        resolve(source.length)

  indexOf: (value, options) ->
    model = @_value_to_model(value, options)
    return -1 unless model?

    source = @_requested_schema(options)
    source.collection.models.indexOf(model)

  splice: ->
    source = @_requested_schema()
    source.collection.splice.apply(source.collection, arguments)

  contains: (value, options) ->
    @_value_to_model(value, options)?

  pop: (options) ->
    source = @_requested_schema(options)
    source.collection.pop()

  shift: (options) ->
    source = @_requested_schema(options)
    source.collection.shift()

  unshift: ->
    source = @_requested_schema()
    source.collection.unshift.apply(source.collection, arguments)

  sort: (method, options) ->
    source = @_requested_schema(options)
    source.collection.sort(method)

  reverse: (options) ->
    source = @_requested_schema(options)
    source.collection.reverse()

}
