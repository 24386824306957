
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseCalendar ?= {}
window.App.Elements.ResponseCalendar.Helpers ?= {}

# Naming this Routes conflicts with the top-level JS-Routes plugin.
class window.App.Elements.ResponseCalendar.Helpers.Routing
  constructor: (@period) ->

  data: ->
    Routes.calendar_data_path(
      @period.form().permalink(),
      @period.permalink(),
      year: @period.params.year(),
      month: @period.params.month(),
      week: @period.params.week()
    )

  repeat_event: (submission_permalink, period) ->
    Routes.repeat_event_path(
      @period.form().permalink(),
      @period.permalink(),
      submission_permalink,
      period
    )
