
window.App ?= {}
window.App.Events ?= {}
window.App.Events.Submissions ?= {}
window.App.Events.Submissions.MultipleChoices ?= {}

window.App.Events.Submissions.MultipleChoices.preload_response_options = (form_permalink, field_permalink) ->
  Form.new(form_permalink).schema(deleted_fields: true).then (form) ->
    field = form.fields.find_by_permalink(field_permalink, deleted: true)
    field.schema() if field? && (field.field_type() == "dropdown" || field.field_type() == "boolean")

  undefined

window.App.Events.Submissions.MultipleChoices.set_option = (field, submission, value) ->
  selections = submission.field(field) || []
  previously_selected = selections.indexOf(value) != -1

  if field.data.multiple
    if previously_selected
      selections.splice(selections.indexOf(value), 1)
    else
      selections.push(value)
  else
    if previously_selected
      selections = []
    else
      selections = [value]

  # Re-enable in the future once the attachment and checkbox resetting bugs have been worked out
  # Change to only modifying the individual column rather than resetting all columns to the passed values.
  # submission_editor = submission.form().submissions.find_locally(submission.permalink())

  # if submission_editor
  #   submission_field = submission_editor.fields.find_by_permalink(field.permalink())
  #   submission_field.value(selections)

  # submission.field(field, selections)
  # submission.save()

  new Promise (resolve, reject) ->
    submission.submission().then (model) ->
      submission_field = model.fields.find_by_permalink(field.permalink())
      submission_field.value(selections)

      model.save()
      .then resolve
      .catch reject
    .catch reject

window.App.Events.Submissions.MultipleChoices.generate_menu_options = (field, submission, element) ->
  menu_options = []
  field_options = App.Events.Submissions.MultipleChoices.generate_field_options(field)
  selections = submission.field(field) || []

  permalinks = {
    form: field.form().permalink()
    submission: submission.permalink(),
    field: field.permalink()
  }

  for field_option in field_options
    if Array.isArray(field_option)
      text = field_option[0]
      value = field_option[1]
      color = field_option[2]
    else
      text = field_option
      value = field_option
      color = undefined

    definition = {}
    definition.text = text
    definition.class = "highlight-#{color}" if color?

    if field.data.multiple == true
      definition.refresh_on_click = true

      if selections.indexOf(text) != -1
        definition.icon = "dot"
        definition.icon_color = "teal"

    definition.callback = ((element, field, submission, value) -> ->
      App.Events.Submissions.MultipleChoices.set_option(field, submission, value)

      @_set_actions App.Events.Submissions.MultipleChoices.generate_menu_options(field, submission, element)
    )(element, field, submission, value)

    menu_options.push(definition)

  menu_options

window.App.Events.Submissions.MultipleChoices.generate_field_options = (field) ->
  switch field.field_type()
    when "boolean"
      field_options = [["Yes", true], ["No", false], ["None", ""]]
    when "dropdown"
      field_options = []

      for field_option in field.data.dropdown.options
        continue if field_options.length >= 10
        field_options.push [field_option.name, field_option.name, field_option.color]

  field_options

window.App.Events.Submissions.MultipleChoices.open_response_options = (element, form_permalink, submission_permalink, field_permalink) ->
  form = Form.new(form_permalink)

  submission_promise = form.submissions.query_by_permalink(submission_permalink)

  new Promise (resolve, reject) ->
    form.schema(deleted_fields: true).then (form) ->
      field = form.fields.find_by_permalink(field_permalink, deleted: true)

      if !field? || !(field.field_type() == "dropdown" || field.field_type() == "boolean")
        return remove_loader(element)

      Promise.all([submission_promise, field.schema()]).then (responses) ->
        remove_loader element

        submission = responses[0]
        menu_options = []

        menu_options = App.Events.Submissions.MultipleChoices.generate_menu_options(field, submission, element)

        menu = new App.Interface.ContextMenu.Object(menu_options)
        menu.element = element
        menu.config.small = true

        coords = element.getBoundingClientRect()
        menu.show(position: { x: coords.left, y: coords.top })

        resolve(menu)
      .catch (error) ->
        remove_loader(element)
        throw error

$(document).on "click", "table .response_option, table .response_option_empty", (event) ->
  event.preventDefault()

  option = event.currentTarget

  return if loading option
  add_loader option

  cell = App.Helpers.Elements.closest(option, "td")
  row = cell.parentNode
  header = App.Helpers.Tables.th_from_td(cell)
  table = App.Helpers.Elements.closest(row, "table")

  form_permalink = table.dataset.permalink
  submission_permalink = row.dataset.permalink
  field_permalink = header.dataset.columnPermalink

  return unless form_permalink? && form_permalink.length > 0
  return unless submission_permalink? && submission_permalink.length > 0
  return unless field_permalink? && field_permalink.length > 0

  App.Events.Submissions.MultipleChoices.open_response_options(
    option, form_permalink, submission_permalink, field_permalink
  )

$(document).on "click", ".view_submission .response_option, table .response_option_empty", (event) ->
  event.preventDefault()

  option = event.currentTarget

  return if loading option
  add_loader option

  row_value = App.Helpers.Elements.closest(option, ".row-value")
  container = App.Helpers.Elements.closest(row_value, ".view_submission")

  return unless container?

  form_permalink = container.dataset.formPermalink
  submission_permalink = container.dataset.submissionPermalink
  field_permalink = row_value.dataset.permalink

  return unless form_permalink? && form_permalink.length > 0
  return unless submission_permalink? && submission_permalink.length > 0
  return unless field_permalink? && field_permalink.length > 0

  App.Events.Submissions.MultipleChoices.open_response_options(
    option, form_permalink, submission_permalink, field_permalink
  )

$(document).on "mouseover", "table .response_option, table .response_option_empty", (event) ->
  return unless App.windowing_enabled()

  option = event.currentTarget

  cell = App.Helpers.Elements.closest(option, "td")
  row = cell.parentNode
  header = App.Helpers.Tables.th_from_td(cell)
  table = App.Helpers.Elements.closest(row, "table")

  form_permalink = table.dataset.permalink
  field_permalink = header.dataset.columnPermalink

  return unless form_permalink? && form_permalink.length > 0
  return unless field_permalink? && field_permalink.length > 0

  App.Events.Submissions.MultipleChoices.preload_response_options(
    form_permalink, field_permalink
  )

$(document).on "mouseover", ".view_submission .response_option, table .response_option_empty", (event) ->
  return unless App.windowing_enabled()

  option = event.currentTarget

  row_value = App.Helpers.Elements.closest(option, ".row-value")
  container = App.Helpers.Elements.closest(row_value, ".view_submission")

  return unless container?

  form_permalink = container.dataset.formPermalink
  field_permalink = row_value.dataset.permalink

  return unless form_permalink? && form_permalink.length > 0
  return unless field_permalink? && field_permalink.length > 0

  App.Events.Submissions.MultipleChoices.preload_response_options(
    form_permalink, field_permalink
  )
