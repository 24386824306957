
window.App ?= {}
window.App.Schema ?= {}
window.App.Schema.Form ?= {}

class window.App.Schema.Form.Procedure extends App.Schema.Base
  include @, App.Schema.SourceBase

  constructor: (@form_permalink, @data) ->
    undefined

  name: -> @data.name
  permalink: -> @data.permalink
  endpoint: -> @data.endpoint
  form: -> App.Models.Form.new(@form_permalink)

  url: ->
    current_url = App.Helpers.URIs.current()

    if window.location.host.includes("staging.sonadier")
      current_url.subdomain(current_url.subdomain() + "-scripts.staging")
    else
      current_url.subdomain(current_url.subdomain() + "-scripts")

    current_url.path(Routes.execute_procedure_path(@form().permalink(), @endpoint()))
    current_url.value()

  get_requests: ->
    @require_schema()
    @data.get_requests

  interval: ->
    @require_schema()
    @data.interval

  run: (submission_or_data, data = {}) ->
    options = App.Helpers.Arguments.overload(
      submission_or_data, data, [
        [["submission", App.Models.Submission.Object], ["data", Object]],
        [["data", Object]]
      ]
    )

    if options.submission? && !options.submission.persisted()
      throw new Error("unsaved submissions cannot be referenced in procedures")

    new Promise (resolve, reject) =>
      if options.submission?
        if options.submission instanceof App.Models.Submission.Object
          data.submission = options.submission.permalink()
        else if options.submission instanceof App.Schema.Submission
          data.submission = options.submission.permalink()
        else
          data.submission = options.submission

      if App.is_child
        App.parent.publish("procedure.run", {
          form_permalink: @form_permalink,
          endpoint: @endpoint(),
          parameters: data
        })
        .then resolve
        .catch reject
      else
        data.authenticity_token = current_user.authenticity_token()

        $.ajax
          url: @url()
          type: "POST"
          data: data
        .done resolve
        .fail (response) -> reject(response.responseJSON)

  _request_schema: (options) ->
    @_route_get({
      api: { url: @data.urls.self },
      channel: {
        event: "schema.load.procedure",
        data: {
          form: @form_permalink,
          procedure: @permalink()
        }
      },
      options: options
    })

  schema: (options) ->
    source = @_requested_schema(options)
    source.promise ?= new Promise (resolve, reject) =>
      @_request_schema(options).then (response) =>
        @load_data(response, options)
        resolve(@)
      .catch (response) ->
        source.promise = undefined
        reject(response)

    source.promise

  require_schema: ->
    if !@loaded()
      throw new Error("method requires procedure.load() to be called and resolved")

  destroy: (options) ->
    @_route_destroy({
      api: {
        url: @data.urls.self
      },
      channel: {
        event: "procedure.destroy",
        data: {
          form: @form_permalink,
          procedure: @permalink(),
        }
      }
      options: options
    }).then =>
      return if App.is_child

      @form().procedures.reload()

  destroy: ->
    new Promise (resolve, reject) =>
      if App.is_child
        App.parent.publish("procedure.destroy", form: @form_permalink, procedure: @permalink())
        .then resolve
        .fail reject
      else
        $.ajax
          url: @data.urls.self
          type: "DELETE"
          data: { authenticity_token: current_user.authenticity_token() }
        .done => resolve(@)
        .fail (response) -> reject(response.responseJSON)
