
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.Upload ?= {}

class window.App.Elements.Upload.Object
  constructor: (@input) ->
    @input = $(@input) if @input instanceof HTMLElement

    @form = @input.closest(".file_upload_form")

    @folder_permalink = @input.attr("data-folder")

    @label = @form.find("label[for=#{@input.attr("id")}]")

    @input.on "change", (e) => @upload(e)

  token: ->
    App.Helpers.Forms.data(@form).authenticity_token

  progress: (index, progress) ->
    @upload_progress ||= {}
    @upload_progress[index] = progress

    progress_total = 0
    progress_current = 0

    for i, current of @upload_progress
      progress_total += 1
      progress_current += current

    value = (progress_current / progress_total) * 100
    if value == 100
      value = 0

    @label.find(".label_progress").css("width", value + "%")

  reset_progress: ->
    @upload_progress = {}

  upload: (event) ->
    event.stopPropagation()
    event.preventDefault()

    replace = undefined
    if @input.attr("data-replace")? && @input.attr("data-replace") != ""
      replace = @input.attr("data-replace")

    return if @label && @label.hasClass("loading")
    add_page_loader()
    @label.addClass("loading")

    @reset_progress()

    upload_requests = []

    for file, i in @input[0].files
      replaced_file = if i == 0 then replace else undefined
      upload_requests.push @upload_single_file(file, replaced_file)

    $.when.apply($, method for method in upload_requests).then =>
      @form[0].reset()
      remove_page_loader()
      @label.removeClass("loading")

  upload_single_file: (file, replace = undefined) ->
    deferred = $.Deferred()

    data = new FormData()
    data.append("authenticity_token", @token())
    data.append("file[upload]", file)
    data.append("file[folder]", @folder_permalink)
    data.append("file[replace]", replace) if replace?

    instance = App.Helpers.Generators.uuid()

    $.ajax
      url: Routes.upload_file_path(format: "json")
      type: "POST"
      data: data
      cache: false
      dataType: "json"
      processData: false
      contentType: false
      xhr: =>
        @upload_progress[instance] = 0

        xhr = new window.XMLHttpRequest()
        xhr.upload.addEventListener "progress", ((index) =>
          (event) =>
            if event.lengthComputable
              current_progress = event.loaded / event.total
            else
              current_progress = 1

            @progress(index, current_progress)
        )(instance)

        xhr
    .done (response) =>
      folder_name = @folder_permalink
      folder_name = "home" if !@folder_permalink? || @folder_permalink == "nil"
      PubSub.publish("file.uploaded.#{folder_name}", response)

      deferred.resolve()
    .fail (xhr) =>
      App.Errors.xhr_error(xhr)
      deferred.reject()

    deferred.promise()

App.Models.bind ".file_upload_form .file_upload_button", App.Elements.Upload.Object
