
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentTable ?= {}
window.App.Elements.AttachmentTable.Helpers ?= {}

class window.App.Elements.AttachmentTable.Helpers.Params
  constructor: (@table) ->

  url_data: ->
    output = {}
    output.format = "json"

    if @folder()?
      output.folder = @folder()

    if @deleted()
      output.deleted = @deleted()

    output.start = @page() * @length()
    output.length = @length()

    output

  folder: ->
    folder_permalink = @table.element.dataset.folder
    return if !folder_permalink? || folder_permalink.length == 0
    folder_permalink

  folder_or_home: ->
    @folder() || "home"

  form: ->
    form_permalink = @table.element.dataset.form
    return if !form_permalink? || form_permalink.length == 0
    form_permalink

  default_length: ->
    100

  page: (value) ->
    if value?
      value = 0 if value < 0
      @_page_number = value

      return @table.refresh()

    return 0 unless @_page_number

    @_page_number

  length: (value) ->
    value = value[0] if value? && App.Helpers.Elements.is_jquery(value)

    # If the jQuery element matches no objects, value[0] will be null
    if value?
      # Search with Element
      if value.nodeType
        value.addEventListener("input", ((event) =>
          @_page_length = parseInt(event.currentTarget.value)
          @table.refresh()
        ), false)
      else
        @_page_length = parseInt(value)
        @table.refresh()

    return @default_length() unless @_page_length?

    @_page_length

  search: (value) ->
    value = value[0] if value? && App.Helpers.Elements.is_jquery(value)

    # If the jQuery element matches no objects, value[0] will be null
    if value?
      # Search with Element
      if value.nodeType
        value.addEventListener("input", ((event) =>
          @_search_value = event.currentTarget.value
          clearTimeout(@_search_timeout)
          @_search_timeout = setTimeout((=> @table.refresh()), 50)
        ), false)
      else
        @_search_value = value
        @table.refresh()

    @_search_value

  deleted: ->
    deleted = @table.element.dataset.deleted
    return false if !deleted? || deleted != "true"
    true

  order: (value) ->
    @table._ordering = value if value?
    return unless @table._ordering?

    output = []

    for order in @table._ordering
      output.push({
        column: order.column.column_name
        dir: order.direction
      })

    output

  serialize: ->
    data = {}
    data.draw = @table.draw_count
    data.start = @page() * @length()
    data.length = @length()
    data.authenticity_token = current_user.authenticity_token()

    order_value = @order()
    data.order = order_value if order_value?

    search_value = @search()

    if search_value?
      data.search = {
        value: search_value,
        regex: search_value instanceof RegExp
      }

    data