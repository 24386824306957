
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.Button
  @_generate_counter: (button) ->
    counter = button.querySelector(".run_counter")
    return counter if counter?

    counter = document.createElement("SPAN")
    counter.className = "run_counter"
    counter.innerHTML = " (<span class='count'>0</span>)"

    button.appendChild(counter)

    counter

  @_increment_counter: (button) ->
    counter = App.Models.Submission.Field.Helpers.Button._generate_counter(button)

    count = counter.querySelector(".count")
    old_count = parseInt(count.textContent)
    new_count = old_count + 1

    count.textContent = new_count

    new_count

  constructor: (@field) ->

  trigger: (increment_counter = false) ->
    new Promise (resolve, reject) =>
      @field.callbacks.trigger("button.click", @field)

      if @field.submission?
        @field.submission.fields.callbacks.trigger("button.click", @field)

      if @is_procedure()
        @trigger_procedure()
        .then (response) =>
          @_increment_counter() if increment_counter
          resolve(response)
        .catch reject
      else
        @_increment_counter() if increment_counter
        resolve()

  trigger_procedure: (data) ->
    return Promise.reject() unless @field.submission?
    endpoint = @button_element().dataset.procedure
    @field.submission.procedure(endpoint, data)

  button_element: ->
    @field._element.querySelector(".submission_button")

  is_procedure: ->
    @button_element().dataset.procedure?

  _increment_counter: ->
    App.Models.Submission.Field.Helpers.Button._increment_counter(
      @button_element()
    )
