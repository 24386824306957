
window.App ?= {}
window.App.Objects ?= {}

class window.App.Objects.URL
  @current: ->
    App.Objects.URL.parse(window.location.href)

  @parse: (value) ->
    return value if value instanceof App.Objects.URL
    new App.Objects.URL(value)

  @split_search_brackets: (key) ->
    key.split("[").map((val) =>
      loc = val.indexOf("]")
      if loc != -1 then val.slice(0, loc) else val
    )

  @transform_numeric_search: (result) ->
    return result if typeof result != "object"

    for key, value of result
      continue if typeof value != "object"
      continue if Object.keys(value).some(isNaN)

      array = []

      for subkey, subvalue of value
        array[parseInt(subkey)] = App.Objects.URL.transform_numeric_search(subvalue)

      result[key] = array

    result

  @parse_search: (search) ->
    return {} if !search? || search == ""

    result = {}

    search = search.substr(1) if search[0] == "?"
    pairs = search.split("&")

    for set in pairs
      array = set.split("=")

      key = array.shift()
      value = decodeURIComponent(array.join("="))

      value = true if value == "true"
      value = false if value == "false"

      if key.indexOf("[") == -1
        result[key] = value
      else
        subkeys = App.Objects.URL.split_search_brackets(key)
        subresult = result

        for subkey, index in subkeys
          if index == subkeys.length - 1
            subresult[subkey] = value
          else
            subresult[subkey] ?= {}
            subresult = subresult[subkey]

    result = App.Objects.URL.transform_numeric_search(result)

    result

  @equivalent: (first, second) ->
    first = App.Objects.URL.parse(first)
    first.search = undefined

    second = App.Objects.URL.parse(second)
    second.search = undefined

    first.equals(second, true)

  @attributes: ["protocol", "hostname", "port", "pathname", "search", "hash", "host"]

  @load_attribute_from_anchor = (url, anchor, name, allowed) ->
    if !allowed? || allowed.indexOf(name) != -1
      if anchor[name]? && anchor[name].length > 0
        url[name] = anchor[name]
      else
        url[name] = null

  @load_from_string = (url, string, allowed) ->
    anchor = document.createElement("a")
    anchor.href = string
    anchor

    # Most modern browsers will return null for empty anchor properties,
    # but IE11 returns an empty string.

    for attribute in App.Objects.URL.attributes
      App.Objects.URL.load_attribute_from_anchor(url, anchor, attribute, allowed)

    url

  constructor: (string) ->
    App.Objects.URL.load_from_string(@, string)

  params: (key, value) ->
    parameters = App.Objects.URL.parse_search(@search)
    return parameters unless key?
    return parameters[key] unless value?
    @set_param(key, value)

  set_param: (key, value) ->
    parameters = App.Objects.URL.parse_search(@search)
    parameters[key] = value
    delete parameters[key] unless value?

    serialize = decodeURIComponent(App.Helpers.Parameters.serialize(parameters))
    @search = if serialize.length > 0 then "?" + serialize else ""

    parameters

  domain: (value) ->
    if value?
      fragments = (@subdomain() || "").split(".")
      fragments.push(value)

      @hostname = fragments.join(".")

    return unless @hostname?

    domain_fragments = @hostname.split(".")
    domain_fragments.slice(-2).join(".")

  subdomain: (value) ->
    if value?
      fragments = (@domain() || "").split(".")
      fragments.unshift(value) if value.length > 0

      @hostname = fragments.join(".")

    return unless @hostname?

    domain_fragments = @hostname.split(".")
    domain_fragments.pop()
    domain_fragments.pop()
    domain_fragments.join(".")

  value: (options = {}) ->
    site = @site(options) || ""
    path = @path(options) || ""

    return "" if site.length == 0 && path.length == 0
    return "/#{path}" if site.length == 0

    site + path

  static_value: (options = {}) ->
    @static_site(options) + @static_path(options)

  site: (options = {}) ->
    if options? && App.Helpers.Objects.isString(options)
      App.Objects.URL.load_from_string(@, options, ["protocol", "hostname", "port"])
      options = {}

    output = ""
    output += @protocol + "//" if @protocol? && options.protocol != false
    output += @hostname if @hostname? && options.hostname != false
    output += ":" + @port if @port? && @port.length > 0 && options.port != false
    output

  path: (options = {}) ->
    if options? && App.Helpers.Objects.isString(options)
      App.Objects.URL.load_from_string(@, options, ["pathname", "search", "hash"])
      options = {}

    output = ""
    output += @pathname if @pathname? && options.pathname != false
    output += @search if @search? && options.search != false
    output += @hash if @hash? && options.hash != false
    output

  static_site: (options = {}) ->
    @site(options)

  static_path: (options = {}) ->
    output = ""
    output += @pathname if @pathname? && options.pathname != false

    if @search? && @search.length > 0 && options.search != false
      params = @params()
      search_keys = window.Object.keys(params).sort()

      search = []
      search.push("#{key}=#{params[key]}") for key in search_keys
      search = "?#{search.join('&')}"

      output += search

    output += @hash if @hash? && options.hash != false
    output

  extension: ->
    extensions = @extensions()
    extensions[extensions.length - 1]

  extensions: ->
    split_path = @pathname.split(".")
    split_path.shift()
    split_path

  equals: (url, request_level = false) ->
    return false if @protocol != url.protocol || @hostname != url.hostname || @port != url.port
    return false if @pathname != url.pathname || @search != url.search
    return false if request_level == false && @hash != url.hash

    params = @params()
    uri_params = url.params()

    return false if window.Object.keys(params).length != window.Object.keys(uri_params).length

    # Tests for equality, ignoring different search parameter orders
    for key, value of params
      return false if uri_params[key] != params[key]

    true

window.App.Helpers ?= {}
window.App.Helpers.URIs ?= {}

window.App.Helpers.URIs.Object = App.Objects.URL

window.App.Helpers.URIs.parse = App.Objects.URL.parse
window.App.Helpers.URIs.parse_search = App.Objects.URL.parse_search

window.App.Helpers.URIs.equivalent = App.Objects.URL.equivalent

window.App.Helpers.URIs.current = App.Objects.URL.current
