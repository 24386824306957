
window.Workflows ?= {}
window.Workflows.Conditions ?= {}

window.Workflows.Conditions["each"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_value_selector().then (values) ->
    iterations = []
    initial_deferred = $.Deferred()
    current_deferred = initial_deferred

    for value, i in values
       iterations[i] = current_deferred = current_deferred.then ((iterator) ->
        ->
          step.set_local_variable("value", values[iterator])
          step.set_local_variable("this", values[iterator])

          if values[iterator] instanceof App.Models.Submission.Object
            step.log("#<Submission:#{values[iterator]._unique_id()}>")
          else
            step.log(values[iterator])
          step.publish()
      )(i)

    current_deferred.done ->
      deferred.resolve({ silent: true })

    initial_deferred.resolve()
  .fail ->
    step.log("✕ Failed")
    deferred.reject()

  deferred

window.Workflows.Conditions["if"] = (step) ->
  deferred = $.Deferred()

  condition_is_formula = (step.data.options.formula_type == "formula")

  if condition_is_formula
    matching_formula = step.data.options.formula

    if !matching_formula? || matching_formula.length == 0
      step.error("Formula isn't defined")
      return deferred.reject()

    step.evaluate_formula("formula")
    .done (evaluated_value) ->
      if evaluated_value == false || evaluated_value == "false" || evaluated_value == 0
        step.log("✕ Failed")
        deferred.reject()
      else
        step.log("✓ Passed")
        deferred.resolve()
    .fail (response) ->
      step.error("Formula evaluation failed: `#{matching_formula}`")
      deferred.reject()
  else
    field_value = step.data.options.field_value
    operator = step.data.options.operator

    field_data = step.decode_field_data(step.data.options.formula)
    matching_field_name = field_data.field

    if !matching_field_name? || matching_field_name.length == 0
      step.error("Field isn't defined")
      return deferred.reject()

    submission_request = step.get_variable(field_data.submission)

    submission_request
    .done (submission) ->
      matching_field = submission.fields.find_by_column_name(matching_field_name)

      if !matching_field?
        step.error("Field isn't defined")
        return deferred.reject()

      step.evaluate_formula("field_value")
      .done (evaluated_value) ->
        passed = false

        switch operator
          when "=="
            passed = (evaluated_value == matching_field.value())
          when "!="
            passed = (evaluated_value != matching_field.value())
          when ">"
            passed = (parseInt(evaluated_value) > parseInt(matching_field.value()))
          when ">="
            passed = (parseInt(evaluated_value) >= parseInt(matching_field.value()))
          when "<"
            passed = (parseInt(evaluated_value) < parseInt(matching_field.value()))
          when "<="
            passed = (parseInt(evaluated_value) <= parseInt(matching_field.value()))

        if passed
          step.log("✓ Passed")
          deferred.resolve()
        else
          step.log("✕ Failed")
          deferred.reject()
      .fail (response) ->
        step.error("Formula evaluation failed: `#{matching_formula}`")
        deferred.reject()
    .fail (response) ->
      step.error("Submission could not be fetched")
      return deferred.reject()

  deferred

window.Workflows.Conditions["case:switch"] = (step) ->
  deferred = $.Deferred()

  condition_is_formula = (step.data.options.condition_type == "formula")

  if condition_is_formula
    matching_formula = step.data.options.condition

    if !matching_formula? || matching_formula.length == 0
      step.error("Formula isn't defined")
      return deferred.reject()

    step.evaluate_formula("condition")
    .done (evaluated_value) ->
      step.set_local_variable("value", evaluated_value)
      step.set_local_variable("__case_value__", evaluated_value)
      step.set_local_variable("__case_matched__", false)

      deferred.resolve()
    .fail (response) ->
      step.error("Formula evaluation failed: `#{matching_formula}`")
      deferred.reject()
  else
    step.submission_field_value_selector(field: "condition").then (values) ->
      collapsed_values = [].concat.apply([], values)

      step.set_local_variable("value", collapsed_values)
      step.set_local_variable("__case_value__", collapsed_values)
      step.set_local_variable("__case_matched__", false)

      deferred.resolve()
    .catch (error) ->
      step.error(error)
      deferred.reject()

  deferred

window.Workflows.Conditions["case:when"] = (step) ->
  deferred = $.Deferred()

  step.get_variable(["__case_value__", "__case_matched__"]).done (variables) ->
    case_value = variables[0]
    case_matched = variables[1]

    if case_matched == true
      step.log("✕ Skipped")
      return deferred.reject()

    value_is_formula = (step.data.options.value_type == "formula")

    if value_is_formula
      matching_formula = step.data.options.value

      if !matching_formula? || matching_formula.length == 0
        step.error("Formula isn't defined")
        return deferred.reject()

      step.evaluate_formula("value")
      .done (evaluated_value) ->
        if Workflows.Conditions.Helpers.case_matches(case_value, evaluated_value)
          step.set_variable("__case_matched__", true)
          step.log("✓ Passed")
          deferred.resolve()
        else
          step.log("✕ Failed")
          deferred.reject()

      .fail (response) ->
        step.error("Formula evaluation failed: `#{matching_formula}`")
        deferred.reject()
    else
      field_data = step.decode_field_data(step.data.options.value)
      matching_field_name = field_data.field

      if !matching_field_name? || matching_field_name.length == 0
        step.error("Field isn't defined")
        return deferred.reject()

      submission_request = step.get_variable(field_data.submission)

      submission_request
      .done (submission) ->
        matching_field = submission.fields.find_by_column_name(matching_field_name)

        if !matching_field?
          step.error("Field isn't defined")
          return deferred.reject()

        if Workflows.Conditions.Helpers.case_matches(case_value, matching_field.value())
          step.set_variable("__case_matched__", true)
          step.log("✓ Passed")
          deferred.resolve()
        else
          step.log("✕ Failed")
          deferred.reject()

      .fail (response) ->
        step.error("Submission could not be fetched")
        return deferred.reject()

  deferred

window.Workflows.Conditions["case:else"] = (step) ->
  deferred = $.Deferred()

  step.get_variable("__case_matched__").done (case_matched) ->
    if case_matched == true
      step.log("✕ Skipped")
      return deferred.reject()

    step.set_variable("__case_matched__", true)
    step.log("✓ Passed")
    deferred.resolve()

  deferred

window.Workflows.Conditions["request"] = (step) ->
  deferred = $.Deferred()

  step.evaluate_formulas("url", "method", "body", "variable_object").then (values) ->
    request_url = values.url
    request_method = values.method || "GET"

    if request_method == "GET"
      request_data = values.variable_object
    else
      request_data = values.body || values.variable_object

    $.ajax
      url: request_url
      method: request_method
      data: request_data
    .done (response, textStatus, xhr) ->
      step.set_local_variable("success", true)
      step.set_local_variable("status", xhr.status)
      step.set_local_variable("response", response.toString())
      deferred.resolve()
    .fail (xhr) ->
      # Connection Error
      if xhr.readyState == 0
        step.error("Connection Error")
        deferred.reject()
      # HTTP Error
      else
        step.set_local_variable("success", false)
        step.set_local_variable("status", xhr.status)
        step.set_local_variable("response", xhr.responseText)

      deferred.resolve()

  deferred

window.Workflows.Conditions["timeout"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred

window.Workflows.Conditions["interval"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred

window.Workflows.Conditions["submission:open"] = (step) ->
  deferred = $.Deferred()

  custom_variable_formula = step.data.options.custom_variable

  if custom_variable_formula? && custom_variable_formula.length > 0
    custom_variable_lookup = step.evaluate_formula("custom_variable")

  step.submission_selector(throw_on_empty: false).then (submissions) ->
    open_new = step.data.options.open_new
    open_new = (open_new == 'true' || open_new == true)

    if WorkflowStepInstance.blank_submission_request(submissions) && !open_new
      step.error("Submission isn't defined")
      return deferred.reject()

    query_variable = step.data.options.variable

    open_in_background = step.data.options.open_in_background
    open_in_background = (open_in_background == 'true' || open_in_background == true)

    if open_new
      submission_promise = new Promise (resolve, reject) ->
        form = App.Models.Form.new(step.data.options.submission_form)

        query_promise = form.submissions.new(selector: App.Helpers.Generators.uuid())
        context_promise = Workflows.Helpers.context_from_variables(step, "submission")

        Promise.all([query_promise, context_promise]).then (results) ->
          submission = results[0]
          context = results[1]

          # If the submission was created through a submission:field selector, assign it to the
          # selector connection for persisting on save.
          if context.submissions? && context.field? && context.field.field_type() == "connection"
            for parent in context.submissions
              connection = parent.fields.find_by_column_name(context.field.column_name())
              connection.helpers._add_spawned_submission(submission)

          resolve([submission])
    else
      submission_promise = Promise.resolve(submissions)

    submission_promise.then (submissions_array) ->
      if !open_in_background
        submission.show() for submission in submissions_array

      setters = []
      variables = {}

      variables[query_variable] = submissions_array
      variables["submissions"] = submissions_array
      variables["submission"] = submissions_array[0]

      # Static UUID generated by the submission:open step
      if step.data.options.internal_variable?
        variables[step.data.options.internal_variable] = submissions_array[0]

      setters.push step.set_local_variables(variables)

      if custom_variable_lookup?
        setters.push new Promise (resolve, reject) ->
          custom_variable_lookup.done (custom_variable) ->
            step.set_local_variable(custom_variable, submissions_array)
            .done resolve
            .fail ->
              step.error("Custom variable lookup failed")
              reject()
          .fail ->
            step.error("Custom variable lookup failed")
            reject()

      Promise.all(setters)
      .then -> deferred.resolve()
      .catch -> deferred.reject()

  deferred

window.Workflows.Conditions["submission:if_persisted"] = (step) ->
  deferred = $.Deferred()

  step.submission_selector().then (values) ->
    all_persisted = true

    if values.length > 0
      for value in values
        all_persisted = false unless value.persisted()
    else
      all_persisted = false

    if all_persisted
      step.log("✓ Passed")
      deferred.resolve()
    else
      step.log("✕ Failed")
      deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Conditions["submission:if_visible"] = (step) ->
  deferred = $.Deferred()

  step.submission_selector().then (values) ->
    all_visible = true

    if values.length > 0
      for value in values
        all_visible = false unless value.opened()
    else
      all_visible = false

    if all_visible
      step.log("✓ Passed")
      deferred.resolve()
    else
      step.log("✕ Failed")
      deferred.reject()

  deferred

window.Workflows.Conditions["submission:page:if_visible"] = (step) ->
  deferred = $.Deferred()

  step.submission_selector().then (values) ->
    field_name = step.decode_field_data(step.data.options.field).field
    all_visible = true

    if values.length > 0
      for value in values
        all_visible = false unless value.pages.is_open(field_name)
    else
      all_visible = false

    if all_visible
      step.log("✓ Passed")
      deferred.resolve()
    else
      step.log("✕ Failed")
      deferred.reject()

  deferred

window.Workflows.Conditions["group:if_member"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done (response) ->
    if response == true
      step.log("✓ Passed")
      deferred.resolve()
    else
      step.log("✕ Failed")
      deferred.reject()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors
    deferred.reject()

  deferred

window.Workflows.Conditions["group:unless_member"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done (response) ->
    if response != true
      step.log("✓ Passed")
      deferred.resolve()
    else
      step.log("✕ Failed")
      deferred.reject()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors
    deferred.reject()

  deferred

window.Workflows.Conditions["parse:css_path"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done (response) ->
    step.set_local_variable("value", response.value)
    step.set_local_variable("values", response.values)
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors
    deferred.reject()

  deferred

window.Workflows.Conditions["parse:json_path"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done (response) ->
    step.set_local_variable("value", response.value)
    step.set_local_variable("values", response.values)
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors
    deferred.reject()

  deferred

window.Workflows.Conditions["parse:x_path"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done (response) ->
    step.set_local_variable("value", response.value)
    step.set_local_variable("values", response.values)
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors
    deferred.reject()

  deferred

window.Workflows.Conditions["integration:google_calendar:if_event_exists"] = (step) ->
  deferred = $.Deferred()

  step.run_server().done (output) ->
    if output == true || output == "true"
      step.log("✓ Passed")
      deferred.resolve()
    else
      step.log("✕ Failed")
      deferred.reject()
  .fail ->
    step.error("Event could not be looked up")
    if response? && response.errors?
      step.error(error) for error in response.errors
    deferred.reject()

  deferred
