
window.App ?= {}
window.App._page_contexts = {}

# Get the WindowManager context of a path or element
window.App.context = (context) ->
  return App.load_initial_context() unless context?

  page_path = App.context_path_from_value(context)

  return unless page_path?

  if !App._page_contexts[page_path]?
    page_element = document.querySelector(".main_view_page[data-page-path='#{page_path}']")
    menu_element = document.querySelector(".navbar.context[data-page-path='#{page_path}']")
    sidebar_element = document.querySelector(".page-sidebar[data-page-path='#{page_path}']")

    return if !page_element? || !menu_element?

    page_context = new App.WindowContext(page_path)

    page_context._page_element = page_element
    page_context._menu_element = menu_element
    page_context._sidebar_element = sidebar_element if sidebar_element?

    window.App._page_contexts[page_path] = page_context

  window.App._page_contexts[page_path]

window.App.context_path = (path) ->
  uri = App.Helpers.URIs.parse(path)
  # uri.set_param("display", "scaffold")
  uri.static_path(hash: false)

window.App.context_path_from_value = (value) ->
  value = value[0] if App.Helpers.Elements.is_jquery(value)

  if value instanceof HTMLElement
    if value.dataset.pagePath?
      page_path = value.dataset.pagePath
    else
      page_element = App.Helpers.Elements.closest(value, "[data-page-path]")
      return unless page_element?
      page_path = page_element.dataset.pagePath
  else if typeof value == "string" || value instanceof String
    page_path = value

  return unless page_path?

  App.context_path(page_path)

window.App.load_initial_context = ->
  page_context = App.context(location.toString())
  return page_context if page_context?

  page_path = App.context_path(location.toString())

  page_element = document.querySelector(".main_view_page:not([data-page-path])")

  if !page_element?
    main_element = document.querySelector(".document-main")
    return unless main_element?

    page_element = document.createElement("DIV")
    page_element.className = "main_view_page"

    for child in main_element.childNodes
      page_element.appendChild(main_element.childNodes[0])

    main_element.appendChild(page_element)

  page_element.setAttribute("data-page-path", page_path)

  menu_element = document.querySelector(".navbar.context:not([data-page-path])")
  menu_element.setAttribute("data-page-path", page_path) if menu_element?

  sidebar_element = document.querySelector(".page-sidebar:not([data-page-path])")
  sidebar_element.setAttribute("data-page-path", page_path) if sidebar_element?

  page_context = new App.WindowContext(page_path)
  page_context._page_element = page_element
  page_context._menu_element = menu_element if menu_element?
  page_context._sidebar_element = sidebar_element if sidebar_element?
  page_context.title = document.title

  page_context

# Collects loaded sidebars, navbars, etc. based on the object type
# so they can be passed with a header to skip calculating them server-side
window.App.loaded_context_elements = (object_type, include_models = false) ->
  output = {}

  if object_type == "all" || object_type == "grouping.set" || object_type == "grouping"
    output.sidebars = []

    for sidebar in App.Interface.Sidebar.GroupingSideBar.all()
      value = { type: "grouping.set", id: sidebar.permalink() }
      value.model = sidebar if include_models

      output.sidebars.push(value)

  output

class window.App.WindowContext
  @hide_all_except: (model) ->
    for path, context of App._page_contexts
      continue unless context? && context != model
      context.hide(except: model)

    undefined

  @hide_all: ->
    for path, context of App._page_contexts
      continue unless context?
      context.hide()

    undefined

  @slash_path: (path) ->
    # return path unless App.Helpers.Compatibility.is_ie
    return path if path[0] == "/"
    App.Helpers.URIs.parse(path).path()

  @all: ->
    Object.values(App._page_contexts).filter(Boolean)

  constructor: (path_id) ->
    @set_path(path_id)

    @title = ""
    @locals = {}

    true

  set_path: (value) ->
    if value?
      old_path_id = @path_id if @path_id?

      @path_id = App.context_path(value)

      if old_path_id? && old_path_id != @path_id
        App._page_contexts[old_path_id] = undefined

        if App.window_manager?
          App.window_manager.deferred_requests[@path_id] = App.window_manager.deferred_requests[old_path_id]
          App.window_manager.deferred_requests[old_path_id] = undefined

          App.window_manager.timers[@path_id] = App.window_manager.timers[old_path_id]
          App.window_manager.timers[old_path_id] = undefined

      App._page_contexts[@path_id] = @

      parser = App.Helpers.URIs.parse(@path_id)
      parser.set_param("display", undefined)
      @path = parser.path()

      @_path_params = undefined
      @_path_search_hash = undefined

      if @_page_element?
        @_page_element.dataset.pagePath = @path_id

      if @_menu_element?
        @_menu_element.dataset.pagePath = @path_id

      if @_sidebar_element?
        @_sidebar_element.dataset.pagePath = @path_id

    @path

  params: (param) ->
    if !@_path_params?
      search = App.Helpers.URIs.parse(@path).search.substring(1)
      @_path_params = App.Helpers.URIs.parse_search(search)
      @_path_search_hash = App.Helpers.Strings.hash(search)

    return @_path_params[param] if param?

    @_path_params

  menu: ->
    App.Interface.Menu.find(@_menu_element)

  sidebar: ->
    return App.overview_sidebar() unless @_sidebar_element?
    App.Interface.Sidebar.find_or_add(@_sidebar_element)

  menu_element: ->
    return unless @_menu_element?
    @_menu_element

  page_element: ->
    return unless @_page_element?
    @_page_element

  sidebar_element: ->
    return unless @_sidebar_element?
    @_sidebar_element

  elements: ->
    output = []
    output.push(@_menu_element) if @_menu_element?
    output.push(@_page_element) if @_page_element?
    output.push(@_sidebar_element) if @_sidebar_element?
    output

  shared_elements: (context) ->
    context_elements = context.elements()

    for element in @elements()
      return true if context_elements.indexOf(element) != -1

    false

  destroy: ->
    @hide()

    @_page_element.remove() if @_page_element?
    @_menu_element.remove() if @_menu_element?
    @_sidebar_element.remove() if @_sidebar_element?

    if App.window_manager?
      App.window_manager.deferred_requests[@path_id] = undefined
      delete App.window_manager.deferred_requests[@path_id]

    App._page_contexts[@path_id] = undefined
    delete App._page_contexts[@path_id]

  refresh: ->
    visible = @visible()

    App.window_manager.load_request(@path, @).then =>
      @show() if visible

  visible: ->
    @_page_element? && @_page_element.parentNode?

  generate_scrollbars: ->
    if @_page_element?
      page_wrapper = document.querySelector(".document-main")

      page_wrapper.classList.add("antiscroll-wrap")
      @_page_element.classList.add("antiscroll-inner")

      if @_page_scroller?
        @_page_scroller.rebuild()
      else
        @_page_scroller = $(page_wrapper).antiscroll().data("antiscroll")

    App.sidebar().generate_scrollbars()

    null

  show: ->
    App.WindowContext.hide_all_except(@)

    if @_page_element?
      if @_page_element.parentNode?
        @_page_element.style.display = "block"
      else
        page_wrapper = document.querySelector(".document-main")
        page_wrapper.appendChild(@_page_element)

    if @_menu_element?
      if @_menu_element.parentNode?
        @_menu_element.style.display = "block"
      else
        menu_wrapper = document.getElementById("global_page_navbar")
        head_wrapper = menu_wrapper.querySelector(".navbar.head")

        # Context Navbars must be inserted before alert/notice navbars
        if head_wrapper?
          menu_wrapper.insertBefore(@_menu_element, head_wrapper.nextSibling)
        else
          menu_wrapper.appendChild(@_menu_element)

    if @_sidebar_element?
      if @_sidebar_element.parentNode?
        @_sidebar_element.style.display = "block"
      else
        sidebar_wrapper = document.getElementById("document-sidebar-inner")
        sidebar_wrapper.appendChild(@_sidebar_element)

    sidebar = @sidebar()

    if sidebar.is_overview()
      if sidebar.loaded()
        sidebar.show(mobile: false, manual: false)
        @generate_scrollbars()
      else
        sidebar.load().then =>
          sidebar.show(mobile: false, manual: false)
          @generate_scrollbars()
    else
      sidebar.show(mobile: false, manual: false)
      @generate_scrollbars()

    undefined

  # Detached iframes cause TONS of problems, even in current (2018) browsers.
  # Panes containing iframes are hidden, even though there is some DOM bloat.
  # options.except when set to another context will not hide elements shared between them.
  hide: (options) ->
    if @_page_element? && @_page_element.parentNode? && @_page_element.style.display != "none"
      if !options? || !options.except? || options.except._page_element != @_page_element
        if @_page_element.querySelector("iframe")?
          @_page_element.style.display = "none"
        else
          @_page_element.parentNode.removeChild(@_page_element)

    if @_menu_element? && @_menu_element.parentNode? && @_menu_element.style.display != "none"
      if !options? || !options.except? || options.except._menu_element != @_menu_element
        if @_menu_element.querySelector("iframe")?
          @_menu_element.style.display = "none"
        else
          @_menu_element.parentNode.removeChild(@_menu_element)

    if @_sidebar_element? && @_sidebar_element.parentNode? && @_sidebar_element.style.display != "none"
      if !options? || !options.except? || options.except._sidebar_element != @_sidebar_element
        if @_sidebar_element.querySelector("iframe")?
          @_sidebar_element.style.display = "none"
        else
          @_sidebar_element.parentNode.removeChild(@_sidebar_element)

    undefined

  attach: (options) ->
    if @_page_element? && !@_page_element.parentNode?
      page_wrapper = document.querySelector(".document-main")
      page_wrapper.appendChild(@_page_element)

    if @_menu_element? && !@_menu_element.parentNode?
      menu_wrapper = document.getElementById("global_page_navbar")
      menu_wrapper.appendChild(@_menu_element) if menu_wrapper?

    if @_sidebar_element? && !@_sidebar_element.parentNode?
      sidebar_wrapper = document.getElementById("document-sidebar-inner")
      sidebar_wrapper.appendChild(@_sidebar_element) if sidebar_wrapper?

    @hide(options)
