
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.UserViewer ?= {}
window.App.Elements.UserViewer._models ?= {}

window.App.Elements.UserViewer.find_by_username = (username) ->
  App.Elements.UserViewer._models[username]

window.App.Elements.UserViewer.add = (element) ->
  username = element.dataset.user

  if element.dataset.formPermalink
    form_permalink = element.dataset.formPermalink

  if element.dataset.submissionPermalink
    submission_permalink = element.dataset.submissionPermalink

  model = App.Elements.UserViewer.find_by_username(username)
  model ?= new App.Elements.UserViewer.Object(username, form_permalink, submission_permalink)
  model.add(element)

class window.App.Elements.UserViewer.Object
  constructor: (@username, @form_permalink, @submission_permalink) ->
    window.App.Elements.UserViewer._models[@username] = @

    @_elements = []

  form: -> App.Models.Form.new(@form_permalink)

  submission: (event) ->
    if event?
      event.preventDefault()
      button = event.currentTarget

    return Promise.resolve(@_submission) if @_submission?

    add_loader button

    new Promise (resolve, reject) =>
      $.ajax
        url: Routes.edit_user_profile_path(@username)
      .done (response) =>
        @_submission = @form().submissions.initialize_html(response, hidden: true)

        if button?
          remove_loader button
          App.Models.Submission.Helpers.Generators.assign_link_attributes(@_submission, button)

        if !@_submission.persisted()
          @_submission.after_create (submission, response) =>
            @submission_permalink = @_submission.permalink()

            @generate_viewers()
            @submission_viewer().update(response.submission)

        resolve @_submission

  submission_viewer: ->
    return unless @submission_permalink?

    model = App.Elements.ResponseViewer.find_by_permalink(@submission_permalink)

    if !model?
      model = new App.Elements.ResponseViewer.Object(@form_permalink, @submission_permalink)
      model.add(element) for element in @_elements

    model

  generate_viewers: ->
    viewer = @submission_viewer()

    for element in @_elements
      continue if element.classList.contains("view_submission")

      element.classList.add("view_submission")
      element.dataset.formPermalink = @form_permalink
      element.dataset.submissionPermalink = @submission_permalink

      viewer.add(element)

    undefined

  permalink: -> @submission_permalink

  add: (element) ->
    return if @_elements? && @_elements.indexOf(element) != -1

    element = element[0] if App.Helpers.Elements.is_jquery(element)
    element.classList.add("initialized")

    @_elements ?= []
    @_elements.push(element)

    @submission_viewer().add(element) if @submission_permalink?

    element

App.Models.bind_function ".view_user", (element) ->
  return if element.classList.contains("view_user_initialized")
  App.Elements.UserViewer.add(element)
