
window.Workflows ?= {}
window.Workflows.Conditions ?= {}
window.Workflows.Conditions.Helpers ?= {}

window.Workflows.Conditions.Helpers.case_matches = (case_value, evaluated_value) ->
  if Array.isArray(case_value)
    if Array.isArray(evaluated_value)
      return JSON.stringify(case_value) == JSON.stringify(evaluated_value)
    else
      return case_value.indexOf(evaluated_value) != -1
  else
    if Array.isArray(evaluated_value)
      return evaluated_value.indexOf(case_value) != -1
    else
      return case_value == evaluated_value
