
$(document).on "click", ".open_table_calendar", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink

  App.Interface.Modal.from_url(Routes.new_calendar_path(form_permalink)).show()
  .then -> remove_loader(button)
  .catch (error) ->
    remove_loader(button)
    throw error

$(document).on "click", ".destroy_calendar", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  calendar_permalink = button.dataset.calendarPermalink

  form = App.Models.Form.new(form_permalink)
  form.calendars.query_by_permalink(calendar_permalink).then (calendar) ->
    calendar.destroy()
    .then ->
      remove_loader button
    .catch (xhr) ->
      remove_loader button
      App.Errors.xhr_error(xhr)

$(document).on "click", ".calendar_settings", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  calendar_permalink = button.dataset.permalink

  form = App.Models.Form.new(form_permalink)

  form.calendars.schema().then ->
    calendar = form.calendars.find_by_permalink(calendar_permalink)
    return unless calendar?

    modal = App.Elements.ResponseCalendar.settings_modal(calendar)
    modal.show()
    .then -> remove_loader(button)
    .catch (error) ->
      remove_loader(button)
      throw error

$(document).on "submit", ".new_calendar_form", (event) ->
  event.preventDefault()

  form_element = event.currentTarget
  button = form_element.querySelector("button[type=submit], input[type=submit]")

  return if loading button

  add_loader button

  calendar_data = App.Helpers.Forms.data(form_element).calendar

  form_permalink = form_element.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  modal_element = App.Helpers.Elements.closest(form_element, ".modal-container")
  modal = App.Interface.Modal.find_by_id(modal_element.id)

  form.calendars.create(calendar_data)
  .then (calendar) ->
    remove_loader button
    modal.hide()

    App.redirect_to Routes.calendar_path(calendar.form().permalink(), calendar.permalink())
  .catch (xhr) ->
    remove_loader button
    modal.show()

    apply_errors_to_form(form_element, xhr.responseJSON.errors)

$(document).on "submit", ".edit_calendar_form", (event) ->
  event.preventDefault()

  form_element = event.currentTarget
  button = form_element.querySelector("button[type=submit], input[type=submit]")

  return if loading button

  add_loader button

  calendar_data = App.Helpers.Forms.data(form_element).calendar

  form_permalink = form_element.dataset.formPermalink
  calendar_permalink = form_element.dataset.calendarPermalink

  form = App.Models.Form.new(form_permalink)
  form.calendars.query_by_permalink(calendar_permalink).then (calendar) ->
    modal_element = App.Helpers.Elements.closest(form_element, ".modal-container")
    modal = App.Interface.Modal.find_by_id(modal_element.id)

    calendar.update(calendar_data)
    .then ->
      remove_loader button
      modal.hide()
    .catch (xhr) ->
      remove_loader button
      modal.show()

      apply_errors_to_form(form_element, xhr.responseJSON.errors)
