
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Hotkeys ?= {}

window.App.Helpers.Hotkeys._timeouts = {}
window.App.Helpers.Hotkeys._bindings = []

window.App.Helpers.Hotkeys._modifier_keys = [
  "ctrl", "alt", "shift"
]

window.App.Helpers.Hotkeys._shifted_keys = [
  "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "<", ">", "?"
]

window.App.Helpers.Hotkeys._named_keys = {
  "escape": 27, "esc": 27,
  "left": 37, "up": 38, "right": 39, "down": 40,
  "delete": 46, "del": 46,
  "!": 49, "@": 50, "#": 51, "$": 52, "%": 53, "^": 54, "&": 55, "*": 56,
  "(": 57, ")": 48, "_": 189, "+": 187, "<": 188, ">": 190, "?": 191
}

window.App.Helpers.Hotkeys.event_modifiers = (event) ->
  {
    "ctrl": event.ctrlKey,
    "alt": event.altKey,
    "shift": event.shiftKey
  }

# Check if an event matches a hotkey combination, ignoring timeouts
window.App.Helpers.Hotkeys.matches = (combination, event) ->
  keys = combination.keys
  event_key = event.which || event.keyCode
  modifiers = App.Helpers.Hotkeys.event_modifiers(event)

  return false if modifiers.shift && keys.indexOf("shift") == -1
  return false if modifiers.ctrl && keys.indexOf("ctrl") == -1
  return false if modifiers.alt && keys.indexOf("alt") == -1

  for key in keys
    if key instanceof RegExp
      return false unless String.fromCharCode(event_key).match(key)
    else
      if App.Helpers.Hotkeys._modifier_keys.indexOf(key) == -1
        return false unless event_key == key
      else
        return false unless modifiers[key]

  if combination.if?
    return false unless combination.if(event)

  true

# Check if the hotkey combination is timed out.
window.App.Helpers.Hotkeys.timed_out = (combination, index, event) ->
  return false unless combination.timeout?

  App.Helpers.Hotkeys._timeouts[index] == true

# Run the hotkey combination's callback, setting the timeout if present.
window.App.Helpers.Hotkeys.trigger = (combination, index, event) ->
  if combination.timeout?
    App.Helpers.Hotkeys._timeouts[index] = true

    setTimeout((-> App.Helpers.Hotkeys._timeouts[index] = false), combination.timeout)

  combination.callback(event)

window.App.Helpers.Hotkeys.parse_key = (key) ->
  return key if key instanceof RegExp

  key = key.toLowerCase()

  return key if App.Helpers.Hotkeys._modifier_keys.indexOf(key) != -1

  if App.Helpers.Hotkeys._named_keys[key]?
    output = [App.Helpers.Hotkeys._named_keys[key]]

    # If the key requires shift, also include the shift key.
    if App.Helpers.Hotkeys._shifted_keys.indexOf(key) != -1
      output.push("shift")

    return output

  key.toUpperCase().charCodeAt(0)

window.App.Helpers.Hotkeys.bind = (keys, options) ->
  keys = if Array.isArray(keys) then keys else [keys]
  combo = []

  for key in keys
    parsed_key = App.Helpers.Hotkeys.parse_key(key)

    # Parsing can return multiple keys. For example, ! requires shift.
    if Array.isArray(parsed_key)
      combo = combo.concat(parsed_key)
    else
      combo.push(parsed_key)

  combination = {}
  combination.keys = combo
  combination.callback = options.do
  combination.if = options.if if options.if?
  combination.timeout = options.timeout if options.timeout?

  App.Helpers.Hotkeys._bindings.push(combination)

  # Return the index of the bound hotkey
  App.Helpers.Hotkeys._bindings.length - 1

# Removes an existing binding. The _bindings array indexes must be preserved
# so that other bindings can be unbound by index. Don't compress the array.
window.App.Helpers.Hotkeys.unbind = (index) ->
  App.Helpers.Hotkeys._bindings[index] = undefined

# Checks if the event's keys are allowed to fire from within the active element.
# For example, if the user has an input selected, normal keystrokes can't trigger hotkeys.
window.App.Helpers.Hotkeys.event_allowed_from_element = (event, active_element) ->
  return true unless active_element?

  event_key = event.which || event.keyCode

  # Specific carve-out for Ctrl+Left/Right to switch modals while inputs are focused. Not ideal.
  return true if event.ctrlKey && (event_key == 37 || event_key == 38 || event_key == 39 || event_key == 40)

  return true if event_key == 27
  return false if active_element.tagName == "INPUT" || active_element.tagName == "TEXTAREA"
  return false if active_element.contentEditable == "true"

  true

document.addEventListener "keydown", (event) ->
  return unless App.Helpers.Hotkeys.event_allowed_from_element(event, document.activeElement)

  window.App.Helpers.Hotkeys.data = {}

  for combination, index in App.Helpers.Hotkeys._bindings
    if combination? && App.Helpers.Hotkeys.matches(combination, event)
      event.preventDefault()

      return if App.Helpers.Hotkeys.timed_out(combination, index, event)

      App.Helpers.Hotkeys.trigger(combination, index, event)

window.Hotkey = App.Helpers.Hotkeys
window.App.Hotkey = App.Helpers.Hotkeys
