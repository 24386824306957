
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Sidebar ?= {}

class window.App.Interface.Sidebar.GroupingSideBar extends App.Interface.Sidebar.SideBar
  @all: ->
    output = []

    for model in App.Interface.Sidebar._models
      output.push(model) if model instanceof App.Interface.Sidebar.GroupingSideBar

    output

  @find_by_permalink: (permalink) ->
    for model in App.Interface.Sidebar._models
      continue unless model instanceof App.Interface.Sidebar.GroupingSideBar
      return model if model.element.dataset.permalink == permalink

    null

  constructor: (@element) ->
    super(@element)

    @_permalink = @element.dataset.permalink
    @_form_permalink = @element.dataset.formPermalink

    PubSub.subscribe "submission.created.#{@_form_permalink}", => @refresh()
    PubSub.subscribe "submission.updated.#{@_form_permalink}", => @refresh()

  permalink: ->
    @_permalink

  form: ->
    Form.new(@_form_permalink)

  next_page: ->

  previous_page: ->

  show: (options) ->
    super(options)

    @generate_overview_return(options)

  refresh: (options = {}) ->
    @_refresh_request ?= new Promise (resolve, reject) =>
      data = {}
      data.authenticity_token = current_user.authenticity_token()
      data.selection_permalink = options.selected.permalink if options.selected?

      $.ajax
        url: Routes.form_grouping_sidebar_path(@_form_permalink, @_permalink)
        type: "POST"
        data: data
      .done (response) =>
        @_refresh_request = undefined

        element = App.Helpers.Elements.from_html(response)

        if element?
          children = element.children

          @element.innerHTML = ""
          @element.insertBefore(children[0], null) for child in children
          @element.classList.add("loaded")

        resolve()
      .fail (xhr) =>
        @_refresh_request = undefined

        return unless current_user.signed_in()

        App.Errors.response_error(xhr)

        reject(xhr.responseJSON)

    @_refresh_request
