
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.User
  constructor: (@field) ->
    # Allows filtering of select menu options by deleting the elements and restoring them from a base list
    @base_options = @options()

    @convert_to_select2()

  option_elements: ->
    @field._element.querySelectorAll("option")

  select_element: ->
    @field._element.querySelector("select")

  options: ->
    output = []

    for option in @option_elements()
      output.push([option.textContent.trim(), option.value])

    output

  show_options: (scoped_options) ->
    scoped_options ?= @base_options

    @select_element().innerHTML = App.Helpers.Selects.options_for_select(
      scoped_options, @field.value()
    )

  # Filter the users displayed by an array of usernames
  filter: (users) ->
    new_options = []
    existing_options = @options()
    filtered_usernames = []

    for user in users
      if user instanceof App.Schema.User
        filtered_usernames.push(user.username())
      else
        filtered_usernames.push(user)

    for option in existing_options
      continue if filtered_usernames.indexOf(option[1]) == -1
      new_options.push(option)

    @show_options(new_options)

  convert_to_select2: ->
    @field._element.querySelector(".SELECT_FIELD").classList.add("SELECT2_FIELD")

    select = @select_element()

    if select.multiple
      empty_option = select.querySelector("option:not([value]), option[value='']")
      empty_option.remove() if empty_option?

    $(select).select2()
