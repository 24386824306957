
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}
window.App.Elements.ResponseTable.Helpers ?= {}

class window.App.Elements.ResponseTable.Helpers.Conditions
  constructor: (@table, @options = {}) ->
    @simple_conditions ?= []

  has_simple_conditions: ->
    @simple_conditions? && @simple_conditions.length > 0

  simple_condition: (value) ->
    # If the value is already a condition, add it to the array.
    if value instanceof App.Query.Condition.Base
      if @simple_conditions.indexOf(value) == -1
        @simple_conditions.push(value)

      @draw()

      return value

    # If an condition matching the options exists at the top-level, bind to it.
    top_level_conditions = @table.query.all()
    field = App.Query.Condition.schema_from_parameter(value.field, @table.form())

    return false unless field?

    for condition in top_level_conditions
      if condition.field.permalink() == field.permalink() && condition.operator() == value.operator
        if @simple_conditions.indexOf(condition) == -1
          @simple_conditions.push(condition)

        @draw()

        return condition

    # Create a top-level condition matching the options.
    condition = @table.query.condition(value)
    @simple_conditions.push(condition)

    @draw()

    condition

  remove_simple_condition: (value) ->
    # If the value is already a condition, remove it from the array.
    if value instanceof App.Query.Condition.Base
      index = @simple_conditions.indexOf(value)
      return if index == -1

      @simple_conditions.splice(index, 1)

      return

    top_level_conditions = @table.query.all()
    field = App.Query.Condition.schema_from_parameter(value.field, @table.form())

    return unless field?

    for condition in top_level_conditions
      if condition.field.permalink() == field && condition.operator() == value.operator
        index = @simple_conditions.indexOf(condition)
        @simple_conditions.splice(index, 1) unless index == -1

    undefined

  draw: ->
    App.Elements.ResponseTable.Helpers.Conditions.draw_conditions(@table)

  @to_element: (object) ->
    if object instanceof App.Query.Group
      App.Elements.ResponseTable.Helpers.Conditions.group_to_element(object)
    else
      App.Elements.ResponseTable.Helpers.Conditions.condition_to_element(object)

  @group_to_element: (group) ->
    element = document.createElement("DIV")
    element.className = "response_table_conditions"
    element.dataset.instance = group.instance_id

    for child in group.all()
      element.appendChild App.Elements.ResponseTable.Helpers.Conditions.to_element(child)

      if child != group.last()
        element.appendChild App.Elements.ResponseTable.Helpers.Conditions.separator_to_element(group)

    element

  @condition_to_element: (condition) ->
    element = document.createElement("DIV")
    element.dataset.instance = condition.instance_id
    element.className = "response_table_condition"

    name_element = document.createElement("DIV")
    name_element.className = "response_table_condition_name"
    name_element.textContent = condition.field.name()

    element.appendChild(name_element)

    operator_element = document.createElement("DIV")
    operator_element.className = "response_table_condition_operator"
    operator_element.textContent = App.Query.Condition.operator_name(
      condition.field.field_type(), condition.operator()
    )

    element.appendChild(operator_element)

    if App.Elements.ResponseQuery.Query.operator_has_value(condition.operator())
      value_element = document.createElement("DIV")
      value_element.className = "response_table_condition_value"

      condition_value = condition.value()
      condition_value = condition_value.join(", ") if Array.isArray(condition_value)

      value_element.textContent = condition_value

      element.appendChild(value_element)

      condition.present().then (value) ->
        value_element.textContent = value

    delete_element = document.createElement("A")
    delete_element.href = "#"
    delete_element.className = "response_table_condition_delete"
    delete_element.innerHTML = "&times;"
    delete_element.title = "Remove Condition"

    element.appendChild(delete_element)

    element

  @separator_to_element: (group) ->
    element = document.createElement("DIV")
    element.className = "response_table_condition_separator"
    element.textContent = group.logical_operator().toUpperCase()
    element.title = "Toggle Operator"
    element

  @simple_conditions_to_element: (wrapper, simple_conditions) ->
    for child in simple_conditions
      @simple_condition_to_element(wrapper, child)

    wrapper

  @simple_condition_to_element: (wrapper, condition) ->
    instance = condition.instance_id
    element = wrapper.querySelector(".response_table_simple_condition[data-instance='#{instance}']")

    if !element?
      element = document.createElement("DIV")
      element.dataset.instance = condition.instance_id
      element.className = "response_table_simple_condition"

      name_element = document.createElement("DIV")
      name_element.className = "response_table_simple_condition_name"
      name_element.textContent = condition.field.name()

      element.appendChild(name_element)

      value_select = document.createElement("SELECT")
      value_select.className = "response_table_simple_condition_value_select"

      element.appendChild(value_select)

      wrapper.appendChild(element)

      @bind_simple_condition_search_to_input(condition, value_select)

    value_select = element.querySelector(".response_table_simple_condition_value_select")

    condition_value = condition.value()
    condition_value = condition_value.join(", ") if Array.isArray(condition_value)

    if condition_value? && value_select.value != condition_value
      App.Helpers.Selects.set_option(value_select, condition_value, true)

    element

  @bind_simple_condition_search_to_input: (condition, input) ->
    model = $(input).select2({
      delay: 250,
      minimumInputLength: 0,
      minimumResultsForSearch: 7,
      tokenSeparator: ","
      ajax: {
        url: Routes.unique_field_values_path(
          condition.form().permalink(),
          condition.field.variable_name()
        )
        data: (params) ->
          {
            _type: params._type,
            length: 10,
            page: params.page,
            q: params.term
          }
      }
      createTag: (params) ->
        term = params.term.trim()
        return if term.length == 0
        { id: term, text: term }
    })

    model.data("select2").$dropdown.addClass("select2-container-compressed")

    model

  @draw_conditions: (table) ->
    return unless table.header_element?

    wrapper = table.header_element.querySelector(".response_table_conditions_wrapper")
    simple_wrapper = table.header_element.querySelector(".response_table_simple_conditions_wrapper")

    if !wrapper?
      wrapper = document.createElement("DIV")
      wrapper.className = "response_table_conditions_wrapper"

      append_element = table.header_element.querySelector(".table_subtitle")
      append_element ?= table.header_element.querySelector(".table_options")
      append_element ?= table.header_element.querySelector(".table_name")

      table.header_element.insertBefore wrapper, append_element.nextSibling

    wrapper.innerHTML = ""

    if table.conditions.has_simple_conditions()
      return App.Elements.ResponseTable.Helpers.Conditions.draw_simple_conditions(table)
    else if simple_wrapper?
      simple_wrapper.innerHTML = ""

    return if !table.query.any()

    wrapper.appendChild App.Elements.ResponseTable.Helpers.Conditions.to_element(table.query)

    edit_element = document.createElement("A")
    edit_element.href = "#"
    edit_element.className = "edit_response_table_conditions"
    edit_element.title = "Edit Conditions"
    edit_element.innerHTML = "<i class='icon icon-filter'></i>"

    destroy_element = document.createElement("A")
    destroy_element.href = "#"
    destroy_element.className = "destroy_response_table_conditions"
    destroy_element.title = "Clear Conditions"
    destroy_element.innerHTML = "<i class='icon icon-cancel'></i>"

    wrapper.appendChild edit_element
    wrapper.appendChild destroy_element
    wrapper

  @draw_simple_conditions: (table) ->
    return unless table.header_element?

    wrapper = table.header_element.querySelector(".response_table_simple_conditions_wrapper")

    if !wrapper?
      wrapper = document.createElement("DIV")
      wrapper.className = "response_table_simple_conditions_wrapper"
      table.header_element.appendChild(wrapper)

    return unless table.conditions.has_simple_conditions()

    @simple_conditions_to_element(wrapper, table.conditions.simple_conditions)

    wrapper
