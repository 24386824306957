
window.App ?= {}
window.App.Query ?= {}

class window.App.Query.Group
  @from_parameters: (parent, form, parameters, options = {}) ->
    group_options = options
    group_options.logical_operator = parameters.operator
    group_options.negative = parameters.negative

    model = new App.Query.Group(form.permalink(), parameters.conditions, group_options)
    model._set_parent(parent)
    model.instance_id = parameters.instance if parameters.instance?
    model

  constructor: (@form_permalink, children = [], @_options = {}) ->
    @instance_id = App.Helpers.Generators.instance_id()

    @children = []

    @condition(child) for child in children

  form: ->
    App.Models.Form.new(@form_permalink)

  _set_parent: (parent) ->
    @parent = parent

  _remove_child: (value, destroy_empty_parent = false) ->
    return @destroy(true) if @children.length == 0 && destroy_empty_parent

    value_index = @children.indexOf(value)
    return if value_index == -1
    @children.splice(value_index, 1)

    return @destroy(true) if destroy_empty_parent

  _replace_child: (old_value, new_value) ->
    value_index = @children.indexOf(old_value)
    return if value_index == -1

    new_value = @_create_child(new_value)
    instance = old_value.instance_id

    delete @children[value_index]
    @children[value_index] = new_value
    @children[value_index].instance_id = instance

    new_value

  # Create a condition from parameters or a condition object, but do not attach it.
  _create_child: (value = {}) ->
    return value if App.Query.value_is_conditional(value)

    options = Object.assign({}, @_options)
    App.Query.from_parameters(@, @form(), value, options)

  logical_operator: (value) ->
    @_options.logical_operator = value.toLowerCase() if value?
    @_options.logical_operator || "and"

  toggle_logical_operator: (value) ->
    value ?= @_options.logical_operator
    value = value.toLowerCase()

    if value == "and"
      @logical_operator("or")
    else
      @logical_operator("and")

    @_options.logical_operator

  negative: (value) ->
    if value?
      @_options.negative = (value == "true" || value == true)

    @_options.negative || false

  condition: (value = {}) ->
    value = @_create_child(value)

    @children.push(value)

    value

  group: (value = {}) ->
    value.type ?= "group"

    @condition(value)

  index: (value) ->
    return undefined unless @parent?

    if value?
      old_index = @parent.children.indexOf(@)
      return value if old_index == value

      @parent.children = App.Helpers.Arrays.move(@parent.children, old_index, value)

    @parent.children.indexOf(@)

  find_condition_by_instance_id: (value) ->
    return @ if @instance_id == value

    for child in @children
      return child if child.instance_id == value

      if child instanceof App.Query.Group
        grandchild = child.find_condition_by_instance_id(value)
        return grandchild if grandchild?

    undefined

  conditions_count: ->
    count = 0

    for child in @children
      if child instanceof App.Query.Group
        count += child.conditions_count()
      else if child instanceof App.Query.Condition.Base
        count += 1

    count

  any: -> @children.length > 0
  all: -> @children

  first: -> @children[0]
  second: -> @children[1]
  third: -> @children[2]
  last: -> @children[@children.length - 1]

  destroy: (destroy_empty_parent = false) ->
    return unless @parent?
    @parent._remove_child(@, destroy_empty_parent)

  clear: ->
    @children = []

  clone: ->
    parameters = @serialize(instance: true)
    parameters.logical_operator = parameters.operator
    delete parameters.operator

    App.Query.Group.from_parameters(@parent, @form(), parameters)

  equals: (group) ->
    serialization = JSON.stringify(@serialize())
    group_serialization = JSON.stringify(group.serialize())

    serialization == group_serialization

  merge: (group) ->
    parameters = group

    if group instanceof App.Query.Group
      if group.form_permalink != @form_permalink
        group = group.clone()
        group._set_parent(@)

        @children.push(group)

        return @

      parameters = group.serialize(instance: true)

    @negative(parameters.negative)
    @logical_operator(parameters.operator)

    for condition_parameters in parameters.conditions
      @condition(condition_parameters)

    @

  prepend: (group) ->
    parameters = group

    if group instanceof App.Query.Group
      if group.form_permalink != @form_permalink
        group = group.clone()
        group._set_parent(@)

        @children.push(group)

        return @

      parameters = group.serialize(instance: true)

    @negative(parameters.negative)
    @logical_operator(parameters.operator)

    old_children = @children
    @children = []

    for condition_parameters in parameters.conditions
      @condition(condition_parameters)

    for condition in old_children
      @condition(condition)

    @

  load: (parameters, form) ->
    if parameters instanceof App.Query.Group
      parameters = parameters.serialize(instance: true)

    form ?= @form()

    @form_permalink = form.permalink()
    @negative(parameters.negative)
    @logical_operator(parameters.operator)

    @children = []

    for condition_parameters in parameters.conditions
      @condition(condition_parameters)

    @

  query: (options) ->
    new App.Schema.SubmissionPromise (resolve, reject) =>
      if App.is_child
        App.parent.publish(
          "submission_data.query",
          form: @form_permalink,
          query: @serialize()
          options: options
        ).then (response) =>
          request = response._request_data
          delete response._request_data

          resolve(App.Schema.SubmissionCollection.from_response(
            @form_permalink, response, request
          ))
      else
        options.where = @serialize()
        options.authenticity_token = current_user.authenticity_token()

        $.ajax
          url: "/api/rest/v1/forms/#{@form_permalink}/responses"
          data: options
        .done (response) =>
          resolve(App.Schema.SubmissionCollection.from_response(
            @form_permalink, response, options
          ))
        .fail (xhr) ->
          reject(xhr.responseJSON)

  serialize: (options) ->
    output = {
      type: "group",
      negative: @negative(),
      operator: @logical_operator(),
      conditions: []
    }

    if options? && options.instance == true
        output.instance = @instance_id

    if options? && options.urlsafe == true
      output.conditions = {}

      for child, index in @children
        output.conditions[index] = child.serialize(options)
    else
      for child in @children
        output.conditions.push child.serialize(options)

    output
