
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Group ?= {}

class window.App.Models.Group.Users
  constructor: (@usernames, @group_type) ->
    undefined

  all: ->
    output = []
    output.push App.Models.User.find(username) for username in @usernames
    output

  any: ->
    return true if @group_type == "public"
    @usernames.length > 0

  first: -> App.Models.User.find(@usernames[0])
  second: -> App.Models.User.find(@usernames[1])
  third: -> App.Models.User.find(@usernames[2])
  last: -> App.Models.User.find(@usernames[@usernames.length - 1])

  contains: (user) ->
    return true if @group_type == "public"
    user = user.username() if user instanceof App.Models.User.Object
    @usernames.indexOf(user) != -1

