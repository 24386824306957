
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Inputs ?= {}

window.App.Helpers.Inputs.resize_textarea = (element) ->
  setTimeout((->
    element.style.height = "auto"
    element.style.height = element.scrollHeight + 1 + "px"
  ), 1)

  element

window.App.Helpers.Inputs.name_to_dot_syntax = (value) ->
  value = value.replace(/\]\[/g, ".").replace(/\]/g, ".").replace(/\[/g, ".")

  if value[0] == "."
    value = value.substring(1, value.length)

  if value[value.length - 1] == "."
    value = value.substring(0, value.length - 1)

  value

window.App.Helpers.Inputs.name_to_bracket_syntax = (value) ->
  values = value.split(".")

  return values[0] if values.length <= 1

  output = values[0]
  output += "["

  values.shift()

  output += values.join("][")
  output += "]"
  output

window.App.Helpers.Inputs.bracket_to_underscore_syntax = (value) ->
  value = value.replace(/\]\[/g, "_").replace(/\]/g, "_").replace(/\[/g, "_")

  if value[0] == "_"
    value = value.substring(1, value.length)

  if value[value.length - 1] == "_"
    value = value.substring(0, value.length - 1)

  value.toLowerCase()

# Focus an input with the cursor at the end.
window.App.Helpers.Inputs.focus_end = (input) ->
  return unless input?
  input = input[0] if App.Helpers.Elements.is_jquery(input)

  input.focus()
  return input if input.tagName.toUpperCase() == "SELECT"

  input_value = input.value

  input.value = ""
  input.value = input_value

  input

window.App.Helpers.Inputs.checkbox_tag = (name, text, value, options = {}) ->
  options.id ?= App.Helpers.Inputs.bracket_to_underscore_syntax(name)
  options.value ?= true

  reset_input = document.createElement("INPUT")
  reset_input.className = "checkbox_reset"
  reset_input.value = false
  reset_input.type = "hidden"

  checkbox_input = document.createElement("INPUT")
  checkbox_input.id = options.id
  checkbox_input.type = "checkbox"
  checkbox_input.name = name
  checkbox_input.value = options.value
  checkbox_input.checked = value
  checkbox_input.className = options.class

  checkbox_icon = document.createElement("LABEL")
  checkbox_icon.className = "checkbox"
  checkbox_icon.setAttribute("for", options.id)

  checkbox_check = document.createElement("DIV")
  checkbox_check.className = "check"

  checkbox_icon.appendChild(checkbox_check)

  checkbox_label = document.createElement("LABEL")
  checkbox_label.className = "checkbox-label"
  checkbox_label.setAttribute("for", options.id)
  checkbox_label.textContent = text

  wrapper = document.createElement("SPAN")
  wrapper.appendChild(reset_input)
  wrapper.appendChild(checkbox_input)
  wrapper.appendChild(checkbox_icon)
  wrapper.appendChild(checkbox_label)
  wrapper

window.App.Helpers.Inputs.labeled_toggle = (name, checked, left, right, options = {}) ->
  options.id ?= App.Helpers.Inputs.bracket_to_underscore_syntax(name)
  options.value ?= true

  checked = (checked == true)

  wrapper = document.createElement("DIV")
  wrapper.className = "labeled_toggle_wrapper"

  input = document.createElement("INPUT")
  input.type = "checkbox"
  input.name = name
  input.value = options.value
  input.id = options.id
  input.checked = checked

  toggle_wrapper = document.createElement("LABEL")
  toggle_wrapper.className = "labeled_toggle"
  toggle_wrapper.setAttribute("for", options.id)

  left_label = document.createElement("SPAN")
  left_label.className = "labeled_toggle_label"
  left_label.textContent = left

  right_label = document.createElement("SPAN")
  right_label.className = "labeled_toggle_label"
  right_label.textContent = right

  switch_wrapper = document.createElement("DIV")
  switch_wrapper.className = "labeled_toggle_switch"

  switch_indicator = document.createElement("SPAN")
  switch_indicator.className = "labeled_toggle_indicator"

  switch_wrapper.appendChild(switch_indicator)

  toggle_wrapper.appendChild(left_label)
  toggle_wrapper.appendChild(switch_wrapper)
  toggle_wrapper.appendChild(right_label)

  wrapper.appendChild(input)
  wrapper.appendChild(toggle_wrapper)

  wrapper

window.App.Helpers.Inputs.CodeMirror ?= {}

window.App.Helpers.Inputs.CodeMirror.focus_end = (input) ->
  return unless input?
  input = input[0] if App.Helpers.Elements.is_jquery(input)

  element = App.Helpers.Elements.closest(input, ".CodeMirror")

  return App.Helpers.Inputs.focus_end(input) unless element?

  instance = element.CodeMirror

  return App.Helpers.Inputs.focus_end(input) unless instance?

  instance.focus()
  instance.setCursor(instance.lineCount(), 0)

  input
