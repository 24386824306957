
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentTable ?= {}
window.App.Elements.AttachmentTable.Helpers ?= {}

# Naming this Routes conflicts with the top-level JS-Routes plugin.
class window.App.Elements.AttachmentTable.Helpers.Routing
  constructor: (@table) ->

  data: ->
    if @table.params.folder()?
      return Routes.folder_table_data_path(
        @table.params.folder(),
        @table.params.url_data()
      )
    
    if @table.params.form()?
      return Routes.form_file_table_data_path(
        @table.params.form(),
        @table.params.url_data()
      )

    Routes.home_folder_table_data_path(
      @table.params.url_data()
    )
