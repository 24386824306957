import 'app/elements/attachment_post/helpers/routing'


window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentPost ?= {}

window.App.Elements.AttachmentPost._models ?= {}

window.App.Elements.AttachmentPost.find = (permalink) ->
  App.Elements.AttachmentPost._models[permalink]

class window.App.Elements.AttachmentPost.Post
  constructor: (@element) ->
    @element = @element[0] if App.Helpers.Elements.is_jquery(@element)

    @element.classList.add("initialized")

    if @persisted()
      App.Elements.AttachmentPost._models[@permalink()] = @

    @status = @element.dataset.status || "read"
    @read_element = @element.querySelector(".content_read")
    @edit_element = @element.querySelector(".content_edit")

    @routes = new App.Elements.AttachmentPost.Helpers.Routing(@)

    $(@element).on "submit", ".content_edit", (event) =>
      event.preventDefault()
      @save()

    @element.addEventListener "click", (event) =>
      button = event.target

      if App.Helpers.Elements.closest(button, ".edit_post")?
        event.stopPropagation()
        return @edit(event)

      if App.Helpers.Elements.closest(button, ".read_post")?
        event.stopPropagation()
        return @read(event)

      if App.Helpers.Elements.closest(button, ".toggle_post")?
        event.stopPropagation()
        return @toggle(event)

      if App.Helpers.Elements.closest(button, ".save_post")?
        event.stopPropagation()
        return @save(event)

      if App.Helpers.Elements.closest(button, ".delete_post")?
        event.stopPropagation()
        return @destroy(event)

    @load_textarea()

  permalink: ->
    value = @element.dataset.permalink
    return if !value? || value.length == 0
    value

  facility_permalink: ->
    value = @element.dataset.facilityPermalink
    return if !value? || value.length == 0
    value

  folder_permalink: ->
    value = @element.dataset.folderPermalink
    return if !value? || value.length == 0
    value

  fullscreen: ->
    value = @element.dataset.fullpage
    return false if !value? || value.length == 0
    value == "true"

  persisted: -> @permalink()?

  data: ->
    $(@edit_element).serialize()

  load_reader: (reload = false) ->
    if !@deferred_reader?
      read_element_content = @read_element.innerHTML.trim()

      if read_element_content.length > 0
        @deferred_reader = $.Deferred().resolve(read_element_content)

    return @deferred_reader if @deferred_reader? && !reload

    @deferred_reader = $.Deferred()

    add_loader @read_element

    $.ajax
      url: @routes.reader()
    .done (response) =>
      remove_loader @read_element

      @read_element.innerHTML = response
    .fail (xhr) =>
      App.Errors.response_error(xhr)
      remove_loader @read_element

      @deferred_reader.reject()
      @deferred_reader = undefined

    @deferred_reader

  read: (event) ->
    event.preventDefault() if event?

    return unless @persisted()

    state_path = App.WindowContext.slash_path(@routes.read())

    window.history.replaceState({ "path": state_path, "title": "Sonadier Forms" }, "Sonadier Forms", state_path)

    @load_reader()

    @edit_element.style.display = "none"
    @read_element.style.display = "block"

    @status = "read"

  load_textarea: ->
    textarea = @edit_element.querySelector(".expanding_textarea")
    return unless textarea?

    if !@_autosave_listener_defined? && @autosave_enabled()
      @_autosave_listener_defined = true

      textarea.addEventListener "keydown", (event) =>
        clearTimeout(@_autosave_timeout) if @_autosave_timeout?

        @_autosave_timeout = setTimeout((=> @autosave()), 1000)

    textarea.focus()

    App.Helpers.Inputs.resize_textarea textarea

    textarea

  load_editor: (reload = false) ->
    return @deferred_editor if @deferred_editor? && !reload

    @deferred_editor = $.Deferred()

    return @deferred_editor.resolve() if @edit_element.innerHTML.length > 0

    add_loader @edit_element

    $.ajax
      url: @routes.editor()
    .done (response) =>
      remove_loader @edit_element

      @edit_element.innerHTML = response

      @load_textarea()

      @deferred_editor.resolve()
    .fail (xhr) =>
      App.Errors.response_error(xhr)
      remove_loader @edit_element

      @deferred_editor.reject()
      @deferred_editor = undefined

    @deferred_editor

  edit: (event) ->
    event.preventDefault() if event?

    state_path = App.WindowContext.slash_path(@routes.edit())

    window.history.replaceState({ "path": state_path, "title": "Sonadier Forms" }, "Sonadier Forms", state_path)

    @edit_element.style.display = "block"
    @read_element.style.display = "none"

    @load_editor().done => @load_textarea()

    @status = "edit"

  toggle: (event) ->
    event.preventDefault() if event?
    if @status == "read" then @edit() else @read()

  destroy: (event) ->
    event.preventDefault() if event?
    return unless @permalink()?

    if event?
      button = event.target

      if button?
        if button.tagName != "A" && button.tagName != "INPUT" && button.tagName != "BUTTON"
          button = App.Helpers.Elements.closest(button, "a, input, button")
        return if button.classList.contains("loading")
        add_loader button

    $.ajax
      url: @routes.destroy()
      type: "DELETE"
      data: { authenticity_token: current_user.authenticity_token() }
    .done (response) =>
      remove_loader button

      App.redirect_to @routes.files(), scaffold: false
    .fail (xhr) =>
      App.Errors.response_error(xhr)
      remove_loader button

  restore: (event) ->
    event.preventDefault() if event?
    return unless @permalink()?

    if event?
      button = event.target

      if button?
        if button.tagName != "A" && button.tagName != "INPUT" && button.tagName != "BUTTON"
          button = App.Helpers.Elements.closest(button, "a, input, button")
        return if button.classList.contains("loading")
        add_loader button

    $.ajax
      url: @routes.restore()
      type: "POST"
      data: { authenticity_token: current_user.authenticity_token() }
    .done (response) =>
      remove_loader button

      App.redirect_to @routes.files(), scaffold: false
    .fail (xhr) =>
      App.Errors.response_error(xhr)
      remove_loader button

  history: (event) ->
    event.preventDefault() if event?

    if event?
      button = event.target

      if button?
        button = App.Helpers.Elements.closest(button, "a") unless button.tagName == "A"
        return if button.classList.contains("loading")
        add_loader button

    @history_modal ?= App.Interface.Modal.from_url(@routes.history())

    @history_modal.show()
    .then -> remove_loader(button)
    .catch (error) ->
      remove_loader(button)
      throw error

  save: (event) ->
    event.preventDefault() if event?

    if event?
      button = event.target

      if button?
        button = App.Helpers.Elements.closest(button, "a") unless button.tagName == "A"
        return if button.classList.contains("loading")
        add_loader button

    @submit()
    .done =>
      remove_loader button

      @load_reader(true)
      @read()
    .fail =>
      remove_loader button

  autosave: ->
    return unless @persisted() && @autosave_enabled()
    add_page_loader()

    @submit().always => remove_page_loader()

  autosave_enabled: ->
    false

  submit: ->
    deferred = $.Deferred()

    clearTimeout(@_autosave_timeout) if @_autosave_timeout?

    @load_editor().done =>
      $.ajax
        url: @routes.save()
        type: if @permalink()? then "PATCH" else "POST"
        data: @data()
      .done (response) =>
        if !@permalink()
          @element.dataset.permalink = response.permalink

          state_path = App.WindowContext.slash_path(@routes.edit())

          window.history.replaceState({ "path": state_path, "title": "Sonadier Forms" }, "Sonadier Forms", state_path)

          if App.windowing_enabled()
            context = App.context(@element)
            context.set_path(@routes.edit())

        App.Elements.AttachmentPost._models[@permalink()] = @

        @history_modal = undefined

        deferred.resolve()
      .fail (xhr) =>
        App.Errors.response_error(xhr)
        deferred.reject()

    deferred

App.Models.bind ".post_wrapper main.post", App.Elements.AttachmentPost.Post
