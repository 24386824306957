
$(document).on "click", ".new_folder", (event) ->
  event.preventDefault()
  button = event.currentTarget
  parent_folder = button.dataset.folder

  return if button.classList.contains("loading")
  add_loader button

  modal = App.Interface.Modal.from_url(Routes.new_folder_path(folder: parent_folder))

  modal.show().then (modal) ->
    remove_loader button

    modal_form = modal.element.querySelector("form")
    modal_folder_name = modal_form.querySelector("[name='folder[name]']")
    modal_folder_name.focus()
  .catch (xhr) ->
    remove_loader button
    App.Errors.response_error(xhr)

# TODO: Build Schema objects for Folders, Files, and Posts, and transition this
# to those schema objects. Include them in SSJS for advanced file management.
$(document).on "submit", ".create_folder", (event) ->
  event.preventDefault()

  form_element = event.currentTarget
  modal_element = App.Helpers.Elements.closest(form_element, ".modal-container")
  modal = App.Interface.Modal.find_by_id(modal_element.id)

  App.Helpers.Forms.ajax_submit(form_element).then (response) ->
    modal.hide()

    parent_folder_or_home = form_element.querySelector("[name='folder[folder]']").value
    parent_folder_or_home = "home" if !parent_folder_or_home? || parent_folder_or_home == "nil"

    PubSub.publish("file.uploaded.#{parent_folder_or_home}")
