
window.App ?= {}
window.App.Plugins ?= {}

window.App.Plugins.load = ->
  window.App.Plugins.loaded = true
  App.Plugins.refresh()

window.App.Plugins.refresh = ->
  return unless window.App.Plugins.loaded

  variables = {}

  for id, plugin of App.Plugins.methods
    unless plugin.initialized
      plugin.initialized = true

      new App.Plugins.Instance(id, variables)

  undefined

class window.App.Plugins.Instance
  constructor: (@id, variables) ->
    console.log("Starting Plugin #{@id}")
    App.Plugins.methods[@id](variables)

class window.SubmissionReference
  constructor: ->

  _assign_callback: (callback) ->
    # Run for existing submissions
    for form in Form.all()
      for submission in form.submissions.all()
        callback(submission)

    # Run for future submissions
    PubSub.subscribe "submission.loaded", (t, data) -> callback(data.submission)

    undefined

  do: (callback) ->
    if $.isReady
      @_assign_callback(callback)
    else
      $ => @_assign_callback(callback)

    undefined

window.Submission = new SubmissionReference()

$ ->
  App.Models.User.initialize_current_user()
  App.Plugins.load()
