
$(document).on "click", ".app_response_table .paginate_button.previous, .app_attachment_table .paginate_button.previous", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if button.classList.contains("disabled") || button.disabled

  table_container = App.Helpers.Elements.closest(button, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  add_loader button

  new_page = table_model.params.page() - 1

  table_model.params.page(new_page).always ->
    remove_loader button

$(document).on "click", ".app_response_table .paginate_button.next, .app_attachment_table .paginate_button.next", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if button.classList.contains("disabled") || button.disabled

  table_container = App.Helpers.Elements.closest(button, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  add_loader button

  new_page = table_model.params.page() + 1

  table_model.params.page(new_page).always ->
    remove_loader button
