
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseCalendar ?= {}
window.App.Elements.ResponseCalendar.Helpers ?= {}

class window.App.Elements.ResponseCalendar.Helpers.Events
  constructor: (@period) ->

  all: ->
    @period.element.querySelectorAll(".calendar_event")

  add: (data) ->
    container = @period.element.querySelector(
      "td.current_period .calendar-event-container[data-day='#{data.day}'][data-hour='#{data.hour}']"
    )

    return unless container?

    container.appendChild @_build(data)

  repeat: (submission_permalink, period) ->
    $.ajax
      url: @period.routes.repeat_event(submission_permalink, period)
      type: "PATCH"
      data: {
        authenticity_token: current_user.authenticity_token()
      }
    .done (response) =>
      @period.refresh_all()
    .fail (xhr) ->
      App.Errors.response_error(xhr)

  _build: (data) ->
    element = document.createElement("A")
    element.className = "calendar_event"
    element.setAttribute("data-permalink", data.submission)
    element.setAttribute("data-form-permalink", data.form)

    inner = document.createElement("SPAN")
    inner.className = "submission_field"
    inner.textContent = data.name
    inner.setAttribute("data-column-name", data.primary_field)

    element.appendChild(inner)

    if data.editable
      element.classList.add("edit_submission_link")
    else if data.readable
      # Setting data-object should only be done if it's not editable, since
      # clicking should edit if available. Href is set separately so that
      # right-click -> open new tab still works
      element.setAttribute("data-object", "submission")

    if data.readable
      element.setAttribute("href", Routes.submission_path(data.form, data.submission))

    element
