import css_escape from 'polyfills/css-escape-new'

window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Selects ?= {}

window.App.Helpers.Selects.options_for_select = (options, selected, config) ->
  selected_is_array = selected? && Array.isArray(selected)
  option_values = [] if selected?

  output = ""

  output += "<option></option>" if config? && config.include_blank == true

  if options?
    for option in options
      if Array.isArray(option)
        name = option[0]
        value = option[1]
      else
        name = option
        value = option

      option_values.push(value) if selected?

      option_is_selected = (selected_is_array && selected.indexOf(value) != -1) || selected == value

      output += App.Helpers.Selects.option_for_select(name, value, option_is_selected).outerHTML

  if selected? && config? && config.create_selected == true
    if selected_is_array
      for option in selected
        if option_values.indexOf(option) == -1
          output += App.Helpers.Selects.option_for_select(option, option, true).outerHTML
    else if option_values.indexOf(selected) == -1
      output += App.Helpers.Selects.option_for_select(selected, selected, true).outerHTML

  output

window.App.Helpers.Selects.escape_value = (value) ->
  (value || "").toString().replace(/\'/g,'&#39;').replace(/"/g,'&quot;')

window.App.Helpers.Selects.option_for_select = (name, value, selected) ->
  # Element creation and textContent used instead of building a string
  # for some XSS protection.
  element = document.createElement("OPTION")
  element.textContent = name
  element.setAttribute("value", App.Helpers.Selects.escape_value(value))
  element.setAttribute("selected", "selected") if selected
  element

window.App.Helpers.Selects.value_from_checkboxes = (element, checkboxes) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  current_values = element.value || []
  current_values = Array(current_values) unless Array.isArray(current_values)

  removed_values = []
  new_values = []

  # Since table results are paged and checkboxes for previous pages do not exist in the DOM, values must be explicitly
  # removed from the selected value list when their checkbox is both loaded and unchecked.
  for checkbox in checkboxes
    if checkbox.checked
      new_values.push(checkbox.value)
    else
      removed_values.push(checkbox.value)

  for value in current_values
    if removed_values.indexOf(value) == -1 && new_values.indexOf(value) == -1
      new_values.push(value)

  App.Helpers.Selects.value_from_array(element, new_values)

window.App.Helpers.Selects.value_from_array = (element, array, append = false) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  array = [] unless array?
  array = Array(array) unless Array.isArray(array)

  unless append
    options = element.querySelectorAll("option")
    option.selected = false for option in options

  for val, i in array
    continue unless val?

    if val.constructor == Array
      name = val[1]
      val = val[0]
    else
      name = val

    App.Helpers.Selects.add_option(element, name: name, value: val, selected: true)

  element.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))

window.App.Helpers.Selects._set_option_value = (option, selected) ->
  if selected
    option.setAttribute("selected", "selected")
    option.selected = true
  else
    option.removeAttribute("selected")
    option.selected = false

  option

window.App.Helpers.Selects.set_option = (select, value, selected) ->
  option_element = select.querySelector("option[value='#{value}']")

  return unless option_element?

  selected ?= !option_element.hasAttribute("selected")

  App.Helpers.Selects._set_option_value(option_element, selected)

  option_element

window.App.Helpers.Selects.add_option = (select, options = {}) ->
  escaped_value = css_escape(options.value)
  option_element = select.querySelector("option[value=\"#{escaped_value}\"]")

  if !option_element?
    option_element = App.Helpers.Selects.option_for_select(options.name || options.value, options.value)
    select.appendChild(option_element)

    option_element.textContent = options.name if options.name?
  else if !option_element.textContent? || option_element.textContent.length == 0
    option_element.textContent = options.name if options.name?

  options.selected ?= !option_element.hasAttribute("selected")

  App.Helpers.Selects._set_option_value(option_element, options.selected)

  option_element

window.App.Helpers.Selects.values = (select, new_values) ->
  select = select[0] if App.Helpers.Elements.is_jquery(select)

  if new_values?
    App.Helpers.Selects.value_from_array(select, new_values)

  values = []

  for option in select.options
    values.push(option.value) if option.selected

  values

window.App.Helpers.Selects.value = (select, new_value) ->
  select = select[0] if App.Helpers.Elements.is_jquery(select)

  if new_value?
    App.Helpers.Selects.value_from_array(select, [new_value])

  for option in select.options
    return option.value if option.selected

  undefined

window.App.Helpers.Selects.options = (select) ->
  select = select[0] if App.Helpers.Elements.is_jquery(select)

  values = []

  for option in select.options
    values.push(option.textContent) if option.selected

  values

window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Inputs ?= {}

window.App.Helpers.Inputs.options_for_select = App.Helpers.Selects.options_for_select

window.App.Helpers.Inputs.checkboxes_to_select = App.Helpers.Selects.value_from_checkboxes

window.App.Helpers.Inputs.set_select_option = App.Helpers.Selects.set_option
window.App.Helpers.Inputs.add_select_option = App.Helpers.Selects.add_option
