
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Helpers ?= {}

class window.App.Models.Submission.Helpers.Versioning
  constructor: (@submission) ->

  any_conflicts: ->
    @_conflicts?

  fields_to_override: ->
    conflicts = App.Helpers.Forms.data(@submission._element).conflicts
    output = []

    for field_name, value of conflicts
      continue unless value
      output.push(@submission.fields.find_by_variable_name(field_name))

    output

  changed_value: (field) ->
    if !@has_conflict(field)
      throw new Error("Field #{field.variable_name()} is not conflicted")

    @_conflicts.changes.fields[field.variable_name()]

  has_conflict: (field) ->
    return false unless @_conflicts? && @_conflicts.changes? && @_conflicts.changes.fields?
    return false unless @_conflicts.changes.fields[field.variable_name()]?

    true

  load_conflicts: (data) ->
    @_conflicts = data

    merges = @generate_merge_element()
    merges.style.display = "block"

    conflicts = merges.querySelector(".CONFLICTS_ENTRIES")
    conflicts.innerHTML = data.html

    merges

  resolve_conflicts: ->
    return false unless @any_conflicts()

    for field in @fields_to_override()
      field.merge_conflicted_value()

    true

  conflicts_resolved: ->
    @submission.last_merged = new Date()
    @_conflicts = null

    merges = @submission._element.querySelector(".CONFLICTING_CHANGES")
    return unless merges?

    merges.style.display = "none"

    conflicts = merges.querySelector(".CONFLICTS_ENTRIES")
    conflicts.innerHTML = ""

    merges

  generate_merge_element: ->
    merges = @submission._element.querySelector(".CONFLICTING_CHANGES")
    return merges if merges?

    base_errors = @submission._element.querySelector(".BASE_ERRORS")

    merges = document.createElement("DIV")
    merges.className = "CONFLICTING_CHANGES"

    title = document.createElement("DIV")
    title.className = "CONFLICTS_TITLE"
    title.textContent = i18n.t("conflicting_changes", {
      response: @submission._element.dataset.responsesSingular.toLowerCase()
    })

    subtitle = document.createElement("DIV")
    subtitle.className = "CONFLICTS_SUBTITLE"
    subtitle.textContent = i18n.t("conflict_instructions")

    body = document.createElement("DIV")
    body.className = "CONFLICTS_ENTRIES"

    merges.appendChild(title)
    merges.appendChild(subtitle)
    merges.appendChild(body)

    base_errors.parentNode.insertBefore(merges, base_errors)

    merges
