
window.App ?= {}
window.App.Schema ?= {}
window.App.Schema.Form ?= {}

window.App.Schema.Form.Errors ?= {}
window.App.Schema.Form.Errors.ProcedureNotFound = "Procedure Not Found"

class window.App.Schema.Form.ProcedureManager
  include @, App.Schema.SourceBase
  include @, App.Schema.SourceCollectionBase

  constructor: (@form_permalink) ->
    undefined

  model_instance: (data) -> new App.Schema.Form.Procedure(@form_permalink, data)
  model_class: -> App.Schema.Form.Procedure

  _value_to_model: (value) ->
    if typeof value == "string"
      return @find_by_endpoint(value) || @find_by_permalink(value) || @find_by_name(value)
    else if typeof value == "object"
      return @find_by_endpoint(value.endpoint) || @find_by_permalink(value.permalink) || @find_by_name(value.name)

    undefined

  find_by_permalink: (value, options) ->
    source = @_requested_schema(options)
    source.collection._find_by_parameter("permalink", value)

  find_by_name: (value, options) ->
    source = @_requested_schema(options)
    source.collection._find_by_parameter("name", value)

  find_by_endpoint: (value, options) ->
    source = @_requested_schema(options)
    source.collection._find_by_parameter("endpoint", value)

  query_by_permalink: (value) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        model = @find_by_permalink(value)
        if model? then resolve(model) else reject()

  query_by_name: (value) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        model = @find_by_name(value)
        if model? then resolve(model) else reject()

  query_by_endpoint: (value) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        model = @find_by_endpoint(value)
        if model? then resolve(model) else reject()

  run: (endpoint, arg1, arg2) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        procedure = @find_by_endpoint(endpoint)

        return reject(App.Schema.Form.Errors.ProcedureNotFound) unless procedure?

        procedure.run(arg1, arg2)
        .then resolve
        .catch reject
      .catch reject

  _request_schema: (options) ->
    new Promise (resolve, reject) =>
      @form().schema().then (form) =>
        @_route_get({
          api: { url: form.data.urls.procedures },
          channel: {
            event: "schema.load.procedures",
            data: { form: @form_permalink }
          },
          options: options
        })
        .then resolve
        .catch reject

  schema: (options) ->
    source = @_requested_schema(options)
    source.promise ?= new Promise (resolve, reject) =>
      @_request_schema(options).then (response) =>
        for value in response.procedures
          @new(value, options)

        # procedures should not be deleted on the referenced response object
        data = Object.assign({}, response)
        delete data.procedures

        source.data = data
        source.loaded = true

        resolve(@)
    .catch (response) =>
      source.promise = undefined
      reject(response)

    source.promise

  require_schema: ->
    if !@loaded()
      throw new Error("method requires procedures.load() to be called and resolved")

  form: -> App.Models.Form.new(@form_permalink)
