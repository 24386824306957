
window.App ?= {}
window.App.Schema ?= {}
window.App.Schema.Form ?= {}

class window.App.Schema.Form.Header extends App.Schema.Base
  include @, App.Schema.SourceBase

  constructor: (@form_permalink, @data) ->
    undefined

  name: ->
    @data.name

  column_name: ->
    @data.variable_name

  variable_name: ->
    @data.variable_name

  tabular_name: ->
    @data.tabular_name || @data.name

  permalink: ->
    @data.variable_name

  field_type: ->
    @data.field_type

  form: ->
    App.Models.Form.new(@form_permalink)

  _request_schema: (options) ->
    @_route_get({
      api: { url: @data.urls.self },
      channel: {
        event: "schema.load.header",
        data: {
          form: @form_permalink,
          header: @variable_name()
        }
      },
      options: options
    })

  schema: (options) ->
    source = @_requested_schema(options)
    source.promise ?= new Promise (resolve, reject) =>
      @_request_schema(options).then (response) =>
        @load_data(response, options)
        resolve(@)
      .catch (response) =>
        source.promise = undefined
        reject(response)

    source.promise

  matches: (parameter, value) ->
    member = @[parameter]

    return true if !member? && !value?
    return false if !member? && !@data?

    if parameter == "column_name" || parameter == "variable_name"
      if value == "created_at"
        value = "created"
      else if value == "updated_at"
        value = "updated"
      else if value == "hidden_at"
        value = "deleted"

    member ?= @data[parameter]

    return true if !member? && !value?

    member_is_function = App.Helpers.Objects.isFunction(member)

    if member_is_function
      return true if member.call(@) == value
    else
      return true if member == value

    false

  destroy: ->
    Promise.reject("Headers cannot be destroyed")

  present: (value) ->
    @_presented_values ?= {}

    if !value? || value == ""
      return Promise.resolve()

    if @_presented_values[value]?
      return Promise.resolve(@_presented_values[value])

    new Promise (resolve, reject) =>
      @schema().then =>
        $.ajax
          url: Routes.field_presenter_path(@form().permalink(), @variable_name())
          type: "POST"
          data: {
            authenticity_token: current_user.authenticity_token(),
            value: value
          }
        .done (response) =>
          @_presented_values[value] = response.value
          resolve(response.value)
        .fail -> resolve()

  @columns: [
    "time", "created_at", "created", "updated_at", "updated",
    "hidden_at", "hidden", "deleted", "creator", "author", "permalink",
  ]
