
$(document).on "click", ".toggle_column", (event) ->
  cell = event.currentTarget
  index = App.Helpers.Elements.index(cell) + 1
  table = App.Helpers.Elements.closest(cell, ".table_container")

  checkboxes = table.querySelectorAll("td:nth-child(#{index}) input[type=checkbox]")
  checkboxes_are_checked = true

  for checkbox in checkboxes
    checkboxes_are_checked = false unless checkbox.checked

  status = !checkboxes_are_checked

  for checkbox in checkboxes
    if !checkbox.disabled
      checkbox.checked = status
      checkbox.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))

  undefined

$(document).on "click", ".toggle_row", (event) ->
  return if event.target != event.currentTarget && !event.target.classList.contains("td-content")

  cell = event.currentTarget
  row = App.Helpers.Elements.closest(cell, "tr")

  checkboxes = row.querySelectorAll("input[type=checkbox]")
  checkboxes_are_checked = true

  for checkbox in checkboxes
    checkboxes_are_checked = false unless checkbox.checked

  status = !checkboxes_are_checked

  for checkbox in checkboxes
    if !checkbox.disabled
      checkbox.checked = status
      checkbox.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))

$(document).on "click", ".cell_overlay_background", (event) ->
  event.preventDefault()

  App.Helpers.Tables.close_cell_overlay()
