
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}
window.App.Elements.ResponseTable.Row ?= {}

# Aggregation rows only have cells for actively aggregated cells, and use colspan to make up the difference.
# The first cell contains the operator but is usually wider than the checkbox.
# To keep the checkbox column as thin as possible, the operator cell expands to fit available space before
# the first aggregated column. Aggregated columns do the same.
window.App.Elements.ResponseTable.Row.remove_column_from_aggregation = (index, row) ->
  cell_at_index = null
  total_index = -1

  for cell, cell_index in row.children
    if !cell_at_index?
      cell_width = if cell.colSpan? then parseInt(cell.colSpan) else 1
      total_index += cell_width

      if total_index >= index
        cell_at_index = cell

  if cell_at_index.colSpan? && parseInt(cell_at_index.colSpan) > 1
    cell_at_index.colSpan = parseInt(cell_at_index.colSpan) - 1
  else
    cell_at_index.style.display = 'none'

  row
