
window.App ?= {}
window.App.Channels ?= {}
window.App.Channels.FrameEvent ?= {}

window.App.Channels.default_ready_data = ->
  { params: App.filtered_params() }

window.App.child = -> App.Channels.parent?

class window.App.FrameChannel
  constructor: (@frame) ->
    if !@frame?
      throw new Error(
        "contentWindow is undefined; the frame must be loaded before App.FrameChannel is defined."
      )

    @listeners = []
    @contact_interval_id = undefined
    @contact_established = false

    @_log_incoming_events = true

    @_sent_count = 0
    @_received_count = 0

    @_queued_incoming_events = []
    @_queued_outgoing_events = []

    window.addEventListener "message", (event) =>
      @handle_event(event) if event.data.channel == true

    @on "system.contact_request", =>
      @publish("system.contact") if document.readyState == "complete"

    @on "system.contact", (event) =>
      clearInterval(@contact_interval_id)
      @contact_established = true
      @origin = event.origin

      @resolve_contact(event)

    @

  open: (data = {}) ->
    return @_contact_promise if @_contact_promise?

    @_contact_promise = new Promise (resolve, reject) =>
      @resolve_contact = resolve
      @reject_contact = reject

    data.origin = window.location.origin

    @contact_interval_id = setInterval((=>
      @publish("system.contact_request", data)
    ), 200)

    @_contact_promise

  queue_incoming_event: (event) ->
    return if @_queued_incoming_events.indexOf(event) != -1
    @_queued_incoming_events.push(event)

  handle_queued_incoming_events: ->
    return if @_queued_incoming_events.length == 0

    active_event = undefined

    for event in @_queued_incoming_events
      if @_received_count == event.data.count
        active_event = event

    if active_event?
      @_queued_incoming_events.splice(@_queued_incoming_events.indexOf(active_event), 1)
      @handle_event(active_event)

  queue_outgoing_event: (name, body, id) ->
    event_data = { type: name, body: body, id: id }
    @_queued_outgoing_events.push(event_data)

    new App.Channels.FrameEvent.Sent(@, event_data)

  handle_queued_outgoing_events: ->
    return unless @contact_established
    return unless @_queued_outgoing_events.length > 0

    event = @_queued_outgoing_events[0]

    @_queued_outgoing_events.splice(0, 1)
    @publish(event.type, event.body, event.id)

  handle_queued_events: ->
    @handle_queued_incoming_events()
    @handle_queued_outgoing_events()

  handle_event: (event) ->
    return if event.source != @frame

    system_event = App.Helpers.Events.matches_scope("system", event.data.type)

    event_in_order = @_received_count == event.data.count
    event_queued = !@contact_established || !event_in_order

    if event_queued && !system_event
      @log_incoming_event(event, false) if @_log_incoming_events
      return @queue_incoming_event(event)

    @log_incoming_event(event) if @_log_incoming_events

    event_data = App.Channels.deserialize_event(@, event.data)
    received_event = new App.Channels.FrameEvent.Received(@, event_data)
    @_received_count += 1 unless system_event

    for listener in @listeners
      listener.run(received_event) if listener.matches(received_event)

    @handle_queued_events()

    undefined

  log_incoming_event: (event, delivered = true) ->
    if @ == App.Channels.parent
      if delivered
        symbol = "←"
      else
        symbol = "⇤"
    else
      if delivered
        symbol = "→"
      else
        symbol = "⇥"

    console.log "#{symbol} #{event.data.type}"

  on: (name, callback) ->
    listener = new App.Channels.FrameEventListener(name, callback)

    @listeners.push(listener)

    listener

  off: (selector) ->
    if selector instanceof App.Channels.FrameEventListener
      @listeners.splice(@listeners.indexOf(selector))
    else
      # Increment backwards because the array is being mutated
      for listener, index in @listeners by -1
        @listeners.splice(index) if listener.matches(selector)

    true

  subscribe: (name, callback) -> @on(name, callback)

  publish: (name, body, id = undefined) ->
    id ?= App.Helpers.Generators.uuid()
    system_event = App.Helpers.Events.matches_scope("system", name)

    return @queue_outgoing_event(name, body, id) if !@contact_established && !system_event

    origin = if @origin? && @origin.length > 0 then @origin else "*"

    count = @_sent_count
    @_sent_count += 1 unless system_event

    event_data = App.Channels.serialize_event(@, {
      id: id,
      channel: true,
      count: count,
      type: name,
      body: body
    })

    if window.Worker? && @frame instanceof Worker
      @frame.postMessage(event_data)
    else
      @frame.postMessage(event_data, origin)

    @handle_queued_outgoing_events()

    new App.Channels.FrameEvent.Sent(@, event_data)

  close: ->
    @frame.close()

window.App.Channels.serialize_event = (channel, event_data) ->
  event_data.body = App.Channels.serialize_event_body(channel, event_data.body)
  event_data

window.App.Channels.serialize_event_body = (channel, object) ->
  return unless object?

  if Array.isArray(object)
    for value, index in object
      object[index] = App.Channels.serialize_event_body(channel, value)
  else if typeof object == "object"
    # Disallow events or DOM Nodes
    return if object.nodeType? || object.target?

    for key, value of object
      object[key] = App.Channels.serialize_event_body(channel, value)
  else if typeof object == "function"
    instance_id = App.Helpers.Generators.uuid()

    channel.subscribe("function.#{instance_id}", object)

    object = { _channel_object: "function", instance_id: instance_id }

  object

window.App.Channels.deserialize_event = (channel, event_data) ->
  event_data.body = App.Channels.deserialize_event_body(channel, event_data.body)
  event_data

window.App.Channels.deserialize_event_body = (channel, object) ->
  return unless object?

  if Array.isArray(object)
    for value, index in object
      object[index] = App.Channels.deserialize_event_body(channel, value)
  else if typeof object == "object"
    if object._channel_object == "function"
      object = ((channel, instance_id) ->
        (value) -> channel.publish("function.#{instance_id}", value)
      )(channel, object.instance_id)
    else
      for key, value of object
        object[key] = App.Channels.deserialize_event_body(channel, value)

  object

class window.App.Channels.FrameEventListener
  constructor: (names, @callback) ->
    @scopes = App.Helpers.Events.generate_scopes(names)

  matches: (event) ->
    return false if @scopes.indexOf(event.type) == -1

    true

  run: (event) ->
    @callback(event)

class window.App.Channels.FrameEvent.Sent
  constructor: (@channel, @_packet) ->
    true

  done: (callback) ->
    @promise().then callback

    @

  fail: (callback) ->
    @promise().catch callback

    @

  then: (callback1, callback2) ->
    @done(callback1) if callback1?
    @fail(callback2) if callback2?

    @

  catch: (callback) ->
    @fail(callback)

    @

  promise: ->
    return @_promise if @_promise?

    @_promise = new Promise (resolve, reject) =>
      identifier = App.Helpers.Generators.uuid()

      @channel.on "#{@_packet.id}.resolve.#{identifier}", (event) =>
        @channel.off("#{@_packet.id}.resolve.#{identifier}")
        @channel.off("#{@_packet.id}.reject.#{identifier}")

        resolve(event.data)

      @channel.on "#{@_packet.id}.reject", (event) =>
        @channel.off("#{@_packet.id}.resolve.#{identifier}")
        @channel.off("#{@_packet.id}.reject.#{identifier}")

        reject(event.data)

    @_promise

class window.App.Channels.FrameEvent.SentCollection
  constructor: (@events) ->
    true

  done: (callback) ->
    @promise().then callback

    @

  fail: (callback) ->
    @promise().catch callback

    @

  then: (callback1, callback2) ->
    @done(callback1) if callback1?
    @fail(callback2) if callback2?

    @

  catch: (callback) ->
    @fail(callback)

    @

  promise: ->
    return @_promise if @_promise?

    if @events.length == 0
      @_promise = Promise.resolve()
    else
      @_promise = new Promise (resolve, reject) =>
        event_promises = []
        event_promises.push(event.promise()) for event in @events

        Promise.all(event_promises)
        .then resolve
        .catch reject

class window.App.Channels.FrameEvent.Received
  constructor: (@channel, @_packet) ->
    @type = @_packet.type
    @data = @_packet.body || {}

  resolve: (value) ->
    @channel.publish "#{@_packet.id}.resolve", value

  reject: (value) ->
    @channel.publish "#{@_packet.id}.reject", value

class window.App.Channels.FrameEvent.ReceivedCollection
  constructor: (@events) ->
    true

  resolve: (value) -> event.resolve(value) for event in @events
  reject: (value) -> event.reject(value) for event in @events

# This is now set directly by the embedded layouts
window.App.is_child ?= false

if App.is_child
  window.App.Channels.parent = new App.FrameChannel(parent)
  window.App.parent = App.Channels.parent

  App.Channels.parent.open().then (event) ->
    window.App.is_sandbox = true if event.data.sandbox

  App.Channels.parent.on "ready", (event) ->
    App._parameters = event.data.params

    App.trigger("ready")
else
  if window.jQuery?
    $ -> App.trigger("ready")
  else
    document.onload = App.trigger("ready")

if window.opener?
  window.App.Channels.opener = new App.FrameChannel(opener)
  window.App.opener = App.Channels.opener

  App.Channels.opener.open()

window.App.is_iframe = false

try
  window.App.is_iframe = (window.self != window.top)
catch e
  window.App.is_iframe = true
