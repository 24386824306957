class window.OptionListWidget
  @interpolate_name: (text, iterator) ->
    text.replace("*", iterator)

  constructor: (@container) ->
    @container = @container[0] if App.Helpers.Elements.is_jquery(@container)
    @container.classList.add("initialized")

    @list = @container.querySelector(".option_list")
    @option_template = @list.querySelector(".option_container").cloneNode(true)

    Sortable.create(@list, {
      sort: true,
      disabled: false,
      draggable: ".option_container",
      handle: ".sort_option",
      ghostClass: "sortable-placeholder",
      scroll: true,
      onSort: => @calculate_ordering()
    })

    option_containers = @list.querySelectorAll(".option_container")

    @list.dataset.iterator = option_containers.length

    for container in option_containers
      option_fields = container.querySelectorAll(".option_field")

      for option_field in option_fields
        option_number = option_field.dataset.option
        option_name = option_field.dataset.name

        option_field.name = OptionListWidget.interpolate_name(option_name, option_number)

    @container.addEventListener "keyup", (event) =>
      if event.target.matches(".option_field.empty_option")
        @add_empty_option(event)

    @container.addEventListener "change", (event) =>
      if event.target.matches("select.option_field.empty_option")
        @add_empty_option(event)

    @container.addEventListener "click", (event) =>
      if App.Helpers.Elements.closest(event.target, ".add_option")
        return @add()

      if App.Helpers.Elements.closest(event.target, ".destroy_option")
        return @remove(event)

      if App.Helpers.Elements.closest(event.target, ".save_option_list")
        return @submit()

  add_empty_option: (event) ->
    option = event.target
    option_container = App.Helpers.Elements.closest(option, ".option_container")

    if option.value? && option.value.length > 0
      new_or_empty_options = option_container.querySelectorAll(".empty_option, .new_option")

      for new_or_empty_option in new_or_empty_options
        new_or_empty_option.classList.remove("new_option")
        new_or_empty_option.classList.remove("empty_option")

      @add(true)

  add: (options = {}) ->
    # Support both add(true) and add({ automatic: true })
    if options == true || options == false
      options = { automatic: options }

    existing_new_options = @container.querySelectorAll(".option_field.new_option")
    return if options.automatic && existing_new_options.length > 1

    @list.dataset.iterator = parseInt(@list.dataset.iterator) + 1

    iterator = @list.dataset.iterator
    input_name = @list.dataset.inputName

    new_container = @template(iterator)
    new_container_field = new_container.querySelector(".option_field")

    if input_name? && input_name.length > 0
      new_container_field.name = OptionListWidget.interpolate_name(input_name, iterator)

    if options.prepend
      @list.insertBefore(new_container, @list.firstChild)
    else
      if existing_new_options.length > 0
        last_new_option = existing_new_options[existing_new_options.length - 1]
        @list.insertBefore(new_container, last_new_option)
      else
        @list.appendChild(new_container)

    new_container

  remove: (event) ->
    option = event.target
    container = App.Helpers.Elements.closest(option, ".option_container")
    container.parentNode.removeChild(container)

    @add(true) unless @new_option_exists()

  calculate_ordering: ->
    for option, i in @container.querySelectorAll(".option_container")
      for input in option.querySelectorAll("input, select, textarea")
        if input.dataset.name? && input.dataset.name.length > 0
          input.name = OptionListWidget.interpolate_name(input.dataset.name, i)

    undefined

  calculate_positions: ->
    options = []

    for option, i in @container.querySelectorAll(".option_field")
      if option.value.trim().length > 0
        options.push(option.value)

    options

  submit: ->
    undefined

  template: (index) ->
    template = @option_template.cloneNode(true)

    option_field = template.querySelector(".option_field")
    option_field.classList.add("new_option")
    option_field.classList.add("empty_option")
    option_field.dataset.option = index

    template.querySelector(".sort_option").dataset.option = index
    template.querySelector(".disabled_sort_option").dataset.option = index
    template.querySelector(".destroy_option").dataset.option = index

    for input in template.querySelectorAll("input, select, textarea")
      input.value = ""

      if input.dataset.name? && input.dataset.name.length > 0
        input.name = OptionListWidget.interpolate_name(input.dataset.name, index)

    template

  new_option_exists: ->
    @container.querySelector(".new_option")?

App.Models.bind ".option_list_container", OptionListWidget
