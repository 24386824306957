
$(document).on "submit", ".move_file_modal form", (event) ->
  event.preventDefault()

  form = event.currentTarget
  container = App.Helpers.Elements.closest(form, ".modal-container")

  modal = App.Interface.Modal.find_by_id(container.id)
  return unless modal?

  table = App.Elements.AttachmentTable._models[modal.element.dataset.folder]
  return unless table?

  data = App.Helpers.Forms.data(form)
  new_table = App.Elements.AttachmentTable._models[data.parent_folder]

  App.Helpers.Forms.ajax_submit(form).then ->
    modal.unload()
    table.refresh()
    new_table.refresh() if new_table?
