
window.App ?= {}
window.App.Elements ?= {}

class window.App.Elements.ResponseShare
  constructor: (@submission, @modal) ->
    $(@modal.element).on "submit", ".submission_grant_form", (event) =>
      event.preventDefault()
      @submit()

    $(@modal.element).on "submit", ".new_submission_grant_form", (event) =>
      event.preventDefault()
      @persist_new_grant()

    $(@modal.element).on "click", ".new_submission_grant_button", (event) =>
      event.preventDefault()

      closest_form = App.Helpers.Elements.closest(event.currentTarget, "form")
      closest_form.dispatchEvent(App.Helpers.Events.new("submit", { bubbles: true }))

    $(@modal.element).on "click", ".submission_grant_remove", (event) =>
      event.preventDefault()

      closest_grant = App.Helpers.Elements.closest(event.currentTarget, ".submission_grant")
      closest_grant_index = closest_grant.dataset.index

      @remove_grant(closest_grant_index)

    $(@modal.element).on "change", ".submission_grant_permission", (event) =>
      closest_grant = App.Helpers.Elements.closest(event.currentTarget, ".submission_grant")
      closest_grant_index = closest_grant.dataset.index

      @submit_grant(closest_grant_index)

  sharing_wrapper: ->
    @modal.element.querySelector(".submission_sharing_wrapper")

  grant_element: (index) ->
    return unless index?
    @sharing_wrapper().querySelector(".submission_grant[data-index='#{index}']")

  grant_form: ->
    @sharing_wrapper().querySelector(".submission_grant_form")

  grant_submit_button: ->
    @modal.element.querySelector(".submission_grant_submit")

  persist_new_grant: ->
    element = @modal.element

    new_grant_form = element.querySelector(".new_submission_grant_form")
    new_grant_email_input = new_grant_form.querySelector(".new_submission_grant_email_address")

    email_address = new_grant_email_input.value
    return unless email_address? && email_address.length > 0

    new_grant_email_input.value = ""

    @add_grant(email_address)

  add_grant: (email_address) ->
    element = @modal.element

    grant_form = @grant_form()
    grant_index = parseInt(grant_form.dataset.index)
    grant_form.dataset.index = grant_index + 1

    wrapper = document.createElement("DIV")
    wrapper.className = "submission_grant"
    wrapper.dataset.index = grant_index

    header = document.createElement("DIV")
    header.className = "submission_grant_header"

    header_email = document.createElement("SPAN")
    header_email.className = "submission_grant_email"
    header_email.textContent = email_address

    header_remove = document.createElement("A")
    header_remove.className = "submission_grant_remove"
    header_remove.title = "Remove Email Address"
    header_remove.href = "#"
    header_remove.innerHTML = "&times;"

    header_email_input = document.createElement("INPUT")
    header_email_input.type = "hidden"
    header_email_input.name = "grants[#{grant_index}][email_address]"
    header_email_input.value = email_address

    header_permalink_input = document.createElement("INPUT")
    header_permalink_input.type = "hidden"
    header_permalink_input.name = "grants[#{grant_index}][permalink]"

    header_destroy_input = document.createElement("INPUT")
    header_destroy_input.type = "hidden"
    header_destroy_input.name = "grants[#{grant_index}][_destroy]"
    header_destroy_input.value = false

    header.appendChild(header_email)
    header.appendChild(header_remove)
    header.appendChild(header_email_input)
    header.appendChild(header_permalink_input)
    header.appendChild(header_destroy_input)

    permissions_wrapper = document.createElement("DIV")
    permissions_wrapper.className = "submission_grant_permissions"

    permissions_column_wrapper = document.createElement("DIV")
    permissions_column_wrapper.className = "column-wrapper"

    permissions_read_column = document.createElement("DIV")
    permissions_read_column.className = "column"
    permissions_read_column.appendChild App.Helpers.Inputs.checkbox_tag(
      "grants[#{grant_index}][permissions][read]", "Read", true, class: "submission_grant_permission"
    )

    permissions_update_column = document.createElement("DIV")
    permissions_update_column.className = "column"
    permissions_update_column.appendChild App.Helpers.Inputs.checkbox_tag(
      "grants[#{grant_index}][permissions][update]", "Update", class: "submission_grant_permission"
    )

    permissions_column_wrapper.appendChild(permissions_read_column)
    permissions_column_wrapper.appendChild(permissions_update_column)

    permissions_wrapper.appendChild(permissions_column_wrapper)

    wrapper.appendChild(header)
    wrapper.appendChild(permissions_wrapper)

    grant_form.appendChild(wrapper)

    @submit_grant(grant_index)

    wrapper

  remove_grant: (index) ->
    grant = @grant_element(index)
    grant_destroy = grant.querySelector("[name='grants[#{index}][_destroy]']")
    grant_destroy.value = true
    grant.style.display = "none"

    @submit_grant(index)

  submit_grant: (index) ->
    submit_button = @grant_submit_button()
    add_loader submit_button

    element = @grant_element(index)

    submit_path = Routes.update_submission_email_permissions_path(
      @submission.form().permalink(),
      @submission.permalink()
    )

    submit_data = App.Helpers.Forms.data(element)
    submit_data.authenticity_token = current_user.authenticity_token()
    submit_data.grants = submit_data.grants.filter((el) -> el?)

    $.ajax
      url: submit_path
      type: "PATCH"
      data: submit_data
    .done (response) =>
      permalink_input = element.querySelector("input[name='grants[#{index}][permalink]']")
      permalink_input.value = response.grants["0"]

      remove_loader submit_button
    .fail (xhr) ->
      App.Errors.xhr_error(xhr)
      remove_loader submit_button

  submit: ->
    submit_button = @grant_submit_button()
    return if loading submit_button
    add_loader submit_button

    submit_path = Routes.update_submission_email_permissions_path(
      @submission.form().permalink(),
      @submission.permalink()
    )

    submit_data = App.Helpers.Forms.data(@grant_form())
    submit_data.authenticity_token = current_user.authenticity_token()

    $.ajax
      url: submit_path
      type: "PATCH"
      data: submit_data
    .done (response) =>
      @modal.destroy()
      @submission.modals._sharing_modal = undefined
    .fail (xhr) ->
      App.Errors.xhr_error(xhr)
      remove_loader submit_button
