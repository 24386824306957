
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.StickyHeaders = {
  initialize_sticky_headers: ->
    $(@element).stickyTableHeaders({
      scrollableArea: $(@element).closest(".main_view_page"),
      cacheHeaderHeight: true
    })

    $(@element).on "enabledStickiness.stickyTableHeaders", (event) =>
      @table_head_element.classList.add("sticky_thead")

      for column in @columns()
        column.find_all_colhead_elements()
        #element = @table_head_element.querySelector(".colhead[data-column-name='#{column.field_id}']")

      @apply_resizing()

    $(@element).on "disabledStickiness.stickyTableHeaders", (event) =>
      @table_head_element.classList.remove("sticky_thead")
      @apply_resizing()

    @

  header_is_sticky: ->
    @table_head_element.classList.contains("sticky_thead")
}
