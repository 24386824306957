
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseQuery ?= {}
window.App.Elements.ResponseQuery._models ?= {}

window.App.Elements.ResponseQuery.find_by_container = (container) ->
  for id, model of App.Elements.ResponseQuery._models
    return model if model.container == container

  undefined

class window.App.Elements.ResponseQuery.Query
  constructor: (form, @query, @editor_query) ->
    @form_permalink = form.permalink()
    @instance_id = App.Helpers.Generators.uuid()

    App.Elements.ResponseQuery._models[@instance_id] = @

    @query ?= new App.Query.Group(form.permalink())

    # Clone the query to provide a model for the interface to reference
    # without adding unsaved conditions to the query itself.
    @editor_query ?= @query.clone()

    @callbacks = new CallbackManager(@)

    @generate_modal()

    @update_editor()

  on: (event, callback) -> @callbacks.on(event, callback)
  changed: -> @_changed == true
  form: -> App.Models.Form.new(@form_permalink)

  load: (options) ->
    new Promise (resolve, reject) =>
      @form().schema(deleted_fields: true).then =>
        if !@initial_modal_actions
          @modal.actions App.Elements.ResponseQuery.Query.generate_modal_actions(@)
          @initial_modal_actions = true

        @modal.load().then =>
          if options? && options.build == true && !@editor_query.any()
            @build_condition()

          resolve(@)

  show: (options) ->
    new Promise (resolve, reject) =>
      @load(options).then =>
        @modal.show().then =>
          resolve(@)

  hide: ->
    return true unless @modal?

    @modal.hide()

  update_report: (data = {}) ->
    new Promise (resolve, reject) =>
      @update_query()

      data.conditions = @query.serialize()

      @report.update(data).then (report) =>
        App.Interface.Sidebar.overview().refresh()

        if @modal.loaded()
          @modal.actions App.Elements.ResponseQuery.Query.generate_modal_actions(@)

        resolve(@)

        @callbacks.trigger("report.update", report)

  create_report: (data = {}) ->
    new Promise (resolve, reject) =>
      @update_query()

      @form().schema().then =>
        data.conditions = @query.serialize()

        @form().reports.create(data).then (report) =>
          App.Interface.Sidebar.overview().refresh()

          @report = report

          if @modal.loaded()
            @modal.actions App.Elements.ResponseQuery.Query.generate_modal_actions(@)

          resolve(@)

          @callbacks.trigger("report.create", report)

  create_report_with_name: (event) ->
    button = event.currentTarget if event?
    add_loader button

    new Promise (resolve, reject) =>
      @request_rename().then (data) =>
        remove_loader button

        @create_report(data)
        .then resolve
        .catch reject

  update_report_with_name: (event) ->
    button = event.currentTarget if event?
    add_loader button

    new Promise (resolve, reject) =>
      @request_rename().then (data) =>
        remove_loader button

        @update_report(data)
        .then resolve
        .catch reject

  request_rename: ->
    new Promise (resolve, reject) =>
      if @report?
        name_url = Routes.rename_report_path(@form().permalink(), @report.permalink())
      else
        name_url = Routes.new_search_path(@form().permalink())

      Modal.from_url(name_url).show().then (modal) =>
        modal_form = modal.element.querySelector("form")

        modal_form.addEventListener "submit", (event) ->
          event.preventDefault()
          return false

        private_report = modal.element.querySelector(".create_private_report")

        if private_report?
          private_report.addEventListener "click", (event) =>
            event.preventDefault()

            name = App.Helpers.Forms.data(modal.element).report.name
            modal.unload()

            resolve({ name: name, personal: true })

        public_report = modal.element.querySelector(".create_public_report, .update_report")

        if public_report?
          public_report.addEventListener "click", (event) =>
            event.preventDefault()

            name = App.Helpers.Forms.data(modal.element).report.name
            modal.unload()

            resolve({ name: name })

      .catch (data) ->
        remove_loader button
        reject(data)

  # Update the query with the unsaved changes made to the editor_query
  update_query: ->
    first_change = !@changed()

    @_changed = true

    @query.load(@editor_query.serialize(instance: true))

    @update_editor()

    if first_change
      @callbacks.trigger("change.initial")
    else
      @callbacks.trigger("change")

    @query

  # Refresh the editor_query and editor element display from the query
  update_editor: (reload_query = true) ->
    # Cut down on the number of draws by hashing the serialized editor state.
    # Objects like select2 may be recreated on each draw, so draws should be minimized.
    serialized_query = @query.serialize(instance: true)
    serialized_hash = App.Helpers.Strings.hash(JSON.stringify(serialized_query))
    return if reload_query && @loaded_editor_hash? && @loaded_editor_hash == serialized_hash

    if reload_query
      @editor_query.load(serialized_query)
      @loaded_editor_hash = serialized_hash

    modal_body = @modal.element.querySelector(".body")
    wrapper = modal_body.querySelector(".response_conditions_wrapper")

    if !wrapper?
      wrapper = document.createElement("DIV")
      wrapper.className = "response_conditions_wrapper"
      modal_body.insertBefore(wrapper, modal_body.firstChild)

    selected_header = wrapper.querySelector(".response_condition_header.selected")
    if selected_header?
      selected_element = App.Helpers.Elements.closest(selected_header, ".response_condition")
      selected_instance = selected_element.dataset.instance

    wrapper.innerHTML = ""
    wrapper.appendChild App.Elements.ResponseQuery.Query.build_group_element(@editor_query)

    if selected_instance?
      selected_element = wrapper.querySelector(".response_condition[data-instance='#{selected_instance}']")

      if selected_element?
        App.Elements.ResponseQuery.Query.toggle_condition_drawer(selected_element, true)

  build_condition: (group, options = {}) ->
    group ?= @editor_query
    options.field ?= @form().fields.queryable()[0]

    group_element = @container.querySelector(".response_condition_group[data-instance='#{group.instance_id}']")
    group_children = group_element.querySelector(".response_condition_group_children")

    new_condition = group.condition(options)

    if options.type == "group"
      element = App.Elements.ResponseQuery.Query.build_group_element(new_condition)
    else
      element = App.Elements.ResponseQuery.Query.build_condition_element(new_condition)

    group_children.appendChild(element)

    if options.type == "group"
      wrapper = App.Helpers.Elements.closest(group_element, ".response_conditions_wrapper")
      App.Elements.ResponseQuery.Query.hide_all_condition_drawers(wrapper)
    else
      App.Elements.ResponseQuery.Query.toggle_condition_drawer(element, true)

    new_condition

  generate_modal: ->
    @modal = App.Interface.Modal.new(i18n.t("advanced_search"), "", {}, size: "large", visible: false)
    @modal.on "show", => @update_editor()

    @container = @modal.element.querySelector(".body")

    @modal

  @generate_modal_actions: (response_query) ->
    form = response_query.form()
    report_createable = form.has_permission("read")

    options = []

    if response_query.report? && report_createable
      options.push({
        text: i18n.t("save"),
        type: "primary",
        align: "right",
        callback: -> response_query.update_report()
      })

      options.push({
        text: i18n.t("cancel"),
        type: "secondary",
        align: "right"
      })

      options.push({
        text: i18n.t("preview"),
        type: "secondary",
        align: "left",
        callback: -> response_query.update_query()
      })
    else
      options.push({
        text: i18n.t("ok"),
        type: "primary",
        align: "right",
        callback: -> response_query.update_query()
      })

      options.push({
        text: i18n.t("cancel"),
        type: "secondary",
        align: "right"
      })

      if current_user.feature("reports")
        options.push({
          text: i18n.t("reports.save"),
          type: "secondary",
          align: "left",
          if: report_createable,
          callback: (event) ->
            button = event.currentTarget
            return if loading button

            response_query.create_report_with_name(event)
        })
      else
        # TODO: Save Report button advertising Team Plan

    options

  @generate_model_from_element: (query, element) ->
    closest_group = App.Helpers.Elements.closest(group, ".response_condition_group")

    if closest_group?
      parent_instance = closest_group.dataset.instance
      parent_model = query.find_condition_by_instance_id(parent_instance)
    else
      parent_model = query

    if element.classList.contains("response_condition")
      parent_model.condition()
    else
      parent_model.group()

  @build_child_element: (child) ->
    if child instanceof App.Query.Condition.Base
      return @build_condition_element(child)
    else if child instanceof App.Query.Group
      return @build_group_element(child)

    undefined

  @build_group_element: (group) ->
    group_element = document.createElement("DIV")
    group_element.className = "response_condition_group"
    group_element.dataset.instance = group.instance_id

    group_element.appendChild @build_group_header(group)

    group_children = document.createElement("DIV")
    group_children.className = "response_condition_group_children"

    for child in group.all()
      group_children.appendChild @build_child_element(child)

    group_element.appendChild group_children

    group_element

  @build_group_header: (group) ->
    element = document.createElement("DIV")
    element.className = "response_condition_group_header"

    destroy_element = document.createElement("A")
    destroy_element.className = "response_condition_header_destroy"
    destroy_element.innerHTML = "&times;"
    destroy_element.title = i18n.t("form_query.group.titles.destroy")

    operator_element_name = "response_condition_#{group.instance_id}_operator_checkbox"
    operator_element_checked = (group.logical_operator() == "and")

    operator_element = App.Helpers.Inputs.labeled_toggle(
      operator_element_name, operator_element_checked, i18n.t("reports.any"), i18n.t("reports.all")
    )

    operator_element.classList.add("response_condition_group_operator")

    element.appendChild(destroy_element)
    element.appendChild @build_group_header_options(group)
    element.appendChild(operator_element)

    element

  @build_group_header_options: (group) ->
    wrapper = document.createElement("DIV")
    wrapper.className = "response_condition_buttons"

    group_option = document.createElement("A")
    group_option.href = "#"
    group_option.className = "response_condition_button response_condition_group_add_group"

    group_option_icon = document.createElement("I")
    group_option_icon.className = "icon icon-plus-circled"

    group_option_text = document.createTextNode(i18n.t("reports.group"))

    group_option.appendChild(group_option_icon)
    group_option.appendChild(group_option_text)

    condition_option = document.createElement("A")
    condition_option.href = "#"
    condition_option.className = "response_condition_button response_condition_group_add_condition"

    condition_option_icon = document.createElement("I")
    condition_option_icon.className = "icon icon-plus-circled"

    condition_option_text = document.createTextNode(i18n.t("reports.condition"))

    condition_option.appendChild(condition_option_icon)
    condition_option.appendChild(condition_option_text)

    wrapper.appendChild(condition_option)
    wrapper.appendChild(group_option)

    wrapper

  @build_condition_element: (condition) ->
    condition_element = document.createElement("DIV")
    condition_element.className = "response_condition"
    condition_element.dataset.instance = condition.instance_id

    condition_element.appendChild @build_condition_header(condition)
    condition_element.appendChild @build_condition_form(condition)

    @check_operator_value(condition, condition_element)

    condition_element

  @build_condition_header: (condition) ->
    if condition.field?
      field_name = condition.field.name()
      field_type = condition.field.field_type()

    element = document.createElement("A")
    element.href = "#"
    element.className = "response_condition_header"

    field_element = document.createElement("SPAN")
    field_element.className = "response_condition_header_field"
    field_element.textContent = field_name if field_name?
    element.appendChild(field_element)

    operator = condition.operator() || "=="

    operator_element = document.createElement("SPAN")
    operator_element.className = "response_condition_header_operator"

    operator_element.textContent = App.Query.Condition.operator_name(
      field_type, operator
    )

    element.appendChild(operator_element)

    if @operator_has_value(operator)
      value_element = document.createElement("SPAN")
      value_element.className = "response_condition_header_value"

      condition_value = condition.value() || "Value"
      condition_value = condition_value.join(", ") if Array.isArray(condition_value)

      value_element.textContent = condition_value
      element.appendChild(value_element)

      condition.present().then (value) -> value_element.textContent = value

    destroy_element = document.createElement("A")
    destroy_element.className = "response_condition_header_destroy"
    destroy_element.href = "#"
    destroy_element.title = i18n.t("form_query.condition.titles.destroy")
    destroy_element.innerHTML = "&times;"
    element.appendChild(destroy_element)

    element

  @replace_condition_header: (condition, element) ->
    old_header = element.querySelector(".response_condition_header")
    new_header = @build_condition_header(condition)

    if old_header.classList.contains("selected")
      new_header.classList.add("selected")

    old_header.parentNode.replaceChild(new_header, old_header)

  @check_operator_value: (condition, element) ->
    old_value = element.querySelector(".response_condition_value_container")
    new_value = App.Elements.ResponseQuery.Query.build_condition_form_value(condition)

    old_value.parentNode.replaceChild(new_value, old_value)

    value_container = element.querySelector(".response_condition_value_container")

    if @operator_has_value(condition.operator())
      value_container.style.display = "block"
    else
      value_container.style.display = "none"

  @operator_has_value: (operator) ->
    operator.indexOf("?") == -1

  @build_condition_form: (condition) ->
    container = document.createElement("DIV")
    container.className = "response_condition_form"

    operator_has_value = condition.operator().indexOf("?") == -1

    container.appendChild @build_condition_form_field(condition)
    container.appendChild @build_condition_form_operator(condition)

    value_element = @build_condition_form_value(condition)
    value_element.style.display = "none" unless @operator_has_value(condition.operator())
    container.appendChild value_element

    container

  @build_condition_form_field: (condition) ->
    if condition.field?
      condition_field_variable = condition.field.variable_name()

    field_options = []

    for header in condition.form().headers.all()
      field_options.push([header.name(), header.variable_name()])

    for field in condition.form().fields.queryable()
      field_options.push([field.name(), field.variable_name()])

    @build_dropdown_field(
      condition, "field", condition_field_variable, field_options
    )

  @build_condition_form_operator: (condition) ->
    field_type = condition.field.field_type()
    field_operator = condition.operator() || "=="

    operator_options = []

    constructor = App.Query.Condition.class_from_schema(condition.field)

    for operator in constructor.operators
      operator_name = App.Query.Condition.operator_name(field_type, operator)
      operator_options.push([operator_name, operator])

    @build_dropdown_field(
      condition, "operator", field_operator, operator_options
    )

  @build_condition_form_value: (condition) ->
    if condition instanceof App.Query.HeaderCondition.Base
      #if !condition instanceof App.Query.HeaderCondition.User
      return @build_text_field(condition, "value", condition.value())

    if @is_complex_field_type(condition)
      return @build_text_field(condition, "value", condition.value())

    operator = condition.operator()

    switch operator
      when ">", ">=", "<", "<="
        output = @build_dropdown_field(condition, "value", condition.value(), undefined, false, true)
      else
        output = @build_dropdown_field(condition, "value", condition.value(), undefined, true, true)

    select = output.querySelector("select")

    $(select).select2({
      tags: true,
      delay: 250,
      minimumInputLength: 0,
      tokenSeparator: ","
      ajax: {
        url: Routes.unique_field_values_path(
          condition.form().permalink(),
          condition.field.variable_name(),
        )
        data: (params) ->
          {
            _type: params._type,
            length: 100,
            page: params.page,
            q: params.term,
            escape: false
          }
      }
      createTag: (params) ->
        term = params.term.trim()
        return if term.length == 0
        { id: term, text: term }
      templateSelection: ((condition) ->
        (item) ->
          element = document.createElement("SPAN")
          element.textContent = item.text

          if condition.field? && condition.field instanceof App.Schema.Form.Field
            condition.field.present(item.element.value).then (value) ->
              element.textContent = value

          element
      )(condition)
    })

    output

  @build_text_field: (condition, subfield, value = "") ->
    container = document.createElement("DIV")
    container.className = "CONTAINER THIN response_condition_#{subfield}_container"
    container.dataset.fieldType = "text"

    container_title = document.createElement("LABEL")
    container_title.className = "TITLE"
    container_title.setAttribute("for", "response_condition_#{condition.instance_id}_#{subfield}")
    container_title.textContent = i18n.t("form_query.titles.#{subfield}")

    field_container = document.createElement("DIV")
    field_container.className = "FIELD_CONTAINER"
    field_container.dataset.fieldType = "text"

    field = document.createElement("DIV")
    field.className = "FIELD TEXT_FIELD"

    input = document.createElement("INPUT")
    input.id = "response_condition_#{condition.instance_id}_#{subfield}"
    input.className = "response_condition_#{subfield}"
    input.type = "text"
    input.value = value

    container.appendChild(container_title)
    container.appendChild(field_container)

    field_container.appendChild(field)

    field.appendChild(input)

    container

  @build_dropdown_field: (condition, subfield, value, options, multiple = false, select2 = false) ->
    container = document.createElement("DIV")
    container.className = "CONTAINER THIN response_condition_#{subfield}_container"
    container.dataset.fieldType = "dropdown"

    container_title = document.createElement("LABEL")
    container_title.className = "TITLE"
    container_title.setAttribute("for", "response_condition_#{condition.instance_id}_#{subfield}")
    container_title.textContent = i18n.t("form_query.titles.#{subfield}")

    field_container = document.createElement("DIV")
    field_container.className = "FIELD_CONTAINER"
    field_container.dataset.fieldType = "dropdown"

    field = document.createElement("DIV")
    field.className = "FIELD SELECT_FIELD"
    field.classList.add("SELECT2_FIELD") if select2

    select = document.createElement("SELECT")
    select.id = "response_condition_#{condition.instance_id}_#{subfield}"
    select.className = "response_condition_#{subfield}"
    select.multiple = "multiple" if multiple
    select.innerHTML = App.Helpers.Selects.options_for_select(options, value, create_selected: true)

    container.appendChild(container_title)
    container.appendChild(field_container)

    field_container.appendChild(field)

    field.appendChild(select)

    container

  @toggle_condition_drawer: (element, state) ->
    selected_header = element.querySelector(".response_condition_header")

    state ?= !selected_header.classList.contains("selected")
    return selected_header.classList.remove("selected") unless state

    wrapper = App.Helpers.Elements.closest(selected_header, ".response_conditions_wrapper")
    all_headers = wrapper.querySelectorAll(".response_condition_header")

    for header in all_headers
      if header == selected_header
        header.classList.add("selected")
      else
        header.classList.remove("selected")

    undefined

  @hide_all_condition_drawers: (wrapper) ->
    all_headers = wrapper.querySelectorAll(".response_condition_header")

    for header in all_headers
      header.classList.remove("selected")

    undefined

  @is_complex_field_type: (condition) ->
    return false unless condition.field?
    field_type = condition.field.field_type()
    field_type == "address" || field_type == "date_time" || field_type == "phone"
