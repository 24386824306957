
window.App ?= {}
window.App.Schema ?= {}
window.App.Schema.Form ?= {}

class window.App.Schema.Form.Field extends App.Schema.Base
  include @, App.Schema.SourceBase

  constructor: (@form_permalink, @data = {}) ->
    @callbacks = new CallbackManager(@)

    if App.is_child
      @_subscribe "update", (response) => @data = response

    undefined

  channel_namespace: -> "field.#{@data.permalink}"

  name: ->
    @data.name

  column_name: ->
    @data.variable_name

  variable_name: ->
    @data.variable_name

  tabular_name: ->
    @data.tabular_name || @data.name

  permalink: ->
    @data.permalink

  field_type: ->
    @data.field_type

  form: ->
    App.Models.Form.new(@form_permalink)

  connected_form: ->
    if !@loaded()?
      throw new Error("connected_form requires schema() to be called on the field")

    return unless @field_type() == "connection"

    App.Models.Form.new(@data.connection.form.permalink)

  _request_schema: (options) ->
    @_route_get({
      api: { url: @data.urls.self },
      channel: {
        event: "schema.load.field",
        data: {
          form: @form_permalink,
          field: @variable_name()
        }
      },
      options: options
    })

  schema: (options) ->
    source = @_requested_schema(options)
    source.promise ?= new Promise (resolve, reject) =>
      @_request_schema(options).then (response) =>
        @load_data(response, options)
        resolve(@)
      .catch (response) ->
        source.promise = undefined
        reject(response)

    source.promise

  matches: (parameter, value) ->
    member = @[parameter]

    return true if !member? && !value?
    return false if !member? && !@data?

    member ?= @data[parameter]

    return true if !member? && !value?

    member_is_function = App.Helpers.Objects.isFunction(member)

    if member_is_function
      return true if member.call(@) == value
    else
      return true if member == value

    false

  update: (parameters, options) ->
    @_route_update({
      api: {
        url: @data.urls.self,
        data: {
          field: App.Schema.Form.Calendar.parameters_to_data(parameters)
        }
      },
      channel: {
        event: "field.update",
        data: {
          form: @form_permalink,
          field: @permalink(),
          parameters: parameters
        }
      },
      options: options
    }).then =>
      return if App.is_child

      @callbacks.trigger("update", @)
      @form().fields.callbacks.trigger("update", @)

      App.Schema.change(@)

      @

  destroy: (options) ->
    new Promise (resolve, reject) =>
      @_route_destroy({
        api: {
          url: @data.urls.self
        },
        channel: {
          event: "field.destroy",
          data: {
            form: @form_permalink,
            field: @permalink()
          }
        },
        options: options
      }).then =>
        return if App.is_child

        @form().fields.reload(options).then =>
          @callbacks.trigger("destroy", @)
          @form().fields.callbacks.trigger("destroy", @)

          resolve(@)
      .catch reject

  presenter: (value) ->
    @present(value)

  present: (value) ->
    @_presented_values ?= {}

    if !value? || value == ""
      return Promise.resolve()

    if @_presented_values[value]?
      return Promise.resolve(@_presented_values[value])

    new Promise (resolve, reject) =>
      @schema().then =>
        $.ajax
          url: Routes.field_presenter_path(@form().permalink(), @permalink())
          type: "POST"
          data: {
            authenticity_token: current_user.authenticity_token(),
            value: value
          }
        .done (response) =>
          @_presented_values[value] = response.value
          resolve(response.value)
        .fail -> resolve()

  # TODO: Validate field parameters
  @parameters_to_data: (parameters) ->
    parameters

  @types = [
    "text", "check_box", "number", "text_area", "message", "dropdown", "connection", "rating",
    "boolean", "address", "phone", "date_time", "attachment", "user", "signature", "formula",
    "barcode", "button", "email"
  ]
