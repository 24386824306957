
$(document).on "click", ".response_condition_header_destroy", (event) =>
  event.preventDefault()
  event.stopPropagation()

  button = event.currentTarget
  modal = App.Helpers.Elements.closest(button, ".modal .body")
  query = App.Elements.ResponseQuery.find_by_container(modal)

  return throw "Query not found" unless query?

  element = App.Helpers.Elements.closest(button, ".response_condition, .response_condition_group")
  instance = element.dataset.instance

  condition = query.editor_query.find_condition_by_instance_id(instance)
  return throw "Condition not found" unless condition?

  condition.destroy() if condition?

  element.remove()

$(document).on "change", ".response_condition_field", (event) =>
  event.preventDefault()

  input = event.currentTarget
  modal = App.Helpers.Elements.closest(input, ".modal .body")
  query = App.Elements.ResponseQuery.find_by_container(modal)

  return throw "Query not found" unless query?

  element = App.Helpers.Elements.closest(input, ".response_condition")
  instance = element.dataset.instance

  condition = query.editor_query.find_condition_by_instance_id(instance)
  return throw "Condition not found" unless condition?

  condition = condition._set_field(input.value)

  old_operator = element.querySelector(".response_condition_operator_container")
  new_operator = App.Elements.ResponseQuery.Query.build_condition_form_operator(condition)

  old_operator.parentNode.replaceChild(new_operator, old_operator)

  new_operator_value = new_operator.querySelector(".response_condition_operator").value
  condition.operator(new_operator_value)

  App.Elements.ResponseQuery.Query.check_operator_value(condition, element)

  App.Elements.ResponseQuery.Query.replace_condition_header(condition, element)

$(document).on "change", ".response_condition_operator", (event) =>
  event.preventDefault()

  input = event.currentTarget
  modal = App.Helpers.Elements.closest(input, ".modal .body")
  query = App.Elements.ResponseQuery.find_by_container(modal)

  return throw "Query not found" unless query?

  element = App.Helpers.Elements.closest(input, ".response_condition")
  instance = element.dataset.instance

  condition = query.editor_query.find_condition_by_instance_id(instance)
  return throw "Condition not found" unless condition?

  condition.operator(input.value)

  App.Elements.ResponseQuery.Query.check_operator_value(condition, element)

  App.Elements.ResponseQuery.Query.replace_condition_header(condition, element)

$(document).on "change", ".response_condition_value", (event) =>
  event.preventDefault()

  input = event.currentTarget
  modal = App.Helpers.Elements.closest(input, ".modal .body")
  query = App.Elements.ResponseQuery.find_by_container(modal)

  return throw "Query not found" unless query?

  element = App.Helpers.Elements.closest(input, ".response_condition")
  instance = element.dataset.instance

  condition = query.editor_query.find_condition_by_instance_id(instance)
  return throw "Condition not found" unless condition?

  if input.tagName.toUpperCase() == "SELECT"
    condition.value App.Helpers.Selects.values(input)
  else
    condition.value input.value

  App.Elements.ResponseQuery.Query.replace_condition_header(condition, element)