import 'app/schema/base'
import 'app/schema/collection_base'
import 'app/schema/source_base'
import 'app/schema/source_collection_base'
import 'app/schema/form/field_manager'
import 'app/schema/form/field'
import 'app/schema/form/header_manager'
import 'app/schema/form/header'
import 'app/schema/form/calendar_manager'
import 'app/schema/form/calendar'
import 'app/schema/form/kanban_manager'
import 'app/schema/form/kanban'
import 'app/schema/form/procedure_manager'
import 'app/schema/form/procedure'
import 'app/schema/form/report_manager'
import 'app/schema/form/report'
import 'app/schema/submission/collection'
import 'app/schema/submission/promise'
import 'app/schema/submission/submission'
import 'app/schema/group'
import 'app/schema/user'





window.App.Schema.load = ->
  return App.Schema._schema_promise if App.Schema._schema_promise?

  new Promise (resolve, reject) ->
    $.ajax
      url: "/api/rest/v1"
    .done resolve
    .fail ->
      reject()

      App.Schema._schema_promise = undefined

# TODO: Implement any specific logic for changed schemas here.
window.App.Schema.change = (model) ->
  App.overview_sidebar().refresh()

  undefined

window.App.Schema.source_url = (default_url, options) ->
  if !App.Helpers.Objects.isString(default_url)
    options = default_url
    default_url = undefined

  return default_url unless options? && options.source?

  if App.Helpers.Objects.isString(options.source)
    return options.source

  if options.source instanceof App.Schema.Procedure
    return options.source.url()

  default_url
