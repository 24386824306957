
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentTable ?= {}
window.App.Elements.AttachmentTable.Helpers ?= {}

class window.App.Elements.AttachmentTable.Helpers.Selections
  constructor: (@table) ->
    @element = @_generate()
    @counter = @element.querySelector(".row_count")

    @_selections = []
    @_selections_deleted = []
    @_selections_visible = []

    @table.on "refresh", =>
      for selection in @all()
        @_select_checkbox(selection)

  _generate: ->
    element = document.createElement("DIV")
    element.className = "selection_bar"
    element.setAttribute("data-facility", current_user.facility_permalink)
    element.style.display = "none"

    counter = document.createElement("DIV")
    counter.className = "option static"
    counter.innerHTML = "<span class='row_count'></span>"

    delete_option = document.createElement("A")
    delete_option.href = "#"
    delete_option.className = "option right option-button red delete_rows"
    delete_option.textContent = "Delete"
    delete_option.addEventListener "click", (e) =>
      e.preventDefault()
      @destroy_selections(e)

    restore_option = document.createElement("A")
    restore_option.href = "#"
    restore_option.className = "option right option-button teal restore_rows"
    restore_option.textContent = "Restore"
    restore_option.addEventListener "click", (e) =>
      e.preventDefault()
      @restore_selections(e)

    cancel_option = document.createElement("A")
    cancel_option.href = "#"
    cancel_option.className = "option right cancel_rows"
    cancel_option.textContent = "Cancel"
    cancel_option.addEventListener "click", (e) =>
      e.preventDefault()
      @deselect_all(e)

    element.appendChild(counter)
    element.appendChild(delete_option)
    element.appendChild(restore_option)
    element.appendChild(cancel_option)

    parent = App.Helpers.Elements.closest(@table.element, ".main_view_page, body")
    parent.appendChild(element)

    element

  any: -> @_selections.length > 0

  # Returns an array of permalinks for selections
  all: -> @_selections

  # Returns an array of permalinks for selections that are deleted
  all_deleted: -> @_selections_deleted

  # Returns an array of permalinks for selections that are not deleted
  all_visible: -> @_selections_visible

  _select_checkbox: (permalink) ->
    checkbox = @table.element.querySelector("input[type=checkbox][data-permalink='#{permalink}']")
    checkbox.checked = true if checkbox?

  # Select a row by passing it's permalink and the checkbox used to select it
  select: (permalink) ->
    deleted = @table.params.deleted()

    return unless @_selections.indexOf(permalink) == -1

    @_selections.push(permalink)

    if deleted
      @_selections_deleted.push(permalink) if @_selections_deleted.indexOf(permalink) == -1
    else
      @_selections_visible.push(permalink) if @_selections_visible.indexOf(permalink) == -1

    @_select_checkbox(permalink)

    @draw()

  select_all: ->
    checkboxes = @table.element.querySelectorAll("input.row_select[type=checkbox]")

    @select(checkbox.dataset.permalink) for checkbox in checkboxes

    @draw()

  _deselect_checkbox: (permalink) ->
    checkbox = @table.element.querySelector("input[type=checkbox][data-permalink='#{permalink}']")
    checkbox.checked = false if checkbox?

  # Deselect a row by passing it's permalink
  deselect: (permalink) ->
    index = @_selections.indexOf(permalink)
    deleted_index = @_selections_deleted.indexOf(permalink)
    visible_index = @_selections_visible.indexOf(permalink)

    @_selections.splice(index, 1) if index != -1
    @_selections_deleted.splice(deleted_index, 1) if deleted_index != -1
    @_selections_visible.splice(visible_index, 1) if visible_index != -1

    @_deselect_checkbox(permalink)

    @draw()

  deselect_all: ->
    cloned_selections = @_selections.slice(0)
    @table.element.querySelector(".row_select_all").checked = false

    @deselect(selection) for selection in cloned_selections

    @draw()

  toggle_all: (status = true) ->
    if status
      @select_all()
    else
      @deselect_all()

  destroy_selections: (event) ->
    button = $(event.currentTarget) if event && event.currentTarget

    if button?
      return if button.hasClass("loading")
      add_loader button

    $.ajax
      url: Routes.bulk_delete_files_path()
      type: "POST"
      data: {
        selections: @all().join(","),
        authenticity_token: current_user.authenticity_token()
      }
    .done (response) =>
      remove_loader button
      @deselect_all()

      @table.refresh()
    .fail (xhr) ->
      remove_loader button
      App.Errors.response_error(xhr)

  restore_selections: (event) ->
    button = $(event.currentTarget) if event && event.currentTarget

    if button?
      return if button.hasClass("loading")
      add_loader button

    $.ajax
      url: Routes.bulk_restore_files_path()
      type: "POST"
      data: {
        selections: @all().join(","),
        authenticity_token: current_user.authenticity_token()
      }
    .done (response) =>
      remove_loader button
      @deselect_all()

      @table.refresh()
    .fail (xhr) ->
      remove_loader button
      App.Errors.response_error(xhr)

  draw: ->
    count = @all().length
    deleted_count = @all_deleted().length
    visible_count = @all_visible().length

    @counter.textContent = i18n.t("files_selected", { count: count })

    if count == 0
      $(@element).slideUp(250)
    else
      $(@element).slideDown(250)

      delete_button = @element.querySelector(".delete_rows")
      restore_button = @element.querySelector(".restore_rows")

      if visible_count == 0
        delete_button.classList.add("hidden")
      else
        delete_button.classList.remove("visible")

      if deleted_count == 0
        restore_button.classList.add("hidden")
      else
        restore_button.classList.remove("hidden")

    @element
