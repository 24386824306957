
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}
window.App.Frame.Sandbox.Bind ?= {}

window.App.Frame.Sandbox.Bind.submissions = (channel, options) ->
  if options.read == true
    channel.on "submissions.load", (event) ->
      form = App.Models.Form.new(event.data.form_permalink)

      if event.data.new_submission
        if event.data.hidden || event.data.first_unsaved == false
          request = form.submissions.new()
        else
          request = form.submissions.new_or_unsaved()
      else
        if event.data.hidden
          request = form.submissions.load(event.data.permalink)
        else
          request = form.submissions.open(event.data.permalink)

      request
      .then (submission) ->
        submission.sandboxes.add(channel)
        event.resolve(submission.serialize())
      .catch event.reject

  if options.read == true
    channel.on "submissions.count", (event) ->
      form = App.Models.Form.new(event.data.form_permalink)

      form.count()
      .then event.resolve
      .catch event.reject

  if options.read == true
    channel.on "submissions.query", (event) ->
      form = App.Models.Form.new(event.data.form_permalink)

      form.submissions.query(event.data.conditions, event.data.options)
      .then (submissions) -> event.resolve(submissions.serialize())
      .catch event.reject

  if options.read == true
    channel.on "submissions.query_new", (event) ->
      form = App.Models.Form.new(event.data.form_permalink)

      form.submissions.query_new()
      .then (submission) -> event.resolve(submission.serialize())
      .catch event.reject

  if options.read == true
    channel.on "schema.submission.refresh", (event) ->
      form = App.Models.Form.new(event.data.form)

      form.submissions.query_by_permalink(event.data.submission)
      .then (submission) ->
        submission.refresh()
        .then -> event.resolve(submission.serialize())
        .catch event.reject
      .catch event.reject

  if options.destroy == true
    channel.on "schema.submission.destroy", (event) ->
      form = App.Models.Form.new(event.data.form)

      form.submissions.query_by_permalink(event.data.submission)
      .then (submission) ->
        submission.destroy()
        .then -> event.resolve(submission.serialize())
        .catch event.reject
      .catch event.reject

    channel.on "schema.submission.restore", (event) ->
      form = App.Models.Form.new(event.data.form)

      form.submissions.query_by_permalink(event.data.submission)
      .then (submission) ->
        submission.restore()
        .then -> event.resolve(submission.serialize())
        .catch event.reject
      .catch event.reject

  if options.update == true
    channel.on "schema.submission.update", (event) ->
      form = App.Models.Form.new(event.data.form)

      form.submissions.query_by_permalink(event.data.submission)
      .then (submission) ->
        submission.update(event.data.parameters)
        .then -> event.resolve(submission.serialize())
        .catch event.reject
      .catch event.reject
