
window.App ?= {}
window.App.Errors ?= {}

window.App.Errors.PERMISSION_DENIED = "Permission Denied"

# Changes to true when the page is being redirected, to prevent connection
# error popups from cancelled/failed requests.
window.App.unloading = false
window.App.ever_unloaded = false

window.App.Errors.connection_error = ->
  App.Interface.Modal.new(
    i18n.t("connection_error"),
    i18n.t("connection_error_msg", email: "<a href='mailto:support@sonadier.com'>support@sonadier.com</a>", interpolation: { escapeValue: false }),
    size: "small"
  )

window.App.Errors.request_error = ->
  App.Interface.Modal.new(
    i18n.t("request_error"),
    i18n.t("request_error_msg", email: "<a href='mailto:support@sonadier.com'>support@sonadier.com</a>", interpolation: { escapeValue: false }),
    size: "small"
  )

window.App.Errors.unauthorized_error = ->
  menu = App.Interface.Menu.current()

  uri = App.Helpers.URIs.parse(window.location.href)
  uri = uri.static_path(hash: false)

  sign_in = "<a href='#{Routes.new_user_session_path(redirect: btoa(window.location.href))}'>Sign In</a>"
  refresh = "<a href='#{uri}'>Refresh</a>"

  if menu?
    menu.unsafe_alert(
      i18n.t("session_error_msg", sign_in: sign_in, refresh: refresh, interpolation: { escapeValue: false }),
      id: "unauthorized_request_error_alert"
    )
  else
    App.Interface.Modal.new(
      i18n.t("session_error"),
      i18n.t("session_error_msg", sign_in: sign_in, refresh: refresh, interpolation: { escapeValue: false }),
      size: "small",
      escape: false
    )

window.App.Errors.xhr_error = (xhr, options = {}) ->
  return if App.unloading || !xhr? || !xhr.getResponseHeader?

  error_message = xhr.getResponseHeader("Error-Message")

  # Allow options.for to be an array of un-silenced errors.
  # App.Errors.xhr_error(xhr, for: ["unauthorized", "forbidden"])
  return unless App.Errors.error_allowed(xhr.status, options.for)

  if error_message? && error_message.length > 0
    return App.Interface.Modal.new(i18n.t("request_error"), error_message, size: "small")

  return App.Errors.connection_error() if xhr.readyState == 0

  if xhr.status == 401
    App.Errors.unauthorized_error()
  else if xhr.status == 402
    App.menu().alert(
      i18n.t(
        "errors.status_codes.payment_required.title",
        feature: xhr.getResponseHeader("Required-Feature")
      )
    )
  else if xhr.status == 403
    App.Interface.Modal.new(
      i18n.t("errors.status_codes.forbidden.title"),
      i18n.t("errors.status_codes.forbidden.description"),
      size: "small"
    )
  else if xhr.status == 413
    App.Interface.Modal.new(
      i18n.t("errors.status_codes.request_entity_too_large.title"),
      i18n.t("errors.status_codes.request_entity_too_large.description", email: "<a href='mailto:support@sonadier.com'>support@sonadier.com</a>", interpolation: { escapeValue: false }),
      size: "small"
    )
  else
    App.Errors.request_error()

window.App.Errors.response_error = (xhr, options) ->
  return if App.unloading

  if xhr?
    App.Errors.xhr_error(xhr, options)
  else
    return if options? && options.for? && !App.Errors.error_allowed(0, options.for)
    App.Errors.connection_error()

window.App.Errors.error_allowed = (status, allowed) ->
  return true unless allowed?
  return true if allowed.indexOf(status) != -1
  return true if allowed.indexOf(App.Errors.response_code_names[status])

  false

window.App.Errors.response_code_names = {
  0:   "offline",
  401: "unauthorized",
  403: "forbidden",
  413: "request_entity_too_large"
}

window.connection_error = -> App.Errors.connection_error()
window.request_error = -> App.Errors.request_error()

window.addEventListener "beforeunload", ->
  App.unloading = true

  if !App.ever_unloaded
    App.ever_unloaded = true

    document.addEventListener "DOMContentLoaded", ->
      App.unloading = false
      undefined
