
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Modal ?= {}
window.App.Interface.Modal.Helpers ?= {}
window.App.Interface.Modal.Helpers.Sandboxes ?= {}

window.App.Interface.Modal.Helpers.Sandboxes.register = (modal) ->
  modal.sandboxes.subscribe "load", -> modal.load()
  modal.sandboxes.subscribe "show", -> modal.show()
  modal.sandboxes.subscribe "hide", -> modal.hide()
  modal.sandboxes.subscribe "destroy", -> modal.destroy()

  modal.sandboxes.subscribe "update.size", (event) ->
    modal.size(event.data.value)

  modal.sandboxes.subscribe "update.title", (event) ->
    modal.title(event.data.value)

  modal.sandboxes.subscribe "update.body", (event) ->
    modal.body(event.data.value)

  modal.sandboxes.subscribe "update.actions", (event) ->
    modal.actions(event.data.value)

  undefined
