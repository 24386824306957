
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.ColumnManagement = {
  _order_columns_array: ->
    @_columns_array.sort (a, b) -> a.index() - b.index()

  column: (column) ->
    if column instanceof App.Elements.ResponseTable.Column
      return column

    column = column[0] if App.Helpers.Elements.is_jquery(column)
    return unless column?

    # Allow column to be called with TD elements to return their column
    if column.nodeType? && column.tagName == "TD"
      column = App.Helpers.Tables.th_from_td(column)

    column = column.dataset.columnName if column.nodeType?
    return unless column?

    if column instanceof App.Schema.Form.Field
      column = column.variable_name()
    else if column instanceof App.Schema.Form.Header
      column = column.variable_name()

    if @_columns_hash[column]
      return @_columns_hash[column]

    for model in @columns()
      return model if model.permalink() == column
      return model if model.variable_name() == column
      return model if model.column_variable? && model.column_variable == column
      return model if model.field_id? && model.field_id == column

    undefined

  columns: (columns) ->
    return @_columns_array unless columns?

    return (@column(column) for column in columns)

  update_columns: (data) ->
    schema_request = @form().schema()
    update_request = new Promise (resolve, reject) =>
      $.ajax
        url: @routes.update_settings()
        method: "PATCH"
        dataType: "json"
        data: {
          authenticity_token: current_user.authenticity_token(),
          settings: data
        }
      .done resolve
      .fail reject

    new Promise (resolve, reject) =>
      Promise.all([schema_request, update_request]).then (responses) =>
        response = responses[1]
        @clear()

        columns = []

        for id, column_data of response.columns
          column_data.id = id
          columns.push(column_data)

        columns.sort((a, b) ->
          if a.position > b.position || (!a.visible && b.visible)
            return 1
          if b.position > a.position || (a.visible && !b.visible)
            return -1

          0
        )

        for column_data in columns
          column_data.permalink = column_data.id
          column = @column(column_data.id)

          if !column?
            continue unless column_data.visible
            schema = @form().fields.find(column_data.id) || @form().headers.find(column_data.id)
            column = @initialize_column(schema) if schema?

          column.assign(column_data, refresh: false) if column?

        @apply_resizing()
        @refresh()

        resolve(@columns())

  visible_columns: ->
    output = []

    for column in @columns()
      output.push(column) if column.visible()

    output

  visible_columns_without_expander: ->
    output = []

    for column in @columns()
      output.push(column) if column.visible() && !column.row_select()

    output

  column_parameters: (options = {}) ->
    output = []

    for column in @columns()
      column_data = {
        data: column.field_id,
        name: column.field_id
      }

      column_search = column.search()
      if column_search? && column_search != ""
        column_data.search = {
          value: column_search
        }

      if !options? || options.exclude_unchanged != true
        if column_data.data != column_data.value || column_data.search?
          output.push(column_data)

    output

  initialize_column: (column) ->
    if column instanceof App.Schema.Form.Field || column instanceof App.Schema.Form.Header
      column = App.Elements.ResponseTable.Column.build_element(column)

      real_columns = @table_head_element.querySelectorAll(".colhead:not(.fake-cell)")

      last_real_column = real_columns[real_columns.length - 1]
      last_real_column.parentNode.insertBefore(column, last_real_column.nextSibling)

    if !App.Helpers.Objects.isNode(column)
      throw new Error("column must be a Node or Schema")

    model = new App.Elements.ResponseTable.Column(@, column)

    @_columns_hash[model.field_id] = model
    @_columns_array.push(model)

    model

  toggle_column: (field, status) ->
    column = @column(field)

    if column
      column.toggle(status)
    else
      @initialize_column(field).toggle(status)

  column_positions: (positions, persist = true) ->
    index = 0

    for position in positions
      column = @column(position)

      if column?
        column.index(index, refresh: false, persist: false)
        index += 1

    @column("checkbox").index(0, refresh: false, persist: false)

    if persist
      @save_column_positions()
    else
      Promise.resolve()

  save_column_positions: ->
    return Promise.reject(App.Errors.PERMISSION_DENIED) unless @has_permission("edit_form")

    @_order_columns_array()

    output = []

    for column in @columns()
      output.push(column.field_permalink) if column.not_row_select()

    new Promise (resolve, reject) =>
      $.ajax
        url: @routes.reorder_column_positions()
        method: "POST"
        data: {
          authenticity_token: current_user.authenticity_token(),
          column: {
            positions: output
          }
        }
      .done resolve
      .fail reject
}
