
window.App ?= {}
window.App.Events ?= {}
window.App.Events._onetime_events = ["ready"]
window.App.Events._onetime_events_data = {}

window.App.Events._retroactive_events = ["submission.load"]
window.App.Events._retroactive_events_data = {}

window.App.trigger = (event, data) ->
  App._events ?= {}
  App._events[event] ?= []

  if App.Events._onetime_events.indexOf(event) != -1
    App.Events._onetime_events_data[event] = data || {}

  if App.Events.retroactive_event(event)
    App.Events._retroactive_events_data[event] ?= []
    App.Events._retroactive_events_data[event].push(data)

  for callback in App._events[event]
    callback(data)

  undefined

window.App.on = (event, callback) ->
  return App._events[event] || [] if !callback?

  App._events ?= {}
  App._events[event] ?= []
  App._events[event].push(callback)

  if App.Events._onetime_events.indexOf(event) != -1
    event_data = App.Events._onetime_events_data[event]

    callback(event_data) if event_data?

  if App.Events.retroactive_event(event)
    event_data = App.Events._retroactive_events_data[event]

    if event_data?
      for data in event_data
        callback(data)

  undefined

class window.App.Events.Manager
  constructor: ->
    @

  subscribe: (name, callback = undefined) ->
    if name.split(" ").length > 0
      events = []

      for event in name.split(" ")
        events.push new App.Events.Subscription(event, callback)

      new App.Events.SubscriptionCollection(events)
    else
      new App.Events.Subscription(name, callback)

  publish: (name, data) ->
    PubSub.publish(name, data)

class window.App.Events.SubscriptionCollection
  constructor: (@events) ->
    @

  where: (conditions, callback) ->
    for event in @events
      event.where(conditions, callback)

class window.App.Events.Subscription
  constructor: (@name, callback = undefined) ->
    if callback?
      @id = PubSub.subscribe @name, ((callback) ->
        (name, data) -> callback(data)
      )(callback)
    @

  where: (conditions, callback) ->
    @id = PubSub.subscribe(@name, ((conditions, callback) ->
      (name, data) ->
        return unless callback?
        callback(data) if App.Events.data_matches_conditions(data, conditions)
    )(conditions, callback))

    @

window.App.Events.retroactive_event = (event) ->
  App.Events._retroactive_events.indexOf(event) != -1

window.App.Events.data_matches_conditions = (data, conditions) ->
  return false if !data? && !App.Helpers.Objects.isEmptyObject(conditions)

  for key, value of conditions
    continue if data[key] == value

    if !App.Helpers.Objects.isPlainObject(data[key])
      return false if data[key] != value
    else
      child = {}
      child[key] = value

      return false unless App.Events.data_matches_conditions(data, child)

  true

window.App.Event ?= new App.Events.Manager()
window.Events = window.App.Event
