
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Arrays ?= {}

window.App.Helpers.Arrays.stable_sort = (array, comparison_method) ->
  length = array.length
  entries = Array(length)

  for entry, index in array
    entries[index] = [index, array[index]]

  entries.sort ((x, y) ->
    comparison = Number(@(x[1], y[1]))

    return comparison if comparison != 0

    x[0] - y[0]
  ).bind(comparison_method)

  for entry, index in entries
    array[index] = entries[index][1]

  array

window.App.Helpers.Arrays.deep_clone = (array) ->
  return array if !array? || !Array.isArray(array)

  clone = []

  for value in array
    clone.push App.Helpers.Objects.deep_clone(value)

  clone

window.App.Helpers.Arrays.to_sentence = (array, end_separator = "and") ->
  return "" if array.length == 0
  return array[0] if array.length == 1
  return "#{array[0]} #{end_separator} #{array[1]}" if array.length == 2

  last_value = array.pop()

  output = array.join(", ")
  output += ", #{end_separator} #{last_value}"
  output

window.App.Helpers.Arrays.compact = (array) ->
  new_array = []

  for value in array
    new_array.push(value) if value?

  new_array

window.App.Helpers.Arrays.move = (array, old_index, new_index) ->
  array = array.slice(0)

  while old_index < 0
    old_index += array.length

  while new_index < 0
    new_index += array.length

  if new_index >= array.length
    key = new_index - array.length

    while k-- + 1
      array.push(undefined)

  array.splice(new_index, 0, array.splice(old_index, 1)[0])

  array
