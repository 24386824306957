
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentTable ?= {}
window.App.Elements.AttachmentTable.Rows ?= {}

class window.App.Elements.AttachmentTable.Rows.File
  @generate_context_menu: (table) ->
    App.Interface.ContextMenu.new(table.table_body_element, "tr.attachment_table_file_row", [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: -> table.row(@element).open(false)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: -> table.row(@element).open(true)
      },
      {
        separator: true
      },
      {
        text: i18n.t("get_a_link"),
        icon: "link",
        callback: -> table.row(@element).sharing_modal().show()
      },
      {
        text: i18n.t("move"),
        icon: "folder-open-empty",
        active: -> table.row(@element).has_permission("update"),
        callback: -> table.row(@element).move_modal().show()
      },
      {
        text: i18n.t("rename"),
        icon: "pencil",
        active: -> table.row(@element).has_permission("update"),
        callback: -> table.row(@element).rename()
      },
      {
        html: -> "<label for='#{table.element.dataset.uploadButton}'><i class='icon icon-upload'></i>" +
          i18n.t("files.replace") + "</label>"
        active: -> table.row(@element).has_permission("update") && table.element.dataset.uploadButton?,
        listener: ->
          upload_button = App.menu().element.querySelector(".file_upload_button")
          upload_button.dataset.replace = table.row(@element).permalink() if upload_button?
      },
      {
        text: i18n.t("properties"),
        icon: "info-circled"
        callback: -> table.row(@element).properties_modal().show()
      },
      {
        text: i18n.t("permissions"),
        icon: "cog"
        active: -> table.row(@element).has_permission("manage")
        callback: -> table.row(@element).permissions_modal().show()
      },
      {
        active: -> table.row(@element).has_permission("destroy")
        separator: true
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        active: ->
          row = table.row(@element)
          row.has_permission("destroy") && !row.destroyed()
        callback: -> table.row(@element).destroy()
      },
      {
        text: i18n.t("restore"),
        icon: "ok",
        active: ->
          row = table.row(@element)
          row.has_permission("destroy") && row.destroyed()
        callback: -> table.row(@element).restore()
      }
    ])

  constructor: (@table, element) ->
    @set_element(element)

  set_element: (element) ->
    @element = element

  cell: (column) ->
    return unless column?

    unless column instanceof App.Elements.AttachmentTable.Column
      column = @table.column(column)

    return unless column?

    @element.children[column.index()]

  permalink: ->
    @element.dataset.permalink

  link: ->
    attachment_link = App.Helpers.URIs.parse(Routes.attachment_file_path(@permalink()))
    if window.location.host.includes("staging.sonadier")
      attachment_link.subdomain(current_user.facility_permalink + "-files.staging")
    else
      attachment_link.subdomain(current_user.facility_permalink + "-files")
    attachment_link.value()

  open: (new_tab = true) ->
    if new_tab
      App.redirect_to @link(), target: "_blank"
    else
      App.redirect_to @link(), scaffold: false

  destroyed: ->
    @table.params.deleted()

  destroy: ->
    $.ajax
      url: Routes.delete_file_path(@permalink(), modal: true)
      type: "DELETE"
      data: { authenticity_token: current_user.authenticity_token() }
    .done (response) =>
      @table.refresh()

      menu = App.menu()
      return unless menu?

      button = menu.element.querySelector(".deleted_files_link")
      button.style.display = "inline-block" if button?

  restore: ->
    $.ajax
      url: Routes.restore_file_path(@permalink(), modal: true)
      type: "POST"
      data: { authenticity_token: current_user.authenticity_token() }
    .done =>
      @table.refresh()

  move: (new_folder) ->
    $.ajax
      url: Routes.move_file_path(@permalink(), new_folder, modal: true)
      type: "PUT"
      data: { authenticity_token: current_user.authenticity_token() }
    .done (response) =>
      @table.refresh()

  rename: (value) ->
    if value?
      add_page_loader()

      $.ajax
        url: Routes.rename_file_path(@permalink())
        type: "POST"
        data: {
          authenticity_token: current_user.authenticity_token()
          name: value
        }
      .always => remove_page_loader()
      .done => @table.refresh()
      .fail (xhr) => App.Errors.response_error(xhr)
    else
      App.Helpers.Tables.open_cell_editor @cell("name"), (value) => @rename(value)

  has_permission: (permission) ->
    return true if @table.has_permission(permission)

    @_permissions[permission]

  permissions_modal: ->
    return @_permissions_modal if @_permissions_modal?

    @_permissions_modal = App.Interface.Modal.from_url(
      Routes.file_permissions_modal_path(@permalink(), modal: true)
    )

  properties_modal: ->
    return @_properties_modal if @_properties_modal?

    @_properties_modal = App.Interface.Modal.from_url(
      Routes.file_properties_modal_path(@permalink(), modal: true)
    )

  sharing_modal: ->
    return @_sharing_modal if @_sharing_modal?

    @_sharing_modal = App.Interface.Modal.from_url(
      Routes.file_sharing_modal_path(@permalink(), modal: true)
    )

    # Selects the sharing modal's link input for copying
    @_sharing_modal.on "load", (modal) ->
      modal.element.querySelector(".link_url").select()

    @_sharing_modal

  move_modal: ->
    return @_move_modal if @_move_modal?

    @_move_modal = App.Interface.Modal.from_url(
      Routes.file_move_modal_path(@permalink(), modal: true)
    )

    @_move_modal
