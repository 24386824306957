
$(document).on "click", ".app_response_table .response_subscription", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if button.classList.contains("disabled") || button.disabled

  table_container = App.Helpers.Elements.closest(button, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  table_model.subscription_toggle(event)

$(document).on "click", ".response_table_settings", (event) ->
  event.preventDefault()

  option = event.currentTarget

  return if loading option
  add_loader option

  table_container = App.Helpers.Elements.closest(option, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  menu_options = []

  if table_model.has_permission("edit_form")
    menu_options.push({
      text: i18n.t("response_table.options.settings"),
      icon: "cog",
      callback: -> table_model.modals.settings().show()
    })

  if table_model.has_permission("edit_form")
    menu_options.push({
      text: i18n.t("response_table.options.export"),
      icon: "download",
      callback: ->
        table_model.export.modal().show().then ->
          table_model.export.reset_query()
    })

  if !table_model.params.fullpage()
    menu_options.push({
        text: i18n.t("response_table.options.filter"),
        icon: "filter",
        callback: ->
          table_model.form().schema().then ->
            table_model.query_editor().show()
      })

  if table_model.has_permission("destroy")
    if table_container.dataset.deletedExist == "true"
      menu_options.push({
        text: i18n.t("response_table.options.restore"),
        icon: "arrows-ccw",
        href: Routes.submissions_path(table_model.form().permalink(), deleted: true),
        single_page: true
      })

  if table_model._custom_menu_options?
    for custom_options in table_model._custom_menu_options
      menu_options.push(custom_options)

  remove_loader option

  menu = new App.Interface.ContextMenu.Object(menu_options)
  menu.element = option

  coords = option.getBoundingClientRect()
  menu.show(position: { x: coords.left + option.offsetWidth, y: coords.bottom })
