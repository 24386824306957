
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.Attachment
  constructor: (@field) ->
    @element = @field._element
    @field_id = @field.id

    @files_container = @element.querySelector(".UPLOAD_FILES_WRAPPER")

    @old_files = []
    @new_files = []

    for file in @element.querySelectorAll(".UPLOAD_INPUTS .UPLOADED_FILE")
      index = parseInt(file.dataset.index)
      permalink_field = file.querySelector(".attachment_file_permalink")

      @old_files[index] = {
        name: permalink_field.dataset.name,
        permalink: permalink_field.value
      }

    if @field.submission?
      @field.submission.after_submit (submission, response) =>
        @mark_as_persisted(response.submission.text[@field.variable_name()])

    @generate_display()

  _value: (value) ->
    output = []

    for file in @old_files
      object = Object.assign({}, file)
      object.url = Routes.attachment_file_path(file.permalink)
      object.persisted = true

      output.push(object)

    for file in @new_files
      # Deleted new files are simply replaced with undefined to preserve indexes
      if file?
        object = {}
        object.name = file.name
        object.file = file
        object.persisted = false

        output.push(object)

    output

  data: ->
    { attachment: @_value() }

  count: -> @old_files.length + @new_files.length

  delete_file: (index) ->
    index = parseInt(index)

    if index < @old_files.length
      @field.set_subfield("attachment[#{index}][_destroy]", true)
      @old_files[index].marked_for_destruction = true
    else
      index = index - @old_files.length
      @new_files[index] = undefined

    @generate_display()

  collect_new_files: (input) ->
    for file in input.files
      @new_files.push(file)
    
    @generate_display()

    input.value = ""

  generate_display: ->
    @files_container.innerHTML = ""

    for file, index in @old_files
      unless file.marked_for_destruction
        @files_container.appendChild @generate_file_display(file, index)

    for file, index in @new_files
      # Deleted new files are simply replaced with undefined to preserve indexes
      if file?
        index = @old_files.length + index
        @files_container.appendChild @generate_file_display(file, index)

    undefined

  generate_file_display: (file, iterator) ->
    element = document.createElement("A")
    element.className = "UPLOAD_FILE"
    element.textContent = file.name
    element.title = file.name
    element.dataset.index = iterator

    if file.permalink?
      element.dataset.permalink = file.permalink

      attachment_url = App.Helpers.URIs.parse(Routes.attachment_file_path(file.permalink))
      if window.location.host.includes("staging.sonadier")
        attachment_url.subdomain(current_user.facility_permalink + "-files.staging")
      else
        attachment_url.subdomain(current_user.facility_permalink + "-files")

      element.href = attachment_url.value()
      element.target = "_blank"
    else
      element.href = "#"

    delete_element = document.createElement("SPAN")
    delete_element.className = "DELETE_UPLOAD_FILE"
    delete_element.textContent = "×"
    delete_element.title = "Remove Attachment"

    element.appendChild(delete_element)

    element

  mark_as_persisted: (attachment_permalinks) ->
    old_files_count = 0

    for file, index in @old_files
      if !file.marked_for_destruction && !@field.get_subfield("attachment[#{index}][_destroy]")
        old_files_count = old_files_count + 1

    compact_new_files = App.Helpers.Arrays.compact(@new_files)
    deleted_new_files_count = @new_files.length - compact_new_files.length

    for file, new_server_index in compact_new_files
      # The server throws away invalid or deleted files
      local_index = @old_files.length + @new_files.indexOf(file)
      server_index = old_files_count + new_server_index

      file_data = {
        name: file.name,
        permalink: attachment_permalinks[server_index]
      }

      @generate_upload_input(file_data, local_index)
      @old_files.push(file_data)

    # Preserve local indexes by maintaining deleted new files positions
    @new_files = []
    @new_files.push(undefined) for [1..deleted_new_files_count] if deleted_new_files_count

    @generate_display()

  generate_upload_input: (file, iterator) ->
    element = document.createElement("DIV")
    element.className = "UPLOADED_FILE"
    element.dataset.index = iterator

    permalink_input = document.createElement("INPUT")
    permalink_input.type = "hidden"
    permalink_input.className = "attachment_file_permalink"
    permalink_input.name = "submission[#{@field_id}][attachment][#{iterator}][permalink]"
    permalink_input.value = file.permalink
    permalink_input.dataset.name = file.name

    destroy_input = document.createElement("INPUT")
    destroy_input.type = "hidden"
    destroy_input.className = "attachment_file_destroy"
    destroy_input.name = "submission[#{@field_id}][attachment][#{iterator}][_destroy]"
    destroy_input.value = false

    element.appendChild(permalink_input)
    element.appendChild(destroy_input)

    @element.querySelector(".UPLOAD_INPUTS").appendChild(element)

    element
