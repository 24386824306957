import 'app/models/mail_merge/helpers/modals'
import 'app/models/mail_merge/helpers/routing'


window.App ?= {}
window.App.Models ?= {}

class window.App.Models.MailMerge
  @parameters_to_request_data: (input) ->
    output = {}

    # These optional renames just make the parameters more intuitive frontend.
    if input.report?
      output.report = input.report
      input.report = undefined

    output.mail_merge = input

    output

  constructor: (@form_permalink, @mail_merge_permalink) ->
    @instance_id = App.Helpers.Generators.uuid()
    @element_model = undefined

    @callbacks = new CallbackManager(@)
    @modals = new App.Models.MailMergeHelpers.Modals(@)
    @routes = new App.Models.MailMergeHelpers.Routing(@)

    @load()

  on: (event, callback) -> @callbacks.on(event, callback)

  permalink: -> @mail_merge_permalink
  persisted: -> @mail_merge_permalink?

  form: -> App.Models.Form.new(@form_permalink)

  load: ->
    new Promise (resolve, reject) =>
      @modals.settings().load()
      .then resolve
      .catch reject

  show: ->
    new Promise (resolve, reject) =>
      @modals.settings().show()
      .then resolve
      .catch reject

  save: (data) ->
    new Promise (resolve, reject) =>
      data = App.Models.MailMerge.parameters_to_request_data(data)
      data.authenticity_token = current_user.authenticity_token()

      $.ajax
        url: @routes.save()
        method: @routes.save_method()
        data: data
      .done (response) =>
        @callbacks.trigger("save", @)
        @form().mail_merges.callbacks.trigger("save", @)

        if @persisted()
          @callbacks.trigger("update", @)
          @form().mail_merges.callbacks.trigger("update", @)
        else
          @mail_merge_permalink = response.mail_merge.permalink
          @callbacks.trigger("create", @)
          @form().mail_merges.callbacks.trigger("create", @)

        resolve()
      .fail (response) =>
        reject(response.responseJSON)

  save_and_send: (data, target) ->
    @save(data).then => @send(target)

  send: (target) ->
    if Array.isArray(target)
      @send_submissions(target)
    else if target instanceof App.Models.Form
      @send_form(target)
    else if target instanceof App.Schema.Form.Report
      @send_report(target)

  send_submissions: (submissions) ->
    new Promise (resolve, reject) =>
      $.ajax
        url: @routes.send_submissions()
        type: "POST"
        data: {
          authenticity_token: current_user.authenticity_token(),
          submissions: submissions
        }
      .done => resolve()
      .fail (response) => reject(response.responseJSON)

  send_form: ->
    new Promise (resolve, reject) =>
      $.ajax
        url: @routes.send_form()
        type: "POST"
        data: {
          authenticity_token: current_user.authenticity_token()
        }
      .done => resolve()
      .fail (response) => reject(response.responseJSON)

  send_report: (report) ->
    new Promise (resolve, reject) =>
      $.ajax
        url: @routes.send_report(report.permalink())
        type: "POST"
        data: {
          authenticity_token: current_user.authenticity_token()
        }
      .done => resolve()
      .fail (response) => reject(response.responseJSON)

  destroy: ->
    new Promise (resolve, reject) =>
      $.ajax
        url: @routes.destroy()
        method: "DELETE"
        data: {
          authenticity_token: current_user.authenticity_token()
        }
      .done =>
        @callbacks.trigger("destroy", @)
        @form().mail_merges.callbacks.trigger("destroy", @)

        resolve(@)
      .fail (xhr) ->
        reject(xhr.responseJSON)
