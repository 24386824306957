
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.Connection
  constructor: (@field) ->
    @element = @field._element

    @connected_form_permalink = @element.dataset.connectedForm
    @primary_field = @element.dataset.primaryFieldColumn

    if @display() == "table"
      @table = App.tables.add(@element.querySelector(".table_container"))

      connection_search = @element.querySelector(".CONNECTION_SEARCH input")

      @table.params.search(connection_search) if connection_search?
      @table.selections.options.multiple = @allow_multiple_choices()

      @table.on "submission.new", (submission) =>
        @_add_spawned_submission(submission)

      if App.feature("reports")
        @table.on "refresh", =>
          if @table.query.any()
            @_add_clear_query_link()
          else
            @_remove_clear_query_link()

      if @field.submission?
        PubSub.subscribe "submission.created.#{@field.form().permalink()}.#{@field.submission.permalink()}", =>
          @_update_table_url()

    @base_options = {}
    @base_values = []

    if @display() == "dropdown"
      @connection_select = @field.container.find(".hidden_connection_select")
      @convert_to_select2()

      App.Models.Form.new(@connected_form().permalink()).schema(cache_failures: true).then (form) =>
        return unless form.has_permission("create")

        @field.shortcut({
          label: i18n.t("new"),
          href: Routes.new_submission_path(@connected_form().permalink()),
          callback: (event) =>
            button = event.currentTarget
            return if loading button
            add_loader button

            @connected_form().submissions.open_new(event)
            .then (submission) =>
              remove_loader button

              return if @_is_spawned_submission(submission)
              @_add_spawned_submission(submission)

              submission.after_submit (submission, response) =>
                permalink = submission.permalink()
                value = response.submission.text[@primary_field] || permalink

                @add_submission_to_select(permalink, value)

                connection_value = if @allow_multiple_choices() then @field.value() else []
                connection_value.push(submission.permalink())
                @field.value(connection_value)
            .catch -> remove_loader button
        })

        if !@allow_multiple_choices()
          @_update_connection_edit_link(@field.value())
          @field.on "change", => @_update_connection_edit_link(@field.value())

      .catch -> # Do Nothing

      options = @options()

      for option in options
        option = $(option)
        value = option.attr("value")

        @base_options[value] = option.text().trim()
        @base_values.push(value)

  _value: (value) ->
    return value if value?

    []

  data: ->
    @_value()

  connected_form: -> App.Models.Form.new(@connected_form_permalink)

  connections: ->
    return $.Deferred().resolve([]) if @field.empty()

    deferred = $.Deferred()
    requests = []

    permalinks = @field.value()
    permalinks = [permalinks] unless Array.isArray(permalinks)

    for permalink in permalinks
      requests.push @connected_form().submissions.load(permalink)

    Promise.all(requests)

  # Submissions created from this connection's "New" button.
  # These should be listed as selectable options by passing them with the "new_unselected" param to TableData.
  _spawned_submissions: ->
    return [] unless @_instantiated_submissions?
    @_instantiated_submissions

  _spawned_submission_permalinks: ->
    return [] unless @_instantiated_submissions?

    output = []

    for submission in @_instantiated_submissions
      output.push(submission.permalink()) if submission.persisted()

    output

  _add_spawned_submission: (submission) ->
    @_instantiated_submissions ?= []
    return submission if @_instantiated_submissions.indexOf(submission) != -1
    @_instantiated_submissions.push(submission)

    submission

  _is_spawned_submission: (submission) ->
    @_instantiated_submissions ?= []
    return true if @_instantiated_submissions.indexOf(submission) != -1
    false

  _add_clear_query_link: ->
    @clear_query_link ?= @field.shortcut({
      label: "× Clear Filters",
      callback: =>
        @table.query.clear()
        @table.refresh()
    })

    @clear_query_link.show()

  _remove_clear_query_link: ->
    return unless @clear_query_link?
    @clear_query_link.hide()

  _add_connection_edit_link: ->
    return if @connection_edit_link?

    @connection_edit_link = @field.shortcut($("<a/>", {
      text: i18n.t("edit"),
      class: "edit_connection edit_submission_link",
      "data-form-permalink": @connected_form().permalink()
    }))

    value = @field.value()
    value = value[0] if Array.isArray(value)

    if value?
      @connection_edit_link.setAttribute("data-permalink", value)
    else
      @connection_edit_link.style.display = "none"

    @connection_edit_link

  _update_connection_edit_link: (value) ->
    value = value[0] if Array.isArray(value)

    @_add_connection_edit_link()
    return unless @connection_edit_link?

    if value? && !App.Helpers.Objects.isEmptyObject(value)
      @connection_edit_link.style.display = "inline-block"
      @connection_edit_link.setAttribute("data-permalink", value)
    else
      @connection_edit_link.style.display = "none"

  add_submission_to_select: (permalink, primary_field_value) ->
    return unless permalink? && @connection_select?

    App.Helpers.Selects.value_from_array(
      @connection_select, [[permalink, primary_field_value]], true
    )

  set_subfield: (field, value) ->
    if @display() == "table"
      App.Helpers.Selects.value_from_array(field, value)
      return @table.refresh()

    value = [value] unless Array.isArray(value)
    @add_submission_to_select(permalink) for permalink in value

    @connected_form().submissions.query({ permalink: value }).then (responses) =>
      for response in responses.all()
        @add_submission_to_select response.permalink(), response.field(@primary_field)

      field.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))

    @field.value()

  append: (submission) ->
    if submission instanceof App.Models.Submission.Object || App.Schema.Submission
      submission = submission.permalink()

    if @allow_multiple_choices()
      current_value = @field.value()
      current_value.push(submission)
      @field.value(current_value)
    else
      @field.value(submission)

  display: ->
    if @element.querySelector(".FIELD").classList.contains("SELECT_FIELD")
      "dropdown"
    else
      "table"

  options: (hide_warning = false) ->
    if @display() == "dropdown"
      @element.querySelectorAll("option")
    else
      if !hide_warning
        console.error("Warning: Calling the options method on a connection table only returns visible rows. Call options(true) to hide this message.")
      @table.table_body_element.children

  show_options: (options_array = undefined) ->
    current_value = @field.value()

    if @display() == "table"
      if !options_array?
        options_array = []

      @table.params.allowed_permalinks(options_array)
    else if @display() == "dropdown"
      if !options_array?
        options_array = @base_values

      new_options = "<option value=''></option>"

      for value, text of @base_options
        # Either permalinks or primary values can be passed in with the options_array
        if options_array.indexOf(value) != -1 || options_array.indexOf(text) != -1
          if current_value.indexOf(value) != -1
            new_options += "<option value='#{value}' selected>#{text}</option>"
          else
            new_options += "<option value='#{value}'>#{text}</option>"

      @element.querySelector("select").innerHTML = new_options

  data_path: ->
    submission = @field.submission

    if submission? && submission.persisted()
      Routes.field_table_data_path(@connected_form().permalink(), @field.field_id, submission.permalink(), format: "json")
    else
      Routes.new_field_table_data_path(@connected_form().permalink(), @field.field_id, format: "json")

  convert_to_select2: ->
    @element.querySelector(".SELECT_FIELD").classList.add("SELECT2_FIELD")

    select_fields = @element.querySelectorAll("select")

    for field in select_fields
      if field.multiple
        empty_option = field.querySelector("option:not([value]), option[value='']")
        empty_option.remove() if empty_option?

      $(field).select2({
        ajax: {
          url: => @data_path(),
          type: "POST",
          dataType: "json",
          delay: 250,
          minimumInputLength: 0,
          contentType: "application/json; charset=utf-8",
          data: (params) =>
            params.page = params.page || 0

            JSON.stringify({
              search: { value: params.term },
              selected: { value: @field.value() },
              new_unselected: { value: @_spawned_submission_permalinks() },
              display: "simple",
              start: (params.page * 100),
              end: (params.page * 100 + 100),
              columns: [{ data: @primary_field, name: @primary_field }],
              order: [{ column: 0, dir: "asc" }],
              authenticity_token: current_user.authenticity_token()
            })
          processResults: (response, params) =>
            params.page = params.page || 1

            results = []

            if !@allow_multiple_choices() && !@field.required()
              results.push({ id: "", text: "" }) if !@field.required() || @allow_multiple_choices()

            for submission in response.data
              permalink = submission.permalink
              value = submission[@primary_field] || permalink

              results.push({ id: permalink, text: value, escaped: true })

            {
              results: results,
              pagination: (params.page * 100) < response.count
            }
        }
        templateResult: (item) ->
          # Table Data is escaped server side. This stops " from appearing as &quot;.
          result = document.createElement("SPAN")

          if item.escaped
            result.innerHTML = item.text
          else
            result.textContent = item.text

          result
        templateSelection: ((form) ->
          (item) ->
            return unless item.id? && item.id.length > 0

            # This structure for submission links stops select2's absurdly clingy event handling from
            # opening the dropdown on top of the submission modal when the option is clicked.
            link = document.createElement("A")
            link.href = "#"
            link.dataset.permalink = item.id
            link.dataset.formPermalink = form.permalink()
            link.className = "submission_connection_option"

            # Table Data is escaped server side.
            if item.escaped
              link.innerHTML = item.text
            else
              link.textContent = item.text

            App.Models.Form.new(form.permalink()).schema(cache_failures: true).then (form) =>
              return unless form.has_permission("update")

              link.addEventListener "click", (event) ->
                event.preventDefault()
                event.stopPropagation()

                button = event.currentTarget

                return if loading button
                add_loader button

                form.submissions.open(event.currentTarget.dataset.permalink)
                .then -> remove_loader button
                .catch -> remove_loader button

                $(field).select2("close")
            .catch => undefined

            wrapper = document.createElement("SPAN")
            wrapper.appendChild(link)
            wrapper
        )(@connected_form())
      })

      field.nextElementSibling.classList.add("select2-interactive-selections")

  allow_multiple_choices: ->
    if @display() == "table"
      @table.table_element.dataset.multiple == "true"
    else if @display() == "dropdown"
      @connection_select.prop("multiple")
    else
      false

App.Interface.ContextMenu.new(".submission_connection_option", [
  {
    text: "Open",
    icon: "reply",
    callback: (event, menu) ->
      form_permalink = menu.element.dataset.formPermalink
      permalink = menu.element.dataset.permalink

      App.redirect_to Routes.submission_path(form_permalink, permalink)
  },
  {
    text: "Open in New Tab",
    icon: "reply-all",
    callback: (event, menu) ->
      form_permalink = menu.element.dataset.formPermalink
      permalink = menu.element.dataset.permalink

      App.redirect_to Routes.submission_path(form_permalink, permalink), target: "_blank"
  },
  {
    separator: true
  },
  {
    text: "Edit",
    icon: "pencil",
    callback: (event, menu) ->
      form_permalink = menu.element.dataset.formPermalink
      permalink = menu.element.dataset.permalink

      App.Models.Form.new(form_permalink).submissions.open(permalink)
  },
  {
    text: "History",
    icon: "book-open",
    callback: (event, menu) ->
      form_permalink = menu.element.dataset.formPermalink
      permalink = menu.element.dataset.permalink

      App.Models.Form.new(form_permalink).submissions.load(permalink).then (submission) ->
        submission.modals.history().show()
  }
])
