
window.App ?= {}
window.App.Models ?= {}

class window.App.Models.MailMergeManager
  constructor: (@form_permalink) ->
    @callbacks = new CallbackManager(@)

    @_models = {}
    @_unsaved_models = []

  on: (event, callback) -> @callbacks.on(event, callback)

  _add: (value) ->
    if value instanceof App.Models.MailMerge
      model = value
      permalink = model.permalink()
    else
      permalink = value
      model = new App.Models.MailMerge(@form_permalink, permalink)

    if permalink?
      @_models[permalink] = model
    else
      @_unsaved_models.push(model)

    if !@_first_model?
      @_first_model = model
    else if !@_second_model?
      @_second_model = model
    else if !@_third_model?
      @_third_model = model

    @_last_model = model

    model

  _mark_as_persisted: (model) ->
    return unless model.persisted()

    if @_unsaved_models.indexOf(model) != -1
      @_unsaved_models.splice(@_unsaved_models.indexOf(model), 1)

    @_add(model)

  find: (value) ->
    @_models[permalink]

  find_by_instance_id: (instance_id) ->
    for model in @all()
      return model if model.instance_id == instance_id

    undefined

  # Return the first new mail merge
  find_new: ->
    if @_unsaved_models.length > 0
      @_unsaved_models[0].load()
    else
      @new()

  load: (permalink) ->
    return @_models[permalink] if @_models[permalink]?
    @_add(permalink)

  first: -> @_first_model
  second: -> @_second_model
  third: -> @_third_model
  last: -> @_last_model

  all: -> Object.values(@_models).concat(@_unsaved_models)

  new: -> @_add().load()
  edit: (permalink) -> @load(permalink).load()

  create: (input) ->
    data = App.Models.MailMerge.parameters_to_request_data(input)
    data.authenticity_token = current_user.authenticity_token()

    new Promise (resolve, reject) =>
      $.ajax
        url: Routes.create_mail_merge_path(@form_permalink, format: "json")
        method: "POST"
        data: data
      .done (response) =>
        model = @_add(response.mail_merge.permalink)

        @callbacks.trigger("create", model)

        resolve(model)
      .fail (xhr) ->
        reject(xhr)
