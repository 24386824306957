
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.RowToggling = {
  toggle_row: (value, status) ->
    if App.Helpers.Objects.isEvent(value)
      event = value

      if event.target != event.currentTarget
        return unless event.target.classList.contains("td-content")

      event.stopPropagation()

    row = @row_element(value)
    checkbox = row.querySelector(".row_select")
    return unless checkbox?

    if !status?
      if event? && event.currentTarget == checkbox
        status = checkbox.checked
      else
        status = !checkbox.checked

    document.getSelection().removeAllRanges()

    if status
      @selections.select(checkbox.dataset.permalink)
    else
      @selections.deselect(checkbox.dataset.permalink)

  toggle_rows_between: (start, finish, status) ->
    status ?= @selections.selected(start)
    permalinks = @row_permalinks()

    start_element = @row_element(start)
    finish_element = @row_element(finish)

    start = start_element.dataset.permalink
    finish = finish_element.dataset.permalink

    start_index = permalinks.indexOf(start)
    finish_index = permalinks.indexOf(finish)

    if start_index > finish_index
      old_start_index = start_index
      start_index = finish_index
      finish_index = old_start_index

    permalinks.splice(0, start_index)
    permalinks = permalinks.slice(0, finish_index - start_index + 1)

    for permalink in permalinks
      @toggle_row(permalink, status)

    permalinks

  shift_toggle_row: (value) ->
    return @toggle_row(value) unless @selections.any()

    first_selection = @selections.ordered_selections()[0]
    last_selection = @row_element(value).dataset.permalink

    @toggle_rows_between(first_selection, last_selection, true)
}
