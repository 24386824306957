class window.SelectListWidget
  # Accept arguments in the patterns (input, list, options) or (options)
  constructor: (argument1, argument2, argument3) ->
    args = App.Helpers.Arguments.overload(
      argument1, argument2, argument3, [
        [["input", HTMLElement], ["list", HTMLElement], ["options", Object]],
        [["input", jQuery], ["list", jQuery], ["options", Object]],
        [["input", HTMLElement], ["list", HTMLElement]],
        [["input", jQuery], ["list", jQuery]],
        [["options", Object]]
      ]
    )

    if args.input? && App.Helpers.Elements.is_jquery(args.input)
      args.input = args.input[0]

    if args.list? && App.Helpers.Elements.is_jquery(args.list)
      args.list = args.list[0]

    @options = args.options || {}

    @options.input = args.input if args.input?
    @options.list = args.list if args.list?

    @options.value_attribute ?= "data-value"
    @options.selector ?= "li"
    @options.multiple ?= false
    @options.selected_class ?= "list-option-selected"

    @input = @options.input
    @list = @options.list

    @list.addEventListener "click", (event) =>
      selected_element = App.Helpers.Elements.closest(event.target, @options.selector)

      @select(selected_element) if selected_element?

  list_options: ->
    @list.querySelectorAll(@options.selector)

  select: (value_or_element) ->
    value_or_element = value_or_element[0] if App.Helpers.Elements.is_jquery(value_or_element)

    if value_or_element.nodeType?
      element = value_or_element
      value = element.getAttribute(@options.value_attribute)
    else
      value = value_or_element
      element = @list.querySelector("#{@options.selector}[#{@options.value_attribute}='#{value}']")

    unless @options.multiple
      @input.value = ""

      for option in @list_options()
        option.classList.remove(@options.selected_class)
    
    element.classList.add(@options.selected_class)
    select_option = @input.querySelector("option[value='#{value}']")
    select_option.selected = true if select_option?

    event = new CustomEvent("select_list:change", {
      bubbles: true,
      detail: {
        value: value,
        target: element
      }
    })

    element.dispatchEvent(event)

    element
