import 'app/models/mail_merge/manager'
import 'app/models/mail_merge/object'
import 'app/schema/all'
import 'app/query/all'
import 'app/models/submission/object'
import 'app/models/submission/manager'
import 'modules/form/preference_manager'
import 'modules/workflows/workflow_manager'





window.App ?= {}
window.App.Models ?= {}

class window.App.Models.Form
  # Class Methods
  @_models: {}

  @new: (key) ->
    return unless key?

    @_models[key] ?= new @(key)
    @_models[key]

  @find: (key) -> @new(key)

  @all: -> Object.values(@_models)
  @first: -> @all()[0]
  @second: -> @all()[1]
  @third: -> @all()[2]
  @last: ->
    values = @all()
    values[values.length - 1]

  @schema: ->
    @_schema_promise ?= new Promise (resolve, reject) =>
      $.ajax
        url: "/api/rest/v1/forms/"
      .done (response) =>
        for form_response in response.forms
          form = @new(form_response.permalink)
          form._apply_schema(form_response)
          form._schema_options.bulk ?= true

        resolve(@)
      .fail (response) =>
        @_schema_promise = undefined

        reject(response.responseJSON)

    @_schema_promise

  @load: -> @schema()

  @count: ->
    new Promise (resolve, reject) =>
      @schema()
      .then => resolve(@all().length)
      .catch reject

  # Redirects to the form page if the current URL is `child_path`
  @redirect_on_child_destruction: (form, child_path) ->
    current_path = App.Helpers.URIs.parse(App.context().path)
    current_path.search = undefined

    child_path = App.Helpers.URIs.parse(child_path)
    child_path.search = undefined

    return unless current_path.equals(child_path, true)

    if !form.schema_loaded() || form.has_permission("read")
      return App.redirect_to Routes.submissions_path(form.permalink())

    App.redirect_to Routes.forms_index_path()

  constructor: (@form_permalink) ->
    @preferences = new PreferenceManager(@form_permalink)
    @submissions = new App.Models.SubmissionManager(@form_permalink)

    @fields = new App.Schema.Form.FieldManager(@form_permalink)
    @headers = new App.Schema.Form.HeaderManager(@form_permalink)
    @reports = new App.Schema.Form.ReportManager(@form_permalink)
    @procedures = new App.Schema.Form.ProcedureManager(@form_permalink)
    @calendars = new App.Schema.Form.CalendarManager(@form_permalink)
    @kanbans = new App.Schema.Form.KanbanManager(@form_permalink)

    @mail_merges = new App.Models.MailMergeManager(@form_permalink)
    @deleted_fields = new App.Schema.Form.FieldManager(@form_permalink)

    # Modal with embed codes for the form
    @embed_modal = App.Interface.Modal.from_url(Routes.embed_form_path(@form_permalink, display: "modal"))

    @custom_script = undefined
    @custom_sorting = JSON.parse($("##{@form_permalink}_custom_sorting").val() || "{}")

  # Name of the form
  name: ->
    unless @data?
      throw new Error("method requires schema() to be called on the form")

    @form_name

  # Permalink of the form
  permalink: ->
    @form_permalink

  persisted: ->
    @form_permalink?

  _request_schema: (data) ->
    new Promise (resolve, reject) =>
      $.ajax
        url: "/api/rest/v1/forms/#{@form_permalink}"
        data: data
      .done resolve
      .fail (response) -> reject(response.responseJSON)

  _apply_schema: (response, options) ->
    @_schema_options ?= {}

    @form_name ?= response.name

    if response.fields? && !@fields.loaded(options)
      source = @fields._requested_schema(options)
      source.loaded = true

      for variable_name, data of response.fields
        @fields.push(data, options)

    if response.headers? && !@headers.loaded(options)
      source = @headers._requested_schema(options)
      source.loaded = true

      for variable_name, data of response.headers
        @headers.push(data, options)

    if response.deleted_fields? && !@deleted_fields.loaded(options)
      @_schema_options.deleted_fields = true

      source = @deleted_fields._requested_schema(options)
      source.loaded = true

      for variable_name, data of response.deleted_fields
        @deleted_fields.push(data, options)

    # fields should not be deleted on the referenced response object
    data = Object.assign({}, response)
    delete data.fields
    delete data.headers

    @load_data(data)

  # TODO: Replace schema_options with sourcebase sources
  _apply_schema_options: (options) ->
    return unless options?

    @_schema_options ?= {}

    if options.deleted_fields?
      @_schema_options.deleted_fields ||= options.deleted_fields

    @_schema_options

  schema: (options = {}) ->
    @_schema_options ?= {}

    if options.cache_failures == true && @_failed_schema_promise?
      return @_failed_schema_promise

    if options.deleted_fields == true && !@_schema_options.deleted_fields
      @_schema_promise = undefined

    @_apply_schema_options(options)

    @_schema_promise ?= new Promise (resolve, reject) =>
      @_request_schema(options)
      .then (response) =>
        @_apply_schema(response)
        @_failed_schema_promise = undefined

        resolve(@)
      .catch (response) =>
        @_failed_schema_promise = @_schema_promise
        @_schema_promise = undefined

        reject(response)

    @_schema_promise

  load: -> @schema()
  loaded: -> @data?
  schema_loaded: -> @data?
  load_data: (data) -> @data = data

  has_permission: (value) ->
    if !@_schema_promise?
      throw new Error("method requires schema() to be called on the form")

    @data.permissions[value] || false

  permissions: ->
    if !@_schema_promise?
      throw new Error("method requires schema() to be called on the form")

    @data.permissions

  generate_query: (conditions = [], options = {}) ->
    App.Query.from_top_level_parameters(@, conditions, options)

  share_report: (report_permalink, event) ->
    @report_sharing_modals ?= {}
    @report_sharing_modals[report_permalink] ?= App.Interface.Modal.from_url(
      Routes.share_report_path(@form_permalink, report_permalink: report_permalink, display: "modal")
    )

    @report_sharing_modals[report_permalink].show(event)

  share_search: (report_search, event) ->
    @report_sharing_modals ?= {}
    @report_sharing_modals[report_search] ?= App.Interface.Modal.from_url(
      Routes.share_report_path(@form_permalink, report: report_search, display: "modal")
    )

    @report_sharing_modals[report_search].show(event)

  share_calendar: (calendar_permalink, event) ->
    @calendar_sharing_modals ?= {}
    @calendar_sharing_modals[calendar_permalink] ?= App.Interface.Modal.from_url(
      Routes.share_calendar_path(@form_permalink, calendar_permalink: calendar_permalink, display: "modal")
    )

    @calendar_sharing_modals[calendar_permalink].show(event)

  destroy: ->
    deferred = $.Deferred()

    $.ajax
      url: Routes.delete_form_path(@permalink(), format: "json")
      type: "DELETE"
      data: { authenticity_token: current_user.authenticity_token() }
    .done ->
      deferred.resolve()
    .fail ->
      deferred.reject()

    deferred

  subscribe: (report = undefined) ->
    $.ajax
      url: Routes.subscribe_form_path(
        @permalink(),
        report: report,
        format: "json"
      )
      type: "POST"
      data: { authenticity_token: current_user.authenticity_token() }

  unsubscribe: (report = undefined) ->
    $.ajax
      url: Routes.unsubscribe_form_path(
        @permalink(),
        report: report,
        format: "json"
      )
      type: "DELETE"
      data: { authenticity_token: current_user.authenticity_token() }

  subscription_toggle: (status = undefined, report = undefined) ->
    if status != true
      @unsubscribe(report)
    else
      @subscribe(report)

  permissions_modal: ->
    @_permissions_modal ?= App.Interface.Modal.from_url(Routes.form_permissions_path(@form_permalink, display: "modal"))
    @_permissions_modal

  report_permissions_modal: (permalink) ->
    @_report_permissions_modal ?= {}
    @_report_permissions_modal[permalink] ?= App.Interface.Modal.from_url(
      Routes.report_permissions_path(@form_permalink, permalink, display: "modal")
    )

    @_report_permissions_modal[permalink]

  viewer_settings_modal: ->
    if !@_viewer_settings_modal?
      @_viewer_settings_modal = App.Interface.Modal.from_url(Routes.edit_submission_viewer_columns_path(@form_permalink, display: "modal"))
      @_viewer_settings_modal.on "submit", (event) => @_viewer_settings_modal.unload()

    @_viewer_settings_modal

  _script_variables: (submission) ->
    {
      submission: submission,
      self: submission
    }

  _script_text: (submission) ->
    return Promise.resolve(@_script_string) if @_script_string?

    new Promise (resolve, reject) =>
      $.ajax
        url: submission._script_url()
      .done (response) => resolve(response)
      .fail (xhr) => reject(xhr.responseText)

  _script_blob: (submission) ->
    new Promise (resolve, reject) =>
      @_script_text(submission).then (script) ->
        try
          try
            blob = new Blob([script], { type: "application/javascript" })
          catch e
            BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder
            blob = new BlobBuilder()
            blob.append(script)
            blob = blob.getBlob()

          resolve(blob)
        catch e
          reject()

  _run_submission_workflows: (submission) =>
    return Promise.resolve(@custom_workflows(submission)) if @custom_workflows?

    @_get_workflows_script().then (script) => script(submission)

  _get_workflows_script: =>
    @_workflows_script ?= new Promise (resolve, reject) =>
      $.ajax
        url: Routes.submission_workflows_path(@permalink())
        dataType: "script"
      .done (response) =>
        @custom_workflows = (submission) =>
          variables = @_script_variables(submission)
          variables.Workflow = submission.workflows

          window["form_#{@permalink()}_submission_workflows"](variables)

        resolve(@custom_workflows)

    @_workflows_script


window.Form = App.Models.Form
