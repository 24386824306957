
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Helpers ?= {}
window.App.Models.Submission.Helpers.Sandboxes ?= {}

window.App.Models.Submission.Helpers.Sandboxes.register = (submission) ->
  submission.sandboxes.subscribe "visibility.hide", -> submission.hide()
  submission.sandboxes.subscribe "visibility.show", -> submission.show()

  submission.sandboxes.subscribe "redirect.disable", -> submission.disable_redirect()
  submission.sandboxes.subscribe "redirect.enable", -> submission.enable_redirect()
  submission.sandboxes.subscribe "redirect.update", (event) -> submission.redirect(event.data.value)
  submission.sandboxes.subscribe "redirect.send", (event) -> submission.redirect(event.data.force)

  submission.sandboxes.subscribe "destroy", -> submission.destroy()
  submission.sandboxes.subscribe "restore", -> submission.restore()

  submission.sandboxes.subscribe "page.open", (event) -> submission.pages.open(event.data.value)

  submission.sandboxes.subscribe "procedure.run", (event) ->
    submission.procedures.run(event.data.endpoint, event.data.parameters)
    .done (response) -> event.resolve(response)
    .fail (response) -> event.reject(response)

  submission.sandboxes.subscribe "field.update", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.value(event.data.value)

  submission.sandboxes.subscribe "field.default", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.default(event.data.value)

  submission.sandboxes.subscribe "field.rename", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.rename(event.data.value)

  submission.sandboxes.subscribe "field.disable", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.disable()

  submission.sandboxes.subscribe "field.enable", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.enable()

  submission.sandboxes.subscribe "field.visibility.hide", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.hide()

  submission.sandboxes.subscribe "field.visibility.show", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.show()

  submission.sandboxes.subscribe "field.errors.add", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.errors.add(event.data.value)

  submission.sandboxes.subscribe "field.errors.clear", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.errors.clear()

  submission.sandboxes.subscribe "field.highlight", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.highlight(event.data.value)

  submission.sandboxes.subscribe "field.persist", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    field.persist()

  submission.sandboxes.subscribe "field.menu.add", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    options = event.data.options
    instance_id = options.instance_id

    options.callback = ((sandboxes, instance_id) ->
      sandboxes.publish("field.menu.callback.#{instance_id}")
    )(submission, instance_id)

    field.menu(options)

  submission.sandboxes.subscribe "field.shortcut.add", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    options = event.data.options
    instance_id = options.instance_id

    options.callback = ((sandboxes, instance_id) ->
      sandboxes.publish("field.shortcut.callback.#{instance_id}")
    )(submission, instance_id)

    field.shortcut(options)

  submission.sandboxes.subscribe "field.cascading_value", (event) ->
    field = submission.fields.find_by_field_id(event.data.field_id)
    chained_field = submission.fields.find_by_field_id(event.data.chained_field_id)

    field.cascading_value(chained_field, event.data.value, event.data.chained_values)

  submission.validate -> submission.sandboxes.publish("validation.validate").promise()
  submission.before_submit -> submission.sandboxes.publish("validation.before_submit").promise()
  submission.before_create -> submission.sandboxes.publish("validation.before_create").promise()
  submission.before_update -> submission.sandboxes.publish("validation.before_update").promise()
  submission.after_submit -> submission.sandboxes.publish("validation.after_submit").promise()
  submission.after_create -> submission.sandboxes.publish("validation.after_create").promise()
  submission.after_update -> submission.sandboxes.publish("validation.after_update").promise()
  submission.failed_submit -> submission.sandboxes.publish("validation.failed_submit").promise()
  submission.failed_create -> submission.sandboxes.publish("validation.failed_create").promise()
  submission.failed_update -> submission.sandboxes.publish("validation.failed_update").promise()