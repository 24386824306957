
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseKanban ?= {}

class window.App.Elements.ResponseKanban.Column
  @build_item_element: (form_permalink, data, escape_data = true) ->
    item = document.createElement("A")
    item.href = "#"
    item.setAttribute("data-form-permalink", form_permalink)
    item.setAttribute("data-permalink", data.permalink)
    item.className = "kanban_item"

    # Server-side data is escaped by StructurePresenters on the server
    if escape_data
      item.textContent = data.name
    else
      item.innerHTML = data.name

    item

  @value_from_body: (element) ->
    return unless element?

    column = App.Helpers.Elements.closest(element, ".kanban_category")

    return unless column?

    column.dataset.category

  constructor: (@kanban, @element) ->
    @category_value = @element.dataset.category

    @body_element = @element.querySelector(".kanban_category_body")

    @initialize_sorting()

  value: -> @category_value
  items: -> @body_element.querySelectorAll(".kanban_item")

  permalinks: ->
    output = []
    output.push(item.dataset.permalink) for item in @items()
    output

  add: (data, escape_data = true) ->
    item = App.Elements.ResponseKanban.Column.build_item_element(
      @kanban.form().permalink(), data, escape_data
    )

    @body_element.appendChild(item)

  empty: ->
    @body_element.innerHTML = ""

    undefined

  assign: (permalink, previous_column) ->
    new Promise (resolve, reject) =>
      @kanban.form().submissions.load(permalink, page_loader: false).then (submission) =>
        field = submission.fields.find_by_column_name(@kanban.category_field)
        value = field.value()

        if old_category? && Array.isArray(value)
          if value.indexOf(old_category) != -1
            value.splice(value.indexOf(old_category), 1)

          value.push(@category_value)
        else
          value = @category_value

        field.value(value)

        submission.save(publish: false)
        .then resolve
        .catch reject

  initialize_sorting: ->
    return unless @kanban.has_permission("update") && @kanban.changeable()

    Sortable.create @body_element, {
      sort: true,
      draggable: ".kanban_item"
      ghostClass: "sortable-placeholder"
      group: "kanban_#{@kanban.permalink()}"
      onStart: =>
        @kanban.kanban_element.classList.add("sorting")
      onAdd: (event) =>
        permalink = event.item.dataset.permalink

        old_column = App.Elements.ResponseKanban.Column.value_from_body(event.from)
        new_column = App.Elements.ResponseKanban.Column.value_from_body(event.to)

        @kanban.columns.save_order([old_column, @category_value])
        @kanban.columns.find(new_column).assign(permalink, old_column)
      onUpdate: (event) =>
        categories = [
          App.Elements.ResponseKanban.Column.value_from_body(event.from),
          App.Elements.ResponseKanban.Column.value_from_body(event.to)
        ]

        @kanban.columns.save_order(categories)
    }
