
$ ->
  App.Elements.ResponseComment.load_visible_comments()

  setTimeout((->
    App.Elements.ResponseComment.load_visible_comments()
  ), 1000)

$(document).on "page:open", (event, context) ->
  scope = context.page_element() if context?
  scope ?= document

  App.Elements.ResponseComment.load_visible_comments(scope)

  setTimeout((->
    App.Elements.ResponseComment.load_visible_comments(scope)
  ), 1000)

(->
  main_element = document.querySelector(".document-main")
  return unless main_element?

  if App.Helpers.Events.passive_listeners
    event_options = { passive: true }
  else
    event_options = false

  comment_load_timer = null
  comment_load_listener = ->
    clearTimeout(comment_load_timer) if comment_load_timer?

    comment_load_timer = setTimeout((->
      scope = document.body
      scope = App.context().page_element() if App.context?

      App.Elements.ResponseComment.load_visible_comments(scope)
    ), 100)

  main_element.addEventListener("scroll", comment_load_listener, event_options)
  main_element.addEventListener("resize", comment_load_listener, event_options)
)()

$(document).on "click", ".load-comments", (event) ->
  event.preventDefault()

  button = event.currentTarget
  comments_element = App.Helpers.Elements.closest(button, ".comment-section")

  return if loading comments_element

  viewer_element = App.Helpers.Elements.closest(comments_element, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.load()

$(document).on "click", ".show-resolved-comments", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button
  add_loader button

  viewer_element = App.Helpers.Elements.closest(button, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.show_resolved().done ->
    remove_loader button
