
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Sidebar ?= {}

class window.App.Interface.Sidebar.SideBar
  constructor: (@element) ->
    @element = @element[0] if App.Helpers.Elements.is_jquery(@element)

  is_overview: -> false

  empty: -> @element.querySelector(".option, .sidebar-option")?

  calculate_button_visibility: ->
    return unless App.Sidebar.mobile()

    hidden = @empty() && App.Sidebar.container().mobile_menu_empty()

    toggle_button = App.menu().element.querySelector(".toggle_document_sidebar")
    toggle_button.classList.toggle("hidden", hidden)

  visible: ->
    return @mobile_visible() if App.Sidebar.mobile()

    return false unless App.Sidebar.container().visible()

    !@element.classList.contains("hidden")

  active: ->
    !@element.classList.contains("hidden") && @attached()

  attached: ->
    App.Helpers.Elements.closest(@element, ".document-sidebar")?

  empty: ->
    @element.children.length == 0

  show: (options) ->
    return unless @element?

    return if options? && options.manual == false && App.is_fullscreen()
    return if @empty()

    wrapper = document.getElementById("document-sidebar")
    wrapper.classList.toggle("sidebar-dark", @constructor.DARK_MODE == true)

    App._is_fullscreen = false
    App.Sidebar.container().show(@, options)
    App.Interface.Menu.show_head()
    App.user_drawer().hide()

    @generate_scrollbars()

    undefined

  hide: ->
    return unless @element?

    @element.classList.add("hidden")

    App.Sidebar.container().hide(@)

    undefined

  toggle: (event) ->
    if @visible() then @hide() else @show()

  mobile_visible: ->
    container = App.Sidebar.container()
    container.mobile_visible() && !@element.classList.contains("hidden")

  mobile_show: (options) ->
    container = App.Sidebar.container()
    return unless container?

    container.mobile_show(@, options)

    undefined

  mobile_hide: ->
    container = App.Sidebar.container()
    return unless container?

    container.mobile_hide(@)

    undefined

  generate_scrollbars: ->
    if App.Sidebar._scroller?
      App.Sidebar._scroller.rebuild()
    else
      sidebar_wrapper = document.getElementById("document-sidebar")
      sidebar_inner = document.getElementById("document-sidebar-inner")

      sidebar_wrapper.classList.add("antiscroll-wrap")
      sidebar_inner.classList.add("antiscroll-inner")

      App.Sidebar._scroller = $(sidebar_wrapper).antiscroll().data("antiscroll")

  generate_overview_return: (options) ->
    return_button = @element.querySelector(".toggle_overview_sidebar_return")
    return_button.remove() if return_button?

    return_button = document.createElement("A")
    return_button.className = "sidebar-option sidebar-option-large toggle_overview_sidebar_return"

    return_icon = document.createElement("I")
    return_icon.className = "icon icon-left-open"

    if App.overview_sidebar().title?
      return_text = document.createTextNode(App.overview_sidebar().title)
    else
      return_text = document.createTextNode("Overview")

    return_button.appendChild(return_icon)
    return_button.appendChild(return_text)

    @element.insertBefore(return_button, @element.firstChild)

    null
