
window.App ?= {}
window.App.Query ?= {}
window.App.Query.FieldCondition ?= {}

class window.App.Query.FieldCondition.Address extends App.Query.FieldCondition.Base
  constructor: (@field, @_operator, @_value, @_options = {}) ->
    super(@field, @_operator, @_value, @_options)
  
  @operators: [
    "[address]==", "[address]!=", "[address]%", "[address]!%", "[address]?", "[address]!?",
    "[city]==", "[city]!=", "[city]%", "[city]!%", "[city]?", "[city]!?"
    "[postcode]==", "[postcode]!=", "[postcode]%", "[postcode]!%", "[postcode]?", "[postcode]!?"
    "[region]==", "[region]!=", "[region]%", "[region]!%", "[region]?", "[region]!?"
    "[country]==", "[country]!=", "[country]%", "[country]!%", "[country]?", "[country]!?"
  ]

  @aggregators: ["count"]

  @groupings: ["address", "city", "postcode", "region"]
