
window.App ?= {}
window.App.Schema ?= {}

window.App.Schema.SourceBase = {
  _route_get: (params) ->
    if App.is_child
      throw "Method unavailable in sandbox"  unless params.channel?

      channel_data = Object.assign({}, params.channel.data)

      if params.options? && params.options.source?
        channel_data.source = params.options.source

      return App.parent.publish(params.channel.event, channel_data)
    else
      new Promise (resolve, reject) =>
        $.ajax
          url: App.Schema.source_url(params.api.url, params.options)
        .done resolve
        .fail (response) -> reject(response.responseJSON)

  _route_create: (params) ->
    new Promise (resolve, reject) =>
      if App.is_child
        throw "Method unavailable in sandbox"  unless params.channel?
        channel_data = Object.assign({}, params.channel.data)

        if params.options? && params.options.source?
          channel_data.source = params.options.source

        App.parent.publish(params.channel.event, channel_data)
        .then resolve
        .fail reject
      else
        ajax_data = Object.assign({}, params.api.data)
        ajax_data.authenticity_token ?= current_user.authenticity_token()

        $.ajax
          url: App.Schema.source_url(params.api.url, params.options)
          type: "POST"
          data: ajax_data
        .done (response) =>
          model = @new(response)
          resolve(model)
        .fail reject

  _route_update: (params) ->
    new Promise (resolve, reject) =>
      if App.is_child
        throw "Method unavailable in sandbox"  unless params.channel?
        channel_data = Object.assign({}, params.channel.data)

        if params.options? && params.options.source?
          channel_data.source = params.options.source

        App.parent.publish(params.channel.event, channel_data)
        .then (response) =>
          @load_data(response, params.options)
          resolve(@)
        .fail reject
      else
        ajax_data = Object.assign({}, params.api.data)
        ajax_data.authenticity_token ?= current_user.authenticity_token()

        $.ajax
          url: App.Schema.source_url(params.api.url, params.options)
          type: "PATCH"
          data: ajax_data
        .done (response) =>
          @load_data(response, params.options)

          if !App.is_child && @channel_exists? && @channel_exists()
            @_publish("update", response)

          resolve(@)
        .fail reject

  _route_destroy: (params) ->
    new Promise (resolve, reject) =>
      if App.is_child
        throw "Method unavailable in sandbox"  unless params.channel?
        channel_data = Object.assign({}, params.channel.data)

        if params.options? && params.options.source?
          channel_data.source = params.options.source

        App.parent.publish(params.channel.event, channel_data)
        .then => resolve(@)
        .fail reject
      else
        $.ajax
          url: App.Schema.source_url(params.api.url, params.options)
          type: "DELETE"
          data: {
            authenticity_token: current_user.authenticity_token(),
          }
        .done (response) =>
          if !App.is_child && @channel_exists? && @channel_exists()
            @_publish("destroy")

          resolve(@)
        .fail reject

  _requested_schema: (options) ->
    if options? && options.source?
      source_url = App.Schema.source_url(options.source)

      @_source_schemas ?= {}
      @_source_schemas[source_url] ?= {}
      @_source_schemas[source_url].collection ?= new App.Schema.ChildCollection(@)

      return @_source_schemas[source_url]
    else
      @_base_schema ?= {}
      @_base_schema.collection ?= new App.Schema.ChildCollection(@)

      return @_base_schema

  base_schema: ->
    @_requested_schema()

  load: (options) -> @schema(options)

  loaded: (options, conditions) ->
    source = @_requested_schema(options)

    return false unless source.loaded == true

    if !conditions? || !conditions.allow_empty == true
      return false unless source.collection.models().length > 0

    true

  reload: (options) ->
    source = @_requested_schema(options)
    source.collection = new App.Schema.ChildCollection(@)
    source.promise = undefined
    source.loaded = false

    @load(options)
}

class window.App.Schema.ChildCollection
  include @, App.Schema.CollectionBase

  constructor: (@parent) -> null
  model_class: -> @parent.model_class()
  model_instance: (data) -> @parent.model_instance(data)

  _value_to_model: -> @parent._value_to_model.apply(@parent, arguments)
