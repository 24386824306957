
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.ContextMenu ?= {}

window.App.Interface.ContextMenu.BUTTON_SELECTOR = "a.context_menu_item, button.context_menu_item"
window.App.Interface.ContextMenu.ACTIVE_BUTTON_SELECTOR = "a.context_menu_item:not([disabled]), button.context_menu_item:not([disabled])"

window.App.Interface.ContextMenu.new = (scope_or_element, element_or_actions = [], actions) ->
  new App.Interface.ContextMenu.Object(scope_or_element, element_or_actions, actions)

window.App.Interface.ContextMenu.parse_actions = (actions = []) ->
  output = []

  for action in actions
    parsed_action = App.Interface.ContextMenu.parse_action(action)
    output.push(parsed_action) if parsed_action?

  output

window.App.Interface.ContextMenu.parse_action = (options = {}) ->
  options.active = options.if if options.if?

  return if options.active == false

  output = {}
  output.instance_id = App.Helpers.Generators.uuid()

  if options.active? && App.Helpers.Objects.isFunction(options.active)
    output.active = options.active

  if options.separator == true
    output.separator = true
    return output

  if options.html?
    output.html = options.html
  else
    output.icon = options.icon
    output.text = options.text || options.label

    output.icon_color = options.icon_color if options.icon_color?

  if options.callback?
    output.callback = options.callback

  if options.listener?
    output.listener = options.listener

  output.disabled = (options.disabled == true)

  if options.children?
    if Array.isArray(options.children)
      output.children = App.Interface.ContextMenu.parse_actions(options.children)
    else
      output.children = options.children

  if options.href?
    output.href = options.href

    if options.single_page?
      output.single_page = options.single_page
  else
    output.href = "#"

  if options.target?
    output.target = options.target

  if options.class?
    output.class = options.class

  if options.refresh_on_click?
    output.refresh_on_click = options.refresh_on_click

  if options.cache_children?
    output.cache_children = options.cache_children

  if options.paginate_children?
    output.paginate_children = options.paginate_children

  output

window.App.Interface.ContextMenu.any_icons_present = (actions) ->
  for action in actions
    return true if action.icon? && action.icon != ""

  false

window.App.Interface.ContextMenu.actions_to_element = (event, actions, context_menu) ->
  return if !actions? || actions.length == 0

  container = document.createElement("DIV")
  container.className = "context_menu"
  container.classList.add("context_menu_small") if context_menu.config.small
  container.classList.add("context_menu_medium") if context_menu.config.medium
  container.classList.add("no_icons") if !App.Interface.ContextMenu.any_icons_present(actions)

  layer_element = document.createElement("DIV")
  layer_element.className = "context_menu_layer"

  body_element = document.createElement("DIV")
  body_element.className = "context_menu_body"

  for options in actions
    element = App.Interface.ContextMenu.action_to_element(event, options, context_menu)
    body_element.appendChild(element)

  return unless body_element.querySelector("*:not(.empty_context_menu_item)")?

  container.appendChild(layer_element)
  container.appendChild(body_element)
  container

window.App.Interface.ContextMenu.action_to_element = (event, options, context_menu) ->
  if options.active? && options.active.call(context_menu, event, context_menu) == false
    element = document.createElement("DIV")
    element.className = "empty_context_menu_item"
    return element

  if options.separator
    element = document.createElement("DIV")
    element.className = "context_menu_separator"
    element.dataset.instance = options.instance_id

    return element

  if options.html?
    if typeof options.html == "function"
      element = options.html.call(context_menu, event, context_menu)
    else
      element = options.html

    element = App.Helpers.Elements.from_html(element)
    element.dataset.instance = options.instance_id
  else
    element = document.createElement("A")
    element.dataset.instance = options.instance_id

    # Icon element generated for options without icons for spacing
    icon_element = document.createElement("I")
    icon_element.className = "icon"
    icon_element.classList.add("icon-#{options.icon}") if options.icon?
    icon_element.classList.add(options.icon_color) if options.icon_color?

    if typeof options.text == "function"
      text = options.text.call(context_menu, event, context_menu)
    else
      text = options.text

    text_node = document.createTextNode(text)

    element.appendChild(icon_element)
    element.appendChild(text_node)

  if options.class?
    element.className = options.class

  element.classList.add("context_menu_item")

  if element.tagName.toUpperCase() == "A"
    element.setAttribute("href", options.href || "#")
    element.setAttribute("target", options.target) if options.target?
    element.setAttribute("data-object", "_") if options.single_page

  element.setAttribute("disabled", "disabled") if options.disabled

  element.classList.add("context_menu_parent") if options.parent == true
  element.classList.add("context_menu_children") if options.children?
  element.classList.add("context_menu_refresh") if options.refresh_on_click == true

  if options.listener?
    element.addEventListener "click", ((listener) -> (event) ->
      listener.call(context_menu, event, context_menu)
    )(options.listener)

  if options.callback?
    element.addEventListener "click", ((callback) -> (event) ->
      event.preventDefault()
      callback.call(context_menu, event, context_menu)
    )(options.callback)

  element

window.App.Interface.ContextMenu.action_to_submenu = (event, options, context_menu) ->
  new Promise (resolve, reject) ->
    if App.Helpers.Objects.isFunction(options.children)
      children = options.children.call(context_menu, event, context_menu)

      if Array.isArray(children)
        children = App.Interface.ContextMenu.parse_actions(children)
        options.children = children
    else
      children = options.children

    if Array.isArray(children)
      resolve App.Interface.ContextMenu.submenu_result_to_element(event, options, context_menu)
    else
      children.then (result) ->
        options.children = App.Interface.ContextMenu.parse_actions(result)
        resolve App.Interface.ContextMenu.submenu_result_to_element(event, options, context_menu)

window.App.Interface.ContextMenu.paginate_children = (children, length) ->
  length = 7 if length == true

  return children if children.length <= length

  next_children = App.Helpers.Objects.deep_clone(children.splice(length))
  next_children = App.Interface.ContextMenu.paginate_children(next_children, length)

  children.push(App.Interface.ContextMenu.parse_action({
    text: i18n.t("more_options"),
    children: next_children
  }))

  children

window.App.Interface.ContextMenu.submenu_result_to_element = (event, options, context_menu) ->
  parent_option = Object.assign({}, options)
  parent_option.parent = true
  parent_option.children = undefined
  parent_option.icon = "left-open"

  if options.paginate_children? && options.paginate_children != false
    App.Interface.ContextMenu.paginate_children(options.children, options.paginate_children)

  children_with_header = options.children.slice(0)
  children_with_header.unshift(parent_option)

  App.Interface.ContextMenu.actions_to_element(event, children_with_header, context_menu)

class window.App.Interface.ContextMenu.Object
  constructor: (scope_or_element, element_or_actions, actions) ->
    options = App.Helpers.Arguments.overload(
      scope_or_element, element_or_actions, actions, [
        [["element", HTMLElement], ["actions", Array]],
        [["element", jQuery], ["actions", Array]],
        [["selector", String], ["actions", Array]],
        [["scope", HTMLElement], ["selector", String], ["actions", Array]],
        [["scope", jQuery], ["selector", String], ["actions", Array]],
        [["actions", Array]]
      ]
    )

    @original_actions = App.Helpers.Objects.deep_clone(options.actions)
    @actions = App.Interface.ContextMenu.parse_actions(options.actions)
    @config = {}

    if options.element?
      options.element = options.element[0] if App.Helpers.Elements.is_jquery(options.element)

      @element = options.element

      @element.addEventListener "contextmenu", (event) =>
        event.preventDefault()
        @show(event)

    if options.selector?
      options.scope ?= document
      options.scope = options.scope[0] if App.Helpers.Elements.is_jquery(options.scope)

      return unless options.scope?

      @selector = options.selector

      options.scope.addEventListener "contextmenu", (event) =>
        @element = undefined

        if App.Helpers.Elements.matches(event.target, @selector)
          @element = event.target

        @element ?= App.Helpers.Elements.closest(event.target, @selector)

        if @element?
          event.preventDefault()
          @_reset_actions()

          @show(event)

  _position: ->
    old_body = @menu.querySelector(".context_menu_body")

    return unless old_body?

    {
      x: parseInt(old_body.style.left),
      y: parseInt(old_body.style.top)
    }

  # Create multiple sets of actions for ContextMenus applied to selectors,
  # one for each node the menu is called upon.
  _reset_actions: ->
    @element.id ||= App.Helpers.Generators.instance_id()

    @_actions ?= @actions
    @_actions_by_node ?= {}
    @_actions_by_node[@element.id] ?= App.Helpers.Arrays.deep_clone(@_actions)

    @_original_actions ?= @original_actions
    @_original_actions_by_node ?= {}
    @_original_actions_by_node[@element.id] ?= App.Helpers.Arrays.deep_clone(@_original_actions)

    @actions = @_actions_by_node[@element.id]
    @original_actions = @_original_actions_by_node[@element.id]

  _set_actions: (new_actions) ->
    @element.id ||= App.Helpers.Generators.instance_id()

    @_original_actions_by_node ?= {}
    @_original_actions_by_node[@element.id] ?= App.Helpers.Arrays.deep_clone(new_actions)

    new_actions = App.Interface.ContextMenu.parse_actions(new_actions)
    @_actions_by_node ?= {}
    @_actions_by_node[@element.id] = App.Helpers.Arrays.deep_clone(new_actions)

    @actions = @_actions_by_node[@element.id]
    @original_actions = @_original_actions_by_node[@element.id]

  _reload_original_actions: ->
    @_reload_nested_original_actions(@actions, @original_actions)

  _reload_nested_original_actions: (current, original) ->
    for action, index in current
      if action.cache_children == false
        action.children = original[index].children
      else if original[index]? && original[index].children?
        @_reload_nested_original_actions(action.children, original[index].children)

    current

  _activate: ->
    if App.Interface.ContextMenu.active_menu?
      App.Interface.ContextMenu.active_menu.hide()

    App.Interface.ContextMenu.active_menu = @

  _attach_element: (event, menu_options = {}) ->
    document.body.appendChild(@menu)

    body = @menu.querySelector(".context_menu_body")
    background = @menu.querySelector(".context_menu_layer")

    @generate_menu_position(event, body, menu_options.position)
    @generate_menu_events(background, body, menu_options.events)

    first_input = body.querySelector("input, textarea")
    first_input.select() if first_input?

  _generate_element: (event, actions, menu_options) ->
    @_activate()

    # Menu must be visible before body height can be accurately fetched
    if actions? && actions != @actions
      App.Interface.ContextMenu.action_to_submenu(event, actions, @)
      .then (menu) =>
        @menu = menu
        @_attach_element(event, menu_options)
    else
      return unless @actions?
      menu_element = App.Interface.ContextMenu.actions_to_element(event, @actions, @)
      return unless menu_element?
      @menu = menu_element
      @_attach_element(event, menu_options)

  _deactivate: ->
    if App.Interface.ContextMenu.active_menu == @
      App.Interface.ContextMenu.active_menu = undefined

  _destroy_element: ->
    if @menu?
      document.body.removeChild(@menu)
      delete @menu

      @menu = undefined

  _find_option_path_by_id: (instance_id, scope) ->
    scope ?= @actions

    for option in scope
      return [option.instance_id] if option.instance_id == instance_id

      if option.children? && Array.isArray(option.children)
        child_output = @_find_option_path_by_id(instance_id, option.children)

        if child_output?
          return [option.instance_id].concat(child_output)

    undefined

  _find_option_by_id: (instance_id, scope) ->
    scope ?= @actions

    for option in scope
      return option if option.instance_id == instance_id

      if option.children? && Array.isArray(option.children)
        child_option = @_find_option_by_id(instance_id, option.children)
        return child_option if child_option?

    undefined

  hide: (event) ->
    return unless @menu?

    @_deactivate()
    @_destroy_element()
    @_reload_original_actions()

    @element.dispatchEvent(new CustomEvent("context_menu.hide", detail: { menu: @ }))

  show: (event, menu_options = {}) ->
    return if !@actions? || @actions.length == 0

    options = App.Helpers.Arguments.overload(
      event, menu_options, [
        [["event", window.Event], ["menu_options", window.Object]],
        [["menu_options", window.Object]],
      ]
    )

    @_activate()

    @_generate_element(options.event, undefined, options.menu_options)

    @element.dispatchEvent(new CustomEvent("context_menu.show", detail: { menu: @ }))

  show_instance: (event, instance_id) ->
    menu_options = { position: @_position() }

    if !instance_id? || instance_id.length == 0
      return @show(menu_options)

    @_deactivate()
    @_destroy_element()

    option_path = @_find_option_path_by_id(instance_id)

    return @show(event, menu_options) if !option_path? || option_path.length < 2

    option_instance = option_path[option_path.length - 1]
    option = @_find_option_by_id(option_instance)

    @_generate_element(event, option, menu_options)

  show_parent: (event, instance_id) ->
    menu_options = { position: @_position() }

    if !instance_id? || instance_id.length == 0
      return @show(menu_options)

    @_deactivate()
    @_destroy_element()

    option_path = @_find_option_path_by_id(instance_id)

    return @show(event, menu_options) if !option_path? || option_path.length < 2

    option_instance = option_path[option_path.length - 2]
    option = @_find_option_by_id(option_instance)

    @_generate_element(event, option, menu_options)

  show_children: (event, instance_id) ->
    menu_options = { position: @_position() }

    if !instance_id? || instance_id.length == 0
      return @show(menu_options)

    @_deactivate()
    @_destroy_element()

    option = @_find_option_by_id(instance_id)
    return unless option?

    @_generate_element(event, option, menu_options)

  add: (action) ->
    @actions.push App.Interface.ContextMenu.parse_action(action, [@actions.length])

  generate_menu_position: (event, body, options = {}) ->
    if event?
      x_coord = event.pageX
      y_coord = event.pageY
    else
      bounds = @element.getBoundingClientRect()
      x_coord = bounds.left
      y_coord = bounds.top

    y_coord = options.y if options.y?
    x_coord = options.x if options.x?

    @coords = new App.Objects.Coordinates(x_coord, y_coord)

    App.Helpers.Elements.Floating.position_element(body, [[x_coord, y_coord]])

    body

  generate_menu_events: (background, body) ->
    body.setAttribute("tabindex", "-1")
    body.focus()

    background.addEventListener "click", (event) =>
      event.preventDefault()
      event.stopPropagation()
      @hide(event)

    background.addEventListener "contextmenu", (event) =>
      # Not using preventDefault lets double-clicking open the browser
      # context menu on the clicked element
      coordinates = new App.Objects.Coordinates(event.pageX, event.pageY)

      if !@coords.equals(coordinates)
        event.preventDefault()
      
      @hide(event)

    body.addEventListener "click", (event) =>
      button = App.Helpers.Elements.closest(event.target, App.Interface.ContextMenu.BUTTON_SELECTOR)
      return unless button?

      if button.classList.contains("context_menu_parent")
        event.preventDefault()

        # Prevent the button from being double-clicked if loading deferred
        return if button.classList.contains("active")
        button.classList.add("active")

        return @show_parent(event, button.dataset.instance)

      if button.classList.contains("context_menu_children")
        event.preventDefault()

        # Prevent the button from being double-clicked if loading deferred
        return if button.classList.contains("active")
        button.classList.add("active")

        return @show_children(event, button.dataset.instance)

      if button.classList.contains("context_menu_refresh")
        event.preventDefault()

        # Prevent the button from being double-clicked if loading deferred
        return if button.classList.contains("active")
        button.classList.add("active")

        return @show_instance(event, button.dataset.instance)

      @hide(event)

    body.addEventListener "contextmenu", (event) =>
      coordinates = new App.Objects.Coordinates(event.pageX, event.pageY)
      return @hide(event) if @coords.equals(coordinates)
      event.preventDefault()

    body.addEventListener "keydown", (event) =>
      event_key = event.which || event.keyCode

      intercepted_events = [27, 32, 33, 34, 35, 36, 37, 38, 39, 40]
      intercepted_input_events = [38, 40]

      return if event.target.tagName.toUpperCase() == "INPUT" && intercepted_input_events.indexOf(event_key) == -1
      return if intercepted_events.indexOf(event_key) == -1

      event.preventDefault()

      menu_links = Array.from(body.querySelectorAll(App.Interface.ContextMenu.ACTIVE_BUTTON_SELECTOR))

      focused_item = body.querySelector(".context_menu_item:focus")
      focused_item_fallback = false

      if !focused_item?
        focused_item = menu_links[0]
        focused_item_fallback = true

      focused_instance = focused_item.dataset.instance if focused_item?

      switch event_key
        when 13 # Enter
          return unless focused_item?
          focused_item.click()
        when 27 # Escape
          @hide(event)
        when 32, 33, 34 # Space, Page Up, Page Down
          event.preventDefault()
        when 35 # End
          return unless focused_item?
          menu_links[menu_links.length - 1].focus()
        when 36 # Home
          return unless focused_item?
          menu_links[0].focus()
        when 37 # Left
          @show_parent(event, focused_instance) if focused_instance?
        when 39 # Right
          @show_children(event, focused_instance) if focused_instance?
        when 38 # Up
          return unless focused_item?
          return menu_links[menu_links.length - 1].focus() if focused_item_fallback

          focused_index = menu_links.indexOf(focused_item)

          if focused_index == 0
            menu_links[menu_links.length - 1].focus()
          else
            menu_links[focused_index - 1].focus()
        when 40 # Down
          return unless focused_item?
          return menu_links[0].focus() if focused_item_fallback

          focused_index = menu_links.indexOf(focused_item)

          if focused_index == menu_links.length - 1
            menu_links[0].focus()
          else
            menu_links[focused_index + 1].focus() 

# Exit an open context menu with ESCAPE
Hotkey.bind(["ESCAPE"], if: (-> App.Interface.ContextMenu.active_menu?), do: (-> App.Interface.ContextMenu.active_menu.hide()))

# Back button to close context menu on mobile
window.onpopstate = (event) ->
  if App.is_mobile() && App.Interface.ContextMenu.active_menu?
    event.preventDefault()

    App.Interface.ContextMenu.active_menu._deactivate()
