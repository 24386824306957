import 'js-cookie'


window.App ?= {}
window.App.Tutorial ?= {}

# lesson.step {
#   name: "Step Title"
#   text: "Step Body"
#   trigger: ((resolve) -> resolve())
#   skip: (-> true)
#   callback: (-> ...)
#   display: "modal"
#   modal: {
#     actions: [...]
#     options: {...}
#   }
# }

class window.App.Tutorial.Lesson
  constructor: (@id, @name) ->
    window.App.Tutorial.Lessons[@id] = @

    @current_index = -1

    @_raw_steps = []

    @steps = []
    @_steps_by_id = []

  step: (options) ->
    @_raw_steps.push(options)

    if options.name?
      @name ?= options.name

    options

  get: (selector) ->
    @activate_steps()

    if Number.isInteger(selector)
      return @steps[selector]

    if App.Helpers.Objects.isString(selector)
      return @_steps_by_id[selector]

    undefined

  activate_steps: ->
    return @steps if @steps.length > 0

    @activate_step(options) for options in @_raw_steps

    @steps

  activate_step: (options) ->
    step = Object.assign({}, options)
    index = @steps.length

    if options.text instanceof Function
      step.text = options.text.call(@)

    if options.trigger?
      step.trigger.call(@, App.Tutorial.Lesson.trigger_callback(@, options.id || index))

    if options.id?
      @_steps_by_id[options.id] = options

    @steps.push(step)

    step

  cleanup: (selector, next_index = null) ->
    step = @get(selector)
    return unless step?

    step.cleanup.call(@) if step.cleanup?

    switch @display(selector)
      when "menu"
        return if next_index? && @display(next_index) == "menu"
        App.menu().remove("tutorial")
      when "modal"
        modal = App.Interface.Modal.find_by_id("tutorial")
        modal.destroy() if modal?

    undefined

  next_valid_index: (selector) ->
    step = @get(selector)
    next_index = @steps.indexOf(step) + 1

    while next_index < @steps.length
      next_step = @get(next_index)
      return unless next_step?

      if !next_step.skip? || !next_step.skip.call(@)
        return next_index

      next_index += 1

    undefined

  _execute: (selector) ->
    step = @get(selector)

    @current_index = @steps.indexOf(step)

    Cookies.set("tutorial", { id: @id, step: step.id || selector })

    switch @display(selector)
      when "callback"
        step.callback.call(@)
      when "menu"
        App.menu().tutorial(step.text)
      when "modal"
        modal_actions = []
        modal_options = {}

        if step.modal?
          modal_actions = step.modal.actions if step.modal.actions?
          modal_options = step.modal.options if step.modal.options?

        modal_options.id = "tutorial"

        App.Interface.Modal.new(step.name || @name, step.text, modal_actions, modal_options)

  trigger: (selector) ->
    step = @get(selector)
    return unless @current_index == @steps.indexOf(step) - 1

    @run(selector)

  run: (selector) ->
    step = @get(selector)
    index = @next_valid_index(@current_index)

    if !step?
      throw "Tutorial `#{@id}` does not have a step #{index}."

    @cleanup(@current_index, index)

    @_execute(index)

  display: (selector) ->
    step = @get(selector)
    return unless step?

    return step.display if step.display?

    return "callback" if step.callback?

    return "menu" if App.menu()

    "modal"

  next: -> @run(@current_index + 1)

  start: -> @run(0)
  started: -> @current_index >= 0

  stop: ->
    Cookies.remove("tutorial")

    App.Tutorial.Lesson.remove_highlights() if @_highlighted_elements

    @cleanup(@current_index)
    @current_index = -1

  restart: ->
    @stop()
    @start()

  run_on_reload: ->
    Cookies.set("tutorial", { id: @id })

  # Generates a "resolve" function parameter for the step trigger option.
  @trigger_callback: (lesson, selector) ->
    -> lesson.trigger(selector)

  @highlight_elements: (selector) ->
    @remove_highlights()

    new_highlights = document.querySelectorAll(selector)

    for element in new_highlights
      element.classList.add("tutorial_highlight")

    undefined

  @remove_highlights: ->
    old_highlights = document.querySelectorAll(".tutorial_highlight")

    for element in old_highlights
      element.classList.remove("tutorial_highlight")

    # Handle highlighting for any off-page elements.
    if App.windowing_enabled()
      for context in App.WindowContext.all()
        if !context.visible()
          for context_element in context.elements()
            old_highlights = context_element.querySelectorAll(".tutorial_highlight")

            for element in old_highlights
              element.classList.remove("tutorial_highlight")

    undefined

$(document).on "ready", ->
  data = Cookies.getJSON("tutorial")
  return unless data?

  tutorial = App.Tutorial.Lessons[data.id]?
  return Cookies.remove("tutorial") unless tutorial?

  tutorial.step(data.step || 0)
