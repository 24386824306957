
jQuery.fn.extend({
  detect_links: (options = {}) ->
    for element in this
      App.Helpers.Elements.linkify(element, options)
})

window.detect_links = (element, options = {}) ->
  App.Helpers.Elements.linkify(element, options)

# Gets an element from an indeterminate object passed (may be an event, element, etc.)
window.element_from_object = (object) ->
  return undefined if !object
  return $(object) if object.nodeType?
  return $(object.currentTarget) if object.currentTarget
  object

# Gets a DOM Node from an indeterminate object passed (may be an event, element, etc.)
window.node_from_object = (object) ->
  return unless object?
  return object.currentTarget if object.currentTarget?
  object

window.App.is_mobile = ->
  # return false unless window.matchMedia?
  # window.matchMedia("only screen and (max-width: 800px)").matches

  if window.innerWidth?
    return window.innerWidth <= 800

  if window.documentElement? && window.documentElement.clientWidth?
    return window.documentElement.clientWidth <= 800

  if document.body? && document.body.clientWidth?
    return document.body.clientWidth <= 800

  false

window.App.is_fullscreen = ->
  if App.is_mobile()
    container = App.Sidebar.container()
    return !container.mobile_visible() if container?

  App._is_fullscreen == true

window.App.fullscreen = (value) ->
  return App.is_fullscreen() unless value?

  head_navbar = App.Menu.head_element

  if value == true
    App._is_fullscreen = true
    App.Interface.Menu.hide_head()
    App.Event.publish("fullscreen.open")

    sidebar = App.sidebar()
    if sidebar?
      sidebar.hide()
      App.Event.publish("sidebar.hide")
  else
    App._is_fullscreen = false
    App.Interface.Menu.show_head()
    App.Event.publish("fullscreen.close")

    sidebar = App.sidebar()
    if sidebar?
      sidebar.show()
      App.Event.publish("sidebar.show")

  $(window).trigger("resize")
  $(document).trigger("resize")

  App.is_fullscreen()

# Allow setting the data-checked-toggle attribute to a selector to toggle visibility for
$(document).on "click change", "input[type=checkbox][data-checked-toggle]:checkbox", ->
  checked_toggle($($(@).data("checked-toggle")), $(@).prop("checked"))

# Allow setting the data-unchecked-toggle attribute to a selector to toggle visibility for
$(document).on "click change", "input[type=checkbox][data-unchecked-toggle]:checkbox", ->
  checked_toggle($($(@).data("unchecked-toggle")), !$(@).prop("checked"))

$(document).on "click", "input[data-toggle][type=checkbox]:checkbox, a[data-toggle], div[data-toggle]", (event) ->
  if event.currentTarget.tagName.toUpperCase() == "A"
    event.preventDefault()

  button = $(event.currentTarget)
  icon = button.find(".icon-up-open, .icon-down-open")
  icon.removeClass("icon-up-open icon-down-open")

  elements = $(button.attr("data-toggle"))

  return if elements.length == 0

  visible = elements.first().is(":visible")

  if visible
    elements.slideUp(250)
    icon.addClass("icon-down-open")
  else
    elements.slideDown(250)
    icon.addClass("icon-up-open")
