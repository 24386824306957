import 'app/interface/menu/menu_bar'
import 'app/interface/menu/base_option'
import 'app/interface/menu/filter'
import 'app/interface/menu/option'
import 'app/interface/menu/search'
import 'app/interface/menu/submit'
import 'app/interface/menu/helpers/sandboxes'
import 'app/interface/menu/sub_menu/base_option'
import 'app/interface/menu/sub_menu/option'
import 'app/interface/menu/sub_menu/child_option'




window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}
window.App.Interface.Menu._models ?= {}

window.App.Interface.Menu.container = document.getElementById("global_page_navbar")

if App.Interface.Menu.container?
  window.App.Interface.Menu.head_element = App.Interface.Menu.container.querySelector(".navbar.head")

# Options which should be moved to the sidebar on mobile devices
window.App.Interface.Menu.MOBILE_SIDEBAR_OPTION_SELECTOR = ".option:not(.desktop_option):not(.circle_option)"

window.App.Interface.Menu.current = ->
  if App.context?
    context = App.context()
    return context.menu() if context?

  element = App.Interface.Menu._visible_context_element()
  return unless element?

  model = App.Interface.Menu.find(element)
  model ?= new App.Interface.Menu.MenuBar(element)
  model

window.App.Interface.Menu._visible_context_element = ->
  elements = App.Interface.Menu.container.querySelectorAll(".navbar.context")

  for element in elements
    return element if App.Helpers.Elements.visible(element)

  undefined

window.App.Interface.Menu.find = (selector) ->
  selector = selector[0] if App.Helpers.Elements.is_jquery(selector)

  if selector instanceof HTMLElement
    selector = selector.dataset.pagePath

  model = App.Interface.Menu._models[selector]
  return model if model?

  if App.context?
    ctx = App.context(selector)
    return unless ctx?

    element = ctx._menu_element
  else
    element = App.Interface.Menu._visible_context_element()

  return unless element?

  model = new App.Interface.Menu.MenuBar(element)

  App.Interface.Menu._models[selector] = model

  model

window.App.Interface.Menu.head = ->
  App.Interface.Menu.container.querySelector(".navbar.head")

window.App.Interface.Menu.show_head = ->
  element = App.Interface.Menu.head_element

  return false unless element? && element.hasChildNodes()
  return true if App.Helpers.Elements.visible(element)

  element.classList.remove("hidden")
  element.style.display = "block"

  visible_options_exist = false
  for option in element.children
    visible_options_exist = true if App.Helpers.Elements.visible(option)

  element.classList.add("hidden")
  element.style.display = "none"

  return false unless visible_options_exist

  App.Helpers.Elements.slide_down(element)

  true

window.App.Interface.Menu.hide_head = ->
  element = App.Interface.Menu.head_element

  return false unless element?
  return true unless App.Helpers.Elements.visible(element)
  return false if App.is_mobile()

  App.Helpers.Elements.slide_up(element)

window.App.Interface.Menu.synchronize_head = ->
  return App.Interface.Menu.show_head() if App.is_mobile()

  head_element = App.Interface.Menu.head_element
  return unless head_element?

  head_options_visible = head_element.querySelector(".option:not(.root_option):not(.mobile_visible)")?

  if head_options_visible && !App.is_fullscreen()
    App.Interface.Menu.show_head()
  else
    head_element.style.display = "none"

window.App.Interface.Menu.animation_time = 250

window.App.Menu = window.App.Interface.Menu
window.App.menu = window.App.Interface.Menu.current

$(window).on "resize", ->
  clearTimeout(App.Interface.Menu._head_timeout)
  App.Interface.Menu._head_timeout = setTimeout(App.Interface.Menu.synchronize_head, 50)

$ App.Interface.Menu.synchronize_head

App.Interface.Menu.synchronize_head()
