
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}
window.App.Frame.Sandbox.Bind ?= {}

window.App.Frame.Sandbox.Bind.procedures = (channel, options) ->
  if options.read == true
    channel.on "schema.load.procedures", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.procedures.schema()
      .then (data, response) -> event.resolve(response)
      .catch event.reject

  if options.read == true
    channel.on "schema.load.procedure", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.procedures.schema()
      .then ->
        procedure = form.procedures.find_by_permalink(event.data.procedure)

        procedure.schema()
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject

  if options.destroy == true
    channel.on "procedure.destroy", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.procedures.schema()
      .then ->
        procedure = form.procedures.find_by_permalink(event.data.procedure)

        return event.reject("Procedure not found") unless procedure?

        procedure.destroy()
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject

  if options.run == true
    channel.on "procedure.run", (event) =>
      form = App.Models.Form.new(event.data.form_permalink)

      form.procedures.run(event.data.endpoint, event.data.parameters)
      .then event.resolve
      .catch event.reject
