
window.App ?= {}
window.App.Schema ?= {}
window.App.Schema.Form ?= {}

class window.App.Schema.Form.FieldManager
  include @, App.Schema.SourceBase
  include @, App.Schema.SourceCollectionBase

  constructor: (@form_permalink) ->
    @callbacks = new CallbackManager(@)
    undefined

  model_instance: (data) -> new App.Schema.Form.Field(@form_permalink, data)
  model_class: -> App.Schema.Form.Field

  _find_by_parameter: (parameter, value, options) ->
    if @form().deleted_fields == @
      if !@loaded(options, allow_empty: true)
        throw new Error("method requires form.deleted_fields.load() to be called and resolved")

      for field in @models(options)
        return field if field.matches(parameter, value)

      return

    for field in @models(options)
      return field if field.matches(parameter, value)

    # deleted: true checks deleted fields additionally, not exclusively
    if options? && options.deleted == true && @form().deleted_fields != @
      return @form().deleted_fields._find_by_parameter(parameter, value, options) 

    undefined

  _value_to_model: (value, options) ->
    if typeof value == "string"
      return @find_by_permalink(value, options) || @find_by_name(value, options) || @find_by_variable_name(value, options)
    else if typeof value == "object"
      return @find_by_permalink(value.permalink, options) || @find_by_name(value.name, options) || @find_by_variable_name(value.variable_name, options)

    undefined

  find_by_index: (value, options) -> @models(options)[value]
  find_by_permalink: (value, options) -> @_find_by_parameter("permalink", value, options)
  find_by_column_name: (value, options) -> @_find_by_parameter("column_name", value, options)
  find_by_variable_name: (value, options) -> @_find_by_parameter("variable_name", value, options)
  find_by_name: (value, options) -> @_find_by_parameter("name", value, options)

  query_by_variable_name: (value) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        model = @find_by_variable_name(value)
        if model? then resolve(model) else reject(model)

  query_by_permalink: (value) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        model = @find_by_permalink(value)
        if model? then resolve(model) else reject(model)

  query_by_name: (value) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        model = @find_by_name(value)
        if model? then resolve(model) else reject(model)

  where: (values, options) ->
    fields = @models(options)

    for key, value of values
      return fields if fields.length == 0

      new_fields = []

      for field in fields
        new_fields.push(field) if field.matches(key, value)

      fields = new_fields

    fields

  schema: (options) ->
    new Promise (resolve, reject) =>
      @form().schema(options)
      .then => resolve(@)
      .catch => reject(@)

  require_schema: (options) ->
    return if @loaded(options)
    throw new Error("method requires fields.load() to be called and resolved")

  create: (data, options) ->
    new Promise (resolve, reject) =>
      @load().then =>
        @_route_create({
          api: {
            url: @form().data.urls.new_field,
            data: {
              field: data
            }
          },
          options: options
        }).then (model) =>
          return if App.is_child

          @callbacks.trigger("create", @)

          App.Schema.change(model)

          resolve(model)
        .catch reject

  form: -> App.Models.Form.new(@form_permalink)

  queryable: ->
    @require_schema()

    output = []

    for field in @models()
      field_type = field.field_type()
      output.push(field) if field_type != "message" && field_type != "button"

    output
