import 'app/events'
import 'app/before'
import 'app/classes'
import 'app/models/base'
import 'app/objects'
import 'app/helpers'
import 'app/frame/base'
import 'modules/helpers'
import 'app/errors'
import 'app/models/user'
import 'app/models/group/group'
import 'app/models/group/users'
import 'app/interface/modal'
import 'modules/integration_manager'
import 'modules/integration_authorization'
import 'app/interface/sidebar'
import 'app/interface/menu'
import 'app/interface/command/palette'
import 'app/interface/context_menu'
import 'app/interface/user_drawer'
import 'app/elements/attachment_post/post'
import 'app/elements/custom_view'
import 'app/elements/table_manager'
import 'app/elements/response_calendar/calendar'
import 'app/elements/response_comment/comment'
import 'app/elements/response_kanban/base'
import 'app/elements/response_mail_merge/mail_merge'
import 'app/elements/response_query/base'
import 'app/elements/response_table/base'
import 'app/elements/response_share'
import 'app/elements/response_viewer'
import 'app/elements/attachment_table/base'
import 'app/elements/upload'
import 'app/elements/user_viewer'
import 'modules/resource'
import 'app/tutorial/lesson'
import 'app/tutorial/lessons/introduction'
import 'app/tutorial/lessons/form_editor'
import 'modules/response/approval_manager'
import 'app/models/form'
import 'modules/plugin_manager'
import 'modules/editors/code_editor'
import 'events/all'
import 'app/window_context'
import 'app/models/base_after'

if !document.addEventListener
  window.location = "http://www.sonadier.com/upgrade"

window.side_link = (page, section) ->
  previous_main_element = document.querySelector(".main_inner:not(.hidden)")
  previous_main = if previous_main_element? then previous_main_element.dataset.mainPage else undefined
  new_main = undefined

  sidebar = App.sidebar()
  return unless sidebar?

  for inner_sidebar in sidebar.element.querySelectorAll(".sidebar_inner")
    inner_sidebar.style.display = "none"

  page_element = sidebar.element.querySelector(".sidebar_inner[data-page='#{page}']")
  page_element.style.display = "block"

  all_side_links = document.querySelectorAll(".side_link")
  matching_side_links = []

  for link in all_side_links
    if link.dataset.page == page && (!section? || link.dataset.section == section)
      link.classList.add("selected")
      matching_side_links.push(link)

      new_main ?= link.dataset.main if link.dataset.main?
    else
      link.classList.remove("selected")

  if section?
    section = section.toString()

    for sidebar_section in page_element.querySelectorAll(".side_section")
      if sidebar_section.dataset.index == section
        sidebar_section.style.display = "block"
      else
        sidebar_section.style.display = "none"

    sidebar.element.scrollTop = 0

  if !new_main? || new_main == previous_main
    sidebar.show(mobile_menu: false)
    return true
  else if App.is_mobile()
    sidebar.hide()
    return false

window.main_link = (main) ->
  for inner_main in document.querySelectorAll(".main_inner")
    if inner_main.dataset.mainPage == main
      inner_main.classList.remove("hidden")
    else
      inner_main.classList.add("hidden")

  undefined

$ ->
  App.Models.User.initialize_current_user()

  for table in $(".table_container:not(.initialized):not(.manual_initialization)")
    App.tables.add(table)

  $(".MESSAGE_FIELD").detect_links()
  $(".dropit").dropit()
  $(".linkify").detect_links()
  $(".interface-field select").select2({
    theme: "interface",
    minimumResultsForSearch: 20
  })

  # Focuses the main document panel on load.
  # This keeps the arrow keys usable for scrolling.
  document_main = document.querySelector(".document-main")

  if document_main?
    document_main.setAttribute("tabindex", 0)
    document_main.focus() unless App.is_iframe

  if window.top != window.self
    document.body.classList.add("embedded")

  window.Integrations = new IntegrationManager()

  for modal in document.querySelectorAll(".modal-container:not(.initialized)")
    App.Interface.Modal.from_html(modal)

  # If the window manager script is loaded, allow the onready method in that file
  # to take over scrollbar generation after the SPA-enabled check is ready.
  App._generate_scrollbars() if !App.WindowManager?

$(window).on "load resize orientationchange", ->
  for element in document.querySelectorAll(".multiple_container")
    App.Helpers.Elements.Multiples.reflow(element)

  App.Helpers.Elements.PageRows.reflow()

  for select in document.querySelectorAll(".ui-selectmenu-open")
    select.classList.remove("ui-selectmenu-open")

  for element in document.querySelectorAll(".expanding_textarea")
    App.Helpers.Inputs.resize_textarea(element)

  # Some mobile browsers, like Chrome, consider the address bar as part of vh.
  # This hides part of the global-page, which is height: 100vh.
  view_height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  container = document.querySelector(".global-page")
  return unless container?

  if view_height != container.offsetHeight
    container.style.height = "#{view_height}px"
  else
    container.style.height = ""

  clearTimeout(App.Helpers.Elements.resize_debounce)

  App.Helpers.Elements.resize_debounce = setTimeout((->
    if App.windowing_enabled() && App.context?
      App.context().generate_scrollbars()
    else if App._scroller?
      App._scroller.rebuild()
  ), 50)

  null

$(document).on "change", ".current_facility_select", (event) ->
  event.preventDefault()

  value = App.Helpers.Selects.value(event.currentTarget)

  if value.length > 0
    facility_url = App.Helpers.URIs.parse(Routes.forms_path())
    facility_url.subdomain(value)

    App.redirect_to facility_url.value(), scaffold: false
  else
    new_facility_url = App.Helpers.URIs.parse(Routes.new_facility_path())

    if window.location.host.includes("staging.sonadier")
      new_facility_url.subdomain("staging")
    else
      new_facility_url.subdomain("")

    App.redirect_to new_facility_url.value(), scaffold: false

$(document).on "click", "thead .row_select_all_td", (event) ->
  cell = event.currentTarget
  checkbox = cell.querySelector(".row_select_all")
  return unless checkbox?

  checkbox.checked = !checkbox.checked
  checkbox.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))

$(document).on "change", ".interface-field input[type=file]", (event) ->
  input = event.currentTarget

  return unless input.files?

  field = App.Helpers.Elements.closest(input, ".interface-field")
  label = field.querySelector(".interface-uploads")

  return unless label?

  if input.files.length == 0
    label.textContent = "Upload a file"
  else
    file_names = []

    for file in input.files
      file_names.push(file.name)

    label.textContent = file_names.join(", ")

  undefined

$(document).on "change", ".update_submission_value", (event) ->
  input = event.currentTarget

  form_permalink = input.dataset.formPermalink
  submission_permalink = input.dataset.permalink
  variable_name = input.dataset.field

  if input.type == "checkbox"
    value = input.checked
  else
    value = input.value

  App.Models.Form.new(form_permalink).submissions.update_field(
    submission_permalink, variable_name, value
  )

$(document).on "click", ".toggled_description_list .list_item_toggle", (event) ->
  event.preventDefault()

  button = event.currentTarget
  list_item = App.Helpers.Elements.closest(button, "li")
  list_item.classList.toggle("description_visible")

$(document).on "click", ".help_title_icon", (event) ->
  button = event.target
  button_tag = button.tagName.toUpperCase()

  return if button_tag == "A" && button.getAttribute("href") != "#"

  event.preventDefault()

  App.Interface.Modal.new("Info", button.title, undefined, size: "small")

$(document).on "page:open", (event, model) ->
  return unless App.window_manager? && App.window_manager.referrer?
  responses = "(?:" + Routes.submissions_path(")([A-Z0-9]+)(?:").replace(/\//g, "\\/") + ")"
  return unless App.window_manager.referrer.match(responses)

  referring_form = App.window_manager.referrer.match(responses)[1]
  user_button = $(model._page_element).find(".user_table_button[data-form-permalink='#{referring_form}']")

  user_button.trigger("click") if user_button.length > 0

$(document).on "page:open", (event, model) ->
  page = model._page_element
  return unless page?

  for element in page.querySelectorAll(".major-page-row")
    element.style.width = page.scrollWidth + "px"

# Forms with the ajax_form class will be submitted over AJAX.
# If the response has a redirect_url parameter, the top-level page will be redirected to the URL it contains.
$(document).on "submit", ".ajax_form", (event) ->
  event.preventDefault()
  App.Helpers.Forms.ajax_submit(@)

$(document).on "click", ".ajax_modal", (event) ->
  event.preventDefault()

  element = event.currentTarget
  return if element.disabled == "disabled"

  # data-modal-href overrides the normal href attribute to allow the link to be opened in a new
  # tab with a different URL. For example, submissions can be opened with the modal link when
  # clicked directly, or the view link when opened in a new tab.
  link = element.dataset.modalHref || element.getAttribute("href")

  App.Interface.Modal._ajax_modal_cache ||= {}

  if App.Interface.Modal._ajax_modal_cache[link]?
    App.Interface.Modal._ajax_modal_cache[link].show()
  else
    return if element.classList.contains("loading")
    add_loader element

    App.Interface.Modal.from_url(link).show()
    .then (modal) ->
      remove_loader(element)
      App.Interface.Modal._ajax_modal_cache[link] = modal
    .catch (error) ->
      remove_loader(element)
      throw error

$(document).on "change", ".upload_form input[type=file]", (event) ->
  event.preventDefault()

  input = event.currentTarget
  form = App.Helpers.Elements.closest(input, ".upload_form")

  App.Helpers.Forms.ajax_submit(form)

# Close data-close attribute target on click
$(document).on "click", "[data-close]", (event) ->
  button = event.currentTarget

  if !button.dataset.close? || button.dataset.close == ""
    element = button.parentNode
  else
    element = document.querySelectorAll(button.dataset.close)

  $(element).slideUp App.Interface.Menu.animation_time, =>
    $(window).trigger "resize"
    $(document).trigger "resize"

# Open the sidebar page that matches the selectors below
$(document).on "click", ".side_link", (event) ->
  event.preventDefault()
  event.stopPropagation()
  button = event.currentTarget

  side_link button.dataset.page, button.dataset.section

  undefined

$(document).on "click", ".main_link", (event) ->
  event.preventDefault()
  event.stopPropagation()
  button = event.currentTarget

  main_link button.dataset.main

  undefined

$(document).on "click", ".patch_checkbox", (event) ->
  checkbox = event.currentTarget
  checkbox.disabled = "disabled"

  if checkbox.checked
    checkbox_value = checkbox.value
  else
    checkbox_value = undefined

  $.ajax
    url: checkbox.dataset.url
    method: "PATCH"
    data: {
      authenticity_token: current_user.authenticity_token(),
      status: checkbox.checked,
      value: checkbox_value
    }
  .done (response) -> checkbox.removeAttribute("disabled")
  .fail (response) -> checkbox.removeAttribute("disabled")

# Select row of a selectable table by clicking it
$(document).on "click", ".select_table tr", (event) ->
  row = event.currentTarget
  row_select = row.querySelector(".row_select")
  return unless row_select?
  row_select.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))

$(document).on "click", ".hide_invitation_notice", ->
  $.ajax
    url: Routes.read_invitation_path()
    type: "POST"
    data: { authenticity_token: current_user.authenticity_token() }

window.select_parent_checkbox_event = (element_or_event) ->
  if App.Helpers.Objects.isEvent(element_or_event)
    checkbox = element_or_event.currentTarget
  else if App.Helpers.Elements.is_jquery(element_or_event)
    checkbox = element_or_event[0]
  else
    checkbox = element_or_event

  child_selector = checkbox.dataset.forceCheckChildren

  return unless child_selector? && child_selector.length > 0

  children = document.querySelectorAll(child_selector)

  for input in children
    if input.tagName == "INPUT"
      input.classList.toggle("force_checked", checkbox.checked)

  undefined

$(document).on "change", "input[type='checkbox'][data-force-check-children]", select_parent_checkbox_event
$(document).on "input", "input[type='checkbox'][data-force-check-children]", select_parent_checkbox_event
$(document).on "click", "input[type='checkbox'][data-force-check-children]", select_parent_checkbox_event
