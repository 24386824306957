
class window.Integration
  constructor: (@name, @readable_name) ->
    @requested_configuration = false
    @authorization_denied = false
    @configuration_data = {}

  # [Internal] Join a provided path with a hash of parameters.
  format_path: (path, parameters = {}) ->
    if path.charAt(0) != "/"
      path = "/#{path}"

    unless App.Helpers.Objects.isEmptyObject(parameters)
      path += "?" unless path.indexOf("?") > -1
      path += "&" unless path.slice(-1) == "&"
      path += $.param(parameters)

    path

  # [Internal] Join a provided path with the base URL of the integration intelligently.
  format_url: (path, parameters = {}, oauth_parameters = {}) ->
    deferred = $.Deferred()
    oauth_parameters.refreshed = oauth_parameters.refresh
    oauth_parameters.refresh = false

    @request_config(oauth_parameters).done (config) =>
      deferred.resolve(config["base_url"] + @format_path(path, parameters))

    deferred

  # [Internal] Returns the API request configuration properties, requesting them from the server if
  # they haven't already been requested.
  default_request_parameters: (oauth_parameters = {}) ->
    deferred = $.Deferred()

    @request_config(oauth_parameters)
    .done (config) ->
      deferred.resolve({
        crossDomain: true,
        contentType: "application/json",
        dataType: "json",
        headers: {
          "Authorization": "Bearer #{config['access_token']}"
        }
      })
    .fail (response) ->
      deferred.reject(response)

    deferred

  # [Internal] Request that the user set up an account for this Integration.
  user_authorization: (url, required = false) ->
    return @deferred_authorization if @deferred_authorization?
    @deferred_authorization = $.Deferred()

    if required
      body = i18n.t("integration_requires_access", name: @readable_name)
    else
      body = i18n.t("integration_requests_access", name: @readable_name)

    @account_request_modal = App.Interface.Modal.new(
      i18n.t("integrate_with", name: @readable_name),
      body,
      [
        {
          text: i18n.t("accept"),
          align: "right",
          type: "primary",
          callback: =>
            @request_authorization(url)
            .done (response) => @deferred_authorization.resolve(response)
            .fail (response) => @deferred_authorization.reject(response)
        },
        {
          text: i18n.t("decline"),
          align: "right",
          type: "secondary",
          callback: =>
            window.location = "select" if required
            @deferred_authorization.reject()
        }
      ],
      {
        forced: required
      }
    )

    @deferred_authorization

  request_authorization: (url) ->
    deferred = $.Deferred()
    return deferred.reject() if @authorization_popup?

    @authorization_popup = window.open(url, "Integrate With #{@readable_name}", "menubar=yes,location=no,resizable=yes,scrollbars=yes,status=yes")

    addEventListener "message", (message) =>
      if message.source == @authorization_popup
        response = JSON.parse(message.data)
        if response.status == "success"
          @account_request_modal.hide()
          @authorization_popup.close()
          deferred.resolve()
        else
          deferred.reject()
          App.Errors.response_error()

    return deferred

  # [Internal] Fetch the API request ajax configuration properties from the server, including the
  # access_token.
  request_config: (oauth = {}) ->
    if @deferred_configuration? && !oauth.refresh
      return @deferred_configuration
    else
      @deferred_configuration = $.Deferred()
      oauth["refresh"] ||= false
      oauth["required"] ||= false

      if !oauth["master"]?
        oauth["master"] = true

      $.ajax
        url: Routes.oauth_integration_token_path(@name, current_user.facility_permalink, force: oauth["refresh"], master: oauth["master"])
      .done (response) =>
        @configuration_data = response
        @requested_configuration = true

        @deferred_configuration.resolve(@configuration_data)
      .fail (response) =>
        refreshing = (oauth.refreshed || oauth.refresh)
        if response.responseJSON["error"] == "no_access_token" && !refreshing
          @request_config($.extend(oauth, { refresh: true }))
        else if response.responseJSON["error"] == "no_integration_grant" && @authorization_denied
          @deferred_configuration.reject(response.responseJSON)
        else if response.responseJSON["error"] == "no_integration_grant" && !@authorization_denied
          @user_authorization(response.responseJSON["signup_url"], oauth.required)
          .done (account) =>
            # A new deferred object will be generated by the refreshed configuration.
            # Save the existing one to resolve it when the refreshed configuration resolves.
            deferred = @deferred_configuration
            @request_config($.extend(oauth, { refresh: true }))
            .done (config) => deferred.resolve(config)
            .fail (config) => deferred.reject(config)
          .fail (failure) =>
            @authorization_denied = true
            @deferred_configuration.reject(response.responseJSON)
        else if response.responseJSON["error"] == "no_integration"
          @deferred_configuration.reject(response.responseJSON)
        else if response.responseJSON["error"] == "no_facility"
          @deferred_configuration.reject(response.responseJSON)
        else if response.responseJSON["error"] == "no_user_facility_connection"
          @deferred_configuration.reject(response.responseJSON)
        else
          @requested_configuration = true
          @deferred_configuration.reject()

    @deferred_configuration

  # [Internal] Main request-handler method. Individual request-type (eg. get()) functions wrap this method.
  request: (method, path, parameters = {}, request_parameters = {}, oauth_parameters = {}) ->
    # Returns a custom deferred object to abstract away the API request ajax, since multiple
    # requests may be made to fetch the access token and options.
    deferred = $.Deferred()

    # Fetch the default request configuration (eg. access_token) from the server, and apply
    # it to the API request.
    @default_request_parameters(oauth_parameters)
    .done (default_parameters) =>
      request_params = $.extend({}, default_parameters, request_parameters)
      request_params.method ||= method
      request_params.data   ||= parameters

      # Add the user-specified path to the base-URL provided by the request configuration.
      @format_url(path, {}, oauth_parameters)
      .done (url) =>
        request_params.url  ||= url

        # Make the initial AJAX request to the integrated API.
        $.ajax request_params
        .done (data, textStatus, jqXHR) =>
          # If the request is successful, resolve the request with it's response.
          deferred.resolve(data, textStatus, jqXHR)
        .fail (jqXHR, textStatus, errorThrown) =>
          # If the request fails, check if it failed because of a permission issue, and if so
          # attempt to fetch a new access_token from the server.
          # If the refresh parameter is true, the method is already attempting to refresh and
          # it's blocked from doing so again to prevent an infinite loop.
          refreshing = (oauth_parameters.refreshed || oauth_parameters.refresh)
          if !refreshing && (jqXHR.status == 401 || jqXHR.status == 0)
            oauth_params = $.extend({}, oauth_parameters, { refresh: true })

            @request(method, path, parameters, request_parameters, oauth_params)
            .done (data, textStatus, jqXHR) => deferred.resolve(data, textStatus, jqXHR)
            .fail (jqXHR, textStatus, errorThrown) => deferred.reject(jqXHR, textStatus, errorThrown)
          else
            # If the request fails, and the conditions above don't apply, reject the request
            # and send the failure information to the calling script.
            deferred.reject(jqXHR, textStatus, errorThrown)
      .fail (response) =>
        deferred.reject(response)
    .fail (response) =>
      deferred.reject(response)

    deferred

  # Check if this integration has been set up by the user
  exists: ->
    deferred = $.Deferred()

    @request_config()
    .done (response) -> deferred.resolve()
    .fail (response) -> deferred.reject()

    deferred

  # Require the user to set up this integration. Spawns a popup and redirects them if they close it.
  require: ->
    @request_config({ required: true })

  master: =>
    get:     (path, params = {}, req_params = {}) => @request("get", path, params, req_params, { master: true })
    post:    (path, params = {}, req_params = {}) => @request("post", path, params, req_params, { master: true })
    patch:   (path, params = {}, req_params = {}) => @request("patch", path, params, req_params, { master: true })
    put:     (path, params = {}, req_params = {}) => @request("put", path, params, req_params, { master: true })
    delete:  (path, params = {}, req_params = {}) => @request("delete", path, params, req_params, { master: true })
    options: (path, params = {}, req_params = {}) => @request("options", path, params, req_params, { master: true })

  user: =>
    get:     (path, params = {}, req_params = {}) => @request("get", path, params, req_params, { master: false })
    post:    (path, params = {}, req_params = {}) => @request("post", path, params, req_params, { master: false })
    patch:   (path, params = {}, req_params = {}) => @request("patch", path, params, req_params, { master: false })
    put:     (path, params = {}, req_params = {}) => @request("put", path, params, req_params, { master: false })
    delete:  (path, params = {}, req_params = {}) => @request("delete", path, params, req_params, { master: false })
    options: (path, params = {}, req_params = {}) => @request("options", path, params, req_params, { master: false })

  get:     (path, params = {}, req_params = {}) -> @request("get", path, params, req_params, { master: true })
  post:    (path, params = {}, req_params = {}) -> @request("post", path, params, req_params, { master: true })
  patch:   (path, params = {}, req_params = {}) -> @request("patch", path, params, req_params, { master: true })
  put:     (path, params = {}, req_params = {}) -> @request("put", path, params, req_params, { master: true })
  delete:  (path, params = {}, req_params = {}) -> @request("delete", path, params, req_params, { master: true })
  options: (path, params = {}, req_params = {}) -> @request("options", path, params, req_params, { master: true })
