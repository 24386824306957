
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Draft ?= {}

class window.App.Models.Submission.Draft.Manager
  constructor: (@form_permalink) ->
    @list_modal = App.Interface.Modal.from_url(
      Routes.submission_drafts_path(@form_permalink)
    )

    @draft_modals = {}

    @callbacks = new CallbackManager(@)

    @on "destroy", (data) =>
      @remove_save_modal(data.permalink)
      @remove_list_draft(data.permalink)

    @on "create", (data) =>
      @remove_list_modal(false)

  on: (event, callback) -> @callbacks.on(event, callback)

  form: -> App.Models.Form.new(@form_permalink)

  show_save_modal: (permalink) ->
    return @draft_modals[permalink].show() if @draft_modals[permalink]?

    new Promise (resolve, reject) =>
      @draft_modals[permalink] = App.Interface.Modal.from_url(
        Routes.edit_submission_draft_path(@form_permalink, permalink, display: "modal")
      )

      @draft_modals[permalink].show().then (modal) =>
        submission = @form().submissions.initialize_element(modal.container)
        submission.element.find(".open_save_draft").attr("data-submission", submission.instance_id)

        @callbacks.trigger("initialize", { permalink: permalink, submission: submission })

        resolve(modal)
      .catch (error) =>
        App.Errors.response_error(xhr)
        reject(error)

  remove_save_modal: (permalink) ->
    return unless @draft_modals[permalink]?
    @draft_modals[permalink].unload()
    @draft_modals[permalink] = undefined

  remove_list_modal: (hide_drafts_link = true) ->
    draft_links = document.querySelectorAll(".open_submission_drafts[data-form-permalink='#{@form_permalink}']")

    for link in draft_links
      option = App.Helpers.Elements.closest(link, ".option")
      option.classList.toggle("hidden", hide_drafts_link) if option?

    @list_modal.unload() if @list_modal.loaded()

  remove_list_draft: (permalink) ->
    return unless @list_modal.loaded()

    element = @list_modal.element.querySelector(".open_submission_draft[data-permalink='#{permalink}']")

    list_item = App.Helpers.Elements.closest(element, "li")
    list = App.Helpers.Elements.closest(list_item, "ul")

    list_item.remove()

    if !list.querySelector("li")?
      @remove_list_modal(true)

  destroy: (permalink) ->
    new Promise (resolve, reject) =>
      $.ajax
        url: Routes.destroy_submission_draft_path(@form_permalink, permalink)
        type: "DELETE"
        data: { authenticity_token: current_user.authenticity_token() }
      .done (response) =>
        @callbacks.trigger("destroy", { permalink: permalink })

        resolve(@)
      .fail (xhr) ->
        App.Errors.response_error(xhr)
        reject(xhr)
