
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Arguments ?= {}

window.App.Helpers.Arguments.overload = ->
  patterns = arguments[arguments.length - 1]
  parameters = Array.prototype.slice.call(arguments, 0, arguments.length - 1)

  # Match longer patterns first
  patterns = App.Helpers.Arrays.stable_sort(patterns, (x, y) -> y.length - x.length)

  for pattern in patterns
    output = {}
    passed = true

    for define, index in pattern
      name = define[0]
      value_class = define[1]

      value = parameters[index]

      if App.Helpers.Objects.matchesType(value, value_class)
        output[name] = parameters[index]
      else
        passed = false

        break

    return output if passed

  output
