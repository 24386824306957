
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseViewer ?= {}
window.App.Elements.ResponseViewer._models ?= {}

window.App.Elements.ResponseViewer.find_by_permalink = (submission_permalink) ->
  App.Elements.ResponseViewer._models[submission_permalink]

window.App.Elements.ResponseViewer.find_by_element = (element) ->
  for permalink, viewer of App.Elements.ResponseViewer._models
    return viewer if viewer._elements.indexOf(element) != -1

  null

window.App.Elements.ResponseViewer.add = (element) ->
  form_permalink = element.dataset.formPermalink
  submission_permalink = element.dataset.submissionPermalink

  model = App.Elements.ResponseViewer.find_by_permalink(submission_permalink)
  model ?= new App.Elements.ResponseViewer.Object(form_permalink, submission_permalink)

  model.add(element)

window.App.Elements.ResponseViewer.generate_shortcut = (options) ->
  element = document.createElement("DIV")
  element.className = "option custom_option"

  anchor = document.createElement("A")
  anchor.href = "#"
  anchor.title = options.title if options.title?

  icon = document.createElement("I")
  icon.className = "icon icon-#{options.icon}"

  element.appendChild(anchor)
  anchor.appendChild(icon)

  if options.listener?
    anchor.addEventListener "click", options.listener

  if options.callback?
    anchor.addEventListener "click", (event) ->
      event.preventDefault()
      options.callback()

  element

class window.App.Elements.ResponseViewer.Object
  constructor: (@form_permalink, @submission_permalink) ->
    window.App.Elements.ResponseViewer._models[@submission_permalink] = @

    @_elements = []

  form: -> App.Models.Form.new(@form_permalink)
  permalink: -> @submission_permalink

  add: (element) ->
    return if @_elements? && @_elements.indexOf(element) != -1

    element = element[0] if App.Helpers.Elements.is_jquery(element)
    element.classList.add("initialized")

    comments_element = element.querySelector(".comment-section")

    if comments_element?
      @comments = new App.Elements.ResponseComment.Object(comments_element, @)

    @_elements ?= []
    @_elements.push(element)

    @format_element(element)

    element

  update: (data) ->
    for element in @_elements
      @update_element(element, data)

    undefined

  update_element: (element, data) ->
    reports_wrapper = element.querySelector(".response_viewer_reports")

    if reports_wrapper?
      reports_wrapper.innerHTML = ""

      for report in data.reports
        element = document.createElement("A")
        element.href = Routes.report_path(@form_permalink, report.permalink)
        element.textContent = report.name
        element.className = "btn btn-light"
        element.dataset.object = "_"
        reports_wrapper.appendChild(element)

    fields = element.querySelectorAll(".submission_field[data-column-name]")

    for field in fields
      column_name = field.dataset.columnName
      wrapper = App.Helpers.Elements.closest(field, ".submission_field_wrapper")

      if wrapper.dataset.fieldType != "button"
        # if data.html is undefined then all fields have been removed and the submission has no data
        if data.html?
          field.innerHTML = data.html[column_name]
          wrapper.classList.toggle("hidden", !data.html[column_name]?)
        else
          field.innerHTML = ""
          wrapper.classList.toggle("hidden", true)

    @update_tabs(element)

    element

  update_tabs: (element) ->
    tabs_container = element.querySelector(".submission_page_header")
    return unless tabs_container?

    tabs = element.querySelectorAll(".submission_page_tab")
    visible_tab_names = []

    for tab in tabs
      page = element.querySelector(".FORM_PAGE[data-field='#{tab.dataset.field}']")
      visible_fields = element.querySelector(".submission_field_wrapper:not(.hidden)")
      visible_tab_names.push(tab.dataset.field) if visible_fields

      page.classList.toggle("hidden", !visible_fields)
      tab.classList.toggle("hidden", !visible_fields)

    if visible_tab_names.indexOf(@active_form_page_name()) == -1
      @open_form_page(visible_tab_names[0])

    tabs_container.classList.toggle("hidden", visible_tab_names.length == 0)

    undefined

  update_subscription: (status) ->
    for element in @_elements
      menu = App.context(element).menu_element()
      button = menu.querySelector(".follow_resource_link[data-resource=submission], .unfollow_resource_link[data-resource=submission]")
      continue unless button?

    status

  format_element: (element) ->
    for value_element in element.querySelectorAll(".row-value")
      App.Helpers.Elements.linkify(value_element, { form: @form_permalink })

    active_page = @active_form_page_name()
    @open_form_page(active_page) if active_page?

    @generate_element_shortcuts(element)

    element

  open_form_page: (field, tab_header, tabs) ->
    tab_header ?= @_elements[0].querySelector(".submission_page_header")
    tabs ?= tab_header.querySelectorAll(".submission_page_tab")

    if Number.isInteger(field)
      # If the number of tabs is 10, and the field is -100, using tabs.length + field once
      # will not solve the problem. The recursion below continues calculating until a valid
      # index is found.
      if field < 0
        return @open_form_page(tabs.length + field, tab_header, tabs)
      else if field >= tabs.length
        return @open_form_page(field - tabs.length, tab_header, tabs)

      active_tab = tabs[field]
      field = active_tab.dataset.field

    for element in @_elements
      element_tabs = element.querySelectorAll(".submission_page_tab")
      element_pages = element.querySelectorAll(".FORM_PAGE")

      for tab in element_tabs
        tab.classList.toggle("selected", tab.dataset.field == field)

      for page in element_pages
        page.classList.toggle("ACTIVE_PAGE", page.dataset.field == field)

    undefined

  active_form_page_index: ->
    active_tab = @_elements[0].querySelector(".submission_page_tab.selected")
    return unless active_tab?

    Array.prototype.indexOf.call(active_tab.parentNode.children, active_tab)

  active_form_page_name: ->
    active_tab = @_elements[0].querySelector(".submission_page_tab.selected")
    return unless active_tab?

    active_tab.dataset.field

  destroy: ->
    for element in @_elements
      if App.context() == App.context(element)
        App.redirect_to Routes.form_path(@form().permalink())
        return undefined

    undefined

  restore: ->
    undefined

  menu: (object) ->
    if Array.isArray(object)
      @menu(option) for option in object
      return

    @_custom_menu_options ?= []
    @_custom_menu_options.push(object)

    if !@_custom_menu_shortcut
      @_menu_shortcut_exists = true

      @shortcut({
        icon: "cog",
        last: true,
        listener: (event) =>
          event.preventDefault()

          shortcut = event.currentTarget
          coords = shortcut.getBoundingClientRect()

          menu = new App.Interface.ContextMenu.Object(@_custom_menu_options)
          menu.element = shortcut

          menu.show(position: { x: coords.left + shortcut.offsetWidth, y: coords.bottom })
      })

    undefined

  shortcut: (object) ->
    if Array.isArray(object)
      @shortcut(option) for option in object
      return

    if @_custom_shortcuts?
      if object.id?
        for shortcut in @_custom_shortcuts
          return if shortcut.id == object.id

      last_shortcut = @_custom_shortcuts[@_custom_shortcuts.length - 1]

      if last_shortcut? && last_shortcut.last
        prepend_shortcuts = []
        append_shortcuts = []

        for shortcut in @_custom_shortcuts
          if shortcut.last
            append_shortcuts.push(shortcut)
          else
            prepend_shortcuts.push(shortcut)

        all_shortcuts = prepend_shortcuts
        all_shortcuts.push(object)
        all_shortcuts = all_shortcuts.concat(append_shortcuts)

        @_custom_shortcuts = all_shortcuts
      else
        @_custom_shortcuts.push(object)
    else
      @_custom_shortcuts = [object]

    @generate_shortcuts()

    undefined

  generate_shortcuts: ->
    return unless @_custom_shortcuts?

    for element in @_elements
      @generate_element_shortcuts(element)

    undefined

  generate_element_shortcuts: (element) ->
    return unless @_custom_shortcuts?

    container = element.querySelector(".response_viewer_shortcuts")

    if container?
      options = Array.from(container.querySelectorAll(".custom_option"))
      element.remove() for element in options
    else
      container = document.createElement("DIV")
      container.className = "table_options response_viewer_shortcuts"

      title_element = element.querySelector(".page_name")
      title_element.insertBefore(container, title_element.firstChild)

    first_element = container.firstElementChild

    for shortcut in @_custom_shortcuts
      shortcut_element = App.Elements.ResponseViewer.generate_shortcut(shortcut)
      continue unless shortcut_element?

      container.insertBefore(shortcut_element, first_element)

    undefined

App.Models.bind_function ".view_submission", (element) ->
  return if element.classList.contains("initialized")
  App.Elements.ResponseViewer.add(element)
