
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.Configuration = {
  load_configuration: ->
    element = @element.querySelector("script[type='text/view-configuration']")

    if element?
      @configuration = JSON.parse(element.textContent)
    else
      @configuration = {}

  config: (values...) ->
    return unless @configuration?
    output = @configuration

    for name in values
      return unless output?
      output = output[name]

    output

  config_or_true: (values...) ->
    output = @configuration.apply(@, values)
    return true unless output?
    output

  config_or_false: (values...) ->
    output = @configuration.apply(@, values)
    return false unless output?
    output
}
