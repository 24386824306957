
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}

window.App.Interface.Menu.parse_filter_data = (data) ->
  options = {}
  options.id = data.id
  options.instance_id = data.instance_id
  options.query = data.query
  options.editor = data.editor
  options.preload = data.preload

  if data.form?
    if data.form instanceof App.Models.Form
      options.form = data.form.permalink()
    else
      options.form = data.form

  options

class window.App.Interface.Menu.Filter extends App.Interface.Menu.BaseOption
  constructor: (@menu, options = {}) ->
    options = App.Interface.Menu.parse_filter_data(options)

    super(@menu, options)

    if options.editor?
      @query_editor(options.editor)

    @menu.sandboxes.publish("filter.create", @serialize())
    @menu.callbacks.trigger("filter.add", @)

    @_queries = []

  _generate: (options) ->
    cleanup_fragments = @id?
    @element.remove() if cleanup_fragments

    @id = options.id || @id || App.Helpers.Generators.uuid()
    @instance_id ?= options.instance_id || @id
    @_form_permalink ?= options.form

    @callback(options.callback) if options.callback?
    @preload(options.preload) if options.preload?

    options.text ?= i18n.t("filter")

    text_element = document.createElement("SPAN")
    text_element.textContent = options.text

    @button = document.createElement("A")
    @button.innerHTML = "<i class='icon icon-filter'></i>"
    @button.appendChild(text_element)
    @button.href = "#"

    @element = document.createElement("DIV")
    @element.className = "option filter_option custom_option"
    @element.dataset.instance = @instance_id
    @element.id = @id
    @element.appendChild(@button)

    @menu.element.appendChild(@element)

    @recount()

  _generate_sandbox: ->
    super()

    @callback (query) =>
      @_publish("callback", query.serialize(instance: true))

  form: ->
    App.Models.Form.new(@_form_permalink)

  query: (query) ->
    return unless query?

    @_queries.push(query)

    @recount()

    query

  query_editor: (existing_editor) ->
    if !@_query_editor?
      if existing_editor?
        @_query_editor = existing_editor
      else
        @_query_editor = new App.Elements.ResponseQuery.Query(@form())

      @_query_editor.on "change", =>
        for query in @_queries
          query.load(@_query_editor.query)

        @recount()

        @trigger_callbacks(@_query_editor.query)

      @_query_editor.on "recount", => @recount()

    @_query_editor

  update: (options) ->
    @_generate(options)
    @_publish "update", @serialize()

  destroy: ->
    @element.remove()

    @menu.callbacks.trigger("option.remove", @id)
    @_publish("remove")

    delete @menu._custom_options[@id]
    delete @menu._filter_option

    undefined

  text: -> i18n.t("filter")

  recount: ->
    count = 0

    if @_queries? && @_queries.length > 0
      count += @_queries[0].conditions_count()

    @count(count)

  serialize: ->
    output = {}
    output.instance_id = @instance_id
    output.form = @_form_permalink
    output.id = @id
    output
