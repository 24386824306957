
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.CustomView ?= {}

class window.App.Frame.CustomView.Object
  constructor: (@element) ->
    App.load_initial_context()

    @element = @element[0] if App.Helpers.Elements.is_jquery(@element)
    @element.classList.add("initialized")

    @context = App.context(@element)
    @context ?= App.context()

    @sandbox = App.Frame.Sandbox.new(@element)
    @sandbox.load_menu(@context.menu())
    @sandbox.load_current_user(App.current_user)
    @sandbox.ready()

    if @element.classList.contains("custom_view_partial")
      @sandbox.channel.publish("dimensions.subscribe").done (data) =>
        @element.style.width = data.width + "px"
        @element.style.height = data.height + "px"

      @sandbox.channel.on "dimensions.change", (event) =>
        @element.style.width = event.data.width + "px"
        @element.style.height = event.data.height + "px"

App.Models.bind_function "iframe.custom_view", (element) ->
  return if element.classList.contains("initialized") 
  element.onload = -> new App.Frame.CustomView.Object(element)

PubSub.subscribe "submission.loaded", (t, data) ->
  for element in data.submission._element.querySelectorAll("iframe.custom_view:not(.initialized)")
    element.onload = -> new App.Frame.CustomView.Object(element)

  undefined
