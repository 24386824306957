
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Draft ?= {}

class window.App.Models.Submission.Draft.Object
  constructor: (@submission) ->
    @draft_permalink = @submission._element.dataset.draft

    @modal = App.Interface.Modal.from_url(@modal_path())

    @modal.on "load", =>
      @modal.element.querySelector("form").addEventListener "submit", (event) =>
        event.preventDefault()
        @create(event)

    @submission.sandboxes.subscribe "draft.prompt", => @prompt()
    @submission.sandboxes.subscribe "draft.create", (event) => @create(event.data.name)
    @submission.sandboxes.subscribe "draft.enable", => @enable()
    @submission.sandboxes.subscribe "draft.disable", => @disable()

  form: -> @submission.form()

  permalink: -> @draft_permalink
  persisted: -> @draft_permalink? && @draft_permalink.length > 0

  save_path: ->
    if @persisted()
      Routes.update_submission_draft_path(@submission.form().permalink(), @permalink())
    else
      Routes.create_submission_draft_path(@submission.form().permalink())

  modal_path: ->
    Routes.new_submission_draft_path(@submission.form().permalink(), instance_id: @submission.instance_id)

  prompt: (event) ->
    event.preventDefault() if event?

    @modal.show(event).then (modal) =>
      modal.element.querySelector(".draft_name").select()

  enable: (event) ->
    event.preventDefault() if event?

    @submission.element.find(".open_save_draft").show()

  disable: (event) ->
    event.preventDefault() if event?

    @submission.element.find(".open_save_draft").hide()

  create: (name_or_event) ->
    name = undefined
    event = undefined

    if name_or_event?
      if name_or_event.target?
        event = name_or_event
      else
        name = name_or_event

    event.preventDefault() if event?

    data = {}
    data.submission = @submission.data()
    data.instance_id = @submission.instance_id
    data.authenticity_token = current_user.authenticity_token()

    data.draft_name = name if name?
    data.draft_name ?= @modal.element.querySelector(".draft_name").value if @modal.loaded()

    new Promise (resolve, reject) =>
      $.ajax
        url: @save_path()
        type: "POST"
        data: data
        context: @
      .done (response) =>
        @draft_permalink = response.draft_permalink

        @form().submissions.drafts.callbacks.trigger "create", { permalink: @draft_permalink }
        @submission.sandboxes.publish "draft.create", { permalink: @draft_permalink }

        resolve(response)
      .fail (response) ->
        reject(response)

  serialize: ->
    output = {}
    output.permalink = @permalink()
    output