import 'app/interface/command/option'


window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Command ?= {}

class window.App.Interface.Command.Palette
  constructor: (@container) ->
    @input = @container.find(".command_palette_input")
    @suggestion_element = @container.find(".command_palette_options")

    @cursor = {}
    @_option_models = []

    @suggestion_element.hide() unless @input.is(":focus")

    @reorder()

    @input.on "input", (e) => @keydown(e)
    @input.on "focus", (e) => @focus(e)
    @input.on "blur", (e) => @_calculate_cursors(e)

    @input.on "keydown", (e) => @_navigate_suggestions(e.which, e)

    @suggestion_element.on "click", "a", (event) => @hide()

    Hotkey.bind ["CTRL", "SHIFT", "P"], do: (=> @show())
    Hotkey.bind ["CTRL", "ALT", "P"], do: (=> @show())
    Hotkey.bind ["CTRL", "K"], do: (=> @show())
    Hotkey.bind ["ESC"], do: (=> @hide()), if: (=> @visible())

    @container.on "click", (event) =>
      return unless event.target == event.currentTarget
      event.preventDefault()
      @hide()

  reorder: ->
    options = @options().detach()

    options.sort (x, y) ->
      return 1 if x.textContent > y.textContent
      return -1 if x.textContent < y.textContent
      return 0

    @suggestion_element.append(options)

    @_refresh_focus()

  show: ->
    @load_basic_options() unless @_basic_options_requested
    @container.addClass("visible")
    @input.focus()
    @input.select()

  hide: ->
    @container.removeClass("visible")

  visible: ->
    @container.hasClass("visible")

  load_basic_options: ->
    return if @_basic_options_requested
    @_basic_options_requested = true

    $.ajax
      url: Routes.command_palette_path()
      type: "POST"
      data: { authenticity_token: current_user.authenticity_token() }
    .done (response) =>
      @suggestion_element.prepend $(response)
      @reorder()
      @search @input.val()
    .fail (xhr) ->
      App.Errors.response_error(xhr)

  load_dynamic_options: (value) ->
    $.ajax
      url: Routes.fulltext_search_path()
      method: "POST"
      data: {
        authenticity_token: current_user.authenticity_token()
        value: value
      }
    .done (response) =>
      return unless response.value == @_current_value

      @destroy_dynamic_options()

      for submission in response.data
        name = "#{submission.form_name} › #{submission.value}"

        submission_option = @option name, dynamic: true, ((form_permalink, submission_permalink) ->
          -> App.redirect_to Routes.submission_path(form_permalink, submission_permalink)
        )(submission.form_permalink, submission.permalink)

        @show_option(submission_option)
    .fail (xhr) ->
      App.Errors.response_error(xhr)

  option: (text, arg1, arg2) ->
    new App.Interface.Command.Option(@, text, arg1, arg2)

  options: ->
    @suggestion_element.children("a")

  show_option: (element) ->
    @suggestion_element.removeClass("command_palette_empty")
    $(element).removeClass("hidden")

  hide_option: (element) ->
    $(element).addClass("hidden")

  hide_all_options: ->
    @suggestion_element.addClass("command_palette_empty")
    @options().addClass("hidden")

  destroy_dynamic_options: ->
    @suggestion_element.find(".dynamic_option").remove()

    new_options = []

    for option, index in @_option_models
      if option.options.dynamic
        delete @_option_models[index]
      else
        new_options.push(option)

    @_option_models = new_options

  focus: (event) ->
    @keydown(event)

  keydown: (event) ->
    @suggestion_element.show()

    @_calculate_cursors(event)

    @search(@input.val())

  search: (value) ->
    @_current_value = value

    values = value.toLowerCase().split(" ")

    options = @options()
    @hide_all_options()
    @destroy_dynamic_options()

    for option in options
      # Replacing the unicode arrow with > makes it more intuitive to search
      search_value = option.textContent.toLowerCase().replace("›", ">")
      matches = true

      for segment in values
        matches = false if search_value.indexOf(segment) == -1

      @show_option(option) if matches

    clearTimeout(@_dynamic_option_timeout)
    @_dynamic_option_timeout = setTimeout((=> @load_dynamic_options(value)), 50)

    @_refresh_focus()

    @suggestion_element

  _calculate_cursors: (event) ->
    input_node = @input[0]

    @cursor = {}

    @cursor.position = input_node.selectionStart
    @cursor.start = input_node.selectionStart
    @cursor.end = input_node.selectionEnd
    @cursor.selecting = @cursor.start != @cursor.end

  _select_suggestion: (element) ->
    element[0].click()

  _focus_suggestion: (element) ->
    @suggestion_element.find("a").removeClass("focused")
    @suggestion_element.scrollTop(0)
    @suggestion_element.scrollTop(element.offset().top + element.height() - @suggestion_element.height())
    element.addClass("focused")

  _increment_focus: ->
    focus = @_focused_suggestion()

    if focus?
      next_focus = focus.nextAll("a:not(.hidden):first")
      next_focus = @suggestion_element.find("a").first() if next_focus.length == 0
    else
      next_focus = @suggestion_element.find("a").first()

    return unless next_focus.length > 0

    @_focus_suggestion(next_focus)

  _decrement_focus: ->
    focus = @_focused_suggestion()

    if focus?
      prev_focus = focus.prevAll("a:not(.hidden):first")
      prev_focus = @suggestion_element.find("a").last() if prev_focus.length == 0
    else
      prev_focus = @suggestion_element.find("a").last()

    return unless prev_focus.length > 0

    @_focus_suggestion(prev_focus)

  _reset_focus: ->
    @suggestion_element.find("a").removeClass("focused")
    new_focus = @suggestion_element.find("a:not(.hidden):first")

    return unless new_focus.length > 0

    @_focus_suggestion(new_focus)

  _refresh_focus: ->
    current_focus = @_focused_suggestion()
    @_reset_focus() if !current_focus? || current_focus.hasClass("hidden")

  _focused_suggestion: ->
    focus = @suggestion_element.find("a.focused").first()

    return unless focus.length > 0

    focus

  _navigate_suggestions: (key, event) ->
    @_calculate_cursors(event)

    return if [13, 38, 40].indexOf(key) == -1
    event.preventDefault()

    switch key
      when 13 then @_select_suggestion(@_focused_suggestion())
      when 38 then @_decrement_focus()
      when 40 then @_increment_focus()


$ ->
  window.App.Interface.command_palette = new App.Interface.Command.Palette($("#command_palette_container"))
