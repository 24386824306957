import 'polyfills/arrays'
import 'polyfills/base64'
import 'polyfills/css_escape'
import 'polyfills/custom_events'
import 'polyfills/dataset'
import 'polyfills/elements'
import 'polyfills/events'
import 'polyfills/fetch'
import 'polyfills/is_number'
import 'polyfills/objects'
import 'polyfills/promises'
import 'polyfills/set_immediate'
import 'polyfills/strings'
import 'polyfills/textcontent'
//= require polyfills/arrays
//= require polyfills/base64
//= require polyfills/css_escape
//= require polyfills/custom_events
//= require polyfills/dataset
//= require polyfills/elements
//= require polyfills/events
//= require polyfills/fetch
//= require polyfills/is_number
//= require polyfills/objects
//= require polyfills/promises
//= require polyfills/set_immediate
//= require polyfills/strings
//= require polyfills/textcontent
