import css_escape from 'polyfills/css-escape-new'

window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.Dropdown
  constructor: (@field) ->
    @element = @field._element
    App.Helpers.Elements.Multiples.reflow @element

    # Allows filtering of select menu options by deleting the elements and restoring them from a base list
    @base_options = []
    for option in @options()
      @base_options.push option.textContent.trim()

    if @display() == "multiple_choice"
      @container = @element.querySelector(".multiple_container")
      @bound_select = document.querySelector(@container.dataset.boundSelect)

      $(@bound_select).on "change", => @value_changed()

    if @display() == "dropdown"
      @convert_to_select2()

  multiple_values: ->
    if @display() == "dropdown"
      @element.querySelector("select").multiple?
    else
      App.Helpers.Elements.Multiples.multiple_values(@container)

  display: ->
    if @element.querySelector(".FIELD").classList.contains("MULTIPLE_FIELD")
      "multiple_choice"
    else
      "dropdown"

  options: ->
    if @display() == "multiple_choice"
      @element.querySelectorAll(".multiple_wrapper")
    else
      @element.querySelectorAll("option")

  visible_options: ->
    values = []

    if @display() == "multiple_choice"
      for wrapper in @element.querySelectorAll(".multiple_wrapper")
        continue unless App.Helpers.Elements.visible(wrapper)

        for option in wrapper.querySelectorAll(".multiple")
          values.push option.dataset.value
    else
      for option in @element.querySelectorAll("option")
        values.push option.value

    values

  show_option: (name) ->
    visible_options = @visible_options()

    return if visible_options.indexOf(name) != -1

    visible_options.push(name)

    @show_options(visible_options)

  hide_option: (name) ->
    visible_options = @visible_options()

    return if visible_options.indexOf(name) == -1

    visible_options.splice(visible_options.indexOf(name), 1)

    @show_options(visible_options)

  show_options: (scoped_options) ->
    current_value = @field.value()
    scoped_options ?= @base_options

    switch @display()
      when "multiple_choice"
        new_value = []

        for option in @options()
          option_value = option.querySelector(".multiple").dataset.value

          if options_array.indexOf(option_value) == -1
            option.style.display = "none"
          else
            option.style.display = "block"

            if current_value.indexOf(option_value) != -1
              new_value.push(option_value)

        @field.value(new_value)

        App.Helpers.Elements.Multiples.reflow(@element)
      when "dropdown"
        select_element = @element.querySelector("select")
        select_element.innerHTML = App.Helpers.Selects.options_for_select(
          scoped_options, current_value
        )

  update_other_multiple: (other) ->
    container = App.Helpers.Elements.closest(other, ".multiple_container")
    bound_selector = container.dataset.boundSelect || undefined
    multiple_value = other.parentNode.dataset.multi
    value = other.querySelector("input").value

    if !@multiple_values()
      for multiple in container.querySelectorAll(".multiple")
        multiple.classList.remove("selected")

    other.classList.add("selected")
    other.dataset.value = value

    if @multiple_values()
      if !value.trim()
        other.classList.add("empty_other")

        potential_focuses = container.querySelectorAll(".multiple.other")
        potential_empty_focus = []

        for multiple in potential_focuses
          continue unless multiple.classList.contains("empty_other")
          potential_empty_focus.push multiple

        if potential_empty_focus.length > 1 || potential_focuses.length > 1
          if potential_empty_focus.length > 1
            other.parentNode.remove()
            container.querySelector(".multiple.empty_other input").focus()
          else if potential_focuses.length > 1
            new_other = other.parentNode.previousElementSibling
            App.Helpers.Elements.Multiples.add_other(other)
            other.parentNode.remove()
            new_other.querySelector("input").focus()

          App.Helpers.Elements.Multiples.reflow(container)

      else if other.classList.contains("empty_other")
        other.classList.remove("empty_other")
        App.Helpers.Elements.Multiples.add_other other
    else
      if !value.trim()
        other.classList.add("empty_other")
      else
        other.classList.remove("empty_other")

    if bound_selector?
      bound_select = document.querySelector(bound_selector)

      options = bound_select.querySelectorAll("option[data-multi='#{multiple_value}']")
      option.value = value for option in options

      values = []

      for multiple in container.querySelectorAll(".multiple.selected")
        values.push(multiple.dataset.value)

      App.Helpers.Selects.value_from_array(bound_select, values)

  toggle_multiple: (multiple) ->
    App.Models.Submission.Field.Helpers.Dropdown.toggle_multiple(
      multiple, @bound_select, @field.required()
    )

  @toggle_multiple: (multiple, bound_select, required = false) ->
    multiple_value = multiple.dataset.value

    selected = multiple.classList.contains("selected")
    multiple_values = App.Helpers.Elements.Multiples.multiple_values(multiple)

    # Prevent required multiple choice fields from being deselected if only one is chosen.
    if selected && required && !multiple_values
      return unless bound_select.value? && bound_select.value.length != 0

    bound_select.value = "" unless multiple_values

    escaped_value = css_escape(multiple_value)
    bound_select_option = bound_select.querySelector('option[value="' + escaped_value + '"]')
    bound_select_option.selected = !selected

    bound_select.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))
    bound_select

  value_changed: ->
    values = App.Helpers.Selects.values(@bound_select)

    for multiple in @container.querySelectorAll(".multiple")
      multiple_is_selected = values.indexOf(multiple.dataset.value) != -1
      multiple.classList.toggle("selected", multiple_is_selected)

    undefined

  convert_to_select2: ->
    @element.querySelector(".SELECT_FIELD").classList.add("SELECT2_FIELD")

    for field in @element.querySelectorAll("select")
      if field.multiple
        empty_option = field.querySelector("option:not([value]), option[value='']")
        empty_option.remove() if empty_option?

      $(field).select2()

    undefined
