
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}

window.App.Interface.Menu.parse_option_data = (data) ->
  options = {}
  options.id = data.id
  options.href = data.href
  options.icon = data.icon
  options.text = data.text
  options.align = data.align
  options.callback = data.callback
  options.preload = data.preload
  options

class window.App.Interface.Menu.Option extends App.Interface.Menu.BaseOption
  constructor: (@menu, options = {}) ->
    super(@menu, options)

    @menu.sandboxes.publish("option.create", @serialize())
    @menu.callbacks.trigger("option.add", @)

  _generate: (options) ->
    cleanup_fragments = @id?
    @element.remove() if cleanup_fragments

    @id = options.id || @id || App.Helpers.Generators.uuid()
    @instance_id ?= options.instance_id || @id

    @callback(options.callback) if options.callback?
    @preload(options.preload) if options.preload?

    @button = document.createElement("A")
    @button.textContent = options.text

    if options.href?
      @button.href = options.href
    else
      @button.href = "#"

    if options.icon?
      icon = document.createElement("I")
      icon.className = "icon icon-#{options.icon}"

      @button.insertBefore(icon, @button.firstChild)

    @element = document.createElement("DIV")
    @element.className = "option text_option custom_option"
    @element.classList.add("primary") if options.align == "right"
    @element.dataset.instance = @instance_id
    @element.id = @id
    @element.appendChild(@button)

    @menu.element.appendChild(@element)

  _generate_sandbox: ->
    super()

    @_subscribe "text", (event) => @text(event.value)
    @_subscribe "icon", (event) => @icon(event.value)

    @callback => @_publish("callback")

  update: (options) ->
    @_generate(options)
    @_publish "update", @serialize()

  text: (value) ->
    text_node = App.Helpers.Elements.text_node(@element)

    if value?
      if text_node?
        text_node.textContent = value
      else
        text_node = document.createTextNode(value)
        @element.appendChild(text_node)

      @_publish("text", { value: value })

    return "" unless text_node?

    text_node.textContent

  icon: (value) ->
    icon = @button.querySelector(".icon")

    if value?
      if !icon?
        icon = document.createElement("I")
        @button.insertBefore(icon, @button.firstChild)

      icon.className = "icon icon-#{value}"
      @_publish("icon", { value: value })

    return unless icon?

    icon_name = icon.className.match(/\bicon\-\S*/)
    icon_name = icon_name.join(" ") if Array.isArray(icon_name)
    icon_name

  destroy: ->
    @element.remove()

    @menu.callbacks.trigger("option.remove", @id)
    @_publish("remove")

    delete @menu._custom_options[@id]

    undefined

  serialize: ->
    output = {}
    output.instance_id = @instance_id
    output.id = @id
    output.text = @text()
    output.icon = @icon()
    output
