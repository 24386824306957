
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.Tables ?= {}

window.App.Elements.Tables._models = {}

window.App.Elements.Tables.initialize_element = (element) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  if element.classList.contains("attachment_table")
    new App.Elements.AttachmentTable.Table(element)
  else if element.dataset.permalink?
    new App.Elements.ResponseTable.Table(element)

window.App.Elements.Tables.selector_to_id = (selector) ->
  return unless selector?

  selector = selector[0] if App.Helpers.Elements.is_jquery(selector)
  selector = selector.id if App.Helpers.Objects.isNode(selector)

  return if selector.length == 0

  selector

window.App.Elements.Tables.add = (table) ->
  existing_table = App.Elements.Tables.find(table)
  return existing_table if existing_table?

  table = table[0] if App.Helpers.Elements.is_jquery(table)

  if App.Helpers.Objects.isNode(table)
    table = App.Elements.Tables.initialize_element(table)

  return unless table?

  if !table.element.id? || table.element.id.length == 0
    table.element.id = "table_#{App.Helpers.Generators.uuid()}"

  table_count = Object.keys(App.Elements.Tables._models).length

  if table_count == 0
    App.Elements.Tables._first_model = table
  else if table_count == 1
    App.Elements.Tables._second_model = table
  else if table_count == 2
    App.Elements.Tables._third_model = table

  App.Elements.Tables._models[table.element.id] = table
  App.Elements.Tables._last_model = table

  table

window.App.Elements.Tables.visible = ->
  for table in App.Elements.Tables.all()
    if App.Helpers.Elements.visible_in_vertical_viewport(table.table_element)
      return table

  undefined

window.App.Elements.Tables.find = (selector) ->
  table_id = App.Elements.Tables.selector_to_id(selector)
  return unless table_id?

  App.Elements.Tables._models[table_id]

window.App.Elements.Tables.find_by_element = (element) ->
  table_id = App.Elements.Tables.selector_to_id(element)
  return App.Elements.Tables._models[table_id] if table_id?

  element = element[0] if App.Helpers.Elements.is_jquery(element)

  for table in App.Elements.Tables.all()
    return table if table.element == element

  undefined

window.App.Elements.Tables.find_by_table_element = (element) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  for table in App.Elements.Tables.all()
    return table if table.table_element == element

  undefined

window.App.Elements.Tables.find_by_instance_id = (instance) ->
  for table in App.Elements.Tables.all()
    return table if table.instance_id == instance

  undefined

window.App.Elements.Tables.first = ->
  App.Elements.Tables._first_model

window.App.Elements.Tables.second = ->
  App.Elements.Tables._second_model

window.App.Elements.Tables.third = ->
  App.Elements.Tables._third_model

window.App.Elements.Tables.last = ->
  App.Elements.Tables._last_model

window.App.Elements.Tables.all = ->
  Object.values(App.Elements.Tables._models)

window.App.tables = App.Elements.Tables
