
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Tables ?= {}

window.App.Helpers.Tables.open_cell_overlay = (td, overlay_content) ->
  App.Helpers.Tables.close_cell_overlay()

  if !td? || td.tagName != "TD"
    throw new Error("cell_overlay must be called on a TD node")

  td.classList.add("td_cell_overlay")

  if overlay_content instanceof Function
    overlay_content = overlay_content.call(td)

  overlay = document.createElement("DIV")
  overlay.className = "cell_overlay_body"
  overlay.style.position = "absolute"
  overlay.innerHTML = overlay_content if overlay_content?

  App.Helpers.Tables.position_cell_overlay(td, overlay)

  background = document.createElement("DIV")
  background.className = "cell_overlay_background"

  document.body.appendChild(background)
  document.body.appendChild(overlay)

  overlay

window.App.Helpers.Tables.close_cell_overlay = (td) ->
  cell_overlays = document.querySelectorAll(".cell_overlay_body, .cell_overlay_background")

  for element in cell_overlays
    element.parentNode.removeChild(element)

  undefined

window.App.Helpers.Tables.position_cell_overlay = (td, overlay) ->
  coords = td.getBoundingClientRect()

  overlay.style.top = (coords.bottom - 1) + "px"
  overlay.style.left = (coords.left - 1) + "px"
  overlay.style.width = (td.offsetWidth + 1) + "px"

  overlay

window.App.Helpers.Tables.open_cell_editor = (td, callback) ->
  if !td? || td.tagName != "TD"
    throw new Error("open_cell_editor must be called on a TD node")

  td.classList.add("td_cell_editor")

  value = td.textContent

  input = document.createElement("INPUT")
  input.type = "text"
  input.value = value

  form = document.createElement("FORM")
  form.appendChild(input)

  form.addEventListener "submit", (event) ->
    event.preventDefault()

    App.Helpers.Tables.close_cell_editor(td)

    callback(input.value) if callback?

  input.addEventListener "keyup", (event) ->
    if event.keyCode == 27
      input.value = value

      App.Helpers.Tables.close_cell_editor(td)

  input.addEventListener "blur", (event) ->
    App.Helpers.Tables.close_cell_editor(td)

    callback(input.value) if callback?

  td.innerHTML = ""
  td.appendChild(form)

  # border-left and border-right width included?
  th = App.Helpers.Tables.th_from_td(td)
  th_width = parseInt(th.dataset.columnWidth) - 2

  input.style.width = th_width + "px"
  input.style.minWidth = th_width + "px"

  input.select()

window.App.Helpers.Tables.close_cell_editor = (td) ->
  return if td.classList.contains("td_cell_editor_closing")
  td.classList.add("td_cell_editor_closing")
  td.classList.remove("td_cell_editor")

  input = td.querySelector("input")

  td_content = document.createElement("DIV")
  td_content.className = "td-content"
  td_content.textContent = input.value

  td.innerHTML = ""
  td.appendChild(td_content)

  td.classList.remove("td_cell_editor_closing")

  td

window.App.Helpers.Tables.th_from_td = (td) ->
  index = App.Helpers.Elements.index(td)
  table = App.Helpers.Elements.closest(td, "table")
  header = table.querySelector("thead tr:last-child")

  header.children[index]

window.App.Helpers.Tables.toggle_column = (th, status) ->
  status ?= !App.Helpers.Elements.visible(th)
  index = App.Helpers.Elements.index(th) + 1
  table = App.Helpers.Elements.closest(th, ".table_container")

  cells = table.querySelectorAll("td:nth-child(#{index})")

  if status
    th.style.display = null
  else
    th.style.display = "none"

  for cell in cells
    if status
      cell.style.display = null
    else
      cell.style.display = "none"

  undefined