
$(document).on "click", ".response_condition_group_add_condition", (event) ->
  event.preventDefault()

  button = event.currentTarget
  modal = App.Helpers.Elements.closest(button, ".modal .body")
  query = App.Elements.ResponseQuery.find_by_container(modal)

  return unless query?

  group_element = App.Helpers.Elements.closest(button, ".response_condition_group")
  group = query.editor_query.find_condition_by_instance_id(group_element.dataset.instance)

  query.build_condition(group)

$(document).on "click", ".response_condition_group_add_group", (event) ->
  event.preventDefault()

  button = event.currentTarget
  modal = App.Helpers.Elements.closest(button, ".modal .body")
  query = App.Elements.ResponseQuery.find_by_container(modal)

  return unless query?

  group_element = App.Helpers.Elements.closest(button, ".response_condition_group")
  group = query.editor_query.find_condition_by_instance_id(group_element.dataset.instance)

  new_group = query.build_condition(group, type: "group")

  # Initialize the new group with an empty condition
  query.build_condition(new_group)

