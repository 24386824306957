
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.UserDrawer ?= {}

window.App.Interface.UserDrawer.REFRESH_TIMEOUT = 1000

class window.App.Interface.UserDrawer.Object
  constructor: (@element) ->
    @notifications = @element.querySelector("#notifications")

    @unread_notification_permalinks = []
    @property_modals = {}

    @button = document.querySelector(".user_drawer_toggle")

    if App.current_user.config_or_true("responses", "views", "preload", "hover")
      $(@button).on "mouseover", (event) =>
        event.preventDefault()
        @load_notifications()

    $(@button).on "mousedown", (event) =>
      event.preventDefault()
      @load_notifications()

    $(@button).on "click", (event) =>
      event.preventDefault()
      @toggle()

    $(document).on "click", ".user_drawer_exit", (event) =>
      event.preventDefault()
      @hide()

  unread_notifications: ->
    @unread_notification_permalinks? && @unread_notification_permalinks.length > 0

  mark_unread: ->
    @button.classList.add("unread_notifications")
    @button.setAttribute("title", i18n.t("notifications.have_unread"))

  mark_read: ->
    @button.classList.remove("unread_notifications")
    @button.removeAttribute("title")

  toggle: ->
    if @element.classList.contains("visible") then @hide() else @show()

  hide: ->
    return unless @element.classList.contains("visible")

    @element.classList.remove("visible")

    $(window).off("resize", App.Interface.UserDrawer.reposition)

  show: ->
    return if @element.classList.contains("visible")

    sidebar = App.sidebar()
    sidebar.mobile_hide() if sidebar?

    @_position_drawer()
    @element.classList.add("visible")
    @load_notifications().then => @read()

    $(window).on("resize", App.Interface.UserDrawer.reposition)

  load_notifications: ->
    current_time = App.Helpers.Times.unix()

    if @_last_loaded?
      if current_time < @_last_loaded + App.Interface.UserDrawer.REFRESH_TIMEOUT
        return Promise.resolve()

    @_last_loaded = current_time

    new Promise (resolve, reject) =>
      @notifications.innerHTML = ""
      add_loader @notifications

      $.ajax
        url: Routes.preview_notifications_path()
        method: "POST"
        data: { authenticity_token: current_user.authenticity_token() }
      .done (response) =>
        remove_loader @notifications

        if response.data?
          for notification in response.data
            @create(notification)

        resolve()
      .fail =>
        remove_loader @notifications
        reject()

  create: (notification) ->
    if notification.type == "announcement"
      symbol = document.createElement("I")
      symbol.className = "icon icon-ok-circled"
      symbol.title = i18n.t("notifications.official")
    else
      symbol = document.createElement("SPAN")
      name = document.createElement("B")
      name.textContent = notification.organization

      symbol.appendChild(name)
      symbol.innerHTML += " &mdash; "

    note = document.createElement("A")
    note.href = notification.path
    note.className = "notification"
    note.setAttribute("tabindex", "-1")
    note.setAttribute("data-permalink", notification.permalink)

    content = document.createElement("SPAN")
    content.textContent = notification.content

    note.appendChild(symbol)
    note.appendChild(content)

    if notification.resolved
      note.classList.add("resolved")
      note.title = i18n.t("notifications.been_resolved")
    else if notification.path != "#"
      note.title = i18n.t("notifications.open_linked")

    if !notification.read
      @unread_notification_permalinks.push(notification.permalink)

      @mark_unread()

    @notifications.appendChild(note)

    @generate_notification_context_menu note

  read: ->
    @mark_read()

    return unless @unread_notifications()

    $.ajax
      url: Routes.read_notifications_path()
      type: "POST"
      dataType: "JSON"
      data: {
        permalinks: @unread_notification_permalinks,
        authenticity_token: current_user.authenticity_token()
      }

    @unread_notification_permalinks = []

  destroy: (permalink) ->
    $.ajax
      url: Routes.destroy_notification_path(permalink)
      type: "DELETE"
      dataType: "JSON"
      data: {
        authenticity_token: current_user.authenticity_token()
      }

    note = @notifications.querySelector(".notification[data-permalink='#{permalink}']")
    note.parentNode.removeChild(note)

  properties: (permalink) ->
    deferred = $.Deferred()

    if @property_modals[permalink]?
      return deferred.resolve(@property_modals[permalink].show())

    $.ajax
      url: Routes.notification_properties_path(permalink, display: "modal")
    .done (response) =>
      @property_modals[permalink] = App.Interface.Modal.from_html(response)
      @property_modals[permalink].show()
      deferred.resolve(@property_modals[permalink])
    .fail (xhr) ->
      App.Errors.response_error(xhr)
      deferred.reject(response)

    deferred

  generate_notification_context_menu: (element) ->
    return if element.classList.contains("initialized")

    notification_link = element.getAttribute("href")
    notification_link_disabled = false

    if notification_link == "#" || notification_link == "javascript:void(0)"
      notification_link = "javascript:void(0)"
      notification_link_disabled = true

    App.Interface.ContextMenu.new(element, [
      {
        text: i18n.t("open"),
        icon: "reply",
        disabled: notification_link_disabled,
        href: notification_link
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        disabled: notification_link_disabled,
        href: notification_link,
        target: "_blank"
      },
      {
        text: i18n.t("information"),
        icon: "info-circled",
        callback: ((notification) =>
          => @properties(notification.dataset.permalink)
        )(element)
      },
      {
        separator: true
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        callback: ((notification) =>
          => @destroy(notification.dataset.permalink)
        )(element)
      }
    ])

  _position_drawer: ->
    body_height = document.body.offsetHeight
    navbar_height = document.getElementById("global_page_navbar").offsetHeight

    @element.style.top = navbar_height + "px"
    @element.style.height = (body_height - navbar_height) + "px"


window.App.Interface.UserDrawer.reposition = ->
  App.user_drawer()._position_drawer()

window.App.Interface.user_drawer = ->
  App.Interface.UserDrawer.model

window.App.user_drawer = App.Interface.user_drawer

$ ->
  element = document.getElementById("user_drawer")
  return unless element?

  window.App.Interface.UserDrawer.model = new App.Interface.UserDrawer.Object(element)
