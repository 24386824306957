
PubSub.subscribe "submission.submitted_data", (trigger, data) ->
  permalink = trigger.split(".")[3]
  viewer = App.Elements.ResponseViewer.find_by_permalink(permalink)

  return unless viewer?

  viewer.update(data)

PubSub.subscribe "submission.destroyed", (trigger, data) ->
  permalink = trigger.split(".")[3]
  viewer = App.Elements.ResponseViewer.find_by_permalink(permalink)

  return unless viewer?

  viewer.destroy()

PubSub.subscribe "submission.restored", (trigger, data) ->
  permalink = trigger.split(".")[3]
  viewer = App.Elements.ResponseViewer.find_by_permalink(permalink)

  return unless viewer?

  viewer.restore()

$(document).on "click", ".submission_page_tab", (event) ->
  event.preventDefault()

  button = event.currentTarget
  viewer_element = App.Helpers.Elements.closest(button, ".view_submission")

  viewer = App.Elements.ResponseViewer.find_by_element(viewer_element)
  viewer.open_form_page(button.dataset.field)

$(document).on "click", ".user_table_button", (event) ->
  event.preventDefault()

  table_button = event.currentTarget
  table_button_list = App.Helpers.Elements.closest(table_button, ".user_table_button_list")

  table_id = table_button.dataset.table
  table = document.querySelector(".user_table_page[data-table='#{table_id}']")
  table_list = App.Helpers.Elements.closest(table, ".user_table_list")
  tables = table_list.querySelectorAll(".user_table_page")

  for table_page in tables
    if table_page.dataset.table == table_id
      table_page.style.display = "block"
    else
      table_page.style.display = "none"

  table_elements = table.querySelectorAll("table")
  table_buttons  = table_button_list.querySelectorAll(".user_table_button")

  for element in table_elements
    table_model = App.tables.find_by_table_element(element)

    if table_model? && table_model.initialize_resizing?
      table_model.initialize_resizing()

  for element in table_buttons
    if element == table_button
      element.classList.add("selected")
    else
      element.classList.remove("selected")

  App.Helpers.Elements.PageRows.reflow App.context().page_element()

$(document).on "click", ".response_viewer_settings", (event) ->
  event.preventDefault()

  button = event.currentTarget
  viewer_element = App.Helpers.Elements.closest(button, ".view_submission")

  form_permalink = viewer_element.dataset.formPermalink

  Form.new(form_permalink).viewer_settings_modal().show()

$(document).on "click", ".view_submission[data-permission-update=true] .submission_field", (event) ->
  element = event.target
  element_tag = element.tagName.toUpperCase()

  return unless element_tag == "DIV" || element_tag == "P"
  return if element.classList.contains("submission_field_button")

  if window.getSelection && window.getSelection().toString() != ""
    selection = window.getSelection()

    # Allow users to select text without triggering the editor
    return false if element.contains(selection.anchorNode)

  submission_element = App.Helpers.Elements.closest(element, ".view_submission")
  permalink = submission_element.dataset.submissionPermalink
  form_permalink = submission_element.dataset.formPermalink
  form = Form.new(form_permalink)

  form.submissions.load(permalink, hidden: false, focus: false).then (submission) ->
    field = submission.fields.find_by_permalink(element.dataset.permalink)
    return unless field?
    field.focus()

$ ->
  if current_user.config("modal_toggle_hotkeys")
    Hotkey.bind ["CTRL", "ALT", "LEFT"],
      do: ->
        viewer = App.Helpers.Hotkeys.data.viewer
        index = viewer.active_form_page_index()

        viewer.open_form_page(index - 1)
      if: ->
        return false if App.Interface.Modal.visible_modal()?

        for permalink, viewer in App.Elements.ResponseViewer._models
          if App.Helpers.Elements.visible_in_vertical_viewport(viewer.element)
            App.Helpers.Hotkeys.data.viewer = viewer
            return true

        false

    Hotkey.bind ["CTRL", "ALT", "RIGHT"],
      do: ->
        viewer = App.Helpers.Hotkeys.data.viewer
        index = viewer.active_form_page_index()

        viewer.open_form_page(index + 1)
      if: ->
        return false if App.Interface.Modal.visible_modal()?

        for permalink, viewer in App.Elements.ResponseViewer._models
          if App.Helpers.Elements.visible_in_vertical_viewport(viewer.element)
            App.Helpers.Hotkeys.data.viewer = viewer
            return true

        false

    Hotkey.bind ["CTRL", "ALT", /[1-9]/],
      do: (event) ->
        App.Helpers.Hotkeys.data.viewer.open_form_page(event.keyCode - 49)
      if: ->
        return false if App.Interface.Modal.visible_modal()?

        for permalink, viewer in App.Elements.ResponseViewer._models
          if App.Helpers.Elements.visible_in_vertical_viewport(viewer.element)
            App.Helpers.Hotkeys.data.viewer = viewer
            return true

        false

    Hotkey.bind ["CTRL", "ALT", "0"],
      do: (event) ->
        App.Helpers.Hotkeys.data.viewer.open_form_page(9)
      if: ->
        return false if App.Interface.Modal.visible_modal()?

        for permalink, viewer in App.Elements.ResponseViewer._models
          if App.Helpers.Elements.visible_in_vertical_viewport(viewer.element)
            App.Helpers.Hotkeys.data.viewer = viewer
            return true

        false