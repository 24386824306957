
window.App.Events ?= {}
window.App.Events.Handlers ?= {}
window.App.Events.Handlers.Submissions ?= {}
window.App.Events.Handlers.Submissions.Buttons ?= {}

$(document).on "click", ".submission_button", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button
  add_loader button

  if App.Helpers.Elements.closest(button, ".FIELD_CONTAINER")?
    return App.Events.Handlers.Submissions.Buttons.response_field_button(event)
    .then -> remove_loader button
    .catch -> remove_loader button

  if App.Helpers.Elements.closest(button, ".response_table_wrapper")?
    return App.Events.Handlers.Submissions.Buttons.response_table_button(event)
    .then -> remove_loader button
    .catch -> remove_loader button

  if App.Helpers.Elements.closest(button, ".submission_field")?
    return App.Events.Handlers.Submissions.Buttons.response_view_button(event)
    .then -> remove_loader button
    .catch -> remove_loader button

window.App.Events.Handlers.Submissions.Buttons.response_field_button = (event) ->
  new Promise (resolve, reject) ->
    button = event.currentTarget

    submission_element = App.Helpers.Elements.closest(button, ".SUBMISSION")
    form = App.Models.Form.new(submission_element.dataset.formPermalink)

    submission = form.submissions.find_locally(submission_element)
    submission.on "ready", ->
      field = submission.fields.find_by_variable_name(button.dataset.field)
      field.helpers.trigger(true)
      resolve()

window.App.Events.Handlers.Submissions.Buttons.response_table_button = (event) ->
  new Promise (resolve, reject) ->
    button = event.currentTarget

    row_element = App.Helpers.Elements.closest(button, "tr")
    table_element = App.Helpers.Elements.closest(row_element, "table")

    form_permalink = table_element.dataset.permalink
    submission_permalink = row_element.dataset.permalink
    field_variable = button.dataset.field

    form = App.Models.Form.new(form_permalink)
    form.submissions.load(submission_permalink).then (submission) ->
      submission.on "ready", ->
        field = submission.fields.find_by_variable_name(field_variable)
        field.helpers.trigger(true)
        resolve()
    .catch reject

window.App.Events.Handlers.Submissions.Buttons.response_view_button = (event) ->
  new Promise (resolve, reject) ->
    button = event.currentTarget

    field_element = App.Helpers.Elements.closest(button, ".submission_field")
    field_variable = field_element.dataset.columnName

    submission_element = App.Helpers.Elements.closest(field_element, ".view_submission")
    submission_permalink = submission_element.dataset.submissionPermalink

    form_permalink = submission_element.dataset.formPermalink

    form = App.Models.Form.new(form_permalink)
    form.submissions.load(submission_permalink).then (submission) ->
      submission.on "ready", ->
        field = submission.fields.find_by_variable_name(field_variable)
        field.helpers.trigger(true)
        resolve()
    .catch reject
