
window.App ?= {}
window.App.Schema ?= {}

window.App.Schema.SubmissionPromise = (->
  SubmissionPromise = App.Helpers.Objects.deep_clone(Promise)

  SubmissionPromise.prototype.to_array = ->
    return @_array? if @_array?
    @_array = []

    # Frameworks like Vue mutate the array in-place to wrap
    # methods like `push` with methods triggering UI refreshes.
    @then (data) =>
      for submission in data.all()
        @_array.push(submission)

      # These bound potentially-mutated arrays are kept in sync
      # by the submission data array, when any pushes or splices
      # are made.
      data._bound_objects ?= []
      data._bound_objects.push(@_array)
      data

    @_array

  SubmissionPromise.prototype.to_simple_array = ->
    return @_simple_array if @_simple_array?
    @_simple_array = []

    @then (data) =>
      for submission in data.all()
        @_simple_array.push(submission.to_simple_data())

      data

    @_simple_array

  SubmissionPromise.prototype.submissions = ->
    @then (data) => data.submissions()

  SubmissionPromise.prototype.form = (form) ->
    @_form = form if form?
    @_form

  SubmissionPromise.prototype.toArray = SubmissionPromise.prototype.to_array
  SubmissionPromise.prototype.toSimpleArray = SubmissionPromise.prototype.to_simple_array
  SubmissionPromise.prototype.submissions = SubmissionPromise.prototype.submissions
  SubmissionPromise.prototype.form = SubmissionPromise.prototype.form

  SubmissionPromise
)()
