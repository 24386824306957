import 'app/helpers/arguments'
import 'app/helpers/arrays'
import 'app/helpers/assets'
import 'app/helpers/callbacks'
import 'app/helpers/compatibility'
import 'app/helpers/elements'
import 'app/helpers/events'
import 'app/helpers/generators'
import 'app/helpers/forms'
import 'app/helpers/hotkeys'
import 'app/helpers/inputs'
import 'app/helpers/loaders'
import 'app/helpers/objects'
import 'app/helpers/parameters'
import 'app/helpers/requests'
import 'app/helpers/selects'
import 'app/helpers/strings'
import 'app/helpers/tables'
import 'app/helpers/times'
import 'app/helpers/versions'


window.App.redirect_to = (url, options = {}) ->
  url = App.Helpers.URIs.parse(url).value()

  if options.target? || options.scaffold == false || !App.windowing_enabled()
    if !options.target? || options.target == "_self"
      window.location = url
      return new Promise(->)
    else
      redirect = window.open(url, options.target)
      redirect.opener = null

      return new Promise(->)
  else
    App.window_manager.open_page(url)

window.App.hard_redirect_to = (url, options = {}) ->
  options.scaffold = false

  App.redirect_to(url, options)

window.App._generate_scrollbars = ->
  document_main = document.querySelector(".document-main")

  if document_main?
    if App.context? && App.windowing_enabled()
      App.context().show()
    else
      if !document_main.classList.contains("antiscroll-wrap")
        document_main.classList.add("antiscroll-wrap")

        main_view_page = document_main.querySelector(".main_view_page")
        main_view_page.classList.add("antiscroll-inner")

        App._scroller = $(document_main).antiscroll().data("antiscroll")

  null

if i18next?
  window.i18n = i18next
