
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseQuery ?= {}

window.App.Elements.ResponseQuery._form_searches = {}

window.App.Elements.ResponseQuery.from_search = (form) ->
  existing_search = App.Elements.ResponseQuery._form_searches[form.permalink()]
  return existing_search if existing_search?
  query_editor = new App.Elements.ResponseQuery.Query(form)

  App.Elements.ResponseQuery._form_searches[form.permalink()] = query_editor

  query_editor.on "change.initial", =>
    App.redirect_to Routes.submissions_path(
      form.permalink(),
      query_editor: query_editor.instance_id,
      conditions: btoa(JSON.stringify(query_editor.query.serialize()))
    )

  query_editor.on "report.create", =>
    delete App.Elements.ResponseQuery._form_searches[form.permalink()]

    App.redirect_to Routes.report_path(
      form.permalink(),
      query_editor.report.permalink(),
      query_editor: query_editor.instance_id
    )

  query_editor
