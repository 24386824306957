
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Sidebar ?= {}

window.App.Interface.Sidebar.toggle_drawer = (drawer, visible) ->
  drawer = drawer[0] if App.Helpers.Elements.is_jquery(drawer)
  button = drawer.querySelector(".sidebar-drawer-title")
  content = drawer.querySelector(".sidebar-drawer-content")
  container = App.Helpers.Elements.closest(drawer, ".sidebar-drawer-container")

  visible ?= !drawer.classList.contains("sidebar-drawer-open")

  if container? && visible
    single_drawer = container.classList.contains("sidebar-drawer-single")

    # .sidebar-drawer-single allows only one drawer in a set to be open.
    # If this drawer is already open, return to prevent double animation.
    if single_drawer
      open_drawers = container.querySelectorAll(".sidebar-drawer.sidebar-drawer-open")

      drawer_is_open = false

      for open_drawer in open_drawers
        if open_drawer == drawer
          drawer_is_open = true
        else
          App.Interface.Sidebar.close_drawer open_drawer

      return drawer if drawer_is_open

  drawer.classList.toggle("sidebar-drawer-open", visible)

  if visible
    $(content).slideDown App.Interface.Sidebar.animation_time, ->
      App.sidebar().generate_scrollbars() if App.sidebar()?
  else
    $(content).slideUp App.Interface.Sidebar.animation_time, ->
      App.sidebar().generate_scrollbars() if App.sidebar()?

  drawer

window.App.Interface.Sidebar.close_drawer = (drawer) ->
  App.Interface.Sidebar.toggle_drawer(drawer, false)

window.App.Interface.Sidebar.open_drawer = (drawer) ->
  App.Interface.Sidebar.toggle_drawer(drawer, true)

$(document).on "click", ".sidebar-drawer .sidebar-drawer-title", (event) ->
  event.preventDefault()

  button = event.currentTarget
  drawer = App.Helpers.Elements.closest(button, ".sidebar-drawer")

  App.Sidebar.toggle_drawer(drawer)
