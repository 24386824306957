
$(document).on "click", ".reply-comment", (event) ->
  event.preventDefault()

  button = event.currentTarget
  comment = App.Helpers.Elements.closest(button, ".comment")

  return unless comment?

  viewer_element = App.Helpers.Elements.closest(button, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.reply_toggle(comment)

$(document).on "click", ".delete-comment", (event) ->
  event.preventDefault()

  button = event.currentTarget
  comment = App.Helpers.Elements.closest(button, ".comment")

  return unless comment?

  viewer_element = App.Helpers.Elements.closest(button, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.destroy(comment)

$(document).on "click", ".resolve-comment", (event) ->
  event.preventDefault()

  button = event.currentTarget
  comment = App.Helpers.Elements.closest(button, ".comment")

  return unless comment?

  viewer_element = App.Helpers.Elements.closest(button, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.resolve(comment)

$(document).on "click", ".unresolve-comment", (event) ->
  event.preventDefault()

  button = event.currentTarget
  comment = App.Helpers.Elements.closest(button, ".comment")

  return unless comment?

  viewer_element = App.Helpers.Elements.closest(button, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.unresolve(comment)

$(document).on "click", ".show-resolved-comments", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button
  add_loader button

  comment = App.Helpers.Elements.closest(button, ".comment")

  return unless comment?

  viewer_element = App.Helpers.Elements.closest(button, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.show_resolved(comment)

$(document).on "submit", ".comment-form", (event) ->
  event.preventDefault()

  form = event.currentTarget
  parent_comment = App.Helpers.Elements.closest(form, ".comment")

  # Having no parent comment is allowed, for top-level comments

  viewer_element = App.Helpers.Elements.closest(form, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.submit(parent_comment)
