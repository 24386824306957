
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}
window.App.Elements.ResponseTable.Helpers ?= {}

class window.App.Elements.ResponseTable.Helpers.Params
  constructor: (@table) ->

  url_data: ->
    output = {}
    output.format = "json"
    output

  fullpage: ->
    @table.element.dataset.fullpage == "true"

  page: (value) ->
    if value?
      value = 0 if value < 0
      @_page_number = value

      return @table.refresh()

    return 0 unless @_page_number
    @_page_number

  default_length: ->
    return 5 if @field_exists()

    100

  length: (value) ->
    value = value[0] if value? && App.Helpers.Elements.is_jquery(value)

    # If the jQuery element matches no objects, value[0] will be null
    if value?
      # Search with Element
      if value.nodeType
        value.addEventListener("input", ((event) =>
          @_page_length = parseInt(event.currentTarget.value)
          @table.refresh()
        ), false)
      else
        @_page_length = parseInt(value)
        @table.refresh()

    return @default_length() unless @_page_length?

    @_page_length

  search: (value) ->
    value = value[0] if value? && App.Helpers.Elements.is_jquery(value)

    # If the jQuery element matches no objects, value[0] will be null
    if value?
      # Search with Element
      if value.nodeType
        value.addEventListener("input", ((event) =>
          @_search_value = event.currentTarget.value
          clearTimeout(@_search_timeout)
          @_search_timeout = setTimeout((=> @table.refresh()), 100)
        ), false)
      else
        @_search_value = value
        @table.refresh()

    @_search_value

  # Filter by allowed submission permalinks
  allowed_permalinks: (values) ->
    if values?
      if values == false
        @_allowed_permalinks = undefined
        @table.refresh()
      else
        @_allowed_permalinks = values
        @table.refresh()

    @_allowed_permalinks

  deleted: ->
    deleted = @table.element.dataset.deleted
    return false if !deleted? || deleted != "true"
    true

  creator: ->
    value = @table.element.dataset.user
    return if !value? || value == ""
    value

  report: ->
    value = @table.element.dataset.report
    return if !value? || value == ""
    value

  # Tables can be passed a query_editor parameter to initialize them with an editor that
  # already exists on the page.
  initial_query_editor: ->
    value = @table.element.dataset.queryEditor
    return if !value? || value == ""
    value

  # If the table's filter query has been changed, and the user has READ permission for the
  # form itself, the filter query will include any Report conditions (which may be edited).
  # The data source should be changed to the form's data so that it is not filtered by old
  # Report conditions.
  query_changed_with_form_permission: ->
    return false unless @table.has_form_permission("read")
    return false unless @table.query_editor_loaded()
    return false unless @table.query_editor().changed()

    true

  connected_to: ->
    field_element = @table.element.querySelector("[name='connection_id']")
    submission_element = @table.element.querySelector("[name='connection_value']")

    return unless field_element? && submission_element?

    { field: field_element.value, submission: submission_element.value }

  field_exists: ->
    @_field_exists ?= @field_element()?
    @_field_exists

  field_element: ->
    App.Helpers.Elements.closest(@table.element, ".FIELD_CONTAINER")

  field: ->
    return unless @field_exists()

    @_field_column_name ?= @field_element().dataset.columnName

    @submission().fields.find_by_column_name(@_field_column_name)

  grouping_exists: ->
    @table.configuration.resource? && @table.configuration.resource.type == "grouping.set"

  grouping: ->
    return null unless @grouping_exists()

    {
      set: @table.configuration.resource.permalink,
      grouping: @table.configuration.resource.grouping.permalink
    }

  submission: ->
    return unless @field_exists()

    if !@_submission_instance_id?
      field_element = @field_element()
      submission_element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")

      @_submission_form_permalink = submission_element.dataset.formPermalink
      @_submission_instance_id = submission_element.dataset.instance

    form = App.Models.Form.new(@_submission_form_permalink)
    form.submissions._find_by_parameter("instance_id", @_submission_instance_id)

  basic_form_table: ->
    @basic_form_or_report() && !@report()

  basic_report_table: ->
    @basic_form_or_report() && @report()?

  # A basic form or report table, without additional filters or parameters.
  basic_form_or_report: ->
    return false if @creator() || @connected_to() || @field_exists() || @grouping_exists()
    return false if @table.query_editor_loaded() && @table.query_editor().changed()

    true

  serialize: ->
    data = {}
    data.draw = @table.draw_count
    data.start = @page() * @length()
    data.length = @length()
    data.authenticity_token = current_user.authenticity_token()

    if @creator()?
      data.creator = @creator()

    if @report()?
      data.report_permalink = @report()

    if @deleted()
      data.deleted = @deleted()

    if @allowed_permalinks()?
      data.filter = { value: @allowed_permalinks() }

    if @table.selections?
      selections = @table.selections.all()

      if selections? && selections.length > 0
        data.selected = { value: selections }

    if @field_exists()
      field = @field()

      if field.helpers?
        data.new_unselected = {
          value: field.helpers._spawned_submission_permalinks()
        }

    if @table.query.any()
      data.form_query = @table.query.serialize()

    data.columns = @table.column_parameters()
    data.order = @table.ordering()

    search_value = @search()
    if search_value?
      data.search = {
        value: search_value
      }

    data