
$ ->
  return if App.windowing_enabled()

  for binding in App.Models._bound_classes
    App.Models.bind_children(document, binding)

$(document).on "page:load", (event, context) ->
  for binding in App.Models._bound_classes
    if context._menu_element?
      App.Models.bind_children(context._menu_element, binding)

    if context._page_element?
      App.Models.bind_children(context._page_element, binding)

    if context._sidebar_element?
      App.Models.bind_children(context._sidebar_element, binding)
