
window.App ?= {}
window.App.Models ?= {}

window.App.Models._bound_classes = []

window.App.Models.bind_children = (container, binding) ->
  elements = container.querySelectorAll(binding.selector)

  for element in elements
    if binding.model?
      unless element.classList.contains("initialized")
        new binding.model(element)
    else if binding.method?
      binding.method.call(element, element)

window.App.Models.bind = (selector, model) ->
  App.Models._bound_classes.push({ selector: selector, model: model })

window.App.Models.bind_function = (selector, method) ->
  App.Models._bound_classes.push({ selector: selector, method: method })
