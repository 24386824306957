
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}
window.App.Interface.Menu.SubMenu ?= {}

class window.App.Interface.Menu.SubMenu.Option extends App.Interface.Menu.SubMenu.BaseOption
  constructor: (@menu, options = {}) ->
    super(@menu, options)

    @menu.sandboxes.publish("dropdown.create", @serialize())
    @menu.callbacks.trigger("dropdown.add", @)

  _generate: (options) ->
    options = App.Interface.Menu.SubMenu.parse_option_data(options)
    options = @_cache_options(options)

    @callback(options.callback) if options.callback?

    old_element = @element

    @id = options.id || @id || App.Helpers.Generators.uuid()
    @instance_id ?= options.instance_id || @id

    @_update_options(options.children) if options.children?
    @_detach_options()

    @_children_wrapper = document.createElement("DIV")
    @_children_wrapper.className = "navbar_dropdown"

    @button = document.createElement("A")
    @button.textContent = options.text
    @button.href = "#"

    icon = document.createElement("I")
    icon.className = "icon icon-down icon-open ml-1"

    @button.appendChild(icon)

    @element = document.createElement("DIV")
    @element.className = "option navbar_dropdown_option submenu_option"
    @element.id = @id
    @element.appendChild(@button)

    @_children_wrapper.appendChild(@element)

    @submenu_background_element = document.createElement("DIV")
    @submenu_background_element.className = "navbar_dropdown_layer"

    @_children_wrapper.insertBefore(@submenu_background_element, @_children_wrapper.firstChild)

    old_element.parentNode.replaceChild(@element, old_element) if old_element?

    @_attach_options()

    @submenu_background_element.addEventListener "click", (event) =>
      event.preventDefault()
      @close()

    @button.addEventListener "click", (event) =>
      event.preventDefault()
      @toggle_opened()

    @reapply_callbacks()

    @

  _generate_sandbox: ->
    super()

    for option in @all_options()
      option._generate_sandbox()

    undefined

  # Returns the main dropdown parent option which appears in the Menu
  top_level_parent: ->
    @

  text: (value) ->
    first_child = @element.firstChild

    for node in first_child.childNodes
      continue unless node.nodeType == 3
      text_node = node
      break

    if value?
      if text_node?
        text_node.textContent = value
      else
        text_node = document.createTextNode(value)
        first_child.appendChild(text_node)

      @_publish("text", { value: value })

    return "" unless text_node?

    text_node.textContent

  destroy: ->
    @element.remove()

    @menu.callbacks.trigger("dropdown.remove", @id)
    @_publish("remove")

    delete @menu._custom_options[@id]

    undefined

  open: ->
    @_children_wrapper.classList.add("navbar_dropdown_visible")

    coords = @coords()

    App.Helpers.Elements.Floating.position_element(
      @_children_wrapper, [[coords.left, coords.bottom]]
    )

    @_publish "open"

    @_children_wrapper

  close: ->
    @_children_wrapper.classList.remove("navbar_dropdown_visible")
    @close_all_options()

    @_publish "close"

    @_children_wrapper

  toggle_opened: ->
    if @opened() then @close() else @open()

  opened: ->
    @_children_wrapper.classList.contains("navbar_dropdown_visible")

  serialize: ->
    output = {}
    output.instance_id = @instance_id
    output.id = @id
    output.text = @text()
    output.opened = @opened()

    if @_children?
      output.options = []

      for child in @_children
        output.options.push(child.serialize())

    output
