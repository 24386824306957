
window.App ?= {}
window.App.Objects ?= {}

class window.App.Objects.Coordinates
  constructor: (@x, @y) ->

  value: -> [@x, @y]

  equals: (value) ->
    @x == value.x && @y == value.y
