
$(document).on "click", ".modal", (event) -> event.stopPropagation()

# Exit a modal by clicking the X icon in the upper right
$(document).on "click", ".modal-exit", (event) ->
  button = event.currentTarget

  event.preventDefault() if button.getAttribute("href") == "#"

  container = App.Helpers.Elements.closest(button, ".modal-container")

  return unless container?

  modal = App.Interface.Modal.find_by_id(container.id)
  return unless modal?

  return if modal.forced()

  modal.hide()

# Exit a modal by clicking the darkened background outside the modal
$(document).on "click", ".modal-container", (event) ->
  container = event.currentTarget

  return unless event.target == container

  modal = App.Interface.Modal.find_by_id(container.id)
  return unless modal?

  return if modal.forced()

  modal.hide()

$(document).on "submit", ".modal form", (event) ->
  form = event.currentTarget

  container = App.Helpers.Elements.closest(form, ".modal-container")
  return unless container?

  modal = App.Interface.Modal.find_by_id(container.id)
  return unless modal?

  modal.callbacks.trigger("submit", event)

$(document).on "persist", ".modal form", (event) ->
  form = event.currentTarget

  container = App.Helpers.Elements.closest(form, ".modal-container")
  return unless container?

  modal = App.Interface.Modal.find_by_id(container.id)
  return unless modal?

  modal.callbacks.trigger("persist", event)

$(document).on "click", ".tab_container .tab", (event) ->
  event.preventDefault()

  tab = event.currentTarget
  tab_name = tab.dataset.tab
  menu = App.Helpers.Elements.closest(tab, ".tab_menu")
  container = App.Helpers.Elements.closest(menu, ".tab_container")

  menu_tabs = menu.querySelectorAll(".tab")

  for element in menu_tabs
    if element == tab
      element.classList.add("selected")
    else
      element.classList.remove("selected")

  container_sections = container.querySelectorAll(".tab_section")

  for element in container_sections
    if element.dataset.tab == tab_name
      element.classList.add("selected")
      element.style.display = "block"
    else
      element.classList.remove("selected")
      element.style.display = "none"

  undefined

# Exit an open modal with ESCAPE
Hotkey.bind ["ESCAPE"],
  if: (-> App.Interface.Modal.visible_modal()?),
  do: (-> App.Interface.Modal.visible_modal().hide())

# Move to the previous open modal with CTRL+LEFT
Hotkey.bind ["CTRL", "LEFT"],
  if: (->
    modal = App.Interface.Modal.visible_modal()
    modal? && modal.container.classList.contains("SUBMISSION") && App.Interface.Modal.submission_modals.length > 1
  ),
  do: (->
    modal = App.Interface.Modal.visible_modal()

    App.Interface.Modal.hide_all(modal)
    index = App.Interface.Modal.submission_modals.indexOf(modal)

    $(m.modal_element).stop(true, true) for m in App.Interface.Modal.submission_modals

    # Handle wrapping from the start of the array to the end of the array
    if index == 0
      submission_index = App.Interface.Modal.submission_modals.length - 1
    else
      submission_index = index - 1

    App.Interface.Modal._current = App.Interface.Modal.submission_modals[submission_index]
    App.Interface.Modal._current.slide_in("left")

    modal.slide_out("right")
  )

# Move to the next open modal with CTRL+RIGHT
Hotkey.bind ["CTRL", "RIGHT"],
  if: (->
    modal = App.Interface.Modal.visible_modal()
    modal? && modal.container.classList.contains("SUBMISSION") && App.Interface.Modal.submission_modals.length > 1
  ),
  do: (->
    modal = App.Interface.Modal.visible_modal()

    App.Interface.Modal.hide_all(modal)
    index = App.Interface.Modal.submission_modals.indexOf(modal)

    $(m.modal_element).stop(true, true) for m in App.Interface.Modal.submission_modals

    # Handle wrapping from the end of the array to the start of the array
    if index == App.Interface.Modal.submission_modals.length - 1
      submission_index = 0
    else
      submission_index = index + 1

    App.Interface.Modal._current = App.Interface.Modal.submission_modals[submission_index]
    App.Interface.Modal._current.slide_in("right")

    modal.slide_out("left")
  )

# Reopen the last closed modal with CTRL+DOWN
Hotkey.bind ["CTRL", "DOWN"],
  if: (-> App.Interface.Modal._last?),
  do: (-> App.Interface.Modal._last.show())
