
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.CheckBox
  constructor: (@field) ->
    undefined

  _value: (field_value) ->
    field_value = true if field_value == "true"
    field_value = false if field_value == "false"
    field_value
