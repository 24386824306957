
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}
window.App.Frame.Sandbox.Bind ?= {}

window.App.Frame.Sandbox.Bind.kanbans = (channel, options) ->
  if options.read == true
    channel.on "schema.load.kanbans", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.kanbans.schema()
      .then (data, response) -> event.resolve(response)
      .catch event.reject

  if options.read == true
    channel.on "schema.load.kanban", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.kanbans.schema()
      .then ->
        kanban = form.kanbans.find_by_permalink(event.data.kanban)
        kanban.sandboxes().add(channel)

        kanban.schema()
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject

  if options.destroy == true
    channel.on "kanban.destroy", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.kanbans.schema()
      .then ->
        kanban = form.kanbans.find_by_permalink(event.data.kanban)

        return event.reject("Kanban not found") unless kanban?

        kanban.destroy()
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject

  if options.update == true
    channel.on "kanban.update", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.kanbans.schema()
      .then ->
        kanban = form.kanbans.find_by_permalink(event.data.kanban)

        return event.reject("Kanban not found") unless kanban?

        kanban.update(event.data.parameters)
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject
