
window.IntegrationAuthorization ?= {}
window.IntegrationAuthorization.Strategy ?= {}

window.IntegrationAuthorization.initiate = (service, options = {}) ->
  strategy = IntegrationAuthorization.strategy(service)
  strategy.call(IntegrationAuthorization, service, options)

window.IntegrationAuthorization.strategy = (service) ->
  if IntegrationAuthorization.strategies[service]?
    return IntegrationAuthorization.strategies[service]

  IntegrationAuthorization.Strategy.OAuth

window.IntegrationAuthorization.Strategy.OAuth = (service, options = {}) ->
  new Promise (resolve, reject) ->
    url_options = options
    url_options.resource = service

    integration_url = Routes.integrations_services_initiate_path(url_options)

    popup = window.open(
      integration_url,
      "Authorize Integration",
      "menubar=yes,location=no,resizable=yes,scrollbars=yes,status=yes"
    )

    addEventListener "message", (message) ->
      return unless message.source == popup
      return unless message.data? && message.data.status?

      popup.close()

      if message.data.status == "success"
        resolve(message.data)
      else
        reject(message.data)

window.IntegrationAuthorization.Strategy.Twilio = (service, options = {}) ->
  new Promise (resolve, reject) ->
    url_options = options
    url_options.resource = service

    integration_url = Routes.integrations_services_twilio_initiate_path(url_options)

    popup = window.open(
      integration_url,
      "Authorize Integration",
      "menubar=yes,location=no,resizable=yes,scrollbars=yes,status=yes"
    )

    addEventListener "message", (message) ->
      return unless message.source == popup
      return unless message.data? && message.data.status?

      popup.close()

      if message.data.status == "success"
        resolve(message.data)
      else
        reject(message.data)

window.IntegrationAuthorization.strategies = {
  "twilio": IntegrationAuthorization.Strategy.Twilio
}