import 'modules/workflows/helpers/base'
import 'modules/workflows/helpers/conditions'
import 'modules/workflows/actions'
import 'modules/workflows/conditions'
import 'modules/workflows/triggers'



class window.WorkflowManager
  constructor: (@submission) ->
    @subscriptions = {}

    @_variables = {}
    @_steps = {}

    @set_variable("submission", @submission)
    @set_variable("submission_context", @submission)

    @set_variable("current_user", current_user.username())

  action: (step_type, parent_permalink, permalink, options) ->
    @_steps[permalink] = new WorkflowAction(@, {
      step_type: step_type,
      parent_permalink: parent_permalink,
      permalink: permalink,
      options: options
    })

  condition: (step_type, parent_permalink, permalink, options) ->
    @_steps[permalink] = new WorkflowCondition(@, {
      step_type: step_type,
      parent_permalink: parent_permalink,
      permalink: permalink,
      options: options
    })

  trigger: (step_type, permalink, options) ->
    @_steps[permalink] = new WorkflowTrigger(@, {
      step_type: step_type,
      permalink: permalink,
      options: options
    })

  subscribe: (parent_permalink, step) ->
    @subscriptions[parent_permalink] ?= []
    @subscriptions[parent_permalink].push(step)
    @subscriptions[parent_permalink]

  publish: (permalink, local_variables = {}) ->
    deferred = $.Deferred()

    return deferred.resolve() unless @subscriptions[permalink]?

    current_deferred = deferred

    for subscription in @subscriptions[permalink]
      current_deferred = current_deferred.then ((subscription) ->
        ->
          sub_deferred = $.Deferred()
          subscription.run(local_variables).always -> sub_deferred.resolve()
          sub_deferred
      )(subscription)

    deferred.resolve()

    current_deferred

  submission_context: (step_permalink = undefined) ->
    return $.Deferred().resolve(@submission) unless step_permalink?

    step = @_steps[step_permalink]

    return $.Deferred().resolve(@submission) unless step?

    return instance.evaluate_formula("formula") if step.step_type() == "submission:open"

    $.Deferred().resolve(@submission)

  encode_variable: (value) ->
    deferred = $.Deferred()

    if !value?
      deferred.resolve(value)
    else if value instanceof App.Models.Submission.Object
      deferred.resolve({
        _object_type: "submission",
        form: value.form().permalink(),
        permalink: value.permalink(),
        instance: value.instance_id,
        fields: ((submission) ->
          -> submission.unsaved_data()
        )(value)
      })
    else if Array.isArray(value)
      encode_elements = $.when.apply($, @encode_variable(x) for x in value)
      encode_elements.then -> deferred.resolve(element for element in arguments)
    else if value.constructor.name == "Object"
      return deferred.resolve(value) unless value._object_type?
      return deferred.resolve(value.text) if value._object_type == "text"

      deferred.resolve(value)
    else
      deferred.resolve(value)

    deferred

  decode_variable: (value) ->
    deferred = $.Deferred()
    return deferred.resolve(null) unless value?

    if Array.isArray(value)
      decode_elements = $.when.apply($, @decode_variable(x) for x in value)
      decode_elements.then -> deferred.resolve(element for element in arguments)
    else if value._object_type?
      switch value._object_type
        when "submission"
          form = App.Models.Form.new(value.form)

          if value.instance?
            submission = form.submissions.find_locally_by_instance_id(value.instance)
            deferred.resolve(submission)
          else
            if value.permalink?
              form.submissions.find(value.permalink).then (submission) ->
                deferred.resolve(submission)
            else
              form.submissions.new_or_unsaved().then (submission) ->
                deferred.resolve(submission)
    else
      deferred.resolve(value)

    deferred

  evaluate_variable: (value, local_variables = {}) ->
    deferred = $.Deferred()

    @encode_variable(value)
    .done (encoded_value) =>
      if typeof encoded_value == "string"
        @evaluate_formula(encoded_value, local_variables)
        .done (evaluated_value) =>
          @encode_variable(evaluated_value)
          .done (re_encoded_value) =>
            deferred.resolve(re_encoded_value)
          .fail (response) =>
            console.log("    ✕ Formula evaluation failed")
            deferred.reject()
        .fail (response) =>
          console.log("    ✕ Formula evaluation failed")
          deferred.reject()
      else
        deferred.resolve(encoded_value)
    .fail =>
      console.log("    ✕ Object encoding failed")
      deferred.reject()

    deferred

  set_variable: (name, value, local_variables = {}) ->
    deferred = $.Deferred()

    @evaluate_variable(value, local_variables)
    .done (evaluated_value) =>
      if value instanceof App.Models.Submission.Object
        value.after_submit (((variable, submission) ->
          -> variable.permalink = submission.permalink()
        )(evaluated_value, value))

      @_variables[name] = evaluated_value
      deferred.resolve(evaluated_value)
    .fail =>
      deferred.reject()

    deferred

  get_variable: (name, local_variables = {}) ->
    if Array.isArray(name)
      value = []
      value.push(local_variables[item] || @_variables[item]) for item in name
    else
      value = local_variables[name] || @_variables[name]

    @decode_variable(value)

  variables: (local_variables = {}) ->
    $.extend(true, {}, @_variables, local_variables)

  variables_for_request: (local_variables = {}) ->
    variables = @evaluate_nested_functions(@variables(local_variables))
    inverted_assigned_values = {}

    output = {}
    output.values = {}
    output.references = {}

    for name, value of variables
      referenced_name = inverted_assigned_values[value]

      if referenced_name?
        output.references[name] = referenced_name
      else
        output.values[name] = value
        inverted_assigned_values[value] = name

    output

  evaluate_nested_functions: (object, depth = 0) ->
    return object if depth > 10

    for key, value of object
      object[key] = value() if value instanceof Function
      object[key] = @evaluate_nested_functions(object[key], depth + 1) if object[key] instanceof Object

    object

  formula_exists: (text) ->
    text.indexOf("{{") != -1

  evaluate_formula: (text, local_variables = {}) ->
    deferred = $.Deferred()

    return deferred.resolve(text) unless @formula_exists(text)

    deferred.resolve(text) # Replace with formula evaluation request

    deferred

  evaluate_step_formula: (type, permalink, value, local_variables = {}) ->
    deferred = $.Deferred()

    variables = @variables_for_request(local_variables)

    url = Routes["workflow_#{type}_formula_path"](@submission.form().permalink(), permalink, value)

    $.ajax
      url: url
      type: "POST"
      dataType: "json"
      data: {
        variables: variables,
        authenticity_token: current_user.authenticity_token()
      }
    .done (response) =>
      @decode_variable(response.value).done (decoded_response) ->
        deferred.resolve decoded_response
    .fail (response) -> deferred.reject(response.responseText)

    deferred

  depth: (step) ->
    return 0 if step.type() == "trigger"

    parent_permalink = step.data.parent_permalink
    count = 0

    while parent_permalink != "ready"
      count += 1

      parent = @_steps[parent_permalink]
      parent_permalink = parent.data.parent_permalink

      return count if parent.type() == "trigger"

    count

class window.WorkflowStepInstance
  constructor: (@step, local_variables = {}) ->
    @parent_variables = local_variables
    @local_variables = App.Helpers.Objects.deep_clone(local_variables)
    @local_variables_touched = {}
    @workflow = @step.workflow
    @data = @step.data
    @storage = {}

  set_local_variable: (name, value) ->
    return $.Deferred().resolve() unless name? && name.length > 0

    deferred = $.Deferred()

    @workflow.evaluate_variable(value, @locals())
    .done (evaluated_value) =>
      @local_variables_touched[name] = true
      @local_variables[name] = evaluated_value
      deferred.resolve(value)
    .fail =>
      deferred.reject()

    deferred

  set_local_variables: (options) ->
    variable_setters = []

    for key, value of options
      variable_setters.push @set_local_variable(key, value)

    $.when(variable_setters)

  set_global_variable: (name, value) ->
    @workflow.set_variable(name, value, @locals())

  set_variable: (name, value) ->
    if @local_variables_touched[name]
      @set_local_variable(name, value)
    else if Object.keys(@parent_variables).indexOf(name) != -1
      deferred = $.Deferred()

      @workflow.evaluate_variable(value, @parent_variables)
      .done (evaluated_value) =>
        @parent_variables[name] = evaluated_value
        deferred.resolve(value)
      .fail =>
        deferred.reject()
    else
      @set_global_variable(name, value)

  get_variable: (name) ->
    @workflow.get_variable(name, @locals())

  locals: ->
    @local_variables

  publish: ->
    @workflow.publish(@step.data.permalink, @locals())

  run: ->
    @step.run(@locals())

  run_server: ->
    deferred = $.Deferred()

    variables = @workflow.variables_for_request(@local_variables)

    url = Routes["run_workflow_#{@step.type()}_path"](@workflow.submission.form().permalink(), @step.data.permalink)

    $.ajax
      url: url
      type: "POST"
      data: {
        variables: variables,
        authenticity_token: current_user.authenticity_token()
      }
    .done (response) -> deferred.resolve(response.value)
    .fail -> deferred.reject()

    deferred

  evaluate_formula: (option) ->
    if !@data.options[option]? || @workflow.formula_exists(@data.options[option])
      @workflow.evaluate_step_formula(@step.type(), @step.data.permalink, option, @locals())
    else
      $.Deferred().resolve(@data.options[option])

  evaluate_formulas: ->
    method_arguments = arguments

    new Promise (resolve, reject) =>
      promises = []

      for option in method_arguments
        promises.push @evaluate_formula(option)

      Promise.all(promises).then (values) ->
        output = {}

        for option, index in method_arguments
          output[option] = values[index]

        resolve(output)

  # Finds submissions based on a submission select or formula
  submission_selector: (query = {}) ->
    new Promise (resolve, reject) =>
      query.field ?= "field"
      query.submission ?= "submission"
      query.submission_type ?= "#{query.submission}_type"
      query.form ?= "#{query.submission}_form"

      Workflows.Helpers.submissions_from_variables(@, query.submission, query.form, query.submission_type)
      .then resolve
      .catch (response) =>
        if !response.form?
          return reject "Form `#{query.form}` not found"

        if !response.submissions? || response.submissions.length == 0
          return reject "Submission `#{query.submission}` not found"

        reject response

  submission_field_selector: (query = {}) ->
    query.field ?= "field"
    query.submission ?= "submission"
    query.submission_type ?= "#{query.submission}_type"
    query.form ?= "#{query.submission}_form"

    new Promise (resolve, reject) =>
      @submission_selector(query).then (submissions) =>
        return resolve([]) if submissions.length == 0

        @evaluate_formula(query.field).then (field_reference) =>
          output = []

          for submission in submissions
            field = submission.fields.find(field_reference)
            output.push(field) if field?

          if output.length == 0
            return reject("Field `#{query.field}` not found")

          resolve(output)
        .catch reject
      .catch reject

  submission_field_value_selector: (query = {}) ->
    new Promise (resolve, reject) =>
      @submission_field_selector(query).then (fields) =>
        output = []

        for field in fields
          output.push(field.value())

        resolve(output)

  # Finds values that can either by submission-specific (fields) or generated by a formula
  submission_value_selector: (lookup_variable, form_variable, lookup_type_variable) ->
    form_variable ?= "#{lookup_type_variable}_form"
    lookup_type_variable ?= "#{lookup_variable}_type"

    Workflows.Helpers.field_values_from_variables(
      @, lookup_variable, form_variable, lookup_type_variable
    )

  # Field data passed in the format "submission_variable_name:field_variable_name"
  decode_field_data: (value) ->
    object = @decode_submission_scoped_data(value)
    { submission: object.submission, field: object.value }

  decode_submission_scoped_data: (value) ->
    return { submission: "submission_context", value: value } if !value? || value.length == 0

    data = value.split(":")

    if data.length == 1
      { submission: "submission_context", value: data[0] }
    else
      { submission: data[0], value: data[1] }

  submission: -> @step.workflow.submission

  log: (content) -> @step.log(content)

  error: (content) ->
    @step.error(content)
    console.log(@locals()) if WorkflowStepInstance.dump_errors == true
    undefined

  @blank_submission_request: (request) ->
    @blank_result(request)

  @blank_result: (result) ->
    !result? || (Array.isArray(result) && result.length == 0)

class window.WorkflowAction
  constructor: (@workflow, @data) ->
    @data.parent_permalink ||= "ready"
    @workflow.subscribe(@data.parent_permalink, @)

  run: (local_variables = {}) ->
    submission_id = App.Helpers.Strings.right_pad(@workflow.submission._unique_id(), 15)
    step_permalink = App.Helpers.Strings.right_pad(@data.permalink, 36)
    step_class = App.Helpers.Strings.right_pad("Action", 10)
    step_type = App.Helpers.Strings.right_pad(@data.step_type, 36)

    console.log("#{@depth_pipes()}⨍ #{submission_id}\t#{step_class}\t#{step_type}\t#{step_permalink}")

    step_method = window.Workflows.Actions[@data.step_type]

    if !step_method?
      throw "Missing Workflow Action: `#{@data.step_type}`"

    step_method(@instance(local_variables))

  instance: (local_variables = {}) ->
    new WorkflowStepInstance(@, local_variables)

  type: -> "action"
  step_type: -> @data.step_type

  log: (content) -> console.log(@depth_pipes() + "    • " + content)
  error: (content) -> if console.error? then console.error("    ✕ " + content) else console.log("    ✕ " + content)

  depth: -> @workflow.depth(@)
  depth_pipes: ->
    depth = @depth()

    return "" if depth == 0

    chars = ""
    chars += "│  " for [0...depth]
    chars

class window.WorkflowCondition
  constructor: (@workflow, @data) ->
    @data.parent_permalink ||= "ready"
    @workflow.subscribe(@data.parent_permalink, @)

  run: (local_variables = {}) ->
    deferred = $.Deferred()

    submission_id = App.Helpers.Strings.right_pad(@workflow.submission._unique_id(), 15)
    step_permalink = App.Helpers.Strings.right_pad(@data.permalink, 36)
    step_class = App.Helpers.Strings.right_pad("Condition", 10)
    step_type = App.Helpers.Strings.right_pad(@data.step_type, 36)

    console.log("#{@depth_pipes()}⨍ #{submission_id}\t#{step_class}\t#{step_type}\t#{step_permalink}")

    instance = @instance(local_variables)

    step_method = window.Workflows.Conditions[@data.step_type]

    if !step_method?
      throw "Missing Workflow Condition: `#{@data.step_type}`"

    step_method(instance)
    .done (local_variables = {}, silent = false) =>
      if silent
        deferred.resolve()
      else
        instance.publish(@data.permalink, instance.locals()).done (execution_data = {}) ->
          deferred.resolve() unless execution_data.silent
    .fail =>
      deferred.reject()

    deferred

  instance: (local_variables = {}) ->
    new WorkflowStepInstance(@, local_variables)

  type: -> "condition"
  step_type: -> @data.step_type

  log: (content) -> console.log(@depth_pipes() + "    • " + content)
  error: (content) -> if console.error? then console.error("    ✕ " + content) else console.log("    ✕ " + content)

  depth: -> @workflow.depth(@)
  depth_pipes: ->
    depth = @depth()

    return "" if depth == 0

    chars = ""
    chars += "│  " for [0...depth]
    chars

class window.WorkflowTrigger
  constructor: (@workflow, @data) ->
    step_method = window.Workflows.Triggers[@data.step_type]

    if !step_method?
      throw "Missing Workflow Trigger: `#{@data.step_type}`"

    step_method(@instance())

  run: (local_variables = {}) ->
    deferred = $.Deferred()

    submission_id = App.Helpers.Strings.right_pad(@workflow.submission._unique_id(), 15)
    step_permalink = App.Helpers.Strings.right_pad(@data.permalink, 36)
    step_class = App.Helpers.Strings.right_pad("Trigger", 10)
    step_type = App.Helpers.Strings.right_pad(@data.step_type, 36)

    console.log("#{@depth_pipes()}⨍ #{submission_id}\t#{step_class}\t#{step_type}\t#{step_permalink}")

    @workflow.publish(@data.permalink, local_variables)
    .done -> deferred.resolve()
    .fail -> deferred.reject()

    deferred

  instance: ->
    new WorkflowStepInstance(@)

  type: -> "trigger"
  step_type: -> @data.step_type

  log: (content) -> console.log(@depth_pipes() + "    • " + content)
  error: (content) -> if console.error? then console.error("    ✕ " + content) else console.log("    ✕ " + content)

  depth: -> @workflow.depth(@)
  depth_pipes: ->
    depth = @depth()

    return "" if depth == 0

    chars = ""
    chars += "│  " for [0...depth]
    chars
