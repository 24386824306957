
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.Secret
  constructor: (@field) ->
    @element = @field._element

    @secret_input = @element.querySelector(".SECRET_INPUT")

  load: ->
    if !@secret_input.disabled
      return Promise.resolve(@secret_input.value)

    @secret_input.placeholder = "Loading…"

    new Promise (resolve, reject) =>
      $.ajax
        url: Routes.reveal_submission_secret_path(@field.form().permalink(), @field.submission.permalink(), @field.permalink())
        type: "POST"
        dataType: "json"
        data: {
          authenticity_token: current_user.authenticity_token()
        }
      .done (response) =>
        @secret_input.value = response.value
        @secret_input.placeholder = ""
        @secret_input.disabled = false
        @element.classList.add("loaded")

        resolve(response.value)
      .fail reject
