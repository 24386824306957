
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Requests ?= {}

window.App.Helpers.Requests.request = (url, options = {}) ->
  new Promise (resolve, reject) ->
    ajax_options = options
    ajax_options.url = url

    $.ajax(ajax_options)
    .done (response) ->
      resolve(response)
    .fail (xhr) ->
      if xhr.responseJSON?
        reject(xhr.responseJSON)
      else
        reject(xhr.responseText)

window.App.request = App.Helpers.Requests.request
