
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}
window.App.Interface.Menu.SubMenu ?= {}

class window.App.Interface.Menu.SubMenu.BaseOption extends App.Interface.Menu.BaseOption
  constructor: (@menu, options = {}) ->
    @_children = undefined
    @_children_wrapper = undefined

    super(@menu, options)

    @callback => @_publish("callback")

  _generate_sandbox: ->
    super()

    @_subscribe "child_option.add", (event) =>
      @add(event.data.text, event.data, event.data.index)

    @_subscribe "text", (event) => @text(event.data.value)
    @_subscribe "update", (event) => @update(event.data)

  coords: ->
    @element[0].getBoundingClientRect()

  find_option_by_id: (id) ->
    return unless @_children?

    for child in @_children
      return child if child.id == id

      subchild = child.find_option_by_id(id)

      return subchild if subchild?

    undefined

  options: ->
    @_children

  # Includes options nested inside children, collapsed into one array.
  all_options: ->
    output = []

    for child in @_children
      output.push(child)
      output = output.concat child.all_options()

    output

  close_all_options: ->
    return unless @_children?

    for child in @_children
      child.close()
      child.close_all_options()

  _update_options: (children) ->
    @_children = []

    # The add method handles merging children with existing IDs
    for child_options in children
      @add(child_options.text, child_options)

    @_children

  _detach_options: ->
    return unless @_children?

    for child in @_children
      child.element.detach()

  _attach_options: ->
    return unless @_children? && @_children_wrapper?

    for child in @_children
      child.element.appendTo(@_children_wrapper)

  # Cache and reapply options that should be prefilled when passed to update()
  _cache_options: (options) ->
    @_element_options ?= {}

    cached_options = ["id", "text", "icon"]

    for name in cached_options
      if options[name]?
        @_element_options[name] = options[name]

    for name in cached_options
      options[name] ?= @_element_options[name]

    options

  callback: (callback) ->
    return unless callback?

    super(callback)

    if @button?
      @button.addEventListener "click", (event) =>
        event.preventDefault()
        callback(event, @)

  update: (options) ->
    @_generate(options)
    @_publish "update", @serialize()

    @

  add: (text, options_or_callback, index) ->
    if typeof options_or_callback == "function"
      options = {}
      options.callback = options_or_callback
    else
      options = options_or_callback

    options ?= {}
    options.text = text

    newly_created_child = true

    if options.id?
      child = @find_option_by_id(options.id)

      if child?
        child.update(options)
        child.element.detach()

        newly_created_child = false

    child ?= new App.Interface.Menu.SubMenu.ChildOption(@menu, @, options)

    if index?
      @_children.splice(index, 0, child)

      if @_children_wrapper?
        children = Array.from(@_children_wrapper)

        @_children_wrapper.insertBefore(child.element, children[index])
    else
      @_children.push(child)

      if @_children_wrapper?
        @_children_wrapper.appendChild(child.element)

    if newly_created_child
      @_publish "child_option.add", child.serialize()

    child

# This method turns data.options into data.children. options makes more sense as an input parameter,
# but it makes less sense when a member of the child option "options" attribute.
window.App.Interface.Menu.SubMenu.parse_option_data = (data, allow_html = false) ->
  output = {}
  output.id = data.id
  output.text = data.text
  output.callback = data.callback

  if data.options? || data.children?
    children = data.options || data.children
    output.children = App.Interface.Menu.SubMenu.parse_actions(children, allow_html)

  output

window.App.Interface.Menu.SubMenu.parse_actions = (actions = [], allow_html = false) ->
  output = []

  for action in actions
    parsed_action = App.Interface.Menu.SubMenu.parse_action(action, allow_html)
    output.push(parsed_action) if parsed_action?

  output

window.App.Interface.Menu.SubMenu.parse_action = (action = {}, allow_html = false) ->
  return if action.active == false
  return { separator: true } if action.separator == true

  output = {}

  output.id = action.id

  if action.html? && allow_html
    output.html = action.html
  else
    output.icon = action.icon
    output.text = action.text

  if action.callback?
    output.callback = action.callback

  output.disabled = (action.disabled == true)

  if action.options? || action.children?
    children = action.options || action.children

    output.children = App.Interface.Menu.SubMenu.parse_actions(children, allow_html)

  if action.href?
    output.href = action.href
  else
    output.href = "#"

  if action.target?
    output.target = action.target

  output
