
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Group ?= {}

class window.App.Models.Group.Object
  constructor: (@data) ->
    undefined

  name: ->
    @data.name

  permalink: ->
    @data.permalink

  users: ->
    @_users_promise ?= new Promise (resolve, reject) =>
      Promise.all([@schema(), App.Models.User.schema()]).then =>
        usernames = @data.users.map (user) -> user.username

        resolve(new App.Models.Group.Users(usernames, @data.type))
      .catch reject

    @_users_promise

  _apply_schema: (data) ->
    @data = data

  _request_schema: ->
    new Promise (resolve, reject) =>
      if App.is_child
        App.parent.publish("schema.load.group", group: @permalink())
        .then resolve
        .fail reject
      else
        $.ajax
          url: @data.urls.self
        .done resolve
        .fail (response) -> reject(response.responseJSON)

  schema: ->
    @_schema_promise ?= new Promise (resolve, reject) =>
      @_request_schema()
      .then (response) =>
        @data = response
        resolve(@)
      .catch (response) ->
        @_schema_promise = undefined
        reject(response)

    @_schema_promise

window.App.Models.Group._models = {}

window.App.Models.Group.new = (permalink) ->
  if App.Models.Group._models[permalink]?
    return App.Models.Group._models[permalink]

  group = new App.Models.Group.Object({ permalink: permalink })

  App.Models.Group._models[permalink] = group

  group

window.App.Models.Group._request_schema = ->
  new Promise (resolve, reject) =>
    if App.is_child
      App.parent.publish("schema.load.groups")
      .then resolve
      .fail reject
    else
      $.ajax
        url: "/api/rest/v1/groups/"
      .done resolve
      .fail (response) -> reject(response.responseJSON)

window.App.Models.Group.schema = ->
  App.Models.Group._schema_promise ?= new Promise (resolve, reject) =>
      App.Models.Group._request_schema()
      .then (response) ->
        for data in response.groups
          model = App.Models.Group.new(data.permalink)
          model._apply_schema(data)

        resolve(App.Models.Group)
      .catch (response) ->
        App.Models.Group._schema_promise = undefined
        reject(response)

    App.Models.Group._schema_promise

window.App.Models.Group.find = (value) ->
  if App.Models.Group._models[permalink]?
    return App.Models.Group._models[permalink]

  groups = Object.values(App.Models.Group._models)
  return group if group.name() == value for group in groups

  undefined

window.App.Models.Group.all = ->
  Object.values(App.Models.Group._models)

window.App.Models.Group.any = ->
  App.Models.Group.all().length > 0

window.App.Models.Group.first = -> App.Models.Group.all()[0]
window.App.Models.Group.second = -> App.Models.Group.all()[1]
window.App.Models.Group.third = -> App.Models.Group.all()[2]
window.App.Models.Group.last = ->
  values = App.Models.Group.all()
  values[values.length - 1]

window.App.Models.Group.public = ->
  for group in App.Models.Group.all()
    return group if group.data.type == "public"

  undefined

window.App.Models.Group.members = ->
  for group in App.Models.Group.all()
    return group if group.data.type == "members"

  undefined

window.App.Models.Group.count = ->
  new Promise (resolve) ->
    App.Models.Group.schema().then ->
      resolve(App.Models.Group.all().length)

window.Group = App.Models.Group
