import 'app/models/submission/field/manager'
import 'app/models/submission/field/errors'
import 'app/models/submission/field/object'
import 'app/models/submission/field/pages'
import 'app/models/submission/field/menus'
import 'app/models/submission/draft/object'
import 'app/models/submission/helpers/generators'
import 'app/models/submission/helpers/modals'
import 'app/models/submission/helpers/sandboxes'
import 'app/models/submission/helpers/versioning'




window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}

# Isolate Custom Scripts in IFrame sandboxes
window.App.Models.Submission.sandbox_scripts = false

# Parameters of the current page which should be passed to any redirected page
window.App.Models.Submission._sticky_parameters = ["iframe", "navbar"]

# Applies specific GET parameters of the current page to a new URL to persist them
window.App.Models.Submission._apply_sticky_parameters = (url_string) ->
  params = App.params()

  for parameter in App.Models.Submission._sticky_parameters
    if params[parameter]?
      if url_string.indexOf("?") == -1
        url_string += "?#{parameter}=#{params[parameter]}"
      else
        url_string += "&#{parameter}=#{params[parameter]}"

  url_string

window.App.Models.Submission.visible = ->
  visible_modal = App.Interface.Modal.visible_modal()

  for form in App.Models.Form.all()
    for submission in form.submissions.all()
      display = submission.display()

      return submission if display == "page" && !visible_modal?
      return submission if visible_modal == submission.modal

  undefined

window.App.Models.Submission.find_locally_by_element = (element) ->
  return unless element?

  element = element[0] if App.Helpers.Elements.is_jquery(element)

  if element.dataset.formPermalink?
    form = App.Models.Form.new(element.dataset.formPermalink)

    if !element.classList.contains("initialized")
      return form.submissions.initialize_element($(element))

    for submission in form.submissions.all()
      return submission if submission._element == element

  for form in App.Models.Form.all()
    for submission in form.submissions.all()
      return submission if submission._element == element

  undefined

window.App.Models.Submission.valid_permalink = (value) ->
  return unless value? && App.Helpers.Objects.isString(value)
  value.length == 10

class window.App.Models.Submission.Object
  # Returns the mode that the submission is displayed in
  display: ->
    return @_display_cache if @display_cache?

    if @element?
      if @element.hasClass("modal-container")
        @_display_cache = "modal"
      else if @element.hasClass("page-container")
        @_display_cache = "page"
      else
        @_display_cache = undefined
    else
      @_display_cache = undefined

    @_display_cache

  constructor: (element, @form_permalink, @options = {}) ->
    @instance_id = App.Helpers.Generators.uuid()

    @element = $(element)
    @_element = @element[0]
    @_element.classList.add("initialized")
    @_element.dataset.instance = @instance_id

    @form_element = @element.find("form").first()
    @_form_element = @form_element[0]

    @form().submissions._add(@)

    @submission_permalink = @_form_element.dataset.submission
    @submission_deleted = @_element.dataset.deleted == "true"

    if @_form_element.dataset.generated? && @_form_element.dataset.generated.length > 0
      @last_merged = new Date(@_form_element.dataset.generated)
    else
      @last_merged = new Date()

    @facility_permalink = @_form_element.dataset.facility

    @sandboxes = new App.Frame.Sandbox.Manager(@, "submission.#{@instance_id}", (blob) => App.Frame.Sandbox.from_submission(@, blob))
    @sandboxes.on "start", => App.Models.Submission.Helpers.Sandboxes.register(@)

    if @display() == "modal"
      @sandboxes.on "add", (sandbox) => @modal.sandboxes.add(sandbox)

    @callbacks = new CallbackManager(@)
    @callbacks.onetime_event("ready", "available")

    @fields = new App.Models.Submission.Field.Manager(@)
    @fields._apply_options()

    @pages = new App.Models.Submission.Field.Pages(@)
    @draft = new App.Models.Submission.Draft.Object(@)
    @modals = new App.Models.Submission.Helpers.Modals(@)
    @versioning = new App.Models.Submission.Helpers.Versioning(@)

    switch @display()
      when "modal"
        if @options.modal?
          @modal = @options.modal
        else
          @modal = App.Interface.Modal.from_html(@element)
      when "page"
        App.Events.Submissions.Pages.calculate_page_buttons(@)

        @pages.on "change", => App.Events.Submissions.Pages.calculate_page_buttons(@)

    App.Helpers.Elements.reflow_submission(@form_element)

    Promise.all([@_run_script(), @_run_workflows()]).then =>
      @callbacks.trigger("ready")
      @available(true) if @opened()

    if @display() == "modal" && !App.is_mobile()
      @modal.callbacks.on "show", => @focus()

    @form_element.find(".MESSAGE_FIELD, .CHECKBOX_FIELD .CHECKBOX_DESCRIPTION").detect_links()

    @after_update => @modals.history().unload() if @modals._history_modal?

    PubSub.publish("submission.loaded.#{@form().permalink()}", {
      submission: @
    })

    App.trigger("submission.load", @)

  form: -> App.Models.Form.new(@form_permalink)

  _unique_id: ->
    if @submission_permalink?
      @submission_permalink
    else
      "#{@form().permalink()}:#{@form().submissions.submission_data.indexOf(@)}"

  # Checks if the form is currently being submitted
  _loading: ->
    @_request_loading == true

  # Locks the form, preventing any submissions while a submission request is loading
  _start_loading: ->
    add_loader @form_element.find("button[type=submit], input[type=submit]")

    @_request_loading = true

  # Unlocks the form, allowing new submissions when a submission request is finished
  _finish_loading: ->
    remove_loader @form_element.find("button[type=submit], input[type=submit]")

    @_request_loading = false

  _submit_url: ->
    if @persisted()
      base_url = Routes.update_submission_path(@form().permalink(), @submission_permalink, format: "json")
    else
      base_url = Routes.create_submission_path(@form().permalink(), format: "json")

    App.Models.Submission._apply_sticky_parameters(base_url)

  _submit_method: ->
    if @persisted()
      "PATCH"
    else
      "POST"

  _submit_data: ->
    # IE < 10 does not support FormData. It's used instead of serialize() because it can handle
    # sending file uploads over AJAX and not just plain text. Detect browser and polyfill.
    # Send files in advance on earlier versions of IE, which should eventually be better supported.
    if window.FormData?
      form_data = new FormData(@_form_element)

      for field in @fields.all()
        field_data = field.data()

        if field_data?
          submission_field_data = { submission: {} }
          submission_field_data.submission[field.field_id] = field_data

          App.Helpers.Forms.merge_object_with_form_data(form_data, submission_field_data)

      form_data.set("last_merged", @last_merged.toISOString())

      return form_data
    else
      # TODO Warn that file uploads aren't supported in IE9
      return @form_element.serialize()

  _submit_options: (extra_options) ->
    options = {
      url: @_submit_url(),
      type: @_submit_method(),
      data: @_submit_data(),
      processData: false,
      contentType: false,
      cache: false
    }

    if extra_options?
      for key, value of extra_options
        if key == "data"
          for data_key, data_value of value
            if options.data instanceof FormData
              options.data.set(data_key, data_value)
            else
              options.data[data_key] = data_value
        else
          options[key] = value

    options

  _submit_request: (options) ->
    options = @_submit_options(options)

    return @_queue_submission_request() if @_loading()

    @_start_loading()

    $.ajax(options)
    .done (response) =>
      @_finish_loading()

      @persist(response.submission_permalink)

      @_submit_submission_request_queue()
    .fail (xhr) =>
      @_finish_loading()

      @_load_submit_request_errors(xhr)

  _load_submit_request_errors: (xhr) ->
    switch xhr.status
      when 400 # Field Errors
        @fields._load_errors(xhr.responseJSON.errors)

        if @modal?
          @modal.container.scrollTop = 0
          @show()
      when 409 # Merge Conflict (Changed Since Loading)
        @versioning.load_conflicts(xhr.responseJSON)
        @last_merged = new Date()

        if @modal?
          @modal.container.scrollTop = 0
          @show()
      else
        App.Errors.response_error(xhr)

  save: (options = {}) ->
    deferred = $.Deferred()

    submission_is_new = !@persisted()

    @fields.empty_errors()
    @versioning.resolve_conflicts()

    @_last_save_options = options

    @callbacks.trigger_synchronous("validate").then =>
      @_field_validation_callbacks().then =>
        @_before_submit_callbacks().then =>
          @_relative_before_submit_callbacks(submission_is_new).then =>
            @_submit_request(options.request)
            .done (response) =>
              @versioning.conflicts_resolved()

              @_after_submit_callbacks(response).then =>
                if options.publish != false
                  PubSub.publish("submission.submitted.#{@form().permalink()}.#{@permalink()}", @)

                  if response.submission?
                    PubSub.publish("submission.submitted_data.#{@form().permalink()}.#{@permalink()}", response.submission)

                @_relative_after_submit_callbacks(submission_is_new, response).then =>
                  @_unbind_create_callbacks() if submission_is_new
                  @_refresh_schemas() if !submission_is_new

                  if options.publish != false
                    action = if submission_is_new then "created" else "updated"
                    PubSub.publish("submission.#{action}.#{@form().permalink()}.#{@permalink()}", @)

                  deferred.resolve()
            .fail (response) =>
              @_failed_submit_callbacks(response).then =>
                @_relative_failed_submit_callbacks(submission_is_new, response).then =>
                  deferred.reject()
      .catch ->
        deferred.reject()
        undefined
    .catch ->
      deferred.reject()
      undefined

    deferred

  submit: (arg1, arg2 = {}) ->
    args = App.Helpers.Arguments.overload(
      arg1, arg2, [
        [["event", window.Event], ["options", window.Object]],
        [["options", window.Object]],
      ]
    )

    event = args.event
    options = args.options

    if event? && event.preventDefault?
      event.preventDefault()

    @save(options).done =>
      return if options.background == true

      @hide()

      @redirect(false)

  _field_validation_callbacks: ->
    new Promise (resolve, reject) =>
      @fields.validate()
      .then => if @fields.any_errors() then reject() else resolve()
      .catch reject

  _before_submit_callbacks: ->
    new Promise (resolve, reject) =>
      @callbacks.trigger_synchronous("before_submit", @)
      .then resolve
      .catch resolve

  # persisted() changes to true after submission, so the original value is sent.
  _relative_before_submit_callbacks: (new_submission) ->
    callback = (if new_submission then "before_create" else "before_update")

    new Promise (resolve, reject) =>
      @callbacks.trigger_synchronous(callback, @)
      .then resolve
      .catch resolve

  _after_submit_callbacks: (response) ->
    new Promise (resolve, reject) =>
      @callbacks.trigger_synchronous("after_submit", @, response)
      .then resolve
      .catch resolve

  # persisted() changes to true after submission, so the original value is sent.
  _relative_after_submit_callbacks: (new_submission, response) ->
    callback = (if new_submission then "after_create" else "after_update")

    new Promise (resolve, reject) =>
      @callbacks.trigger_synchronous(callback, @, response)
      .then resolve
      .catch resolve

  _failed_submit_callbacks: (response) ->
    new Promise (resolve, reject) =>
      @callbacks.trigger_synchronous("before_submit", @, response)
      .then resolve
      .catch resolve

  # persisted() changes to true after submission, so the original value is sent.
  _relative_failed_submit_callbacks: (new_submission, response) ->
    callback = (if new_submission then "failed_create" else "failed_update")

    new Promise (resolve, reject) =>
      @callbacks.trigger_synchronous(callback, @, response)
      .then resolve
      .catch resolve

  _unbind_create_callbacks: ->
    @callbacks.remove("before_create")
    @callbacks.remove("after_create")
    @callbacks.remove("failed_create")

  _refresh_schemas: ->
    return unless @persisted() && @form().submissions.submission_queried(@permalink())

    @form().submissions.query_by_permalink(@permalink()).then (submission) =>
      submission.refresh()

  # This function can be called without any parameters to run local and server-side validations.
  # This should not be used in the save() request chain as validation already happens server-side on save.
  # Using this method would fire two requests for no reason.
  validate: (method) ->
    return @callbacks.callback("validate", method) if method?

    deferred = $.Deferred()

    @fields.empty_errors()

    @callbacks.trigger_synchronous("validate").then =>
      @_field_validation_callbacks().then =>
        @_before_submit_callbacks().then =>
          @_relative_before_submit_callbacks(!@persisted()).then =>
            if @persisted()
              validation_path = Routes.validate_submission_path(@form().permalink(), @permalink())
            else
              validation_path = Routes.validate_new_submission_path(@form().permalink())

            options = @_submit_options()
            options.url = validation_path
            options.type = "POST"

            $.ajax(options)
            .done (response) -> deferred.resolve()
            .fail (xhr) =>
              @_load_submit_request_errors(xhr)

              deferred.reject()
          .catch ->
            deferred.reject()
            undefined
      .catch ->
        deferred.reject()
        undefined
    .catch ->
      deferred.reject()
      undefined

    deferred

  # Shortcut methods to set validations
  before_create: (method) ->
    return if @persisted()
    @callbacks.callback "before_create", method
  after_create:  (method) ->
    return if @persisted()
    @callbacks.callback "after_create", method
  failed_create: (method) ->
    return if @persisted()
    @callbacks.callback "failed_create", method

  before_submit: (method) -> @callbacks.callback "before_submit", method
  after_submit:  (method) -> @callbacks.callback "after_submit", method
  failed_submit: (method) -> @callbacks.callback "failed_submit", method

  before_update: (method) -> @callbacks.callback "before_update", method
  after_update:  (method) -> @callbacks.callback "after_update", method
  failed_update: (method) -> @callbacks.callback "failed_update", method

  on: (event, callback) -> @callbacks.on(event, callback)

  _redirect_url: ->
    if @custom_redirect_url?
      return @custom_redirect_url

    if @_element.dataset.redirect? && @_element.dataset.redirect.length > 0
      return atob(@_element.dataset.redirect)

    if App.params("iframe")?
      App.Models.Submission._apply_sticky_parameters(
        Routes.submitted_path(@form().permalink())
      )

    App.Models.Submission._apply_sticky_parameters(
      Routes.submitted_path(@form().permalink(), { submission: @submission_permalink })
    )

  disable_redirect: -> @_redirect_disabled = true
  enable_redirect: -> @_redirect_disabled = false
  redirect_enabled: -> @_redirect_disabled != true
  redirect_disabled: -> @_redirect_disabled == true

  redirect: (url) ->
    if url == true || url == false
      force_redirect = url
      url = undefined
    else
      force_redirect = true

    return @custom_redirect_url = url if url?
    return if !force_redirect && (@redirect_disabled() || @display() != "page")

    window.location.replace @_redirect_url()

  _queue_submission_request: (data) ->
    data ?= @_submit_data()

    queued_request = { deferred: $.Deferred(), data: data }

    @_request_queue ?= []
    @_request_queue.push(queued_request)

    queued_request.deferred

  _submit_submission_request_queue: ->
    return if !@_request_queue? || @_request_queue.length == 0

    queued_request = @_request_queue[0]
    @_request_queue.shift()

    options = @_submit_options({ data: queued_request.data })

    @_submit_request(options).done (response) =>
      queued_request.deferred.resolve(response)

    queued_request

  # Removes the submission's form elements from the page
  _remove_element: ->
    $(document).off(".#{@instance_id}")
    $(@element).off(".#{@instance_id}")
    $(@form_element).off(".#{@instance_id}")

    @element.remove()

  _has_script: -> @_element.dataset.hasScript == "true"
  _script_domain: -> "https://d2417grndib5wc.cloudfront.net/"
  _script_name: -> "form_#{@form().permalink()}_submission_script"

  _run_script: ->
    return Promise.resolve() if @_script_run
    return Promise.resolve() unless @_has_script()
    @_script_run = true

    new Promise (resolve, reject) =>
      if App.Models.Submission.sandbox_scripts
        sandbox = @sandboxes.new()
        sandbox.channel.publish("submission.script.run", @serialize())
        return resolve(sandbox)

      script_name = @_script_name()
      script_variables = { submission: @, self: @ }

      if window[script_name]?
        window[script_name].call(@, script_variables)
        return resolve()

      $.ajax
        url: @_script_url()
        dataType: "script"
      .done (response) =>
        return reject() unless window[script_name]?
        window[script_name].call(@, script_variables)
        return resolve()
      .fail => reject()

  # @form()._script_blob(@).then (blob) =>
  #   sandbox = @sandboxes.new(blob)
  #   sandbox.channel.publish("submission.script.run", @serialize())
  #   resolve(sandbox)
  # .catch =>
  #   sandbox = @sandboxes.new()
  #   sandbox.channel.publish("submission.script.run", @serialize())
  #   resolve(sandbox)

  _script_url: ->
    name = @_element.dataset.scriptName

    if name? && name != ""
      @_script_domain() + name + ".js"
    else
      undefined

  _has_workflows: -> @_element.dataset.hasWorkflows == "true"
  _run_workflows: ->
    return Promise.resolve() if @_workflows_run
    return Promise.resolve() unless @_has_workflows()

    @workflows ?= new WorkflowManager(@)
    @_workflows_run = true

    @form()._run_submission_workflows(@)

  permalink: -> @submission_permalink

  # Returns true if the submission is DIRECTLY visible (e.g. no submissions or modals above it)
  visible: ->
    display = @display()
    visible_modal = App.Interface.Modal.visible_modal()

    return !visible_modal? if display == "page"
    return visible_modal == @modal if display == "modal"

  # Returns true if the submission is not directly hidden, ignoring fixed elements.
  opened: ->
    display = @display()

    return true if display == "page"

    return true if display == "modal" && @modal.visible()

    false

  hide: ->
    return unless @modal?
    @modal.hide()

  show: ->
    return unless @modal?
    @available(true)
    
    value = @modal.show()

    App.Helpers.Elements.Multiples.reflow(@_form_element)

    value

  destroyed: -> @submission_deleted
  persisted: -> @submission_permalink?

  available: (status) ->
    if status != true || @_ever_visible == true
      return @_ever_visible == true

    @callbacks.trigger("available")
    @_ever_visible = true

  persist: (permalink) ->
    @submission_permalink ?= permalink
    field.persist() for field in @fields.all()

    @last_merged = new Date()

    true

  unsaved_changes: ->
    return true if field.unsaved_changes() for field in @fields.all()

    false

  new_submission_modal: ->
    !@persisted() && !@draft.persisted() && @modal?

  # Focus on the submission's first input.
  # Some field types do not have inputs, iterate until one is found.
  focus: ->
    for field in @fields.all()
      return if field.focus()

  focus_on_errors: ->
    for field in @fields.all()
      if field.errors.any()
        field.focus()
        return

  refresh: -> App.Helpers.Elements.reflow_submission(@form_element)

  # Removes the submission module and all unsaved changes
  remove: -> @form().submissions.remove(@submission_permalink)

  # Initialize an identical submission frontend
  clone: ->
    new_element = $("<div/>").append(@element.clone()).html()
    new_submission = @form().submissions.initialize_html(new_element, true)
    new_submission.submission_permalink = undefined
    new_submission

  # Sends a request to the server to delete the submission
  destroy: ->
    request = new Promise (resolve, reject) =>
      submission_page = App.context(Routes.submission_path(@form().permalink(), @submission_permalink))

      # Preload form responses page
      App.window_manager.load(Routes.submissions_path(@form().permalink())) if submission_page?

      $.ajax
        url: Routes.delete_submission_path({ permalink: @form().permalink(), submission_permalink: @submission_permalink, format: "json" })
        type: "DELETE"
        data: { authenticity_token: current_user.authenticity_token() }
      .done (response) =>
        if submission_page?
          # Allow the user to quickly switch pages after pressing "Destroy" without being
          # rubber-banded back to the responses page after the request finishes.
          if submission_page.visible()
            App.window_manager.open(Routes.submissions_path(@form().permalink())).then ->
              submission_page.destroy()
              resolve()
          else
            submission_page.destroy()
            resolve()
        else
          resolve()
      .fail (xhr) ->
        App.Errors.response_error(xhr, for: ["unauthorized"])
        reject(xhr.responseJSON)

    request.then =>
      @submission_deleted = true
      PubSub.publish("submission.destroyed.#{@form().permalink()}.#{@permalink()}", @)

    request

  # Sends a request to the server to restore the submission
  restore: ->
    request = new Promise (resolve, reject) =>
      submission_page = App.context(Routes.submission_path(@form().permalink(), @permalink()))

      # Preload form responses page
      if submission_page? && App.windowing_enabled()
        App.window_manager.load(Routes.submissions_path(@form().permalink()))

      $.ajax
        url: Routes.restore_submission_path({ permalink: @form().permalink(), submission_permalink: @permalink(), format: "json" })
        type: "POST"
        data: { authenticity_token: current_user.authenticity_token() }
      .done (response) =>
        if submission_page?
          # Allow the user to quickly switch pages after pressing "Destroy" without being
          # rubber-banded back to the responses page after the request finishes.
          if submission_page.visible()
            App.window_manager.open(Routes.submissions_path(@form().permalink())).then ->
              submission_page.destroy()
              resolve()
          else
            submission_page.destroy()
            resolve()
        else
          resolve()
      .fail (response) ->
        App.Errors.response_error(xhr, for: ["unauthorized"])
        reject(xhr.responseJSON)

    request.then =>
      @submission_deleted = false
      PubSub.publish("submission.restored.#{@form().permalink()}.#{@permalink()}", @)

    request

  procedure: (endpoint, data) ->
    new Promise (resolve, reject) =>
      @form().procedures.run(endpoint, @, data)
      .then resolve
      .catch reject

  follow: ->
    new Promise (resolve, reject) =>
      $.ajax
        url: Routes.subscribe_submission_path(@form().permalink(), @permalink())
        type: "POST",
        data: { authenticity_token: current_user.authenticity_token() }
      .done -> resolve()
      .fail -> reject()

  unfollow: ->
    new Promise (resolve, reject) =>
      $.ajax
        url: Routes.unsubscribe_submission_path(@form().permalink(), @permalink())
        type: "DELETE"
        data: { authenticity_token: current_user.authenticity_token() }
      .done -> resolve()
      .fail -> reject()

  follow_toggle: (status) ->
    if status != true
      @unfollow()
    else
      @follow()

  user: -> current_user
  submission: -> @

  data: ->
    return {} unless @fields?

    data = {}
    data[field.column_name] = field.value() for field in @fields.all()
    data

  unsaved_data: ->
    return {} unless @fields?
    return @data() unless @persisted()

    data = {}

    for field in @fields.all()
      if field.unsaved_changes()
        data[field.column_name] = field.value()

    data

  values: -> @data()

  serialize: ->
    data = {}
    data.instance_id = @instance_id
    data.permalink = @permalink()
    data.form_permalink = @form().permalink()

    data.display = @display()
    data.visible = @visible()

    data.script = {}
    data.script.exists = @_has_script()
    data.script.url = @_script_url()

    data.draft = @draft.serialize()
    data.fields = @fields.serialize()
    data.pages = @pages.serialize()
    data.modal = @modal.serialize() if @display() == "modal"

    data

App.Models.bind_function ".SUBMISSION", (element) ->
  return if element.classList.contains("initialized")
  form = App.Models.Form.new(element.dataset.formPermalink)
  form.submissions.initialize_element(element)
