
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Helpers ?= {}

class window.App.Models.Submission.Helpers.Modals
  constructor: (@submission) ->

  history: ->
    return unless @submission.persisted()

    @_history_modal ?= App.Interface.Modal.from_url(
      Routes.submission_history_path(
        @submission.form().permalink(),
        @submission.permalink(),
        display: "modal"
      )
    )

    @_history_modal

  sharing: ->
    return unless @submission.persisted()

    if !@_sharing_modal?
      @_sharing_modal = App.Interface.Modal.from_url(
        Routes.share_submission_path(
          @submission.form().permalink(),
          @submission.permalink(),
          display: "modal"
        )
      )

      @_sharing_modal.on "load", =>
        new App.Elements.ResponseShare(@submission, @_sharing_modal)
        @_sharing_modal.element.querySelector(".new_submission_grant_email_address").focus()

    @_sharing_modal
