
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Compatibility ?= {}

window.App.Helpers.Compatibility.is_ie = (->
  agent = window.navigator.userAgent

  return true if agent.indexOf("MSIE") != -1
  return true if !!agent.match(/Trident\/7\./)

  false
)()
