
$ ->
  toggle_hotkeys = true
  if App.current_user? && (!App.current_user.config("modal_toggle_hotkeys"))
    toggle_hotkeys = false

  if toggle_hotkeys
    Hotkey.bind ["ESCAPE"],
      if: ->
        return false if App.Interface.Modal.visible_modal()?

        table = App.Elements.Tables.visible()
        return false unless table?
        return false unless table.element.classList.contains("app_response_table")
        return false unless table.selections.any()

        App.Helpers.Hotkeys.data.table = table

        true
      do: ->
        table = App.Helpers.Hotkeys.data.table
        table.selections.deselect_all_existing()

    Hotkey.bind ["CTRL", "A"],
      if: ->
        table = App.Elements.Tables.visible()
        return false unless table?
        return false unless table.element.classList.contains("app_response_table")
        App.Helpers.Hotkeys.data.table = table

        true
      do: ->
        table = App.Helpers.Hotkeys.data.table
        table.selections.select_all_existing()

    Hotkey.bind ["CTRL", "N"],
      if: ->
        table = App.Elements.Tables.visible()
        return false unless table?
        return false unless table.element.classList.contains("app_response_table")
        return false unless table.has_permission("create")
        App.Helpers.Hotkeys.data.table = table

        true
      do: ->
        table = App.Helpers.Hotkeys.data.table
        table.form().submissions.open_new()

    Hotkey.bind ["CTRL", "ALT", "N"],
      if: ->
        table = App.Elements.Tables.visible()
        return false unless table?
        return false unless table.element.classList.contains("app_response_table")
        return false unless table.has_permission("create")
        App.Helpers.Hotkeys.data.table = table

        true
      do: ->
        table = App.Helpers.Hotkeys.data.table
        table.form().submissions.open_new()

    Hotkey.bind ["CTRL", "M"],
      if: ->
        table = App.Elements.Tables.visible()
        return false unless table?
        return false unless table.element.classList.contains("app_response_table")
        return false unless table.has_permission("create")
        App.Helpers.Hotkeys.data.table = table

        true
      do: ->
        table = App.Helpers.Hotkeys.data.table
        table.form().submissions.open_new()
