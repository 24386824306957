
window.Workflows ?= {}
window.Workflows.Triggers ?= {}

window.Workflows.Triggers["submission:field:update"] = (step) ->
  field_data = step.decode_field_data(step.data.options.field)

  field_name = field_data.field
  submission_request = step.get_variable(field_data.submission)

  input_timeout_length = 250

  if !field_name? || field_name.length == 0
    submission.element.on "input change", "input, textarea, select", ->
      clearTimeout(step.storage.input_timeout) if step.storage.input_timeout?
      step.storage.input_timeout = setTimeout((-> step.run()), input_timeout_length)
  else
    submission_request.done (submission) ->
      field = submission.fields.find_by_column_name(field_name)

      if field?
        field.element.on "input change", "input, textarea, select", ->
          clearTimeout(step.storage.input_timeout) if step.storage.input_timeout?
          step.storage.input_timeout = setTimeout((-> step.run()), input_timeout_length)
      else
        step.error("Field `#{field_name}` not found")
        return $.Deferred().reject()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:load"] = (step) ->
  step.submission().on "available", -> step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:before_save"] = (step) ->
  step.get_variable("submission").then (submission) ->
    submission.validate -> step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:before_create"] = (step) ->
  step.get_variable("submission").then (submission) ->
    submission.validate ->
      return true if submission.persisted()
      step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:before_update"] = (step) ->
  step.get_variable("submission").then (submission) ->
    submission.validate ->
      return true unless submission.persisted()
      step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:after_save"] = (step) ->
  step.get_variable("submission").then (submission) ->
    submission.after_submit ->
      step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:after_create"] = (step) ->
  step.get_variable("submission").then (submission) ->
    submission.after_create -> step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:after_update"] = (step) ->
  step.get_variable("submission").then (submission) ->
    submission.after_update -> step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:page:open"] = (step) ->
  page_data = step.decode_field_data(step.data.options.page)

  page_name = page_data.field
  submission_request = step.get_variable(page_data.submission)

  submission_request.done (submission) ->
    if !page_name? || page_name.length == 0
      submission.pages.on "change", -> step.run()
    else
      submission.pages.on "change", (name) ->
        return unless page_name == name
        step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:page:next"] = (step) ->
  step.submission_selector().then (submissions) ->
    for submission in submissions
      submission.pages.on "next", -> step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:page:previous"] = (step) ->
  step.submission_selector().then (submissions) ->
    for submission in submissions
      submission.pages.on "previous", -> step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["submission:button:click"] = (step) ->
  field_data = step.decode_field_data(step.data.options.field)

  field_name = field_data.field
  submission_request = step.get_variable(field_data.submission)

  submission_request.done (submission) ->
    if !field_name? || field_name.length == 0
      submission.fields.on "button.click", -> step.run()
    else
      submission.fields.on "button.click", (field) ->
        return unless field_name == field.variable_name()
        step.run()

  $.Deferred().resolve()

window.Workflows.Triggers["function"] = (step) ->
  $.Deferred().resolve()
