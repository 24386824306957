import 'app/models/submission/field/helpers/attachment'
import 'app/models/submission/field/helpers/barcode'
import 'app/models/submission/field/helpers/button'
import 'app/models/submission/field/helpers/check_box'
import 'app/models/submission/field/helpers/date_time'
import 'app/models/submission/field/helpers/dropdown'
import 'app/models/submission/field/helpers/connection'
import 'app/models/submission/field/helpers/boolean'
import 'app/models/submission/field/helpers/secret'
import 'app/models/submission/field/helpers/signature'
import 'app/models/submission/field/helpers/text_area'
import 'app/models/submission/field/helpers/user'


window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}

class window.App.Models.Submission.Field.Object
  constructor: (@submission, element) ->
    @element = $(element)

    @_element = @element[0]
    @_element.classList.add("initialized")

    @type = @_element.dataset.fieldType
    @name = @_element.dataset.fieldName
    @column_name = @_element.dataset.columnName
    @column_permalink = @_element.dataset.id

    @_container = App.Helpers.Elements.closest(@_element, ".CONTAINER")
    @container = $(@_container)

    @column_options = JSON.parse(atob(@_container.querySelector("[name='column_options']").value))

    if @_element.dataset.id?
      @id = @_element.dataset.id
      @field_id = @id

    @errors = new App.Models.Submission.Field.Errors(@)
    @callbacks = new CallbackManager()

    @_unsaved_changes = false

    masked_inputs_exist = @_element.querySelector("[data-inputmask-mask]")?

    if masked_inputs_exist
      App.Helpers.Assets.get_entrypoints("jquery-inputmask.js").then (entrypoints) ->
        App.Helpers.Assets.require(entrypoints...)
      .then =>
        @element.find("input, textarea").inputmask()

  form: ->
    return @submission.form() if @submission?

    permalink = App.Helpers.Elements.closest(".SUBMISSION").dataset.formPermalink
    App.Models.Form.new(permalink)

  variable_name: -> @column_name
  permalink: -> @column_permalink

  _apply_options: ->
    @helpers = @_field_type_module(@type)

    @on "change", =>
      @_unsaved_changes = true
      @errors.empty()

      @trigger_cascading_value()

      @callbacks.trigger("validate", @)

      if @submission?
        @submission.sandboxes.publish("field.update", {
          field_id: @id,
          value: @value()
        })

  _field_type_module: (type) ->
    module = App.Helpers.Strings.classify(type)

    if App.Models.Submission.Field.Helpers[module]?
      return new App.Models.Submission.Field.Helpers[module](@)

    undefined

  data: ->
    return @helpers.data() if @helpers? && @helpers.data?

    undefined

  subfield_input: (name) ->
    namespace = "submission[#{@field_id}]"

    if name? && name.length > 0
      name = App.Helpers.Inputs.name_to_dot_syntax(namespace) + "." + App.Helpers.Inputs.name_to_dot_syntax(name)
      name = App.Helpers.Inputs.name_to_bracket_syntax(name)
    else
      name = namespace

    # Rails form fields that should be submitted as an array are suffixed with []
    field = @_element.querySelector("[name='#{name}[]']:not(.checkbox_reset)")
    field ?= @_element.querySelector("[name='#{name}']:not(.checkbox_reset)")

    field

  get_subfield: (name) ->
    # value = @subfield_input(name).value

    subfield = @subfield_input(name)

    return null if !subfield?

    value = subfield.value

    return true if value == "true"
    return false if value == "false"

    value

  set_subfield: (name, value) ->
    field = @subfield_input(name)

    return unless field?

    if @helpers? && @helpers.set_subfield?
      @helpers.set_subfield(field, value)
    else
      if field.tagName.toUpperCase() == "SELECT"
        App.Helpers.Selects.value_from_array(field, value)
      else if field.tagName.toUpperCase() == "INPUT" && field.type == "checkbox"
        field.checked = (value == true || value == "true")
      else
        field.value = value

      field.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))

    field

  value: (value) ->
    if value?
      if value instanceof Date
        @set_subfield("year", value.getFullYear())
        @set_subfield("month", (value.getMonth() + 1).toString())
        @set_subfield("day", value.getDate())
        @set_subfield("hour", value.getHours().toString())
        @set_subfield("minute", if value.getMinutes() < 10 then "0#{value.getMinutes()}" else value.getMinutes().toString())
      else if value instanceof App.Models.User.Object
        @set_subfield(undefined, value.username())
      else if value instanceof App.Models.Submission.Object
        @set_subfield(undefined, value.permalink())
      else if value instanceof App.Models.Form
        @set_subfield(undefined, value.permalink())
      else if App.Helpers.Objects.isPlainObject(value)
        for key, val of value
          @set_subfield(key, val)
      else
        @set_subfield(undefined, value)

    field_hash = App.Helpers.Forms.data(@_element).submission
    field_value = if field_hash? then field_hash[@field_id] else undefined

    # Rails form fields that should be submitted as an array are suffixed with []
    # Empty array field values should return [] instead of null.
    field_value = if field_value? && field_value[""]? then field_value[""] || [] else field_value

    if @helpers? && @helpers._value?
      field_value = @helpers._value(field_value)

    field_value

  value_is_or_contains: (value) ->
    field_value = @value()

    if Array.isArray(field_value)
      return field_value.indexOf(value) != -1
    else if !isNaN(parseFloat(field_value)) && isFinite(field_value)
      return field_value == parseInt(value)

    field_value == value

  required: -> @_element.dataset.required == "true"
  unsaved_changes: -> (@_unsaved_changes == true)
  persist: -> @_unsaved_changes = false

  field_type: ->
    @type

  # Changes the field's name
  rename: (value) ->
    @name = value
    @container.find(".FIELD_NAME").text(value)

  description: (value) ->
    element = @_container.querySelector(".FIELD_DESCRIPTION")

    if value?
      if !element?
        element = document.createElement("DIV")
        element.className = "FIELD_DESCRIPTION"

        container = @_container.querySelector(".FIELD_CONTAINER")
        container.parentNode.insertBefore(element, container)

      element.textContent = value

    return unless element?
    element.textContent

  # Sets the field's value if no value is present
  default: (value) ->
    @value(value) if @empty()

  # Shorthand for adding an error to the field
  error: (error) ->
    @errors.add(error)

  # Checks if the field has any errors.
  valid: ->
    !@errors.any()

  # Make the field's element visible
  show: ->
    @_container.classList.remove("hidden")

  # Make the field's element hidden
  hide: ->
    @_container.classList.add("hidden")

  # Check whether the field's element is visible or hidden
  visible: ->
    !@_container.classList.contains("hidden")

  # The order in which the field appears on the page
  index: ->
    if @submission?
      return @submission.fields.fields.indexOf(@)

    element = App.Helpers.Elements.closest(".SUBMISSION")
    fields = Array.from(element.querySelectorAll(".CONTAINER"))

    fields.indexOf(@_container)

  disable: ->
    for input in @inputs()
      input.readonly = true

    undefined

  enable: ->
    for input in @inputs()
      input.readonly = false

    undefined

  enabled: ->
    first_input = @inputs()[0]
    return true unless firest_input?
    first_input.readonly != true

  inputs: ->
    @_container.querySelectorAll(".FIELD input, .FIELD textarea, .FIELD select")

  highlight: (color) ->
    return @_highlight_color unless color?
    @_highlight_color = color

    colors = ["red", "orange", "yellow", "lime", "green", "teal", "blue", "indigo", "purple", "pink", "grey"]
    inputs = @inputs()

    for color_name in colors
      for input in inputs
        input.classList.remove("highlight-#{color_name}")

    if colors.indexOf(color) != -1
      for input in inputs
        input.classList.add("highlight-#{color}")

    color

  # Focus on the field's first input
  # Most browsers focus at the start of the input by default, this sets it to the end.
  # blur() is an IE-hack, and val("") sets the cursor to the end of the next value.
  focus: ->
    if @submission.pages.any()
      page_element = App.Helpers.Elements.closest(@_container, ".FORM_PAGE")

      if page_element? && !page_element.classList.contains("ACTIVE_PAGE")
        @submission.pages.open(page_element.dataset.field)

    for input in @inputs()
      continue if autofocus? || !App.Helpers.Elements.visible(input)
      autofocus = input

    return false unless autofocus?
    App.Helpers.Inputs.focus_end(autofocus)

    true

  # Wrapper around the CallbackManager to allow field.on("change", function() {}) syntax
  on: (event_name, method) ->
    @callbacks.on(event_name, method)

  validate: (method) ->
    if method?
      @on "validate", method.bind(@)
    else
      new Promise (resolve, reject) =>
        @callbacks.trigger_synchronous("validate").then =>
          return resolve() unless @submission?

          @submission.sandboxes.publish("field.validation.validate", { field_id: @id })
          .then resolve
          .catch reject
        .catch reject

  submissions: ->
    queries = []

    field_value = @value()

    if @type == "connection"
      form = @helpers.connected_form()
    else
      form = @form()

    if Array.isArray(field_value)
      for permalink in field_value
        queries.push form.submissions.load(field_value)
    else if typeof field_value == "string"
      queries.push form.submissions.load(field_value)

    Promise.all(queries)

  menu: (object) ->
    if Array.isArray(object)
      @menu(option) for option in object
      return

    if !@_container.querySelector(".TITLE_MENU_DROPDOWN")?
      @shortcut App.Models.Submission.Field.Menus.generate()
      new_menu = true

    icon_menu = @_container.querySelector(".TITLE_MENU_DROPDOWN")
    $(icon_menu).dropit() if new_menu

    option = App.Models.Submission.Field.Menus.generate_option(@, object)

    icon_menu.querySelector(".dropit-submenu").appendChild(option)

    option

  shortcut: (object) ->
    if Array.isArray(object)
      @shortcut(option) for option in object
      return

    object = object[0] if App.Helpers.Elements.is_jquery(object)

    if object instanceof HTMLElement
      shortcut = object
      shortcut.classList.add("TITLE_MENU", "TITLE_MENU_TEXT")
    else
      shortcut = App.Models.Submission.Field.Menus.generate_shortcut(@, object)

    container = @_container.querySelector(".TITLE_MENU_CONTAINER")

    if !container?
      container = document.createElement("DIV")
      container.className = "TITLE_MENU_CONTAINER"

      @_container.querySelector(".TITLE").appendChild(container)

    menu = container.querySelector(".TITLE_MENU_DROPDOWN")

    if menu?
      menu.parentNode.insertBefore(shortcut, menu)
    else
      container.appendChild(shortcut)

    shortcut

  empty: -> @is_empty()

  # New preferred syntax.
  # @empty() may be used in custom scripts, but should be phased out in the future.
  is_empty: (value = undefined) ->
    value ?= @value()

    if typeof value == "undefined"
      return true

    if typeof value == "number"
      return false

    if App.Helpers.Objects.isString(value)
      return value.length == 0

    if App.Helpers.Objects.isArray(value)
      return value.length == 0

    if App.Helpers.Objects.isEmptyObject(value)
      return true

    if typeof value == "object"
      for key, val of value
        return false unless @is_empty(val)

    true

  # Update chained_field's selectable values to chained_values based on this field's value
  cascading_value: (chained_field, value, chained_values) ->
    if chained_field.type == "dropdown" || chained_field.type == "boolean"
      field_id = chained_field.field_id

      @column_options.cascading_values ||= {}
      @column_options.cascading_values[field_id] ||= {}
      @column_options.cascading_values[field_id][value] = chained_values

      @trigger_cascading_value()

  default_cascading_value: (chained_field, chained_values) ->
    @cascading_value(chained_field, "", chained_values)

  # Iterates over the set cascading values when the field is changed
  trigger_cascading_value: ->
    return unless @submission? && @column_options.cascading_values?

    current = @value()

    for chained_field_id, trigger_list of @column_options.cascading_values
      chained_field = @submission.fields.find_by_field_id(chained_field_id)
      selected_trigger = ""

      for trigger, chained_values of trigger_list
        if current == trigger || (trigger.length > 0 && current.indexOf(trigger) != -1)
          selected_trigger = trigger

      if chained_field.helpers? && chained_field.helpers.show_options?
        chained_field.helpers.show_options(trigger_list[selected_trigger])

  text: ->
    field_value = @value()

    switch @field_type()
      when "address"
        output = []

        if field_value.street_address? && field_value.street_address.length > 0
          output.push(field_value.street_address)

        if field_value.suite? && field_value.suite.length > 0
          output.push(field_value.suite)

        if field_value.city? && field_value.city.length > 0
          output.push(field_value.city)

        if field_value.region? && field_value.region.length > 0
          output.push(field_value.region)

        if field_value.zip_code? && field_value.zip_code.length > 0
          output.push(field_value.zip_code)

        if field_value.country? && field_value.country.length > 0
          output.push(App.Helpers.Selects.options(@subfield_input("country")))

        return output.join(", ")
      when "date_time"
        output = ""

        if @helpers.supports_dates()
          year = field_value.year || "____"
          month = App.Helpers.Selects.options(@subfield_input("month")) || "____"
          day = field_value.day || "__"

          output += "#{month} #{day}, #{year}"

          if @helpers.supports_times()
            output += " at "

        if @helpers.supports_times()
          hour = field_value.hour || "__"
          minute = field_value.minute || "__"

          output += "#{hour}:#{minute}"

        return output
      when "phone"
        output = []

        if field_value.country? && field_value.country.length > 0
          output.push("+#{field_value.country}")

        output.push(field_value.number || "____")

        if field_value.extension? && field_value.extension.length > 0
          output.push("x#{field_value.extension}")

        return output.join(" ")

    field_value

  merge_conflicted_value: ->
    return unless @submission.versioning.has_conflict(@)

    @value(@submission.versioning.changed_value(@))

  serialize: ->
    data = {}
    data.field_id = @field_id
    data.field_type = @type
    data.name = @name
    data.value = @value()

    data.column_name = @column_name
    data.column_options = @column_options

    data.visible = @visible()
    data.enabled = @enabled()
    data.highlight = @highlight()

    data

window.App.Models.Submission.Field.decode_preset_data = (dataset) ->
  data_attributes = Object.keys(dataset)
  field_values = {}

  for name in data_attributes
    if name.startsWith("fieldValue")
      field_name = App.Helpers.Strings.variable_name(name.slice(10, name.length))
      field_values[field_name] = dataset[name]

  field_values
