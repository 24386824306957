
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Modal ?= {}
window.App.Interface.Modal._models ?= {}

window.App.Interface.Modal.submission_modals = []

class window.App.Interface.Modal.Object
  constructor: (container, @ajax_path) ->
    @instance_id = App.Helpers.Generators.uuid()
    @element_id = @instance_id

    @callbacks = new CallbackManager(@)

    @sandboxes = new App.Frame.Sandbox.Manager(@, "modal.#{@instance_id}")
    @sandboxes.on "start", => App.Interface.Modal.Helpers.Sandboxes.register(@)

    @_set_container(container) if container?

  _destroy_container: ->
    return unless @container?

    delete App.Interface.Modal._models[@element_id]
    @container.remove()

    delete @container

  _set_container: (container) ->
    @_destroy_container()

    container = container[0] if App.Helpers.Elements.is_jquery(container)
    @container = container
    @container.classList.add("initialized")

    @modal_element = @container.querySelector(".modal")
    @element = @modal_element

    id = @container.id
    id = undefined if !id? || id.length == 0

    if id?
      model_exists = App.Interface.Modal._models[id]? && App.Interface.Modal._models[id] != @

    if !id || model_exists 
      @container.id = @instance_id

    @element_id = @container.id

    # Add the module to the global list to allow scripts to find it by ID
    if @element_id? && @element_id.length > 0
      App.Interface.Modal._models[@element_id] = @

      if @container.classList.contains("SUBMISSION")
        App.Interface.Modal.submission_modals ?= []
        App.Interface.Modal.submission_modals.push(@)

    @callbacks.trigger("load", @)

  id: (value) ->
    if value?
      delete App.Interface.Modal._models[@element_id]

      @element_id = value
      @container.id = value

      App.Interface.Modal._models[@element_id] = @

    @container.id

  # Load the modal from the ajax_path provided
  load: (event) ->
    if @loaded() && !@_load_request?
      @_load_request = Promise.resolve(@)

    return @_load_request if @_load_request?

    @_load_request = new Promise (resolve, reject) =>
      link = element_from_object(event)
      add_loader(link)

      $.ajax
        url: @ajax_path
        context: @
      .done (response) =>
        remove_loader(link)

        element = App.Helpers.Elements.from_html(response)
        App.Interface.Modal.container().appendChild(element)

        @_set_container(element)
        @sandboxes.publish("load", @serialize())

        resolve(@)
      .fail (xhr) =>
        remove_loader(link)

        @_load_request = undefined

        App.Errors.response_error(xhr)

        reject(xhr)

  unload: ->
    return unless @loaded()

    @hide()

    @_destroy_container()

    @_load_request = undefined

  # Checks if the modal has been loaded onto the page
  loaded: -> @container?

  # Make the modal visible to the user
  show: (event) ->
    new Promise (resolve, reject) =>
      @load(event).then =>
        @container.classList.add("visible")

        # Brings the last-selected modal to the front, since the time is always going up.
        @container.style.zIndex = App.Helpers.Generators.unix_time()
        
        container_form = @container.querySelector("form")

        if container_form?
          container_form.dispatchEvent(App.Helpers.Events.new("resize"))

        App.Interface.Modal._last = @

        @sandboxes.publish("show")
        @callbacks.trigger("show")
        resolve(@)
      .catch reject

  # Make the modal invisible to the user
  # While there's no need to load the modal to hide it, some modal spawning methods trigger
  # a load request. If one is ongoing, a hide() call should wait until it's finished.
  # This allows the following code to work:
  #     model = App.Interface.Modal.new()
  #     modal.hide()
  hide: ->
    new Promise (resolve, reject) =>
      load_request = @_load_request
      load_request ?= Promise.resolve()

      load_request.then =>
        if @container?
          @container.classList.remove("visible")
          @sandboxes.publish("hide")
          @callbacks.trigger("hide")

        resolve(@)
      .catch reject

  # Force the modal to stay open
  force: -> @container? && @container.classList.add("forced")

  # Allow the user to close the modal, removing any forced effects
  optional: -> @container? && @container.classList.remove("forced")

  # Check if the modal is forced to stay visible
  forced: -> @container? && @container.classList.contains("forced")

  # Check if the modal is currently visible
  visible: -> @container? && @container.classList.contains("visible")

  size: (value) ->
    if value? && @modal_element?
      size = App.Interface.Modal.parse_size(value)

      @modal_element.classList.remove.apply(@, App.Interface.Modal.sizes)
      @modal_element.classList.add(size)

      @sandboxes.publish("update.size", { value: size })

    element = @modal_element

    for class_name in App.Interface.Modal.sizes
      return class_name if element.classList.contains(class_name)

    undefined

  # Toggle between shown and hidden states, with an optional parameter to choose a state
  toggle: (status) ->
    status ?= !@visible()
    if status then @show() else @hide()

  # Remove the modal DOM element from the page
  destroy: ->
    @container.remove()
    @sandboxes.publish("destroy")
    @callbacks.trigger("destroy")

    # Returns undefined for @modal = @modal.destroy() shorthand
    undefined

  # Returns the container element
  element: -> @container

  # Updates the content of the modal's title
  title: (text) ->
    title_element = @title_element()

    if text?
      title_element.textContent = text
      @sandboxes.publish("update.title", { value: text })

    title_element.textContent

  title_element: ->
    @modal_element.querySelector(".title")

  # Updates the content of the modal's body, without updating the actions
  body: (text) ->
    body_element = @body_element()

    if text?
      children = []

      for child in body_element.childNodes
        continue if child.nodeType == 1 && child.classList.contains("actions")
        children.push(child)

      child.remove() for child in children

      text_node = document.createTextNode(text)

      body_element.insertBefore(text_node, body_element.firstChild)

      @sandboxes.publish("update.body", { value: text })

    output = ""

    for child in body_element.childNodes
      continue if child.nodeType == 1 && child.classList.contains("actions")
      output += child.textContent

    output

  body_element: ->
    @modal_element.querySelector(".body")

  # Updates the content of the modal's actions
  actions: (actions) ->
    if actions?
      actions = App.Interface.Modal.parse_actions(actions)
      element = App.Interface.Modal.actions_to_element(actions, @)

      actions_element = @actions_element()
      actions_element.parentNode.replaceChild(element, actions_element)

      @sandboxes.publish("update.actions", { value: actions })

    undefined

  action: (text, options_or_callback, callback) ->
    if App.Helpers.Objects.isPlainObject(text)
      options = text
    else
      options ?= {}

      if App.Helpers.Objects.isFunction(options_or_callback)
        options.callback = options_or_callback
      else if options_or_callback?
        options = options_or_callback
        options.callback = callback

      options.text = text

    element = App.Interface.Modal.action_to_element(options, @)
    @actions_element().appendChild(element)

  actions_element: ->
    @modal_element.querySelector(".actions")

  on: (event, callback) -> @callbacks.callback event, callback

  # Slides the element out of focus when toggling between modals
  slide_out: (direction) ->
    position = if direction == "left" then "-100%" else "100%"

    @container.classList.add("sliding")

    $(@modal_element).animate { left: position }, App.Interface.Modal.animation_time, "linear", =>
      @hide()

      @modal_element.style.left = "0"
      @container.classList.remove("sliding")

  # Slides the element into focus when toggling between modals
  slide_in: (direction) ->
    position = if direction == "left" then "-100%" else "100%"

    @show()

    @modal_element.style.left = position

    $(@modal_element).animate { left: "0" }, App.Interface.Modal.animation_time, "linear", =>
      @show()

  serialize: ->
    data = {}

    data.instance_id = @instance_id
    data.loaded = @loaded()

    data.options = {}
    data.options.visible = @visible()
    data.options.size = @size()

    if data.loaded
      data.options.id = @modal_element.id

    data.elements = {}

    if data.loaded
      data.elements.title = @title()
      data.elements.body = @body()
      data.elements.actions = {}

    data
