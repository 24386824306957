
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Sidebar ?= {}

window.App.Interface.Sidebar.container = ->
  App.Interface.Sidebar._container_model

class window.App.Interface.Sidebar.SideBarContainer
  constructor: (@element) ->
    @mobile_menu_element = @element.querySelector(".mobile_menu_options")

  show: (sidebar, options = {}) ->
    App.Sidebar.hide_all_except(sidebar)

    @element.classList.remove("hidden_sidebar")
    sidebar.element.classList.remove("hidden")

    @mobile_show(sidebar, options) if App.Sidebar.mobile() && options.mobile != false

    @toggle_opener_icons()
    sidebar.generate_scrollbars(sidebar)

    setTimeout (-> $(window).trigger("resize")), App.Interface.Sidebar.animation_time

    sidebar

  hide: (sidebar) ->
    @element.classList.add("hidden_sidebar")

    for model in App.Interface.Sidebar.all()
      model.element.classList.add("hidden")

    @mobile_hide(sidebar) if sidebar? && App.Sidebar.mobile()

    @toggle_opener_icons()

    setTimeout (-> $(window).trigger("resize")), App.Interface.Sidebar.animation_time

    sidebar

  visible: ->
    return @mobile_visible() if App.Sidebar.mobile()

    @element.classList.contains("hidden_sidebar") == false

  mobile_show: (sidebar, options) ->
    if !options? || options.mobile_menu != false
      @show_mobile_menu()
    else
      @hide_mobile_menu()

    @element.classList.add("mobile_visible")
    sidebar.element.classList.remove("hidden")

    document.body.classList.add("mobile_sidebar_open")

    App.Event.publish("sidebar.show")

  mobile_hide: (sidebar) ->
    return unless App.Interface.Sidebar.mobile()

    @element.classList.remove("mobile_visible")
    sidebar.element.classList.add("hidden") if sidebar?

    document.body.classList.remove("mobile_sidebar_open")

    App.Event.publish("sidebar.hide")

  mobile_visible: ->
    @element.classList.contains("mobile_visible")

  show_mobile_menu: ->
    @mobile_menu_option_sync()
    @mobile_menu_element.style.display = "block"

    undefined

  hide_mobile_menu: ->
    @mobile_menu_element.style.display = "none"

    undefined

  # jQuery's clone method preserves listeners. This can be moved to vanillaJS when
  # custom menu option listeners are centralized
  mobile_menu_option_sync: ->
    return unless @mobile_menu_element?

    $(@mobile_menu_element).html $(@mobile_menu_options()).clone(true, true)

  mobile_menu_options: ->
    App.menu().element.querySelectorAll(App.Interface.Menu.MOBILE_SIDEBAR_OPTION_SELECTOR)

  mobile_menu_empty: ->
    !App.menu().element.querySelectorAll(App.Interface.Menu.MOBILE_SIDEBAR_OPTION_SELECTOR)?

  toggle_opener_icons: (openers = undefined) ->
    openers ?= document.querySelectorAll(".toggle_document_sidebar")

    return if App.Sidebar.mobile()
    visible = @visible()

    for button in openers
      icon = button.querySelector(".icon")
      icon.classList.toggle("icon-resize-full", !visible)
      icon.classList.toggle("icon-resize-small", visible)

    undefined

$ ->
  element = document.getElementById("document-sidebar")

  if element?
    window.App.Interface.Sidebar._container_model = new App.Interface.Sidebar.SideBarContainer(element)
