
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Command ?= {}

class window.App.Interface.Command.Option
  constructor: (@parent, name, options_or_callback, callback) ->
    if typeof options_or_callback == "function"
      callback = options_or_callback
    else
      @options = options_or_callback

    @callbacks = []
    @callbacks.push(callback) if callback?

    @options ?= {}

    @element = $("<a/>", href: "#", text: name)
    @element.addClass("dynamic_option") if @options.dynamic
    @element.appendTo(@parent.suggestion_element)

    @parent._option_models.push(@)

    @element.on "click", (event) => @run_callbacks(event)

    App.Interface.command_palette.reorder()

  callback: (method) ->
    @callbacks.push(method) if method?

  run_callbacks: (event) ->
    event.preventDefault()

    for callback in @callbacks
      callback.call(@, event)

window.App.Interface.Command.new = (text, arg1, arg2) ->
  parent = App.Interface.command_palette
  new App.Interface.Command.Option(parent, text, arg1, arg2)
