
$(document).on "change", ".response_condition_group_operator input", (event) =>
  event.preventDefault()

  input = event.currentTarget
  modal = App.Helpers.Elements.closest(input, ".modal .body")
  query = App.Elements.ResponseQuery.find_by_container(modal)

  return unless query?

  element = App.Helpers.Elements.closest(input, ".response_condition_group")
  instance = element.dataset.instance

  group = query.editor_query.find_condition_by_instance_id(instance)
  return unless group?

  operator = if input.checked then "and" else "or"
  group.logical_operator(operator)
