
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.RowManagement = {
  row_permalinks: ->
    output = []

    for row in @table_body_element.querySelectorAll("tr")
      output.push(row.dataset.permalink)

    output

  row_element: (value) ->
    if App.Helpers.Objects.isEvent(value)
      value = value.currentTarget

    if App.Helpers.Objects.isNode(value)
      return value if value.tagName.toUpperCase() == "TR"
      return App.Helpers.Elements.closest(value, "tr")

    if App.Helpers.Objects.isString(value)
      return @table_body_element.querySelector("tr[data-permalink='#{value}']")

    null
}