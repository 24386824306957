
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Parameters ?= {}

window.App.Helpers.Parameters.parse = (search) ->
  App.Helpers.URIs.parse_search(search)

window.App.Helpers.Parameters.params = (param = undefined) ->
  if App.is_child
    if param?
      return unless App._parameters?
      return App._parameters[param]
    else
      return {} unless App._parameters
      return App._parameters

  search = location.search
  return {} unless search?
  search = search.substring(1)

  search_hash = App.Helpers.Strings.hash(search)

  for id, context of App._page_contexts
    if context._path_search_hash == search_hash
      return context.params(param)

  result = App.Helpers.Parameters.parse(search) || {}

  return result[param] if param?

  result

window.App.Helpers.Parameters.unsafe_parameter_names = [
  "username", "password", "authenticity_token", "access_token", "api_request"
]

window.App.Helpers.Parameters.safe_params = (param = undefined) ->
  value = App.Helpers.Parameters.params()

  for unsafe_parameter in App.Helpers.Parameters.unsafe_parameter_names
    delete value[unsafe_parameter]

  if param?
    value[param]
  else
    value

# This method is like safe_params, except it gives an indication when
# a parameter has been filtered out. This makes the action more transparent
# to custom scripts.
window.App.Helpers.Parameters.filtered_params = (param = undefined) ->
  value = App.Helpers.Parameters.params()

  for unsafe_parameter in App.Helpers.Parameters.unsafe_parameter_names
    if value[unsafe_parameter]?
      value[unsafe_parameter] = "[FILTERED]"
    else
      delete value[unsafe_parameter]

  if param?
    value[param]
  else
    value

window.App.Helpers.Parameters.serialize = (object) -> $.param(object)

# Rewritten in CoffeeScript from jQuery.deParam
window.App.Helpers.Parameters.deserialize = (params) ->
  output = {}
  pairs = params.replace(/\+/g, " ").split("&")

  for set in pairs
    param = set.split("=")

    # Checks whether the param is a key-value pair, or just a value
    if param.length == 2
      App.Helpers.Parameters.pair_to_object(param[0], param[1], output)
    else if param[0]
      output[decodeURIComponent(param[0])] = undefined

  output

window.App.Helpers.Parameters.coerce_value = (value) ->
  coerce_types = { "true": !0, "false": !1, "null": null }

  if value && !isNaN(value) && (+value + "") == value
    value = +value
  else if value == "undefined"
    value = undefined
  else if coerce_types[value]?
    value = coerce_types[value]

  value

window.App.Helpers.Parameters.split_keys = (key) ->
  key_parts = key.split("][")
  keys_last = key_parts.length - 1

  if /\[/.test(key_parts[0]) && /\]$/.test(key_parts[keys_last])
    # Remove the trailing ] from the last key.
    key_parts[keys_last] = key_parts[keys_last].replace(/\]$/, "")

    # Split the first key into two parts on the [, add them back onto the key_parts array
    key_parts = key_parts.shift().split("[").concat(key_parts)

  key_parts

# Merges a potentially-serialized key-value pair onto a hash.
# Coerces the value, and supports nested-keys like one[two][three].
window.App.Helpers.Parameters.pair_to_object = (key, value, object = {}) ->
  cursor = object

  if App.Helpers.Objects.isString(key)
    key = decodeURIComponent(key.replace(/\%/g, "%25"))

  if App.Helpers.Objects.isString(value)
    value = decodeURIComponent(value.replace(/\%/g, "%25"))

  value = App.Helpers.Parameters.coerce_value(value)

  key_parts = App.Helpers.Parameters.split_keys(key)
  keys_last = key_parts.length - 1

  # Build nested key structures
  if keys_last
    for element, index in key_parts
      element_is_array = (element == "")
      key = (if element_is_array then cursor.length else element)

      if index < keys_last
        if cursor[key]
          cursor[key] = cursor[key]
        else if key_parts[index + 1] && isNaN(key_parts[index + 1])
          cursor[key] = {}
        else
          cursor[key] = []
      else
        # Parameters like one[two][] should produce { one: { two: [] } }
        # This code makes passed values append to the final array rather than
        # adding a child array under it.
        if element_is_array && Array.isArray(value)
          for item, item_index in value
            cursor[key + item_index] = item
        else
          cursor[key] = value

      cursor = cursor[key]
  else
    if Array.isArray(object[key])
      object[key].push(value)
    else if {}.hasOwnProperty.call(object, key)
      object[key] = [object[key], value]
    else
      object[key] = value

  object

window.App.params = (param = undefined) ->
  App.Helpers.Parameters.params(param)

window.App.safe_params = (param = undefined) ->
  App.Helpers.Parameters.safe_params(param)

window.App.filtered_params = (param = undefined) ->
  App.Helpers.Parameters.filtered_params(param)

window.params = App.Helpers.Parameters.params

window.safe_params = App.Helpers.Parameters.safe_params
