
$(document).on "click", ".run_procedure_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  button_link = button.href
  button_link ?= button.dataset.link

  $.ajax
    url: button_link
    type: "POST"
    data: { authenticity_token: current_user.authenticity_token() }
  .always ->
    remove_loader button

    counter = button.querySelector(".run_counter")

    if !counter?
      counter = document.createElement("SPAN")
      counter.className = "run_counter"
      counter.innerHTML = " (<span class='count'>0</span>)"
      button.appendChild(counter)

    count = counter.querySelector(".count")
    count.textContent = parseInt(count.textContent) + 1
  .fail (xhr) ->
    App.Errors.response_error(xhr)

$(document).on "click", ".procedure_options_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  $.ajax
    url: link.href
  .always ->
    remove_loader button
  .done (response, status, request) ->
    options_permalink = request.getResponseHeader("X-Form-Permalink")

    App.Models.Form.new(options_permalink).submissions.initialize_html(response)
  .fail (xhr) ->
    App.Errors.response_error(xhr)
