import 'app/elements/response_comment/helpers/routing'


window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseComment ?= {}

class window.App.Elements.ResponseComment.Object
  constructor: (@element, @viewer) ->
    @element = @element[0] if App.Helpers.Elements.is_jquery(@element)

    @routes = new App.Elements.ResponseComment.Helpers.Routing(@)

  load: ->
    return $.Deferred().resolve() if @_loaded

    @element.innerHTML = ""

    add_loader @element

    @refresh().done =>
      @_loaded = true

      @element.classList.remove("loading")
      @element.classList.add("loaded")

      if location.hash? && location.hash.length > 0
        old_hash = location.hash
        history.replaceState(undefined, undefined, "#")
        location.hash = old_hash

  format: (scope) ->
    scope ?= @element

    $(scope).find(".comment").detect_links({
      form: @viewer.form().permalink()
    })

  refresh: ->
    deferred = $.Deferred()

    $.ajax
      url: @routes.comments()
    .done (response) =>
      @element.style = ""
      @element.innerHTML = response
      @format()

      # Continue showing resolved comments which have been made visible by the user.
      if @_visible_resolved?
        for comment in @_visible_resolved
          @show_resolved(comment)

      deferred.resolve()
    .fail (xhr) ->
      App.Errors.xhr_error(xhr)
      deferred.reject()

    deferred

  reply: (comment, value) ->
    deferred = $.Deferred()
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    if comment?
      button = comment.querySelector(".reply-comment")

      # If there's an explicit value sent by a script, ignore existing request
      if !value? && button? && button.classList.contains("loading")
        return deferred.reject()

      add_loader button

    @load_reply_form(comment)
    .always ->
      remove_loader(button)
    .done (reply_element) =>
      comment_input = reply_element.querySelector("textarea[name=comment]")
      comment_input.focus()

      if value?
        comment_input.value = value
        @submit(comment)

  reply_toggle: (comment) ->
    deferred = $.Deferred()
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    reply_element = @_existing_reply_form(comment)

    if reply_element?
      reply_element.classList.toggle("hidden")

      comment_input = reply_element.querySelector("textarea[name=comment]")
      comment_input.focus()
    else
      @reply(comment)

  _existing_reply_form: (comment) ->
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    if comment?
      reply_element = comment.querySelector(".comment-form")
    else
      reply_element = @element.querySelector(".comment-form:not([data-parent])")

    reply_element

  load_reply_form: (comment) ->
    deferred = $.Deferred()
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    reply_element = @_existing_reply_form(comment)

    if reply_element?
      reply_element.classList.remove("hidden")
      deferred.resolve(reply_element)
    else
      if comment?
        parent_element = comment
        parent_comment_permalink = comment.dataset.permalink
      else
        parent_element = @element

      $.ajax
        url: @routes.new(parent_comment_permalink)
      .done (response) =>
        reply_element = App.Helpers.Elements.from_html(response)
        parent_element.appendChild(reply_element)

        deferred.resolve(reply_element)
      .fail (xhr) ->
        App.Errors.xhr_error(xhr)
        deferred.reject()

    deferred.promise()

  submit: (comment) ->
    deferred = $.Deferred()
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    @load_reply_form(comment).done (reply_element) =>
      button = reply_element.querySelector("button[type=submit], input[type=submit]")

      return deferred.reject() if App.Helpers.Loaders.loading(button)

      if comment?
        comment_parent = comment.dataset.permalink
      else
        comment_parent = undefined

      comment_errors = reply_element.querySelector(".comment-error")
      comment_input  = reply_element.querySelector("textarea[name=comment]")
      comment_value  = comment_input.value

      if !comment_value? || $.trim(comment_value) == ""
        comment_input.focus()
        return deferred.reject()

      add_loader button

      comment_errors.innerHTML = ""

      $.ajax
        url: @routes.create(comment_parent)
        type: "POST"
        data: {
          comment: comment_value,
          authenticity_token: current_user.authenticity_token()
        }
      .done (response) =>
        remove_loader button

        reply_element.classList.add("hidden")
        comment_input.value = ""

        deferred.resolve(@refresh())
      .fail (xhr) ->
        App.Errors.xhr_error(xhr)
        deferred.reject()

    deferred

  destroy: (comment) ->
    deferred = $.Deferred()
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    return deferred.reject() unless comment?

    button = comment.querySelector(".delete-comment")

    return deferred.reject() if App.Helpers.Loaders.loading(button)
    add_loader button

    $.ajax
      url: @routes.destroy(comment.dataset.permalink)
      type: "DELETE"
      data: {
        authenticity_token: current_user.authenticity_token()
      }
    .always ->
      remove_loader button
    .done =>
      deferred.resolve(@refresh())
    .fail (xhr) ->
      App.errors.xhr_error(xhr)
      deferred.reject()

    deferred

  resolve: (comment) ->
    deferred = $.Deferred()
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    return deferred.reject() unless comment?

    button = comment.querySelector(".resolve-comment")

    return deferred.reject() if App.Helpers.Loaders.loading(button)
    add_loader button

    $.ajax
      url: @routes.resolve(comment.dataset.permalink)
      type: "POST"
      data: {
        authenticity_token: current_user.authenticity_token()
      }
    .always ->
      remove_loader button
    .done (response) =>
      deferred.resolve(@refresh())
    .fail (xhr) ->
      App.Errors.xhr_error(xhr)
      deferred.reject()

    deferred

  unresolve: (comment) ->
    deferred = $.Deferred()
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    return deferred.reject() unless comment?

    button = comment.querySelector(".unresolve-comment")

    return deferred.reject() if App.Helpers.Loaders.loading(button)
    add_loader button

    $.ajax
      url: @routes.unresolve(comment.dataset.permalink)
      type: "POST"
      data: {
        authenticity_token: current_user.authenticity_token()
      }
    .always ->
      remove_loader button
    .done (response) =>
      deferred.resolve(@refresh())
    .fail (xhr) ->
      App.Errors.xhr_error(xhr)
      deferred.reject()

    deferred

  show_resolved: (comment) ->
    deferred = $.Deferred()
    comment = comment[0] if App.Helpers.Elements.is_jquery(comment)

    @_visible_resolved ?= []
    @_visible_resolved.push(comment)

    if comment?
      button = comment.querySelector(".show-resolved-comments")
      wrapper = comment
    else
      button = @element.querySelector("*:not(.comment) .show-resolved-comments")
      wrapper = @element

    button.remove() if button?

    child_wrapper = App.Helpers.Elements.child(wrapper, ".comments")

    for child in child_wrapper.children
      child.classList.remove("comment-resolved")

    deferred.resolve()

window.App.Elements.ResponseComment.load_visible_comments = (element) ->
  if App.context?
    context = App.context()
    element ?= context.page_element() if context?
  element ?= document.body
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  if !element.classList.contains("comment-section")
    comment_elements = element.querySelectorAll(".comment-section:not(.loaded)")

    for element in comment_elements
      App.Elements.ResponseComment.load_visible_comments(element)

    return

  return if element.classList.contains("loaded")
  return if element.classList.contains("loading")

  visible_element = App.Helpers.Elements.visible_in_vertical_viewport(element)

  return if !visible_element && !location.hash.startsWith("#comment")

  viewer_element = App.Helpers.Elements.closest(element, ".view_submission")
  viewer = App.Elements.ResponseViewer.find_by_permalink(viewer_element.dataset.submissionPermalink)

  return unless viewer?

  viewer.comments.load()
