
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}
window.App.Frame.Sandbox.Bind ?= {}

window.App.Frame.Sandbox.Bind.headers = (channel, options) ->
  if options.read == true
    channel.on "schema.load.header", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.headers.schema().then ->
        header = form.headers.find_by_variable_name(event.data.header)

        header.schema()
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject
