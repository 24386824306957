
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Forms ?= {}

window.App.Helpers.Forms.data = (form) ->
  form = form[0] if App.Helpers.Elements.is_jquery(form)
  elements = form.querySelectorAll("input, textarea, select")

  output = {}

  for element in elements
    do ->
      return unless element.name && !element.disabled

      node_tag = element.tagName.toLowerCase()
      node_type = element.type.toLowerCase()

      allowed_names = ["select", "textarea"]
      allowed_types = ["hidden", "text", "search", "tel", "url", "email", "password", "datetime", "date", "month", "week", "time", "datetime-local", "number", "range", "color"]

      return unless element.checked || allowed_names.indexOf(node_tag) != -1 || allowed_types.indexOf(node_type) != -1

      if node_tag == "select"
        if element.multiple
          value = App.Helpers.Selects.values(element)
        else
          value = App.Helpers.Selects.value(element)
      else
        value = element.value

      App.Helpers.Parameters.pair_to_object(element.name, value, output)

  output

window.App.Helpers.Forms.clear_errors = (form) ->
  form = form[0] if App.Helpers.Elements.is_jquery(form)

  for error_element in form.querySelectorAll(".ERRORS")
    error_element.innerHTML = ""

  form

window.App.Helpers.Forms.apply_errors = (form, errors) ->
  form = form[0] if App.Helpers.Elements.is_jquery(form)

  for field, field_errors of errors
    # TODO: This SHOULD use only .FIELD_CONTAINER to keep compatibility with actual forms.
    # However, it originally used .CONTAINER a mistake and there may be modals depending on it.
    # Move .CONTAINER-based data-column-name and data-field-name attributes to .FIELD_CONTAINER where present.
    field_container = form.querySelector(".CONTAINER[data-column-name='#{field}'], .FIELD_CONTAINER[data-column-name='#{field}']")
    continue unless field_container?

    title = field_container.dataset.fieldName

    if field_container.classList.contains("FIELD_CONTAINER")
      container = App.Helpers.Elements.closest(field_container, ".CONTAINER")
      continue unless container?

      element = container.querySelector(".ERRORS")
    else
      element = field_container.querySelector(".ERRORS")

    continue unless element?

    element.innerHTML = ""

    for value in field_errors
      error_text = if title? then "#{title} #{value}" else value
      error_element = document.createElement("DIV")
      error_element.className = "ERROR"
      error_element.textContent = error_text

      element.appendChild(error_element)

  form

window.App.Helpers.Forms.object_to_single_level = (object, namespace, new_object) ->
  new_object ?= {}

  for key, value of object
    key = "#{namespace}[#{key}]" if namespace?

    if value instanceof Date
      new_object[key] = value.toISOString()
    else if typeof value == 'object' && !(value instanceof File)
      App.Helpers.Forms.object_to_single_level(value, key, new_object)
    else
      new_object[key] = value

  new_object

window.App.Helpers.Forms.merge_object_with_form_data = (form_data, object) ->
  object = App.Helpers.Forms.object_to_single_level(object)

  for key, value of object
    form_data.set(key, value)

  form_data

window.App.Helpers.Forms.ajax_redirect = (redirect_url) ->
  return unless redirect_url?

  if redirect_url == "refresh"
    window.location.reload()
  else
    App.hard_redirect_to redirect_url

window.App.Helpers.Forms.ajax_submit = (form, extra_options = {}, data = {}) ->
  form = form[0] if App.Helpers.Elements.is_jquery(form)

  return Promise.reject("no url given") unless form? && form.action? && form.action != "#"

  button = form.querySelector("button[type=submit], input[type=submit], .ajax_form_button")

  return Promise.reject("already loading") if loading(button)
  add_loader(button)

  if form.dataset.loader == "page"
    add_page_loader()

  options = {}
  options.url = form.action
  options.type = form.method || "post"

  if form.querySelector("input[type=file]")?
    options.data = new FormData(form)
    options.processData = false
    options.contentType = false

    if extra_options.data?
      for key, value of extra_options.data
        options.data.append(key, value)
  else
    options.data = App.Helpers.Forms.data(form)

    if extra_options.data?
      for key, value of extra_options.data
        options.data[key] = value

  new Promise (resolve, reject) ->
    $.ajax(options)
    .done (response) ->
      remove_loader(button)

      if form.dataset.loader == "page"
        remove_page_loader()

      new CustomEvent("persist", {
        bubbles: true, detail: { target: form }
      })

      if response.redirect_url?
        App.Helpers.Forms.ajax_redirect(response.redirect_url)

      resolve(response)
    .fail (response) ->
      remove_loader(button)

      if form.dataset.loader == "page"
        remove_page_loader()

      if response.responseJSON?
        if response.responseJSON.errors?
          App.Helpers.Forms.apply_errors(form, response.responseJSON.errors)

          modal_parent = App.Helpers.Elements.closest(form, ".modal-container")

          if modal_parent?
            modal = App.Interface.Modal.find_by_id(modal_parent.id)
            modal.show() if modal?
      else
        App.Errors.xhr_error(response)

      reject(response)

window.apply_errors_to_form = App.Helpers.Forms.apply_errors
