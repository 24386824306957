class window.FormulaWidget
  constructor: (@input, variables = {}, options = {}) ->
    @input = $(@input)
    @input.attr("autocomplete", "off")

    @codemirror = CodeMirror.fromTextArea(@input[0], {
      lineNumbers: false,
      mode: "text/x-formula-text",
      theme: "formula",
      tabSize: 2,
      smartIndent: false,
      showCursorWhenSelecting: true,
      scrollbarStyle: "simple",
      autoCloseBrackets: true,
      autoCloseTags: true,
      autoRefresh: true,
      highlightSelectionMatches: true,
      annotateScrollbar: true,
      lineWrapping: true
    })

    @codemirror.on "change", (cm) =>
      @input.val(cm.getValue().replace("\n", " "))
      @input.trigger("input")

    @input.on "change", => @codemirror.getDoc().setValue(@input.val())

    add_code_editor_context_menus(@codemirror)
