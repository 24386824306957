
$(document).on "click", ".SUBMISSION .SIGNATURE_CLEAR", (event) ->
  event.preventDefault()

  field_element = App.Helpers.Elements.closest(event.currentTarget, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)
  field.helpers.clear_signature()

$(document).on "click", ".SUBMISSION .SIGNATURE_REDRAW", (event) ->
  event.preventDefault()

  field_element = App.Helpers.Elements.closest(event.currentTarget, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)
  status = field.helpers.toggle_drawing()
  field.helpers.clear_signature() unless status

$(document).on "mousedown", ".SUBMISSION .SIGNATURE_CANVAS", (event) ->
  event.preventDefault()

  field_element = App.Helpers.Elements.closest(event.currentTarget, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)
  field.helpers.hide_description()
