
window.App ?= {}
window.App.Models ?= {}
window.App.Models.User ?= {}

class window.App.Models.User.Object
  constructor: (@_username, @_options = {}) ->

  username: ->
    @_username

  name: ->
    @_options.name

  guest: ->
    !@_username? || @_username == ""

  schema: ->
    @_schema_promise ?= new Promise (resolve, reject) =>
      $.ajax
        url: "/api/rest/v1/users/#{@username()}"
      .done (response) =>
        @_schema_data = response
        resolve(@)
      .fail (response) =>
        @_schema_promise = undefined
        reject(response.responseJSON)

    @_schema_promise

  groups: ->
    new Promise (resolve, reject) =>
      @schema().then =>
        output = []

        for group in @_schema_data.groups
          output.push App.Schema.Group.new(group)

        resolve(output)

  serialize: ->
    {
      username: @username(),
      name: @name()
    }

class window.App.Models.User.CurrentUserObject extends App.Models.User.Object
  constructor: (@_username, @_options = {}) ->
    @facility_permalink = @_options.facility_permalink

    super(@_username, @_options)

  manager: ->
    @_options.facility_manager == true

  signed_in: ->
    !@guest()

  config: (values...) ->
    return unless @_options.settings?

    output = @_options.settings

    for name in values
      if output?
        output = output[name]

    output

  config_or_true: (values...) ->
    output = @config.apply(@, values)
    return true unless output?
    output

  config_or_false: (values...) ->
    output = @config.apply(@, values)
    return false unless output?
    output

  feature: (name) ->
    @_options.facility_features[name] == true

  authenticity_token: ->
    @_token ?= document.querySelector("input[name=authenticity_token]").value
    @_token

  _newest_local_token: ->
    new_tokens = document.querySelectorAll("input[name=authenticity_token]:not([referenced])")
    return @_token? if new_tokens.length == 0
    token.setAttribute("referenced", true) for token in new_tokens
    @_token = new_tokens[new_tokens.length - 1].value

  serialize: ->
    output = super()
    output.options = @_options
    output

window.App.Models.User.all = ->
  Object.values(App.Models.User._models)

window.App.Models.User.find = (username) ->
  App.Models.User.new(username)

window.App.Models.User.first = ->
  Object.values(App.Models.User._models)[0]

window.App.Models.User.second = ->
  Object.values(App.Models.User._models)[1]

window.App.Models.User.third = ->
  Object.values(App.Models.User._models)[2]

window.App.Models.User.last = ->
  user_values = Object.values(App.Models.User._models)
  user_values[user_values.length - 1]

window.App.Models.User.schema = ->
  App.Models.User._schema_promise ?= new Promise (resolve, reject) ->
    $.ajax
      url: "/api/rest/v1/users/"
    .done (response) ->
      for data in response.users
        App.Models.User.new(data.username, { name: data.name })

      resolve(App.Models.User, response)
    .fail (response) ->
      App.Models.User._schema_promise = undefined

      reject(response.responseJSON)

  App.Models.User._schema_promise

window.App.Models.User.count = ->
  new Promise (resolve, reject) ->
    App.Models.User.schema()
    .then -> resolve(App.Models.User.all().length)
    .catch reject

window.App.Models.User.new = (username, options) ->
  App.Models.User._models ?= {}

  if App.Models.User._models[username]?
    user = App.Models.User._models[username]
    user._options = Object.assign(user._options, options) if options?
    return user

  App.Models.User._models[username] = new App.Models.User.Object(username, options)

  App.Models.User._models[username]

window.App.Models.User.load_current_user_settings = ->
  if !App.current_user.config("modal_toggle_animation")
    window.App.Interface.Modal.animation_time = 0

window.App.Models.User.initialize_current_user = ->
  return if App.current_user?

  user_username = document.head.querySelector("meta[name=sonadier-username]").content
  user_name = document.head.querySelector("meta[name=sonadier-name]").content

  organization = document.head.querySelector("meta[name=sonadier-organization]")
  organization_permissions = document.head.querySelector("meta[name=sonadier-organization-permissions]")
  organization_features = document.head.querySelector("meta[name=sonadier-organization-features]")

  settings_tag = document.head.querySelector("meta[name=sonadier-settings]")

  options = {}
  options.name = user_name
  options.facility_permalink = organization.content if organization?

  try
    options.settings = JSON.parse(atob(settings_tag.content).replace(/\"true\"/g, 'true').replace(/\"false\"/g, 'false'))
  catch
    options.settings = {}

  try
    options.facility_features = JSON.parse(atob(organization_features.content))
  catch
    options.facility_features = {}

  try
    options.facility_permissions = JSON.parse(atob(organization_permissions.content).replace(/\"true\"/g, 'true').replace(/\"false\"/g, 'false'))
  catch
    options.facility_permissions = {}

  options.facility_manager = (options.facility_permissions.manage == true)

  user = new App.Models.User.CurrentUserObject(user_username, options)

  window.App.current_user = user
  window.App._features = options.facility_features

  # if Raven? && App.current_user.signed_in()
  #   Raven.setUserContext({ username: App.current_user.username() })
  if Sentry_setUser? && App.current_user.signed_in()
    Sentry_setUser({ username: App.current_user.username() })

  App.Models.User.load_current_user_settings()

  # May be used in legacy scripts
  window.current_user = user
  window.user = user

  App.current_user

window.App.Models.User.user = ->
  App.current_user

window.App.user = App.Models.User.user

window.User = App.Models.User
