
window.App ?= {}
window.App.Schema ?= {}
window.App.Schema.Form ?= {}

class window.App.Schema.Form.Report extends App.Schema.Base
  include @, App.Schema.SourceBase

  constructor: (@form_permalink, data) ->
    @callbacks = new CallbackManager(@)

    if App.is_child
      @_subscribe "update", (response) => @load_data(response)

    @load_data(data)

    undefined

  channel_namespace: -> "report.#{@data.permalink}"

  on: (event, callback) -> @callbacks.on(event, callback)

  name: -> @data.name
  permalink: -> @data.permalink
  form: -> App.Models.Form.new(@form_permalink)

  sandboxes: ->
    @_sandboxes ?= new App.Frame.Sandbox.Manager(@, "report.#{@permalink()}")
    @_sandboxes

  sandboxes_exist: ->
    @_sandboxes?

  has_permission: (value) ->
    @data.permissions[value]

  permissions: ->
    @data.permissions

  query: ->
    if !@loaded()
      throw new Error("query requires schema() to be called on the report")

    @_query ?= App.Query.from_parameters(undefined, @form(), @data.conditions)
    @_query

  load_data: (data, options) ->
    super(data, options)

    @_query.load(data.conditions) if !options? && @_query?

    @

  submissions: ->
    return Promise.resolve(@_submissions) if @_submissions?

    new App.Schema.SubmissionPromise (resolve, reject) =>
      @query().query().then (submissions) =>
        @_submissions = submissions
        resolve(@_submissions)
      .catch reject

  _request_schema: (options) ->
    @_route_get({
      api: { url: @data.urls.self },
      channel: {
        event: "schema.load.report",
        data: {
          form: @form_permalink,
          report: @permalink()
        }
      },
      options: options
    })

  schema: (options) ->
    source = @_requested_schema(options)
    source.promise ?= new Promise (resolve, reject) =>
      @_request_schema(options).then (response) =>
        source.data = response
        source.loaded = true

        @load_data(response, options)

        resolve(@)
      .catch (response) ->
        source.promise = undefined
        reject(response)

    source.promise

  require_schema: ->
    if !@loaded()
      throw new Error("method requires report.load() to be called and resolved")

  update: (parameters, options) ->
    @_route_update({
      api: {
        url: @data.urls.self,
        data: {
          report: App.Schema.Form.Report.parameters_to_data(parameters)
        }
      },
      channel: {
        event: "report.update",
        data: {
          form: @form_permalink,
          report: @permalink(),
          parameters: parameters
        }
      }
      options: options
    }).then =>
      return if App.is_child

      @callbacks.trigger("update", @)
      @form().reports.callbacks.trigger("update", @)

      App.Schema.change(@)

      @

  destroy: (options) ->
    @_route_destroy({
      api: {
        url: @data.urls.self
      },
      channel: {
        event: "report.destroy",
        data: {
          form: @form_permalink,
          report: @permalink(),
        }
      }
      options: options
    }).then =>
      return if App.is_child

      @form().reports.reload().then =>
        @callbacks.trigger("destroy", @)
        @form().reports.callbacks.trigger("destroy", @)

        App.Schema.change(@)

        App.Models.Form.redirect_on_child_destruction(
          @form(), Routes.report_path(@form().permalink(), @permalink())
        )

        @

  loaded: (options) ->
    source = @_requested_schema(options)
    source.loaded == true

  @parameters_to_data: (parameters) ->
    output = {}

    if parameters.name?
      output.name = parameters.name

    if parameters.personal?
      output.personal = parameters.personal

    if parameters.conditions?
      if parameters.conditions instanceof App.Query.Group
        output.conditions = parameters.conditions.serialize()
      else
        output.conditions = parameters.conditions

    output
