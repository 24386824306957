
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}
window.App.Interface.Menu.SubMenu ?= {}

class window.App.Interface.Menu.SubMenu.ChildOption extends App.Interface.Menu.SubMenu.BaseOption
  constructor: (@menu, @parent, options = {}) ->
    super(@menu, options)

  _generate: (options) ->
    options = App.Interface.Menu.SubMenu.parse_action(options)
    options = @_cache_options(options)

    @callback(options.callback) if options.callback?

    old_element = @element

    @id = options.id || @id || App.Helpers.Generators.uuid()
    @instance_id ?= options.instance_id || @id

    @_update_options(options.children) if options.children?
    @_detach_options()

    @element = App.Interface.Menu.SubMenu.generate_child_element(@, options)
    @button = @element.querySelector("*:not(.navbar_dropdown_option_children)")

    @_children_wrapper = @element.querySelector(".navbar_dropdown_option_children")

    old_element.parentNode.replaceChild(@element, old_element) if old_element?

    @_attach_options()

    @reapply_callbacks()

    @

  index: ->
    @parent._children.indexOf(@)

  # Returns the main dropdown parent option which appears in the Menu
  top_level_parent: ->
    return @parent if @parent instanceof App.Interface.Menu.SubMenu.Option

    @parent.top_level_parent()

  text: (value) ->
    text_node = undefined

    primary_child = @element.children[0]

    for node in primary_child.childNodes
      text_node ?= node if node.nodeType == 3

    return unless text_node?

    if value?
      text_node.nodeValue = value
      @_publish("text", { value: value })

    text_node.nodeValue

  destroy: ->
    @element.remove()

    @menu.callbacks.trigger("dropdown.child.remove", @id)
    @_publish("remove")

    delete @menu._custom_options[@id]

    undefined

  open: ->
    return unless @_children_wrapper?

    @_children_wrapper.classList.add("navbar_dropdown_visible")

    coords = @coords()
    button_width = @element.offsetWidth

    App.Helpers.Elements.Floating.position_element(
      @_children_wrapper, [
        [coords.right, coords.top]
        [coords.right - 2 * button_width, coords.top]
      ]
    )

    @_publish "open"

    @_children_wrapper

  close: ->
    return unless @_children_wrapper?

    @_children_wrapper.classList.remove("navbar_dropdown_visible")
    @close_all_options()

    @_publish "close"

    @_children_wrapper

  toggle_opened: ->
    if @opened() then @close() else @open()

  opened: ->
    return unless @_children_wrapper?

    @_children_wrapper.classList.contains("navbar_dropdown_visible")

  serialize: ->
    output = {}
    output.instance_id = @instance_id
    output.parent = @parent.instance_id

    output.id = @id
    output.index = @index()
    output.text = @text()
    output.opened = @opened()

    if @_children?
      output.options = []

      for child in @_children
        output.options.push(child.serialize())

    output

window.App.Interface.Menu.SubMenu.generate_child_element = (submenu_option, options) ->
  if options.separator
    element = document.createElement("DIV")
    element.className = "navbar_dropdown_option navbar_dropdown_separator"

    return element

  wrapper = document.createElement("DIV")
  wrapper.className = "navbar_dropdown_option"

  if options.html?
    if typeof options.html == "function"
      element = options.html()
    else
      element = App.Helpers.Elements.from_html(options.html)
  else
    element = document.createElement("A")

    icon_element = document.createElement("I")
    icon_element.className = "icon icon-#{options.icon}"

    text_node = document.createTextNode(options.text)

    element.appendChild(icon_element)
    element.appendChild(text_node)

  if element.tagName.toUpperCase() == "A"
    element.setAttribute("href", options.href)
    element.setAttribute("target", options.target) if options.target?

  element.setAttribute("disabled", "disabled") if options.disabled

  wrapper.appendChild(element)

  if options.children?
    wrapper.classList.add("navbar_dropdown_submenu")

    children = document.createElement("DIV")
    children.classList.add("navbar_dropdown", "navbar_dropdown_option_children")

    wrapper.appendChild(children)

    element.addEventListener "click", ((submenu_option) -> (event) ->
      event.preventDefault()
      submenu_option.toggle_opened()
    )(submenu_option)

  wrapper
