
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}

class window.App.Models.Submission.Field.Manager
  constructor: (@submission) ->
    @callbacks = new CallbackManager(@)

    @fields = []

    for field_element in @submission.form_element.find(".FIELD_CONTAINER")
      @add(field_element)

  on: (type, callback) -> @callbacks.add(type, callback)

  _find_by_parameter: (parameter, value) ->
    for field in @fields
      member = field[parameter]
      member_is_function = App.Helpers.Objects.isFunction(member)

      if member_is_function
        return field if member() == value
      else
        return field if member == value

    undefined

  _value_to_model: (value) ->
    if value instanceof App.Schema.Form.Field
      value = value.permalink()

    if typeof value == "string"
      if App.Helpers.Objects.isUuidString(value)
        field = @find_by_field_id(value)
        return field if field?

      return @find_by_column_name(value) || @find_by_name(value)
    else if value.nodeType?
      return @find_by_element($(value))
    else if App.Helpers.Elements.is_jquery(value)
      return @find_by_element(value)

    undefined

  _load_errors: (errors) ->
    applied_error_keys = []
    errors_for_base = []

    for field in @fields
      field_errors = []

      if errors[field.field_id]?
        applied_error_keys.push(field.field_id.toString())
        field_errors = field_errors.concat(errors[field.field_id])

      if errors[field.column_name] && field.column_name != "base"
        applied_error_keys.push(field.column_name)
        field_errors = field_errors.concat(errors[field.column_name])

      field.errors.load(field_errors)

    # Show errors for fields that are not displayed at the top level
    for error_key, error_value of errors
      if applied_error_keys.indexOf(error_key) == -1 && error_value? && error_value.length > 0
        errors_for_base = errors_for_base.concat(error_value)

    base_errors = @submission._element.querySelector(".BASE_ERRORS")
    base_errors.innerHTML = ""

    errors_for_base = [].concat.apply([], errors_for_base)

    for error in errors_for_base
      error_element = document.createElement("DIV")
      error_element.className = "ERROR"
      error_element.textContent = error

      base_errors.appendChild(error_element)

    undefined

  _apply_options: ->
    for field in @fields
      field._apply_options()

  find_by_index: (value) -> @fields[value]
  find_by_field_id: (value) -> @_find_by_parameter("field_id", value)
  find_by_column_name: (value) -> @_find_by_parameter("column_name", value)
  find_by_variable_name: (value) -> @_find_by_parameter("column_name", value)
  find_by_permalink: (value) -> @_find_by_parameter("field_id", value)
  find_by_name: (value) -> @_find_by_parameter("name", value)
  find_by_element: (value) -> @_find_by_parameter("_element", value) || @_find_by_parameter("element", value)
  find: (value) -> @_value_to_model(value)

  base_errors: ->
    elements = @submission._element.querySelectorAll(".BASE_ERRORS .ERROR")

    return [] if base_errors.length == 0

    errors = []

    for element in elements
      errors.push(errors.textContent)

    errors

  empty_errors: ->
    for field in @fields
      field.errors.empty()
    undefined

  any_errors: ->
    for field in @fields
      return true if field.errors.any()
    false

  all: -> @fields
  first: -> @fields[0]
  second: -> @fields[1]
  third: -> @fields[2]
  last: -> @fields[@fields.length - 1]

  add: (field) ->
    if field.nodeType?
      return unless field.dataset.id?
      field = new App.Models.Submission.Field.Object(@submission, field)

    @fields.push(field)
    @[field.column_name] ?= field

    field

  validate: ->
    field_promises = []

    for field in @fields
      field_promises.push(field.validate())

    Promise.all(field_promises)

  serialize: ->
    output = []

    for field in @all()
      output.push(field.serialize())

    output
