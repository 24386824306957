
$(document).on "click", ".open_table_kanban", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink

  App.Interface.Modal.from_url(Routes.new_kanban_path(form_permalink)).show()
  .then -> remove_loader(button)
  .catch (error) ->
    remove_loader(button)
    throw error

$(document).on "click", ".destroy_kanban", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  kanban_permalink = button.dataset.kanbanPermalink

  form = App.Models.Form.new(form_permalink)
  form.kanbans.query_by_permalink(kanban_permalink).then (kanban) ->
    kanban.destroy()
    .then ->
      remove_loader button
    .catch (xhr) ->
      remove_loader button
      App.Errors.xhr_error(xhr)

$(document).on "click", ".kanban_settings", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  kanban_permalink = button.dataset.permalink

  form = App.Models.Form.new(form_permalink)

  form.kanbans.schema().then ->
    kanban = form.kanbans.find_by_permalink(kanban_permalink)
    return unless kanban?

    modal = App.Elements.ResponseKanban.settings_modal(kanban)
    modal.show()
    .then -> remove_loader button
    .catch (error) ->
      remove_loader button
      throw error

$(document).on "submit", ".new_kanban_form", (event) ->
  event.preventDefault()

  form_element = event.currentTarget
  button = form_element.querySelector("button[type=submit], input[type=submit]")

  return if loading button

  add_loader button

  kanban_data = App.Helpers.Forms.data(form_element).kanban

  form_permalink = form_element.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  modal_element = App.Helpers.Elements.closest(form_element, ".modal-container")
  modal = App.Interface.Modal.find_by_id(modal_element.id)

  form.kanbans.create(kanban_data)
  .then (kanban) ->
    remove_loader button
    modal.hide()

    App.redirect_to Routes.kanban_path(kanban.form().permalink(), kanban.permalink())
  .catch (xhr) ->
    remove_loader button
    modal.show()

    apply_errors_to_form(form_element, xhr.responseJSON.errors)

$(document).on "submit", ".edit_kanban_form", (event) ->
  event.preventDefault()

  form_element = event.currentTarget
  button = form_element.querySelector("button[type=submit], input[type=submit]")

  return if loading button

  add_loader button

  kanban_data = App.Helpers.Forms.data(form_element).kanban

  form_permalink = form_element.dataset.formPermalink
  kanban_permalink = form_element.dataset.kanbanPermalink

  form = App.Models.Form.new(form_permalink)

  form.kanbans.query_by_permalink(kanban_permalink).then (kanban) ->
    modal_element = App.Helpers.Elements.closest(form_element, ".modal-container")
    modal = App.Interface.Modal.find_by_id(modal_element.id)

    kanban.update(kanban_data)
    .then ->
      remove_loader button
      modal.hide()
    .catch (xhr) ->
      remove_loader button
      modal.show()

      apply_errors_to_form(form_element, xhr.responseJSON.errors)

$(document).on "click", ".kanban_item", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  submission_permalink = button.dataset.permalink

  form = App.Models.Form.new(form_permalink)
  form.submissions.open(submission_permalink)
  .then -> remove_loader button
  .catch (xhr) -> remove_loader button

$(document).on "click", ".kanban_category_title", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  wrapper = App.Helpers.Elements.closest(button, ".kanban_wrapper")
  category = App.Helpers.Elements.closest(button, ".kanban_category")

  return unless wrapper? && category?

  form_permalink = wrapper.dataset.formPermalink

  category_field = wrapper.dataset.categoryField
  category_value = category.dataset.category

  form = App.Models.Form.new(form_permalink)

  form.submissions.new_or_unsaved(selector: "#{category_field}=#{category_value}")
  .then (submission) ->
    remove_loader button
    field = submission.fields.find_by_column_name(category_field)
    field.value(category_value)
  .catch (xhr) -> remove_loader button
