class window.FilterWidget
  # Accept arguments in the patterns (input, list, options) or (options)
  constructor: (argument1, argument2, argument3) ->
    args = App.Helpers.Arguments.overload(
      argument1, argument2, argument3, [
        [["input", HTMLElement], ["list", HTMLElement], ["options", Object]],
        [["input", jQuery], ["list", jQuery], ["options", Object]],
        [["input", HTMLElement], ["list", HTMLElement]],
        [["input", jQuery], ["list", jQuery]],
        [["options", Object]]
      ]
    )

    if args.input? && App.Helpers.Elements.is_jquery(args.input)
      args.input = args.input[0]

    if args.list? && App.Helpers.Elements.is_jquery(args.list)
      args.list = args.list[0]

    @options = args.options || {}

    @options.input = args.input if args.input?
    @options.list = args.list if args.list?

    @options.value_attribute ?= "data-value"
    @options.selector ?= "li"
    @options.case_sensitive ?= false

    @input = @options.input
    @list = @options.list

    @input.addEventListener "input", (event) =>
      @filter(event.target.value)

  filter: (search = "") ->
    searches = FilterWidget.parse_search(search)

    for element in @list.querySelectorAll(@options.selector)
      if FilterWidget.match_search(element, searches, @options)
        element.style.display = "block"
        element.classList.remove("filter-hidden")
      else
        element.style.display = "none"
        element.classList.add("filter-hidden")

    search

  @match_search = (element, searches, options) ->
    return true if searches.length == 0

    content = element.textContent
    attribute = element.getAttribute(options.value_attribute)

    if !options.case_sensitive
      content = content.toLowerCase()
      attribute = content.toLowerCase()

    for search in searches
      if !options.case_sensitive
        search = search.toLowerCase()

      if !content.includes(search) && !attribute.includes(search)
        return false

    true

  @parse_search: (search) ->
    search = search.trim()
    searches = []

    open_phrase = false
    current_search = ""

    for character, index in search
      if character == '"'
        if open_phrase
          open_phrase = false
          searches.push(current_search)
        else
          open_phrase = true

        continue

      if index == search.length - 1 || (!open_phrase && character == ' ')
        searches.push(current_search)
        current_search = ""
      else
        current_search += character

    searches
