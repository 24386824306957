import 'app/interface/sidebar/container'
import 'app/interface/sidebar/drawers'
import 'app/interface/sidebar/side_bar'
import 'app/interface/sidebar/overview'
import 'app/interface/sidebar/grouping'


window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Sidebar ?= {}

window.App.Interface.Sidebar.MOBILE_LINK_SELECTOR = "a.option, .option a, .option button, .option label, .option input[type=button], .option input[type=submit]"
window.App.Interface.Sidebar.MOBILE_SEARCH_SELECTOR = ".option.search_option a, .option.search_input a"

window.App.Interface.Sidebar.animation_time = 350

window.App.Interface.Sidebar._models = []

window.App.Interface.Sidebar.all = ->
  output = []

  if App.Interface.Sidebar._overview_model?
    output.push(App.Interface.Sidebar._overview_model)

  output = output.concat(App.Interface.Sidebar._models)

  output

window.App.Interface.Sidebar.current = ->
  for sidebar in App.Interface.Sidebar.all()
    return sidebar if sidebar.active()

  context = App.context()
  return App.Interface.Sidebar._fallback_model unless context?
  context.sidebar() || App.Interface.Sidebar._fallback_model

window.App.Interface.Sidebar.overview = ->
  if !App.Interface.Sidebar._overview_model?
    wrapper = document.getElementById("document-sidebar-inner")

    # Create a dummy sidebar element for compatibility if the page has none.
    if !wrapper?
      sidebar = document.createElement("DIV")
    else
      sidebar = wrapper.querySelector(".overview-sidebar")

    if !sidebar?
      sidebar = document.createElement("DIV")
      sidebar.className = "overview-sidebar hidden"
      wrapper.appendChild(sidebar)

    model = new App.Interface.Sidebar.OverviewSideBar(sidebar)

    App.Interface.Sidebar._overview_model = model

  App.Interface.Sidebar._overview_model

window.App.Interface.Sidebar.find_or_current = (selector) ->
  if selector?
    App.Interface.Sidebar.find(selector)
  else
    App.Interface.Sidebar.current()

window.App.Interface.Sidebar.find_or_add = (element) ->
  App.Interface.Sidebar.find(element) || App.Interface.Sidebar.add(element)

window.App.Interface.Sidebar.find = (element) ->
  if App.Sidebar._overview_model?
    overview_element = App.Sidebar._overview_model.element

    if overview_element == element || overview_element.id == element
      return App.Sidebar._overview_model

  for sidebar in App.Interface.Sidebar._models
    return sidebar if sidebar.element == element
    return sidebar if sidebar.element.id == element

  undefined

window.App.Interface.Sidebar.add = (element) ->
  sidebar = App.Interface.Sidebar.find(element)
  return sidebar if sidebar?

  if element.classList.contains("overview-sidebar")
    model = new App.Interface.Sidebar.OverviewSideBar(element)
    App.Interface.Sidebar._overview_model = model
  else if element.classList.contains("grouping-sidebar")
    model = App.Interface.Sidebar.GroupingSideBar.find_by_permalink(element.dataset.permalink)

    if !model?
      model = new App.Interface.Sidebar.GroupingSideBar(element)
      App.Interface.Sidebar._models.push model
  # This is a fallback for older pages. It may introduce bugs, so when
  # all pages are moved to the page-sidebar syntax, this should be removed.
  else if element.classList.contains("document-sidebar")
    new_element = document.createElement("DIV")
    new_element.className = "page-sidebar"

    for child in element.childNodes
      new_element.appendChild(element.childNodes[0])

    element.appendChild(new_element)

    model = new App.Interface.Sidebar.SideBar(new_element)
    App.Interface.Sidebar._fallback_model = model
  else
    model = new App.Interface.Sidebar.SideBar(element)
    App.Interface.Sidebar._models.push model

  model

# Check if sidebar is currently in mobile mode
window.App.Interface.Sidebar.mobile = ->
  menu = App.menu()
  return false unless menu?
  mobile_icon = menu.element.querySelector(".icon.show_mobile")
  return false unless mobile_icon?

  App.Helpers.Elements.visible(mobile_icon)

window.App.Interface.Sidebar.hide_all_except = (exception) ->
  for sidebar in App.Interface.Sidebar.all()
    sidebar.hide() unless sidebar == exception

  null

window.App.Sidebar = App.Interface.Sidebar
window.App.sidebar = App.Interface.Sidebar.find_or_current

window.App.overview_sidebar = App.Interface.Sidebar.overview

$ ->
  document_sidebar = document.getElementById("document-sidebar")
  return unless document_sidebar?

  sidebar = document_sidebar.querySelector(".overview-sidebar, .page-sidebar")

  if sidebar?
    model = App.Interface.Sidebar.add(sidebar)
    model.calculate_button_visibility()
  else
    # Fallback for pages without overview sidebars or page-sidebars
    App.Interface.Sidebar.add(document_sidebar)

App.Models.bind_function ".overview-sidebar, .page-sidebar", (element) ->
  return if element.classList.contains("initialized")
  App.Interface.Sidebar.add(element)

$(document).on "page:load", ->
  sidebar = App.sidebar()
  return unless sidebar?
  sidebar.calculate_button_visibility()
