
$(document).on "click", ".SUBMISSION .SECRET_CONTAINER:not(.loaded)", (event) ->
  event.preventDefault()

  field_element = event.currentTarget

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)
  field.helpers.load()

$(document).on "click", ".reveal_submission_secret:not(.loading)", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading(button)

  add_loader(button)

  $.ajax
    url: button.href
    type: "POST"
    data: {
      authenticity_token: current_user.authenticity_token()
    }
  .done (response) ->
    parent = button.parentNode
    parent.innerHTML = ""
    parent.textContent = response.value
  .fail ->
    remove_loader(button)