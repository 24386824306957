
window.App ?= {}
window.App.Schema ?= {}

class window.App.Schema.User
  @_models: {}

  @load: (data) ->
    @_models[data.username] ?= new App.Schema.User(data)
    @_models[data.username]

  @schema: ->
    @_schema_promise ?= new Promise (resolve, reject) =>
      $.ajax
        url: "/api/rest/v1/users/"
      .done (response) =>
        for data in response.users
          @load(data)

        resolve(@)
      .fail (response) ->
        @_schema_promise = undefined

        reject(response.responseJSON)

    @_schema_promise

  @load: -> @schema()
  @loaded: -> @_schema_promise?

  @require_schema: ->
    if !@_schema_promise?
      throw new Error("all requires App.Schema.User.schema() to be called first")

  @all: ->
    @require_schema()

    Object.values(@_models)

  constructor: (@_data = {}) ->

  username: ->
    @_data.username

  name: ->
    @_data.name || @_data.username || "Guest Account"

  guest: ->
    !@username()

  schema: (options) ->
    @_schema_promise ?= new Promise (resolve, reject) =>
      $.ajax
        url: App.Schema.source_url("/api/rest/v1/users/#{@username()}", options)
      .done (response) =>
        @_schema_data = response
        resolve(@)
      .fail (response) =>
        @_schema_promise = undefined
        reject(response.responseJSON)

    @_schema_promise

  load: (options) -> @schema(options)

  groups: ->
    new Promise (resolve, reject) =>
      @schema().then =>
        output = []

        for group in @_schema_data.groups
          output.push App.Schema.Group.new(group)

        resolve(output)

  serialize: ->
    {
      username: @username(),
      name: @name()
    }
