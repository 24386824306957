
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseQuery ?= {}

window.App.Elements.ResponseQuery._reports = {}

window.App.Elements.ResponseQuery.from_report = (report) ->
  existing_report = App.Elements.ResponseQuery._reports[report.permalink()]
  return existing_report if existing_report?
  query_editor = new App.Elements.ResponseQuery.Query(report.form(), report.query())
  query_editor.report = report

  App.Elements.ResponseQuery._reports[report.permalink()] = query_editor

  query_editor.on "report.update", =>
    context = App.context(Routes.report_path(report.form().permalink(), report.permalink()))
    context.refresh() if context?

  query_editor
