
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Generators ?= {}

# Generate a UUID based on the current time
window.App.Helpers.Generators.uuid = ->
  date = new Date().getTime()

  uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace /[xy]/g, (c) ->
    r = (date + Math.random() * 16) % 16 | 0
    d = Math.floor(date / 16)
    (if c == "x" then r else (r&0x3|0x8)).toString(16)

window.App.Helpers.Generators.permalink = ->
  value = ""
  characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

  for [1..10]
    value += characters.charAt(Math.floor(Math.random() * characters.length))

  value

window.App.Helpers.Generators.instance_id = ->
  App.Helpers.Generators.uuid()

window.App.Helpers.Generators.unix_time = (date) ->
  App.Helpers.Times.unix(date)

window.App.Helpers.Generators.variable_name = (value) ->
  App.Helpers.Strings.variable_name(value)

# Returns the current Unix time (time in seconds)
window.unix_time = App.Helpers.Generators.unix_time

# Generate a UUID based on the current time
window.uuid = App.Helpers.Generators.uuid
