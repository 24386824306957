
$ ->
  return unless App.sidebar()?

  $(document).on "keydown", ".document-sidebar .search_input input", (event) ->
    if event.which == 13
      App.sidebar().mobile_hide()
      event.currentTarget.blur()

  if App.Sidebar.container()
    $(document).on "click", ".toggle_document_sidebar", (event) ->
      event.preventDefault()
      App.fullscreen(!App.is_fullscreen())
  else
    toggles = document.querySelectorAll(".toggle_document_sidebar")

    for button in toggles
      container = App.Helpers.Elements.closest(button, ".option")
      container.classList.add("mobile_hidden", "hidden")
      container.classList.remove("mobile_visible")

    if App.menu()?
      App.menu().calculate_visibility()

  $(document).on "click", ".toggle_overview_sidebar_return", (event) ->
    event.preventDefault()
    # App.overview_sidebar().show(return: { title: "Grouping", model: App.sidebar() })
    App.overview_sidebar().show()

  $(document).on "click", ".toggle_context_sidebar_return", (event) ->
    event.preventDefault()
    App.overview_sidebar().return_to_context()

  $(document).on "click", ".document-sidebar-background, .sidebar-option", ->
    App.sidebar().mobile_hide()

  $(document).on "click", ".close_mobile_sidebar", (event) ->
    event.preventDefault()
    App.sidebar().mobile_hide()

  $(document).on "click", ".document-sidebar .show_search_input", (event) ->
    event.preventDefault()

    App.menu().element.classList.add("mobile_search_visible")

    search_option = App.menu().element.querySelector(".search_button")
    search_input = App.menu().element.querySelector(".search_input")

    search_option.classList.add("hidden")
    search_input.classList.remove("hidden")

    input = search_input.querySelector("input")
    input.focus()

  $(document).on "click", App.Interface.Sidebar.MOBILE_SEARCH_SELECTOR, (event) =>
    button = event.currentTarget

    container = App.Interface.Sidebar.container()
    return unless container?

    container.mobile_hide()

$ ->
  return unless App.Sidebar.container()?

  App.Interface.ContextMenu.new(
    App.Sidebar.container().element,
    ".sidebar-option[data-object=form]:not([disabled=disabled])",
    [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href, target: "_blank")
      },
      {
        text: (event, menu) ->
          response_text = menu.element.dataset.responseText || "Response"
          "#{i18n.t("new")} #{response_text}"
        icon: "plus",
        if: (event, menu) -> menu.element.dataset.permissionCreate == "true"
        callback: (event, menu) ->
          App.Models.Form.new(menu.element.dataset.permalink).submissions.open_new()
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
      },
      {
        text: i18n.t("forms.edit"),
        icon: "pencil",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          add_page_loader()
          App.hard_redirect_to Routes.edit_form_path(menu.element.dataset.permalink)
      },
      {
        text: i18n.t("permissions"),
        icon: "cog",
        if: -> current_user.manager()
        callback: (event, menu) ->
          modal = App.Models.Form.new(menu.element.dataset.permalink).permissions_modal()
          modal.show().catch (error) ->
            App.overview_sidebar().refresh()
            throw error
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          form = App.Models.Form.new(menu.element.dataset.permalink)
          form.destroy().done ->
            App.overview_sidebar().refresh()

            if App.Helpers.URIs.equivalent(Routes.form_path(form.permalink()), window.location.href)
              App.redirect_to Routes.forms_path()
      }
    ]
  )

  App.Interface.ContextMenu.new(
    App.Sidebar.container().element,
    ".sidebar-option[data-object=report]:not([disabled=disabled])",
    [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href, target: "_blank")
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
      },
      {
        text: i18n.t("reports.rename"),
        icon: "pencil",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          form = App.Models.Form.new(menu.element.dataset.formPermalink)
          form.reports.schema().then ->
            report = form.reports.find_by_permalink(menu.element.dataset.permalink)
            report.schema().then ->
              query_editor = App.Elements.ResponseQuery.from_report(report)
              query_editor.hide()
              query_editor.update_report_with_name()
      },
      {
        text: i18n.t("reports.edit"),
        icon: "pencil",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          form = App.Models.Form.new(menu.element.dataset.formPermalink)
          form.reports.schema().then ->
            report = form.reports.find_by_permalink(menu.element.dataset.permalink)
            report.schema().then ->
              query_editor = App.Elements.ResponseQuery.from_report(report)
              query_editor.show()
      },
      {
        text: i18n.t("permissions"),
        icon: "cog",
        if: -> current_user.manager()
        callback: (event, menu) ->
          modal = App.Models.Form.new(menu.element.dataset.formPermalink).report_permissions_modal(menu.element.dataset.permalink)
          modal.show().catch (error) ->
            App.overview_sidebar().refresh()
            throw error
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          form = App.Models.Form.new(menu.element.dataset.formPermalink)
          form.reports.schema().then ->
            report = form.reports.find_by_permalink(menu.element.dataset.permalink)
            report.destroy().then ->
              App.overview_sidebar().refresh()

              report_path = Routes.report_path(form.permalink(), report.permalink())
              if App.Helpers.URIs.equivalent(report_path, window.location.href)
                App.redirect_to Routes.forms_path()
      }
    ]
  )

  App.Interface.ContextMenu.new(
    App.Sidebar.container().element,
    ".sidebar-option[data-object=calendar]:not([disabled=disabled])",
    [
      {
        text: i18n.t("delete"),
        icon: "reply",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href, target: "_blank")
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
      },
      {
        text: i18n.t("calendars.edit"),
        icon: "pencil",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          form = App.Models.Form.new(menu.element.dataset.formPermalink)
          calendar_permalink = menu.element.dataset.permalink

          form.calendars.schema().then ->
            calendar = form.calendars.find_by_permalink(calendar_permalink)
            return unless calendar?

            modal = App.Elements.ResponseCalendar.settings_modal(calendar)
            modal.show().catch (error) ->
              App.overview_sidebar().refresh()
              throw error
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          form = App.Models.Form.new(menu.element.dataset.formPermalink)
          form.calendars.query_by_permalink(menu.element.dataset.permalink).then (calendar) ->
            calendar.destroy()
      }
    ]
  )

  App.Interface.ContextMenu.new(
    App.Sidebar.container().element,
    ".sidebar-option[data-object=kanban]:not([disabled=disabled])",
    [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href, target: "_blank")
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
      },
      {
        text: i18n.t("kanbans.edit"),
        icon: "pencil",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          form = App.Models.Form.new(menu.element.dataset.formPermalink)
          kanban_permalink = menu.element.dataset.permalink

          form.kanbans.schema().then ->
            kanban = form.kanbans.find_by_permalink(kanban_permalink)
            return unless kanban?

            modal = App.Elements.ResponseKanban.settings_modal(kanban)
            modal.show().catch (error) ->
              App.overview_sidebar().refresh()
              throw error
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        if: (event, menu) -> menu.element.dataset.permissionEditForm == "true"
        callback: (event, menu) ->
          form = App.Models.Form.new(menu.element.dataset.formPermalink)
          form.kanbans.query_by_permalink(menu.element.dataset.permalink).then (kanban) ->
            kanban.destroy()
      }
    ]
  )

  App.Interface.ContextMenu.new(
    App.Sidebar.container().element,
    ".sidebar-option[data-object=form_folder]:not([disabled=disabled])",
    [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href, target: "_blank")
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
      },
      {
        text: i18n.t("form_folders.edit"),
        icon: "pencil",
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
        callback: (event, menu) ->
          route = Routes.edit_form_folder_path(menu.element.dataset.permalink)
          modal = App.Interface.Modal.from_url(route)
          modal.show()
          modal.on "submit", (event) ->
            event.preventDefault()

            App.Helpers.Forms.ajax_submit(event.currentTarget).then ->
              App.Sidebar.overview().refresh(selected: {
                type: "form_folder",
                permalink: menu.element.dataset.permalink
              })

              modal.hide()
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
        callback: (event, menu) ->
          permalink = menu.element.dataset.permalink

          $.ajax
            url: Routes.destroy_form_folder_path(permalink, format: "json")
            type: "DELETE"
            data: { authenticity_token: current_user.authenticity_token() }
          .done ->
            App.overview_sidebar().refresh()

            folder_path = Routes.form_folder_path(permalink)
            if App.Helpers.URIs.equivalent(folder_path, window.location.href)
              App.redirect_to Routes.forms_path()
      }
    ]
  )

  App.Interface.ContextMenu.new(
    App.Sidebar.container().element,
    ".sidebar-option[data-object=custom_form_view]:not([disabled=disabled])",
    [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href, target: "_blank")
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          $.ajax
            url: Routes.destroy_form_custom_view_path(form_permalink, permalink, format: "json")
            type: "DELETE"
            data: { authenticity_token: current_user.authenticity_token() }
          .done ->
            App.overview_sidebar().refresh()

            custom_view_path = Routes.form_custom_view_path(permalink)
            if App.Helpers.URIs.equivalent(custom_view_path, window.location.href)
              App.redirect_to Routes.submissions_path(form_permalink)

      }
    ]
  )

  App.Interface.ContextMenu.new(
    App.Sidebar.container().element,
    ".sidebar-option[data-object=custom_dashboard_view]:not([disabled=disabled])",
    [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href, target: "_blank")
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
        callback: (event, menu) ->
          permalink = menu.element.dataset.permalink

          $.ajax
            url: Routes.destroy_dashboard_custom_view_path(permalink, format: "json")
            type: "DELETE"
            data: { authenticity_token: current_user.authenticity_token() }
          .done ->
            App.overview_sidebar().refresh()

            dashboard_path = Routes.dashboard_custom_view_path(permalink)
            if App.Helpers.URIs.equivalent(dashboard_path, window.location.href)
              App.redirect_to Routes.forms_path()

      }
    ]
  )

  App.Interface.ContextMenu.new(
    App.Sidebar.container().element,
    ".sidebar-option[data-object=grouping]:not([disabled=disabled])",
    [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          App.redirect_to(menu.element.href, target: "_blank")
      },
      {
        separator: true,
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
      },
      {
        text: i18n.t("delete"),
        icon: "cancel",
        if: (event, menu) -> menu.element.dataset.permissionManage == "true"
        callback: (event, menu) ->
          permalink = menu.element.dataset.permalink
          form_permalink = menu.element.dataset.formPermalink

          $.ajax
            url: Routes.form_grouping_path(form_permalink, permalink)
            type: "DELETE"
            data: { authenticity_token: current_user.authenticity_token() }
          .done ->
            App.overview_sidebar().refresh()

            # TODO: Only redirect if the current page is the grouping or a set of the grouping
            App.redirect_to Routes.forms_path()

      }
    ]
  )
