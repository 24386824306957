
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

class window.App.Elements.ResponseTable.Column
  @build_element: (field, options = {}) ->
    column = document.createElement("TH")
    column.className = "column_#{field.variable_name()} colhead"

    column.setAttribute("data-column-name", field.variable_name())
    column.setAttribute("data-column-variable", field.variable_name())
    column.setAttribute("data-column-type", field.field_type())
    column.setAttribute("data-column-permalink", field.permalink())
    column.setAttribute("data-column-width", 300)

    if field.header
      column.setAttribute("data-report-type", "header")
    else
      column.setAttribute("data-report-type", "fields")

    column.setAttribute("data-table-column-name", field.tabular_name())

    column_inner = document.createElement("DIV")
    column_inner.className = "td-content"
    column_inner.textContent = field.tabular_name()

    column_grip = document.createElement("DIV")
    column_grip.className = "table_column_grip"

    column.appendChild(column_inner)
    column.appendChild(column_grip)

    column

  @generate_context_menus: (table) ->
    window.cm = App.Interface.ContextMenu.new(table.table_head_element, ".colhead:not(.fake-cell):not(.row_select_all_td)", [
      {
        text: i18n.t("column_options.rename"),
        icon: "font",
        active: -> table.column(@element).renameable()
        children: [
          {
            html: (event, menu) -> App.Elements.ResponseTable.column_rename_content(table, event, menu)
            listener: -> null
          }
        ]
      },
      {
        text: i18n.t("column_options.ordering"),
        icon: "arrow-combo",
        active: -> table.has_permission("edit_form") && table.column(@element).orderable(),
        callback: -> table.column(@element).custom_sorting()
      },
      {
        text: i18n.t("column_options.coloring"),
        icon: "droplet",
        active: -> table.has_permission("edit_form") && table.column(@element).colorable(),
        callback: -> table.column(@element).custom_coloring()
      },
      {
        separator: true,
        active: -> table.column(@element).display_customizable() && table.column(@element).resource_creatable()
      },
      {
        text: i18n.t("column_options.new_resource.grouping"),
        icon: "menu",
        active: -> table.has_permission("edit_form") && table.column(@element).multiple_grouping_menu_children()
        children: -> table.column(@element).grouping_menu_children()
        cache_children: false
        paginate_children: 5
      },
      {
        text: i18n.t("column_options.new_resource.grouping"),
        icon: "menu",
        active: -> table.has_permission("edit_form") && table.column(@element).single_grouping_menu_child()
        callback: (event, menu) ->
          column = table.column(@element)
          operator = column.condition_class().groupings[0]
          operator_active = column.existing_grouping_operators().indexOf(operator) != -1

          if operator_active
            column.ungroup({ operator: operator })
          else
            column.group({ operator: operator })

          menu.hide()
      },
      {
        text: i18n.t("column_options.advanced_search"),
        icon: "filter",
        active: -> table.column(@element).searchable(),
        children: -> table.column(@element).query_menu_children()
      },
      {
        text: i18n.t("column_options.new_resource.aggregation"),
        icon: "link",
        active: -> table.column(@element).resource_aggregation_creatable()
        children: -> table.column(@element).aggregation_menu_children()
        cache_children: false
      },
      {
        separator: true,
        active: -> table.column(@element).resource_creatable() && table.column(@element).filterable()
      },
      {
        text: i18n.t("column_options.new_resource.calendar"),
        icon: "calendar",
        active: -> table.column(@element).resource_calendar_creatable()
        callback: ->
          column = table.column(@element)
          table.form().calendars.create({ start_field: column.field_permalink, report: table.params.report() }).then (calendar) ->
            App.redirect_to Routes.calendar_path(table.form().permalink(), calendar.permalink())
      },
      {
        text: i18n.t("column_options.new_resource.kanban"),
        icon: "columns",
        active: -> table.column(@element).resource_kanban_creatable()
        callback: ->
          column = table.column(@element)
          table.form().kanbans.create({ category_field: column.field_permalink, report: table.params.report() }).then (kanban) ->
            App.redirect_to Routes.kanban_path(table.form().permalink(), kanban.permalink())
      },
      {
        text: i18n.t("column_options.new_field"),
        icon: "plus"
        active: -> table.column(@element).resource_creatable(),
        children: -> table.column(@element).new_field_menu_children()
      },
      {
        separator: true,
        active: -> table.has_permission("edit_form") && table.column(@element).hideable()
      },
      {
        text: i18n.t("column_options.hide"),
        icon: "cancel"
        active: -> table.has_permission("edit_form") && table.column(@element).hideable()
        callback: -> table.column(@element).hide()
      }
    ])

  constructor: (@table, @element) ->
    @column_text = @element.querySelector(".td-content")

    @field_id = @element.dataset.columnName
    @field_type = @element.dataset.columnType
    @field_variable = @element.dataset.columnVariable || @field_id
    @field_permalink = @element.dataset.columnPermalink

    @column_type = @element.dataset.reportType
    @column_name = @element.dataset.tableColumnName
    @column_width = @initial_column_width()

    if !@column_name?
      if @column_text?
        @column_name = @column_text.textContent
      else
        @column_name = @element.textContent

    @find_all_colhead_elements()

  index: (value, options) ->
    if value?
      columns = @table.table_head_element.querySelectorAll(".colhead")
      old_index = App.Helpers.Elements.index(@element)
      fake_cell = @table.table_head_element.querySelector(".fake-cell")

      if @table.column("checkbox")? && !@row_select()
        value += 1

      if old_index? && old_index < value
        value += 1

      column_row = @table.table_head_element.querySelector("tr")
      column_row.insertBefore(@element, columns[value] || fake_cell)

      @table._order_columns_array()
      @table.save_column_positions() unless options? && options.persist == false
      @table.refresh() unless options? && options.refresh == false

    App.Helpers.Elements.index(@element)

  visible_index: ->
    @table.visible_columns().indexOf(@)

  visible_index_without_expander: ->
    @table.visible_columns_without_expander().indexOf(@)

  first: -> @index() == 0
  last: -> @index() == @table._columns_array.length - 1

  permalink: ->
    @field_permalink || @field_id

  variable_name: ->
    @field_variable || @field_id

  not_row_select: ->
    !@row_select()

  row_select: ->
    !@column_type? && @field_id == "checkbox"

  has_row_select: ->
    @element.classList.contains("with_row_select")

  has_row_expander: ->
    @element.classList.contains("with_row_expander")

  initial_column_width: ->
    if @row_select()
      if @has_row_select() && @has_row_expander()
        return 60
      else if @has_row_select()
        return 60
      else if @has_row_expander()
        return 30

      return 60

    parseInt(@element.dataset.columnWidth || @element.offsetWidth)

  hideable: ->
    @not_row_select()

  renameable: ->
    @table.has_permission("edit_form") && @column_type == "fields"

  searchable: ->
    @sortable() && @not_row_select()

  filterable: ->
    return false unless App.feature("reports")
    @groupable() || @searchable()

  orderable: ->
    @column_type == "fields" && @field_type == "dropdown"

  sortable: ->
    return false if @column_type == "headers" && @field_id == "view"
    return false if @column_type == "headers" && @field_id = "edit"
    return false if @field_type == "button"

    !@element.classList.contains("sorting_disabled")

  colorable: ->
    @field_type == "dropdown"

  groupable: ->
    @column_type == "fields" && @sortable() && @condition_class().groupings?

  aggregatable: ->
    @searchable()

  display_customizable: ->
    @table.has_permission("edit_form") && @not_row_select() && (@renameable() || @orderable() || @colorable())

  resource_creatable: ->
    @table.has_permission("edit_form") && @not_row_select()

  resource_calendar_creatable: ->
    return false unless @resource_creatable()
    @column_type == "fields" && @field_type == "date_time"

  resource_kanban_creatable: ->
    return false unless @resource_creatable()
    @column_type == "fields" && @field_type == "dropdown"

  resource_aggregation_creatable: ->
    return false unless @resource_creatable()
    @aggregatable()

  condition_class: ->
    if App.Schema.Form.Header.columns.indexOf(@field_variable) != -1
      App.Query.Condition.class_from_types("header", @field_type)
    else
      App.Query.Condition.class_from_types("field", @field_type)

  query_menu_children: ->
    new Promise (resolve, reject) =>
      @table.form().schema().then =>
        return reject([]) unless @searchable()

        condition_class = @condition_class()

        return resolve([]) unless condition_class?

        output = []
        operators = condition_class.operators

        for operator in operators
          output.push(App.Elements.ResponseTable.column_query_action(@field_type, @, operator))

        resolve(output)

  aggregation_menu_children: ->
    return [] unless @searchable()

    condition_class = @condition_class()

    return [] unless condition_class? && condition_class.aggregators?

    output = []

    for operator in condition_class.aggregators
      output.push(App.Elements.ResponseTable.column_aggregator_action(@field_type, @, operator))

    output

  grouping_menu_children: ->
    return [] unless @groupable()

    condition_class = @condition_class()

    return [] unless condition_class? && condition_class.groupings?

    output = []

    for operator in condition_class.groupings
      output.push(App.Elements.ResponseTable.column_grouping_action(@field_type, @, operator))

    output

  multiple_grouping_menu_children: ->
    @grouping_menu_children().length > 1

  single_grouping_menu_child: ->
    @grouping_menu_children().length == 1

  new_field_menu_children: ->
    return [] unless @resource_creatable()

    output = []

    field_types = [
      "text", "text_area", "check_box", "number", "boolean", "date_time"
    ]

    for field_type in field_types
      output.push({
        text: i18n.t("field_types.#{field_type}"),
        children: [{
          html: App.Elements.ResponseTable.column_new_field_content(@, field_type),
          listener: -> null
        }]
      })

    output.push({
      text: "More",
      children: => @new_field_menu_more_children()
    })

    output

  new_field_menu_more_children: ->
    return [] unless @resource_creatable()

    output = []

    more_field_types = [
      "user", "rating", "address", "phone", "email", "attachment", "signature", "barcode"
    ]

    for field_type in more_field_types
      output.push({
        text: i18n.t("field_types.#{field_type}"),
        children: [{
          html: App.Elements.ResponseTable.column_new_field_content(@, field_type),
          listener: -> null
        }]
      })

    output

  initialize_sorting: ->
    return unless @sortable()

    @element.addEventListener("click", ((event) =>
      event.preventDefault()
      event.stopPropagation()

      # Releasing the resize mousedown should not sort the column
      return if App.Elements.ResponseTable.resized_column_in_last_second

      column_order = @order()
      @table.order_clear(false) unless event.shiftKey
      @order_toggle(column_order)

      return false
    ), false)

  custom_coloring: (mapping) ->
    return @_coloring_modal.show() if @_coloring_modal?

    @_coloring_modal = App.Interface.Modal.from_url(@table.routes.custom_color_column(@field_variable))

    @_coloring_modal.show()
    @_coloring_modal.on "load", (modal) =>
      modal.element.querySelector("form").addEventListener "submit", (event) =>
        event.preventDefault()

        App.Helpers.Forms.ajax_submit(event.currentTarget).then (response) =>
          modal.hide()
          @table.refresh()

  custom_sorting: (options_array) ->
    return @_sorting_modal.show() if @_sorting_modal?

    @_sorting_modal = App.Interface.Modal.from_url(@table.routes.custom_sort_column(@field_variable))

    @_sorting_modal.show()
    @_sorting_modal.on "load", (modal) =>
      sorting_options = new OptionListWidget(modal.container.querySelector(".option_list_container"))

      modal.element.querySelector("form").addEventListener "submit", (event) =>
        event.preventDefault()

        App.Helpers.Forms.ajax_submit(event.currentTarget).then (response) =>
          modal.hide()
          @table.refresh()

  visible: (status, options) ->
    if status?
      if status then @show(options) else @hide(options)

    @hidden != true

  hide: (options) ->
    @hidden = true

    if !options? || options.persist != false
      $.ajax
        url: @table.routes.hide_column(@field_variable)
        type: "POST"
        data: { authenticity_token: current_user.authenticity_token() }

    rows = @table.element.getElementsByTagName("TR")
    index = @index()
    visible_index = @visible_index()

    for row in rows
      if row.classList.contains("aggregation_row")
        App.Elements.ResponseTable.Row.remove_column_from_aggregation(visible_index, row)
      else if row.classList.contains("empty_table_row")
        # Do Nothing
      else if row.children[index]?
        row.children[index].style.display = 'none'

    undefined

  show: (options) ->
    @hidden = false

    if !options? || options.persist != false
      $.ajax
        url: @table.routes.show_column(@field_variable)
        type: "POST"
        data: { authenticity_token: current_user.authenticity_token() }

    rows = @table.element.getElementsByTagName("TR")
    index = @index()

    for row in rows
      if row.className != "empty_table_row" && row.children[index]?
        row.children[index].style.display = ''

    undefined

  toggle: (status) ->
    status ?= @hidden != true
    @visible(status)

  assign: (data, options) ->
    options.persist = false

    @visible(data.visible, options)
    @index(data.position, options)
    @field_permalink = data.permalink if data.permalink?

    data

  rename: (name) ->
    @column_text.textContent = name

    $.ajax
      url: @table.routes.rename_column(@field_variable)
      type: "POST"
      data: { authenticity_token: current_user.authenticity_token(), value: name }
    .done (response) => @column_text.textContent = response.name
    .fail (xhr) -> App.Errors.response_error(xhr)

  name: ->
    @column_text.textContent

  resize: (width, refresh = true) ->
    new Promise (resolve, reject) =>
      width = parseInt(width)
      width = @initial_column_width() if @row_select()

      return reject("Width is not a number") if isNaN(width)

      @column_width = width

      App.Elements.ResponseTable.column_resize_set_width(@, width)

      return resolve() unless refresh

      $.ajax
        url: @table.routes.resize_columns()
        method: "POST"
        data: {
          authenticity_token: current_user.authenticity_token()
          columns: {
            widths: [{ name: @permalink(), width: width }]
          }
        }
      .done -> resolve()
      .fail -> reject()

  resize_sync: ->
    App.Elements.ResponseTable.column_resize_set_width(@)

  search: (value) ->
    if value?
      @_search = value
      @table.refresh()

    @_search

  search_clear: ->
    @_search = undefined
    @table.refresh()

  order: (direction, refresh = true) ->
    if direction?
      @table._ordering ?= []

      @element.classList.remove("sort-asc", "sort-desc")
      @element.classList.add("sort-#{direction}")

      @_order = direction

      existing_ordering = @table._ordering.slice(0)

      for order in existing_ordering
        if order.column == @
          @table._ordering.splice(@table._ordering.indexOf(order), 1)

      @table._ordering.push({
        column: @,
        direction: direction
      })

      if refresh
        @table.refresh()
        @table.order_save()

    @_order

  order_toggle: (column_order, refresh = true) ->
    column_order ?= @order()

    # click 1 = "asc", click 2 = "desc", click 3 = "none"
    if !column_order? || column_order == ""
      @order("asc", refresh)
    else if column_order == "asc"
      @order("desc", refresh)
    else
      @order_clear(refresh)

  order_clear: (refresh = true) ->
    @element.classList.remove("sort-asc", "sort-desc")
    @_order = undefined

    if refresh
      @table.refresh()
      @table.order_save()

  query: (options) ->
    options.field = @field_variable

    new Promise (resolve, reject) =>
      @table.query.condition(options)

      @table.refresh()
      .done => resolve()
      .fail reject

  # TODO: Redirect to new set based on existing set with an added condition,
  # if the table already has a set.
  group: (options) ->
    options.field = @permalink()
    options.operator ?= "value"

    new Promise (resolve, reject) =>
      $.ajax
        url: @table.routes.grouping()
        type: "POST"
        data: {
          authenticity_token: current_user.authenticity_token(),
          dimension: options
          report: @table.params.report()
          set: {
            permalink: @table.config("resource", "grouping", "set", "permalink")
          }
        }
      .done (response) =>
        if @table.params.grouping()?
          @table.configuration.resource ?= {}
          @table.configuration.resource.grouping ?= {}
          @table.configuration.resource.grouping.dimensions ?= []
          @table.configuration.resource.grouping.dimensions.push(response.dimension)

        App.context(@table.element).sidebar().refresh()

        if response.set? && response.set.permalink?
          grouping = @table.params.grouping()
          grouping = if grouping? then grouping.grouping else response.permalink

          App.redirect_to Routes.form_grouping_set_path(
            @table.form().permalink(),
            grouping,
            response.set.permalink
          )

        @table.freeze()
        resolve()
      .fail (response) =>
        @table.refresh()
        .done => reject(response)
        .fail => reject(response)

  ungroup: (options) ->
    options.operator ?= "value"

    dimensions = @table.config("resource", "grouping", "dimensions") || []

    for dimension, index in dimensions
      continue unless dimension.operator == options.operator
      continue unless dimension.column == @permalink()

      operator_dimension = dimension.permalink
      operator_index = index

    return Promise.reject("Dimension Not Found") if !operator_dimension?

    dimensions.splice(operator_index, 1)

    new Promise (resolve, reject) =>
      $.ajax
        url: @table.routes.grouping_dimension(operator_dimension)
        type: "DELETE"
        data: {
          authenticity_token: current_user.authenticity_token()
        }
      .done (response) =>
        if response.destroyed
          App.overview_sidebar().refresh()

          App.redirect_to Routes.submissions_path(
            @table.form().permalink()
          )
        else
          App.context(@table.element).sidebar().refresh()

          if response.set? && response.set.permalink?
            grouping = @table.params.grouping()
            grouping = if grouping? then grouping.grouping else response.permalink

            App.redirect_to Routes.form_grouping_set_path(
              @table.form().permalink(),
              grouping,
              response.set.permalink
            )

        @table.freeze()
        resolve()
      .fail (response) =>
        @table.refresh()
        .done => reject(response)
        .fail => reject(response)

  aggregate: (options) ->
    options.field = @permalink()

    new Promise (resolve, reject) =>
      $.ajax
        url: @table.routes.create_aggregation()
        method: "POST"
        data: {
          authenticity_token: current_user.authenticity_token()
          aggregation: options
        }
      .done =>
        @table.refresh()
        .done => resolve()
        .fail reject

      .fail (response) =>
        @table.refresh()
        .done => reject(response)
        .fail => reject(response)

  deaggregate: (options) ->
    aggregation_cell = @table.element.querySelector(".aggregation_cell[data-field='#{@permalink()}'][data-operator='#{options.operator}']")
    return Promise.reject() unless aggregation_cell?

    permalink = aggregation_cell.dataset.permalink

    new Promise (resolve, reject) =>
      $.ajax
        url: @table.routes.aggregation(permalink)
        method: "DELETE"
        data: {
          authenticity_token: current_user.authenticity_token()
        }
      .done =>
        @table.refresh()
        .done => resolve()
        .fail reject

      .fail (response) =>
        @table.refresh()
        .done => reject(response)
        .fail => reject(response)

  existing_aggregation_operators: ->
    cells = @table.element.querySelectorAll(".aggregation_cell[data-field='#{@permalink()}']")
    return (cell.dataset.operator for cell in cells)

  unused_aggregation_operators: ->
    output = []
    existing = @existing_aggregation_operators()

    for operator in @condition_class().aggregators
      output.push(operator) if existing.indexOf(operator) == -1

    output

  existing_grouping_operators: ->
    output = []
    dimensions = @table.config("resource", "grouping", "dimensions") || []

    for dimension in dimensions
      output.push(dimension.operator) if dimension.column == @permalink()

    output

  find_all_colhead_elements: ->
    # Tables with sticky headers can have multiple TH elements
    if @row_select()
      all_headers = @table.table_element.querySelectorAll("thead .colhead.row_select_all_td")
    else
      all_headers = @table.table_element.querySelectorAll("thead .colhead[data-column-permalink='#{@field_permalink}']")

    @all_headers = Array.prototype.slice.call(all_headers)

window.App.Elements.ResponseTable.column_rename_content = (table, event, menu) ->
  column = table.column(menu.element)

  App.Elements.ResponseTable.column_input menu, column, column.name(), (value) ->
    column.rename(value)
    menu.hide()

window.App.Elements.ResponseTable.column_aggregator_action = (field_type, column, operator) ->
  operator_active = column.existing_aggregation_operators().indexOf(operator) != -1

  {
    text: App.Query.Condition.aggregator_name(field_type, operator),
    icon: (if operator_active then "dot" else null),
    icon_color: (if operator_active then "teal" else null),
    callback: (event, menu) ->
      if operator_active
        column.deaggregate({ operator: operator })
      else
        column.aggregate({ operator: operator })

      menu.hide()
  }

window.App.Elements.ResponseTable.column_grouping_action = (field_type, column, operator) ->
  operator_active = column.existing_grouping_operators().indexOf(operator) != -1

  {
    text: App.Query.Condition.grouping_name(field_type, operator),
    icon: (if operator_active then "dot" else null),
    icon_color: (if operator_active then "teal" else null),
    callback: (event, menu) ->
      if operator_active
        column.ungroup({ operator: operator })
      else
        column.group({ operator: operator })

      menu.hide()
  }

window.App.Elements.ResponseTable.column_query_action = (field_type, column, operator) ->
  if ["?", "!?"].indexOf(operator) == -1
    {
      text: App.Query.Condition.operator_name(field_type, operator),
      children: App.Elements.ResponseTable.column_query_content(column, operator)
    }
  else
    {
      text: App.Query.Condition.operator_name(field_type, operator),
      callback: (event, menu) ->
        column.query({ operator: operator })
        menu.hide()
    }

window.App.Elements.ResponseTable.column_query_content = (column, operator) ->
  (event, menu) ->
    [{
      html: App.Elements.ResponseTable.column_input menu, column, null, (value) ->
        column.query({ operator: operator, value: value })
        menu.hide()
    }]

window.App.Elements.ResponseTable.column_new_field_content = (column, field_type) ->
  (event, menu) ->
    App.Elements.ResponseTable.column_input menu, column, "Field Name", (value) ->
      menu.hide()

      column.table.form().schema().then (form) ->
        field_data = { name: value, field_type: field_type }

        form.fields.create(field_data).then (field) ->
          new_column = column.table.initialize_column(field)
          new_column.toggle(true)
          new_column.index(column.visible_index_without_expander() + 1)

window.App.Elements.ResponseTable.column_input = (menu, column, value, callback) ->
  form = document.createElement("FORM")
  form.className = "context_menu_form"
  form.action = "#"
  form.addEventListener "submit", (event) ->
    event.preventDefault()

    value = menu.menu.querySelector("input").value

    callback(value, menu, column)

  input_option = document.createElement("DIV")
  input_option.className = "context_menu_input"

  input = document.createElement("INPUT")
  input.name = "value"
  input.type = "text"
  input.value = value

  input_option.appendChild(input)

  save_option = document.createElement("DIV")
  save_option.className = "context_menu_actions"

  button = document.createElement("INPUT")
  button.className = "context_menu_option"
  button.type = "submit"
  button.value = "Save"

  save_option.appendChild(button)

  form.appendChild(input_option)
  form.appendChild(save_option)

  form
