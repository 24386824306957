
$(document).on "click", ".user_submission_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  username = button.dataset.user

  viewer = App.Elements.UserViewer.find_by_username(username)
  viewer.submission(event).then (submission) ->
    submission.show()
