
window.App ?= {}
window.App.Models ?= {}
window.App.Models.MailMergeHelpers ?= {}

class window.App.Models.MailMergeHelpers.Modals
  constructor: (@mail_merge) ->
    @_models = {}

  settings: ->
    if !@_models.settings?
      @_models.settings = App.Interface.Modal.from_url(
        @mail_merge.routes.settings()
      )

      @_models.settings.on "load", (modal) =>
        @mail_merge.element_model = new App.Elements.ResponseMailMerge.MailMerge(
          modal.element.querySelector("form"),
          @mail_merge.instance_id
        )

    @_models.settings
