
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.ColumnOrdering = {
  # Request-formatted ordering parameters
  ordering: ->
    return [] unless @_ordering?

    ordering = []

    for order in @_ordering
      ordering.push({
        column: order.column.index(),
        dir: order.direction
      })

    ordering

  order_clear: (refresh = true) ->
    @_ordering = []

    for column in @columns()
      column.order_clear(false)

    if refresh
      @refresh()
      @order_save()

  order_save: ->
    return if current_user.guest()

    ordering = []
    if @_ordering?
      for order in @_ordering
        ordering.push({
          name: order.column.field_id,
          direction: order.direction
        })

    $.ajax
      url: @routes.reorder_column()
      method: "PATCH"
      dataType: "json"
      contentType: "application/json"
      data: JSON.stringify({
        sort: { columns: ordering },
        authenticity_token: current_user.authenticity_token()
      })

  initialize_default_ordering: ->
    default_order = undefined

    if @element.dataset.defaultOrder? && @element.dataset.defaultOrder.length > 0
      default_order = JSON.parse(atob(@element.dataset.defaultOrder))

    # Field Tables should initially order by checkboxes
    if @params.field_exists()
      if @column("checkbox")?
        @column("checkbox").order("asc", false)

    if default_order? && default_order.length > 0
      for order in default_order
        column = @column(order[0])
        column.order(order[1], false) if column?
}
