
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}
window.App.Frame.Sandbox.Bind ?= {}

window.App.Frame.Sandbox.Bind.forms = (channel, options) ->
  if options.read == true
    channel.on "schema.load.forms", (event) ->
      App.Models.Form.schema()
      .then event.resolve
      .catch event.reject

  if options.read == true
    channel.on "schema.load.form", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.schema()
      .then event.resolve
      .catch event.reject

  if options.read == true
    channel.on "submission_data.query", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.schema().then ->
        form.submissions.query(event.data.query, event.data.options)
        .then event.resolve
        .catch event.reject
      .catch event.reject
