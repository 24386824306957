
# Handler for the "Share" button for form response tables
$(document).on "click", ".embed_form_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  form.embed_modal.show()
  .then -> remove_loader(button)
  .catch (error) ->
    remove_loader(button)
    throw error

# Handler for the "Share" button for report response tables
$(document).on "click", ".embed_report_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  report_permalink = button.dataset.reportPermalink
  report_search = button.dataset.reportSearch

  if report_permalink?
    request = form.share_report(report_permalink)
  else
    request = form.share_search(report_search)

  request
  .then ->
    remove_loader(button)
  .catch (error) ->
    remove_loader(button)
    throw error

# Handler for the "Share" button for calendars
$(document).on "click", ".embed_calendar_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  calendar_permalink = button.dataset.calendarPermalink
  request = form.share_calendar(calendar_permalink)

  request
  .then -> remove_loader(button)
  .catch (error) ->
    remove_loader(button)
    throw error

# Handler for the "Share" button for submissions
$(document).on "click", ".embed_submission_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  submission_permalink = button.dataset.permalink

  form.submissions.load(submission_permalink).then (submission) ->
    submission.modals.sharing().show()
    .then -> remove_loader(button)
    .catch (error) ->
      remove_loader(button)
      throw error
  .catch (error) ->
    remove_loader(button)
    throw error
