
$(document).on "click", ".SUBMISSION .BARCODE_LABEL", (event) ->
  event.preventDefault()

  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)

  # DispatchEvent not triggering file dialog
  $(field.helpers.barcode_input).trigger("click")

$(document).on "change", ".SUBMISSION .BARCODE_INPUT", (event) ->
  event.preventDefault()

  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)

  field.helpers.scan(event)

$(document).on "input", ".SUBMISSION .BARCODE_VALUE", (event) ->
  event.preventDefault()

  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)

  field.helpers.barcode_value.setAttribute("placeholder", "")
