
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Menus ?= {}

window.App.Models.Submission.Field.Menus.generate = ->
  list = document.createElement("UL")
  list.className = "TITLE_MENU TITLE_MENU_DROPDOWN dropit"

  list_trigger = document.createElement("LI")
  list_trigger.className = "dropit-trigger"

  list_button = document.createElement("A")
  list_button.href = "#"
  list_button.innerHTML = "<i class='icon icon-menu'></i>"

  list_submenu = document.createElement("UL")
  list_submenu.className = "dropit-submenu no-icons"

  list_trigger.appendChild(list_button)
  list_trigger.appendChild(list_submenu)

  list.appendChild(list_trigger)

  list

window.App.Models.Submission.Field.Menus.generate_option = (field, options) ->
  if options.label == "---"
    container = document.createElement("LI")
    container.appendChild(document.createElement("HR"))
    return container

  element = document.createElement("A")
  element.textContent = options.label

  if options.title?
    element.title = options.title

  if options.instance_id?
    element.dataset.instanceId = options.instance_id

  if options.icon?
    icon = document.createElement("I")
    icon.className = "icon-#{options.icon}"

    element.insertBefore(icon, element.firstChild)

  if options.href?
    element.target = "_blank"
    element.href = options.href
  else
    element.href = "#"

  container = document.createElement("LI")
  container.appendChild(element)

  if options.callback?
    element.addEventListener "click", ((callback) ->
      (event) ->
        event.preventDefault()
        callback.call(field, event)
    )(options.callback)

  if options.listener?
    element.addEventListener "click", ((callback) ->
      (event) ->
        callback.call(field, event)
    )(options.listener)

  container

window.App.Models.Submission.Field.Menus.generate_shortcut = (field, options) ->
  element = document.createElement("A")
  element.className = "TITLE_MENU TITLE_MENU_TEXT"
  element.textContent = options.label

  if options.title?
    element.title = options.title

  if options.instance_id?
    element.dataset.instanceId = options.instance_id

  if options.icon?
    icon = document.createElement("I")
    icon.className = "icon-#{options.icon}"

    element.insertBefore(icon, element.firstChild)

  if options.href?
    element.target = "_blank"
    element.href = options.href
  else
    element.href = "#"

  if options.callback?
    element.addEventListener "click", ((callback) ->
      (event) ->
        event.preventDefault()
        callback.call(field, event)
    )(options.callback)

  if options.listener?
    element.addEventListener "click", ((callback) ->
      (event) ->
        callback.call(field, event)
    )(options.listener)

  element
