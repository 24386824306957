
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}
window.App.Elements.ResponseTable.Helpers ?= {}

# Naming this Routes conflicts with the top-level JS-Routes plugin.
class window.App.Elements.ResponseTable.Helpers.Routing
  constructor: (@table) ->

  settings: ->
    if @table.params.report()?
      return Routes.report_columns_modal_path(
        @table.form().permalink(),
        @table.params.report()
      )

    return Routes.form_columns_modal_path(
      @table.form().permalink()
    )

  update_settings: ->
    if @table.params.report()?
      return Routes.update_report_columns_path(
        @table.form().permalink(),
        @table.params.report()
      )

    return Routes.update_form_columns_path(
      @table.form().permalink()
    )

  export: ->
    if @table.params.report()?
      return Routes.report_export_modal_path(
        @table.form().permalink(),
        @table.params.report(),
        username: @table.params.creator()
      )

    Routes.table_export_modal_path(
      @table.form().permalink(),
      username: @table.params.creator()
    )

  resize_columns: ->
    if @table.params.field_exists()
      return Routes.resize_field_table_columns_path(
        @table.form().permalink(),
        @table.params.field().id
      )

    if @table.params.report()?
      return Routes.resize_report_table_columns_path(
        @table.form().permalink(),
        @table.params.report()
      )

    Routes.resize_form_table_columns_path(
      @table.form().permalink()
    )

  reorder_column_positions: ->
    if @table.params.field_exists()
      return Routes.reorder_field_table_columns_path(
        @table.params.submission().form().permalink(),
        @table.params.field().column_name
      )

    if @table.params.report()?
      Routes.reorder_report_table_columns_path(
        @table.form().permalink(),
        @table.params.report()
      )

    Routes.reorder_form_table_columns_path(
      @table.form().permalink()
    )

  reorder_column: ->
    if @table.params.report()?
      return Routes.sort_report_table_path(
        @table.form().permalink()
        @table.params.report()
      )

    Routes.sort_form_table_path(
      @table.form().permalink()
    )

  rename_column: (column) ->
    Routes.rename_form_table_column_path(
      @table.form().permalink(),
      column
    )

  show_column: (column) ->
    if @table.params.field_exists()
      return Routes.show_field_table_column_path(
        @table.params.submission().form().permalink(),
        @table.params.field().column_name,
        column
      )

    if @table.params.report()?
      return Routes.show_report_table_column_path(
        @table.form().permalink(),
        @table.params.report(),
        column
      )

    Routes.show_form_table_column_path(
      @table.form().permalink(),
      column
    )

  hide_column: (column) ->
    if @table.params.field_exists()
      submission = @table.params.submission()

      return Routes.hide_field_table_column_path(
        @table.params.submission().form().permalink(),
        @table.params.field().column_name,
        column
      )

    if @table.params.report()?
      return Routes.hide_report_table_column_path(
        @table.form().permalink(),
        @table.params.report(),
        column
      )
 
    Routes.hide_form_table_column_path(
      @table.form().permalink(),
      column
    )

  custom_color_column: (column) ->
    Routes.table_column_coloring_modal_path(
      @table.form().permalink(),
      column
    )

  custom_sort_column: (column) ->
    Routes.table_column_sorting_modal_path(
      @table.form().permalink(),
      column
    )

  create_aggregation: ->
    if @table.params.report()?
      return Routes.create_report_aggregation_path(
        @table.form().permalink(),
        @table.params.report()
      )

    Routes.create_form_aggregation_path(
      @table.form().permalink()
    )

  aggregation: (permalink) ->
    if @table.params.report()?
      return Routes.report_aggregation_path(
        @table.form().permalink(),
        @table.params.report(),
        permalink
      )

    Routes.form_aggregation_path(
      @table.form().permalink(),
      permalink
    )

  grouping: ->
    if @table.params.grouping()?
      Routes.new_form_grouping_dimension_path(
        @table.form().permalink(),
        @table.params.grouping().grouping
      )
    else
      Routes.new_form_grouping_path(
        @table.form().permalink()
      )

  grouping_dimension: (dimension) ->
    Routes.form_grouping_dimension_path(
      @table.form().permalink(),
      @table.params.grouping().grouping,
      dimension
    )

  data: ->
    if @table.params.grouping_exists()
      return Routes.grouping_table_data_path(
        @table.form().permalink(),
        @table.params.grouping().grouping,
        @table.params.grouping().set,
        @table.params.url_data()
      )

    # Tables for submission connection fields
    if @table.params.field_exists()
      submission = @table.params.submission()

      if @table.params.report()? && !@table.params.query_changed_with_form_permission()
        if submission.persisted()
          return Routes.field_report_table_data_path(
            @table.form().permalink(),
            @table.params.report(),
            @table.params.field().field_id,
            submission.permalink(),
            @table.params.url_data()
          )
        else
          return Routes.new_field_report_table_data_path(
            @table.form().permalink(),
            @table.params.report(),
            @table.params.field().field_id,
            @table.params.url_data()
          )
      else
        if submission.persisted()
          return Routes.field_table_data_path(
            @table.form().permalink(),
            @table.params.field().field_id,
            submission.permalink(),
            @table.params.url_data()
          )
        else
          return Routes.new_field_table_data_path(
            @table.form().permalink(),
            @table.params.field().field_id,
            @table.params.url_data()
          )

    if @table.params.connected_to()
      return Routes.connection_table_data_path(
        @table.form().permalink(),
        @table.params.connected_to().field,
        @table.params.connected_to().submission,
        @table.params.url_data()
      )

    # Tables for the results of a report.
    if @table.params.report()? && !@table.params.query_changed_with_form_permission()
      return Routes.report_table_data_path(
        @table.form().permalink(),
        @table.params.report(),
        @table.params.url_data()
      )

    return Routes.table_data_path(
      @table.form().permalink(),
      @table.params.url_data()
    )
