window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.Signature
  constructor: (@field) ->
    @element = @field._element
    @drawn_signatures = (@element.dataset.drawnSignatures == "true")

    if @drawn_signatures == true
      @canvas = @element.querySelector(".SIGNATURE_CANVAS")
      @resize_canvas()

      App.Helpers.Assets.get_entrypoints("signature-pad.js").then (entrypoints) ->
        App.Helpers.Assets.require(entrypoints...)
      .then =>
        @drawing = new SignaturePad(@canvas)

  resize_canvas: ->
    # Subtract two for the horizontal border widths of the element
    @canvas.width = @element.offsetWidth - 2
    @canvas.height = @canvas.width / 5
    @canvas.getContext("2d", preserveDrawingBuffer: true)
    @canvas

  visible_drawing: ->
    !@element.querySelector(".SIGNATURE_FIELD").classList.contains("hidden")

  empty_drawing: ->
    @drawing.isEmpty()

  hide_drawing: ->
    App.Helpers.Elements.slide_up @element.querySelector(".SIGNATURE_FIELD")

  show_drawing: ->
    App.Helpers.Elements.slide_down @element.querySelector(".SIGNATURE_FIELD")

  toggle_drawing: (status) ->
    status ?= !@visible_drawing()
    if status then @show_drawing() else @hide_drawing()
    status

  hide_description: ->
    @element.querySelector(".SIGNATURE_DESCRIPTION").classList.add("hidden")

  show_description: ->
    @element.querySelector(".SIGNATURE_DESCRIPTION").classList.remove("hidden")

  clear_signature: ->
    return unless @drawn_signatures
    @show_description()
    @drawing.clear()

  _value: (val) ->
    if @drawn_signatures
      @clear_signature() if val == false
      return !@drawing.isEmpty()
    else
      @field.set_subfield(undefined, val) if val?
      field_hash = App.Helpers.Forms.data(@field._element).submission
      field_value = if field_hash? then field_hash[@field.field_id] else undefined
      return field_value

  data: ->
    if !@drawn_signatures || !@visible_drawing()
      field_hash = App.Helpers.Forms.data(@field._element).submission
      field_value = if field_hash? then field_hash[@field.field_id] else undefined

      return {
        signed: field_value.signed
      }

    output = {}
    output.signed = !@drawing.isEmpty()

    if output.signed
      output.attachment = [{
        file: @drawing.toDataURL(),
        persisted: false
      }]

    output
