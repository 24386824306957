
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseKanban ?= {}
window.App.Elements.ResponseKanban._settings_modals ?= {}

window.App.Elements.ResponseKanban.settings_modal = (model) ->
  model_is_element = model instanceof App.Elements.ResponseKanban.Kanban
  model_is_schema = model instanceof App.Schema.Form.Kanban

  if !model_is_element && !model_is_schema
    throw new Error("model must be a Schema or ResponseKanban")

  App.Elements.ResponseKanban._settings_modals[model.permalink()] ?= App.Interface.Modal.from_url(
    Routes.edit_kanban_path(model.form().permalink(), model.permalink()
    )
  )

  App.Elements.ResponseKanban._settings_modals[model.permalink()]

class window.App.Elements.ResponseKanban.Kanban
  constructor: (@element) ->
    window.kanban = @
    @element = @element[0] if App.Helpers.Elements.is_jquery(@element)
    @element.classList.add("initialized")

    @kanban_element = @element.querySelector(".kanban")

    @form_permalink = @element.dataset.formPermalink
    @kanban_permalink = @element.dataset.permalink

    @category_field = @element.dataset.categoryField

    @form().kanbans.on "update", (kanban) =>
      @refresh() if kanban.permalink() == @permalink()

    @params = new App.Elements.ResponseKanban.Helpers.Params(@)
    @routes = new App.Elements.ResponseKanban.Helpers.Routing(@)
    @columns = new App.Elements.ResponseKanban.ColumnManager(@)

    @initialize_context_menu()

    search_input = App.context(@element).menu_element().querySelector(".table-search")
    @params.search(search_input) if search_input?

    @refresh(true)

    PubSub.subscribe "submission.submitted_data.#{@form_permalink}", =>
      @refresh()

  form: -> App.Models.Form.new(@form_permalink)
  permalink: -> @kanban_permalink

  has_permission: (perm) ->
    @element.getAttribute("data-permission-" + perm.replace("_", "-")) == "true"

  # Checks if the kanban items can be reordered or recategorized.
  # Eg. Static fields may not be changed.
  changeable: ->
    @element.dataset.changeable == "true"

  name: (value) ->
    name_element = @element.querySelector(".kanban_name_text")

    if value?
      name_element.textContent = value

    name_element.textContent

  refresh: (initial_load = false) ->
    new Promise (resolve, reject) =>
      $.ajax
        url: @routes.data()
      .done (response) =>
        @name(response.name)

        for column in @columns.all()
          column.empty()

        for category, items of response.data
          column = @columns.find(category)
          continue unless column?

          for item in items
            column.add(item, false)

        resolve()
      .fail (response) ->
        reject(response.responseJSON)

  initialize_context_menu: ->
    App.Interface.ContextMenu.new(@element, ".kanban_item", [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.redirect_to Routes.submission_path(form_permalink, permalink)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.redirect_to Routes.submission_path(form_permalink, permalink), target: "_blank"
      },
      {
        separator: true
      },
      {
        text: i18n.t("edit"),
        icon: "pencil",
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.Models.Form.new(form_permalink).submissions.open(permalink)
      },
      {
        text: i18n.t("history"),
        icon: "book-open",
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.Models.Form.new(form_permalink).submissions.load(permalink).then (submission) ->
            submission.modals.history().show()
      },
      {
        separator: true
      }
      {
        text: i18n.t("delete"),
        icon: "cancel",
        callback: (event, menu) =>
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.Models.Form.new(form_permalink).submissions.load(permalink).then (submission) =>
            submission.destroy()
            @refresh()
      },
    ])

App.Models.bind ".kanban_wrapper", App.Elements.ResponseKanban.Kanban
