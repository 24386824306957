
window.initialize_codemirror_editor = (element) ->
  element.addClass("codemirror-initialized")

  mode = element.attr("data-mode")
  mode ||= "application/x-liquid"

  cm = CodeMirror.fromTextArea(element[0], {
    lineNumbers: true,
    mode: mode,
    theme: "sonadier",
    tabSize: 2,
    smartIndent: false,
    showCursorWhenSelecting: true,
    scrollbarStyle: "simple",
    autoCloseBrackets: true,
    autoCloseTags: true,
    autoRefresh: true,
    highlightSelectionMatches: true,
    annotateScrollbar: true
  })

  cm.on "change", (cm) =>
    element = $(element)
    element.val(cm.getValue())
    element.trigger("change")

  add_code_editor_context_menus(cm)

window.add_code_editor_context_menus = (cm) ->
  element = cm.getWrapperElement()

  App.Interface.ContextMenu.new(
    element, ".CodeMirror.cm-s-formula .cm-function", [
      {
        text: "Documentation",
        icon: "info-circled",
        callback: (event, menu) ->
          article_path = menu.element.textContent.replace("(", "").replace(".", "/").toLowerCase()

          math_articles = [
            "abs", "acos", "acosh", "asin", "asinh", "atan", "atan2", "atanh", "average", "bin2dec",
            "bin2hex", "bin2oct", "cbrt", "cos", "cosh", "count", "dec2bin", "dec2hex", "dec2oct",
            "erf", "erfc", "exp", "frexp", "gamma", "gcd", "hex2bin", "hex2dec", "hex2oct", "hypot",
            "is_even", "is_odd", "icm", "ldexp", "lgamma", "log", "log10", "log2", "max", "min",
            "mod", "n", "number", "oct2bin", "oct2dec", "oct2hex", "rand", "round", "rounddown",
            "roundup", "sign", "sin", "sinh", "sqrt", "sum", "tan", "tanh"
          ]

          if math_articles.indexOf(article_path) != -1
            article_path = "math/#{article_path}"

          article_url = "https://info.sonadier.com/scripting/formulas/#{article_path}"

          App.redirect_to article_url, target: "_blank"
      }
    ]
  )

window.handle_basic_code_editor = (event) ->
  if window.CodeMirror?
    event.preventDefault()
    element = $(event.currentTarget)
    initialize_codemirror_editor element

  if event.which == 9
    event.preventDefault()
    element = $(event.currentTarget)
    value = element.val()

    start = @.selectionStart
    end = @.selectionEnd

    element.val(value.substring(0, start) + "\t" + value.substring(end))
    @.selectionStart = @.selectionEnd = start + 1
    return false

  if event.which == 13
    element = $(event.currentTarget)
    value = element.val()
    value_before_cursor = value.substring(0, @.selectionStart)

    last_line = value_before_cursor.substr(value_before_cursor.lastIndexOf("\n") + 1)

    return unless last_line.length > 0

    event.preventDefault()

    index = 0
    prefix = ""
    text_detected = false

    while !text_detected && index < last_line.length
      if last_line[index] == "\t"
        prefix += "\t"
      else
        text_detected = true

      index += 1

    start = @.selectionStart
    end = @.selectionEnd

    element.val(value.substring(0, start) + "\n" + prefix + value.substring(end))
    @.selectionStart = @.selectionEnd = start + prefix.length + 1
    return false

$(document).on(
  "keydown",
  ".code_textarea:not(.codemirror-initialized), .tabbed_textarea:not(.codemirror-initialized)",
  handle_basic_code_editor
)

$ ->
  if CodeMirror?
    for element in $(".code_textarea:not(.code_textarea_advanced)")
      initialize_codemirror_editor $(element)
