
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}
window.App.Frame.Sandbox.Bind ?= {}

window.App.Frame.Sandbox.Bind.reports = (channel, options) ->
  if options.read == true
    channel.on "schema.load.reports", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.reports.schema()
      .then (data, response) -> event.resolve(response)
      .catch event.reject

  if options.read == true
    channel.on "schema.load.report", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.reports.schema()
      .then ->
        report = form.reports.find_by_permalink(event.data.report)
        report.sandboxes().add(channel)

        report.schema()
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject

  if options.destroy == true
    channel.on "report.destroy", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.reports.schema() .then ->
        report = form.reports.find_by_permalink(event.data.report)
        return event.reject("Report not found") unless report?

        report.destroy()
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject

  if options.update == true
    channel.on "report.update", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.reports.schema().then ->
        report = form.reports.find_by_permalink(event.data.report)
        return event.reject("Report not found") unless report?

        report.update(event.data.parameters)
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject
