
window.App ?= {}
window.App.Schema ?= {}

class window.App.Schema.Base
  constructor: ->
    undefined

  sandboxes: ->
    @_sandboxes ?= new App.Frame.Sandbox.Manager(@, @channel_namespace())
    @_sandboxes

  channel_exists: ->
    App.is_child || @_sandboxes?

  channel_namespace: ->
    throw new Error("channel_namespace must be defined")

  channel: ->
    if App.is_child then App.Channels.parent else @sandboxes()

  _publish: (event, data) ->
    namespace = @channel_namespace()
    @channel().publish("#{namespace}.#{event}", data)

  _subscribe: (event, callback) ->
    namespace = @channel_namespace()
    @channel().subscribe("#{namespace}.#{event}", callback.bind(@))

  url: (name) ->
    @data.urls[name]

  load_data: (data, options) ->
    if @_requested_schema?
      source = @_requested_schema(options)
      source.data = data

      # Preserve the @data attribute for base-schema sources
      if !options? || !options.source?
        @data = data
    else
      @data = data

    @
