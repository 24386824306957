
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentTable ?= {}

class window.App.Elements.AttachmentTable.Column
  constructor: (@table, @element) ->
    @$_column = $(@element)
    @$_column_text = @$_column.find(".td-content")

    @mobile_hidden = @element.classList.contains("mobile_hidden")
    @column_name = @element.dataset.columnName

    if !@column_name?
      @column_name = @$_column.text()

  index: ->
    App.Helpers.Elements.index(@element)

  searchable: ->
    @field_id != "checkbox"

  orderable: ->
    @field_id != "checkbox"

  sortable: ->
    !@element.classList.contains("sorting_disabled")

  initialize_sorting: ->
    return unless @sortable()

    @element.addEventListener("click", ((event) =>
      event.preventDefault()
      event.stopPropagation()

      column_order = @order()
      @table.order_clear(false) unless event.shiftKey
      @order_toggle(column_order)

      return false
    ), false)

  search: (value) ->
    if value?
      @_search = value
      @table.refresh()

    @_search

  search_clear: ->
    @_search = undefined
    @table.refresh()

  order: (direction, refresh = true) ->
    if direction?
      @table._ordering ?= []

      @$_column.removeClass("sort-asc sort-desc")
      @$_column.addClass("sort-#{direction}")

      @_order = direction

      existing_ordering = @table._ordering.slice(0)

      for order in existing_ordering
        if order.column == @
          @table._ordering.splice(@table._ordering.indexOf(order), 1)

      @table._ordering.push({
        column: @,
        direction: direction
      })

      if refresh
        @table.refresh()

    @_order

  order_toggle: (column_order, refresh = true) ->
    column_order ?= @order()

    # click 1 = "asc", click 2 = "desc", click 3 = "none"
    if !column_order? || column_order == ""
      @order("asc", refresh)
    else if column_order == "asc"
      @order("desc", refresh)
    else
      @order_clear(refresh)

  order_clear: (refresh = true) ->
    @$_column.removeClass("sort-asc sort-desc")
    @_order = undefined

    if refresh
      @table.refresh()
