
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseKanban ?= {}
window.App.Elements.ResponseKanban.Helpers ?= {}

class window.App.Elements.ResponseKanban.Helpers.Params
  constructor: (@kanban) ->

  search: (value) ->
    value = value[0] if value? && App.Helpers.Elements.is_jquery(value)

    # If the jQuery element matches no objects, value[0] will be null
    if value?
      # Search with Element
      if value.nodeType
        value.addEventListener("input", ((event) =>
          @_search_value = event.currentTarget.value
          clearTimeout(@_search_timeout)
          @_search_timeout = setTimeout((=> @kanban.refresh()), 50)
        ), false)
      else
        @_search_value = value
        @kanban.refresh()

    @_search_value
