
window.App ?= {}
window.App.Schema ?= {}

window.App.Schema.CollectionBase = {
  # Subclasses will need these methods defined
  # model_class: -> @
  # model_instance: (data) -> new @(data)

  models: ->
    @_models ?= []
    @_models

  new: (data) ->
    return unless data?

    model = @_value_to_model(data)

    model.load_data(data) if models? && model.load_data?

    model = @_model_instance(data)

    @_models ?= []
    @_models.push(model)

    @length = @_models.length
    @[@length - 1] = model if @_index_keys

    if @_bound_objects?
      for object in @_bound_objects
        object.push(model)

    model

  push: ->
    @new(value) for value in arguments
    @models().length

  _reindex: ->
    return unless @_index_keys

    for model, index in @models()
      @[index] = model

    undefined

  _model_instance: (data) ->
    return data if data instanceof @model_class()
    @model_instance(data)

  # Override as necessary on subclasses
  _value_to_model: (data) ->
    return unless data?
    return data if data instanceof @model_class()

    @value_to_model(data)

  value_to_model: (data) ->
    for model in @models()
      if typeof data == "object"
        if model.data? && model.data.permalink? && data.permalink?
          return model if model.data.permalink == data.permalink
      else if typeof data == "string" || data instanceof String
        if model.data? && model.data.permalink?
          return model if model.data.permalink == data

    undefined

  _find_by_parameter: (parameter, value) ->
    for model in @models()
      member = model[parameter]
      member_is_function = (typeof member == "function")

      if member_is_function
        return model if member.call(model) == value
      else
        return model if member == value

    undefined

  find: (data) -> @_value_to_model(data)

  all: (filter) ->
    @require_schema() if @require_schema?

    return @models() unless filter?

    output = []

    for model in @models()
      output.push(model) if filter.call(model, model)

    output

  any: (filter) ->
    @require_schema() if @require_schema?

    models = @all()
    return models? && models.length > 1 unless filter?

    for model in models
      return true if filter.call(model, model)

    false

  first: ->
    @require_schema() if @require_schema?
    @all()[0]

  second: ->
    @require_schema() if @require_schema?
    @all()[1]

  third: ->
    @require_schema() if @require_schema?
    @all()[2]

  last: ->
    @require_schema() if @require_schema?

    values = @all()
    values[values.length - 1]

  count: -> new Promise (resolve) => @schema().then => resolve(@length)

  indexOf: (value) ->
    model = @_value_to_model(value)
    return -1 unless model?

    @models().indexOf(model)

  splice: ->
    array_arguments = Array.from(arguments)
    splice_arguments = array_arguments.slice(0, 1)
    data_arguments = array_arguments.slice(2)

    for data in data_arguments
      splice_arguments.push @_model_instance(data)

    if @_bound_objects?
      for object in @_bound_objects
        object.splice.apply(null, splice_arguments)

    output = @models().splice.apply(null, splice_arguments)

    @_reindex()

    output

  contains: (value) ->
    @_value_to_model(value)?

  pop: ->
    if @_bound_objects?
      object.pop for object in @_bound_objects

    output = @models().pop()

    @_reindex()

    output

  shift: ->
    if @_bound_objects?
      object.shift() for object in @_bound_objects

    output = @models().shift()

    @_reindex()

    output

  unshift: ->
    array_arguments = Array.from(arguments)
    unshift_arguments = []

    for data in array_arguments
      unshift_arguments.push @_model_instance(data)

    if @_bound_objects?
      object.unshift.apply(null, unshift_arguments) for object in @_bound_objects

    output = @models().unshift.apply(null, unshift_arguments)

    @_reindex()

    output

  sort: (method) ->
    if @_bound_objects?
      object.sort(method) for object in @_bound_objects

    output = @models().sort(method)

    @_reindex()

    output

  reverse: ->
    if @_bound_objects?
      object.reverse() for object in @_bound_objects

    output = @models().reverse()

    @_reindex()

    output
}
