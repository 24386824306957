
class window.SubmissionApprovalManager
  constructor: (@list) ->
    @list.addClass("initialized")
    @list.find(".dropit").dropit()

    @form_permalink = @list.attr("data-form-permalink")
    @approval_permalink = @list.attr("data-approval-permalink")
    @connection_id = @list.attr("data-connection-id")
    @approving_form_permalink = @list.attr("data-approving-form-permalink")
    @approving_field_id = @list.attr("data-approving-field-id")

    @list_type = @list.attr("data-list-type")
    @form_name = @list.attr("data-form-name")

    @value_approved = @list.attr("data-value-approved")
    @value_rejected = @list.attr("data-value-rejected")

    @approvals = {}
    @submissions = {}

    @list.on "click", ".submission_approval .review_approval", (e) => @review(e)
    @list.on "click", ".submission_approval .approved_submission", (e) => @edit_submission(e)

    @list.on "click", ".response_subscription", (event) =>
      event.preventDefault()
      @subscription_toggle(event)

    PubSub.subscribe "approvals.#{@approval_permalink}.submission.reviewed", (e, data) => @reviewed(data)

    @subscription_update(@subscription_permalink())

  form: -> App.Models.Form.new(@form_permalink)
  approving_form: -> App.Models.Form.new(@approving_form_permalink)

  submission_row: (permalink) ->
    @list.find(".submission_approval[data-permalink='#{permalink}']").first()

  edit_submission: (event) ->
    event.preventDefault()
    element = element_from_object(event)
    approval = element.closest(".submission_approval")
    permalink = approval.attr("data-permalink")

    App.Models.Form.new(@form_permalink).submissions.open(permalink, event)

  review: (event) ->
    event.preventDefault()
    element = element_from_object(event)
    approval = element.closest(".submission_approval")
    permalink = approval.attr("data-permalink")

    if @approvals[permalink]?
      @approvals[permalink].modal.show()
    else
      return if element.hasClass("loading")
      add_loader element

      $.ajax
        url: Routes.submission_approval_path(@form_permalink, @approval_permalink, permalink)
      .done (response) =>
        remove_loader element
        @approvals[permalink] = @approving_form().submissions.initialize_html(response)

        @approvals[permalink].before_submit ((approval, permalink) -> ->
            @fields.find_by_field_id(approval.connection_id).value([permalink])
        )(@, permalink)

        @approvals[permalink].after_submit ((approval, permalink) -> ->
            PubSub.publish("approvals.#{approval.approval_permalink}.submission.reviewed", {
              submission: @,
              approval: approval
              approved_submission_permalink: permalink
            })
        )(@, permalink)
      .fail (xhr) ->
        remove_loader element
        App.Errors.response_error(xhr)

  approval_moved: (approving_field) ->
    submission_approved = approving_field.value_is_or_contains(@value_approved)
    submission_rejected = approving_field.value_is_or_contains(@value_rejected)

    switch @list_type
      when "pending"
        submission_approved || submission_rejected
      when "approved"
        !submission_approved
      when "rejected"
        !submission_rejected

  reviewed: (data) ->
    approving_field = data.submission.fields.find_by_field_id(@approving_field_id)

    submission_approved = approving_field.value_is_or_contains(@value_approved)
    submission_rejected = approving_field.value_is_or_contains(@value_rejected)

    if @approval_moved(approving_field)
      @submission_row(data.approved_submission_permalink).remove()

    if @list.find(".submission_approval").length == 0
      element = $("<li/>", {
        "html": $("<a/>", {
          "href": "#",
          "html": $("<span/>", {
            "text": i18n.t("approvals.no_#{@list_type}", form: @form_name)
          }).add($("<a/>", {
            "text": "Reload",
            "href": "javascript:window.location.reload()",
            "class": "list_secondary"
          }))
        })
      })

      @list.find(".list").html(element)

  subscription_permalink: ->
    @list.find(".response_subscription").first().attr("data-subscription")

  subscription_toggle: (event) ->
    event.preventDefault() if event.preventDefault
    button = element_from_object(event)

    return if button? && button.hasClass("loading")
    add_loader(button) if button?

    @form().subscription_toggle(@subscription_permalink())
    .done (response) =>
      remove_loader(button) if button?
      @subscription_update(response.permalink)
    .fail (xhr) =>
      remove_loader(button) if button?
      App.Errors.response_error(xhr)

  subscription_update: (subscription_permalink) ->
    button = @list.find(".response_subscription")
    icon = button.find(".icon")
    text = button.find(".subscription_text")
    name = @list.attr("data-form-name")

    @list.attr("data-subscription", subscription_permalink)
    button.attr("data-subscription", subscription_permalink)

    icon.removeClass("icon-bell")
    icon.removeClass("icon-bell-off-empty")

    if subscription_permalink? && subscription_permalink != ""
      icon.addClass("icon-bell-off-empty")
      text.html(i18n.t("response_subscriptions.forms.unsubscribe", form: name))
    else
      icon.addClass("icon-bell")
      text.html(i18n.t("response_subscriptions.forms.subscribe", form: name))

$(document).on "ready page:load", ->
  for list in $(".submission_approvals_list:not(.initialized)")
    new SubmissionApprovalManager $(list)
