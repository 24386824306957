
$(document).on "mouseover", ".rating-container:not([data-disabled]) .rating", (event) ->
  rating = event.currentTarget

  container = rating.parentNode
  container.classList.add("hovering")

  hovered_rating = parseInt(rating.dataset.rating)

  for element, index in container.querySelectorAll(".rating")
    if parseInt(element.dataset.rating) <= hovered_rating
      element.classList.add("hovered")
    else
      element.classList.remove("hovered")

  undefined

$(document).on "mouseleave", ".rating-container:not([data-disabled]) .rating", (event) ->
  rating = event.currentTarget

  container = rating.parentNode
  container.classList.remove("hovering")

  for element, index in container.querySelectorAll(".rating")
    element.classList.remove("hovered")

  undefined

$(document).on "click", ".rating-container:not([data-disabled]) .rating", (event) ->
  rating = event.currentTarget

  container = rating.parentNode
  input = container.querySelector(".rating-input")
  selected_rating = parseInt(rating.dataset.rating)

  # Double-clicking the same rating should unselect it.
  value = if container.dataset.currentRating == selected_rating.toString() then 0 else selected_rating
  input.value = value

  $(input).trigger("change")

$(document).on "change", ".rating-input", (event) ->
  input = event.currentTarget
  container = input.parentNode
  value = parseInt(input.value)

  App.Helpers.Elements.Ratings.reflow(container, value)
