import 'app/elements/response_calendar/helpers/events'
import 'app/elements/response_calendar/helpers/params'
import 'app/elements/response_calendar/helpers/routing'


window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseCalendar ?= {}
window.App.Elements.ResponseCalendar._models ?= []
window.App.Elements.ResponseCalendar._settings_modals ?= {}

window.App.Elements.ResponseCalendar.new = (element) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  new App.Elements.ResponseCalendar.Calendar(element)

window.App.Elements.ResponseCalendar.visible_calendar = ->
  for calendar in App.Elements.ResponseCalendar._models
    return calendar if calendar.visible()

  undefined

window.App.Elements.ResponseCalendar.settings_modal = (model) ->
  model_is_element = model instanceof App.Elements.ResponseCalendar.Calendar
  model_is_schema = model instanceof App.Schema.Form.Calendar

  if !model_is_element && !model_is_schema
    throw new Error("model must be a Schema or ResponseCalendar")

  App.Elements.ResponseCalendar._settings_modals[model.permalink()] ?= App.Interface.Modal.from_url(
    Routes.edit_calendar_path(model.form().permalink(), model.permalink()
    )
  )

  App.Elements.ResponseCalendar._settings_modals[model.permalink()]

class window.App.Elements.ResponseCalendar.Calendar
  constructor: (@element) ->
    @element.classList.add("initialized")

    @form_permalink = @element.dataset.formPermalink
    @calendar_permalink = @element.dataset.permalink

    App.Elements.ResponseCalendar._models.push(@)

    @form().calendars.on "update", (calendar) =>
      @refresh() if calendar.permalink() == @permalink()

    @next_button = @element.querySelector(".calendar_next")
    @previous_button = @element.querySelector(".calendar_previous")

    @events = new App.Elements.ResponseCalendar.Helpers.Events(@)
    @params = new App.Elements.ResponseCalendar.Helpers.Params(@)
    @routes = new App.Elements.ResponseCalendar.Helpers.Routing(@)

    @initialize_hotkeys()
    @initialize_context_menu()

    @refresh(true)

    PubSub.subscribe "submission.submitted_data.#{@form_permalink}", =>
      @refresh()

    if @params.view_type() == "month"
      $(@element).on "dblclick", "td.calendar_day", (event) =>
        event.preventDefault()
        data = event.currentTarget.dataset
        @new_submission(data.year, data.month, data.day)

    $(@element).on "dblclick", "td.calendar_day_hour", (event) =>
      data = event.currentTarget.dataset
      @new_submission(data.year, data.month, data.day, data.hour)

  visible: -> App.Helpers.Elements.visible(@element)

  next_page: -> @next_button.trigger("click")
  previous_page: -> @previous_button.trigger("click")

  form: -> App.Models.Form.new(@form_permalink)

  name: (value) ->
    name_element = @element.querySelector(".calendar_name_text")

    if value?
      name_element.textContent = value

    name_element.textContent

  permalink: -> @calendar_permalink

  refresh: (initial_load = false) ->
    $.ajax
      url: @routes.data()
    .done (response) =>
      @name(response.name)

      for element in @events.all()
        element.parentNode.removeChild(element)

      for day, day_events of response.data
        for hour, hour_events of day_events
          for event in hour_events
            @events.add({
              name: event.name,
              form: @form().permalink(),
              submission: event.permalink,
              repeating: event.repeating,
              editable: response.permissions.update,
              readable: response.permissions.read,
              day: day,
              hour: hour
            })
    .fail (xhr) ->
      App.Errors.response_error(xhr)

  refresh_all: ->
    for calendar in App.Elements.ResponseCalendar._models
      calendar.refresh() if calendar.form_permalink == @form_permalink

    undefined

  new_submission: (year, month, day, hour = 0, minute = 0) ->
    year = parseInt(year)
    month = parseInt(month)
    day = parseInt(day)
    hour = parseInt(hour)
    minute = parseInt(minute)

    selector = "#{day}/#{month}/#{year}/#{hour}/#{minute}"

    @form().submissions.new_or_unsaved({ selector: selector }).then (submission) =>
      field = submission.fields.find_by_column_name(@params.start_field())
      return unless field?

      field.value(new Date(year, month - 1, day, hour, minute))

  initialize_hotkeys: ->
    # Move to the previous open calendar page with CTRL+LEFT
    Hotkey.bind ["CTRL", "LEFT"],
      if: (=>
        return false if App.Interface.Modal.visible_modal()?
        return false unless @visible()

        true
      ),
      do: => @previous_page()

    Hotkey.bind ["CTRL", "RIGHT"],
      if: (=>
        return false if App.Interface.Modal.visible_modal()?
        return false unless @visible()

        true
      ),
      do: => @next_page()

  initialize_context_menu: ->
    App.Interface.ContextMenu.new(@element, ".calendar_event", [
      {
        text: i18n.t("open"),
        icon: "reply",
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.redirect_to Routes.submission_path(form_permalink, permalink)
      },
      {
        text: i18n.t("open_in_new_tab"),
        icon: "reply-all",
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.redirect_to Routes.submission_path(form_permalink, permalink), target: "_blank"
      },
      {
        separator: true
      },
      {
        text: i18n.t("edit"),
        icon: "pencil",
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.Models.Form.new(form_permalink).submissions.open(permalink)
      },
      {
        text: i18n.t("history"),
        icon: "book-open",
        callback: (event, menu) ->
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.Models.Form.new(form_permalink).submissions.load(permalink).then (submission) ->
            submission.modals.history().show()
      },
      {
        separator: true
      },
      {
        text: "Repeat Weekly",
        icon: "calendar",
        callback: (event, menu) =>
          permalink = menu.element.dataset.permalink
          @events.repeat(permalink, "week")
      },
      {
        text: "Repeat Monthly",
        icon: "calendar",
        callback: (event, menu) =>
          permalink = menu.element.dataset.permalink
          @events.repeat(permalink, "month")
      },
      {
        text: "Repeat Yearly",
        icon: "calendar",
        callback: (event, menu) =>
          permalink = menu.element.dataset.permalink
          @events.repeat(permalink, "year")
      },
      {
        separator: true
      }
      {
        text: i18n.t("delete"),
        icon: "cancel",
        callback: (event, menu) =>
          form_permalink = menu.element.dataset.formPermalink
          permalink = menu.element.dataset.permalink

          App.Models.Form.new(form_permalink).submissions.load(permalink).then (submission) =>
            submission.destroy()
            @refresh()
      },
    ])
