
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Events ?= {}

window.App.Helpers.Events.generate_scopes = (names) ->
  scopes = []

  for name in App.Helpers.Events.split_events(names)
    scope = undefined

    for part in name.split(".")
      scope = if scope? then "#{scope}.#{part}" else part
      scopes.push(scope)

  scopes

window.App.Helpers.Events.split_events = (names) ->
  output = []

  for name in names.split(",")
    output.push(name.trim())

  output

window.App.Helpers.Events.matches_scope = (name, scopes) ->
  if typeof scopes == "string"
    scopes = App.Helpers.Events.generate_scopes(scopes)

  scopes.indexOf(name) != -1

window.App.Helpers.Events.passive_listeners = false

try
  options = Object.defineProperty({}, "passive", {
    get: -> App.Helpers.Events.passive_listeners = true
  })

  window.addEventListener("test", null, options)
catch e then undefined

window.App.Helpers.Events.new = (name, options = {}) ->
  if App.Helpers.Compatibility.is_ie
    event = document.createEvent("CustomEvent")
    event.initCustomEvent(name, options.bubbles, options.cancelable, options.detail)
    return event

  try
    return new Event(name, options)
  catch e
    event = document.createEvent("Event")
    event.initEvent(name, options.bubbles, options.cancelable)
    return event
