import 'app/query/condition'
import 'app/query/field_condition/base'
import 'app/query/header_condition/base'
import 'app/query/field_condition/address'
import 'app/query/field_condition/attachment'
import 'app/query/field_condition/barcode'
import 'app/query/field_condition/boolean'
import 'app/query/field_condition/check_box'
import 'app/query/field_condition/connection'
import 'app/query/field_condition/date_time'
import 'app/query/field_condition/dropdown'
import 'app/query/field_condition/formula'
import 'app/query/field_condition/number'
import 'app/query/field_condition/phone'
import 'app/query/field_condition/email'
import 'app/query/field_condition/rating'
import 'app/query/field_condition/signature'
import 'app/query/field_condition/text'
import 'app/query/field_condition/text_area'
import 'app/query/field_condition/user'
import 'app/query/header_condition/date_time'
import 'app/query/header_condition/text'
import 'app/query/header_condition/user'





window.App ?= {}

# Parse the top-level queries, which are arrays of groups/conditions
# with type = "group" and operator = "and" implied.
window.App.Query.from_top_level_parameters = (form, conditions, options = {}) ->
  if Array.isArray(conditions)
    parameters = {
      type: "group",
      operator: "and",
      conditions: conditions
    }
  else
    parameters = conditions

  App.Query.from_parameters(undefined, form, parameters, options)

window.App.Query.from_parameters = (parent, form, parameters, options = {}) ->
  parameters.type ?= "condition"

  switch parameters.type
    when "group"
      App.Query.Group.from_parameters(parent, form, parameters, options)
    when "condition"
      App.Query.Condition.from_parameters(parent, form, parameters, options)

window.App.Query.value_is_conditional = (value) ->
  return false unless value?

  if value instanceof App.Query.FieldCondition.Base
    return true

  if value instanceof App.Query.HeaderCondition.Base
    return true

  if value instanceof App.Query.Group
    return true

  false

window.App.Query.value_is_serialized_conditional = (value) ->
  return false unless value? && value.type?

  value.type == "group" || value.type == "condition"
