import css_escape from 'polyfills/css-escape-new'

window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Elements ?= {}

window.App.Helpers.Elements.is_jquery = (elem) ->
  if elem? && (elem instanceof jQuery || elem.jquery)
    true
  else
    false

window.App.Helpers.Elements.serialize = (node, callback) ->
  node = node[0] if App.Helpers.Elements.is_jquery(node)

  output = {}
  output.nodeType = node.nodeType
  output.nodeUUID = node.nodeUUID || App.Helpers.Generators.uuid()

  node.nodeUUID ?= output.nodeUUID

  # Node is Element
  if node.nodeType == 1
    output.tagName = node.tagName

    output.attributes = {}
    for attribute in node.attributes
      output.attributes[attribute.name] = attribute.value

    if node.childNodes.length > 0
      output.children = []
      for child_node in node.childNodes
        child = App.Helpers.Elements.serialize(child_node, callback)

        if child.nodeType != 8
          callback(child_node) if callback?
          output.children.push(child)

  # Node is Text
  if node.nodeType == 3
    output.nodeValue = node.nodeValue if node.nodeType == 3

  output

window.App.Helpers.Elements.deserialize = (input) ->
  switch input.nodeType
    when 1 # Element
      node = document.createElement(input.tagName)
      node.nodeUUID = input.nodeUUID

      for name, value of input.attributes
        node.setAttribute(name, value)

      if input.children?
        for child in input.children
          child_node = App.Helpers.Elements.deserialize(child)
          node.appendChild(child_node)
    when 3 # Text
      node = document.createTextNode(input.nodeValue)
      node.nodeUUID = input.nodeUUID

  node

window.App.Helpers.Elements.from_html = (value) ->
  return value[0] if App.Helpers.Elements.is_jquery(value)
  return value if value instanceof HTMLElement

  wrapper = document.createElement("DIV")
  wrapper.innerHTML = value.trim()
  wrapper.firstChild

window.App.Helpers.Elements._from_html_ = window.App.Helpers.Elements.from_html

window.App.Helpers.Elements.slide_toggle = (element, status, callback) ->
  if Array.isArray(element) || App.Helpers.Elements.is_jquery(element)
    App.Helpers.Elements.slide_toggle(node, status) for node in element
    return

  return unless element?

  if status
    return if !element.classList.contains("hidden") && element.style.display != "none"

    if element.classList.contains("hidden")
      element.style.display = "none"
      element.classList.remove("hidden")

    if callback?
      $(element).slideDown(250, -> callback.call(element))
    else
      $(element).slideDown(250)
  else
    return if element.classList.contains("hidden") || element.style.display == "none"

    $(element).slideUp 250, ->
      element.classList.add("hidden")
      callback.call(element) if callback?

App.Helpers.Elements.slide_up = (element, callback) ->
  App.Helpers.Elements.slide_toggle(element, false, callback)

App.Helpers.Elements.slide_down = (element, callback) ->
  App.Helpers.Elements.slide_toggle(element, true, callback)

App.Helpers.Elements.toggle = (element, status) ->
  element.classList.remove("hidden")

  status ?= !App.Helpers.Elements.visible(element)

  if status
    element.style.display = "block"
  else
    element.style.display = "none"

  element

window.App.Helpers.Elements.matches = (element, selector) ->
  match = element.matches || element.mozMatchesSelector || element.msMatchesSelector || element.oMatchesSelector || element.webkitMatchesSelector
  match.call(element, selector)

window.App.Helpers.Elements.closest = (element, selector) ->
  return if !element? || element == document
  return element if App.Helpers.Elements.matches(element, selector)
  return if !element.parentNode?
  App.Helpers.Elements.closest(element.parentNode, selector)

window.App.Helpers.Elements.child = (element, selector) ->
  return if !element?
  children = element.children
  return if children.length == 0

  for child in children
    return child if App.Helpers.Elements.matches(child, selector)

  undefined

window.App.Helpers.Elements.visible_in_vertical_viewport = (element) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  return false unless App.Helpers.Elements.visible(element)

  rect = element.getBoundingClientRect()
  top = rect.top
  bottom = rect.bottom

  height = window.innerHeight || document.documentElement.clientHeight

  table_height = top + bottom

  return true if top >= 0 && top <= height

  return true if top <= height && bottom <= height && bottom > 0

  false

window.App.Helpers.Elements.visible = (element) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  return true if element == document.body
  return false if element.offsetParent == null || element.offsetParent == document.body

  true

window.App.Helpers.Elements.first_childless_child = (element) ->
  return element if element.children.length == 0

  App.Helpers.Elements.first_childless_child(element.children[0])

window.App.Helpers.Elements.text_node = (element) ->
  for node in element.childNodes
    return node if node.nodeType == 3
    child = App.Helpers.Elements.text_node(node)
    return child if child?

  undefined

window.App.Helpers.Elements.index = (element) ->
  Array.from(element.parentNode.children).indexOf(element)

window.App.Helpers.Elements.linkify = (element, options = {}) ->
  return unless element? && element.nodeType == 1 # HTML Node

  options.mention_regex ?= /(?:^|\s)@([a-z0-9_]+)/gim
  options.hashtag_regex ?= /(?:^|\s)#([a-z0-9_]+)/gim
  options.email_regex   ?= /[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}/gim
  options.parser        ?= new DOMParser()

  return element if element.tagName == "A"

  child = element.firstChild
  while child
    switch child.nodeType
      when 1 # HTML Node
        App.Helpers.Elements.linkify(child, options)
      when 3 # Text Node
        content = child.nodeValue

        content = content.replace options.mention_regex, (match, name) ->
          if current_user.facility_permalink?
            "<a href='#{Routes.user_profile_path(name)}' data-object='user'>#{match}</a>"
          else
            "<a href='#{Routes.view_user_path(name)}' data-object='user'>#{match}</a>"

        content = content.replace options.email_regex, (match) ->
          "<a href='mailto:#{match}'>#{match}</a>"

        if options.form?
          content = content.replace options.hashtag_regex, (match, name) ->
            "<a href='#{Routes.tagged_comments_path(options.form, name)}' data-object='hashtag'>#{match}</a>"

        # parseFromString seems to be returning null indeterminately
        if content != child.nodeValue
          parsed_content = options.parser.parseFromString(content, "text/html")
          return element unless parsed_content?

          parsed_body = parsed_content.body
          return element unless parsed_body?

          new_children = parsed_body.childNodes
          return element unless new_children?

          last_new_child = new_children[new_children.length - 1]

          element.replaceChild(last_new_child, child)

          for i in [(new_children.length - 1)..0]
            new_child = new_children[i]

            if new_child?
              element.insertBefore(new_child, last_new_child)
              last_new_child = new_child

    child = child.nextSibling

  linkifyElement(element, attributes: { rel: "noopener noreferrer" })

  element

window.App.Helpers.Elements.Multiples ?= {}

# Sets a multiple container's values from the hidden select that handles storage
window.App.Helpers.Elements.Multiples.sync_bound_select = (container, multiple) ->
  container = container[0] if App.Helpers.Elements.is_jquery(container)
  multiple = multiple[0] if multiple? && App.Helpers.Elements.is_jquery(multiple)

  bound_select_selector = container.dataset.boundSelect

  return unless bound_select_selector?

  bound_select = document.querySelector(bound_select_selector)

  return unless bound_select?

  if multiple?
    value = multiple.dataset.value
    selected = multiple.classList.contains("selected")
    allow_multiple_choices = (container.dataset.multipleSelect == "true")

    # If only one multiple can be selected, remove existing selections
    App.Helpers.Selects.value_from_array(bound_select, []) unless allow_multiple_choices

    App.Helpers.Selects.set_option(bound_select, value, !selected)

  bound_select_values = App.Helpers.Selects.values(bound_select)

  for element in container.querySelectorAll(".multiple")
    if bound_select_values.indexOf(element.dataset.value) != -1
      element.classList.add("selected")
    else
      element.classList.remove("selected")

  if bound_select.dispatchEvent?
    bound_select.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))
  else
    $(bound_select).trigger("change")

# Sets the widths of multiple-choice options based on the container's size.
# All size values collected from the DOM must be multiplied by the devicePixelRatio, the browser's
# zoom level.
window.App.Helpers.Elements.Multiples.reflow = (container) ->
  if App.Helpers.Elements.is_jquery(container)
    for multiple in container
      App.Helpers.Elements.Multiples.reflow(multiple)

    return

  if !container.classList.contains("multiple_container")
    container = container.querySelectorAll(".multiple_container")

    for multiple in container
      App.Helpers.Elements.Multiples.reflow(multiple)

    return

  container.style.display = "block"

  all_elements = container.querySelectorAll(".multiple_wrapper")

  visible_elements = []
  visible_elements_and_spacers = []

  for element in all_elements
    if element.style.display != "none"
      visible_elements_and_spacers.push(element)

      if !element.classList.contains("multiple_spacer")
        visible_elements.push(element)

  count = visible_elements.length
  widest = 0

  for multiple in visible_elements
    multiple.setAttribute("style", "")
    multiple.classList.remove("full_width")
    multiple.style.flexGrow = 0

    input = multiple.querySelector("input")
    input.setAttribute("style", "") if input?

    width = parseInt(multiple.offsetWidth, 10)
    widest = width if width > widest

  container_width = container.offsetWidth

  if window.devicePixelRatio
    container_width = container_width * window.devicePixelRatio
    widest = widest * window.devicePixelRatio

  if count <= 5 && widest <= container_width / count
    column_count = count
  else
    for column in [5..1]
      column_count ||= column if widest <= container_width / column || column == 1

  other_multiple = container.querySelector(".multiple_wrapper.other_multiple")
  other_multiple = undefined if other_multiple? && other_multiple.style.display == "none"

  if count == 1 && other_multiple?
    other_multiple.classList.add("full_width")

    input = other_multiple.querySelector("input")
    input.setAttribute("placeholder", "Add Option")
  else
    multiple_width = (100 / column_count)
    full_width = (multiple_width == 100)

    for multiple, index in visible_elements_and_spacers
      multiple.style.flexGrow = 1
      multiple.style.flexBasis = "auto"
      multiple.style.width = (multiple_width + "%")
      multiple.classList.add("full_width") if full_width

      if (index + 1) % column_count == 0
        multiple.style.paddingRight = 0

      input = multiple.querySelector("input")
      input.style.width = "100%" if input?

      if column_count > 1
        multiple.style.whiteSpace = "nowrap"

  container.style.display = "flex"

  undefined

window.App.Helpers.Elements.Multiples.spawn_other = (options = {}) ->
  options.selected ?= false
  options.index ?= 0

  wrapper = document.createElement("DIV")
  wrapper.className = "multiple_wrapper other_multiple"
  wrapper.setAttribute("data-multi", options.index)

  multiple = document.createElement("DIV")
  multiple.className = "multiple other"
  multiple.classList.add("selected") if options.selected
  multiple.classList.add("empty_other") if !options.value? || options.value.length == 0
  multiple.setAttribute("data-value", options.value || "")

  input = document.createElement("INPUT")
  input.setAttribute("placeholder", i18n.t("other"))
  input.value = options.value || ""

  multiple.appendChild(input)
  wrapper.appendChild(multiple)

  wrapper

window.App.Helpers.Elements.Multiples.new_other = (container, options = {}) ->
  options.selected ?= false
  options.prepend ?= false

  container = container[0] if App.Helpers.Elements.is_jquery(container)

  bound_select_selector = container.dataset.boundSelect
  bound_select = document.querySelector(bound_select_selector) if bound_select_selector?

  allow_multiple_choices = (container.dataset.multipleSelect == "true")

  count = parseInt(container.dataset.count)
  index = count

  existing_other_multiple = container.querySelector(".multiple_wrapper.other_multiple")

  if allow_multiple_choices || !existing_other_multiple?
    container.setAttribute("data-count", count + 1)
  else
    index = existing_other_multiple.dataset.multi

  new_wrapper = App.Helpers.Elements.Multiples.spawn_other({
    selected: options.selected,
    value: options.value
    index: index
  })

  if bound_select?
    if allow_multiple_choices
      App.Helpers.Selects.add_option(bound_select, {
        value: options.value,
        selected: options.selected
      })
    else
      option = bound_select.querySelector("option[data-multi='#{index}']")

      if !option?
        option = document.createElement("OPTION")
        option.dataset.multi = index
        bound_select.appendChild(option)

      option.selected = options.selected
      option.value = App.Helpers.Selects.escape_value(options.value)

  empty_multiple = container.querySelector(".multiple.empty_other")

  if empty_multiple?
    empty_wrapper = App.Helpers.Elements.closest(empty_multiple, ".multiple_wrapper")

    if allow_multiple_choices
      if options.prepend
        container.insertBefore(new_wrapper, empty_wrapper)
      else
        # If nextSibling is null, new_wrapper is inserted at the end automatically
        container.insertBefore(new_wrapper, empty_wrapper.nextSibling)
    else
      container.replaceChild(new_wrapper, empty_wrapper)
  else
    spacer = container.querySelector(".multiple_spacer")

    if spacer?
      container.insertBefore(new_wrapper, spacer)
    else
      container.appendChild(new_wrapper)

  App.Helpers.Elements.Multiples.reflow(container)

# Add an empty other multiple when enter is pressed on existing multiple
window.App.Helpers.Elements.Multiples.add_other = (multiple) ->
  multiple = multiple[0] if App.Helpers.Elements.is_jquery(multiple)
  container = App.Helpers.Elements.closest(multiple, ".multiple_container")

  return unless container.dataset.multipleSelect == "true"

  App.Helpers.Elements.Multiples.new_other(container)

window.App.Helpers.Elements.Multiples.multiple_values = (multiple_or_container) ->
  if multiple_or_container.classList.contains("multiple_container")
    container = multiple_or_container
  else
    container = App.Helpers.Elements.closest(multiple_or_container, ".multiple_container")

  return false unless container?

  container.dataset.multipleSelect == "true"

window.App.Helpers.Elements.Ratings ?= {}

window.App.Helpers.Elements.Ratings.reflow = (element, value) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)

  if value?
    element.setAttribute("data-current-rating", value)
    input = element.querySelector(".rating-input")
    input.value = value if input?

  current_rating = parseInt(element.dataset.currentRating)

  for icon in element.querySelectorAll(".rating")
    rating = parseInt(icon.dataset.rating)

    if rating <= current_rating
      icon.classList.add("selected")
    else
      icon.classList.remove("selected")

window.App.Helpers.Elements.reflow_submission = (form) ->
  form ?= document.body
  form = form[0] if App.Helpers.Elements.is_jquery(form)

  for element in form.querySelectorAll(".multiple.other")
    input = element.querySelector("input")

    if !input.value? || input.value.trim() == ""
      element.classList.add("empty_other")
    else
      element.classList.remove("empty_other")

  for element in form.querySelectorAll(".multiple_container")
    App.Helpers.Elements.Multiples.reflow(element)
    App.Helpers.Elements.Multiples.sync_bound_select(element)

    # Populates multiple-choice "other" fields with their previously submitted values
    if element.dataset.initialOther? && element.dataset.initialOther.length > 0
      for option in JSON.parse(element.dataset.initialOther)
        App.Helpers.Elements.Multiples.new_other(element, {
          value: option,
          selected: true,
          prepend: true
        })

      element.removeAttribute("data-initial-other")

  for element in form.querySelectorAll("*[data-bound-multiple]")
    multiple_container = document.querySelector(element.getAttribute("data-bound-multiple"))
    selected_options = element.querySelectorAll("option:checked")

    for option in selected_options
      multiple = multiple_container.querySelector(".multiple[data-value='#{css_escape(option.value)}']")
      multiple.classList.add("selected") if multiple?

  # data-checked-toggle binds an elements visibility to the checkbox being checked.
  # data-unchecked-toggle does the same, with the opposite checked value.
  # These loops ensure that on refresh the saved checkbox states update their elements accordingly.
  for element in form.querySelectorAll("input[type=checkbox][data-checked-toggle]")
    toggled_elements = document.querySelectorAll(element.getAttribute("data-checked-toggle"))

    for toggle in toggled_elements
      App.Helpers.Elements.slide_toggle(toggle, element.checked)

  for element in form.querySelectorAll("input[type=checkbox][data-unchecked-toggle]")
    toggled_elements = document.querySelectorAll(element.getAttribute("data-checked-toggle"))

    for toggle in toggled_elements
      App.Helpers.Elements.slide_toggle(toggle, !element.checked)

  for element in form.querySelectorAll(".rating-container")
    App.Helpers.Elements.Ratings.reflow(element)

  for element in form.querySelectorAll(".select_table")
    bound_select = document.querySelector(element.dataset.boundSelect)
    checkboxes = element.querySelectorAll(".row_select")
    continue unless bound_select?

    App.Helpers.Selects.value_from_checkboxes(bound_select, checkboxes)

  form

window.App.Helpers.Elements.Floating ?= {}

window.App.Helpers.Elements.Floating.position_element = (element, suggested_coordinates) ->
  $_element = $(element)
  $_window = $(window)

  suggested_coordinates ?= [[0, 0]]

  matches_suggestion = false

  y_coord = 0
  x_coord = 0

  for coords in suggested_coordinates
    if !matches_suggestion
      x_coord = coords[0]
      y_coord = coords[1]

      x_side = coords[2] || "left"
      y_side = coords[3] || "top"

      x_offset = $_window.scrollLeft() + $_window.width()
      y_offset = $_window.scrollTop() + $_window.height()

      # If the menu would overflow the window, place it above the cursor
      element_width = $_element.outerWidth()
      element_height = $_element.outerHeight()

      if element_width + x_coord > x_offset
        x_coord = x_coord - element_width

      if element_height + y_coord > y_offset
        y_coord = y_coord - element_height

      x_coord = 0 if x_coord < 0
      y_coord = 0 if y_coord < 0

      matches_suggestion = (x_coord == coords[0] && y_coord == coords[1])

  $_element.css(x_side, x_coord)
  $_element.css(y_side, y_coord)

  # Return the element in the format given, whether jQuery or HTMLElement
  element

window.App.Helpers.Elements.PageRows ?= {}

window.App.Helpers.Elements.PageRows.reflow = (scope) ->
  scope ?= document

  major_page_rows = scope.querySelectorAll(".major-page-row")
  minor_page_rows = scope.querySelectorAll(".minor-page-row")

  page_main = document.querySelector(".document-main")

  for element in major_page_rows
    element.style.width = ""

  for element in minor_page_rows
    element.style.width = ""

  for element in major_page_rows
    element.style.width = page_main.scrollWidth + "px"

  for element in minor_page_rows
    element.style.width = page_main.clientWidth + "px"

  undefined

window.checked_toggle = App.Helpers.Elements.slide_toggle
