
$(document).on "click", ".post_history", (event) ->
  event.preventDefault()

  permalink = event.currentTarget.dataset.permalink
  post = App.Elements.AttachmentPost.find(permalink)

  return unless post?

  post.history(event)

$(document).on "click", ".edit_post", (event) ->
  event.preventDefault()

  permalink = event.currentTarget.dataset.permalink
  post = App.Elements.AttachmentPost.find(permalink)

  return unless post?

  post.edit(event)

$(document).on "click", ".destroy_post", (event) ->
  event.preventDefault()

  permalink = event.currentTarget.dataset.permalink
  post = App.Elements.AttachmentPost.find(permalink)

  return unless post?

  post.destroy(event)

$(document).on "click", ".restore_post", (event) ->
  event.preventDefault()

  permalink = event.currentTarget.dataset.permalink
  post = App.Elements.AttachmentPost.find(permalink)

  return unless post?

  post.restore(event)

$(document).on "click", ".save_post", (event) ->
  event.preventDefault()

  permalink = event.currentTarget.dataset.permalink
  post = App.Elements.AttachmentPost.find(permalink)

  return unless post?

  post.save(event)
