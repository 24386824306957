import 'app/frame/bindings/calendars'
import 'app/frame/bindings/fields'
import 'app/frame/bindings/forms'
import 'app/frame/bindings/headers'
import 'app/frame/bindings/kanbans'
import 'app/frame/bindings/modals'
import 'app/frame/bindings/procedures'
import 'app/frame/bindings/reports'
import 'app/frame/bindings/submissions'


window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}

window.App.Frame.Sandbox.spawn = ->
  element = document.createElement("IFRAME")
  element.className = "script_sandbox"
  element.src = "/utilities/scripts/sandbox"
  element.sandbox = "allow-scripts"
  element.style.display = "none"

  document.body.appendChild(element)

  element

window.App.Frame.Sandbox.preload = (overwrite = false) ->
  return if App.Frame.Sandbox._preloaded? && !overwrite

  App.Frame.Sandbox._preloaded = new App.Frame.Sandbox.Object()

  undefined

window.App.Frame.Sandbox.new = (iframe) ->
  if iframe?
    sandbox = new App.Frame.Sandbox.Object(iframe)
  else
    sandbox = App.Frame.Sandbox._preloaded
    sandbox ?= new App.Frame.Sandbox.Object()
    App.Frame.Sandbox.preload(true)

  sandbox.element.style.display = null;

  sandbox

window.App.Frame.Sandbox.worker = (blob) ->
  return unless window.Worker?

  URL = window.URL || window.webkitURL
  worker = new Worker(URL.createObjectURL(blob))

  new App.Frame.Sandbox.Object(worker)

window.App.Frame.Sandbox.from_submission = (submission, script_blob) ->
  sandbox = undefined
  sandbox = App.Frame.Sandbox.worker(script_blob) if script_blob?
  sandbox ?= App.Frame.Sandbox.new()

  sandbox.load_submission submission
  sandbox.load_menu App.Interface.Menu.current()
  sandbox.load_current_user App.current_user
  sandbox.ready()

  sandbox

class window.App.Frame.Sandbox.Object
  constructor: (@element) ->
    @element ?= App.Frame.Sandbox.spawn()

    if App.Helpers.Objects.isNode(@element)
      frame = @element.contentWindow
    else
      frame = @element

    @channel = new App.FrameChannel(frame)
    @channel.open({ sandbox: true })

    App.Frame.Sandbox.Bind.forms(@channel, read: true)
    App.Frame.Sandbox.Bind.fields(@channel, read: true, update: true, destroy: true)
    App.Frame.Sandbox.Bind.headers(@channel, read: true)
    App.Frame.Sandbox.Bind.procedures(@channel, read: true, destroy: true, run: true)
    App.Frame.Sandbox.Bind.reports(@channel, read: true, update: true, destroy: true)
    App.Frame.Sandbox.Bind.calendars(@channel, read: true, update: true, destroy: true)
    App.Frame.Sandbox.Bind.kanbans(@channel, read: true, update: true, destroy: true)
    App.Frame.Sandbox.Bind.submissions(@channel, read: true, update: true, destroy: true)
    App.Frame.Sandbox.Bind.modals(@channel, create: true)

  ready: (data) ->
    data ?= App.Channels.default_ready_data()

    @channel.publish "ready", data

  load_script: (url) ->
    element = document.createElement("SCRIPT")
    element.src = url

    serialization = App.Helpers.Elements.serialize(element)

    @channel.publish("html.head.append", serialization)

  load_element: (element) ->
    synced_node = new App.Frame.SyncedNode(@channel, element)

    @channel.publish("html.body.append", synced_node.serialization)

  load_submission: (submission) ->
    submission.sandboxes.add(@)

    @channel.publish("submission.initialize", submission.serialize())

  load_menu: (menu) ->
    menu.sandboxes.add(@)

    @channel.publish("menu.initialize", menu.serialize())

  load_current_user: (user) ->
    @channel.publish("current_user.initialize", user.serialize())

# window.onload = ->
#   if self == top && document.body.dataset.scripts == 'true'
#     App.Frame.Sandbox.preload()
