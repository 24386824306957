
window.App ?= {}
window.App.Schema ?= {}
window.App.Schema.Form ?= {}

class window.App.Schema.Form.HeaderManager
  include @, App.Schema.SourceBase
  include @, App.Schema.SourceCollectionBase

  constructor: (@form_permalink) ->
    undefined

  model_instance: (data) -> new App.Schema.Form.Header(@form_permalink, data)
  model_class: -> App.Schema.Form.Header

  _find_by_parameter: (parameter, value, options) ->
    for field in @models(options)
      return field if field.matches(parameter, value)

    undefined

  _value_to_model: (value, options) ->
    if typeof value == "string"
      return @find_by_variable_name(value, options) || @find_by_name(value, options)
    else if typeof value == "object"
      return @find_by_variable_name(value.variable_name, options) || @find_by_name(value.name, options)

    undefined

  find_by_index: (value, options) -> @models(options)[value]
  find_by_column_name: (value, options) -> @_find_by_parameter("column_name", value, options)
  find_by_variable_name: (value, options) -> @_find_by_parameter("variable_name", value, options)
  find_by_name: (value, options) -> @_find_by_parameter("name", value, options)

  query_by_variable_name: (value) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        model = @find_by_variable_name(value)
        if model? then resolve(model) else reject(model)

  query_by_name: (value) ->
    new Promise (resolve, reject) =>
      @schema().then =>
        model = @find_by_name(value)
        if model? then resolve(model) else reject(model)

  where: (values, options) ->
    fields = @models(options)

    for key, value of values
      return fields if fields.length == 0

      new_fields = []

      for field in fields
        new_fields.push(field) if field.matches(key, value)

      fields = new_fields

    fields

  schema: (options) ->
    new Promise (resolve, reject) =>
      @form().schema(options)
      .then => resolve(@)
      .catch => reject(@)

  require_schema: (options) ->
    return unless @loaded(options)
    throw new Error("method requires headers.load() to be called and resolved")

  form: -> App.Models.Form.new(@form_permalink)
