
window.App ?= {}
window.App.Events ?= {}
window.App.Events.Handlers ?= {}
window.App.Events.Handlers.Forms ?= {}
window.App.Events.Handlers.Forms.Subscriptions ?= {}

window.App.Events.Handlers.Forms.Subscriptions.toggle_button = (button, status) ->
  button.classList.remove("unfollow_resource_link", "follow_resource_link")

  if status
    text = "<i class='icon icon-bell-off-empty'></i> " + i18n.t("submissions.subscriptions.unsubscribe.name")
    class_name = "unfollow_resource_link"
  else
    text = "<i class='icon icon-bell'></i> " + i18n.t("submissions.subscriptions.subscribe.name")
    class_name = "follow_resource_link"

  button.innerHTML = text
  button.classList.add(class_name)
  button

window.App.Events.Handlers.Forms.Subscriptions.toggle_form_subscription = (button, status) ->
  new Promise (resolve, reject) ->
    form_permalink = button.dataset.permalink

    form = Form.new(form_permalink)
    form.subscription_toggle(status)
    .done -> resolve()
    .fail -> reject()

window.App.Events.Handlers.Forms.Subscriptions.toggle_report_subscription = (button, status) ->
  new Promise (resolve, reject) ->
    report_permalink = button.dataset.permalink
    form_permalink = button.dataset.formPermalink

    form = Form.new(form_permalink)
    form.subscription_toggle(status, report_permalink)
    .done -> resolve()
    .fail -> reject()

window.App.Events.Handlers.Forms.Subscriptions.toggle_submission_subscription = (button, status) ->
  new Promise (resolve, reject) ->
    submission_permalink = button.dataset.permalink
    form_permalink = button.dataset.formPermalink

    form = App.Models.Form.new(form_permalink)

    form.submissions.load(submission_permalink).then (submission) ->
      submission.follow_toggle(status).then ->
        viewer = App.Elements.ResponseViewer.find_by_permalink(submission_permalink)
        viewer.update_subscription(status) if viewer?
        resolve()

$(document).on "click", ".follow_resource_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button
  resource = button.dataset.resource

  add_loader button

  App.Events.Handlers.Forms.Subscriptions["toggle_#{resource}_subscription"](button, true).then ->
    remove_loader(button)
    App.Events.Handlers.Forms.Subscriptions.toggle_button(button, true)
  .catch -> remove_loader(button)

$(document).on "click", ".unfollow_resource_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button
  resource = button.dataset.resource

  add_loader button

  App.Events.Handlers.Forms.Subscriptions["toggle_#{resource}_subscription"](button, false).then ->
    remove_loader(button)
    App.Events.Handlers.Forms.Subscriptions.toggle_button(button, false)
  .catch -> remove_loader(button)
