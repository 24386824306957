
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Times ?= {}

# Returns the current Unix time (time in seconds)
window.App.Helpers.Times.unix = (date) ->
  date ?= new Date()
  Math.round(date.getTime() / 1000)

window.App.Helpers.Times.seconds_to_time = (value) ->
  if !value?
    now = new Date()
    value = now.getSeconds() + (60 * (now.getMinutes() + (60 * now.getHours())))

  hours = Math.floor(value / 3600)
  minutes = Math.floor((value / 60) % 60)
  seconds = value % 60

  seconds = "0" + seconds if seconds < 10
  minutes = "0" + minutes if minutes < 10 && hours > 0

  if hours > 0
    "#{hours}:#{minutes}:#{seconds}"
  else
    "#{minutes}:#{seconds}"
