
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.Elements = {
  load_elements: ->
    @element.classList.add("app_response_table")

    @header_element = @element.querySelector(".table_header, .external_table_header")
    @body_element = @element.querySelector(".table_body")

    @body_element.innerHTML = "<div class='table_wrapper response_table_wrapper'>#{@body_element.innerHTML}</div>"
    @table_wrapper_element = @element.querySelector(".table_wrapper")

    @table_element = @element.querySelector("table")

    @$_table_element = $(@table_element)

    if !@table_element.id? || @table_element.id == ""
      @table_element.id = "table_#{App.Helpers.Generators.uuid()}_element"

    @table_head_element = @table_element.querySelector("thead")
    @table_body_element = @table_element.querySelector("tbody")

    if !@table_head_element?
      @table_head_element = document.createElement("THEAD")
      @table_element.appendChild(@table_head_element)

    if !@table_body_element?
      @table_body_element = document.createElement("TBODY")
      @table_element.appendChild(@table_body_element)

    table_name_element = @element.querySelector(".table_name")

    if table_name_element?
      @table_name = table_name_element.dataset.name
    else
      @table_name = "Table"

    @fake_cell_element = @table_head_element.querySelector(".fake-cell")

    @element.classList.add("initialized")

    @

  generate_elements: ->
    @generate_header()
    @generate_footer()

    @

  generate_header: ->
    return if App.current_user.guest() || !@header_element?

    table_options = document.createElement("DIV")
    table_options.className = "table_options"

    page_options = Array.from(@generate_pagination().children)

    for option in page_options
      option_wrapper = document.createElement("DIV")
      option_wrapper.className = "option"
      option_wrapper.appendChild(option)

      table_options.appendChild(option_wrapper)

    settings_option_wrapper = document.createElement("DIV")
    settings_option_wrapper.className = "option"

    settings_option = document.createElement("A")
    settings_option.className = "response_table_settings"
    settings_option.href = "#"
    settings_option.innerHTML = "<i class='icon icon-dot-3 rotate-90'></i>"

    settings_option_wrapper.appendChild(settings_option)
    table_options.appendChild(settings_option_wrapper)

    name_element = @header_element.querySelector(".table_name")
    @header_element.insertBefore table_options, name_element.nextSibling

  generate_footer: ->
    footer = document.createElement("DIV")
    footer.className = "table_footer"

    if @has_permission("create")
      link = document.createElement("A")
      link.className = "table_button new_submission_link force_modal_link"
      link.setAttribute("href", "#")
      link.setAttribute("data-form-permalink", @form().permalink())
      link.textContent = @translations.new_response()

      connection_type = @element.dataset.connectionType

      if connection_type?
        link.setAttribute("data-connection-type", connection_type)
        link.setAttribute("data-connection-form", @element.dataset.connectionForm)
        link.setAttribute("data-connection-field", @element.dataset.connectionField)
        link.setAttribute("data-connection-submission", @element.dataset.connectionSubmission)

      field_data = App.Models.Submission.Field.decode_preset_data(@element.dataset)

      for field, value of field_data
        link.setAttribute("data-field-value-#{field}", value)

      footer.appendChild(link)

    options = document.createElement("DIV")
    options.className = "options_container"

    pages = document.createElement("DIV")
    pages.className = "table_pages"

    options.appendChild(pages)
    options.appendChild @generate_pagination()

    footer.appendChild(options)

    @table_footer_element = footer

    @element.appendChild(@table_footer_element)

    @page_count_element = @table_footer_element.querySelector(".table_pages")

    @table_footer_element

  generate_pagination: ->
    pagination = document.createElement("DIV")
    pagination.className = "table_pagination"

    previous_page = document.createElement("A")
    previous_page.className = "paginate_button previous"
    previous_page.innerHTML = "<i class='icon icon-left-open'></i>"
    pagination.appendChild(previous_page)

    @previous_page_buttons ?= []
    @previous_page_buttons.push(previous_page)

    next_page = document.createElement("A")
    next_page.className = "paginate_button next"
    next_page.innerHTML = "<i class='icon icon-right-open'></i>"
    pagination.appendChild(next_page)

    @next_page_buttons ?= []
    @next_page_buttons.push(next_page)

    pagination

  generate_colgroup: ->
    if !@colgroup_element?
      @colgroup_element = document.createElement("COLGROUP")
      @table_element.insertBefore(@colgroup_element, @table_element.firstChild)

    for column in @columns()
      node = @generate_colgroup_col(column, @colgroup_element)

      # All columns are reappended in order
      @colgroup_element.appendChild(node)

    @colgroup_element

  generate_colgroup_col: (column) ->
    if column.col_element?
      node = column.col_element
    else
      if @colgroup_element?
        node = @colgroup_element.querySelector("col[data-field='#{column.field_permalink}']")

      node ?= document.createElement("COL")

      column.col_element = node

    node.style.width = column.column_width + "px"

    node
}
