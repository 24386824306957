
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}

class window.App.Models.Submission.Field.Errors
  constructor: (@field) ->
    @field._element.classList.add("initialized")

  all: ->
    error_element = @_error_element()
    return [] unless error_element?

    field_errors = []

    for element in error_element.querySelectorAll(".ERROR")
      field_errors.push(element.textContent.trim())

    field_errors

  any: ->
    error_element = @_error_element()
    return false unless error_element?

    error_element.querySelector(".ERROR")?

  # TODO: Deprecate empty() in documentation.
  # Unclear naming, empty() checks if empty on other models.
  empty: -> @clear()

  clear: ->
    error_element = @_error_element()
    return unless error_element?

    error_element.innerHTML = ""

  add: (error) ->
    error_element = @_error_element()
    return unless error_element?

    element = document.createElement("DIV")
    element.className = "ERROR"
    element.textContent = error

    error_element.appendChild(element)

  load: (errors) ->
    @empty()

    return if !errors? || errors.length == 0

    errors = [].concat.apply([], errors)
    @add(error) for error in errors

  _error_element: ->
    @field._container.querySelector(".ERRORS")
