/*
File generated by js-routes 1.4.14
Based on Rails 4.2.11.3 routes of Forms::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {"protocol":"https","host":"sonadier.io"},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_invitation => /invitations/:invitation_uuid/accept(.:format)
  // function(invitation_uuid, options)
  accept_invitation_path: Utils.route([["invitation_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"invitation_uuid",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accept_organization_integration_invitation => /manage/integrations/organizations/invitations/:invitation_permalink(.:format)
  // function(invitation_permalink, options)
  accept_organization_integration_invitation_path: Utils.route([["invitation_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"invitation_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// activate_manage_billing_card_payment_method => /manage/billing/payments/cards/:source/activate(.:format)
  // function(source, options)
  activate_manage_billing_card_payment_method_path: Utils.route([["source",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[3,"source",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin => /admin(.:format)
  // function(options)
  admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// advanced_search_table_data => /forms/:permalink/tables/search(.:format)
  // function(permalink, options)
  advanced_search_table_data_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// approval_table_data => /forms/:permalink/approvals/:approval_permalink/responses/data(.:format)
  // function(permalink, approval_permalink, options)
  approval_table_data_path: Utils.route([["permalink",true],["approval_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"approval_permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// assets => /api/v1/assets(.:format)
  // function(options)
  assets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"assets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// atom_1_report_submissions_feed => /forms/:permalink/reports/:report_permalink/feeds/atom/1.0(.:format)
  // function(permalink, report_permalink, options)
  atom_1_report_submissions_feed_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"atom",false],[2,[7,"/",false],[2,[6,"1",false],[2,[8,".",false],[2,[6,"0",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// atom_1_submissions_feed => /forms/:permalink/feeds/atom/1.0(.:format)
  // function(permalink, options)
  atom_1_submissions_feed_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"atom",false],[2,[7,"/",false],[2,[6,"1",false],[2,[8,".",false],[2,[6,"0",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// atom_report_submissions_feed => /forms/:permalink/reports/:report_permalink/feeds/atom(.:format)
  // function(permalink, report_permalink, options)
  atom_report_submissions_feed_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"atom",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// atom_submissions_feed => /forms/:permalink/feeds/atom(.:format)
  // function(permalink, options)
  atom_submissions_feed_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"atom",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// attachment_file => /files/:file_permalink(.:format)
  // function(file_permalink, options)
  attachment_file_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// attachment_folder => /files/folders/:folder_permalink(.:format)
  // function(folder_permalink, options)
  attachment_folder_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// auth_create => /auth/login(.:format)
  // function(options)
  auth_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// auth_destroy => /auth/logout(.:format)
  // function(options)
  auth_destroy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// auth_index => /auth(.:format)
  // function(options)
  auth_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// auth_new => /auth/login(.:format)
  // function(options)
  auth_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// authorize => /:form_permalink/authorize(.:format)
  // function(form_permalink, options)
  authorize_path: Utils.route([["form_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"form_permalink",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// authorize_submission => /forms/:permalink/responses/authorize(.:format)
  // function(permalink, options)
  authorize_submission_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_clone_submissions => /forms/:permalink/responses/bulk/clone(.:format)
  // function(permalink, options)
  bulk_clone_submissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_delete_files => /files/bulk/delete(.:format)
  // function(options)
  bulk_delete_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_delete_submissions => /forms/:permalink/responses/bulk/delete(.:format)
  // function(permalink, options)
  bulk_delete_submissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_edit_submissions => /forms/:permalink/responses/bulk/update(.:format)
  // function(permalink, options)
  bulk_edit_submissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_restore_files => /files/bulk/restore(.:format)
  // function(options)
  bulk_restore_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_restore_submissions => /forms/:permalink/responses/bulk/restore(.:format)
  // function(permalink, options)
  bulk_restore_submissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_update_submissions => /forms/:permalink/responses/bulk/update(.:format)
  // function(permalink, options)
  bulk_update_submissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// calendar => /forms/:permalink/calendars/:calendar_permalink(.:format)
  // function(permalink, calendar_permalink, options)
  calendar_path: Utils.route([["permalink",true],["calendar_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// calendar_data => /forms/:permalink/calendars/:calendar_permalink/data(.:format)
  // function(permalink, calendar_permalink, options)
  calendar_data_path: Utils.route([["permalink",true],["calendar_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// calendar_range => /forms/:permalink/calendars/:calendar_permalink/events/between(.:format)
  // function(permalink, calendar_permalink, options)
  calendar_range_path: Utils.route([["permalink",true],["calendar_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"between",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// cancel_formpack_version => /developers/apps/:permalink/versions/:version/cancel(.:format)
  // function(permalink, version, options)
  cancel_formpack_version_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// cancel_user_invitation => /manage/invitations/:invitation_uuid(.:format)
  // function(invitation_uuid, options)
  cancel_user_invitation_path: Utils.route([["invitation_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"invitation_uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// clone_form => /forms/:permalink/clone(.:format)
  // function(permalink, options)
  clone_form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// command_palette => /organization/commands(.:format)
  // function(options)
  command_palette_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization",false],[2,[7,"/",false],[2,[6,"commands",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// connection_columns => /forms/:permalink/connection_columns/:index(/:field)(.:format)
  // function(permalink, index, options)
  connection_columns_path: Utils.route([["permalink",true],["index",true],["field",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"connection_columns",false],[2,[7,"/",false],[2,[3,"index",false],[2,[1,[2,[7,"/",false],[3,"field",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// connection_table_data => /forms/:permalink/tables/connections/:field_id/submissions/:submission_permalink/data(.:format)
  // function(permalink, field_id, submission_permalink, options)
  connection_table_data_path: Utils.route([["permalink",true],["field_id",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"connections",false],[2,[7,"/",false],[2,[3,"field_id",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// consumer_dashboard => /users/dashboard(.:format)
  // function(options)
  consumer_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact => /contact(.:format)
  // function(options)
  contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// count => /api/rest/v1/files/count(.:format)
  // function(options)
  count_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_calendar => /forms/:permalink/calendars/new(.:format)
  // function(permalink, options)
  create_calendar_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_dashboard_custom_view => /views/new(.:format)
  // function(options)
  create_dashboard_custom_view_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_database_integration => /manage/integrations/databases/create(.:format)
  // function(options)
  create_database_integration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_database_integration_table => /manage/integrations/databases/:permalink/forms/new(.:format)
  // function(permalink, options)
  create_database_integration_table_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// create_embedded_session => /api/v1/embed/login(.:format)
  // function(options)
  create_embedded_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"embed",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_facility => /organization/new(.:format)
  // function(options)
  create_facility_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_folder => /files/folders/create(.:format)
  // function(options)
  create_folder_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_form => /forms/new(.:format)
  // function(options)
  create_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_form_aggregation => /forms/:permalink/tables/aggregations(.:format)
  // function(permalink, options)
  create_form_aggregation_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"aggregations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_form_approval => /forms/:permalink/approvals/new(.:format)
  // function(permalink, options)
  create_form_approval_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_form_authorization => /forms/:permalink/templates/create(.:format)
  // function(permalink, options)
  create_form_authorization_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_form_custom_view => /forms/:permalink/views/new(.:format)
  // function(permalink, options)
  create_form_custom_view_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_form_folder => /forms/folders/create(.:format)
  // function(options)
  create_form_folder_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_form_procedure => /forms/:permalink/procedures/new(.:format)
  // function(permalink, options)
  create_form_procedure_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"procedures",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_formpack => /developers/apps/new(.:format)
  // function(options)
  create_formpack_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_formpack_dependency => /developers/apps/:permalink/versions/:version/depend(.:format)
  // function(permalink, version, options)
  create_formpack_dependency_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"depend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// create_formpack_plugin => /developers/apps/:permalink/versions/:version/plugins/new(.:format)
  // function(permalink, version, options)
  create_formpack_plugin_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"plugins",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// create_formpack_theme_plugin => /developers/apps/:permalink/versions/:version/themes/new(.:format)
  // function(permalink, version, options)
  create_formpack_theme_plugin_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"themes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// create_group => /manage/groups/new(.:format)
  // function(options)
  create_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_guest => /guests/register(/:role)(.:format)
  // function(options)
  create_guest_path: Utils.route([["role",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"guests",false],[2,[7,"/",false],[2,[6,"register",false],[2,[1,[2,[7,"/",false],[3,"role",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// create_guest_registration => /guests/new(.:format)
  // function(options)
  create_guest_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"guests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_integration => /manage/integrations/new/:integration_name(.:format)
  // function(integration_name, options)
  create_integration_path: Utils.route([["integration_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[3,"integration_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_kanban => /forms/:permalink/kanbans/new(.:format)
  // function(permalink, options)
  create_kanban_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"kanbans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_mail_merge => /forms/:permalink/mail_merges/new(.:format)
  // function(permalink, options)
  create_mail_merge_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"mail_merges",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_manage_billing_card_payment_method => /manage/billing/payments/cards/new(.:format)
  // function(options)
  create_manage_billing_card_payment_method_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_manage_ip_rule => /manage/settings/authorization/ip_addresses/new(.:format)
  // function(options)
  create_manage_ip_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"authorization",false],[2,[7,"/",false],[2,[6,"ip_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_oauth_grant => /settings/oauth/grant/new(.:format)
  // function(options)
  create_oauth_grant_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"grant",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_partial_custom_view => /views/partials/new(.:format)
  // function(options)
  create_partial_custom_view_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_post => /files/posts/new(.:format)
  // function(options)
  create_post_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_published_organization_integration => /manage/integrations/organizations/publish/new(.:format)
  // function(options)
  create_published_organization_integration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"publish",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_report_aggregation => /forms/:permalink/reports/:report_permalink/tables/aggregations(.:format)
  // function(permalink, report_permalink, options)
  create_report_aggregation_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"aggregations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// create_store_installation => /apps/:permalink/install(.:format)
  // function(permalink, options)
  create_store_installation_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"install",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_submission => /forms/:permalink/responses/new(.:format)
  // function(permalink, options)
  create_submission_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_submission_comment => /forms/:permalink/responses/:submission_permalink/comments/new(/:parent_permalink)(.:format)
  // function(permalink, submission_permalink, options)
  create_submission_comment_path: Utils.route([["permalink",true],["submission_permalink",true],["parent_permalink",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[1,[2,[7,"/",false],[3,"parent_permalink",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// create_submission_custom_view => /forms/:permalink/responses/views/new(.:format)
  // function(permalink, options)
  create_submission_custom_view_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_submission_draft => /forms/:permalink/drafts/new(.:format)
  // function(permalink, options)
  create_submission_draft_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"drafts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_submission_import => /forms/:permalink/imports/new(.:format)
  // function(permalink, options)
  create_submission_import_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"imports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_user_access_token => /access_tokens/new(.:format)
  // function(options)
  create_user_access_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"access_tokens",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_user_invitation => /manage/invitations/new(.:format)
  // function(options)
  create_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_webhook => /forms/:permalink/webhooks/new(.:format)
  // function(permalink, options)
  create_webhook_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dashboard_custom_view => /views/:view_permalink(.:format)
  // function(view_permalink, options)
  dashboard_custom_view_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dashboard_custom_view_content => /views/:view_permalink/content(.:format)
  // function(view_permalink, options)
  dashboard_custom_view_content_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// data => /files/data(.:format)
  // function(options)
  data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// database_integration_form => /manage/integrations/databases/form/:database_type(.:format)
  // function(database_type, options)
  database_integration_form_path: Utils.route([["database_type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"database_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// database_integration_table_form => /manage/integrations/databases/:permalink/forms/form/:form_permalink(.:format)
  // function(permalink, form_permalink, options)
  database_integration_table_form_path: Utils.route([["permalink",true],["form_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"form_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// delete_file => /files/:file_permalink(.:format)
  // function(file_permalink, options)
  delete_file_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delete_folder => /files/folders/:folder_permalink(.:format)
  // function(folder_permalink, options)
  delete_folder_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delete_form => /forms/:permalink/edit(.:format)
  // function(permalink, options)
  delete_form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delete_group => /manage/groups/edit/:group_permalink(.:format)
  // function(group_permalink, options)
  delete_group_path: Utils.route([["group_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"group_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// delete_integration => /manage/integrations/delete/:integration_name/:permalink(.:format)
  // function(integration_name, permalink, options)
  delete_integration_path: Utils.route([["integration_name",true],["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"integration_name",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// delete_integration_grant => /oauth/grant/:permalink(.:format)
  // function(permalink, options)
  delete_integration_grant_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"grant",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delete_manage_user => /manage/users/:username(.:format)
  // function(username, options)
  delete_manage_user_path: Utils.route([["username",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"username",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delete_oauth_grant => /settings/oauth/grant/:grant_id(.:format)
  // function(grant_id, options)
  delete_oauth_grant_path: Utils.route([["grant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"grant",false],[2,[7,"/",false],[2,[3,"grant_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// delete_post => /files/posts/:page_permalink(.:format)
  // function(page_permalink, options)
  delete_post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delete_submission => /forms/:permalink/responses/:submission_permalink(.:format)
  // function(permalink, submission_permalink, options)
  delete_submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// delete_submission_comment => /forms/:permalink/responses/:submission_permalink/comments/:comment_permalink(.:format)
  // function(permalink, submission_permalink, comment_permalink, options)
  delete_submission_comment_path: Utils.route([["permalink",true],["submission_permalink",true],["comment_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// deleted_attachment_folder_files => /files/folders/:folder_permalink/deleted(.:format)
  // function(folder_permalink, options)
  deleted_attachment_folder_files_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"deleted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// deleted_facility_files => /files/deleted(.:format)
  // function(options)
  deleted_facility_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"deleted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// deleted_submissions => /forms/:permalink/responses/deleted(.:format)
  // function(permalink, options)
  deleted_submissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"deleted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_calendar => /forms/:permalink/calendars/:calendar_permalink(.:format)
  // function(permalink, calendar_permalink, options)
  destroy_calendar_path: Utils.route([["permalink",true],["calendar_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_dashboard_custom_view => /views/:view_permalink(.:format)
  // function(view_permalink, options)
  destroy_dashboard_custom_view_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_database_integration => /manage/integrations/databases/:permalink(.:format)
  // function(permalink, options)
  destroy_database_integration_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_database_integration_table => /manage/integrations/databases/:permalink/forms/:table_permalink(.:format)
  // function(permalink, table_permalink, options)
  destroy_database_integration_table_path: Utils.route([["permalink",true],["table_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"table_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// destroy_form_custom_view => /forms/:permalink/views/:view_permalink(.:format)
  // function(permalink, view_permalink, options)
  destroy_form_custom_view_path: Utils.route([["permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_form_folder => /forms/folders/:folder_permalink(.:format)
  // function(folder_permalink, options)
  destroy_form_folder_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_kanban => /forms/:permalink/kanbans/:kanban_permalink(.:format)
  // function(permalink, kanban_permalink, options)
  destroy_kanban_path: Utils.route([["permalink",true],["kanban_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"kanbans",false],[2,[7,"/",false],[2,[3,"kanban_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_mail_merge => /forms/:permalink/mail_merges/:mail_merge_permalink(.:format)
  // function(permalink, mail_merge_permalink, options)
  destroy_mail_merge_path: Utils.route([["permalink",true],["mail_merge_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"mail_merges",false],[2,[7,"/",false],[2,[3,"mail_merge_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_manage_billing_card_payment_method => /manage/billing/payments/cards/:source(.:format)
  // function(source, options)
  destroy_manage_billing_card_payment_method_path: Utils.route([["source",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[3,"source",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// destroy_manage_branding_rule => /manage/settings/display/branding(.:format)
  // function(options)
  destroy_manage_branding_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"display",false],[2,[7,"/",false],[2,[6,"branding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_manage_ip_rule => /manage/settings/authorization/ip_addresses/:rule_permalink(.:format)
  // function(rule_permalink, options)
  destroy_manage_ip_rule_path: Utils.route([["rule_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"authorization",false],[2,[7,"/",false],[2,[6,"ip_addresses",false],[2,[7,"/",false],[2,[3,"rule_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// destroy_notification => /inbox/:notification_permalink(.:format)
  // function(notification_permalink, options)
  destroy_notification_path: Utils.route([["notification_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[3,"notification_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_partial_custom_view => /views/partials/:view_permalink(.:format)
  // function(view_permalink, options)
  destroy_partial_custom_view_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_published_organization_integration => /manage/integrations/organizations/publish/:integration_permalink(.:format)
  // function(integration_permalink, options)
  destroy_published_organization_integration_path: Utils.route([["integration_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"publish",false],[2,[7,"/",false],[2,[3,"integration_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// destroy_submission_custom_view => /forms/:permalink/responses/views/:view_permalink(.:format)
  // function(permalink, view_permalink, options)
  destroy_submission_custom_view_path: Utils.route([["permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// destroy_submission_draft => /forms/:permalink/drafts/:draft_permalink(.:format)
  // function(permalink, draft_permalink, options)
  destroy_submission_draft_path: Utils.route([["permalink",true],["draft_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"drafts",false],[2,[7,"/",false],[2,[3,"draft_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_subscribed_organization_integration => /manage/integrations/organizations/subscribe/:integration_permalink(.:format)
  // function(integration_permalink, options)
  destroy_subscribed_organization_integration_path: Utils.route([["integration_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"subscribe",false],[2,[7,"/",false],[2,[3,"integration_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// destroy_user_access_token => /access_tokens/tokens/:token_permalink(.:format)
  // function(token_permalink, options)
  destroy_user_access_token_path: Utils.route([["token_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"access_tokens",false],[2,[7,"/",false],[2,[6,"tokens",false],[2,[7,"/",false],[2,[3,"token_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_user_session => /logout(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_webhook => /forms/:permalink/webhooks/:webhook_permalink(.:format)
  // function(permalink, webhook_permalink, options)
  destroy_webhook_path: Utils.route([["permalink",true],["webhook_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[3,"webhook_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// disable_tutorial => /settings/account/tutorial/disable(.:format)
  // function(options)
  disable_tutorial_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"tutorial",false],[2,[7,"/",false],[2,[6,"disable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit => /api/v1/forms/:permalink/submissions/:submission_permalink/edit(.:format)
  // function(permalink, submission_permalink, options)
  edit_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_calendar => /forms/:permalink/calendars/:calendar_permalink/edit(.:format)
  // function(permalink, calendar_permalink, options)
  edit_calendar_path: Utils.route([["permalink",true],["calendar_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_dashboard_custom_view => /views/:view_permalink/edit(.:format)
  // function(view_permalink, options)
  edit_dashboard_custom_view_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_database_integration => /manage/integrations/databases/:permalink/edit(.:format)
  // function(permalink, options)
  edit_database_integration_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_database_integration_table => /manage/integrations/databases/:permalink/forms/:table_permalink/edit(.:format)
  // function(permalink, table_permalink, options)
  edit_database_integration_table_path: Utils.route([["permalink",true],["table_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"table_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_form => /forms/:permalink/edit(.:format)
  // function(permalink, options)
  edit_form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_form_approval => /forms/:permalink/approvals/:approval_permalink/edit(.:format)
  // function(permalink, approval_permalink, options)
  edit_form_approval_path: Utils.route([["permalink",true],["approval_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"approval_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_form_custom_view => /forms/:permalink/views/:view_permalink/edit(.:format)
  // function(permalink, view_permalink, options)
  edit_form_custom_view_path: Utils.route([["permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_form_folder => /forms/folders/:folder_permalink/edit(.:format)
  // function(folder_permalink, options)
  edit_form_folder_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_form_procedure => /forms/:permalink/procedures/:procedure_permalink/edit(.:format)
  // function(permalink, procedure_permalink, options)
  edit_form_procedure_path: Utils.route([["permalink",true],["procedure_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"procedures",false],[2,[7,"/",false],[2,[3,"procedure_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_formpack_plugin => /developers/apps/:permalink/versions/:version/plugins/:plugin_permalink/edit(.:format)
  // function(permalink, version, plugin_permalink, options)
  edit_formpack_plugin_path: Utils.route([["permalink",true],["version",true],["plugin_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"plugins",false],[2,[7,"/",false],[2,[3,"plugin_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_formpack_theme_plugin => /developers/apps/:permalink/versions/:version/themes/:plugin_permalink/edit(.:format)
  // function(permalink, version, plugin_permalink, options)
  edit_formpack_theme_plugin_path: Utils.route([["permalink",true],["version",true],["plugin_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"themes",false],[2,[7,"/",false],[2,[3,"plugin_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_formpack_version => /developers/apps/:permalink/versions/:version/edit(.:format)
  // function(permalink, version, options)
  edit_formpack_version_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_group => /manage/groups/edit/:group_permalink(.:format)
  // function(group_permalink, options)
  edit_group_path: Utils.route([["group_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"group_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_integration => /manage/integrations/edit/:integration_name/:permalink(.:format)
  // function(integration_name, permalink, options)
  edit_integration_path: Utils.route([["integration_name",true],["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"integration_name",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_kanban => /forms/:permalink/kanbans/:kanban_permalink/edit(.:format)
  // function(permalink, kanban_permalink, options)
  edit_kanban_path: Utils.route([["permalink",true],["kanban_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"kanbans",false],[2,[7,"/",false],[2,[3,"kanban_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_mail_merge => /forms/:permalink/mail_merges/:mail_merge_permalink/edit(.:format)
  // function(permalink, mail_merge_permalink, options)
  edit_mail_merge_path: Utils.route([["permalink",true],["mail_merge_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"mail_merges",false],[2,[7,"/",false],[2,[3,"mail_merge_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_manage_account_name => /manage/integrations/accounts/:provider/rename(.:format)
  // function(provider, options)
  edit_manage_account_name_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_manage_ip_rule => /manage/settings/authorization/ip_addresses/:rule_permalink/edit(.:format)
  // function(rule_permalink, options)
  edit_manage_ip_rule_path: Utils.route([["rule_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"authorization",false],[2,[7,"/",false],[2,[6,"ip_addresses",false],[2,[7,"/",false],[2,[3,"rule_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_partial_custom_view => /views/partials/:view_permalink/edit(.:format)
  // function(view_permalink, options)
  edit_partial_custom_view_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_partial_custom_view_permissions => /views/partials/:view_permalink/permissions(.:format)
  // function(view_permalink, options)
  edit_partial_custom_view_permissions_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_post => /files/posts/:page_permalink/edit(.:format)
  // function(page_permalink, options)
  edit_post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_preferences => /forms/:permalink/edit/preferences(.:format)
  // function(permalink, options)
  edit_preferences_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[6,"preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_procedure_options => /forms/:permalink/procedures/:procedure_permalink/options/edit(.:format)
  // function(permalink, procedure_permalink, options)
  edit_procedure_options_path: Utils.route([["permalink",true],["procedure_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"procedures",false],[2,[7,"/",false],[2,[3,"procedure_permalink",false],[2,[7,"/",false],[2,[6,"options",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_published_organization_integration => /manage/integrations/organizations/publish/:integration_permalink/edit(.:format)
  // function(integration_permalink, options)
  edit_published_organization_integration_path: Utils.route([["integration_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"publish",false],[2,[7,"/",false],[2,[3,"integration_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_submission => /forms/:permalink/responses/:submission_permalink/edit(.:format)
  // function(permalink, submission_permalink, options)
  edit_submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_submission_custom_view => /forms/:permalink/responses/views/:view_permalink/edit(.:format)
  // function(permalink, view_permalink, options)
  edit_submission_custom_view_path: Utils.route([["permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_submission_draft => /forms/:permalink/drafts/:draft_permalink(.:format)
  // function(permalink, draft_permalink, options)
  edit_submission_draft_path: Utils.route([["permalink",true],["draft_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"drafts",false],[2,[7,"/",false],[2,[3,"draft_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_submission_viewer_columns => /forms/:permalink/responses/settings(.:format)
  // function(permalink, options)
  edit_submission_viewer_columns_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_profile => /users/:username/edit(.:format)
  // function(username, options)
  edit_user_profile_path: Utils.route([["username",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_users_form => /forms/users/edit(.:format)
  // function(options)
  edit_users_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_webhook => /forms/:permalink/webhooks/:webhook_permalink/edit(.:format)
  // function(permalink, webhook_permalink, options)
  edit_webhook_path: Utils.route([["permalink",true],["webhook_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[3,"webhook_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// embed_form => /forms/:permalink/sharing(.:format)
  // function(permalink, options)
  embed_form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"sharing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// embed_wrapper => /embed-wrapper(.:format)
  // function(options)
  embed_wrapper_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"embed-wrapper",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// embedded_submit => /forms/:permalink/embedded/submit(.:format)
  // function(permalink, options)
  embedded_submit_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"embedded",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// enable_tutorial => /settings/account/tutorial/enable(.:format)
  // function(options)
  enable_tutorial_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"tutorial",false],[2,[7,"/",false],[2,[6,"enable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// execute_procedure => /forms/:permalink/procedures/:procedure_endpoint(.:format)
  // function(permalink, procedure_endpoint, options)
  execute_procedure_path: Utils.route([["permalink",true],["procedure_endpoint",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"procedures",false],[2,[7,"/",false],[2,[3,"procedure_endpoint",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export => /forms/:permalink/export(.:format)
  // function(permalink, options)
  export_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// facilities => /api/v1/facilities(.:format)
  // function(options)
  facilities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"facilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// facility => /
  // function(options)
  facility_path: Utils.route([], {}, [7,"/",false]),
// facility_files => /files(.:format)
  // function(options)
  facility_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// facility_manage => /manage(.:format)
  // function(options)
  facility_manage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// facility_new => /facility/new(.:format)
  // function(options)
  facility_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"facility",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// field_presenter => /forms/:permalink/fields/:field_variable/presenter(.:format)
  // function(permalink, field_variable, options)
  field_presenter_path: Utils.route([["permalink",true],["field_variable",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_variable",false],[2,[7,"/",false],[2,[6,"presenter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// field_report_table_data => /forms/:permalink/tables/reports/:report_permalink/fields/:field_id/submissions/:submission_permalink/data(.:format)
  // function(permalink, report_permalink, field_id, submission_permalink, options)
  field_report_table_data_path: Utils.route([["permalink",true],["report_permalink",true],["field_id",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_id",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]),
// field_table_data => /forms/:permalink/tables/fields/:field_id/submissions/:submission_permalink/data(.:format)
  // function(permalink, field_id, submission_permalink, options)
  field_table_data_path: Utils.route([["permalink",true],["field_id",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_id",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// file_move_modal => /files/:file_permalink/move(.:format)
  // function(file_permalink, options)
  file_move_modal_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// file_permissions_modal => /files/:file_permalink/permissions(.:format)
  // function(file_permalink, options)
  file_permissions_modal_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// file_preview => /files/:file_permalink(.:format)
  // function(file_permalink, options)
  file_preview_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// file_properties_modal => /files/:file_permalink/properties(.:format)
  // function(file_permalink, options)
  file_properties_modal_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// file_sharing_modal => /files/:file_permalink/sharing(.:format)
  // function(file_permalink, options)
  file_sharing_modal_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"sharing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// find => /api/v1/submissions/find(.:format)
  // function(options)
  find_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[6,"find",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// folder_move_modal => /files/folders/:folder_permalink/move(.:format)
  // function(folder_permalink, options)
  folder_move_modal_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// folder_permissions_modal => /files/folders/:folder_permalink/permissions(.:format)
  // function(folder_permalink, options)
  folder_permissions_modal_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// folder_properties_modal => /files/folders/:folder_permalink/properties(.:format)
  // function(folder_permalink, options)
  folder_properties_modal_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// folder_sharing_modal => /files/folders/:folder_permalink/sharing(.:format)
  // function(folder_permalink, options)
  folder_sharing_modal_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"sharing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// folder_table_data => /files/folders/:folder_permalink/data(.:format)
  // function(folder_permalink, options)
  folder_table_data_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// folders_new => /api/rest/v1/files/home/folders/new(.:format)
  // function(options)
  folders_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"home",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// form => /forms/:permalink(.:format)
  // function(permalink, options)
  form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// form_aggregation => /forms/:permalink/tables/aggregations/:aggregation_permalink(.:format)
  // function(permalink, aggregation_permalink, options)
  form_aggregation_path: Utils.route([["permalink",true],["aggregation_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"aggregations",false],[2,[7,"/",false],[2,[3,"aggregation_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// form_approval_approved_submissions => /forms/:permalink/approvals/:approval_permalink/responses/approved(.:format)
  // function(permalink, approval_permalink, options)
  form_approval_approved_submissions_path: Utils.route([["permalink",true],["approval_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"approval_permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"approved",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// form_approval_rejected_submissions => /forms/:permalink/approvals/:approval_permalink/responses/rejected(.:format)
  // function(permalink, approval_permalink, options)
  form_approval_rejected_submissions_path: Utils.route([["permalink",true],["approval_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"approval_permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"rejected",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// form_approval_submissions => /forms/:permalink/approvals/:approval_permalink/responses(.:format)
  // function(permalink, approval_permalink, options)
  form_approval_submissions_path: Utils.route([["permalink",true],["approval_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"approval_permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// form_approvals => /forms/:permalink/approvals(.:format)
  // function(permalink, options)
  form_approvals_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_columns_modal => /forms/:permalink/tables/settings(.:format)
  // function(permalink, options)
  form_columns_modal_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// form_comments => /forms/:permalink/comments(.:format)
  // function(permalink, options)
  form_comments_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_custom_view => /forms/:permalink/views/:view_permalink(.:format)
  // function(permalink, view_permalink, options)
  form_custom_view_path: Utils.route([["permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// form_custom_view_content => /forms/:permalink/views/:view_permalink/content(.:format)
  // function(permalink, view_permalink, options)
  form_custom_view_content_path: Utils.route([["permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// form_custom_views => /forms/:permalink/views(.:format)
  // function(permalink, options)
  form_custom_views_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"views",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_export => /forms/:permalink/export(.:format)
  // function(permalink, options)
  form_export_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_file_list => /forms/:permalink/files(.:format)
  // function(permalink, options)
  form_file_list_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_file_table_data => /forms/:permalink/files/data(.:format)
  // function(permalink, options)
  form_file_table_data_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// form_folder => /forms/folders/:folder_permalink(.:format)
  // function(folder_permalink, options)
  form_folder_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_folders => /forms/folders(.:format)
  // function(options)
  form_folders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"folders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// form_grouping => /forms/:permalink/groups/:grouping_permalink(.:format)
  // function(permalink, grouping_permalink, options)
  form_grouping_path: Utils.route([["permalink",true],["grouping_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"grouping_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// form_grouping_dimension => /forms/:permalink/groups/:grouping_permalink/dimensions/:dimension_permalink(.:format)
  // function(permalink, grouping_permalink, dimension_permalink, options)
  form_grouping_dimension_path: Utils.route([["permalink",true],["grouping_permalink",true],["dimension_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"grouping_permalink",false],[2,[7,"/",false],[2,[6,"dimensions",false],[2,[7,"/",false],[2,[3,"dimension_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// form_grouping_set => /forms/:permalink/groups/:grouping_permalink/sets/:set_permalink(.:format)
  // function(permalink, grouping_permalink, set_permalink, options)
  form_grouping_set_path: Utils.route([["permalink",true],["grouping_permalink",true],["set_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"grouping_permalink",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// form_grouping_sidebar => /forms/:permalink/groups/:grouping_permalink/sidebar(.:format)
  // function(permalink, grouping_permalink, options)
  form_grouping_sidebar_path: Utils.route([["permalink",true],["grouping_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"grouping_permalink",false],[2,[7,"/",false],[2,[6,"sidebar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// form_notification_subscribe => /inbox/organizations/:facility_permalink/subscriptions/:form_permalink/:status(.:format)
  // function(facility_permalink, form_permalink, status, options)
  form_notification_subscribe_path: Utils.route([["facility_permalink",true],["form_permalink",true],["status",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"form_permalink",false],[2,[7,"/",false],[2,[3,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// form_permissions => /forms/:permalink/permissions(.:format)
  // function(permalink, options)
  form_permissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_preferences => /forms/:permalink/preferences(.:format)
  // function(permalink, options)
  form_preferences_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_procedures => /forms/:permalink/procedures(.:format)
  // function(permalink, options)
  form_procedures_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"procedures",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_webhooks => /forms/:permalink/webhooks(.:format)
  // function(permalink, options)
  form_webhooks_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"webhooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_workflows => /forms/:permalink/workflows(.:format)
  // function(permalink, options)
  form_workflows_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"workflows",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// formpack => /developers/apps/:permalink(.:format)
  // function(permalink, options)
  formpack_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// formpack_version => /developers/apps/:permalink/versions/:version(.:format)
  // function(permalink, version, options)
  formpack_version_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// formpack_versions => /developers/apps/:permalink/versions(.:format)
  // function(permalink, options)
  formpack_versions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// formpacks => /developers/apps(.:format)
  // function(options)
  formpacks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// forms => /forms(.:format)
  // function(options)
  forms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// fullpage_edit_post => /files/posts/:page_permalink/view/edit(.:format)
  // function(page_permalink, options)
  fullpage_edit_post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"view",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// fullpage_post => /files/posts/:page_permalink/view(.:format)
  // function(page_permalink, options)
  fullpage_post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"view",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fulltext_search => /organization/search(.:format)
  // function(options)
  fulltext_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// generate_calendar_feed => /forms/:permalink/calendars/:calendar_permalink/feeds/generate/:type/:version(.:format)
  // function(permalink, calendar_permalink, type, version, options)
  generate_calendar_feed_path: Utils.route([["permalink",true],["calendar_permalink",true],["type",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"generate",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"version",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// generate_form_feed => /forms/:permalink/feeds/generate/:type/:version(.:format)
  // function(permalink, type, version, options)
  generate_form_feed_path: Utils.route([["permalink",true],["type",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"generate",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"version",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// generate_report_feed => /forms/:permalink/reports/:report_permalink/feeds/generate/:type/:version(.:format)
  // function(permalink, report_permalink, type, version, options)
  generate_report_feed_path: Utils.route([["permalink",true],["report_permalink",true],["type",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"generate",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"version",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// google_calendar_readable_calendars => /organizations/integrations/services/google_calendar/readable_calendars(.:format)
  // function(options)
  google_calendar_readable_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_calendar",false],[2,[7,"/",false],[2,[6,"readable_calendars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// google_calendar_writable_calendars => /organizations/integrations/services/google_calendar/writable_calendars(.:format)
  // function(options)
  google_calendar_writable_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_calendar",false],[2,[7,"/",false],[2,[6,"writable_calendars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// google_sheets_readable_spreadsheets => /organizations/integrations/services/google_sheets/readable_spreadsheets(.:format)
  // function(options)
  google_sheets_readable_spreadsheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_sheets",false],[2,[7,"/",false],[2,[6,"readable_spreadsheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// google_sheets_spreadsheets_append => /organizations/integrations/services/google_sheets/spreadsheets/:spreadsheet_id/append(.:format)
  // function(spreadsheet_id, options)
  google_sheets_spreadsheets_append_path: Utils.route([["spreadsheet_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_sheets",false],[2,[7,"/",false],[2,[6,"spreadsheets",false],[2,[7,"/",false],[2,[3,"spreadsheet_id",false],[2,[7,"/",false],[2,[6,"append",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// google_sheets_spreadsheets_get => /organizations/integrations/services/google_sheets/spreadsheets/:spreadsheet_id(.:format)
  // function(spreadsheet_id, options)
  google_sheets_spreadsheets_get_path: Utils.route([["spreadsheet_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_sheets",false],[2,[7,"/",false],[2,[6,"spreadsheets",false],[2,[7,"/",false],[2,[3,"spreadsheet_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// google_sheets_writable_spreadsheets => /organizations/integrations/services/google_sheets/writable_spreadsheets(.:format)
  // function(options)
  google_sheets_writable_spreadsheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_sheets",false],[2,[7,"/",false],[2,[6,"writable_spreadsheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// google_tasks_tasklists => /organizations/integrations/services/google_tasks/tasklists(.:format)
  // function(options)
  google_tasks_tasklists_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_tasks",false],[2,[7,"/",false],[2,[6,"tasklists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// group_submissions => /forms/:permalink/fields/:variable_name/responses(.:format)
  // function(permalink, variable_name, options)
  group_submissions_path: Utils.route([["permalink",true],["variable_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"variable_name",false],[2,[7,"/",false],[2,[6,"responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// grouping_table_data => /forms/:permalink/groups/:grouping_permalink/sets/:set_permalink/data(.:format)
  // function(permalink, grouping_permalink, set_permalink, options)
  grouping_table_data_path: Utils.route([["permalink",true],["grouping_permalink",true],["set_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"grouping_permalink",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_permalink",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// hide_field_table_column => /forms/:permalink/fields/:field/tables/columns/:column/hide(.:format)
  // function(permalink, field, column, options)
  hide_field_table_column_path: Utils.route([["permalink",true],["field",true],["column",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"column",false],[2,[7,"/",false],[2,[6,"hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// hide_form_table_column => /forms/:permalink/tables/columns/:column/hide(.:format)
  // function(permalink, column, options)
  hide_form_table_column_path: Utils.route([["permalink",true],["column",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"column",false],[2,[7,"/",false],[2,[6,"hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// hide_notice => /notices/:notice/hide(.:format)
  // function(notice, options)
  hide_notice_path: Utils.route([["notice",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notices",false],[2,[7,"/",false],[2,[3,"notice",false],[2,[7,"/",false],[2,[6,"hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// hide_report_table_column => /forms/:permalink/reports/:report_permalink/tables/columns/:column/hide(.:format)
  // function(permalink, report_permalink, column, options)
  hide_report_table_column_path: Utils.route([["permalink",true],["report_permalink",true],["column",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"column",false],[2,[7,"/",false],[2,[6,"hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// home_folder_table_data => /files/data(.:format)
  // function(options)
  home_folder_table_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hooks => /api/v1/hooks(.:format)
  // function(options)
  hooks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"hooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// icalendar_calendar_submissions_feed => /forms/:permalink/calendars/:calendar_permalink/feeds/icalendar(.:format)
  // function(permalink, calendar_permalink, options)
  icalendar_calendar_submissions_feed_path: Utils.route([["permalink",true],["calendar_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"icalendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// inbox => /inbox(.:format)
  // function(options)
  inbox_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// incoming_submission_connections => /forms/:permalink/responses/:submission_permalink/connections/incoming/:connected_form/:connected_field(.:format)
  // function(permalink, submission_permalink, connected_form, connected_field, options)
  incoming_submission_connections_path: Utils.route([["permalink",true],["submission_permalink",true],["connected_form",true],["connected_field",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"connections",false],[2,[7,"/",false],[2,[6,"incoming",false],[2,[7,"/",false],[2,[3,"connected_form",false],[2,[7,"/",false],[2,[3,"connected_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// integrations_services_callback => /integrations/services/:resource/callback(.:format)
  // function(resource, options)
  integrations_services_callback_path: Utils.route([["resource",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"resource",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// integrations_services_google_sheets_callback => /integrations/services/:resource/callback(.:format)
  // function(resource, options)
  integrations_services_google_sheets_callback_path: Utils.route([["resource",true],["format",false]], {"resource":"google_sheets"}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"resource",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// integrations_services_google_sheets_initiate => /integrations/services/:resource/initiate(.:format)
  // function(resource, options)
  integrations_services_google_sheets_initiate_path: Utils.route([["resource",true],["format",false]], {"resource":"google_sheets"}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"resource",false],[2,[7,"/",false],[2,[6,"initiate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// integrations_services_initiate => /integrations/services/:resource/initiate(.:format)
  // function(resource, options)
  integrations_services_initiate_path: Utils.route([["resource",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"resource",false],[2,[7,"/",false],[2,[6,"initiate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// integrations_services_twilio_create => /integrations/services/:resource/new(.:format)
  // function(resource, options)
  integrations_services_twilio_create_path: Utils.route([["resource",true],["format",false]], {"resource":"twilio"}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"resource",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// integrations_services_twilio_initiate => /integrations/services/:resource/new(.:format)
  // function(resource, options)
  integrations_services_twilio_initiate_path: Utils.route([["resource",true],["format",false]], {"resource":"twilio"}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"resource",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// invitation_new_user_registration => /sign_up/invitation/:invitation_permalink(.:format)
  // function(invitation_permalink, options)
  invitation_new_user_registration_path: Utils.route([["invitation_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[3,"invitation_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invitation_user_registration => /sign_up/invitation/:invitation_permalink(.:format)
  // function(invitation_permalink, options)
  invitation_user_registration_path: Utils.route([["invitation_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[3,"invitation_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invited_new_user_registration => /sign_up/invite/:invitation(.:format)
  // function(invitation, options)
  invited_new_user_registration_path: Utils.route([["invitation",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"invite",false],[2,[7,"/",false],[2,[3,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invited_user_registration => /sign_up/invite/:invitation(.:format)
  // function(invitation, options)
  invited_user_registration_path: Utils.route([["invitation",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"invite",false],[2,[7,"/",false],[2,[3,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// jsonfeed_1_report_submissions_feed => /forms/:permalink/reports/:report_permalink/feeds/jsonfeed/1.0(.:format)
  // function(permalink, report_permalink, options)
  jsonfeed_1_report_submissions_feed_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"jsonfeed",false],[2,[7,"/",false],[2,[6,"1",false],[2,[8,".",false],[2,[6,"0",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// jsonfeed_1_submissions_feed => /forms/:permalink/feeds/jsonfeed/1.0(.:format)
  // function(permalink, options)
  jsonfeed_1_submissions_feed_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"jsonfeed",false],[2,[7,"/",false],[2,[6,"1",false],[2,[8,".",false],[2,[6,"0",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// jsonfeed_report_submissions_feed => /forms/:permalink/reports/:report_permalink/feeds/jsonfeed(.:format)
  // function(permalink, report_permalink, options)
  jsonfeed_report_submissions_feed_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"jsonfeed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// jsonfeed_submissions_feed => /forms/:permalink/feeds/jsonfeed(.:format)
  // function(permalink, options)
  jsonfeed_submissions_feed_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"jsonfeed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// kanban => /forms/:permalink/kanbans/:kanban_permalink(.:format)
  // function(permalink, kanban_permalink, options)
  kanban_path: Utils.route([["permalink",true],["kanban_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"kanbans",false],[2,[7,"/",false],[2,[3,"kanban_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// kanban_data => /forms/:permalink/kanbans/:kanban_permalink/data(.:format)
  // function(permalink, kanban_permalink, options)
  kanban_data_path: Utils.route([["permalink",true],["kanban_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"kanbans",false],[2,[7,"/",false],[2,[3,"kanban_permalink",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// leave_organization => /settings/organizations/:facility_permalink(.:format)
  // function(facility_permalink, options)
  leave_organization_path: Utils.route([["facility_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// locales => /settings/accounts/locales(.:format)
  // function(options)
  locales_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"locales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// login => /login(.:format)
  // function(options)
  login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// manage => /manage(.:format)
  // function(options)
  manage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// manage_access_token_integrations => /manage/integrations/tokens(.:format)
  // function(options)
  manage_access_token_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"tokens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_account_destroy => /manage/integrations/accounts/:provider(.:format)
  // function(provider, options)
  manage_account_destroy_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"provider",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_account_index => /manage/integrations/accounts/:provider(.:format)
  // function(provider, options)
  manage_account_index_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"provider",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_account_update => /manage/integrations/accounts/:provider(.:format)
  // function(provider, options)
  manage_account_update_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"provider",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_accounts => /manage/integrations/accounts(.:format)
  // function(options)
  manage_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_backup => /manage/backups/:permalink(.:format)
  // function(permalink, options)
  manage_backup_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"backups",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_backups => /manage/backups(.:format)
  // function(options)
  manage_backups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"backups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_billing => /manage/billing(.:format)
  // function(options)
  manage_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_billing_add_ons => /manage/billing/addons(.:format)
  // function(options)
  manage_billing_add_ons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"addons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_billing_analytics_add_on => /manage/billing/addons/analytics(.:format)
  // function(options)
  manage_billing_analytics_add_on_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"addons",false],[2,[7,"/",false],[2,[6,"analytics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_billing_auditing_add_on => /manage/billing/addons/auditing(.:format)
  // function(options)
  manage_billing_auditing_add_on_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"addons",false],[2,[7,"/",false],[2,[6,"auditing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_billing_development_add_on => /manage/billing/addons/development(.:format)
  // function(options)
  manage_billing_development_add_on_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"addons",false],[2,[7,"/",false],[2,[6,"development",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_billing_enterprise_plan => /manage/billing/plans/enterprise(.:format)
  // function(options)
  manage_billing_enterprise_plan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"plans",false],[2,[7,"/",false],[2,[6,"enterprise",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_billing_payment_methods => /manage/billing/payments(.:format)
  // function(options)
  manage_billing_payment_methods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_billing_plans => /manage/billing/plans(.:format)
  // function(options)
  manage_billing_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_billing_portals_add_on => /manage/billing/addons/portals(.:format)
  // function(options)
  manage_billing_portals_add_on_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"addons",false],[2,[7,"/",false],[2,[6,"portals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_billing_storage => /manage/billing/storage(.:format)
  // function(options)
  manage_billing_storage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"storage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_billing_subscribe_plan => /manage/billing/subscriptions/:plan(.:format)
  // function(plan, options)
  manage_billing_subscribe_plan_path: Utils.route([["plan",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"plan",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_billing_team_plan => /manage/billing/plans/team(.:format)
  // function(options)
  manage_billing_team_plan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"plans",false],[2,[7,"/",false],[2,[6,"team",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_billing_unsubscribe_plan => /manage/billing/subscriptions/:plan(.:format)
  // function(plan, options)
  manage_billing_unsubscribe_plan_path: Utils.route([["plan",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"plan",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_box_integrations => /manage/integrations/services/box(.:format)
  // function(options)
  manage_box_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"box",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_branding_rules => /manage/settings/display/branding(.:format)
  // function(options)
  manage_branding_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"display",false],[2,[7,"/",false],[2,[6,"branding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_database_integrations => /manage/integrations/databases(.:format)
  // function(options)
  manage_database_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_google_calendar_integrations => /manage/integrations/services/google_calendar(.:format)
  // function(options)
  manage_google_calendar_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_google_contacts_integrations => /manage/integrations/services/google_contacts(.:format)
  // function(options)
  manage_google_contacts_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_google_sheets_integrations => /manage/integrations/services/google_sheets(.:format)
  // function(options)
  manage_google_sheets_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_sheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_google_tasks_integrations => /manage/integrations/services/google_tasks(.:format)
  // function(options)
  manage_google_tasks_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"google_tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_installation => /manage/apps/installation/:install_permalink(.:format)
  // function(install_permalink, options)
  manage_installation_path: Utils.route([["install_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[6,"installation",false],[2,[7,"/",false],[2,[3,"install_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_installations => /manage/apps(.:format)
  // function(options)
  manage_installations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"apps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_integration_rules => /manage/settings/integrations(.:format)
  // function(options)
  manage_integration_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_integrations => /manage/integrations(.:format)
  // function(options)
  manage_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_invoice => /manage/billing/invoices/:invoice_permalink(.:format)
  // function(invoice_permalink, options)
  manage_invoice_path: Utils.route([["invoice_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"invoice_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_ip_address_rules => /manage/settings/authorization/ip_addresses(.:format)
  // function(options)
  manage_ip_address_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"authorization",false],[2,[7,"/",false],[2,[6,"ip_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_localization_rules => /manage/settings/display/localizations(.:format)
  // function(options)
  manage_localization_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"display",false],[2,[7,"/",false],[2,[6,"localizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_mysql_integrations => /manage/integrations/databases/mysql(.:format)
  // function(options)
  manage_mysql_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[6,"mysql",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_plugins => /manage/plugins(.:format)
  // function(options)
  manage_plugins_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"plugins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_postgresql_integrations => /manage/integrations/databases/postgresql(.:format)
  // function(options)
  manage_postgresql_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[6,"postgresql",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_quickbooks_integrations => /manage/integrations/services/quickbooks(.:format)
  // function(options)
  manage_quickbooks_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_rename_facility => /manage/rename(.:format)
  // function(options)
  manage_rename_facility_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_rename_facility_subdomain => /manage/rename/subdomain(.:format)
  // function(options)
  manage_rename_facility_subdomain_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"rename",false],[2,[7,"/",false],[2,[6,"subdomain",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_rules => /manage/settings(.:format)
  // function(options)
  manage_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_salesforce_integrations => /manage/integrations/services/salesforce(.:format)
  // function(options)
  manage_salesforce_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"salesforce",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_smtp_integrations => /manage/integrations/services/smtp(.:format)
  // function(options)
  manage_smtp_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"smtp",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_sql_server_integrations => /manage/integrations/databases/sqlserver(.:format)
  // function(options)
  manage_sql_server_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[6,"sqlserver",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_storage_rules => /manage/settings/storage(.:format)
  // function(options)
  manage_storage_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"storage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_sugar_crm_integrations => /manage/integrations/services/sugar_crm(.:format)
  // function(options)
  manage_sugar_crm_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"sugar_crm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_twilio_integrations => /manage/integrations/services/twilio(.:format)
  // function(options)
  manage_twilio_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"twilio",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manage_zapier_integrations => /manage/integrations/services/zapier(.:format)
  // function(options)
  manage_zapier_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"zapier",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mass_delete_form => /dashboard/:facility_permalink/mass_delete_forms(.:format)
  // function(facility_permalink, options)
  mass_delete_form_path: Utils.route([["facility_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[2,[7,"/",false],[2,[6,"mass_delete_forms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mass_delete_submission => /forms/:facility_permalink/mass_delete(.:format)
  // function(facility_permalink, options)
  mass_delete_submission_path: Utils.route([["facility_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[2,[7,"/",false],[2,[6,"mass_delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mass_restore_form => /dashboard/:facility_permalink/mass_restore_forms(.:format)
  // function(facility_permalink, options)
  mass_restore_form_path: Utils.route([["facility_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[2,[7,"/",false],[2,[6,"mass_restore_forms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mass_restore_submission => /forms/:facility_permalink/mass_restore(.:format)
  // function(facility_permalink, options)
  mass_restore_submission_path: Utils.route([["facility_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[2,[7,"/",false],[2,[6,"mass_restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// migration_request => /migration-request(.:format)
  // function(options)
  migration_request_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"migration-request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// move_file => /files/:file_permalink/move(.:format)
  // function(file_permalink, options)
  move_file_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// move_folder => /files/folders/:folder_permalink/move(.:format)
  // function(folder_permalink, options)
  move_folder_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// move_post => /files/posts/:page_permalink/move(.:format)
  // function(page_permalink, options)
  move_post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new => /api/v1/organizations/:facility_permalink/forms/:permalink/submissions/new(.:format)
  // function(facility_permalink, permalink, options)
  new_path: Utils.route([["facility_permalink",true],["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// new_calendar => /forms/:permalink/calendars/new(.:format)
  // function(permalink, options)
  new_calendar_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_dashboard_custom_view => /views/new(.:format)
  // function(options)
  new_dashboard_custom_view_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_database_integration => /manage/integrations/databases/new(.:format)
  // function(options)
  new_database_integration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_database_integration_table => /manage/integrations/databases/:permalink/forms/new(.:format)
  // function(permalink, options)
  new_database_integration_table_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_embedded_session => /api/v1/embed/login(.:format)
  // function(options)
  new_embedded_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"embed",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_facility => /organization/new(.:format)
  // function(options)
  new_facility_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_facility_registration => /new(.:format)
  // function(options)
  new_facility_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_field_report_table_data => /forms/:permalink/tables/reports/:report_permalink/fields/:field_id/data(.:format)
  // function(permalink, report_permalink, field_id, options)
  new_field_report_table_data_path: Utils.route([["permalink",true],["report_permalink",true],["field_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_id",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// new_field_table_data => /forms/:permalink/tables/fields/:field_id/data(.:format)
  // function(permalink, field_id, options)
  new_field_table_data_path: Utils.route([["permalink",true],["field_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_id",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_folder => /files/folders/new(.:format)
  // function(options)
  new_folder_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_form => /forms/new(.:format)
  // function(options)
  new_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_form_approval => /forms/:permalink/approvals/new(.:format)
  // function(permalink, options)
  new_form_approval_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_form_authorization => /forms/:permalink/templates/new(.:format)
  // function(permalink, options)
  new_form_authorization_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_form_custom_view => /forms/:permalink/views/new(.:format)
  // function(permalink, options)
  new_form_custom_view_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_form_folder => /forms/folders/new(.:format)
  // function(options)
  new_form_folder_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_form_grouping => /forms/:permalink/groups/new(.:format)
  // function(permalink, options)
  new_form_grouping_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_form_grouping_dimension => /forms/:permalink/groups/:grouping_permalink/dimensions(.:format)
  // function(permalink, grouping_permalink, options)
  new_form_grouping_dimension_path: Utils.route([["permalink",true],["grouping_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"grouping_permalink",false],[2,[7,"/",false],[2,[6,"dimensions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_form_procedure => /forms/:permalink/procedures/new(.:format)
  // function(permalink, options)
  new_form_procedure_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"procedures",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_formpack => /developers/apps/new(.:format)
  // function(options)
  new_formpack_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_formpack_dependency => /developers/apps/:permalink/versions/:version/depend(.:format)
  // function(permalink, version, options)
  new_formpack_dependency_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"depend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_formpack_plugin => /developers/apps/:permalink/versions/:version/plugins/new(.:format)
  // function(permalink, version, options)
  new_formpack_plugin_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"plugins",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_formpack_theme_plugin => /developers/apps/:permalink/versions/:version/themes/new(.:format)
  // function(permalink, version, options)
  new_formpack_theme_plugin_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"themes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_group => /manage/groups/new(.:format)
  // function(options)
  new_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_guest_registration => /guests/new(.:format)
  // function(options)
  new_guest_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"guests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_integration => /manage/integrations/new/:integration_name(.:format)
  // function(integration_name, options)
  new_integration_path: Utils.route([["integration_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[3,"integration_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_kanban => /forms/:permalink/kanbans/new(.:format)
  // function(permalink, options)
  new_kanban_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"kanbans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_mail_merge => /forms/:permalink/mail_merges/new(.:format)
  // function(permalink, options)
  new_mail_merge_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"mail_merges",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_manage_backup => /manage/backups/new(.:format)
  // function(options)
  new_manage_backup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"backups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_manage_billing_card_payment_method => /manage/billing/payments/cards/new(.:format)
  // function(options)
  new_manage_billing_card_payment_method_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_manage_ip_rule => /manage/settings/authorization/ip_addresses/new(.:format)
  // function(options)
  new_manage_ip_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"authorization",false],[2,[7,"/",false],[2,[6,"ip_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_modal => /api/v1/forms/:permalink/submissions/new/modal(.:format)
  // function(permalink, options)
  new_modal_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_oauth_grant => /settings/oauth/grant/new(.:format)
  // function(options)
  new_oauth_grant_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"grant",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_partial_custom_view => /views/partials/new(.:format)
  // function(options)
  new_partial_custom_view_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_post => /files/posts/new(.:format)
  // function(options)
  new_post_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_published_organization_integration => /manage/integrations/organizations/publish/new(.:format)
  // function(options)
  new_published_organization_integration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"publish",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_search => /forms/:permalink/search(.:format)
  // function(permalink, options)
  new_search_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_store_installation => /apps/:permalink/install(.:format)
  // function(permalink, options)
  new_store_installation_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"install",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_submission => /forms/:permalink/responses/new(.:format)
  // function(permalink, options)
  new_submission_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_submission_comment => /forms/:permalink/responses/:submission_permalink/comments/new(/:parent_permalink)(.:format)
  // function(permalink, submission_permalink, options)
  new_submission_comment_path: Utils.route([["permalink",true],["submission_permalink",true],["parent_permalink",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[1,[2,[7,"/",false],[3,"parent_permalink",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// new_submission_custom_view => /forms/:permalink/responses/views/new(.:format)
  // function(permalink, options)
  new_submission_custom_view_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_submission_draft => /forms/:permalink/drafts/new(.:format)
  // function(permalink, options)
  new_submission_draft_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"drafts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_submission_import => /forms/:permalink/imports/new(.:format)
  // function(permalink, options)
  new_submission_import_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"imports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_access_token => /access_tokens/new(.:format)
  // function(options)
  new_user_access_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"access_tokens",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_invitation => /manage/invitations/new(.:format)
  // function(options)
  new_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /sign_up(/:role)(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["role",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[2,[1,[2,[7,"/",false],[3,"role",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// new_user_session => /login(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_webhook => /forms/:permalink/webhooks/new(.:format)
  // function(permalink, options)
  new_webhook_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// no_embedded_login => /:permalink/no_embedded_login(.:format)
  // function(permalink, options)
  no_embedded_login_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"no_embedded_login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notification_properties => /inbox/:notification_permalink/properties(.:format)
  // function(notification_permalink, options)
  notification_properties_path: Utils.route([["notification_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[3,"notification_permalink",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// notification_subscriptions => /inbox/organizations/:facility_permalink/subscriptions(.:format)
  // function(facility_permalink, options)
  notification_subscriptions_path: Utils.route([["facility_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// oauth_approve => /oauth/authorize(.:format)
  // function(options)
  oauth_approve_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_authorize => /oauth/authorize(.:format)
  // function(options)
  oauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_disapprove => /oauth/authorize(.:format)
  // function(options)
  oauth_disapprove_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_empty_redirect => /oauth/redirect(.:format)
  // function(options)
  oauth_empty_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"redirect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_integrate => /oauth/integrate/:integration(.:format)
  // function(integration, options)
  oauth_integrate_path: Utils.route([["integration",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"integrate",false],[2,[7,"/",false],[2,[3,"integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_integration_token => /oauth/:integration/token/:facility_permalink(.:format)
  // function(integration, facility_permalink, options)
  oauth_integration_token_path: Utils.route([["integration",true],["facility_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[3,"integration",false],[2,[7,"/",false],[2,[6,"token",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// oauth_token => /oauth/token(.:format)
  // function(options)
  oauth_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// open_organization_integration_invitation => /integrations/organizations/invitations/:invitation_permalink(.:format)
  // function(invitation_permalink, options)
  open_organization_integration_invitation_path: Utils.route([["invitation_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"invitation_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// organization_integration_invitation => /manage/integrations/organizations/invitations/:invitation_permalink(.:format)
  // function(invitation_permalink, options)
  organization_integration_invitation_path: Utils.route([["invitation_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"invitation_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// outgoing_submission_connections => /forms/:permalink/responses/:submission_permalink/connections/:connected_field(.:format)
  // function(permalink, submission_permalink, connected_field, options)
  outgoing_submission_connections_path: Utils.route([["permalink",true],["submission_permalink",true],["connected_field",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"connections",false],[2,[7,"/",false],[2,[3,"connected_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// partial_custom_view_content => /views/partials/:view_permalink/content(.:format)
  // function(view_permalink, options)
  partial_custom_view_content_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// partial_custom_views => /views/partials(.:format)
  // function(options)
  partial_custom_views_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// passwords => /settings/accounts/passwords(.:format)
  // function(options)
  passwords_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"passwords",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// ping => /api/v1/ping(.:format)
  // function(options)
  ping_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"ping",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// post => /files/posts/:page_permalink(.:format)
  // function(page_permalink, options)
  post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// post_content => /files/posts/:page_permalink/content(.:format)
  // function(page_permalink, options)
  post_content_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_editor => /files/posts/:page_permalink/editor(.:format)
  // function(page_permalink, options)
  post_editor_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"editor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_folder_sidebar => /files/posts/folder_sidebar/:folder_permalink(.:format)
  // function(folder_permalink, options)
  post_folder_sidebar_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[6,"folder_sidebar",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_move_modal => /files/posts/:page_permalink/move(.:format)
  // function(page_permalink, options)
  post_move_modal_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_permissions_modal => /files/posts/:page_permalink/permissions(.:format)
  // function(page_permalink, options)
  post_permissions_modal_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_sharing_modal => /files/posts/:page_permalink/sharing(.:format)
  // function(page_permalink, options)
  post_sharing_modal_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"sharing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_versions => /files/posts/:page_permalink/versions(.:format)
  // function(page_permalink, options)
  post_versions_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// posts_new => /api/rest/v1/files/home/posts/new(.:format)
  // function(options)
  posts_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"home",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// preview_notifications => /inbox/preview(.:format)
  // function(options)
  preview_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// procedure_options => /procedures/options/:procedure_endpoint(.:format)
  // function(procedure_endpoint, options)
  procedure_options_path: Utils.route([["procedure_endpoint",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"procedures",false],[2,[7,"/",false],[2,[6,"options",false],[2,[7,"/",false],[2,[3,"procedure_endpoint",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// profiles => /settings/accounts/profiles(.:format)
  // function(options)
  profiles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"profiles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// publish_formpack_version => /developers/apps/:permalink/versions/:version/publish(.:format)
  // function(permalink, version, options)
  publish_formpack_version_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"publish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// published_organization_integrations => /manage/integrations/organizations(.:format)
  // function(options)
  published_organization_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// read_announcement => /announcements/:announcement_permalink/read(.:format)
  // function(announcement_permalink, options)
  read_announcement_path: Utils.route([["announcement_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"announcements",false],[2,[7,"/",false],[2,[3,"announcement_permalink",false],[2,[7,"/",false],[2,[6,"read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// read_invitation => /invitations/read(.:format)
  // function(options)
  read_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[6,"read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// read_notifications => /inbox/read(.:format)
  // function(options)
  read_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reauthenticate_commit => /settings/reauthenticate(.:format)
  // function(options)
  reauthenticate_commit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"reauthenticate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reauthenticate_new => /settings/reauthenticate(.:format)
  // function(options)
  reauthenticate_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"reauthenticate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// register => /guests/register(.:format)
  // function(options)
  register_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"guests",false],[2,[7,"/",false],[2,[6,"register",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reject_invitation => /invitations/:invitation_uuid/reject(.:format)
  // function(invitation_uuid, options)
  reject_invitation_path: Utils.route([["invitation_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"invitation_uuid",false],[2,[7,"/",false],[2,[6,"reject",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reject_organization_integration_invitation => /manage/integrations/organizations/invitations/:invitation_permalink(.:format)
  // function(invitation_permalink, options)
  reject_organization_integration_invitation_path: Utils.route([["invitation_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"invitation_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rename_file => /files/:file_permalink/rename(.:format)
  // function(file_permalink, options)
  rename_file_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rename_folder => /files/folders/:folder_permalink/rename(.:format)
  // function(folder_permalink, options)
  rename_folder_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rename_form_table_column => /forms/:permalink/tables/columns/:column/rename(.:format)
  // function(permalink, column, options)
  rename_form_table_column_path: Utils.route([["permalink",true],["column",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"column",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rename_post => /files/posts/:page_permalink/rename(.:format)
  // function(page_permalink, options)
  rename_post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rename_report => /forms/:permalink/reports/:report_permalink/rename(.:format)
  // function(permalink, report_permalink, options)
  rename_report_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// reorder_field_table_columns => /forms/:permalink/fields/:field/tables/columns/order(.:format)
  // function(permalink, field, options)
  reorder_field_table_columns_path: Utils.route([["permalink",true],["field",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// reorder_form_table_columns => /forms/:permalink/tables/columns/order(.:format)
  // function(permalink, options)
  reorder_form_table_columns_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// reorder_report_table_columns => /forms/:permalink/reports/:report_permalink/tables/columns/order(.:format)
  // function(permalink, report_permalink, options)
  reorder_report_table_columns_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// repeat_event => /forms/:permalink/calendars/:calendar_permalink/:submission_permalink/repeat/:period(.:format)
  // function(permalink, calendar_permalink, submission_permalink, period, options)
  repeat_event_path: Utils.route([["permalink",true],["calendar_permalink",true],["submission_permalink",true],["period",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"repeat",false],[2,[7,"/",false],[2,[3,"period",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// report => /forms/:permalink/reports/:report_permalink(.:format)
  // function(permalink, report_permalink, options)
  report_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// report_aggregation => /forms/:permalink/reports/:report_permalink/tables/aggregations/:aggregation_permalink(.:format)
  // function(permalink, report_permalink, aggregation_permalink, options)
  report_aggregation_path: Utils.route([["permalink",true],["report_permalink",true],["aggregation_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"aggregations",false],[2,[7,"/",false],[2,[3,"aggregation_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// report_columns_modal => /forms/:permalink/reports/:report_permalink/tables/settings(.:format)
  // function(permalink, report_permalink, options)
  report_columns_modal_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// report_export => /forms/:permalink/reports/:report_permalink/export(.:format)
  // function(permalink, report_permalink, options)
  report_export_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// report_export_modal => /forms/:permalink/reports/:report_permalink/table_export(.:format)
  // function(permalink, report_permalink, options)
  report_export_modal_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"table_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// report_formpack => /application_report(.:format)
  // function(options)
  report_formpack_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"application_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// report_permissions => /forms/:permalink/reports/:report_permalink/permissions(.:format)
  // function(permalink, report_permalink, options)
  report_permissions_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// report_table_data => /forms/:permalink/tables/reports/:report_permalink/data(.:format)
  // function(permalink, report_permalink, options)
  report_table_data_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// report_table_scaffold => /forms/:permalink/tables/reports/:report_permalink/scaffold(.:format)
  // function(permalink, report_permalink, options)
  report_table_scaffold_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"scaffold",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// reset_resource_ordering => /forms/ordering(.:format)
  // function(options)
  reset_resource_ordering_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// resize_field_table_columns => /forms/:permalink/tables/fields/:field_id/columns/resize(.:format)
  // function(permalink, field_id, options)
  resize_field_table_columns_path: Utils.route([["permalink",true],["field_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"resize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// resize_form_table_columns => /forms/:permalink/tables/columns/resize(.:format)
  // function(permalink, options)
  resize_form_table_columns_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"resize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// resize_report_table_columns => /forms/:permalink/tables/reports/:report_permalink/columns/resize(.:format)
  // function(permalink, report_permalink, options)
  resize_report_table_columns_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"resize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// resolve => /api/rest/v1/forms/:form_permalink/responses/:submission_permalink/comments/:comment_permalink/resolve(.:format)
  // function(form_permalink, submission_permalink, comment_permalink, options)
  resolve_path: Utils.route([["form_permalink",true],["submission_permalink",true],["comment_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"form_permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_permalink",false],[2,[7,"/",false],[2,[6,"resolve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]),
// resolve_submission_comment => /forms/:permalink/responses/:submission_permalink/comments/:comment_permalink/resolve(.:format)
  // function(permalink, submission_permalink, comment_permalink, options)
  resolve_submission_comment_path: Utils.route([["permalink",true],["submission_permalink",true],["comment_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_permalink",false],[2,[7,"/",false],[2,[6,"resolve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// restore => /api/rest/v1/forms/:form_permalink/responses/:submission_permalink/restore(.:format)
  // function(form_permalink, submission_permalink, options)
  restore_path: Utils.route([["form_permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"form_permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// restore_file => /files/:file_permalink/restore(.:format)
  // function(file_permalink, options)
  restore_file_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// restore_file_version => /files/:file_permalink/restore_version/:version_number(.:format)
  // function(file_permalink, version_number, options)
  restore_file_version_path: Utils.route([["file_permalink",true],["version_number",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"restore_version",false],[2,[7,"/",false],[2,[3,"version_number",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_form_version => /forms/:permalink/version/:version(.:format)
  // function(permalink, version, options)
  restore_form_version_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"version",false],[2,[7,"/",false],[2,[3,"version",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_post => /files/posts/:page_permalink/restore(.:format)
  // function(page_permalink, options)
  restore_post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_post_version => /files/posts/:page_permalink/restore_version/:version_number(.:format)
  // function(page_permalink, version_number, options)
  restore_post_version_path: Utils.route([["page_permalink",true],["version_number",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"restore_version",false],[2,[7,"/",false],[2,[3,"version_number",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// restore_submission => /forms/:permalink/responses/:submission_permalink/restore(.:format)
  // function(permalink, submission_permalink, options)
  restore_submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// restore_submission_version => /forms/:permalink/responses/:submission_permalink/version/:version(.:format)
  // function(permalink, submission_permalink, version, options)
  restore_submission_version_path: Utils.route([["permalink",true],["submission_permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"version",false],[2,[7,"/",false],[2,[3,"version",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// reveal_submission_secret => /forms/:permalink/responses/:submission_permalink/secrets/:field_permalink(.:format)
  // function(permalink, submission_permalink, field_permalink, options)
  reveal_submission_secret_path: Utils.route([["permalink",true],["submission_permalink",true],["field_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"secrets",false],[2,[7,"/",false],[2,[3,"field_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// rss_0_91_report_submissions_feed => /forms/:permalink/reports/:report_permalink/feeds/rss/0.91(.:format)
  // function(permalink, report_permalink, options)
  rss_0_91_report_submissions_feed_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"rss",false],[2,[7,"/",false],[2,[6,"0",false],[2,[8,".",false],[2,[6,"91",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// rss_0_91_submissions_feed => /forms/:permalink/feeds/rss/0.91(.:format)
  // function(permalink, options)
  rss_0_91_submissions_feed_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"rss",false],[2,[7,"/",false],[2,[6,"0",false],[2,[8,".",false],[2,[6,"91",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rss_1_report_submissions_feed => /forms/:permalink/reports/:report_permalink/feeds/rss/1.0(.:format)
  // function(permalink, report_permalink, options)
  rss_1_report_submissions_feed_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"rss",false],[2,[7,"/",false],[2,[6,"1",false],[2,[8,".",false],[2,[6,"0",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// rss_1_submissions_feed => /forms/:permalink/feeds/rss/1.0(.:format)
  // function(permalink, options)
  rss_1_submissions_feed_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"rss",false],[2,[7,"/",false],[2,[6,"1",false],[2,[8,".",false],[2,[6,"0",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rss_2_report_submissions_feed => /forms/:permalink/reports/:report_permalink/feeds/rss/2.0(.:format)
  // function(permalink, report_permalink, options)
  rss_2_report_submissions_feed_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"rss",false],[2,[7,"/",false],[2,[6,"2",false],[2,[8,".",false],[2,[6,"0",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// rss_2_submissions_feed => /forms/:permalink/feeds/rss/2.0(.:format)
  // function(permalink, options)
  rss_2_submissions_feed_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"rss",false],[2,[7,"/",false],[2,[6,"2",false],[2,[8,".",false],[2,[6,"0",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rss_report_submissions_feed => /forms/:permalink/reports/:report_permalink/feeds/rss(.:format)
  // function(permalink, report_permalink, options)
  rss_report_submissions_feed_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"rss",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rss_submissions_feed => /forms/:permalink/feeds/rss(.:format)
  // function(permalink, options)
  rss_submissions_feed_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"feeds",false],[2,[7,"/",false],[2,[6,"rss",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// run_workflow_action => /forms/:permalink/workflows/actions/:action_permalink/run(.:format)
  // function(permalink, action_permalink, options)
  run_workflow_action_path: Utils.route([["permalink",true],["action_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[6,"actions",false],[2,[7,"/",false],[2,[3,"action_permalink",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// run_workflow_condition => /forms/:permalink/workflows/conditions/:condition_permalink/run(.:format)
  // function(permalink, condition_permalink, options)
  run_workflow_condition_path: Utils.route([["permalink",true],["condition_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[6,"conditions",false],[2,[7,"/",false],[2,[3,"condition_permalink",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// saml_authorize => /saml/:provider/authorize/:auth_token(.:format)
  // function(provider, auth_token, options)
  saml_authorize_path: Utils.route([["provider",true],["auth_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"authorize",false],[2,[7,"/",false],[2,[3,"auth_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// saml_authorize_submit => /saml/:provider/authorize/:auth_token(.:format)
  // function(provider, auth_token, options)
  saml_authorize_submit_path: Utils.route([["provider",true],["auth_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"authorize",false],[2,[7,"/",false],[2,[3,"auth_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// saml_consume => /saml/:provider/consume(.:format)
  // function(provider, options)
  saml_consume_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"consume",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// saml_init => /saml/:provider/init(.:format)
  // function(provider, options)
  saml_init_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"init",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// saml_logout => /saml/:provider/logout(.:format)
  // function(provider, options)
  saml_logout_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// saml_metadata => /saml/:provider/metadata(.:format)
  // function(provider, options)
  saml_metadata_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// save_kanban_ordering => /forms/:permalink/kanbans/:kanban_permalink/order(.:format)
  // function(permalink, kanban_permalink, options)
  save_kanban_ordering_path: Utils.route([["permalink",true],["kanban_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"kanbans",false],[2,[7,"/",false],[2,[3,"kanban_permalink",false],[2,[7,"/",false],[2,[6,"order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// scan_barcode => /utilities/barcodes/decode(.:format)
  // function(options)
  scan_barcode_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"utilities",false],[2,[7,"/",false],[2,[6,"barcodes",false],[2,[7,"/",false],[2,[6,"decode",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// script_sandbox => /utilities/scripts/sandbox(.:format)
  // function(options)
  script_sandbox_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"utilities",false],[2,[7,"/",false],[2,[6,"scripts",false],[2,[7,"/",false],[2,[6,"sandbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// select_facility => /select(.:format)
  // function(options)
  select_facility_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"select",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// send => /api/rest/v1/groups/:group_permalink/notifications/send(.:format)
  // function(group_permalink, options)
  send_path: Utils.route([["group_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_permalink",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"send",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// send_email_confirmation => /settings/accounts/profiles/emails/confirm(.:format)
  // function(options)
  send_email_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"profiles",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// send_form_mail_merge => /forms/:permalink/mail_merges/:mail_merge_permalink/send(.:format)
  // function(permalink, mail_merge_permalink, options)
  send_form_mail_merge_path: Utils.route([["permalink",true],["mail_merge_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"mail_merges",false],[2,[7,"/",false],[2,[3,"mail_merge_permalink",false],[2,[7,"/",false],[2,[6,"send",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// send_report_mail_merge => /forms/:permalink/mail_merges/:mail_merge_permalink/send/reports/:report_permalink(.:format)
  // function(permalink, mail_merge_permalink, report_permalink, options)
  send_report_mail_merge_path: Utils.route([["permalink",true],["mail_merge_permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"mail_merges",false],[2,[7,"/",false],[2,[3,"mail_merge_permalink",false],[2,[7,"/",false],[2,[6,"send",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// send_submissions_mail_merge => /forms/:permalink/mail_merges/:mail_merge_permalink/send/submissions(.:format)
  // function(permalink, mail_merge_permalink, options)
  send_submissions_mail_merge_path: Utils.route([["permalink",true],["mail_merge_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"mail_merges",false],[2,[7,"/",false],[2,[3,"mail_merge_permalink",false],[2,[7,"/",false],[2,[6,"send",false],[2,[7,"/",false],[2,[6,"submissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sentry_tunnel => /api/sentry-tunnel(.:format)
  // function(options)
  sentry_tunnel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"sentry-tunnel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// settings => /settings(.:format)
  // function(options)
  settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// settings_modal_with_two_factor => /settings/update/with_2fa_steps(.:format)
  // function(options)
  settings_modal_with_two_factor_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[6,"with_2fa_steps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// share_calendar => /forms/:permalink/calendars/:calendar_permalink/share(.:format)
  // function(permalink, calendar_permalink, options)
  share_calendar_path: Utils.route([["permalink",true],["calendar_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[2,[7,"/",false],[2,[6,"share",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// share_facility => /share(.:format)
  // function(options)
  share_facility_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"share",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// share_report => /forms/:permalink/reports/share(/:report_permalink)(.:format)
  // function(permalink, options)
  share_report_path: Utils.route([["permalink",true],["report_permalink",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"share",false],[2,[1,[2,[7,"/",false],[3,"report_permalink",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// share_submission => /forms/:permalink/responses/:submission_permalink/share(.:format)
  // function(permalink, submission_permalink, options)
  share_submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"share",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// show_field_table_column => /forms/:permalink/fields/:field/tables/columns/:column/show(.:format)
  // function(permalink, field, column, options)
  show_field_table_column_path: Utils.route([["permalink",true],["field",true],["column",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"column",false],[2,[7,"/",false],[2,[6,"show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// show_form_table_column => /forms/:permalink/tables/columns/:column/show(.:format)
  // function(permalink, column, options)
  show_form_table_column_path: Utils.route([["permalink",true],["column",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"column",false],[2,[7,"/",false],[2,[6,"show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// show_report_table_column => /forms/:permalink/reports/:report_permalink/tables/columns/:column/show(.:format)
  // function(permalink, report_permalink, column, options)
  show_report_table_column_path: Utils.route([["permalink",true],["report_permalink",true],["column",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"column",false],[2,[7,"/",false],[2,[6,"show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// show_submission_import => /forms/:permalink/imports/:import_permalink(.:format)
  // function(permalink, import_permalink, options)
  show_submission_import_path: Utils.route([["permalink",true],["import_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"imports",false],[2,[7,"/",false],[2,[3,"import_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sidebar_overview => /organization/overview(.:format)
  // function(options)
  sidebar_overview_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sort_form_table => /interface/tables/forms/:form_permalink/columns/sort(.:format)
  // function(form_permalink, options)
  sort_form_table_path: Utils.route([["form_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"interface",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"form_permalink",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"sort",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sort_report_table => /interface/tables/forms/:form_permalink/reports/:report_permalink/columns/sort(.:format)
  // function(form_permalink, report_permalink, options)
  sort_report_table_path: Utils.route([["form_permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"interface",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"form_permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"sort",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// start_trial => /trial/:trial(.:format)
  // function(trial, options)
  start_trial_path: Utils.route([["trial",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"trial",false],[2,[7,"/",false],[2,[3,"trial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// store => /apps(.:format)
  // function(options)
  store_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"apps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// store_application => /apps/:permalink(/:slug)(.:format)
  // function(permalink, options)
  store_application_path: Utils.route([["permalink",true],["slug",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[1,[2,[7,"/",false],[3,"slug",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// store_category => /apps/category/:tag(.:format)
  // function(tag, options)
  store_category_path: Utils.route([["tag",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[6,"category",false],[2,[7,"/",false],[2,[3,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_publisher => /apps/publisher/:publisher(.:format)
  // function(publisher, options)
  store_publisher_path: Utils.route([["publisher",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[6,"publisher",false],[2,[7,"/",false],[2,[3,"publisher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_search => /apps/search(.:format)
  // function(options)
  store_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stripe_event => /stripe/webhooks
  // function(options)
  stripe_event_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[6,"webhooks",false]]]]),
// stripe_event.root => /stripe/webhooks/
  // function(options)
  stripe_event_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[6,"webhooks",false]]]],[7,"/",false]]),
// submission => /forms/:permalink/responses/:submission_permalink(.:format)
  // function(permalink, submission_permalink, options)
  submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// submission_approval => /forms/:permalink/approvals/:approval_permalink/responses/:submission_permalink(.:format)
  // function(permalink, approval_permalink, submission_permalink, options)
  submission_approval_path: Utils.route([["permalink",true],["approval_permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"approval_permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// submission_authorize => /:permalink/authorize(.:format)
  // function(permalink, options)
  submission_authorize_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submission_comments => /forms/:permalink/responses/:submission_permalink/comments(.:format)
  // function(permalink, submission_permalink, options)
  submission_comments_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// submission_custom_view => /forms/:permalink/responses/:submission_permalink/views/:view_permalink(.:format)
  // function(permalink, submission_permalink, view_permalink, options)
  submission_custom_view_path: Utils.route([["permalink",true],["submission_permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// submission_custom_view_content => /forms/:permalink/responses/:submission_permalink/views/:view_permalink/content(.:format)
  // function(permalink, submission_permalink, view_permalink, options)
  submission_custom_view_content_path: Utils.route([["permalink",true],["submission_permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// submission_custom_views => /forms/:permalink/responses/views(.:format)
  // function(permalink, options)
  submission_custom_views_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"views",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// submission_drafts => /forms/:permalink/drafts(.:format)
  // function(permalink, options)
  submission_drafts_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"drafts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// submission_export => /forms/:permalink/responses/:submission_permalink/export(.:format)
  // function(permalink, submission_permalink, options)
  submission_export_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// submission_grant_new_user_registration => /sign_up/sharing/:submission_grant(.:format)
  // function(submission_grant, options)
  submission_grant_new_user_registration_path: Utils.route([["submission_grant",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"sharing",false],[2,[7,"/",false],[2,[3,"submission_grant",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// submission_grant_user_registration => /sign_up/sharing/:submission_grant(.:format)
  // function(submission_grant, options)
  submission_grant_user_registration_path: Utils.route([["submission_grant",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"sharing",false],[2,[7,"/",false],[2,[3,"submission_grant",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// submission_history => /forms/:permalink/responses/:submission_permalink/history(.:format)
  // function(permalink, submission_permalink, options)
  submission_history_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// submission_import_status => /forms/:permalink/imports/:import_permalink/status(.:format)
  // function(permalink, import_permalink, options)
  submission_import_status_path: Utils.route([["permalink",true],["import_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"imports",false],[2,[7,"/",false],[2,[3,"import_permalink",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// submission_imports => /forms/:permalink/imports(.:format)
  // function(permalink, options)
  submission_imports_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// submission_workflows => /forms/:permalink/responses/workflows(.:format)
  // function(permalink, options)
  submission_workflows_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"workflows",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// submissions => /forms/:permalink(.:format)
  // function(permalink, options)
  submissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_script => /forms/:permalink/script(.:format)
  // function(permalink, options)
  submit_script_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"script",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// submit_submission_approval => /forms/:permalink/approvals/:approval_permalink/responses/:submission_permalink(.:format)
  // function(permalink, approval_permalink, submission_permalink, options)
  submit_submission_approval_path: Utils.route([["permalink",true],["approval_permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"approval_permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// submit_submission_import => /forms/:permalink/imports/:import_permalink(.:format)
  // function(permalink, import_permalink, options)
  submit_submission_import_path: Utils.route([["permalink",true],["import_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"imports",false],[2,[7,"/",false],[2,[3,"import_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// submitted => /forms/:permalink/submitted(.:format)
  // function(permalink, options)
  submitted_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"submitted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// subscribe_form => /forms/:permalink/subscribe(.:format)
  // function(permalink, options)
  subscribe_form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"subscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// subscribe_submission => /forms/:permalink/responses/:submission_permalink/subscribe(.:format)
  // function(permalink, submission_permalink, options)
  subscribe_submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"subscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// subscribed_organization_integration => /manage/integrations/organizations/subscribe/:integration_permalink(.:format)
  // function(integration_permalink, options)
  subscribed_organization_integration_path: Utils.route([["integration_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"subscribe",false],[2,[7,"/",false],[2,[3,"integration_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// table_column_coloring_modal => /forms/:permalink/fields/:field_variable/coloring(.:format)
  // function(permalink, field_variable, options)
  table_column_coloring_modal_path: Utils.route([["permalink",true],["field_variable",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_variable",false],[2,[7,"/",false],[2,[6,"coloring",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// table_column_sorting_modal => /forms/:permalink/fields/:field_variable/sorting(.:format)
  // function(permalink, field_variable, options)
  table_column_sorting_modal_path: Utils.route([["permalink",true],["field_variable",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_variable",false],[2,[7,"/",false],[2,[6,"sorting",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// table_data => /forms/:permalink/tables/data(.:format)
  // function(permalink, options)
  table_data_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// table_export_modal => /forms/:permalink/table_export(/:username)(.:format)
  // function(permalink, options)
  table_export_modal_path: Utils.route([["permalink",true],["username",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"table_export",false],[2,[1,[2,[7,"/",false],[3,"username",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// table_scaffold => /forms/:permalink/tables/scaffold(.:format)
  // function(permalink, options)
  table_scaffold_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"scaffold",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// tagged_comments => /forms/:permalink/comments/tag/:tagname(.:format)
  // function(permalink, tagname, options)
  tagged_comments_path: Utils.route([["permalink",true],["tagname",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"tag",false],[2,[7,"/",false],[2,[3,"tagname",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// test_500 => /test_500(.:format)
  // function(options)
  test_500_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"test_500",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// toggle_manage_plugin => /manage/plugins/:plugin_permalink/toggle(.:format)
  // function(plugin_permalink, options)
  toggle_manage_plugin_path: Utils.route([["plugin_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"plugins",false],[2,[7,"/",false],[2,[3,"plugin_permalink",false],[2,[7,"/",false],[2,[6,"toggle",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// toggle_sidebar_form => /interface/sidebar/forms/:form_permalink/visible/:status(.:format)
  // function(form_permalink, status, options)
  toggle_sidebar_form_path: Utils.route([["form_permalink",true],["status",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"interface",false],[2,[7,"/",false],[2,[6,"sidebar",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"form_permalink",false],[2,[7,"/",false],[2,[6,"visible",false],[2,[7,"/",false],[2,[3,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// tutorial_disable => /settings/tutorial/disable(.:format)
  // function(options)
  tutorial_disable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tutorial",false],[2,[7,"/",false],[2,[6,"disable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tutorial_step => /tutorial/step(.:format)
  // function(options)
  tutorial_step_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tutorial",false],[2,[7,"/",false],[2,[6,"step",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// twilio_sms_phone_numbers => /organizations/integrations/services/twilio/sms_phone_numbers(.:format)
  // function(options)
  twilio_sms_phone_numbers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"twilio",false],[2,[7,"/",false],[2,[6,"sms_phone_numbers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// two_factor_steps => /settings/2fa_steps(.:format)
  // function(options)
  two_factor_steps_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"2fa_steps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unique_field_values => /forms/:permalink/fields/:variable_name/values(.:format)
  // function(permalink, variable_name, options)
  unique_field_values_path: Utils.route([["permalink",true],["variable_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"variable_name",false],[2,[7,"/",false],[2,[6,"values",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// unresolve_submission_comment => /forms/:permalink/responses/:submission_permalink/comments/:comment_permalink/unresolve(.:format)
  // function(permalink, submission_permalink, comment_permalink, options)
  unresolve_submission_comment_path: Utils.route([["permalink",true],["submission_permalink",true],["comment_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_permalink",false],[2,[7,"/",false],[2,[6,"unresolve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// unsubscribe_form => /forms/:permalink/subscribe(.:format)
  // function(permalink, options)
  unsubscribe_form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"subscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unsubscribe_submission => /forms/:permalink/responses/:submission_permalink/subscribe(.:format)
  // function(permalink, submission_permalink, options)
  unsubscribe_submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"subscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_calendar => /forms/:permalink/calendars/:calendar_permalink/edit(.:format)
  // function(permalink, calendar_permalink, options)
  update_calendar_path: Utils.route([["permalink",true],["calendar_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_dashboard_custom_view => /views/:view_permalink/edit(.:format)
  // function(view_permalink, options)
  update_dashboard_custom_view_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_database_integration => /manage/integrations/databases/:permalink/edit(.:format)
  // function(permalink, options)
  update_database_integration_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_database_integration_table => /manage/integrations/databases/:permalink/forms/:table_permalink/edit(.:format)
  // function(permalink, table_permalink, options)
  update_database_integration_table_path: Utils.route([["permalink",true],["table_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"databases",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"table_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// update_file_permissions => /files/:file_permalink/permissions(.:format)
  // function(file_permalink, options)
  update_file_permissions_path: Utils.route([["file_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"file_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_folder_permissions => /files/folders/:folder_permalink/permissions(.:format)
  // function(folder_permalink, options)
  update_folder_permissions_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_form => /forms/:permalink/edit(.:format)
  // function(permalink, options)
  update_form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_form_approval => /forms/:permalink/approvals/:approval_permalink/edit(.:format)
  // function(permalink, approval_permalink, options)
  update_form_approval_path: Utils.route([["permalink",true],["approval_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"approval_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_form_columns => /forms/:permalink/tables/settings(.:format)
  // function(permalink, options)
  update_form_columns_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_form_custom_view => /forms/:permalink/views/:view_permalink/edit(.:format)
  // function(permalink, view_permalink, options)
  update_form_custom_view_path: Utils.route([["permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_form_folder => /forms/folders/:folder_permalink/edit(.:format)
  // function(folder_permalink, options)
  update_form_folder_path: Utils.route([["folder_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"folders",false],[2,[7,"/",false],[2,[3,"folder_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_form_permissions => /forms/:permalink/permissions(.:format)
  // function(permalink, options)
  update_form_permissions_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_form_procedure => /forms/:permalink/procedures/:procedure_permalink/edit(.:format)
  // function(permalink, procedure_permalink, options)
  update_form_procedure_path: Utils.route([["permalink",true],["procedure_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"procedures",false],[2,[7,"/",false],[2,[3,"procedure_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_form_workflows => /forms/:permalink/workflows(.:format)
  // function(permalink, options)
  update_form_workflows_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"workflows",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_formpack_plugin => /developers/apps/:permalink/versions/:version/plugins/:plugin_permalink/edit(.:format)
  // function(permalink, version, plugin_permalink, options)
  update_formpack_plugin_path: Utils.route([["permalink",true],["version",true],["plugin_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"plugins",false],[2,[7,"/",false],[2,[3,"plugin_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// update_formpack_theme_plugin => /developers/apps/:permalink/versions/:version/themes/:plugin_permalink/edit(.:format)
  // function(permalink, version, plugin_permalink, options)
  update_formpack_theme_plugin_path: Utils.route([["permalink",true],["version",true],["plugin_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"themes",false],[2,[7,"/",false],[2,[3,"plugin_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// update_formpack_version => /developers/apps/:permalink/versions/:version/edit(.:format)
  // function(permalink, version, options)
  update_formpack_version_path: Utils.route([["permalink",true],["version",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"developers",false],[2,[7,"/",false],[2,[6,"apps",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_group => /manage/groups/edit/:group_permalink(.:format)
  // function(group_permalink, options)
  update_group_path: Utils.route([["group_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"group_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_integration => /manage/integrations/edit/:integration_name/:permalink(.:format)
  // function(integration_name, permalink, options)
  update_integration_path: Utils.route([["integration_name",true],["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"integration_name",false],[2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_kanban => /forms/:permalink/kanbans/:kanban_permalink/edit(.:format)
  // function(permalink, kanban_permalink, options)
  update_kanban_path: Utils.route([["permalink",true],["kanban_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"kanbans",false],[2,[7,"/",false],[2,[3,"kanban_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_mail_merge => /forms/:permalink/mail_merges/:mail_merge_permalink/edit(.:format)
  // function(permalink, mail_merge_permalink, options)
  update_mail_merge_path: Utils.route([["permalink",true],["mail_merge_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"mail_merges",false],[2,[7,"/",false],[2,[3,"mail_merge_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_manage_account_name => /manage/integrations/accounts/:provider/rename(.:format)
  // function(provider, options)
  update_manage_account_name_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_manage_branding_rule => /manage/settings/display/branding(.:format)
  // function(options)
  update_manage_branding_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"display",false],[2,[7,"/",false],[2,[6,"branding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_manage_integration_config => /manage/settings/integrations/config/:name(.:format)
  // function(name, options)
  update_manage_integration_config_path: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"config",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_manage_ip_rule => /manage/settings/authorization/ip_addresses/:rule_permalink/edit(.:format)
  // function(rule_permalink, options)
  update_manage_ip_rule_path: Utils.route([["rule_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"authorization",false],[2,[7,"/",false],[2,[6,"ip_addresses",false],[2,[7,"/",false],[2,[3,"rule_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_manage_localization_rule => /manage/settings/display/localizations(.:format)
  // function(options)
  update_manage_localization_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"display",false],[2,[7,"/",false],[2,[6,"localizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_manage_storage_rules => /manage/settings/storage(.:format)
  // function(options)
  update_manage_storage_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"storage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_partial_custom_view => /views/partials/:view_permalink/edit(.:format)
  // function(view_permalink, options)
  update_partial_custom_view_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_partial_custom_view_permissions => /views/partials/:view_permalink/permissions(.:format)
  // function(view_permalink, options)
  update_partial_custom_view_permissions_path: Utils.route([["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[6,"partials",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_post => /files/posts/:page_permalink/edit(.:format)
  // function(page_permalink, options)
  update_post_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_post_permissions => /files/posts/:page_permalink/permissions(.:format)
  // function(page_permalink, options)
  update_post_permissions_path: Utils.route([["page_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"page_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_preferences => /forms/:permalink/edit/preferences(.:format)
  // function(permalink, options)
  update_preferences_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[6,"preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_procedure_options => /forms/:permalink/procedures/:procedure_permalink/options/edit(.:format)
  // function(permalink, procedure_permalink, options)
  update_procedure_options_path: Utils.route([["permalink",true],["procedure_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"procedures",false],[2,[7,"/",false],[2,[3,"procedure_permalink",false],[2,[7,"/",false],[2,[6,"options",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_published_organization_integration => /manage/integrations/organizations/publish/:integration_permalink/edit(.:format)
  // function(integration_permalink, options)
  update_published_organization_integration_path: Utils.route([["integration_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"publish",false],[2,[7,"/",false],[2,[3,"integration_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_report_columns => /forms/:permalink/reports/:report_permalink/tables/settings(.:format)
  // function(permalink, report_permalink, options)
  update_report_columns_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_report_permissions => /forms/:permalink/reports/:report_permalink/permissions(.:format)
  // function(permalink, report_permalink, options)
  update_report_permissions_path: Utils.route([["permalink",true],["report_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_permalink",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_resource_ordering => /forms/ordering(.:format)
  // function(options)
  update_resource_ordering_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_submission => /forms/:permalink/responses/:submission_permalink/edit(.:format)
  // function(permalink, submission_permalink, options)
  update_submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_submission_custom_view => /forms/:permalink/responses/views/:view_permalink/edit(.:format)
  // function(permalink, view_permalink, options)
  update_submission_custom_view_path: Utils.route([["permalink",true],["view_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"view_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_submission_draft => /forms/:permalink/drafts/:draft_permalink(.:format)
  // function(permalink, draft_permalink, options)
  update_submission_draft_path: Utils.route([["permalink",true],["draft_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"drafts",false],[2,[7,"/",false],[2,[3,"draft_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_submission_email_permissions => /forms/:permalink/responses/:submission_permalink/share(.:format)
  // function(permalink, submission_permalink, options)
  update_submission_email_permissions_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"share",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_submission_field => /forms/:permalink/submissions/:submission_permalink/fields/:variable_name/edit(.:format)
  // function(permalink, submission_permalink, variable_name, options)
  update_submission_field_path: Utils.route([["permalink",true],["submission_permalink",true],["variable_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"variable_name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// update_submission_viewer_columns => /forms/:permalink/responses/settings(.:format)
  // function(permalink, options)
  update_submission_viewer_columns_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_table_column_coloring => /forms/:permalink/fields/:field_variable/coloring(.:format)
  // function(permalink, field_variable, options)
  update_table_column_coloring_path: Utils.route([["permalink",true],["field_variable",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_variable",false],[2,[7,"/",false],[2,[6,"coloring",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_table_column_sorting => /forms/:permalink/fields/:field_variable/sorting(.:format)
  // function(permalink, field_variable, options)
  update_table_column_sorting_path: Utils.route([["permalink",true],["field_variable",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"field_variable",false],[2,[7,"/",false],[2,[6,"sorting",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_users_form => /forms/users/edit(.:format)
  // function(options)
  update_users_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_webhook => /forms/:permalink/webhooks/:webhook_permalink/edit(.:format)
  // function(permalink, webhook_permalink, options)
  update_webhook_path: Utils.route([["permalink",true],["webhook_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[3,"webhook_permalink",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// upload => /api/rest/v1/files/home/upload(.:format)
  // function(options)
  upload_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"home",false],[2,[7,"/",false],[2,[6,"upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// upload_file => /files/upload(.:format)
  // function(options)
  upload_file_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[6,"upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user => /api/v1/user(.:format)
  // function(options)
  user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_forms => /users/:username/forms/:form_permalink(.:format)
  // function(username, form_permalink, options)
  user_forms_path: Utils.route([["username",true],["form_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"form_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_profile => /users/:username(.:format)
  // function(username, options)
  user_profile_path: Utils.route([["username",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"username",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /sign_up(/:role)(.:format)
  // function(options)
  user_registration_path: Utils.route([["role",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[2,[1,[2,[7,"/",false],[3,"role",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// user_session => /login(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// validate_new_submission => /forms/:permalink/responses/validate(.:format)
  // function(permalink, options)
  validate_new_submission_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// validate_submission => /forms/:permalink/responses/:submission_permalink/validate(.:format)
  // function(permalink, submission_permalink, options)
  validate_submission_path: Utils.route([["permalink",true],["submission_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// verifications => /settings/accounts/verifications(.:format)
  // function(options)
  verifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"verifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// view_attachment => /forms/:permalink/responses/:submission_permalink/files/:attachment_permalink(.:format)
  // function(permalink, submission_permalink, attachment_permalink, options)
  view_attachment_path: Utils.route([["permalink",true],["submission_permalink",true],["attachment_permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"submission_permalink",false],[2,[7,"/",false],[2,[6,"files",false],[2,[7,"/",false],[2,[3,"attachment_permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// view_form => /:permalink(.:format)
  // function(permalink, options)
  view_form_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"permalink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// view_search => /forms/:permalink/search/view(.:format)
  // function(permalink, options)
  view_search_path: Utils.route([["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"view",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// welcome => /welcome(.:format)
  // function(options)
  welcome_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"welcome",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// where => /api/v1/organizations/:facility_permalink/forms/:permalink/submissions/where(.:format)
  // function(facility_permalink, permalink, options)
  where_path: Utils.route([["facility_permalink",true],["permalink",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"facility_permalink",false],[2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"submissions",false],[2,[7,"/",false],[2,[6,"where",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// workflow_action_formula => /forms/:permalink/workflows/actions/:action_permalink/formulas/:option(.:format)
  // function(permalink, action_permalink, option, options)
  workflow_action_formula_path: Utils.route([["permalink",true],["action_permalink",true],["option",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[6,"actions",false],[2,[7,"/",false],[2,[3,"action_permalink",false],[2,[7,"/",false],[2,[6,"formulas",false],[2,[7,"/",false],[2,[3,"option",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// workflow_condition_formula => /forms/:permalink/workflows/conditions/:condition_permalink/formulas/:option(.:format)
  // function(permalink, condition_permalink, option, options)
  workflow_condition_formula_path: Utils.route([["permalink",true],["condition_permalink",true],["option",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[6,"conditions",false],[2,[7,"/",false],[2,[3,"condition_permalink",false],[2,[7,"/",false],[2,[6,"formulas",false],[2,[7,"/",false],[2,[3,"option",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// workflow_trigger_formula => /forms/:permalink/workflows/triggers/:trigger_permalink/formulas/:option(.:format)
  // function(permalink, trigger_permalink, option, options)
  workflow_trigger_formula_path: Utils.route([["permalink",true],["trigger_permalink",true],["option",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"permalink",false],[2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[6,"triggers",false],[2,[7,"/",false],[2,[3,"trigger_permalink",false],[2,[7,"/",false],[2,[6,"formulas",false],[2,[7,"/",false],[2,[3,"option",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);

