
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.ColumnResizing = {
  initialize_resizing: ->
    return unless @has_permission("manage")

    for column in @columns()
      continue if column.field_id == "checkbox"

      grip = document.createElement("DIV")
      grip.className = "table_column_grip"
      grip.innerHTML = " "

      grip.addEventListener("click", App.Elements.ResponseTable.column_grip_click, false)
      grip.addEventListener("mousedown", App.Elements.ResponseTable.column_resize_start(column), passive: true)
      grip.addEventListener("touchstart", App.Elements.ResponseTable.column_resize_start(column), passive: true)

      column.element.appendChild(grip)
      column.resize_sync()

  apply_resizing: ->
    if @fake_cell_element?
      total_column_widths = 0

      for column in @columns()
        total_column_widths += column.column_width if column.visible()

      fake_cell_width = @table_element.offsetWidth - total_column_widths
      fake_cell_width = 0 if fake_cell_width < 0

      @fake_cell_element.style.maxWidth = fake_cell_width + "px"
      @fake_cell_element.style.minWidth = fake_cell_width + "px"
      @fake_cell_element.style.width = fake_cell_width + "px"

    column.resize_sync() for column, index in @columns()

    @apply_real_widths_to_sticky_header()

    null

  apply_real_widths_to_sticky_header: (model) ->
    return unless @header_is_sticky()

    row = @table_body_element.firstChild
    return unless row? && row.nodeType == 1

    if model?
      cell = row.children[model.index()]
      return unless cell?

      width = cell.offsetWidth

      model.element.style.maxWidth = width + "px"
      model.element.style.minWidth = width + "px"
      model.element.style.width = width + "px"
    else
      for column, index in @columns()
        cell = row.children[index]
        continue unless cell?

        width = cell.offsetWidth

        column.element.style.maxWidth = width + "px"
        column.element.style.minWidth = width + "px"
        column.element.style.width = width + "px"

    null
}
