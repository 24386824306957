
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}
window.App.Frame.Sandbox.Bind ?= {}

window.App.Frame.Sandbox.Bind.calendars = (channel, options) ->
  if options.read == true
    channel.on "schema.load.calendars", (event) ->
      form = App.Models.Form.find(event.data.form)
      options = { source: event.data.source }

      form.calendars.schema({ source: event.data.source })
      .then (data, response) -> event.resolve(response)
      .catch event.reject

  if options.read == true
    channel.on "schema.load.calendar", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.calendars.schema()
      .then ->
        calendar = form.calendars.find_by_permalink(event.data.calendar)
        calendar ?= new App.Schema.Form.Calendar(form.permalink(), {
          permalink: event.data.calendar
        })

        calendar.sandboxes().add(channel)

        calendar.schema({ source: event.data.source })
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject

  if options.destroy == true
    channel.on "calendar.destroy", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.calendars.schema()
      .then ->
        calendar = form.calendars.find_by_permalink(event.data.calendar)

        return event.reject("Calendar not found") unless calendar?

        calendar.destroy()
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject

  if options.update == true
    channel.on "calendar.update", (event) ->
      form = App.Models.Form.find(event.data.form)

      form.calendars.schema()
      .then ->
        calendar = form.calendars.find_by_permalink(event.data.calendar)

        return event.reject("Calendar not found") unless calendar?

        calendar.update(event.data.parameters)
        .then (data, response) -> event.resolve(response)
        .catch (response) -> event.reject(response)
      .catch event.reject
