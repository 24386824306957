
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}

class window.App.Interface.Menu.BaseOption
  constructor: (@menu, options = {}) ->
    @_generate(options)

    @_generate_sandbox() if @menu.sandboxes.started()

  _generate: (options) ->
    # Override

  _generate_sandbox: ->
    @_subscribe "visibility.show", => @show()
    @_subscribe "visibility.hide", => @hide()

    @_subscribe "remove", => @destroy()

  _publish: (event, data) ->
    @menu.sandboxes.publish("option.#{@instance_id}.#{event}", data)

  _subscribe: (event, callback) ->
    @menu.sandboxes.subscribe("option.#{@instance_id}.#{event}", callback)

  reapply_callbacks: ->
    return unless @_callbacks?

    old_callbacks = @_callbacks
    old_preloads = @_preloads

    @_callbacks = []
    @_preloads = []

    @callback(callback) for callback in old_callbacks
    @preload(callback) for callback in old_preloads

    undefined

  trigger_callbacks: ->
    return unless @_callbacks?

    for callback in @_callbacks
      callback.apply(@, arguments)

    undefined

  trigger_preloads: ->
    return unless @_preloads?

    for callback in @_preloads
      callback.apply(@, arguments)

    undefined

  callback: (callback) ->
    return unless callback?

    @_callbacks ?= []
    @_callbacks.push(callback)

  preload: (callback) ->
    return unless callback?

    @_preloads ?= []
    @_preloads.push(callback)

  remove: -> @destroy()

  show: ->
    @element.classList.remove("hidden")
    @_publish "show"

    undefined

  hide: ->
    @element.classList.add("hidden")
    @_publish "hide"

    undefined

  visible: -> !@element.classList.contains("hidden")

  toggle: (value = @visible()) ->
    if value then @show() else @hide()

  count: (value) ->
    badge = @element.querySelector(".option-counter")

    if badge?
      if value?
        if value == 0
          badge.parentNode.removeChild(badge)
          return 0
        else
          badge.innerHTML = value
      else
        return parseInt(badge.innerHTML)
    else
      if value?
        return 0 if value == 0

        badge = document.createElement("DIV")
        badge.className = "option-counter"
        @element.appendChild(badge)

        badge.innerHTML = value
      else
        return 0
