
window.App ?= {}

window.App.windowing_enabled = ->
  return false unless App.window_manager?

  App.window_manager.windowing_enabled()

window.App.locale = ->
  i18n.language

window.App.feature = (feature) ->
  return false unless App._features?
  App._features[feature] == true

$ -> App.Models.User.initialize_current_user()
