
$(document).on "click", ".response_table_condition_delete", (event) ->
  event.preventDefault()
  event.stopPropagation()

  element = event.currentTarget.parentNode
  instance = element.dataset.instance

  table_element = App.Helpers.Elements.closest(element, ".table_container")

  table = App.tables.find_by_element(table_element)

  condition = table.query.find_condition_by_instance_id(instance)
  return unless condition?

  condition.destroy(true)

  table.refresh()

$(document).on "click", ".response_table_condition", (event) ->
  event.preventDefault()

  element = event.currentTarget
  instance = element.dataset.instance

  table_element = App.Helpers.Elements.closest(element, ".table_container")

  table = App.tables.find_by_element(table_element)

  table.form().schema().then ->
    table.query_editor().modal.show().then (modal) ->
      condition_element = modal.element.querySelector(".response_condition[data-instance='#{instance}']")

      return unless condition_element?

      App.Elements.ResponseQuery.Query.toggle_condition_drawer(condition_element, true)

$(document).on "click", ".response_table_condition_separator", (event) ->
  event.preventDefault()

  element = event.currentTarget
  operator = element.textContent.toLowerCase()

  group_element = App.Helpers.Elements.closest(element, ".response_table_conditions")
  instance = group_element.dataset.instance

  table_element = App.Helpers.Elements.closest(group_element, ".table_container")
  table = App.tables.find_by_element(table_element)

  group = table.query.find_condition_by_instance_id(instance)
  group.toggle_logical_operator(operator)

  table.refresh()

$(document).on "click", ".edit_response_table_conditions", (event) ->
  event.preventDefault()

  element = event.currentTarget.parentNode
  instance = element.dataset.instance

  table_element = App.Helpers.Elements.closest(element, ".table_container")

  table = App.tables.find_by_element(table_element)

  table.form().schema().then ->
    table.query_editor().modal.show()

$(document).on "click", ".destroy_response_table_conditions", (event) ->
  event.preventDefault()

  element = event.currentTarget.parentNode
  instance = element.dataset.instance

  table_element = App.Helpers.Elements.closest(element, ".table_container")

  table = App.tables.find_by_element(table_element)

  table.form().schema().then ->
    editor = table.query_editor()
    editor.editor_query.clear()
    editor.update_query()

$(document).on "change", ".response_table_simple_condition_value_select", (event) ->
  event.preventDefault()

  input = event.currentTarget
  condition_element = App.Helpers.Elements.closest(input, ".response_table_simple_condition")
  instance = condition_element.dataset.instance

  table_element = App.Helpers.Elements.closest(condition_element, ".table_container")
  table = App.tables.find_by_element(table_element)

  condition = table.query.find_condition_by_instance_id(instance)
  condition.value(input.value)

  table.refresh()
