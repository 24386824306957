
class window.ResourceList
  constructor: (@list) ->
    @list = $(@list)

    @wrapper = @list.closest(".list_wrapper")

    @resources = []

    for element in @list.find("." + @resource_item_classname())
      @resources.push @resource_object($(element))

    @new_button = @wrapper.find(@resource_new_selector())

    if @new_button.prop("tagName") == "LI"
      @new_button_item = @new_button
    else
      @new_button_item = @new_button.closest("li")

    if @new_button_item? && @new_button_item.length == 0
      @new_button_item = undefined

    @new_button.on "click", (e) => @new(e)

    @_recount()

  cache_new_resources: -> true

  resource_item_classname: -> "resource"

  resource_new_selector: -> ".new-resource"

  resource_class: -> window.Resource
  resource_object: (element) -> new (@resource_class())(element, @)

  new_resource_element: ->
    $("<li/>", {
      class: @resource_item_classname()
    })

  unsaved_resources: ->
    output = []

    for resource in @resources
      output.push(resource) unless resource.persisted()

    output

  new: (event) ->
    event.preventDefault()

    resource = undefined

    if @cache_new_resources()
      resource = @unsaved_resources()[0]

    if !resource?
      resource = @resource_object(@new_resource_element())
      @resources.push(resource)
      @_recount()

    resource.new(event)
    resource

  _remove: (resource) ->
    index = @resources.indexOf(resource)
    return if index == -1

    @resources.splice(index, 1)
    @_recount()

  _recount: ->
    @wrapper.attr("data-count", @resources.length)
