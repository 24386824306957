
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseCalendar ?= {}
window.App.Elements.ResponseCalendar.Helpers ?= {}

class window.App.Elements.ResponseCalendar.Helpers.Params
  constructor: (@period) ->

  view_type: ->
    @period.element.dataset.view

  year: ->
    year = @period.element.dataset.year
    return if !year? || year.length == 0
    parseInt(year)

  month: ->
    month = @period.element.dataset.month
    return if !month? || month.length == 0
    parseInt(month)

  week: ->
    return unless @view_type() == "week"

    week = @period.element.dataset.week
    return if !week? || week.length == 0
    parseInt(week)

  start_field: (value) ->
    if value?
      if value instanceof App.Schema.Form.Field
        value = value.column_name()
      else if value instanceof App.Schema.Form.Header
        value = value.column_name()

      @period.element.setAttribute("data-start-field", value)
      @period.refresh()

    field_name = @period.element.dataset.startField
    return if !field_name? || field_name.length == 0
    field_name
