
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

window.App.Elements.ResponseTable.Subscriptions = {
  subscription_permalink: ->
    @_subscription_permalink

  subscription_toggle: (event) ->
    event.preventDefault() if event.preventDefault?
    button = node_from_object(event)

    if button?
      return if button.classList.contains("loading")
      add_loader(button)

    @form().subscription_toggle(@subscription_permalink(), @params.report())
    .done (response) =>
      remove_loader(button) if button?
      @subscription_update(response.permalink)
    .fail (xhr) =>
      remove_loader(button) if button?
      App.Errors.response_error(xhr)

  subscription_update: (permalink) ->
    if !permalink? || permalink.length == 0
      return @_subscription_permalink = undefined

    @_subscription_permalink = permalink

  subscribed: ->
    @_subscription_permalink?
}
