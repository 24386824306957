
$(document).on "click", ".open_submission_drafts", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  form.submissions.drafts.list_modal.show()
  .then -> remove_loader(button)
  .catch (error) ->
    remove_loader(button)
    throw error

$(document).on "click", ".open_submission_draft", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  draft_permalink = button.dataset.permalink

  form.submissions.drafts.show_save_modal(draft_permalink)
  .then (modal) ->
    form.submissions.drafts.list_modal.hide()
    remove_loader(button)
  .catch (error) ->
    remove_loader(button)
    throw error

$(document).on "click", ".delete_submission_draft", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  draft_permalink = button.dataset.permalink

  request = form.submissions.drafts.destroy(draft_permalink)

  request
  .then -> remove_loader button
  .catch (error) ->
    remove_loader button
    throw error

$(document).on "click", ".open_save_draft", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  submission_element = App.Helpers.Elements.closest(button, ".SUBMISSION")
  return unless submission_element?

  add_loader button

  form_permalink = submission_element.dataset.formPermalink
  instance_id = submission_element.dataset.instance

  form = App.Models.Form.new(form_permalink)
  submission = form.submissions._find_by_parameter("instance_id", instance_id)

  submission.draft.create()
  .then (response) ->
    if button?
      remove_loader(button)
      button.textContent = i18n.t("submissions.drafts.saved")
      setTimeout (-> button.innerHTML = button.dataset.originalValue), 2000
  .catch (response) ->
    if button?
      button.innerHTML = "<i class='icon icon-attention danger'></i> An Error Occurred"
      setTimeout (-> button.innerHTML = button.dataset.originalValue), 2000
