
window.App ?= {}
window.App.Schema ?= {}

class window.App.Schema.SubmissionCollection
  include @, App.Schema.CollectionBase

  _index_keys: true

  constructor: (@form_permalink) ->
    undefined

  form: -> App.Models.Form.new(@form_permalink)

  model_instance: (data) -> new App.Schema.Submission(@form_permalink, data)
  model_class: -> App.Schema.Submission

  # Override as necessary on subclasses
  value_to_model: (data) ->
    if data instanceof App.Schema.Submission || data instanceof App.Models.Submission.Object
      data = data.permalink()

    for model in @models()
      return model if model.permalink() == data

    undefined

  request_data: ->
    @_request_data || {}

  index_by_permalink: ->
    output = {}

    for submission in @all()
      output[submission.permalink()] = submission

    output

  submissions: ->
    requests = []
    requests.push(data.submission()) for data in @models()

    Promise.all(requests)

  next_page: ->
    data = App.Helpers.Objects.deep_clone(@request_data())
    data.page = (data.page || 0) + 1

    if @is_last_page() || data.page < 0
      return Promise.resolve(@constructor.from_response(
        @form_permalink, { count: @_request_total }, data
      ))

    @form().submissions._query_request(data)

  previous_page: ->
    data = App.Helpers.Objects.deep_clone(@request_data())
    data.page = (data.page || 0) - 1

    if @is_first_page() || data.page < 0
      return Promise.resolve(@constructor.from_response(
        @form_permalink, { count: @_request_total }, data
      ))

    @form().submissions._query_request(data)

  first_page: ->
    return Promise.resolve(@) if @is_first_page()

    data = App.Helpers.Objects.deep_clone(@request_data())
    data.page = 0

    @form().submissions._query_request(data)

  last_page: ->
    return Promise.resolve(@) if @is_last_page()

    data = App.Helpers.Objects.deep_clone(@request_data())

    if @_request_total?
      data.length ?= 100
      data.page = (@_request_total / data.length) - 1
      return @form().submissions._query_request(data)

    # If we don't know the total responses, reverse the order and get the first page.
    # Then, reverse the responses in the returned data.
    new App.Schema.SubmissionPromise (resolve, reject) =>
      reverse_data = App.Helpers.Objects.deep_clone(data)
      reverse_data.order = if data.order == "desc" then "asc" else "desc"
      reverse_data.page = 0

      query_data = reverse_data.query
      delete reverse_data.query

      if App.is_child
        App.parent.publish(
          "submission_data.query",
          form: @form_permalink,
          query: query_data,
          options: reverse_data
        ).then (response_data) =>
          response_data.responses.reverse()

          @_request_total = response_data.count
          data.length ?= 100
          data.page = (@_request_total / data.length) - 1

          resolve App.Schema.SubmissionCollection.from_response(
            @form_permalink, response_data, data
          )

      else
        reverse_data.authenticity_token = current_user.authenticity_token()

        $.ajax
          url: "/api/rest/v1/forms/#{@form_permalink}/responses"
          data: reverse_data
        .done (response_data) =>
          response_data.responses.reverse()

          @_request_total = response_data.count
          data.length ?= 100
          data.page = (@_request_total / data.length) - 1

          resolve App.Schema.SubmissionCollection.from_response(
            @form_permalink, response_data, data
          )
        .fail (response) ->
          reject(response)

  is_first_page: ->
    page = @request_data().page

    !page? || page == 0

  is_last_page: ->
    return false unless @_request_total?

    data = @request_data()

    length = data.length || 100
    page = data.page || 0

    (length * (page + 1)) >= @_request_total

  save: ->
    promises = []

    for submission in @models()
      promises.push(submission.save())

    Promise.all(promises)

  destroy: (options = {}) ->
    promises = []

    for submission in @models()
      promises.push(submission.destroy())

    if options.all != true || !@is_last_page()
      return Promise.all(promises)

    new Promise (resolve, reject) =>
      @next_page().then (next_page) =>
        next_page.destroy(options).then (results) =>
          resolve(promises.concat(results))

  restore: (options = {}) ->
    promises = []

    for submission in @models()
      promises.push(submission.restore())

    if options.all != true || !@is_last_page()
      return Promise.all(promises)

    new Promise (resolve, reject) =>
      @next_page().then (next_page) =>
        next_page.restore(options).then (results) =>
          resolve(promises.concat(results))

  load: ->
    new App.Schema.SubmissionPromise (resolve, reject) =>
      request_data = Object.assign({}, @request_data())
      request_data.authenticity_token = current_user.authenticity_token()

      if App.is_child
        App.parent.publish("submissions.query", {
          form_permalink: @form().permalink(),
          conditions: request_data.conditions,
          options: request_data
        }).then (response) =>
          @load_response(response)
          resolve(@)
        .catch reject
      else
        $.ajax
          url: "/api/rest/v1/forms/#{@form_permalink}/responses"
          data: request_data
        .done (response) =>
          @load_response(response)
          resolve(@)
        .fail (response) ->
          reject(response.responseJSON)

  load_response: (data) ->
    @_request_total = data.count

    @push(submission) for submission in data.responses

    data

  serialize: ->
    output = {}

    if @_request_data?
      output._request_data = @_request_data

    if @_request_total?
      output.count = @_request_total

    output.responses = []

    for submission in @all()
      output.responses.push(submission.serialize())

    output

  @from_response: (form_permalink, response_data, request_data) ->
    array = new @ form_permalink
    array.load_response(response_data)

    if request_data?
      request_data = Object.assign({}, request_data)
      delete request_data.authenticity_token
      array._request_data = request_data

    array

window.SubmissionDataArray = App.Schema.SubmissionCollection
