
window.App ?= {}
window.App.Frame ?= {}

class window.App.Frame.SyncedNode
  constructor: (@child_channel, @element) ->
    @serialization = App.Helpers.Elements.serialize(@element, (n) => @each_node(n))

  each_node: (node) ->
    node_tag = node.tagName

    if node.tagName == "INPUT" || node.tagName == "TEXTAREA"
      node.addEventListener "input", (event) =>
        node = event.currentTarget

        @child_channel.publish("html.event.#{node.nodeUUID}", {
          type: "input",
          value: node.value
        })

    node.addEventListener "click", (event) =>
      node = event.currentTarget

      @child_channel.publish("html.event.#{node.nodeUUID}", {
        type: event.type
      })


