import 'app/models/submission/draft/manager'


window.App ?= {}
window.App.Models ?= {}

class window.App.Models.SubmissionManager
  constructor: (@form_permalink) ->
    @submission_data = []
    @submission_view_modals = {}
    @_new_submission_requests = {}

    @drafts = new App.Models.Submission.Draft.Manager(@form_permalink)

  _find_by_parameter: (parameter, value) ->
    param_is_function = App.Helpers.Objects.isFunction(parameter)

    for submission in @submission_data
      member = submission[parameter]
      member_is_function = App.Helpers.Objects.isFunction(member)

      if member_is_function
        return submission if member() == value
      else
        return submission if member == value

    undefined

  _value_to_submission: (value) ->
    if !value?
      return @new_submission()
    else if typeof value == "number"
      return @submission_data[value]
    else if typeof value == "string"
      if App.Helpers.Objects.isPermalinkString(value)
        model = @_find_by_parameter("submission_permalink", value)
        return model if model?
      return @_find_by_parameter("instance_id", value)
    else if App.Helpers.Elements.is_jquery(value)
      return @_find_by_parameter("_element", value[0])
    else if value.nodeType?
      return @_find_by_parameter("_element", value)
    else if value instanceof App.Models.Submission.Object
      return value

    undefined

  # Requests a submission's form from the server with AJAX
  _load_submission: (url, options) ->
    App.Frame.Sandbox.preload()

    # Preload the submission workflows script if it exists.
    @form().load().then (form) =>
      form._get_workflows_script() if form.data.workflows

    new Promise (resolve, reject) =>
      $.ajax
        url: url
        type: "GET"
        context: @
      .done (response) =>
        resolve(@initialize_html(response, options))
      .fail (xhr) ->
        App.Errors.response_error(xhr)
        reject(xhr)

  _open_new_submission: (options) ->
    if @_new_submission_requests[options.selector]?
      return @_new_submission_requests[options.selector]

    options.hidden ?= false
    options.display ?= "modal"

    url = Routes.new_submission_path(
      @form_permalink,
      display: options.display,
      selectors: App.params().selectors
    )

    request = @_load_submission(url, options)
    request.then (submission) =>
      delete @_new_submission_requests[options.selector]
      return submission
    request.catch (error) =>
      delete @_new_submission_requests[options.selector]
      throw error

    @_new_submission_requests[options.selector] = request

    request

  _open_existing_submission: (permalink, options) ->
    @_deferred_loaders ?= {}

    options.hidden ?= false
    options.display ?= "modal"

    if @_deferred_loaders[permalink]?
      @_deferred_loaders[permalink].then (submission) =>
        submission.modal.show() unless options.hidden
        return submission

      return @_deferred_loaders[permalink]

    @_deferred_loaders[permalink] = new Promise (resolve, reject) =>
      local_submission = @_find_by_parameter("submission_permalink", permalink)
      return resolve(local_submission) if local_submission?

      url = Routes.edit_submission_path(
        @form_permalink,
        permalink,
        display: options.display,
        selectors: App.params().selectors
      )

      @_load_submission(url, options)
      .then (submission) -> resolve(submission)
      .catch (error) =>
        reject(error)
        @_deferred_loaders[permalink] = undefined

    @_deferred_loaders[permalink]

  # [Internal] Opens a submission form. Wrapper for load_submission to make methods like create() maintainable
  _open_submission: (event, permalink, options = {}) ->
    link = element_from_object(event)

    if link? || (options.hidden != true && options.page_loader != false)
      add_loader link

    if permalink?
      request = @_open_existing_submission(permalink, options)
    else
      request = @_open_new_submission(options)

    request.then (submission) ->
      remove_loader(link)

      if options.focus != false && !App.is_mobile() && !App.is_iframe
        submission.focus()

      return submission
    .catch (error) ->
      remove_loader(link)
      throw error

  # [Internal] Add the submission to the internal storage array
  _add: (submission) =>
    @submission_data.push(submission)

  form: -> App.Models.Form.new(@form_permalink)

  query: (conditions = [], options = {}) ->
    new App.Schema.SubmissionPromise (resolve, reject) =>
      @form().schema().then (form) =>
        # Optional simple syntax { field_name: value }
        if App.Helpers.Objects.isPlainObject(conditions)
          _conditions = conditions
          conditions = []

          for field, value of _conditions
            conditions.push({
              type: "condition",
              field: field,
              operator: "==",
              value: value
            })

        request_data = {}

        request_data.authenticity_token = current_user.authenticity_token()

        if options.order?
          request_data.order = options.order
          delete options.order

        if options.length? || options.limit?
          request_data.length = options.length || options.limit

          delete options.length
          delete options.limit

        if options.page?
          request_data.page = options.page
          delete options.page

        form_query = form.generate_query(conditions, options)
        request_data.where = form_query.serialize().conditions

        @_query_request(request_data)
        .then resolve
        .catch reject

  _query_request: (request_data) ->
    request_data.authenticity_token = current_user.authenticity_token()

    new App.Schema.SubmissionPromise (resolve, reject) =>
      $.ajax
        url: "/api/rest/v1/forms/#{@form_permalink}/responses"
        type: "POST"
        data: request_data
      .done (response) =>
        resolve(App.Schema.SubmissionCollection.from_response(
          @form_permalink, response, request_data
        ))
      .fail (response) ->
        reject(response.responseJSON)

  submission_queried: (permalink) ->
    App.Models.Submission._queried_models ?= {}
    App.Models.Submission._queried_models[permalink]

  query_new: ->
    new Promise (resolve, reject) =>
      $.ajax
        url: "/api/rest/v1/forms/#{@form_permalink}/responses/new"
      .done (response) =>
        resolve(new App.Schema.Submission(@form_permalink, response))
      .fail (response) ->
        reject(response.responseJSON)

  query_all: (options = {}) ->
    @query([], options)

  query_by_permalink: (permalink) ->
    return @query_by_permalinks(permalink) if Array.isArray(permalink)

    App.Models.Submission._queried_models ?= {}

    if App.Models.Submission._queried_models[permalink]?
      return Promise.resolve(App.Models.Submission._queried_models[permalink])

    new Promise (resolve, reject) =>
      $.ajax
        url: "/api/rest/v1/forms/#{@form_permalink}/responses/#{permalink}"
      .done (response) =>
        submission = new App.Schema.Submission(@form_permalink, response)

        App.Models.Submission._queried_models[permalink] = submission

        resolve(submission)
      .fail (response) ->
        reject(response.responseJSON)

  # Returns a submission data array from an array of permalinks, looking for any
  # locally-cached submissions first.
  query_by_permalinks: (permalinks) ->
    permalinks = [permalinks] unless Array.isArray(permalinks)

    App.Models.Submission._queried_models ?= {}

    query_permalinks = []

    for permalink in permalinks
      continue if App.Models.Submission._queried_models[permalink]?
      continue unless App.Models.Submission.valid_permalink(permalink)

      query_permalinks.push(permalink)

    new App.Schema.SubmissionPromise (resolve, reject) =>
      if query_permalinks.length > 0
        promise = @query({ permalink: query_permalinks })
      else
        promise = Promise.resolve()

      promise.then (submissions) =>
        if submissions?
          for submission in submissions.all()
            App.Models.Submission._queried_models[submission.permalink()] = submission

        output = new App.Schema.SubmissionCollection(@form_permalink)

        for permalink in permalinks
          continue unless App.Models.Submission.valid_permalink(permalink)

          if App.Models.Submission._queried_models[permalink]?
            output.push App.Models.Submission._queried_models[permalink]

        resolve(output)

  # Return the full query page that a submission belongs to.
  # Useful for finding ordered submission neighbors.
  query_seek: (permalink, options = {}) ->
    if permalink instanceof App.Models.Submission.Object || permalink instanceof App.Schema.Submission
      permalink = permalink.permalink()

    new App.Schema.SubmissionPromise (resolve, reject) =>
      options.length ?= 1000

      evaluate_seek_loop = (page) ->
        return resolve(page) if page.contains(permalink)
        return reject() if page.is_last_page()

        page.next_page().then(evaluate_seek_loop)

      @query_all(options).then(evaluate_seek_loop)

  count: ->
    new Promise (resolve, reject) =>
      @form().schema().then (form) =>
        $.ajax
          url: "/api/rest/v1/forms/#{@form_permalink}/responses/count"
        .done (response) -> resolve(response.count)
        .fail (response) -> reject(response.responseJSON)

  query_or_initialize: (conditions = {}) ->
    new Promise (resolve, reject) =>
      if !App.Helpers.Objects.isPlainObject(conditions)
        return reject "query_or_initialize only accepts simple { field_name: value } conditions"

      @query(conditions).then (responses) =>
        response = responses[0]

        return resolve(response) if response?

        @query_new().then (response) =>
          for name, value of conditions
            response.field(name, value)

          resolve(response)
        .catch reject

  query_or_create: (conditions = {}) ->
    new Promise (resolve, reject) =>
      @query_or_initialize(conditions).then (response) =>
        if response.persisted()
          return resolve(response)

        response.save()
        .then resolve
        .catch reject
      .catch reject

  new_submission: (options = {}) ->
    for submission in @submission_data
      if submission.new_submission_modal() && !submission.options.headless
        if !options.selector? || submission.options.selector == options.selector
          return submission

    undefined

  # Opens a submission from the server. If no permalink is given, opens a new submission.
  open: (permalink, event_or_options, display = "modal") ->
    args = App.Helpers.Arguments.overload(
      event_or_options, [
        [["event", Event]],
        [["options", Object]]
      ]
    )

    event = args.event
    options = args.options || {}

    args.options ?= {}
    args.options.hidden ?= true
    args.options.display ?= display

    @_open_submission(event, permalink, options)

  # Shows an initialized new submission if one exists, otherwise loads one from the server.
  # This allows users to click "New Response", exit the modal, and click it again to resume.
  open_new: (event_or_options, options) ->
    args = App.Helpers.Arguments.overload(
      event_or_options, options, [
        [["event", Event], ["options", Object]],
        [["options", Object]]
      ]
    )

    event = args.event
    options = args.options || {}

    new_submission = @new_submission(options)

    if new_submission?
      new_submission.modal.show()
      return Promise.resolve(new_submission)

    if @_new_submission_requests[options.selector]?
      @_new_submission_requests[options.selector].then (submission) -> submission.show()
      return @_new_submission_requests[options.selector]

    @_open_submission(event, undefined, options)

  # Loads a new submission from the server.
  new: (event_or_options, options) ->
    args = App.Helpers.Arguments.overload(
      event_or_options, options, [
        [["event", Event], ["options", Object]],
        [["options", Object]]
      ]
    )

    args.options ?= {}
    args.options.hidden ?= true

    @_open_submission(args.event, undefined, args.options)

  # Loads an unsaved submission, or a new submission from the server.
  new_or_unsaved: (event_or_options, options = {}) ->
    @open_new(event_or_options, options)

  # Opens a submission from the server in the background, with no visible modal
  load: (permalink, event_or_options, options) ->
    args = App.Helpers.Arguments.overload(
      event_or_options, options, [
        [["event", Event], ["options", Object]],
        [["options", Object]]
      ]
    )

    args.options ?= {}
    args.options.hidden ?= true

    @_open_submission(args.event, permalink, args.options)

  # Creates a submission based on an existing element
  initialize_element: (element, options = {}) ->
    new App.Models.Submission.Object(element, @form_permalink, options)

  # Creates a submission based on a HTML string
  initialize_html: (html, options = {}) ->
    options.hidden ?= false

    # Rendering and aligning a new modal has to be be particularly fast.
    # jQuery solution showed an un-refreshed form for several hundred ms as it loaded in.
    element = document.createElement("DIV")
    element.innerHTML = html.trim()

    element = element.firstChild
    document.getElementById("modals-container").appendChild(element)

    new_submission = new App.Models.Submission.Object(element, @form_permalink, options)
    new_submission.modal.toggle(!options.hidden) if new_submission.modal

    App.Helpers.Elements.reflow_submission(element)

    new_submission

  # Fetch a submission from the server by permalink
  # TODO: Deprecate this. Find means local find everywhere else. Use load.
  find: (permalink, options = {}) -> @load(permalink, options)

  # Find an initialized submission by index, permalink, or object
  find_locally: (submission) -> @_value_to_submission(submission)
  find_locally_by_instance_id: (value) -> @_find_by_parameter("instance_id", value)
  find_new: -> @_value_to_submission(undefined)

  first: -> @submission_data[0]
  second: -> @submission_data[1]
  third: -> @submission_data[2]
  last: -> @submission_data[@submission_data.length - 1]
  all: -> @submission_data

  update_field: (submission_permalink, field_name, value) ->
    $.ajax
      url: Routes.update_submission_field_path(@form_permalink, submission_permalink, field_name)
      method: "PATCH"
      data: {
        authenticity_token: current_user.authenticity_token()
        value: value
      }
    .done (response) =>
      PubSub.publish("submission.updated.#{@form_permalink}")
      PubSub.publish("submission.submitted_data.#{@form_permalink}.#{submission_permalink}", response.submission)

      # Apply the updated data to the submission's value if it has been instantiated.
      submission = @find_locally(submission_permalink)
      if submission?
        field = submission.fields.find_by_variable_name(field_name)
        field.value(value) if field?
    .fail (xhr) =>
      # If the update request fails, load the submission to display the errors
      @load(submission_permalink).then (submission) =>
        field = submission.fields.find_by_variable_name(field_name)
        field.value(value) if field?

        submission.fields._load_errors(xhr.responseJSON.errors)
        submission.show().then => submission.focus_on_errors()

  # Remove a submission by permalink, index, or reference
  remove: (submission) ->
    submission = @find_locally(submission)
    return false unless submission?

    index = @submission_data.indexOf(submission)

    @submission_data[index]._remove_element()
    delete @submission_data[index]

    PubSub.publish("submission.removed.#{@form_permalink}")

    true
