
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.AttachmentTable ?= {}
window.App.Elements.AttachmentTable._models ?= {}

class window.App.Elements.AttachmentTable.Table
  constructor: (element) ->
    if App.Helpers.Elements.is_jquery(element)
      @element = element[0]
      @$_element = element
    else
      @element = element
      @$_element = $(element)

    @version = 2

    @element.classList.add("app_attachment_table")

    @load_elements()
    @generate_elements()

    App.Elements.AttachmentTable._models[@table_parent] = @

    @instance_id = App.Helpers.Generators.uuid()

    @default_order = [["name", "asc"]]

    @_columns_hash = {}
    @_columns_array = []

    for element in @table_head_element.querySelectorAll("th:not(.fake-cell)")
      model = new App.Elements.AttachmentTable.Column(@, element)

      @_columns_hash[model.column_name] = model
      @_columns_array.push(model)

    @_rows_array = []
    @_rows_hash = {}

    @callbacks = new CallbackManager(@)

    @params = new App.Elements.AttachmentTable.Helpers.Params(@)
    @routes = new App.Elements.AttachmentTable.Helpers.Routing(@)
    @translations = new App.Elements.AttachmentTable.Helpers.Translations(@)
    @selections = new App.Elements.AttachmentTable.Helpers.Selections(@)

    @initialize_default_ordering()

    @$_element.on "click", "tbody tr:not(.empty_table_row)", (event) =>
      tag = event.target.tagName.toUpperCase()
      return if tag == "INPUT" || tag == "LABEL" || tag == "A"

      event.preventDefault()
      event.stopPropagation()

      model = @_rows_hash[event.currentTarget.dataset.permalink]
      model.open() if model?

    @$_element.on "click", ".row_select_td", (e) => @toggle_row(e)
    @$_element.on "change", ".row_select", (e) => @toggle_row(e)
    @$_element.on "change", ".row_select_all", (e) => @selections.toggle_all(e.currentTarget.checked)

    @$_element.on "mousedown", "tr", (e) => @preload_row(e)
    @$_element.on "mouseover", "tr", (e) => @preload_row(e)

    for column in @columns()
      column.initialize_sorting()

    PubSub.subscribe("file.uploaded.#{@params.folder_or_home()}", => @refresh())

    App.Elements.AttachmentTable.Rows.File.generate_context_menu(@)
    App.Elements.AttachmentTable.Rows.Folder.generate_context_menu(@)
    App.Elements.AttachmentTable.Rows.Post.generate_context_menu(@)

    @table_body_element.addEventListener "context_menu.hide", (event) =>
      upload_button = App.menu().element.querySelector(".file_upload_button")
      upload_button.dataset.replace = "" if upload_button?

    @on "refresh", (response) =>
      @page_badge(@params.page(), @params.length(), response.recordsTotal)

    @refresh(true)

  on: (event, callback) -> @callbacks.on(event, callback)

  initialize_default_ordering: ->
    if @default_order? && @default_order.length > 0
      for order in @default_order
        column = @column(order[0])
        column.order(order[1], false) if column?

  increment_draw_count: ->
    @draw_count ?= 0
    @draw_count += 1

  refresh: (initial = false) ->
    $.ajax
      url: @routes.data()
      data: @params.serialize()
      type: "POST"
    .done (response) =>
      @load_data(response)
      PubSub.publish("attachment_table.ready", { table: @ }) if initial
      @element.dispatchEvent(new CustomEvent("table.refresh", { detail: response }))
      @callbacks.trigger("refresh", response)

  order_clear: (refresh = true) ->
    @_ordering = []

    for column in @columns()
      column.order_clear(false)

    @refresh() if refresh

  column: (column) ->
    return column if column instanceof App.Elements.AttachmentTable.Column

    column = column[0] if App.Helpers.Elements.is_jquery(column)
    column = column.dataset.columnName if column.nodeType
    return unless column?

    @_columns_hash[column]

  row: (row) ->
    return row if row instanceof App.Elements.AttachmentTable.Rows.File
    return row if row instanceof App.Elements.AttachmentTable.Rows.Folder
    return row if row instanceof App.Elements.AttachmentTable.Rows.Post

    row = row[0] if App.Helpers.Elements.is_jquery(row)
    row = App.Helpers.Elements.closest(row, "tr")
    row = row.dataset.permalink if row.nodeType
    return unless row?

    @_rows_hash[row]

  columns: -> @_columns_array

  has_permission: (permission) ->
    @element.getAttribute("data-permission-#{permission}") == "true"

  preload_row: (element) ->
    return unless App.windowing_enabled()

    if App.Helpers.Elements.is_jquery(element)
      element = element[0]

    if element.target? && element.currentTarget?
      element = element.currentTarget

    return unless element?

    row = @_rows_hash[element.dataset.permalink]

    return unless row? && row instanceof App.Elements.AttachmentTable.Rows.Folder

    App.window_manager.load row.link() if row?

  toggle_row: (value, status) ->
    if App.Helpers.Objects.isEvent(value)
      event = value
      value = value.currentTarget

    if event?
      if event.target != event.currentTarget
        return unless event.target.classList.contains("td-content")

      event.stopPropagation()

    tag = value.tagName

    if tag == "INPUT"
      checkbox = value
      checkbox_checked = checkbox.checked
    else
      checkbox = value.querySelector(".row_select")
      return unless checkbox?

      checkbox_checked = !checkbox.checked

    if checkbox_checked
      @selections.select(checkbox.dataset.permalink)
    else
      @selections.deselect(checkbox.dataset.permalink)

  load_empty_table_notice: ->
    row = document.createElement("TR")
    row.className = "empty_table_row"

    cell = document.createElement("TD")
    cell.setAttribute("colspan", @columns().length)
    cell.innerHTML = "<div class='td-content'>#{@translations.empty()}</div>"

    row.appendChild(cell)
    @table_body_element.appendChild(row)

  load_row: (row_data, columns, model_type) ->
    permalink = row_data.permalink

    row = document.createElement("TR")
    row.setAttribute("data-permalink", permalink)
    row.className = "attachment_table_#{model_type.toLowerCase()}_row"

    for column in columns
      column_name = column.column_name

      cell = document.createElement("TD")
      cell.className = "row_select_td" if column_name == "icon"
      cell.className += " mobile_hidden" if column.mobile_hidden

      cell_content = document.createElement("DIV")
      cell_content.className = "td-content"

      if column_name == "icon"
        cell_content.classList.add("thin")

      if row_data[column_name]?
        cell_content.innerHTML = row_data[column_name]

      cell.appendChild(cell_content)
      row.appendChild(cell)

    @table_body_element.appendChild(row)

    if @_rows_hash[permalink]?
      @_rows_hash[permalink].set_element(row)
    else
      @_rows_hash[permalink] = new App.Elements.AttachmentTable.Rows[model_type](@, row)

    @_rows_hash[permalink]._permissions = row_data.permissions

    @_rows_array.push(@_rows_hash[permalink])

  load_data: (response) ->
    delete @_rows_array
    @_rows_array = []

    @table_body_element.innerHTML = ""

    columns = @columns()

    if !response.folders? || response.folders.length == 0
      if !response.posts? || response.posts.length == 0
        if !response.files? || response.files.length == 0
          return @load_empty_table_notice()

    for row_data in response.folders
      @load_row(row_data, columns, "Folder")

    for row_data in response.posts
      @load_row(row_data, columns, "Post")

    for row_data in response.files
      @load_row(row_data, columns, "File")

  page_badge: (page, page_length, total_records) ->
    page_start = page * page_length
    page_end = (page + 1) * page_length
    page_end = total_records if page_end > total_records

    @page_count_element.innerHTML = @translations.page_count(page_start, page_end, total_records)

    if @previous_page_buttons?
      for button in @previous_page_buttons
        button.classList.toggle("disabled", page_start == 0)

    if @next_page_buttons?
      for button in @next_page_buttons
        button.classList.toggle("disabled", page_end == total_records)

    undefined

  load_elements: ->
    @header_element = @element.querySelector(".table_header")
    @body_element = @element.querySelector(".table_body")

    @body_element.innerHTML = "<div class='table_wrapper attachment_table_wrapper'>#{@body_element.innerHTML}</div>"
    @table_wrapper_element = @element.querySelector(".table_wrapper")

    @table_element = @element.querySelector("table")

    if !@table_element.id? || @table_element.id == ""
      @table_element.id = "table_#{App.Helpers.Generators.uuid()}_element"

    @table_head_element = @table_element.querySelector("thead")
    @table_body_element = @table_element.querySelector("tbody")

    if !@table_head_element?
      @table_head_element = document.createElement("THEAD")
      @table_element.appendChild(@table_head_element)

    if !@table_body_element?
      @table_body_element = document.createElement("TBODY")
      @table_element.appendChild(@table_body_element)

    @table_name = @element.querySelector(".table_name").dataset.name
    @table_parent = @element.dataset.folder || "home"

    @element.classList.add("initialized")

    @

  generate_elements: ->
    @generate_header()
    @generate_footer()

    @

  generate_header: ->
    table_options = document.createElement("DIV")
    table_options.className = "table_options"

    page_options = Array.from(@generate_pagination().children)

    for option in page_options
      option_wrapper = document.createElement("DIV")
      option_wrapper.className = "option"
      option_wrapper.appendChild(option)

      table_options.appendChild(option_wrapper)

    name_element = @element.querySelector(".table_name")
    @header_element.insertBefore table_options, name_element.nextSibling

  generate_footer: ->
    footer = document.createElement("DIV")
    footer.className = "table_footer"

    options = document.createElement("DIV")
    options.className = "options_container"

    pages = document.createElement("DIV")
    pages.className = "table_pages"

    options.appendChild(pages)
    options.appendChild @generate_pagination()

    footer.appendChild(options)

    @table_footer_element = footer

    @element.appendChild(@table_footer_element)

    @page_count_element = @table_footer_element.querySelector(".table_pages")

    @table_footer_element

  generate_pagination: ->
    pagination = document.createElement("DIV")
    pagination.className = "table_pagination"

    previous_page = document.createElement("A")
    previous_page.className = "paginate_button previous"
    previous_page.innerHTML = "<i class='icon icon-left-open'></i>"
    pagination.appendChild(previous_page)

    @previous_page_buttons ?= []
    @previous_page_buttons.push(previous_page)

    next_page = document.createElement("A")
    next_page.className = "paginate_button next"
    next_page.innerHTML = "<i class='icon icon-right-open'></i>"
    pagination.appendChild(next_page)

    @next_page_buttons ?= []
    @next_page_buttons.push(next_page)

    pagination