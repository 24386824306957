
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Callbacks ?= {}

window.App.Helpers.Callbacks.new = (object) ->
  new App.Helpers.Callbacks.Object(object)

window.App.Helpers.Callbacks.Types = {
  NORMAL: 0,
  SYNCHRONOUS: 1,
  PROMISE: 2
}

class window.App.Helpers.Callbacks.Object
  constructor: (@object) ->
    @callbacks = {}

  onetime_event: ->
    @_onetime_events ?= []
    @_onetime_event_values ?= {}

    for type in arguments
      for name in App.Helpers.Events.generate_scopes(type)
        @_onetime_events.push(name) if @_onetime_events.indexOf(name) == -1

    undefined

  is_onetime_event: (type) ->
    return false unless @_onetime_events?
    @_onetime_events.indexOf(type) != -1

  onetime_event_triggered: (type) ->
    return false unless @_onetime_event_values?
    @_onetime_event_values[type]?

  on: (type, method) ->
    @add(type, method)

  add: (type, method) ->
    if @is_onetime_event(type) && @onetime_event_triggered(type)
      return @run_method(method, @_onetime_event_values[type])

    for name in App.Helpers.Events.split_events(type)
      @callbacks[name] ||= []
      @callbacks[name].push(method)

    undefined

  remove: (type, method) ->
    for name in App.Helpers.Events.split_events(type)
      if method?
        @callbacks[name] ||= []
        @callbacks[name].splice(@callbacks[name].indexOf(method))
      else
        delete @callbacks[name]

    undefined

  skip: ->
    @_ignored_events ?= []

    for type in arguments
      for name in App.Helpers.Events.generate_scopes(type)
        @_onetime_events.push(name) if @_onetime_events.indexOf(name) == -1

    undefined

  is_skipped_event: (type) ->
    return false unless @_ignored_events?
    @_ignored_events.indexOf(type) != -1

  run_method: (method, args) ->
    if @object? && Function.prototype.apply?
      return method.apply(@object, args)
    else
      return method.apply(window, args)

  run_method_as_promise: (method, args) ->
    value = @run_method(method, args)

    if value?
      return value if App.Helpers.Objects.isPromise(value)

      if value != false
        return Promise.resolve(value)
      else
        return Promise.reject(value)

    Promise.resolve(value)

  _trigger: (type, return_type = 0, args) ->
    scopes = App.Helpers.Events.generate_scopes(type)

    for scope in scopes
      if @is_onetime_event(scope)
        @_onetime_event_values ?= {}
        @_onetime_event_values[scope] = args

    switch return_type
      when App.Helpers.Callbacks.Types.NORMAL
        for name, callbacks of @callbacks
          if App.Helpers.Events.matches_scope(name, scopes)
            continue if @is_skipped_event(name)
            @run_method(method, args) for method in callbacks
      when App.Helpers.Callbacks.Types.SYNCHRONOUS
        promised_callbacks = []

        for name, callbacks of @callbacks
          if App.Helpers.Events.matches_scope(name, scopes)
            if @is_skipped_event(scope)
              return Promise.reject("Event `#{name}` has been skipped by a script.")

            for method in callbacks
              promised_callbacks.push(@run_method_as_promise(method, args))
      when App.Helpers.Callbacks.Types.PROMISE
        promised_callbacks = []

        for name, callbacks of @callbacks
          if App.Helpers.Events.matches_scope(name, scopes)
            for method in callbacks
              promised_callbacks.push(@run_method_as_promise(method, args))

    return if return_type == App.Helpers.Callbacks.Types.NORMAL

    return Promise.resolve() if promised_callbacks.length == 0

    Promise.all(promised_callbacks)

  # Generates deferred objects based on callback method return values for
  # models that expect a response when callback handlers have completed.
  # Eg. submission.before_save
  trigger_synchronous: (type, args...) ->
    @_trigger(type, App.Helpers.Callbacks.Types.SYNCHRONOUS, args)

  # Generates deferred objects based on callback method return values,
  # but does not execute the callbacks synchronously.
  trigger_promise: (type, args...) ->
    @_trigger(type, App.Helpers.Callbacks.Types.PROMISE, args)

  trigger: (type, args...) ->
    @_trigger(type, App.Helpers.Callbacks.Types.NORMAL, args)

  callback: (type, method) ->
    @callbacks[type] ||= []

    if method?
      @add(type, method)
    else
      return {
        methods: => @callbacks[type],
        remove: (method) => @remove(type, method),
        add: (method) => @add(type, method)
      }

window.CallbackManager = App.Helpers.Callbacks.Object
