
# Prevent the click event from propagating to the TH element and triggering a reorder.
App.Elements.ResponseTable.column_grip_click = (event) ->
  event.preventDefault()
  event.stopPropagation()
  return false

App.Elements.ResponseTable.column_resize_start = (column) ->
  (event) ->
    App.Elements.ResponseTable.drag_count = 0
    App.Elements.ResponseTable.resizing_column = column
    App.Elements.ResponseTable.resizing_offset = event.pageX

    column.table.table_element.style.minWidth = column.table.table_element.offsetWidth + "px"

    document.addEventListener("mousemove", App.Elements.ResponseTable.column_resize_drag, false)
    document.addEventListener("mouseup", App.Elements.ResponseTable.column_resize_end, false)

    return false

App.Elements.ResponseTable.column_resize_drag = (event) ->
  App.Elements.ResponseTable.drag_count += 1

  x_position = if event.touches? then event.touches[0].pageX else event.pageX

  drag_width_change = x_position - App.Elements.ResponseTable.resizing_offset
  App.Elements.ResponseTable.resizing_offset = x_position

  column = App.Elements.ResponseTable.resizing_column
  return unless column?

  old_column_real_width = column.element.offsetWidth
  old_column_min_width = parseInt(column.element.style.minWidth)

  table = column.table.table_element
  table_wrapper = column.table.table_wrapper_element
  fake_column = column.table.fake_cell_element

  App.Elements.ResponseTable.column_resize_set_width(
    column,
    old_column_min_width + drag_width_change
  )

  real_width_change = column.element.offsetWidth - old_column_real_width

  table.style.minWidth = parseInt(table.style.minWidth) + real_width_change + "px"

  if fake_column?
    real_table_width = table.offsetWidth - fake_column.offsetWidth

    width_difference = table_wrapper.offsetWidth - real_table_width

    if width_difference > 0
      fake_column_old_width = fake_column.offsetWidth
      fake_column.style.maxWidth = width_difference + "px"
      fake_column.style.minWidth = width_difference + "px"
      fake_column.style.width = width_difference + "px"
      table.style.minWidth = table.offsetWidth + fake_column.offsetWidth - fake_column_old_width + "px"
    else
      fake_column.style.maxWidth = "0px"
      fake_column.style.minWidth = "0px"
      fake_column.style.width = "0px"

App.Elements.ResponseTable.column_resize_end = (event) ->
  event.preventDefault()
  event.stopPropagation()

  column = App.Elements.ResponseTable.resizing_column
  return unless column?

  column.resize(column.element.offsetWidth)
  App.Elements.ResponseTable.resizing_column = undefined
  App.Elements.ResponseTable.resized_column_in_last_second = true

  document.removeEventListener("mousemove", App.Elements.ResponseTable.column_resize_drag, false)
  document.removeEventListener("mouseup", App.Elements.ResponseTable.column_resize_end, false)

  column.table.apply_resizing()

  setTimeout((-> App.Elements.ResponseTable.resized_column_in_last_second = false), 1000)

  return false

App.Elements.ResponseTable.column_resize_set_width = (model, width) ->
  width ?= model.column_width || model.element.dataset.columnWidth
  width = parseInt(width)

  model.column_width = width

  # TODO: This is blank for some models, diagnose this issue more deeply.
  if model.col_element?
    model.col_element.style.width = width + "px"
    model.col_element.style.minWidth = width + "px"

  for element in model.all_headers
    element.dataset.columnWidth = width
    element.style.maxWidth = width + "px"
    element.style.minWidth = width + "px"
    element.style.width = width + "px"

  model.table.apply_real_widths_to_sticky_header(model)

  model
