
$(document).on "change", ".SUBMISSION .attachment-input", (event) ->
  event.preventDefault()

  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)

  field.helpers.collect_new_files(field_input)

$(document).on "click", ".SUBMISSION .DELETE_UPLOAD_FILE", (event) ->
  event.preventDefault()
  event.stopPropagation()

  field_input = event.currentTarget
  field_element = App.Helpers.Elements.closest(field_input, ".FIELD_CONTAINER")

  return unless field_element?
  field_permalink = field_element.dataset.id

  element = App.Helpers.Elements.closest(field_element, ".SUBMISSION")
  submission = App.Models.Submission.find_locally_by_element(element)

  field = submission.fields.find_by_permalink(field_permalink)
  button = App.Helpers.Elements.closest(field_input, ".UPLOAD_FILE")

  field.helpers.delete_file(button.dataset.index)
