
$(document).on "submit", ".SUBMISSION form", (event) ->
  form_element = event.currentTarget
  return if form_element.dataset.ajaxSubmit == "false"

  element = App.Helpers.Elements.closest(form_element, ".SUBMISSION")
  model = App.Models.Submission.find_locally_by_element(element)

  return unless model?

  model.submit(event)

$(document).on "resize", ".SUBMISSION form", (event) ->
  form_element = event.currentTarget
  element = App.Helpers.Elements.closest(form_element, ".SUBMISSION")
  model = App.Models.Submission.find_locally_by_element(element)

  return unless model?

  model.refresh()
