
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.DateTime
  constructor: (@field) ->
    undefined

  _value: (field_value) ->
    return {} unless field_value?

    output = {}

    if field_value.year? && field_value.year.length > 0
      output.year = parseInt(field_value.year)

    if field_value.month? && field_value.month.length > 0
      output.month = parseInt(field_value.month)

    if field_value.day? && field_value.day.length > 0
      output.day = parseInt(field_value.day)

    if field_value.hour? && field_value.hour.length > 0
      output.hour = parseInt(field_value.hour)

    if field_value.minute? && field_value.minute.length > 0
      output.minute = parseInt(field_value.minute)

    output

  value_date: ->
    datetime = @field.value()
    default_datetime = new Date()

    if !datetime.year? || datetime.year == ""
      datetime.year = default_datetime.getFullYear()

    if !datetime.month? || datetime.month == ""
      datetime.month = default_datetime.getMonth() + 1

    if !datetime.day? || datetime.day == ""
      datetime.day = default_datetime.getDate()

    if !datetime.hour? || datetime.hour == ""
      datetime.hour = default_datetime.getHours()

    if !datetime.minute? || datetime.minute == ""
      datetime.minute = default_datetime.getMinutes()

    new Date(datetime.year, datetime.month - 1, datetime.day, datetime.hour, datetime.minute)

  date: ->
    @value_date()

  supports_dates: -> @field._element.dataset.date == "true"
  supports_times: -> @field._element.dataset.time == "true"
  supports_minutes: -> @field._element.dataset.minute == "true"
