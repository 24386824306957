
window.App ?= {}
window.App.Models ?= {}
window.App.Models.MailMergeHelpers ?= {}

class window.App.Models.MailMergeHelpers.Routing
  constructor: (@mail_merge) ->

  settings: ->
    if @mail_merge.persisted()
      Routes.edit_mail_merge_path(
        @mail_merge.form().permalink()
        @mail_merge.permalink()
      )
    else
      Routes.new_mail_merge_path(
        @mail_merge.form().permalink()
      )

  save: ->
    if @mail_merge.persisted()
      Routes.update_mail_merge_path(
        @mail_merge.form().permalink()
        @mail_merge.permalink()
      )
    else
      Routes.create_mail_merge_path(
        @mail_merge.form().permalink()
      )

  save_method: ->
    if @mail_merge.persisted()
      "PATCH"
    else
      "POST"

  send_submissions: ->
    Routes.send_submissions_mail_merge_path(
      @mail_merge.form().permalink()
    )

  send_report: (report_permalink) ->
    Routes.send_report_mail_merge_path(
      @mail_merge.form().permalink()
      report_permalink
    )

  send_form: ->
    Routes.send_form_mail_merge_path(
      @mail_merge.form().permalink()
    )

  destroy: ->
    return unless @mail_merge.persisted()

    Routes.destroy_mail_merge_path(
      @mail_merge.form().permalink(),
      @mail_merge.permalink()
    )
