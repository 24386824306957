
$ ->
  if current_user? && current_user.signed_in()
    Hotkey.bind ["F"],
      if: -> !App.is_mobile()
      do: -> App.fullscreen(!App.is_fullscreen())

  Hotkey.bind ["CTRL", "F"],
    if: (->
      menu = App.menu()
      return false unless menu?
      menu_element = menu.element
      return false unless menu_element?

      menu_element.querySelector(".search_input, #table-search")?
    )
    do: (->
      menu_element = App.menu().element
      search_options = menu_element.querySelectorAll(".search_option")
      button = undefined

      for element in search_options
        continue unless App.Helpers.Elements.visible(element)
        button ?= element.querySelector(".search_button")

      if button?
        button.dispatchEvent(App.Helpers.Events.new("click", { bubbles: true }))
        input = button.querySelector(".search_input")
      else
        input = menu_element.querySelector("#table-search")

      return unless input?
      input.focus()
    )

  if current_user? && current_user.config("modal_toggle_hotkeys")
    Hotkey.bind ["CTRL", "ALT", "UP"],
      do: (->
        sidebar = App.sidebar().element
        return unless sidebar?

        sidebar_options = sidebar.querySelectorAll(".sidebar-option")
        selected_option = sidebar.querySelector("a.sidebar-option.selected")
        visible_options = []

        for option in sidebar_options
          continue unless App.Helpers.Elements.visible(option)
          visible_options.push(option)

        index = visible_options.indexOf(selected_option) - 1
        index = visible_options.length - 1 if index < 0

        next_option = visible_options[index]
        next_option.dispatchEvent("click")
      ),
      timeout: 100

    Hotkey.bind ["CTRL", "ALT", "DOWN"],
      do: (->
        sidebar = App.sidebar().element
        return unless sidebar?

        sidebar_options = sidebar.querySelectorAll(".sidebar-option")
        selected_option = sidebar.querySelector("a.sidebar-option.selected")
        visible_options = []

        for option in sidebar_options
          continue unless App.Helpers.Elements.visible(option)
          visible_options.push(option)

        index = visible_options.indexOf(selected_option) + 1
        index = 0 if index >= visible_options.length

        next_option = visible_options[index]
        next_option.dispatchEvent("click")
      ),
      timeout: 100

    Hotkey.bind ["?"], do: ->
      if !App.Interface.hotkey_modal?
        hotkeys = [{
          "title": "Responses",
          "items": [
            {
              "title": "Search Responses",
              "keys": ["CTRL", "F"]
            },
            {
              "title": "Open New Response",
              "keys": ["CTRL", "ALT", "N"]
            },
            {
              "title": "Open New Response (Alternate)",
              "keys": ["CTRL", "M"]
            },
            {
              "title": "Save Response",
              "keys": ["CTRL", "S"]
            }
          ]
        }, {
          "title": "Sidebar",
          "items": [
            {
              "title": "Open Previous Page",
              "keys": ["CTRL", "ALT", "↑"]
            },
            {
              "title": "Open Next Page",
              "keys": ["CTRL", "ALT", "↓"]
            },
            {
              "title": "Fullscreen",
              "keys": ["F"]
            }
          ]
        }, {
          "title": "Modals",
          "items": [
            {
              "title": "Open Previous Response Modal",
              "keys": ["CTRL", "←"]
            },
            {
              "title": "Open Next Response Modal",
              "keys": ["CTRL", "→"]
            },
            {
              "title": "Open Last Modal",
              "keys": ["CTRL", "↓"]
            },
            {
              "title": "Close Modal",
              "keys": ["ESC"]
            }
          ]
        }, {
          "title": "Calendars",
          "items": [
            {
              "title": "Open Previous Page",
              "keys": ["CTRL", "←"]
            },
            {
              "title": "Open Next Page",
              "keys": ["CTRL", "→"]
            }
          ]
        }, {
          "title": "Miscellaneous",
          "items": [
            {
              "title": "Command Palette",
              "keys": ["CTRL", "SHIFT", "P"]
            },
            {
              "title": "Hotkeys List",
              "keys": ["?"]
            }
          ]
        }]

        modal_content = $("<div/>")

        for category in hotkeys
          category_content = $("<div/>", {
            "html": "<h4 class='mt-1 mb-1'>#{category.title}</h4>",
            "class": "mb-4"
          })

          for combination in category.items
            combo_content = $("<span/>", {
              "style": "float: right;"
            })

            for key in combination.keys
              combo_content.append($("<span/>", {
                "class": "key", "text": key
              }))

            hotkey_content = $("<div/>", { "class": "mb-1" })
            hotkey_content.append(combo_content)
            hotkey_content.append($("<span/>", { "text": combination.title }))

            category_content.append(hotkey_content)

          modal_content.append(category_content)

        App.Interface.hotkey_modal = App.Interface.Modal.new("Hotkeys", modal_content)

      App.Interface.hotkey_modal.show()
