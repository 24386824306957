
window.App ?= {}
window.App.Query ?= {}
window.App.Query.FieldCondition ?= {}

class window.App.Query.FieldCondition.DateTime extends App.Query.FieldCondition.Base
  constructor: (@field, @_operator, @_value, @_options = {}) ->
    super(@field, @_operator, @_value, @_options)
  
  @operators: ["==", "!=", ">", ">=", "<", "<=", "?", "!?"]

  @aggregators: ["count", "maximum", "minimum"]

  @groupings: [
    "millennia", "century", "decade", "year", "quarter", "quarter_of_year",
    "month", "month_of_year", "week", "day", "day_of_year", "day_of_month", "day_of_week",
    "hour", "hour_of_day", "minute", "minute_of_hour", "second", "second_of_minute"
  ]
