import { asset_host, manifest_path } from 'generated/asset_paths'

window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Assets ?= {}

window.App.Helpers.Assets._loaded_paths = []
window.App.Helpers.Assets._promises = []

window.App.Helpers.Assets.requested = (path) ->
  App.Helpers.Assets._promises[path]?

window.App.Helpers.Assets.require = ->
  promises = []

  for path in arguments
    promises.push(App.Helpers.Assets.load(path))

  Promise.all(promises)

window.App.Helpers.Assets.load_manifest = ->
  if window.App.Helpers.Assets._manifest?
    Promise.resolve(window.App.Helpers.Assets._manifest)
  else
    new Promise (resolve, reject) ->
      $.getJSON(manifest_path)
      .done (data) ->
        window.App.Helpers.Assets._manifest = data

        resolve(data)
      .fail (xhr) ->
        reject(xhr)

window.App.Helpers.Assets.get_entrypoints = (asset_path) ->
  App.Helpers.Assets.load_manifest().then (manifest) ->
    path_elems = asset_path.split "."

    extension = path_elems.splice(path_elems.length - 1, 1)
    base_path = path_elems.join(".")

    if manifest.entrypoints?[base_path]?[extension]
      manifest.entrypoints[base_path][extension].map((x) -> asset_host + x)
    else
      [asset_path]

window.App.Helpers.Assets.load = (path) ->
  uri = App.Helpers.URIs.parse(path)
  asset_path = uri.value()
  asset_type = uri.extension()

  return App.Helpers.Assets._promises[asset_path] if App.Helpers.Assets.requested(asset_path)

  App.Helpers.Assets._promises[asset_path] = new Promise (resolve, reject) ->
    switch asset_type
      when "js", "min.js"
        $.getScript(asset_path)
        .done ->
          resolve()
        .fail (xhr) ->
          App.Helpers.Assets._promises[asset_path] = undefined

          reject(xhr)
      when "css"
        element = document.createElement("LINK")
        element.href = asset_path
        element.type = "text/css"
        element.rel = "stylesheet"
        element.media = "screen"
        document.head.appendChild(element)
        resolve()
