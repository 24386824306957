
$(document).on "click", ".form_preferences_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  form.preferences.open()
  .then -> remove_loader(button)
  .catch (error) ->
    remove_loader(button)
    throw error
