
window.App ?= {}
window.App.Tutorial ?= {}
window.App.Tutorial.Lessons ?= {}

(->
  lesson = new App.Tutorial.Lesson("introduction", "Welcome to Sonadier")

  lesson.step {
    text: "Some Body"
  }

  lesson.step {
    text: "Some Body 2",
    trigger: (resolve) -> $(document).on("click", resolve)
  }

  null
)()
