
$(document).on "click", ".app_response_table .row_select_td", (event) ->
  # Adding preventDefault to this event will also disable default behavior for any
  # clicks on the checkbox directly, which also prevents the "change" event below.
  cell = event.currentTarget
  return if cell.classList.contains("disabled")

  checkbox = cell.querySelector(".row_select")
  return if !checkbox? || checkbox.disabled

  table_container = App.Helpers.Elements.closest(cell, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  if event.shiftKey
    table_model.shift_toggle_row(event)
  else
    table_model.toggle_row(event)

$(document).on "change", ".app_response_table .row_select", (event) ->
  # Don't preventDefault this event, or checkboxes will not be correctly set.
  checkbox = event.currentTarget
  return if checkbox.classList.contains("disabled") || checkbox.disabled

  table_container = App.Helpers.Elements.closest(checkbox, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  if event.shiftKey
    table_model.shift_toggle_row(event)
  else
    table_model.toggle_row(event)

$(document).on "change", ".app_response_table .row_select_all", (event) ->
  # Don't preventDefault this event, or checkboxes will not be correctly set.
  checkbox = event.currentTarget
  return if checkbox.classList.contains("disabled") || checkbox.disabled

  table_container = App.Helpers.Elements.closest(checkbox, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  table_model.selections.toggle_all(checkbox.checked)

$(document).on "dblclick", ".app_response_table tbody td", (event) ->
  cell = event.target
  cell_tag = cell.tagName.toUpperCase()

  return unless cell_tag == "TD" || cell_tag == "TR" || cell_tag == "DIV" || cell_tag == "P"

  table_container = App.Helpers.Elements.closest(cell, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  double_click_view = App.current_user.config("responses", "views", "double_click_view")
  double_click_view = true if !double_click_view && !table_model.has_permission("update")

  if double_click_view
    double_click_permission = table_model.has_permission("read")
  else
    double_click_permission = table_model.has_permission("update")

  return unless double_click_permission

  event.preventDefault()

  cell = App.Helpers.Elements.closest(cell, "TD") if cell_tag != "TD"

  column = table_model.column(cell)
  row = App.Helpers.Elements.closest(cell, "TR")

  permalink = row.dataset.permalink

  if permalink? && double_click_view
    return App.redirect_to Routes.submission_path(table_model.form().permalink(), permalink)

  if permalink?
    request = table_model.form().submissions.load(permalink, hidden: false, focus: false)
  else
    request = table_model.form().submissions.open_new(focus: false)

  page_loader request, cursor: true

  return unless column?

  request.then (submission) ->
    field = submission.fields.find_by_permalink(column.field_permalink)
    return unless field?
    field.focus()

$(document).on "longpress", ".app_response_table tbody tr", (event) ->
  event.preventDefault()

  row = event.currentTarget

  table_container = App.Helpers.Elements.closest(row, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  table_model.toggle_row(event)

$(document).on "click", ".app_response_table tbody tr", (event) ->
  return if event.shiftKey

  row = event.currentTarget

  table_container = App.Helpers.Elements.closest(row, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model? && table_model.selections.any()

  table_model.toggle_row(event)

$(document).on "click", ".app_response_table tbody .response_expander", (event) ->
  event.preventDefault()

  button = event.currentTarget

  table_container = App.Helpers.Elements.closest(button, ".table_container")
  table_model = App.Elements.Tables.find(table_container)
  return unless table_model?

  row = App.Helpers.Elements.closest(button, "tr")
  return unless row.dataset.permalink?

  if table_model.selections.any()
    return table_model.toggle_row(row)
  else
    form_permalink = table_model.form().permalink()
    submission_permalink = row.dataset.permalink
    submission_path = Routes.submission_path(form_permalink, submission_permalink)

    if button.target? && button.target.length > 0
      App.redirect_to submission_path, target: button.target
    else
      add_loader(button)

      App.redirect_to(submission_path)
      .then -> remove_loader(button)
      .catch -> remove_loader(button)

$ ->
  listener_events = "mousedown"

  if App.current_user.config_or_true("responses", "views", "preload", "hover")
    listener_events += " mouseover"

  $(document).on listener_events, ".app_response_table tbody .response_expander", (event) ->
    button = event.currentTarget

    return if button.target == "_blank"

    table_container = App.Helpers.Elements.closest(button, ".table_container")
    table_model = App.Elements.Tables.find(table_container)
    return unless table_model?

    row = App.Helpers.Elements.closest(button, "tr")
    return unless row.dataset.permalink?

    form_permalink = table_model.form().permalink()
    submission_permalink = row.dataset.permalink
    submission_path = Routes.submission_path(form_permalink, submission_permalink)

    App.window_manager.load(submission_path)
