
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Versions ?= {}

class window.App.Helpers.Versions.VersionNumber
  constructor: (@name) ->

  array: ->
    output = []

    for string in @name.split(".")
      output.push(parseInt(string))

    while output[output.length - 1] == 0
      output.pop()

    output

  after: (other) ->
    if App.Helpers.Objects.isString(other)
      other = new App.Helpers.Versions.VersionNumber(other)

    array = @array()

    for number, i in other.array()
      return true if !array[i]? || number < array[i]
      return false if number > array[i]

    false

  before: (other) ->
    if App.Helpers.Objects.isString(other)
      other = new App.Helpers.Versions.VersionNumber(other)

    other.after(@)

  equals: (other) ->
    if App.Helpers.Objects.isString(other)
      other = new App.Helpers.Versions.VersionNumber(other)

    array = @array()

    for number, i in other.array()
      return false if !array[i]? || array[i] != number

    true

window.App.Helpers.Versions._current = new App.Helpers.Versions.VersionNumber("2")

window.App.version = ->
  App.Helpers.Versions._current
