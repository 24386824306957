
window.App ?= {}
window.App.Query ?= {}
window.App.Query.HeaderCondition ?= {}

class window.App.Query.HeaderCondition.DateTime extends App.Query.HeaderCondition.Base
  constructor: (@field, @_operator, @_value, @_options = {}) ->
    super(@field, @_operator, @_value, @_options)
  
  @operators: ["==", "!=", ">", ">=", "<", "<=", "?", "!?"]

  @aggregators: ["count", "maximum", "minimum"]
