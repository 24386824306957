
class window.PreferenceManager
  constructor: (@form_permalink) ->
    undefined

  load: (visible = false, event = undefined) ->
    return @_loader if @_loader?
    @_loader = $.Deferred()

    link = element_from_object(event)
    add_loader(link)

    $.ajax
      url: Routes.form_preferences_path(@form_permalink)
      type: "GET"
      context: @
    .done (response, status, request) =>
      remove_loader(link)
      preferences_permalink = request.getResponseHeader("X-Form-Permalink")
      @submission = App.Models.Form.new(preferences_permalink).submissions.initialize_html(response, !visible)
      @_loader.resolve(@submission)
    .fail (xhr) ->
      remove_loader(link)
      App.Errors.response_error(xhr)
      @_loader.reject(response)
      @_loader = undefined

    @_loader

  open: (event) ->
    if @loaded()
      @submission.modal.show()
      return @_loader
    else
      @load(true, event)

  loaded: ->
    @submission?
