import 'app/elements/response_table/column'
import 'app/elements/response_table/column_resizing'
import 'app/elements/response_table/row'
import 'app/elements/response_table/table'
import 'app/elements/response_table/helpers/conditions'
import 'app/elements/response_table/helpers/exporting'
import 'app/elements/response_table/helpers/params'
import 'app/elements/response_table/helpers/mass_update'
import 'app/elements/response_table/helpers/modals'
import 'app/elements/response_table/helpers/routing'
import 'app/elements/response_table/helpers/selections'
import 'app/elements/response_table/helpers/translations'



# Update resource counters (Eg. the form response counts in the sidebar) when tables are refreshed.
App.Elements.ResponseTable.Helpers.update_resource_counters = (table, total_records) ->
  if table.params.basic_form_table()
    permalink = table.form().permalink()
    counters = document.querySelectorAll(".resource_count[data-type=form][data-form='#{permalink}']")

    for counter in counters
      counter.textContent = total_records

  else if table.params.basic_report_table()
    permalink = table.params.report()
    counters = document.querySelectorAll(".resource_count[data-type=report][data-report='#{permalink}']")

    for counter in counters
      counter.textContent = total_records

  undefined

Hotkey.bind ["ESCAPE"],
  if: (=>
    return true if document.activeElement.classList.contains("table-search")
    return true if document.activeElement.classList.contains("search_input_element")
    false
  )
  do: (=>
    search = document.activeElement
    option = App.Helpers.Elements.closest(search, ".option")
    return unless option?

    exit = option.querySelector(".show_search_input")
    return unless exit?

    exit.dispatchEvent(App.Helpers.Events.new("click", { bubbles: true }))
  )

$(document).on "page:open", (event, context) ->
  for table in context._page_element.querySelectorAll(".table_container")
    App.tables.add(table).refresh(rate_limit: true)
