window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.TextArea
  constructor: (@field) ->
    @initialize_quill() if @quill_enabled()

    undefined

  quill_enabled: ->
    @field._element.querySelector(".FIELD_RICH_EDITOR")?

  initialize_quill: ->
    new Promise (resolve, reject) =>
      container = @field._element.querySelector(".FIELD_RICH_EDITOR")
      html_input = container.innerHTML
      container.innerHTML = ""

      App.Helpers.Assets.get_entrypoints("quill.css").then (entrypoints) =>
        App.Helpers.Assets.require(entrypoints...)

      App.Helpers.Assets.get_entrypoints("quill.js").then (entrypoints) =>
        App.Helpers.Assets.require(entrypoints...)
      .then =>
        @editor = new Quill(container, {
          theme: "snow",
          modules: @_quill_modules(container)
        })

        # This HTML is generated by our own markdown->html parsing server-side.
        @editor.clipboard.dangerouslyPasteHTML(html_input)

        # Set placeholder for link insertion input
        tooltip = @editor.theme.tooltip
        tooltip.boundsContainer = container
        input = tooltip.root.querySelector("input[data-link]")
        input.dataset.link = 'sonadier.com'

        resolve(@editor)

  _quill_modules: (container) ->
    output = {}
    output.toolbar = [
      ['bold', 'italic', 'underline', 'link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }]
    ]

    if container.dataset.maxLength? && container.dataset.maxLength != ""
      output.maxlength = { value: parseInt(container.dataset.maxLength) }
    else
      output.maxlength = { value: 9000 }

    output

  _value: (field_value) ->
    return field_value unless @quill_enabled()

    window.quillDeltaToMarkdown(@editor.getContents().ops)

  data: ->
    @_value()
