
window.App ?= {}
window.App.Schema ?= {}

class window.App.Schema.Submission
  constructor: (@form_permalink, @_data) ->

  form: -> App.Models.Form.new(@form_permalink)
  permalink: -> @_data.headers.permalink
  persisted: -> @permalink()?

  header: (name, value) ->
    if typeof name == "object"
      @header(k, v) for k, v of name
      return undefined

    if name? && value?
      @update_simple_data(name, value)
      return @_data.headers[name] = value

    if name?
      return @_data.headers[name]

    @_data.headers

  headers: (object) ->
    if name instanceof App.Schema.Form.Header
      name = name.variable_name()

    if typeof object == "object"
      @header(k, v) for k, v of object

    @_data.headers

  field: (name, value) ->
    if name instanceof App.Schema.Form.Field
      name = name.variable_name()

    if typeof name == "object"
      @field(k, v) for k, v of name
      return undefined

    if name? && value?
      @update_simple_data(name, value)
      return @_data.fields[name] = value

    if name?
      return @_data.fields[name]

    @_data.fields

  fields: (object) ->
    if typeof object == "object"
      @field(k, v) for k, v of object

    @_data.fields

  submission: ->
    @form().submissions.load(@permalink())

  destroy: ->
    new Promise (resolve, reject) =>
      if App.is_child
        App.parent.publish(
          "schema.submission.destroy",
          form: @form_permalink,
          submission: @permalink()
        )
        .then (response) =>
          @_data = response
          resolve(@)
        .fail reject
      else
        $.ajax
          url: @_data.urls.self
          type: "DELETE"
          data: {
            authenticity_token: current_user.authenticity_token()
          }
        .done (response) =>
          @_data = response
          resolve()

          PubSub.publish("submission_data.destroy", @)
          PubSub.publish("submission_data.change", @)
        .fail (response) ->
          reject(response.responseJSON)

  restore: ->
    if @_data.urls.restore?
      request = Promise.resolve()
    else
      request = @refresh()

    new Promise (resolve, reject) =>
      request.then =>
        return resolve(@) unless @header("deleted")?

        if App.is_child
          App.parent.publish(
            "schema.submission.restore",
            form: @form_permalink,
            submission: @permalink()
          )
          .then (response) =>
            @_data = response
            resolve(@)
          .fail reject
        else
          $.ajax
            url: @_data.urls.restore
            type: "POST"
            data: {
              authenticity_token: current_user.authenticity_token()
            }
          .done (response) =>
            @_data = response
            resolve(@)

            PubSub.publish("submission_data.restore", @)
            PubSub.publish("submission_data.change", @)
          .fail (response) ->
            reject(response.responseJSON)

  refresh: ->
    new Promise (resolve, reject) =>
      if App.is_child
        App.parent.publish(
          "schema.submission.refresh",
          form: @form_permalink,
          submission: @permalink()
        )
        .then (response) =>
          @_data = response
          resolve(@)
        .fail reject
      else
        $.ajax
          url: @_data.urls.self
        .done (response) =>
          @_data = response
          resolve(@)
        .catch (xhr) ->
          reject(response.responseJSON)

  update: (parameters) ->
    if parameters?
      @field(key, value) for key, value of parameters

    new Promise (resolve, reject) =>
      @save()
      .then => PubSub.publish("submission_data.update", @)
      .catch reject

  save: ->
    new Promise (resolve, reject) =>
      if App.is_child
        App.parent.publish(
          "schema.submission.update",
          form: @form_permalink,
          submission: @permalink(),
          parameters: parameters
        ).then (response) =>
          @_data = response
          resolve(@)
        .fail reject
      else
        $.ajax
          url: @_data.urls.self
          type: "PATCH"
          dataType: "json"
          processData: false
          contentType: "application/json"
          data: JSON.stringify({
            authenticity_token: current_user.authenticity_token(),
            submission: @_data.fields
          })
        .done (response) =>
          @_data = response
          resolve(@)

          PubSub.publish("submission_data.change", @)
        .fail (response) ->
          reject(response.responseJSON)

  update_simple_data: (name, value) ->
    return unless @_bound_simple_objects?

    for object in @_bound_simple_objects
      object[name] = value

    undefined

  # Frameworks like Vue work better with simple objects
  # updated in place. The object generated here is loaded
  # into the array generated by App.Schema.SubmissionPromise's
  # toSimpleArray method
  to_simple_data: ->
    if @_bound_simple_objects? && @_bound_simple_objects[0]?
      return @_bound_simple_objects[0]

    output = {}

    for name, value of @_data.headers
      output[name] = value

    for name, vale of @_data.fields
      output[name] = value

    @_bound_simple_objects ?= []
    @_bound_simple_objects.push(output)

    output

  serialize: ->
    @_data

window.SubmissionData = App.Schema.Submission
