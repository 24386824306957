
window.App ?= {}
window.App.Query ?= {}
window.App.Query.FieldCondition ?= {}

class window.App.Query.FieldCondition.Number extends App.Query.FieldCondition.Base
  constructor: (@field, @_operator, @_value, @_options = {}) ->
    super(@field, @_operator, @_value, @_options)
  
  @operators: ["==", "!=", ">", ">=", "<", "<=", "?", "!?"]

  @aggregators: ["sum", "average", "median", "maximum", "minimum", "count"]
