class window.SelectFormulaFieldWidget
  constructor: (@element) ->
    @element = @element[0] if App.Helpers.Elements.is_jquery(@element)

    @container = @element.querySelector(".select_formula_switch")

    @formula_input = @element.querySelector(".formula_switch_field")
    @formula_container = @element.querySelector(".TEXT_FIELD")

    @select_input = @element.querySelector(".select_switch_field")
    @select_container = @element.querySelector(".SELECT_FIELD")

    @method_input = @element.querySelector(".select_formula_method")

    @attributes = {
      name: @container.dataset.name,
      id: @container.dataset.id
    }

    if @method_input?
      method_input_value = @method_input.value

      if !method_input_value? || method_input_value.length == 0
        default_value = @method_input.dataset.default

        method_input_value = default_value
        @method_input.value = default_value

      switch method_input_value
        when "select"
          @enable_select()
        when "formula"
          @enable_formula()

    @element.addEventListener "click", (event) =>
      if App.Helpers.Elements.closest(event.target, ".toggle_select")?
        event.preventDefault()
        return @enable_select()

      if App.Helpers.Elements.closest(event.target, ".toggle_formula")?
        event.preventDefault()
        return @enable_formula()

  # Remove ID and Name attributes from inputs, so they can be re-added to the enabled one.
  # This makes querying for values frontend easier than using a disabled attribute.
  disable: ->
    @container.querySelector(".select_formula_switch_button").classList.remove("selected")

    @formula_input.id = ""
    @formula_input.name = ""
    @formula_container.classList.add("hidden")

    @select_input.id = ""
    @select_input.name = ""
    @select_container.classList.add("hidden")

    undefined

  enable: (button, input, input_container) ->
    @disable()

    button.classList.add("selected")

    input.id = @attributes.id
    input.name = @attributes.name

    input_container.classList.remove("hidden")

    input.focus()
    input.dispatchEvent(new Event("keydown"))

    input

  enable_select: ->
    @enable(@element.querySelector(".toggle_select"), @select_input, @select_container)

    if @method_input?
      @method_input.value = "select"
      @method_input.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))

  enable_formula: ->
    @enable(@element.querySelector(".toggle_formula"), @formula_input, @formula_container)

    if @method_input?
      @method_input.value = "formula"
      @method_input.dispatchEvent(App.Helpers.Events.new("change", { bubbles: true }))
