
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}

window.App.Interface.Menu.parse_submit_data = (data) ->
  options = {}
  options.id = data.id
  options.text = data.text
  options.callback = data.callback
  options.preload = data.preload
  options

class window.App.Interface.Menu.Submit extends App.Interface.Menu.BaseOption
  constructor: (@menu, options = {}) ->
    super(@menu, options)

    @menu.sandboxes.publish("submit.create", @serialize())
    @menu.callbacks.trigger("submit.add", @)

  _generate: (options) ->
    cleanup_fragments = @id?
    @element.remove() if cleanup_fragments

    @id = options.id || @id || App.Helpers.Generators.uuid()
    @instance_id ?= options.instance_id || @id

    @callback(options.callback) if options.callback?
    @preload(options.preload) if options.preload?

    content = document.createElement("SPAN")
    content.textContent = options.text

    icon = document.createElement("I")
    icon.className = "icon icon-ok-circled teal"

    @button = document.createElement("A")
    @button.href = "#"

    @button.appendChild(content)
    @button.appendChild(icon)

    @element = document.createElement("DIV")
    @element.className = "option primary submit_option custom_option"
    @element.dataset.instance = @instance_id
    @element.id = @id
    @element.appendChild(@button)

  _generate_sandbox: ->
    super()

    @_subscribe "text", (event) => @text(event.value)

    @callback =>
      new Promise (resolve, reject) =>
        @_publish("callback")
        .then resolve
        .catch reject

  trigger_callbacks: ->
    return unless @_callbacks?

    return if loading @button
    add_loader @button

    promises = []

    for callback in @_callbacks
      output = callback.apply(@, arguments)
      promises.push(output) if output instanceof Promise

    if promises?
      Promise.all(promises)
      .then => remove_loader(@button)
      .catch => remove_loader(@button)

    undefined

  text: (value) ->
    for node in @element.childNodes
      continue unless node.nodeType == 3
      text_node = node
      break

    if value?
      if text_node?
        text_node.textContent = value
      else
        text_node = document.createTextNode(value)
        @button.appendChild(text_node)

      @_publish("text", { value: value })

    return "" unless text_node?

    text_node.textContent

  destroy: ->
    @element.remove()

    @menu.callbacks.trigger("submit.remove", @id)
    @_publish("remove")

    delete @menu._custom_options[@id]

    undefined

  serialize: ->
    output = {}
    output.instance_id = @instance_id
    output.id = @id
    output.text = @text()
    output
