
window.Workflows ?= {}
window.Workflows.Actions ?= {}

window.Workflows.Actions["procedure:run"] = (step) ->
  deferred = $.Deferred()

  request_endpoint = step.data.options.endpoint

  if !request_endpoint? || request_endpoint.length == 0
    step.error("Endpoint isn't defined")
    return deferred.reject()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["function:run"] = (step) ->
  deferred = $.Deferred()

  function_data = step.decode_submission_scoped_data(step.data.options.name)

  submission_request = step.get_variable(function_data.submission)
  function_name = function_data.value

  if !function_name? || function_name.length == 0
    step.error("Function name isn't defined")
    return deferred.reject()

  submission_request.done (submissions) ->
    if WorkflowStepInstance.blank_submission_request(submissions)
      step.error("Submission `#{function_data.submission}` not found")
      return deferred.reject()

    submissions = [submissions] unless Array.isArray(submissions)
    submission_promises = []

    for submission in submissions
      submission_promises.push new Promise (resolve, reject) ->
        step.evaluate_formula("function_name")
        .done (function_name) ->
          step.evaluate_formula("variable_object")
          .done (variables) ->
            function_found = false

            for uuid, step of submission.workflows._steps
              if step.type() == "trigger" && step.step_type() == "function"
                if step.data.options.name == function_name
                  function_found = true
                  step.run(variables)

            if !function_found
              step.error("Function `#{function_name}` not found")
              return reject()

            resolve()

          .fail (response) ->
            step.error(response)
            deferred.reject()
        .fail (response) ->
          step.error(response)
          reject()

    Promise.all(submission_promises)
    .then -> deferred.resolve()
    .catch -> deferred.reject()
  .fail (response) ->
    step.error("Submission not found")
    return deferred.reject()

  deferred

window.Workflows.Actions["submission:save"] = (step) ->
  deferred = $.Deferred()

  step.submission_selector().then (submissions) ->
    save_in_background = step.data.options.save_in_background
    submission_promises = []

    for submission in submissions
      submission_promises.push new Promise (resolve, reject) ->
        submission.submit(background: save_in_background)
        .done -> resolve()
        .fail -> reject()

    Promise.all(submission_promises)
    .then -> deferred.resolve()
    .catch -> deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:errors:add"] = (step) ->
  deferred = $.Deferred()

  error_promise = step.evaluate_formula("value")

  step.submission_field_selector().then (fields) ->
    error_promise.then (value) ->
      for field in fields
        field.error(value)

      deferred.resolve()
    .fail (response) ->
      step.error(response)
      deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:errors:clear"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_selector().then (fields) ->
    for field in fields
      field.errors.empty()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:update"] = (step) ->
  deferred = $.Deferred()

  source_promise = step.evaluate_formula("source")

  step.submission_field_selector().then (fields) ->
    source_promise.then (source) ->
      if source == "field"
        step.submission_field_selector(submission: "source_submission", field: "source_field")
        .then (source_fields) ->
          source_field = source_fields[0]

          for field in fields
            field.value(source_field.value())

          deferred.resolve()
        .catch (response) ->
          step.error(response)
          deferred.reject()
      else
        step.evaluate_formula("value").then (value) ->
          for field in fields
            field.value(value)

          deferred.resolve()
        .fail (response) ->
          step.error(response)
          deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:name:update"] = (step) ->
  deferred = $.Deferred()

  value_promise = step.evaluate_formula("value")

  step.submission_field_selector().then (fields) ->
    value_promise.then (value) ->
      for field in fields
        field.rename(value)

      deferred.resolve()
    .fail (response) ->
      step.error(response)
      deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:description:update"] = (step) ->
  deferred = $.Deferred()

  value_promise = step.evaluate_formula("value")

  step.submission_field_selector().then (fields) ->
    value_promise.then (value) ->
      for field in fields
        field.description(value)

      deferred.resolve()
    .fail (response) ->
      step.error(response)
      deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:enable"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_selector().then (fields) ->
    for field in fields
      field.enable()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:disable"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_selector().then (fields) ->
    for field in fields
      field.disable()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:visibility:show"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_selector().then (fields) ->
    for field in fields
      field.show()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:visibility:hide"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_selector().then (fields) ->
    for field in fields
      field.hide()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:visibility:toggle"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_selector().then (fields) ->
    for field in fields
      if field.visible()
        field.hide()
      else
        field.show()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:color"] = (step) ->
  deferred = $.Deferred()

  value_promise = step.evaluate_formula("color")

  step.submission_field_selector().then (fields) ->
    value_promise.then (value) ->
      for field in fields
        field.highlight(value)

      deferred.resolve()
    .fail (response) ->
      step.error(response)
      deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:multiple_choice:show"] = (step) ->
  deferred = $.Deferred()

  value_promise = step.evaluate_formula("field_option")

  step.submission_field_selector().then (fields) ->
    value_promise.then (value) ->
      for field in fields
        field.helpers.show_option(value)

      deferred.resolve()
    .fail (response) ->
      step.error(response)
      deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:multiple_choice:hide"] = (step) ->
  deferred = $.Deferred()

  value_promise = step.evaluate_formula("field_option")

  step.submission_field_selector().then (fields) ->
    value_promise.then (value) ->
      for field in fields
        field.helpers.hide_option(value)

      deferred.resolve()
    .fail (response) ->
      step.error(response)
      deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:user:scoped_groups:add"] = (step) ->
  deferred = $.Deferred()

  group_promise = new Promise (resolve, reject) ->
    step.evaluate_formula("value").done (permalink) ->
      App.Schema.Group.find_by_permalink(permalink)
      .then resolve
      .catch reject

  users_promise = new Promise (resolve, reject) ->
    group_promise.then (group) ->
      group.users()
      .then resolve
      .catch reject
    .catch reject

  step.submission_field_selector().then (fields) ->
    group_promise.then (group) ->
      users_promise.then (users) ->
        for field in fields
          if field.field_type() != "user"
            step.error("Field `#{field_name}` is not a user field")
            return reject()

          field.helpers.filter(users)
          deferred.resolve()
      .catch (response) ->
        step.error(response)
        deferred.reject()
    .catch (response) ->
      step.error(response)
      deferred.reject()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:field:user:scoped_groups:clear"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_selector().then (fields) ->
    for field in fields
      if field.field_type() != "user"
        step.error("Field `#{field_name}` is not a user field")
        return reject()

      field.helpers.show_options()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:page:open"] = (step) ->
  deferred = $.Deferred()

  step.submission_field_selector().then (fields) ->
    for field in fields
      field.submission.pages.open(field)

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:page:tabs:show"] = (step) ->
  deferred = $.Deferred()

  step.submission_selector().then (submissions) ->
    for submission in submissions
      submission.pages.show_tabs()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["submission:page:tabs:hide"] = (step) ->
  deferred = $.Deferred()

  step.submission_selector().then (submissions) ->
    for submission in submissions
      submission.pages.hide_tabs()

    deferred.resolve()
  .catch (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["variable:set"] = (step) ->
  deferred = $.Deferred()

  variable_name = step.data.options.name
  variable_value = step.data.options.value

  if !variable_name? || variable_name.length == 0
    step.error("Variable `#{variable_name}` not found")
    return deferred.reject()

  step.workflow.set_variable(variable_name, variable_value)
  .done => deferred.resolve()
  .fail => deferred.reject()

  deferred

window.Workflows.Actions["modal:open"] = (step) ->
  deferred = $.Deferred()

  modal_name_evaluation = step.evaluate_formula("name")
  modal_title_evaluation = step.evaluate_formula("title")
  modal_body_evaluation = step.evaluate_formula("body")
  modal_size_evaluation = step.evaluate_formula("size")

  $.when(modal_name_evaluation, modal_title_evaluation, modal_body_evaluation, modal_size_evaluation)
  .done (modal_name, modal_title, modal_body, modal_size) ->
    options = {}
    options.size = modal_size || "x-small"
    options.id = "workflow_modal_#{modal_name}" || App.Helpers.Generators.uuid()

    App.Interface.Modal.escape_new(modal_title, modal_body, options)

    deferred.resolve()
  .fail (modal_id_error, modal_title_error, modal_body_error, modal_size_error) ->
    deferred.reject()

  deferred

window.Workflows.Actions["modal:close"] = (step) ->
  deferred = $.Deferred()
  modal_name_evaluation = step.evaluate_formula("name")

  modal_name_evaluation
  .done (modal_name) ->
    modal = App.Interface.Modal.find_by_id("workflow_modal_#{modal_name}_container")

    if !modal?
      step.error("Modal `#{modal_name}` not found")
    else
      modal.hide()

    deferred.resolve()
  .fail ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["navbar:notice:open"] = (step) ->
  deferred = $.Deferred()

  # Used for control flow between value_types
  content_lookup = $.Deferred()

  switch step.data.options.value_type
    when "select"
      field_data = step.decode_field_data(step.data.options.value)
      field_name = field_data.field

      if !field_name? || field_name.length == 0
        step.error("Field isn't defined")
        return deferred.reject()

      step.get_variable(field_data.submission).done (submissions) ->
        submissions = [submissions] unless Array.isArray(submissions)

        output = []

        for submission in submissions
          field = submission.fields.find_by_column_name(field_name)

          if !field?
            step.error("Field `#{field_name}` not found")
            return content_lookup.reject()

          output.push(field.text())

        content_lookup.resolve(output)
      .fail (response) ->
        step.error(response)
        content_lookup.reject()
    when "formula"
      step.evaluate_formula("value")
      .done (content) -> content_lookup.resolve(content)
      .fail (response) ->
        step.error(response)
        content_lookup.reject()

  step.evaluate_formula("name").done (evaluated_name) ->
    content_lookup.done (content) ->
      options = {}
      options.id = evaluated_name if evaluated_name? && evaluated_name.length > 0

      if Array.isArray(content)
        output = []

        for element in content
          if element? && (!element.length? || element.length > 0)
            output.push(element)

        content = output.join(", ")

      if step.data.options.type == "alert"
        App.menu().alert(content, options)
      else
        App.menu().notice(content, options)

      deferred.resolve()
    .fail -> deferred.reject()
  .fail (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["navbar:notice:close"] = (step) ->
  deferred = $.Deferred()

  message_id_evaluation = step.evaluate_formula("name")

  message_id_evaluation
  .done (evaluated_name) ->
    evaluated_name = null if evaluated_name? && evaluated_name.length == 0
    App.menu().remove(evaluated_name) if evaluated_name?
    deferred.resolve()
  .fail (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["notification:user:send"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred

window.Workflows.Actions["notification:user:resolve"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred

window.Workflows.Actions["notification:group:send"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred

window.Workflows.Actions["notification:group:resolve"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred

window.Workflows.Actions["window:redirect"] = (step) ->
  deferred = $.Deferred()

  redirect_url_evaluation = step.evaluate_formula("url")

  redirect_url_evaluation
  .done (evaluated_url) ->
    if evaluated_url? && evaluated_url.length > 0
      if !evaluated_url.match(/^[a-z]+:\/\//gi) && evaluated_url[0] != "/"
        evaluated_url = "https://#{evaluated_url}"

      window.location = evaluated_url

    # Workflow evaluation shouldn't continue after redirecting
    deferred.reject()
  .fail (response) ->
    step.error(response)
    deferred.reject()

  deferred

window.Workflows.Actions["integration:google_calendar:add_event"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred

window.Workflows.Actions["integration:google_tasks:add_task"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred

window.Workflows.Actions["integration:google_sheets:add_row"] = (step) ->
  deferred = $.Deferred()

  step.run_server()
  .done ->
    deferred.resolve()
  .fail (response) ->
    if response? && response.errors?
      step.error(error) for error in response.errors?
    deferred.reject()

  deferred
