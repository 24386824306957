
window.App ?= {}
window.App.Elements ?= {}
window.App.Elements.ResponseTable ?= {}

class window.App.Elements.ResponseTable.Helpers.Selections
  constructor: (@table, @options = {}) ->
    @element = @_generate()

    if @element?
      @counter = @element.querySelector(".row_count")
      @select_all_button = @element.querySelector(".select_all_existing")

    @_selections = []
    @_selections_deleted = []
    @_selections_visible = []
    @_select_all_existing = false

    @table.on "refresh", =>
      for selection in @all()
        @_select_checkbox(selection)

    if @table.table_element.dataset.boundSelect?
      @bound_select = document.querySelector(@table.table_element.dataset.boundSelect)

      @bound_select.addEventListener "change", (event) =>
        @_load_from_select(@bound_select)

      @_load_from_select(@bound_select)

  # Important static options
  _find_options: ->
    output = []

    output.push {
      text: i18n.t("delete")
      class: "option right mobile_hidden option-button red delete_rows"
      if: => !@table.params.deleted()
      callback: (e) => @destroy_selections(e)
    }

    output.push {
      text: i18n.t("restore")
      class: "option right mobile_hidden option-button teal restore_rows"
      if: => @table.params.deleted()
      callback: (e) => @restore_selections(e)
    }

    output.push {
      text: i18n.t("cancel")
      class: "option right mobile_hidden cancel_rows"
      callback: (e) => @deselect_all_existing(e)
    }

    output.push {
      text: i18n.t("mass_update")
      class: "option right mobile_hidden mass_update_rows"
      callback: (e) => @mass_update().show(e)
    }

    output

  # Less-important static options
  _find_secondary_options: ->
    output = []

    output.push {
      text: i18n.t("export")
      callback: (e) => @export_selections(e)
    }

    output.push {
      text: i18n.t("clone")
      callback: (e) => @clone_selections(e)
    }

    output

  # Dynamic options generated from procedures or buttons
  _find_custom_options: ->
    new Promise (resolve, reject) =>
      output = []

      @table.form().load().then (form) =>
        buttons = []

        for field in form.fields.all()
          buttons.push(field) if field.field_type() == "button"

        for button in buttons
          output.push {
            text: button.name(),
            callback: ((button) => =>
              @query_selections().then (schemas) =>
                schemas.forEach (schema) =>
                  schema.submission().then (submission) =>
                    submission.on "ready", =>
                      submission.fields.find(button).helpers.trigger()
            )(button)
          }

        resolve(output)

  _generate_option_element: (option) ->
    element = document.createElement("A")
    element.href = "#"
    element.className = option.class
    element.textContent = option.text
    element.addEventListener "click", ((option) => (e) =>
      e.preventDefault()
      option.callback(e)
    )(option)

    element

  _generate_more_options_element: ->
    element = document.createElement("A")
    element.href = "#"
    element.className = "option right more_mass_select_options"
    element.innerHTML = "<i class='icon icon-dot-3 rotate-90 ml-1'></i>"

    element.addEventListener "click", (e) =>
      e.preventDefault()

      add_loader(element)

      @_find_custom_options().then (custom_options) =>
        remove_loader(element)

        menu_options = []

        for option in @_find_options()
          new_option = Object.assign({}, option)
          new_option.class = "mobile_visible"
          menu_options.push(new_option)

        for option in @_find_secondary_options()
          menu_options.push(option)

        for option in custom_options
          menu_options.push(option)

        menu = new App.Interface.ContextMenu.Object(menu_options)
        menu.element = element
        menu.config.medium = true

        coords = element.getBoundingClientRect()
        menu.show(position: { x: coords.left + element.offsetWidth, y: coords.bottom - 5 })

    element

  _generate: ->
    return if @options.visible == false

    element = document.createElement("DIV")
    element.className = "selection_bar"
    element.setAttribute("data-facility", current_user.facility_permalink)
    element.style.display = "none"

    row_count = document.createElement("SPAN")
    row_count.className = "row_count"

    select_all = document.createElement("A")
    select_all.href = "#"
    select_all.className = "select_all_existing ml-1"
    select_all.textContent = i18n.t("response_table.mass_select.select_all")
    select_all.addEventListener "click", (e) =>
      e.preventDefault()
      @select_all_existing()

    counter = document.createElement("DIV")
    counter.className = "option static"

    counter.appendChild(row_count)
    counter.appendChild(select_all)

    element.appendChild(counter)
    element.appendChild(@_generate_more_options_element())

    for option in @_find_options()
      element.appendChild(@_generate_option_element(option))

    parent = App.Helpers.Elements.closest(@table.element, ".main_view_page, body")
    parent.appendChild(element)

    element

  any: -> @_selections.length > 0

  # Returns an array of permalinks for selections
  all: -> @_selections

  # Returns an array of permalinks for selections that are deleted
  all_deleted: -> @_selections_deleted

  # Returns an array of permalinks for selections that are not deleted
  all_visible: -> @_selections_visible

  ordered_selections: ->
    output = []

    for permalink in @table.row_permalinks()
      output.push(permalink) if @_selections.indexOf(permalink) != -1

    output

  _load_from_select: (select) ->
    values = App.Helpers.Selects.values(select)

    if values? && values.length > 0
      @select(value) for value in values

  _select_checkbox: (permalink) ->
    checkbox = @table.element.querySelector("input[type=checkbox][data-permalink='#{permalink}']")
    checkbox.checked = true if checkbox?

  selected: (permalink) ->
    @_selections.indexOf(permalink) != -1

  # Select a row by passing it's permalink and the checkbox used to select it
  select: (permalink, draw) ->
    deleted = @table.params.deleted()

    if @options.multiple == false
      @deselect_all_existing()

    return unless @_selections.indexOf(permalink) == -1

    @_selections.push(permalink)

    if deleted
      @_selections_deleted.push(permalink) if @_selections_deleted.indexOf(permalink) == -1
    else
      @_selections_visible.push(permalink) if @_selections_visible.indexOf(permalink) == -1

    @_select_checkbox(permalink)

    if @bound_select?
      App.Helpers.Selects.add_option(@bound_select, {
        value: permalink,
        selected: true
      })

    @draw() unless draw == false

  select_all: ->
    checkboxes = @table.element.querySelectorAll("input.row_select[type=checkbox]")

    @select(checkbox.dataset.permalink) for checkbox in checkboxes

    @draw()

  _deselect_checkbox: (permalink) ->
    checkbox = @table.element.querySelector("input[type=checkbox][data-permalink='#{permalink}']")
    checkbox.checked = false if checkbox?

  # Deselect a row by passing it's permalink
  deselect: (permalink, draw) ->
    index = @_selections.indexOf(permalink)
    deleted_index = @_selections_deleted.indexOf(permalink)
    visible_index = @_selections_visible.indexOf(permalink)

    @_selections.splice(index, 1) if index != -1
    @_selections_deleted.splice(deleted_index, 1) if deleted_index != -1
    @_selections_visible.splice(visible_index, 1) if visible_index != -1

    @_deselect_checkbox(permalink)

    if @bound_select?
      App.Helpers.Selects.set_option(@bound_select, permalink, false)

    @draw() unless draw == false

  deselect_all: ->
    cloned_selections = @_selections.slice(0)

    select_all_checkbox = @table.element.querySelector(".row_select_all")
    select_all_checkbox.checked = false if select_all_checkbox?

    @deselect(selection) for selection in cloned_selections

    @draw()

  toggle_all: (status = true) ->
    if status
      @select_all()
    else
      @deselect_all()

  select_all_existing: ->
    @select_all()
    @set_counter(@table.total_count())
    @_select_all_existing = true

  deselect_all_existing: ->
    @_select_all_existing = false
    @deselect_all()
    @set_counter(0)

  load_promised_selections: ->
    return Promise.resolve(@_selections) if !@_select_all_existing || @_select_all_fetched

    new Promise (resolve, reject) =>
      data_route = App.Helpers.URIs.parse(@table.routes.data())
      data_route.set_param("length", 10000)

      data_parameters = @table.params.serialize()
      data_parameters.columns = [
        { data: "permalink", name: "permalink" }
      ]

      $.ajax
        url: data_route.value()
        data: data_parameters
        type: "POST"
      .done (response) =>
        page_length = @table.params.length()
        page_index = @table.params.page() * page_length

        # Remove submissions from the current page, some of which may
        # be unchecked.
        new_submissions = response.data
        new_submissions.splice(page_index, page_length)

        for submission in response.data
          @select(submission.permalink, false)

        @draw()

        resolve(@_selections)
      .fail reject

  destroy_selections: (event) ->
    if App.Helpers.Objects.isEvent(event)
      button = event.currentTarget
      return if loading button
      add_loader button

    new Promise (resolve, reject) =>
      @load_promised_selections().then =>
        $.ajax
          url: Routes.bulk_delete_submissions_path(@table.form().permalink())
          type: "POST"
          data: {
            selections: @all().join(","),
            select_all: @_select_all_existing,
            authenticity_token: current_user.authenticity_token()
          }
        .done (response) =>
          remove_loader button
          @deselect_all_existing()
          @table.refresh()
          resolve()
        .fail (xhr) ->
          remove_loader button
          App.Errors.response_error(xhr)
          reject()

  restore_selections: (event) ->
    if App.Helpers.Objects.isEvent(event)
      button = event.currentTarget
      return if loading button
      add_loader button

    new Promise (resolve, reject) =>
      @load_promised_selections().then =>
        $.ajax
          url: Routes.bulk_restore_submissions_path(@table.form().permalink())
          type: "POST"
          data: {
            selections: @all().join(","),
            select_all: @_select_all_existing,
            authenticity_token: current_user.authenticity_token()
          }
        .done (response) =>
          remove_loader button
          @deselect_all_existing()
          @table.refresh()
          resolve()
        .fail (xhr) ->
          remove_loader button
          App.Errors.response_error(xhr)
          reject()

  clone_selections: (event) ->
    if App.Helpers.Objects.isEvent(event)
      button = event.currentTarget
      return if loading button
      add_loader button

    new Promise (resolve, reject) =>
      @load_promised_selections().then =>
        $.ajax
          url: Routes.bulk_clone_submissions_path(@table.form().permalink())
          type: "POST"
          data: {
            selections: @all().join(","),
            select_all: @_select_all_existing,
            authenticity_token: current_user.authenticity_token()
          }
        .done (response) =>
          remove_loader button
          @deselect_all_existing()
          @table.refresh()
          resolve()
        .fail (xhr) ->
          remove_loader button
          App.Errors.response_error(xhr)
          reject()

  mass_update: ->
    @_mass_update_model ?= new App.Elements.ResponseTable.Helpers.MassUpdate(@table)
    @_mass_update_model

  export_selections: ->
    schema = @table.form().schema()
    modal = @table.export.modal().show()

    new Promise (resolve, reject) =>
      @load_promised_selections().then =>
        Promise.all([schema, modal]).then (values) =>
          @table.export.query([{ field: "permalink", value: @all() }])

          resolve(values[1])

  query_selections: ->
    new Promise (resolve, reject) =>
      @load_promised_selections().then =>
        @table.form().submissions.query_by_permalinks(@all()).then (schemas) =>
          resolve(schemas)
        .catch reject
      .catch reject

  set_counter: (value) ->
    return unless @counter?

    @counter.textContent = i18n.t("responses_selected", { count: value })

  count: ->
    if !@_select_all_existing || @_select_all_fetched
      return @all().length

    total_count = @table.total_count()
    page_count = @table.visible_count()

    page_selected_count = @all().length
    page_unselected_count = page_count - page_selected_count

    if page_unselected_count > 0
      return total_count - page_unselected_count
    else
      return total_count

  # Deal with any leftover bindings when the selection bar is dismissed.
  cleanup: ->
    if @table.export.loaded()
      @table.export.reset_query()

    undefined

  draw: ->
    return unless @element?

    count = @count()
    deleted_count = @all_deleted().length
    visible_count = @all_visible().length

    @set_counter(count)

    table_visible = @table.visible_count()

    if table_visible == count && table_visible != @table.total_count()
      @select_all_button.style.display = "inline"
    else
      @select_all_button.style.display = "none"

    if count == 0
      App.Helpers.Elements.slide_up(@element)
      @cleanup()
    else
      App.Helpers.Elements.slide_down(@element)

      delete_button = @element.querySelector(".delete_rows")
      restore_button = @element.querySelector(".restore_rows")

      select_all_visible = @_select_all_existing && @table.params.deleted() == false
      select_all_deleted = @_select_all_existing && @table.params.deleted() == true

      if visible_count == 0 && !select_all_visible
        delete_button.classList.add("hidden")
      else
        delete_button.classList.remove("visible")

      if deleted_count == 0 && !select_all_deleted
        restore_button.classList.add("hidden")
      else
        restore_button.classList.remove("hidden")

    @element
