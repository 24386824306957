
window.App ?= {}
window.App.Query ?= {}
window.App.Query.FieldCondition ?= {}

class window.App.Query.FieldCondition.Connection extends App.Query.FieldCondition.Base
  constructor: (@field, @_operator, @_value, @_options = {}) ->
    super(@field, @_operator, @_value, @_options)

  value: (value) ->
    if value? && App.Query.value_is_serialized_conditional(value)
      if !@field.data.connection?
        return throw new Error(
          "schema() must be called on the connection field before connection conditions can be parsed"
        )

      form = App.Models.Form.new(@field.data.connection.form.permalink)

      value = App.Query.Condition.from_parameters(
        undefined, form, value
      )

    super(value)

  serialize: ->
    output = super()

    if App.Query.value_is_conditional(output.value)
      output.value = output.value.serialize()

    output
  
  @operators: ["==", "!=", "%", "!%", "?", "!?"]

  @aggregators: ["count"]
