
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}

window.App.Interface.Menu.parse_search_data = (data) ->
  options = {}
  options.id = data.id
  options.callback = data.callback
  options

class window.App.Interface.Menu.Search extends App.Interface.Menu.BaseOption
  constructor: (@menu, options = {}) ->
    super(@menu, options)

    @menu.sandboxes.publish("search.create", @serialize())
    @menu.callbacks.trigger("search.add", @)

  _generate: (options) ->
    # Preserve input for compatibility with any legacy or custom plugin listeners
    @input.parentNode.removeChild(@input) if @input?

    cleanup_fragments = @id?
    @element.remove() if cleanup_fragments

    @id = options.id || @id || App.Helpers.Generators.uuid()
    @instance_id ?= options.instance_id || @id

    @element = document.createElement("DIV")
    @element.dataset.instance = @instance_id
    @element.className = "option primary search_option custom_option"
    @element.id = "#{@id}_option"

    button = document.createElement("A")
    button.href = "#"
    button.className = "show_search_input search_button"
    button.innerHTML = "<i class='icon icon-search'></i> #{i18n.t("search")}"

    @element.appendChild(button)

    @input ?= document.createElement("INPUT")
    @input.className = "search_input_element"
    @input.type = "text"
    @input.id = @id
    @input.setAttribute("placeholder", i18n.t("search"))

    @input_wrapper = document.createElement("DIV")
    @input_wrapper.dataset.instance = @instance_id
    @input_wrapper.className = "option primary hidden search_input custom_option"

    input_close = document.createElement("A")
    input_close.href = "#"
    input_close.className = "show_search_input"
    input_close.innerHTML = "<i class='icon icon-cancel'></i>"

    @input_wrapper.appendChild(@input)
    @input_wrapper.appendChild(input_close)

    @menu.element.appendChild(@element)
    @menu.element.appendChild(@input_wrapper)

    @element

  _generate_sandbox: ->
    super()

    @_subscribe "search.update", (event) => @search(event.value)

    @callback (value) => @_publish("callback", value)

  search: (value) ->
    if value?
      @open() unless App.is_mobile()
      @input.value = value
      @input.dispatchEvent(App.Helpers.Events.new("input", { bubbles: true }))

    @input.value

  open: ->
    search_button = @element.querySelector(".search_button")
    search_wrapper = @input_wrapper

    search_button.classList.add("hidden")
    search_wrapper.classList.remove("hidden")

  close: ->
    search_button = @element.querySelector(".search_button")
    search_wrapper = @input_wrapper

    search_button.classList.remove("hidden")
    search_wrapper.classList.add("hidden")

    if !App.is_mobile() && @input.value? && @input.value != ""
      @input.value = ""

      input.dispatchEvent(App.Helpers.Events.new("input", { bubbles: true }))
      input.focus()

  opened: ->
    !@input_wrapper.classList.contains("hidden")

  focus: ->
    @input.focus()

  destroy: ->
    @element.remove()

    @menu.callbacks.trigger("search.remove", @id)
    @_publish("remove")

    delete @menu._custom_options[@id]
    delete @menu._search_option

    undefined

  text: -> i18n.t("search")

  serialize: ->
    output = {}
    output.instance_id = @instance_id
    output.id = @id
    output.search = @search()
    output

