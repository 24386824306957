
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Objects ?= {}

window.App.Helpers.Objects.isPlainObject = (object) ->
  return false unless object?
  return false if typeof object != "object"
  return false if object.nodeType?
  return false if App.Helpers.Objects.isWindow(object)

  hasOwn = Object.prototype.hasOwnProperty

  try
    if object.constructor && !hasOwn.call(object, "constructor")
      if !hasOwn.call(object.constructor.prototype, "isPrototypeOf")
        return false
  catch e
    # IE8-9 throw exceptions on certain host objects
    return false

  # Properties
  last = key for key in object

  return !key? || hasOwn.call(object, key)

window.App.Helpers.Objects.isEmptyObject = (object) ->
  for name in object
    return false

  true

window.App.Helpers.Objects.isArray = (object) ->
  Array.isArray(object)

window.App.Helpers.Objects.isWindow = (value) ->
  return false if !value?
  return false if value != value.window

  true

window.App.Helpers.Objects.isFunction = (value) ->
  typeof value == "function"

window.App.Helpers.Objects.isPromise = (value) ->
  return false if !value?
  return true if Promise? && value instanceof Promise
  App.Helpers.Objects.isFunction(value.then) && App.Helpers.Objects.isFunction(value.done)

window.App.Helpers.Objects.isNode = (value) ->
  value.nodeType?

window.App.Helpers.Objects.isEvent = (value) ->
  return false unless value?
  return true if Event? && value instanceof Event
  value.target? && value.currentTarget? && typeof value.target == "object"

window.App.Helpers.Objects.isString = (value) ->
  typeof value == "string" || value instanceof String

window.App.Helpers.Objects.isPermalinkString = (value) ->
  return unless App.Helpers.Objects.isString(value)

  value.length == 10

window.App.Helpers.Objects.isUuidString = (value) ->
  return unless App.Helpers.Objects.isString(value)

  value.length == 36

window.App.Helpers.Objects.matchesType = (value, class_name) ->
  switch class_name
    when String
      return App.Helpers.Objects.isString(value)
    when Event
      return App.Helpers.Objects.isEvent(value)
    when Object
      return App.Helpers.Objects.isPlainObject(value)
    when Function
      return App.Helpers.Objects.isFunction(value)
    else
      return value instanceof class_name

window.App.Helpers.Objects.deep_clone = (object) ->
  if Array.isArray(object)
    return App.Helpers.Arrays.deep_clone(object)

  if !object? || typeof object != "object"
    return object

  clone = new object.constructor()

  for key, value of object
    clone[key] = App.Helpers.Objects.deep_clone(value)

  clone

window.App.Helpers.Objects.isEquivalent = (object, other) ->
  object_props = Object.getOwnPropertyNames(object)
  other_props = Object.getOwnPropertyNames(other)

  return false if object_props.length != other_props.length

  for prop in object_props
    return false if object[prop] != other[prop]

  true
