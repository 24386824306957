
$(document).on "click", ".new_submission_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  form.submissions.open_new()
  .then (submission) ->
    remove_loader(button)
    App.Models.Submission.Helpers.Generators.assign_link_attributes(submission, button)
  .catch -> remove_loader(button)

$(document).on "mousedown", ".new_submission_link", (event) ->
  return unless event.which == 1

  event.preventDefault()

  button = event.currentTarget

  form_permalink = button.dataset.formPermalink
  form = App.Models.Form.new(form_permalink)

  form.submissions.open_new()
  .then (submission) ->
    App.Models.Submission.Helpers.Generators.assign_link_attributes(submission, button)
  .catch -> null

$(document).on "click", ".edit_submission_link", (event) ->
  event.preventDefault()
  event.stopPropagation()

  button = event.currentTarget
  return if loading button

  add_loader button

  submission_permalink = button.dataset.permalink
  form_permalink = button.dataset.formPermalink

  form = App.Models.Form.new(form_permalink)

  form.submissions.open(submission_permalink)
  .then -> remove_loader(button)
  .catch -> remove_loader(button)

$(document).on "mousedown", ".edit_submission_link", (event) ->
  return unless event.which == 1

  event.preventDefault()
  event.stopPropagation()

  button = event.currentTarget

  submission_permalink = button.dataset.permalink
  form_permalink = button.dataset.formPermalink

  form = App.Models.Form.new(form_permalink)
  form.submissions.load(submission_permalink)

$(document).on "click", ".restore_submission_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  submission_permalink = button.dataset.permalink
  form_permalink = button.dataset.formPermalink

  form = App.Models.Form.new(form_permalink)

  form.submissions.load(submission_permalink)
  .then (submission) ->
    submission.restore()
    .then -> remove_loader button
    .catch -> remove_loader button
  .catch (xhr) ->
    remove_loader button
    App.Errors.response_error(xhr)

$(document).on "click", ".destroy_submission_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  submission_permalink = button.dataset.permalink
  form_permalink = button.dataset.formPermalink

  form = App.Models.Form.new(form_permalink)

  form.submissions.load(submission_permalink)
  .then (submission) ->
    submission.destroy()
    .then -> remove_loader button
    .catch -> remove_loader button
  .catch (xhr) ->
    remove_loader button
    App.Errors.response_error(xhr)

$(document).on "click", ".submission_history_link", (event) ->
  event.preventDefault()

  button = event.currentTarget
  return if loading button

  add_loader button

  submission_permalink = button.dataset.permalink
  form_permalink = button.dataset.formPermalink

  return unless form_permalink?

  form = App.Models.Form.new(form_permalink)

  form.submissions.load(submission_permalink)
  .then (submission) ->
    submission.modals.history().show()
    .then -> remove_loader(button)
    .catch (error) ->
      remove_loader(button)
      throw error
  .catch (error) ->
    remove_loader(button)
    App.Errors.response_error(error)
    throw error

$ ->
  if current_user.config("modal_toggle_hotkeys") != false
    Hotkey.bind ["CTRL", "S"],
      if: ->
        submission = App.Models.Submission.visible()

        return false unless submission?
        return false unless submission.display() == "modal" && submission.visible()

        App.Helpers.Hotkeys.data.submission = submission

        true
      do: ->
        App.Helpers.Hotkeys.data.submission.save()
