
window.App ?= {}
window.App.Helpers ?= {}
window.App.Helpers.Loaders ?= {}

window.App.Helpers.Loaders.start = (element, original_icon_or_promise, original_icon) ->
  if !element? || element == "page"
    return App.Helpers.Loaders.start_page()

  if element == window || element == document || element == document.body
    return App.Helpers.Loaders.start_page()

  if App.Helpers.Objects.isPromise(element)
    return App.Helpers.Loaders.promise(element)

  if App.Helpers.Objects.isEvent(element)
    if element.currentTarget? && element.currentTarget.tagName.toUpperCase() != "FORM"
      element = element.currentTarget
    else
      element = element.target

  if App.Helpers.Elements.is_jquery(element) || Array.isArray(element) || element instanceof NodeList || element instanceof HTMLCollection
    if element.length <= 1
      element = element[0]
    else
      for node in element
        App.Helpers.Loaders.start(node, original_icon_or_promise, original_icon)

      return

  return unless element?

  if App.Helpers.Objects.isPromise(original_icon_or_promise)
    promise = original_icon_or_promise
  else
    original_icon = original_icon_or_promise

  element.classList.add("loading")

  icon = element.querySelector(".loader_icon")
  icon ?= element.querySelector(".icon")

  if icon?
    original_icon ?= icon.className.match(/\bicon\-\S*/)
    original_icon = original_icon.join(" ") if Array.isArray(original_icon)

    if !icon.dataset.originalIcon?
      icon.dataset.originalIcon = original_icon

    icon.classList.remove(original_icon)
    icon.classList.add("icon", "icon-spin", "rotate-pulse")
  else
    if element.children.length > 0
      App.Helpers.Loaders.start(
        element.children[0],
        original_icon_or_promise,
        original_icon
      )
    else
      element.style.width = element.offsetWidth + "px"
      element.style.textAlign = "center"
      element.dataset.originalValue = element.textContent
      element.innerHTML = "<i class='icon icon-spin rotate-pulse generated_icon'></i>"

  if promise?
    promise
    .then -> App.Helpers.Loaders.finish(element)
    .catch (error) ->
      App.Helpers.Loaders.finish(element)
      throw error

  element

window.App.Helpers.Loaders.start_page = (options = {}) ->
  loader = document.body.querySelector(".page_loader")
  return loader if loader?

  loader = document.createElement("DIV")
  loader.className = "page_loader"
  loader.innerHTML = "<div class='loader_bar'></div>"

  document.body.appendChild(loader)
  document.body.style.cursor = "wait" if options.cursor

  loader

window.App.Helpers.Loaders.start_cursor = (element) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)
  element ?= document.body

  App.Helpers.Loaders.start_page() if App.is_mobile()

  element.style.cursor = "wait"

window.App.Helpers.Loaders.finish = (element) ->
  if !element? || element == "page"
    return App.Helpers.Loaders.finish_page()

  if element == window || element == document || element == document.body
    return App.Helpers.Loaders.finish_page()

  if App.Helpers.Objects.isEvent(element)
    if element.currentTarget? && element.currentTarget.tagName.toUpperCase() != "FORM"
      element = element.currentTarget
    else
      element = element.target

  if App.Helpers.Elements.is_jquery(element) || Array.isArray(element) || element instanceof NodeList || element instanceof HTMLCollection
    if element.length <= 1
      element = element[0]
    else
      for node in element
        App.Helpers.Loaders.finish(node)

      return

  return unless element?

  element.classList.remove("loading")

  icon = element.querySelector(".loader_icon")
  icon ?= element.querySelector(".icon:not(.generated_icon)")

  if icon? && !element.dataset.originalValue?
    icon.classList.remove("icon-spin", "rotate-pulse")
    icon.classList.add(icon.dataset.originalIcon)
    icon.style.width = null
  else
    if element.children.length > 0 && !element.children[0].classList.contains("icon")
      App.Helpers.Loaders.finish(element.children[0])
    else
      element.removeAttribute("style")
      element.textContent = element.dataset.originalValue

  element

window.App.Helpers.Loaders.finish_page = (options = {}) ->
  loader = document.body.querySelector(".page_loader")
  return unless loader?

  loader.classList.add("loaded")

  document.body.style.cursor = "" if options.cursor

  setTimeout((->
    return if !loader? || !loader.parentNode?
    loader.parentNode.removeChild(loader)
  ), 1500)

window.App.Helpers.Loaders.finish_cursor = (element) ->
  element = element[0] if App.Helpers.Elements.is_jquery(element)
  element ?= document.body

  App.Helpers.Loaders.finish_page() if App.is_mobile()

  element.style.cursor = ""

window.App.Helpers.Loaders.loading = (element) ->
  if !element? || element == "page"
    return App.Helpers.Loaders.page_loading()

  if element == window || element == document || element == document.body
    return App.Helpers.Loaders.page_loading()

  element = element[0] if App.Helpers.Elements.is_jquery(element)

  return false unless element?

  if App.Helpers.Objects.isEvent(element)
    if element.currentTarget? && element.currentTarget.tagName.toUpperCase() != "FORM"
      element = element.currentTarget
    else
      element = element.target

  if Array.isArray(element)
    for value in element
      return true if App.Helpers.Loaders.loading(value)
    return false
  else
    element.classList.contains("loading")

window.App.Helpers.Loaders.page_loading = ->
  document.body.querySelector(".page_loader")?

window.App.Helpers.Loaders.promise = (promise, options = {}) ->
  App.Helpers.Loaders.start_page(options)
  promise.then -> App.Helpers.Loaders.finish_page(options)

window.add_page_loader = App.Helpers.Loaders.start_page

window.remove_page_loader = App.Helpers.Loaders.finish_page

window.page_loader = App.Helpers.Loaders.promise

# Set a DOM element to "loading", animating it and preventing it from being triggered again
window.add_loader = App.Helpers.Loaders.start

# Remove the loading state from an element, unanimating it and allowing it to be triggered again
window.remove_loader = App.Helpers.Loaders.finish

window.loading = App.Helpers.Loaders.loading

window.App.start_loader = App.Helpers.Loaders.start
window.App.finish_loader = App.Helpers.Loaders.finish
window.App.loading = App.Helpers.Loaders.loading
