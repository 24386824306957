
window.App ?= {}
window.App.Frame ?= {}
window.App.Frame.Sandbox ?= {}
window.App.Frame.Sandbox.Bind ?= {}

window.App.Frame.Sandbox.Bind.modals = (channel, options) ->
  if options.create == true
    channel.on "modal.create", (event) =>
      elements = event.data.elements
      App.Interface.Modal.escape_new(
        elements.title, elements.body, elements.actions
      )
