
window.App ?= {}
window.App.Models ?= {}
window.App.Models.Submission ?= {}
window.App.Models.Submission.Field ?= {}
window.App.Models.Submission.Field.Helpers ?= {}

class window.App.Models.Submission.Field.Helpers.Barcode
  constructor: (@field) ->
    @element = @field._element

    @barcode_label = @element.querySelector(".BARCODE_LABEL")
    @barcode_input = @element.querySelector(".BARCODE_INPUT")
    @barcode_value = @element.querySelector(".BARCODE_VALUE")

  scan: (event) ->
    @_scan_reset()

    return unless event.target.files? && event.target.files.length > 0

    add_loader @barcode_label
    @field.submission._start_loading() if @field.submission?

    $.ajax
      url: Routes.scan_barcode_path()
      type: "POST"
      data: event.target.files[0]
      processData: false
      contentType: false
    .done (response) => @barcode_value.value = response.value
    .fail (response) => @_scan_error()
    .always (response) =>
      @_scan_clear()
      @field.submission._finish_loading() if @field.submission?

  _scan_error: ->
    @barcode_value.value = ""
    @barcode_value.setAttribute("placeholder", "We couldn't scan that image, please try again.")

  _scan_reset: ->
    @barcode_value.value = ""
    @barcode_value.setAttribute("placeholder", "")

  _scan_clear: ->
    new_barcode_input = @barcode_input.cloneNode()
    new_barcode_input.value = ""

    @barcode_input.parentNode.replaceChild(new_barcode_input, @barcode_input)
    @barcode_input = new_barcode_input

    remove_loader @barcode_label
