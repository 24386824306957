
window.App ?= {}
window.App.Interface ?= {}
window.App.Interface.Menu ?= {}
window.App.Interface.Menu._models ?= {}

class window.App.Interface.Menu.MenuBar
  constructor: (@element) ->
    @element = @element[0] if App.Helpers.Elements.is_jquery(@element)

    @instance_id = App.Helpers.Generators.uuid()

    @callbacks = new CallbackManager(@)

    @_custom_options = {}
    @_search_option = undefined

    App.Interface.Menu._models[@element.dataset.pagePath] = @

    @sandboxes = new App.Frame.Sandbox.Manager(@, "menu.#{@instance_id}")
    @sandboxes.on "start", => App.Interface.Menu.MenuBar.Helpers.Sandboxes.register(@)

  on: (event, callback) -> @callbacks.on(event, callback)

  option: (text, options_or_callback, callback) ->
    if typeof options_or_callback == "function"
      callback = options_or_callback
    else
      options = options_or_callback

    options ?= {}
    options.text ?= text

    if options.id? && @_custom_options[options.id]?
      model = @_custom_options[options.id]
      model.icon(options.icon) if options.icon?
      model.text(options.text) if options.text?
    else
      model = new App.Interface.Menu.Option(@, options)
      model.callback(callback) if callback?

    @_custom_options[model.id] = model

    model

  remove_option: (selector) ->
    if selector instanceof App.Interface.Menu.Option
      model = selector
    else
      model = @_custom_options[selector]

    model.remove() if model?

  options: (selector) ->
    return @_custom_options[selector] if selector?

    output = []
    output.push(option) for id, option of @_custom_options
    output

  filter_option: (text_or_query, query_or_callback, callback) ->
    args = App.Helpers.Arguments.overload(
      text_or_query, query_or_callback, callback,
      [
        [["text", String], ["query", App.Query.Group], ["callback", Function]],
        [["text", String], ["query", App.Elements.ResponseQuery.Query], ["callback", Function]],
        [["text", String], ["query", App.Query.Group]],
        [["text", String], ["query", App.Elements.ResponseQuery.Query]],
        [["query", App.Query.Group], ["callback", Function]],
        [["query", App.Elements.ResponseQuery.Query], ["callback", Function]]
      ]
    )

    options = {}
    options.text = args.text if args.text?

    if args.query instanceof App.Elements.ResponseQuery.Query
      options.editor = args.query
      args.query = args.query.editor_query

    model = new App.Interface.Menu.Filter(@, options)
    model.query(args.query) if args.query?
    model.callback(args.callback) if args.callback?

    @_custom_options[model.id] = model

    model

  search_option: (options_or_callback, callback) ->
    if typeof options_or_callback == "function"
      callback = options_or_callback
    else
      options = options_or_callback
      callback = undefined

    options ?= {}
    callback ?= options.callback

    if @_search_option?
      @_search_option.callback(callback)
      return @_search_option

    existing_input = @element.querySelector(".search_input input")

    if existing_input?
      existing_input.parentNode.removeChild(existing_input)
      @element.querySelector(".search_option").remove()
      @element.querySelector(".search_input").remove()

    model = new App.Interface.Menu.Search(@, options)
    model.callback(callback) if callback?

    if existing_input?
      model.input.parentNode.replaceChild(existing_input, model.input)
      model.input = existing_input
      model.input.id = model.id

    @_custom_options[model.id] = model
    @_search_option = model

    model

  search: (option1, option2, option3) ->
    # Search for a string
    if typeof option1 == "string"
      @search_option(option2, option3) unless @_search_option?
      @_search_option.search(option1)
    else
      @search_option(option1, option2)

  submit_option: (text, options_or_callback, callback) ->
    if typeof options_or_callback == "function"
      callback = options_or_callback
    else
      options = options_or_callback
      callback = undefined

    options ?= {}
    options.text ?= text

    model = new App.Interface.Menu.Submit(@, options)
    model.callback(callback) if callback?

    @_custom_options[model.id] = model

    model

  dropdown_option: (text, options) ->
    options ?= {}
    options.text ?= text

    model = new App.Interface.Menu.SubMenu.Option(@, options)

    @_custom_options[model.id] = model

    model

  fullscreen_option: (visible = true) ->
    button_wrapper = @element.querySelector(".toggle_sidebar_wrapper:not(.mobile_visible)")

    if !button_wrapper?
      button_wrapper = document.createElement("DIV")
      button_wrapper.className = "option circle_option toggle_sidebar_wrapper mobile_hidden"
      button_wrapper.title = i18n.t("toggle_sidebar")

      button = document.createElement("A")
      button.className = "toggle_document_sidebar"
      button.href = "#"

      icon = document.createElement("I")

      if App.sidebar().visible()
        icon.className = "icon icon-resize-small"
      else
        icon.className = "icon icon-resize-full"

      button.appendChild(icon)
      button_wrapper.appendChild(button)

      new_resource_button = @element.querySelector(".new_resource_button_wrapper")

      if new_resource_button?
        @element.insertBefore(button_wrapper, new_resource_button.nextSibling)
      else
        @element.insertBefore(button_wrapper, @element.firstChild)

    @sandboxes.publish("fullscreen.create", { visible: visible })

    button_wrapper.classList.toggle("hidden", !visible)

    button_wrapper

  fullscreen_option_exists: ->
    @element.querySelector(".toggle_sidebar_wrapper.mobile_hidden:not(.hidden)")?

  alert: (text, options = {}) ->
    options.text = text

    @sandboxes.publish("alert", options)
    @_message("alert", text, options)

  unsafe_alert: (html, options = {}) ->
    options.safe = false
    @alert(html, options)

  notice: (text, options = {}) ->
    options.text = text

    @sandboxes.publish("notice", options)
    @_message("notice", text, options)

  unsafe_notice: (html, options = {}) ->
    options.safe = false
    @notice(html, options)

  remove: (id) ->
    notice = App.Interface.Menu.container.querySelector("##{id}")
    return unless notice?
    App.Helpers.Elements.slide_up(notice, -> notice.remove())

    undefined

  visibly_empty: ->
    for child in @element.children
      return false if App.Helpers.Elements.visible(child)

    true

  calculate_visibility: ->
    @element.style.display = "block"
    @element.style.display = "none" if @visibly_empty()

    null

  tutorial: (text, options = {}) ->
    options.id = "tutorial"

    text = "<b class='tutorial_badge'>Tutorial</b> #{text}"

    @unsafe_notice(text, options)

  _message: (message_type, content, options = {}) ->
    options.id ?= "message_#{App.Helpers.Generators.uuid()}"

    new_message = false
    message_type = if message_type == "alert" then "alert" else "notice"
    message = App.Interface.Menu.container.querySelector("##{options.id}")

    if message?
      content_wrapper = message.querySelector(".notice_content")
    else
      new_message = true

      message = document.createElement("DIV")
      message.id = options.id
      message.className = "navbar #{message_type}"

      close_icon = document.createElement("I")
      close_icon.className = "icon icon-cancel red right"
      close_icon.setAttribute("data-close", "")
      close_icon.title = "Close Notification"

      content_wrapper = document.createElement("SPAN")
      content_wrapper.className = "notice_content"

      message.appendChild(close_icon)
      message.appendChild(content_wrapper)

    if options.safe
      content_wrapper.textContent = content
    else
      content_wrapper.innerHTML = content

    badge = message.querySelector(".notice_badge")

    if options.badge?
      if !badge?
        badge = document.createElement("DIV")
        badge.className = "badge inline notice_badge"

      badge.textContent = options.badge
    else
      badge.remove() if badge?

    App.Interface.Menu.container
    .appendChild(message)

    if new_message || message.style.display == "none"
      $(message).slideUp(0, -> App.Helpers.Elements.slide_down(message))

    message

  serialize: ->
    output = {}
    output.menu = {}
    output.menu.instance_id = @instance_id
    output.menu.fullscreen_option_exists = @fullscreen_option_exists()

    output.options = []
    output.submit_options = []
    output.search_options = []

    for id, option of @_custom_options
      if option instanceof App.Interface.Menu.Option
        output.options.push(option.serialize())
      else if option instanceof App.Interface.Menu.Submit
        output.submit_options.push(option.serialize())
      else if option instanceof App.Interface.Menu.Search
        output.search_options.push(option.serialize())

    output
