
window.App ?= {}
window.App.Query ?= {}
window.App.Query.FieldCondition ?= {}

class window.App.Query.FieldCondition.Dropdown extends App.Query.FieldCondition.Base
  constructor: (@field, @_operator, @_value, @_options = {}) ->
    super(@field, @_operator, @_value, @_options)
  
  @operators: ["==", "!=", "?", "!?"]

  @aggregators: ["count"]

  @groupings: ["value"]
